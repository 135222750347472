import { fetchAPI } from 'particles';

type NewPage = {
  comment?: ArgComment;
  contentBlocks?: ArgContentBlocks | [];
  domain: ArgID;
  keywords?: ArgKeywords | [];
  name: ArgName;
  page: ArgID;
  pageId?: string;
  pageType: ArgPageType;
  status?: ArgStatus;
  tags?: ArgTags | [];
  title?: string;
  url: ArgURL;
  _with?: ArgWith;
};

type UpdateExistingPage = {
  comment: ArgComment;
  contentBlocks: ArgContentBlocks | [];
  keywords: ArgKeywords | [];
  name: ArgName;
  page: ArgID;
  pageId: string;
  pageType: ArgPageType;
  status: ArgStatus;
  tags: ArgTags | [];
  title: string;
  url: ArgURL;
  _with?: ArgWith;
};

type CreatePageRevisionParameters = NewPage | UpdateExistingPage;

export const callCreatePageRevision = async (
  parameters: CreatePageRevisionParameters,
): Promise<{ data: PageRevision }> => {
  const response = await fetchAPI({ method: `POST`, parameters, route: `pagerevisions` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreatePageRevision;

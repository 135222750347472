export const columns = [
    {
      align: `left`,
      dbKey: `competitor`,
      filter: true,
      sort: true,
      hide: false,
      label: `Competitor`,
      numeric: false,
      value: `competitor`,
      wrap: false,
      tooltip: `Name of competitor`,
    },
    {
      align: `left`,
      dbKey: `rankings`,
      filter: true,
      sort: true,
      hide: false,
      label: `Rankings`,
      numeric: true,
      value: `rankings`,
      wrap: false,
      tooltip: `Number of URL’s the domain ranks for in the data set`,
    },
    {
      align: `left`,
      dbKey: `gap`,
      filter: true,
      sort: true,
      hide: false,
      label: `Gap`,
      numeric: true,
      value: `gap`,
      wrap: false,
      tooltip: `Number of URLs that the competing domain ranks for where your domain does not rank in the data set. A green negative figure means your domain has more ranking URLs than your competitor; whereas a positive red number means that the competitor is ranking for more URLs than your domain`,
    },
    {
      align: `left`,
      dbKey: `opportunities`,
      filter: true,
      sort: true,
      hide: false,
      label: `Opportunities`,
      numeric: true,
      value: `opportunities`,
      wrap: false,
      tooltip: `The number of URLs that the competing domain ranks for and you do not (Gap) PLUS the number of URLs that the competing domain ranks for higher than your domain in the SERP in the data set`,
    },
    {
      align: `left`,
      dbKey: `rating`,
      filter: true,
      sort: true,
      hide: false,
      label: `Rating`,
      numeric: false,
      value: `rating`,
      wrap: false,
      tooltip: `A ranking order showing which domain has the most SERP coverage in the data set`,
    },
    {
      align: `left`,
      dbKey: `coverage`,
      filter: true,
      sort: true,
      hide: false,
      label: `Coverage`,
      numeric: false,
      value: `coverage`,
      wrap: false,
      tooltip: `The percentage of keywords within the selected category data set that this domain has coverage`,
    },
    {
      align: `left`,
      dbKey: `opportunityGap`,
      filter: true,
      sort: true,
      hide: false,
      label: `Opportunity Gap`,
      numeric: true,
      value: `opportunityGap`,
      wrap: false,
      tooltip: `Number of keywords within the selected category data set that this domain does not have SERP coverage for`,
    },
  ];
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { navigate } from 'gatsby-link';

// Particles
import { addSingleCache } from 'corigan';
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Field, Link, Select } from 'corigan';
import { Breadcrumbs, Card, Error, Info } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callCreateUser } from 'corigan';
import { callGetRoles } from 'corigan';
import { callGetManyTeams } from 'corigan';

const UsersCreate = () => (
  <ProtectedRoute redirect={ROUTES.users} requiredPermissions={[`users:create`]}>
    <Page application="portal" pageTitle="Create User">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.users}>Users</Link>
              <h1>Create User</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <CreateUserForm />
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

const selectChange = ({ event, field, setFieldValue }) => {
  const noValueProvided = !event || !field;
  if (noValueProvided) setFieldValue([]);
  setFieldValue(field, event);
};

const successMessage = (user: string): string => {
  return `The user "${user}" has been successfully created in the system.`;
};

type FormErrors = {
  email?: string;
  name?: string;
  roles?: string;
  teams?: string;
};

type FormValues = {
  email: ArgPhrase;
  name: ArgURL;
  roles?: ArgRoles;
  teams?: ArgTeams;
};

const buildSelectObjects = (arrayVal: { id: string; name: string }[]): { label: string; value: string }[] => {
  return arrayVal.map(({ id, name }) => ({ label: name, value: id }));
};

type CreateUserProps = {};

const initialValues = {
  email: ``,
  name: ``,
  roles: undefined,
  teams: undefined,
};

const CreateUserForm = (props: CreateUserProps) => {
  const [success, setSuccess] = useState<string>(null);
  const queryCache = useQueryCache();

  const { userHasPermission: canCreateUser } = useHasPermissions({ requiredPermissions: [`users:create`] });
  const { userHasPermission: canReadTeams } = useHasPermissions({ requiredPermissions: [`teams:read`] });

  const rolesQuery = useQuery([`callGetRoles`], callGetRoles);
  const rolesData = rolesQuery?.data?.data;

  // Fetch roles available to the system
  const roleOptions = useMemo(() => {
    const hasData = rolesData?.length > 0;
    if (!hasData) return [];

    const allRoles = buildSelectObjects(rolesData);
    const hasRoles = allRoles?.length > 0;
    if (!hasRoles) return [];

    return allRoles;
  }, [rolesData]);

  const teamsQuery = useQuery([`callGetManyTeams`], callGetManyTeams, { enabled: canReadTeams });
  const teamsData: Team[] = teamsQuery?.data?.data;

  // Fetch teams available to the system
  const teamOptions = useMemo(() => {
    const hasData: boolean = teamsData?.length > 0;
    if (!hasData) return [];

    const allRoles = buildSelectObjects(teamsData);
    const hasRoles: boolean = allRoles?.length > 0;
    if (!hasRoles) return [];

    return allRoles;
  }, [teamsData]);

  const [mutate, { error, isLoading: loading }] = useMutation(callCreateUser, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyUsers`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const userData: User = data?.data;
      const id = userData?.id;

      // Parameters for single user query
      const singleWith = [`roles`, `teams`];
      const singleWhere: ArgWhere = `[_id][eq]=${id}`;

      // Parameters for inital all users query
      const mastersheetPerPage = 10;
      const mastersheetWith: ArgWith = [`roles`, `teams`, `teams.domains`];

      // Optimistically update to the new value for mastersheet page
      queryCache.setQueryData(
        [`callGetManyUsers`, { perPage: mastersheetPerPage, _with: mastersheetWith }],
        (previousUsers: APIResponse) => addSingleCache(previousUsers, userData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyUsers`, { _with: singleWith, where: singleWhere }],
        (previousUsers: APIResponse) => addSingleCache(previousUsers, userData),
      );

      // If updateUser function was successful, get values from API response
      const latestName = userData?.name;

      // Let the user know the page has been updated and update UI
      setSuccess(successMessage(latestName));

      // Redirect to the users table
      navigate(ROUTES.users);
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (windowAvailable() && !canCreateUser) window.alert(`You do not have permission to create a new user`);
      const { roles: rolesArray, teams: teamsArray } = values;
      let teams = undefined;
      let roles = undefined;
      if (rolesArray) roles = rolesArray.map(r => r.value);
      if (teamsArray) teams = teamsArray.map(t => t.value);
      if (canCreateUser) return await mutate({ ...values, roles, teams });
    },
    validate: (values: FormValues) => {
      const { email, name, roles, teams } = values;
      const hasRoles = roles?.length > 0;
      const hasTeams = teams?.length > 0;

      const errors: FormErrors = {};
      if (!name) errors.name = `The user must have a name`;
      if (!email) errors.email = `The user must have an email address`;
      if (!hasTeams) errors.teams = `The user must have at least one team`;
      if (!hasRoles) errors.roles = `The user must have at least one role`;
      return errors;
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  const { errors, touched, values, handleChange, handleBlur, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const isBusy: boolean = isSubmitting || loading;
  const isLoading: boolean = rolesQuery?.isLoading || teamsQuery?.isLoading || isBusy;
  const isLocked: boolean = !canCreateUser || isBusy;

  let buttonText: string = `Create User`;
  if (isBusy) buttonText = `Creating User`;

  return (
    <Card loading={isLoading}>
      <section className="user__create">
        <h2>User Details</h2>
        <form onSubmit={handleSubmit} aria-label="Create a User">
          <fieldset className="mb-0" aria-busy={isBusy} disabled={isLocked}>
            {success && (
              <Info onClose={onClose} y="lg">
                {success}
              </Info>
            )}
            {error && <Error error={error}>{error}</Error>}
            <Field {...formikHelpers} id="name" label="Name" required={true} />
            <Field {...formikHelpers} id="email" label="Email Address" type="email" required={true} />
            <label htmlFor="teams">Teams</label>
            {teamsQuery.error && <Error id="teamsQueryError" error={teamsQuery.error} />}
            <Select
              disabled={isLocked}
              onChange={event =>
                selectChange({
                  event,
                  field: `teams`,
                  setFieldValue,
                })
              }
              options={teamOptions}
              value={values.teams}
            />
            {touched && touched.teams && errors.teams && <Error id="errorTeams" error={errors.teams} />}
            <label htmlFor="roles">Roles</label>
            {rolesQuery.error && <Error id="rolesQueryError" error={rolesQuery.error} />}
            <Select
              disabled={isLocked}
              onChange={event =>
                selectChange({
                  event,
                  field: `roles`,
                  setFieldValue,
                })
              }
              options={roleOptions}
              value={values.roles}
            />
            {touched && touched.roles && errors.roles && <Error id="errorRoles" error={errors.roles} />}
            <button type="submit" disabled={isLocked}>
              {buttonText}
            </button>
          </fieldset>
        </form>
      </section>
    </Card>
  );
};

export default UsersCreate;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { dateFormal } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { HR, Link, Table } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetCrawl } from 'corigan';
import { callGetCrawlURLs } from 'corigan';

// Local Partials
import { ChipKeys } from '../partials';
import { columns } from '../partials';
import { generateRows } from '../partials';

declare type CrawlDetailsPageProps = {
  children?: React.ReactNode;
};

const CrawlDetails: React.FC<CrawlDetailsPageProps> = (props: CrawlDetailsPageProps) => {
  return (
    <ProtectedRoute>
      <Page application="competitor-research" pageTitle="Crawl Details">
        <PageContents />
      </Page>
    </ProtectedRoute>
  );
};

const PageContents: React.FC = () => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  const [mounted, setMounted] = useState<boolean>(false);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const parsedWhere: string = React.useMemo(() => {
    const whereQueries: string[] = [];

    Object.keys(queryParameters).forEach((query: string) => {
      if (query.includes(`where`)) {
        const queryValues: string | string[] = queryParameters[query];
        const queryValuesArray: string[] = Array.isArray(queryValues) ? queryValues : [queryValues];

        queryValuesArray.forEach((queryValue: string) => {
          const whereQuery: string = `${query}=${queryValue}`;

          whereQueries.push(whereQuery);
        });
      }
    });

    const joinedWhere = whereQueries.join(`&`);
    const finalWhere = joinedWhere.replace(`where`, ``);

    return finalWhere;
  }, [queryParameters]);

  // Create initial API arguments
  const initialWhere: ArgWhere = parsedWhere;
  const initialOrderBy: ArgOrderBy = undefined;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = undefined;

  const whereProtected: ArgWhere = `[crawl][eq]=${id}`;
  const [where, setWhere] = useState<ArgWhere>(initialWhere);
  const [orderBy, setOrderBy] = useState<ArgOrderBy>(initialOrderBy);
  const [page, setPage] = useState<ArgPage>(initialPage);
  const [perPage, setPerPage] = useState<ArgPerPage>(initialPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = initialOrderBy;
    const page: ArgPage = initialPage;
    const perPage: ArgPerPage = initialPerPage;
    const where: ArgWhere = initialWhere;

    // Set the new values to state
    setWhere(where);
    setOrderBy(orderBy);
    setPage(page);
    setPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const queryFunction = callGetCrawlURLs;
  const queryName = `callGetCrawlURLs`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = hasDomain;

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination: Pagination = res?.pagination;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data });
  }, [data]);

  // Query the parent crawl so we have a consistent date
  const parentCrawl = useQuery([`callGetCrawl`, { id, _with }], callGetCrawl);
  const parentCrawlData = parentCrawl?.data?.data;
  const hasParentDate = Boolean(parentCrawlData?.crawlDate);

  let title = `Crawl URLs`;
  if (hasParentDate) title += ` - ${dateFormal(parentCrawlData.crawlDate)}`;

  let csvTitle = `structure-changes`;
  if (hasParentDate) csvTitle += `-${dateFormal(parentCrawlData.crawlDate)}`;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={`${ROUTES.crawl}?id=${id}`}>Category List</Link>
            <h1>{title}</h1>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {mounted && (
              <Table
                apiArgs={apiArgs}
                apiDetails={apiDetails}
                apiFunctions={apiFunctions}
                allowFilters={true}
                collectionType="page"
                columns={columns}
                csvTitle={csvTitle}
                error={error}
                filtersToHide={[`categoryNames`]}
                id="mastersheet-structure-changes"
                items={items}
                loading={loading}
                pagination={pagination}
                selectable={true}
                small={false}
                exportArgs={apiArgs}
                exportRoute={`crawlurls`}
              />
            )}
            <HR background="var(--grey200)" variant="large" />
            <ChipKeys />
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default CrawlDetails;

import * as React from 'react';

function SvgLibraryBooks(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M2.4 4.8H0v16.8C0 22.9 1.1 24 2.4 24h16.8v-2.4H2.4V4.8zM21.6 0H7.2C5.9 0 4.8 1.1 4.8 2.4v14.4c0 1.3 1.1 2.4 2.4 2.4h14.4c1.3 0 2.4-1.1 2.4-2.4V2.4C24 1.1 22.9 0 21.6 0zm-1.2 10.8h-12V8.4h12v2.4zm-4.8 4.8H8.4v-2.4h7.2v2.4zM20.4 6h-12V3.6h12V6z"
      />
    </svg>
  );
}

export default SvgLibraryBooks;

import * as React from 'react';

// Components
import { Card } from 'corigan';

// Local Components
import KeywordRanks from '../ranks';

declare type KeywordTablesProps = {
  data: any;
  loading: boolean;
};

const KeywordTables: React.FC<KeywordTablesProps> = (props: KeywordTablesProps) => {
  const { data, loading } = props;
  return (
    <Card animate={true} loading={loading}>
      <KeywordRanks data={data} loading={loading} title="Keyword SERP Rankings" />
    </Card>
  );
};

export default KeywordTables;

import ROUTES from 'routes';

import type { NavigationItem } from '../navigation.types';

export const sidebarItems: NavigationItem[] = [
  {
    href: ROUTES.keyword_manager,
    icon: `listBulleted`,
    title: `Keywords`,
  },
  {
    disabled: true,
    href: ROUTES.opportunity_tool,
    icon: `find`,
    title: `Opportunity Tool`,
  },
];

export default sidebarItems;

import { css } from 'styled-components';
import { shouldBeDark } from 'particles';

const label = css`
  label {
    display: block;
    margin-bottom: var(--spacingTiny);
    margin-top: 24px;

    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    cursor: pointer;
    font-weight: 500;
    text-align: left;
  }

  .label--disabled {
    cursor: not-allowed;
  }
`;

export default label;

import { useQuery } from 'react-query';
import { useMemo } from 'react';

import { callMe } from 'corigan';

export const useMyDomains = (): Domain[] => {
  const _with: ArgWith = [`teams.domains.client`];
  const { data: res } = useQuery([`callMe`, { _with }], callMe);
  const user = res?.data;

  const domains = useMemo(() => {
    // @ts-ignore
    const teams: Team[] = user?.teams;
    const hasTeams: boolean = teams?.length > 0;

    if (!hasTeams) return [];

    const userDomains = teams?.map(t => {
      const hasDomains: boolean = t?.domains?.length > 0;
      if (!hasDomains) return null;

      const userDomains = t.domains;
      return userDomains;
    });

    const filteredDomain = userDomains.filter(Boolean);

    const hasDomains: boolean = filteredDomain?.length > 0;
    if (!hasDomains) return [];

    const flatDomains = Array.prototype.concat.apply([], filteredDomain);
    const flatSuccess: boolean = flatDomains?.length > 0;
    if (!flatSuccess) return [];

    const seen = new Set();

    const domains = flatDomains.filter(domain => {
      const duplicate = seen.has(domain.id);
      seen.add(domain.id);
      return !duplicate;
    });

    const domainsOrderByHostname = domains.sort((a, b) => {
      return a.hostname.localeCompare(b.hostname);
    });

    return domainsOrderByHostname;
  }, [user]);

  return domains;
};

export default useMyDomains;

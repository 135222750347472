import { css } from 'styled-components';
import { shouldBeDark } from 'particles';

export const toolbar = css`
  button,
  input,
  input[disabled] {
    align-items: center;
    display: flex;
    justify-content: center;

    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--offWhite)`)};
    border: 0;
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    font-size: 18px;

    &:active,
    &:focus,
    &:hover {
      background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--offWhite)`)};
      outline: 0; /* reset for :focus */
    }
  }

  .draftJsToolbar__toolbar__dNtBH {
    button {
      padding: 0;
    }
  }

  .draftJsToolbar__buttonWrapper__1Dmqh,
  .editor__toolbar__link {
    display: inline-block;
  }

  .editor__toolbar__link {
    margin-right: 4px;
    margin-left: 2px;
  }

  button.editor__toolbar__link__button {
    padding: 2px;

    &:active,
    &:focus,
    &:hover {
      background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--blue050)`)};

      path {
        fill: var(--blue);
      }
    }
  }

  .editor__toolbar__link__button--active {
    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--blue050)`)};

    path {
      fill: var(--blue);
    }
  }

  .draftJsToolbar__active__3qcpF {
    background-color: var(--grey100);
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
  }

  .draftJsToolbar__active__3qcpF svg {
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
  }

  .draftJsToolbar__separator__3U7qt {
    display: inline-block;
    border-right: 1px solid #ddd;
    height: 24px;
    margin: 0 0.5em;
  }

  .draftJsToolbar__toolbar__dNtBH {
    box-sizing: border-box;
    left: 50%;
    padding-top: 6px;
    position: absolute;
    z-index: 2;

    background: var(--white);
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
    transform: translate(-50%) scale(0);
  }

  .draftJsToolbar__toolbar__dNtBH:after,
  .draftJsToolbar__toolbar__dNtBH:before {
    content: ' ';
    height: 0;
    left: 50%;
    position: absolute;
    top: 100%;
    width: 0;

    border: solid transparent;
    pointer-events: none;
  }

  .draftJsToolbar__toolbar__dNtBH:after {
    margin-left: -4px;

    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
  }

  .draftJsToolbar__toolbar__dNtBH:before {
    margin-left: -6px;

    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 6px;
  }
`;

export default toolbar;

export const removeSingleCache = (previousCollection, id: ArgID) => {
  // Are there keywords in the cache?
  const data = previousCollection?.data;
  if (!data) return previousCollection;

  // If there are no keywords in the cache, create a new array with the new keyword
  const hasExistingData = data?.length > 0;
  if (!hasExistingData) return { ...previousCollection, data: [] };

  // Remove the new old collection item to the data array
  const newData = data.filter(k => k.id !== id);

  return {
    ...previousCollection,
    data: newData,
  };
};

export default removeSingleCache;

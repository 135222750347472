import * as React from 'react';

import { StyledTH } from '../../table.styles';

import { ArrowDown } from 'icons';

import { Tooltip } from 'corigan';

type TableHeadingProps = {
  align?: 'left' | 'center' | 'right';
  dbKey: string;
  sort: boolean;
  hide: boolean;
  label?: string;
  name?: string;
  numeric: boolean;
  tooltip: string;
  value: string;
  wrap: boolean;
  apiArgs: any;
  apiFunctions: any;
  index?: number;
};

type DirectionOrder = 'ascending' | 'descending';

const TableHeading: React.FC<TableHeadingProps> = (props: TableHeadingProps) => {
  const { dbKey, sort, label, tooltip, index } = props;

  const { apiArgs, apiFunctions } = props;
  const { orderBy } = apiArgs;
  const { setOrderBy } = apiFunctions;

  const isPositive = orderBy === dbKey;
  const isNegative = orderBy === `-${dbKey}`;
  const isActive = isPositive || isNegative;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();

    if (!isActive) {
      setOrderBy(dbKey);
      return;
    }

    if (isPositive) setOrderBy(`-${dbKey}`);
    if (isNegative) setOrderBy(dbKey);
  };

  const isDescending = orderBy && orderBy.startsWith(`-`);
  const direction: DirectionOrder = isDescending ? `descending` : `ascending`;

  let className = `th`;
  if (isActive) className += ` th--active`;
  if (isActive && direction) className += ` th--${direction.toLowerCase()}`;

  const tooltipSide = (index === 0) ? `right`: `left`;
  const hasTooltip = (tooltip !== ``) ? true : false;

  let content = (
    <React.Fragment>
      {!sort && <span>{label}</span>}
      {sort && (
        <button data-sort={dbKey} onClick={handleClick}>
          <span>{label}</span>
          {isActive && <ArrowDown />}
        </button>
      )}
    </React.Fragment>
  )

  if(hasTooltip){
    content = (
      <Tooltip align="top" content={tooltip} side={tooltipSide}>
        {content}
      </Tooltip>
    )
  }

  return (
    <StyledTH className={className}>
      {content}
    </StyledTH>);
};

// Default prop values
TableHeading.defaultProps = {
  align: `left`,
  sort: false,
  hide: false,
  numeric: false,
  wrap: false,
  tooltip: ``,
};

export default TableHeading;

import * as React from 'react';
import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';

// Particles
import { buildSelectObjects } from 'corigan';
import { selectChange } from 'corigan';
import { updateSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useMyPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Field, Select } from 'corigan';
import { Error, Info } from 'corigan';

// API
import { callGetDomains } from 'corigan';
import { callUpdateTeam } from 'corigan';

// Localised partials
import TeamContent from './content';

type RequestCompleteProps = {
  data: any;
};

const RequestComplete = (props: RequestCompleteProps) => {
  const { data } = props;
  const { name } = data;

  return (
    <TeamContent data={data} title={name}>
      <TeamOverview data={data} />
    </TeamContent>
  );
};

const successMessage = team => {
  return `The team "${team}" has been successfully updated in the system.`;
};

type FormErrors = {
  domains?: any;
  name?: ArgName;
};

type FormValues = {
  domains: ObjectLiteral[];
  name: ArgURL;
};

type TeamOverviewProps = {
  data: any;
};

const domainsToSelect = domainsArr => {
  const hasArray = domainsArr?.length > 0;
  if (!hasArray) return [];

  const selectData = domainsArr.map(({ hostname, id }) => ({ id, name: hostname }));
  const allDomains = buildSelectObjects(selectData);
  return allDomains;
};

const TeamOverview = (props: TeamOverviewProps) => {
  const { data } = props;
  const { id, domains, name } = data;
  const [success, setSuccess] = useState(null);
  const queryCache = useQueryCache();

  const { userHasPermission: canUpdate } = useHasPermissions({ requiredPermissions: [`teams:update`] });

  const domainsQuery = useQuery([`callGetDomains`], callGetDomains);
  const domainsData = domainsQuery?.data?.data;

  // Fetch domains available to the system
  const domainOptions = useMemo(() => {
    const hasData = domainsData?.length > 0;
    if (!hasData) return [];

    const allDomains = domainsToSelect(domainsData);
    const hasDomains = allDomains?.length > 0;
    if (!hasDomains) return [];

    return allDomains;
  }, [domainsData]);

  const formik = useFormik({
    initialValues: {
      domains: domainsToSelect(domains),
      name,
    },
    onSubmit: async values => await mutate(values),
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { domains, name } = values;
      const hasDomains = domains?.length > 0;
      if (!hasDomains) errors.domains = `Please add at least one domain`;
      if (!name) errors.name = `Please specify a team name`;
      return errors;
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const updateTeam = async values => {
    if (windowAvailable() && !canUpdate) window.alert(`You do not have permission to update teams`);
    if (!canUpdate) return;

    const { domains: unparsedDomains, name } = values;
    const domains = unparsedDomains.map(({ value }) => value);

    const fnArgs = {
      id,
      domains,
      name,
    };

    return await callUpdateTeam(fnArgs);
  };

  const [mutate, { error, isLoading: loading }] = useMutation(updateTeam, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyTeams`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const updatedTeam: Team = data?.data;
      // If updateTeam function was successful, get values from API response
      const latestName = updatedTeam?.name;
      // Let the team know the page has been updated and update UI
      setSuccess(successMessage(latestName));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetOneTeam`, { id, _with: [`domains`] }], () => data);

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTeams`], (previousTeams: APIResponse) =>
        updateSingleCache(previousTeams, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTeams`, { perPage: 10, _with: [`domains`] }], (previousTeams: APIResponse) =>
        updateSingleCache(previousTeams, updatedTeam),
      );
    },
  });

  const onClose = e => {
    e.preventDefault();
    setSuccess(null);
  };

  const locked: boolean = !canUpdate || loading;

  let buttonText: string = `Update Team`;
  if (loading) buttonText = `Updating Team`;

  return (
    <section className="team__update">
      <h2>Team Details</h2>
      <form onSubmit={handleSubmit} aria-label="Update a Team">
        <fieldset className="mb-0" aria-busy={locked} disabled={locked}>
          {success && (
            <Info onClose={onClose} y="lg">
              {success}
            </Info>
          )}
          {error && <Error error={error}>{error}</Error>}
          <Field {...formikHelpers} id="name" label="Name" required={true} />
          <label htmlFor="domains">Sites</label>
          {domainsQuery.error && <Error id="domainsQueryError" error={domainsQuery.error} />}
          <Select
            disabled={locked}
            onChange={event =>
              selectChange({
                event,
                field: `domains`,
                setFieldValue,
              })
            }
            options={domainOptions}
            value={values.domains}
          />
          {touched[`domains`] && errors[`domains`] && <Error id="domainsError" error={errors[`domains`]} />}
          <button type="submit" disabled={locked}>
            {buttonText}
          </button>
        </fieldset>
      </form>
    </section>
  );
};

export default RequestComplete;

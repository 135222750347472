import * as React from 'react';

function SvgKey(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M12.7 9.8c-.9-2.5-3.3-4.3-6.1-4.3C3 5.5.1 8.4.1 12s2.9 6.5 6.5 6.5c2.8 0 5.2-1.8 6.1-4.3h4.7v4.3h4.3v-4.3h2.2V9.8H12.7zm-6.1 4.4c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgKey;

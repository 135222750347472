import { fetchAPI } from 'particles';

type GetDomainsParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
  _with?: ArgWith;
};

export const callGetDomains = async (
  queryKey,
  parameters: GetDomainsParameters,
): Promise<{ data: Domain[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `domains` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetDomains;

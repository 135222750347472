import * as React from 'react';

// Components
import { Chip } from 'corigan';
import { Keys } from 'corigan';

const ChipKeys = () => {
  return (
    <Keys bullets={false} className="mt-3" id="crCrawlDetailsKeys" title="Significance Levels">
      <li>
        <Chip bold={true} variant="green">
          New
        </Chip>
        {` `}- New URL has appeared since the last crawl.
      </li>
      <li>
        <Chip bold={true} variant="red">
          Removed
        </Chip>
        {` `}- The URL has been unindexed
      </li>
      <li>
        <Chip bold={true} variant="plain">
          Info
        </Chip>
        {` `}- A normal redirect or 200 that has no real impact
      </li>
      <li>
        <Chip bold={true} variant="yellow">
          Low
        </Chip>
        {` `}- A redirect or change that may yield a low performance impact
      </li>
      <li>
        <Chip bold={true} variant="orange">
          Med
        </Chip>
        {` `}- A redirect or change that may yield a medium performance impact
      </li>
      <li>
        <Chip bold={true} variant="red">
          High
        </Chip>
        {` `}- A redirect or change that may yield a high performance impact
      </li>
    </Keys>
  );
};

export { ChipKeys };
export default ChipKeys;

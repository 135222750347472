import React, { useEffect, useRef } from 'react';

type OverlayProps = {
  active: boolean;
  children?: React.ReactNode;
  className?: string;
  updateHeaderView?: Function;
};

type RefDefinition = { current: HTMLDivElement | null };

const Overlay: React.FC<OverlayProps> = (props: OverlayProps) => {
  const { active, children, className, updateHeaderView } = props;
  const myRef: RefDefinition = useRef();

  const handleClickOutside = e => {
    if (!active) return;
    const current = myRef?.current;
    if (!current) return;
    const wasOutside = !current.contains(e.target);
    if (wasOutside) updateHeaderView(`default`);
  };

  useEffect(() => {
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => document.removeEventListener(`mousedown`, handleClickOutside);
  });

  let classList = `header__overlay`;
  if (className) classList += ` ${className}`;

  return (
    <nav className={classList} ref={myRef}>
      {children}
    </nav>
  );
};

export default Overlay;

import * as React from 'react';

// Icons
import { ArrowDown } from 'icons';

// Components
import { Link } from 'corigan';
import { Tooltip } from 'corigan';

// Local Particles
import StyledStatistic from './statistic.styles';

type StatisticFigure = {
  change?: number;
  figure: number;
  href?: string;
  label: string;
};

declare type StatisticFigures = { figures?: StatisticFigure[] };

declare type StatisticSingle = {
  change?: number;
  changeLabel?: string;
  figure?: number;
  href?: string;
  label?: string;
  large?: boolean;
  orientation?: 'landscape' | 'portrait';
  title?: string;
  titleLower?: boolean;
};

type StatisticProps = StatisticFigures & StatisticSingle;

const Statistic: React.FC<StatisticProps> = (props: StatisticProps) => {
  const { change, figures } = props;
  const { large, orientation, titleLower } = props;

  const isNegative: boolean = change < 0;

  const isSideways: boolean = orientation === `landscape`;

  let classList: string = `statistic`;
  if (large) classList += ` statistic--large`;
  if (isSideways) classList += ` statistic--landscape`;
  if (isNegative) classList += ` statistic--negative`;
  if (!isNegative) classList += ` statistic--positive`;
  if (titleLower) classList += ` statistic--title-lower`;

  const isMultiple: boolean = figures?.length > 0;

  return (
    <StyledStatistic className={classList}>
      {isMultiple && <MultipleStatistics {...props} />}
      {!isMultiple && <SingleStatistic {...props} />}
    </StyledStatistic>
  );
};

const MultipleStatistics = (props: StatisticFigures & { title?: string }) => {
  const { figures, title } = props;

  return (
    <React.Fragment>
      <h3>{title}</h3>
      <div className="statistic__figures">
        {figures.map(f => {
          const change = f?.change;
          const isNegative = change < 0;
          let classList = `statistic__figure`;
          if (isNegative) classList += ` statistic--negative`;
          if (!isNegative) classList += ` statistic--positive`;

          return (
            <div key={`stat-${f?.label}-${title}`}>
              <h4 className="statistic__label">{f?.label}</h4>
              <p className={classList}>{f?.figure?.toLocaleString()}</p>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const SingleStatistic = props => {
  const { change, changeLabel, figure, href, label, tooltip } = props;
  const percentChange: number = change / 100;

  const hasChange = Boolean(change);

  const isNegative: boolean = change < 0;
  const prepend: 'less' | 'more' | null = !hasChange ? null : isNegative ? `less` : `more`;

  const changeLabelFull: string = prepend ? `${prepend} ${changeLabel}` : changeLabel;
  const hasChangeData: boolean = Boolean(change) || Boolean(changeLabel);

  let content = (
    <React.Fragment>
      <h4 className="statistic__label">{label}</h4>
      <p className="statistic__figure">{figure?.toLocaleString()}</p>
      {hasChangeData && (
        <React.Fragment>
          {hasChange && (
            <p className="statistic__change">
              {percentChange?.toLocaleString(`en`, { style: `percent`, minimumFractionDigits: 2 })}
              <ArrowDown />
            </p>
          )}

          {changeLabel && <span className="statistic__change__label">{changeLabelFull}</span>}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  if (href) content = <Link href={href}>{content}</Link>;
  if (tooltip)
    content = (
      <Tooltip align="top" content={tooltip} side="left">
        {content}
      </Tooltip>
    );

  return content;
};

Statistic.defaultProps = {
  large: false,
  orientation: `portrait`,
  titleLower: false,
};

export default Statistic;

import * as React from 'react';
import { useContext } from 'react';

// Particles
import { KRContext } from 'corigan';

// Components
import { Toggle } from 'corigan';

declare type HistoryViewProps = {};

const HistoryView: React.FC<HistoryViewProps> = (props: HistoryViewProps) => {
  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;
  const historyView: string = state?.historyView;

  const viewOptions: { label: string; value: 'ctr' | 'impressions' }[] = [
    { label: `CTR`, value: `ctr` },
    { label: `Impressions`, value: `impressions` },
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = e?.target?.value;
    if (!value) return;

    if (!dispatch) return;
    dispatch({ key: `historyView`, value });
  };

  return (
    <div className="history__controls">
      {viewOptions.map(option => {
        const { label, value } = option;

        const isOn: boolean = historyView === value;
        const id: string = `keyword-research-gsc-view-` + option.value;

        return <Toggle key={id} id={id} label={label} on={isOn} onChange={onChange} value={value} />;
      })}
    </div>
  );
};

export default HistoryView;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { dateFormal } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ROUTES } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Card } from 'corigan';
import { Col, Row } from 'corigan';
import { Table } from 'corigan';

// Localised partials
import Content from './content';
import CrawlCharts from '../../partials/crawl-charts';
import CrawlStatistics from '../../partials/crawl-statistics';

// API
import { callGetCategoryCounts } from 'corigan';

type WrapperProps = {
  data: Crawl;
  id: ArgID;
};

export interface CSSProperties extends React.CSSProperties {
  '--rows'?: number;
}

const Wrapper = (props: WrapperProps) => {
  const { data: crawl } = props;
  const title = `Category List`;
  const subtitle = `${crawl?.domain?.hostname} - ${dateFormal(crawl?.crawlDate)}`;

  const statistics = crawl?.statistics;
  const hasStatistics: boolean = statistics?.length > 0;

  useEffect(() => {
    document.title = `Corigan - Competitor Research: Crawl Categories - ${subtitle}`;
  }, [subtitle]);

  return (
    <Content data={crawl} title={title} subtitle={subtitle}>
      {hasStatistics && (
        <Row>
          <Col>
            <Card minHeight={false}>
              <CrawlStatistics crawl={crawl} />
            </Card>
          </Col>
        </Row>
      )}
      <CrawlCharts crawl={crawl} />
      <Row>
        <Col>
          <Card minHeight={false}>
            <CrawlCategoryTable crawl={crawl} />
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export const columns = [
  {
    align: `left`,
    dbKey: `category`,
    filter: true,
    sort: true,
    hide: false,
    label: `Category Name`,
    numeric: false,
    value: `categoryName`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `count`,
    filter: true,
    sort: true,
    hide: false,
    label: `Total`,
    numeric: true,
    value: `total`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `statistics.new`,
    filter: true,
    sort: true,
    hide: false,
    label: `New`,
    numeric: true,
    value: `new`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `statistics.changed`,
    filter: true,
    sort: true,
    hide: false,
    label: `Changed`,
    numeric: true,
    value: `changed`,
    wrap: false,
  },

  {
    align: `left`,
    dbKey: `statistics.removed`,
    filter: true,
    sort: true,
    hide: false,
    label: `Removed`,
    numeric: true,
    value: `removed`,
    wrap: false,
  },
];

type RequestCompleteProps = {
  crawl: any;
};

const generateRows = ({ data, id }) => {
  if (!data) return [];

  const formattedData = data.map(category => {
    const categoryName = {
      href: `${ROUTES.structure_changes}?id=${id}&category=${category.category}`,
      value: category?.category,
    };

    const statistics = category?.statistics;
    const total = category?.count || `-`;
    const newCount = statistics?.new || `-`;
    const changed = statistics?.changed || `-`;
    const removed = statistics?.removed || `-`;

    const item = {
      categoryName,
      total,
      new: newCount,
      changed,
      removed,
    };

    return item;
  });

  return formattedData;
};

const CrawlCategoryTable = (props: RequestCompleteProps) => {
  const [mounted, setMounted] = useState<boolean>(false);

  const id = props?.crawl?.id;

  const { releaseVersion } = getRelease();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=crCategoryCrawlMastersheet-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Create initial API arguments
  const initialWhere: ArgWhere = undefined;
  const initialOrderBy: ArgOrderBy = undefined;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = undefined;

  // Read local storage values
  const localOrderBy: ArgOrderBy = localStorageRead(buildKey(`OrderBy`))
    ? localStorageRead(buildKey(`OrderBy`))
    : initialOrderBy;
  const localPage: ArgPage = localStorageRead(buildKey(`Page`)) ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = localStorageRead(buildKey(`PerPage`))
    ? localStorageRead(buildKey(`PerPage`))
    : initialPerPage;
  const localWhere: ArgWhere = localStorageRead(buildKey(`Where`)) ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }

      return newValue;
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = (value: any) => setStateValue(`Page`, value);
  const setPerPage = (value: any) => setStateValue(`PerPage`, value);
  const setWhere = (value: any) => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const whereProtected: ArgWhere = `[crawl][eq]=${id}`;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const queryFunction = callGetCategoryCounts;
  const queryName = `callGetCategoryCounts`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = hasDomain;

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination: Pagination = res?.pagination;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data, id });
  }, [data, id]);

  if (!mounted) return null;

  return (
    <Table
      apiArgs={apiArgs}
      apiDetails={apiDetails}
      apiFunctions={apiFunctions}
      allowFilters={true}
      collectionType="crawl"
      columns={columns}
      error={error}
      filtersToHide={[`crawl`]}
      id="mastersheet-crawl-categories"
      items={items}
      loading={loading}
      pagination={pagination}
      selectable={false}
      small={false}
      transfer={false}
    />
  );
};

export default Wrapper;

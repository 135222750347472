import * as React from 'react';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { useMemo } from 'react';
import moment from 'moment';

// Particles
import { OMContext } from 'corigan';
import { ApplicationContext } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useWindowEvent } from 'corigan';
import { callMe } from 'corigan';
import { toStartCase } from 'corigan';

// Components
import { Chip } from 'corigan';
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import DeletePage from '../buttons/delete';
import PatchPage from '../buttons/patch';
import PostPage from '../buttons/post';
import RevisionsButton from '../buttons/revision';
import UnlockButtons from '../buttons/unlock';

import Sidebar from '../sidebar';

import { statuses } from 'particles';

type PageContentProps = {
  children?: React.ReactNode;
  isLocked?: boolean;
  loading: boolean;
};

const PageContent: React.FC<PageContentProps> = (props: PageContentProps) => {
  const { children, isLocked, loading: pageLoading } = props;

  // Get the user information
  const { data: res, isLoading: userLoading } = useQuery([`callMe`], callMe);
  const user = res?.data;

  const loading = pageLoading || userLoading;

  const { userHasPermission: permittedToApprove } = useHasPermissions({ requiredPermissions: [`pages:approve`] });
  const { userHasPermission: permittedToArchive } = useHasPermissions({ requiredPermissions: [`pages:archive`] });

  const context = useContext(OMContext);
  const state = context?.state;

  const haveLoadedState = Boolean(state);
  const id: ArgID = state?.id;

  const editedRevision: PageRevision = state?.editedRevision;
  const loadedRevision: PageRevision = state?.loadedRevision;
  const error: Error | null = state?.error ? state?.error : null;
  if (error) {
    context.dispatch({ type: `set`, key: `error`, value: null });
  }
  const createdByUser: User | string = Array.isArray(loadedRevision?.createdBy)
    ? loadedRevision?.createdBy[0]
    : loadedRevision?.createdBy;
  const createdByUserId: string = typeof createdByUser !== `string` ? createdByUser?.id : createdByUser;
  const status: ArgStatus = editedRevision?.status;
  const diff: PageRevisionDiff = editedRevision?.diff;
  const lowerStatus: string = status?.toLowerCase();

  const isNewPage: boolean = lowerStatus === statuses.newPage.toLowerCase();
  const isBriefed: boolean = lowerStatus === statuses.briefed.toLowerCase();
  const isDraft: boolean = lowerStatus === statuses.draft.toLowerCase();
  const isAwaiting: boolean = lowerStatus === statuses.awaitingApproval.toLowerCase();
  const isRejected: boolean = lowerStatus === statuses.rejected.toLowerCase();
  const isMismatched: boolean = lowerStatus === statuses.mismatched.toLowerCase();
  const isLive: boolean = lowerStatus === statuses.live.toLowerCase();
  const isImplemented: boolean = lowerStatus === statuses.implemented.toLowerCase();

  const hasName: boolean = Boolean(editedRevision?.name);

  // Lock fields if status is past the approval stage
  const statusLocked: boolean = !isNewPage && !isBriefed && !isDraft;

  // If any edits to the page revision have been made yet
  const startedEdit: boolean = Boolean(state?.startedEdit);

  // If the status is currently briefed, and no edits to the page revision have been made yet
  const briefedNoEdit: boolean = isBriefed && !startedEdit;

  // If the status is currently live, and no edits to the page revision have been made yet
  const liveNoEdit: boolean = isLive && !startedEdit;

  // If the status is currently implemented, and no edits to the page revision have been made yet
  const implementedNoEdit: boolean = isImplemented && !startedEdit;

  // If the status is currently rejected, and no edits to the page revision have been made yet
  const rejectedNoEdit: boolean = isRejected && !startedEdit;

  // If the status is currently rejected, and no edits to the page revision have been made yet
  const hideCancelApproval: boolean = isAwaiting && createdByUserId !== user.id;

  // What date was the page locked, if we have an ISO8601 date value available
  const lockedDate: string = editedRevision?.editLocked?.date;

  // Set the unlock date to null
  let unlockStamp = null;

  // If we have a locked date, then figure out the time from now until unlocked
  if (lockedDate) unlockStamp = moment(lockedDate).fromNow();

  // TODO: Sort out correct permissions
  const briefedButton = {
    permissions: [],
    hideOn: [isLocked, !hasName, briefedNoEdit, rejectedNoEdit, hideCancelApproval],
    hideNoPermissions: true,
    status: statuses.briefed,
    text: `Create Brief`,
    editedText: `Create Brief`,
    loadingText: `Creating Brief`,
  };
  const draftButton = {
    permissions: [],
    hideOn: [isLocked, !hasName, !startedEdit],
    hideNoPermissions: true,
    status: statuses.draft,
    text: `Save as Draft`,
    editedText: `Save as Draft`,
    loadingText: `Saving Draft`,
    toastMessage: `Your page revision has been saved in the drafts table.`,
    modal: true,
    modalTitle: `Add Draft Comment`,
    modalConfirm: `Save Draft`,
    modalComment: true,
  };
  const awaitingApprovalButton = {
    permissions: [],
    hideOn: [isLocked, rejectedNoEdit],
    hideNoPermissions: true,
    status: statuses.awaitingApproval,
    text: `Submit for Approval`,
    editedText: `Submit for Approval`,
    loadingText: `Submitting for Approval`,
    toastMessage: `Your page has been successfully submitted for approval in the system.`,
  };
  const rejectedButton = {
    permissions: [`pages:approve`],
    hideOn: [!permittedToApprove],
    hideNoPermissions: true,
    status: statuses.rejected,
    text: `Reject`,
    editedText: `Reject`,
    loadingText: `Rejecting`,
    modal: true,
    modalTitle: `Add Rejection Comment`,
    modalConfirm: `Reject Revision`,
    modalComment: true,
  };
  const readyToImplementButton = {
    permissions: [`pages:approve`],
    hideOn: [!permittedToApprove],
    hideNoPermissions: true,
    status: statuses.readyToImplement,
  };
  const implementedButton = {
    permissions: [`pages:approve`],
    hideOn: [!permittedToApprove, implementedNoEdit],
    hideNoPermissions: true,
    status: statuses.implemented,
    text: `Mark as Implemented`,
    editedText: `Mark as Implemented`,
    loadingText: `Marking as Implemented`,
  };
  const liveButton = {
    permissions: [`pages:approve`],
    hideOn: [!permittedToApprove, liveNoEdit],
    hideNoPermissions: true,
    status: statuses.live,
    text: `Mark as Live`,
    editedText: `Mark as Live`,
    loadingText: `Marking as Live`,
    modal: true,
    modalTitle: `Are you sure?`,
    modalConfirm: `Yes`,
    modalComment: false,
    toastMessage: `Your page has been successfully marked as live in the system.`,
  };
  const offlineButton = {
    permissions: [`pages:approve`],
    hideOn: [!permittedToApprove],
    hideNoPermissions: true,
    status: statuses.offline,
  };
  const archiveButton = {
    permissions: [],
    hideOn: [isLocked, !permittedToArchive],
    hideNoPermissions: true,
    status: statuses.archived,
    text: `Discard Revision`,
    editedText: `Discard Revision`,
    loadingText: `Discarding Revision`,
  };
  const awaitingStatusButton = {
    permissions: [],
    hideOn: [],
    hideNoPermissions: false,
    text: `View Awaiting Approval`,
    status: `Awaiting Approval`,
  };

  const adminUnlockButton = {
    admin: true,
    text: `Admin Unlock`,
    editedText: `Unlock revision editing`,
    loadingText: `Unlocking page revision`,
    modal: true,
    modalTitle: `Are you sure?`,
    modalConfirm: `Yes`,
    modalComment: false,
    toastMessage: `This page has been successfully unlocked in the system.`,
  };

  const userUnlockButton = {
    admin: false,
    text: `Unlock Editing`,
    editedText: `Unlock revision editing`,
    loadingText: `Unlocking page revision`,
    modal: true,
    modalTitle: `Are you sure?`,
    modalConfirm: `Yes`,
    modalComment: false,
    toastMessage: `This page has been successfully unlocked in the system.`,
  };

  const statusWorkflow = {
    'New Page': [
      {
        method: `PATCH`,
        type: briefedButton,
        text: `Mark as Briefed`,
        editedText: `Mark Briefed`,
        loadingText: `Marking as Briefed`,
      },
    ],
    Archived: [
      { method: `POST`, type: briefedButton },
      { method: `POST`, type: draftButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    Briefed: [
      {
        method: `PATCH`,
        type: briefedButton,
        text: `Save`,
        editedText: `Save`,
        loadingText: `Saving`,
      },
      { method: `POST`, type: draftButton },
      { method: `PATCH`, type: awaitingApprovalButton },
      { method: `PATCH`, type: archiveButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    Draft: [
      {
        method: `PATCH`,
        type: draftButton,
        text: `Save`,
        editedText: `Save`,
        loadingText: `Saving`,
      },
      { method: `PATCH`, type: briefedButton },
      { method: `PATCH`, type: awaitingApprovalButton },
      { method: `POST`, type: archiveButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    'Awaiting Approval': [
      { method: `PATCH`, type: rejectedButton },
      {
        method: `PATCH`,
        type: briefedButton,
        text: `Cancel Approval`,
        editedText: `Cancel Approval`,
        loadingText: `Canceling Approval`,
      },
      { method: `PATCH`, type: readyToImplementButton },
    ],
    Rejected: [
      { method: `POST`, type: briefedButton },
      { method: `POST`, type: awaitingApprovalButton },
      { method: `POST`, type: draftButton },
      { method: `PATCH`, type: archiveButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    'Ready to Implement': [
      { method: `PATCH`, type: implementedButton },
      { method: `PATCH`, type: briefedButton },
      { method: `POST`, type: draftButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    Implemented: [
      { method: `PATCH`, type: liveButton },
      { method: `POST`, type: briefedButton },
      { method: `POST`, type: draftButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    Live: [
      {
        method: `POST`,
        type: liveButton,
        text: `Admin Quicksave`,
        editedText: `Admin Quicksave`,
        loadingText: `Admin Quicksaving`,
        modalText: `This action overwrites the live page.`
      },
      { method: `PATCH`, type: offlineButton },
      { method: `POST`, type: briefedButton },
      { method: `POST`, type: draftButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
    Mismatched: [{ method: `POST`, type: briefedButton }],
    Offline: [
      { method: `PATCH`, type: briefedButton },
      { method: `POST`, type: draftButton },
      { method: `AWAIT`, type: awaitingStatusButton },
    ],
  };

  return (
    <Grid>
      <Row>
        {useMemo(
          () => {
            const activeStatusFlow = statusWorkflow?.[status] || [];
            const hasFlowItems = activeStatusFlow?.length > 0;

            return (
              <Col>
                <Breadcrumbs>
                  <Link href={ROUTES.optimisation_manager}>Dashboard</Link>
                  <Link href={ROUTES.mastersheet}>Mastersheet</Link>
                  <span className="breadcrumb__heading">
                    <PageTitle isLocked={isLocked} />
                  </span>
                  {hasFlowItems &&
                    activeStatusFlow.map((statusButton, i) => {
                      const { method, type } = statusButton;
                      const { status: newStatus, hideOn } = type;

                      if (!hideOn.some(hide => hide)) {
                        // Overrides default button props if provided
                        const buttonProps = {
                          ...type,
                          ...statusButton,
                        };

                        if (method === `AWAIT`) {
                          return (
                            <span className="breadcrumb__button" key={`${i}-${newStatus}`}>
                              <RevisionsButton {...buttonProps} />
                            </span>
                          );
                        }
                        if (method === `PATCH`) {
                          return (
                            <span className="breadcrumb__button" key={`${i}-${newStatus}`}>
                              <PatchPage isLocked={isLocked} {...buttonProps} />
                            </span>
                          );
                        }
                        if (method === `POST`) {
                          return (
                            <span className="breadcrumb__button" key={`${i}-${newStatus}`}>
                              <PostPage isLocked={isLocked} {...buttonProps} />
                            </span>
                          );
                        }
                      }
                      return null;
                    })}
                  {isLocked && permittedToApprove && (
                    <span className="breadcrumb__button">
                      <UnlockButtons isLocked={isLocked} data={adminUnlockButton} />
                    </span>
                  )}
                </Breadcrumbs>
              </Col>
            );
          },
          [id, isLocked, unlockStamp, editedRevision], // eslint-disable-line react-hooks/exhaustive-deps
        )}
      </Row>
      {!haveLoadedState && (
        <Row>
          <Col>
            <Card loading={loading}>{children}</Card>
          </Col>
        </Row>
      )}
      {haveLoadedState && (
        <React.Fragment>
          {error && <Error error={error}>{error}</Error>}
          {isMismatched && <MismatchedErrors diff={diff} />}
          <Row>
            <Col xl={8}>
              <Card loading={loading}>{children}</Card>
            </Col>
            <Col xl={4}>
              <Sidebar isLocked={isLocked || statusLocked} status={status} />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Grid>
  );
};

type MismatchedErrorsProps = {
  diff: PageRevisionDiff;
};

const MismatchedErrors = (props: MismatchedErrorsProps) => {
  const { diff } = props;

  const id = `om-page-content`;
  const context = useContext(ApplicationContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const tabs = state?.tabs;
  const hasTabs = tabs.length > 0;
  const focus = 4; // The 'Difference' tab

  const handleClick = e => {
    if (e) e.preventDefault();

    if (!dispatch) return;

    const otherTabs = !hasTabs ? [] : tabs.filter(tab => tab.id !== id);
    const hasOtherTabs = otherTabs.length > 0;

    if (!hasOtherTabs) {
      dispatch({ type: `set`, key: `tabs`, value: [{ id, focus }] });
      return;
    }

    const newTabsState = [...otherTabs, { id, focus }];
    dispatch({ type: `set`, key: `tabs`, value: newTabsState });
  };

  const filteredDiffs = Object.keys(diff).filter(diffType => diff[diffType]?.length)

  return (
    <Error className="mt-0" title="Mismatched Errors:">
      <div>
        {filteredDiffs.map((diffType, index) => (
          <li key={index}>{toStartCase(diffType)}</li>
        ))}
      </div>
      <button className="mt-1 ml-1" onClick={handleClick}>
        View errors
      </button>
    </Error>
  );
};

const PageStatus = () => {
  const context = useContext(OMContext);
  const editedRevision: PageRevision = context?.state?.editedRevision;
  const startedEdit: boolean = context?.state?.startedEdit;
  if (!editedRevision) return null;

  const { status } = editedRevision;

  const lowerStatus = status?.toLowerCase();

  let text: string = status;
  let variant = undefined;
  switch (lowerStatus) {
    case statuses.draft.toLowerCase():
      variant = `tertiary`;
      break;
    case statuses.awaitingApproval.toLowerCase():
      variant = `red`;
      break;
    case statuses.implemented.toLowerCase():
      variant = `blue`;
      text = `Implemented`;
      break;
    case statuses.live.toLowerCase():
      variant = `green`;
      break;
    case statuses.mismatched.toLowerCase():
      variant = `red`;
      break;
    case statuses.archived.toLowerCase():
      break;
  }

  return (
    <>
      <Chip variant={variant} className="ml-1">&#9679; {text}</Chip>
      {startedEdit && <Chip variant={`senary`} className="ml-1">&#9679; Unsaved Changes</Chip>}
    </>
  );
};

const PageTitle = ({ isLocked }: { isLocked: boolean }) => {
  const context = useContext(OMContext);
  const state = context?.state;
  const startedEdit: boolean = state?.startedEdit;

  const editedRevision: PageRevision = state?.editedRevision;
  const loadedRevision: PageRevision = state?.loadedRevision;
  const name = editedRevision?.name;
  const pageIdEdited = editedRevision?.pageId;
  const pageIdLoaded = loadedRevision?.pageId;
  const pageId = pageIdEdited || pageIdLoaded;

  const confirmLeave = e => {
    if (startedEdit) {
      // Cancel the event as stated by the standard.
      e.preventDefault();
      // Chrome requires returnValue to be set.
      e.returnValue = ``;
    }
  };

  React.useEffect(() => {
    document.title = `Corigan - Optimisation Manager - ${name}`;
  }, [name]);

  useWindowEvent(`beforeunload`, confirmLeave, []);

  return useMemo(() => {
    let subtitle = ``;
    if (pageId) subtitle = pageId;

    return (
      <div>
        <div className="display--flex align-items--center">
          {isLocked && (
            <h1 className="mr-2"><span style={{color:`red`}}>Locked: </span>{name}</h1>
          )}
          {!isLocked && (
            <h1 className="mr-2">{name}</h1>
          )}
        </div>
        <div className="display--flex align-items--center">
          {subtitle}
          <PageStatus />
        </div>
      </div>
    );
  }, [isLocked, name, pageId]);
};

export default PageContent;

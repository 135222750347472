import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Page } from 'corigan';

// Local components
import Complete from './partials/complete';
import Error from './partials/error';
import Loading from './partials/loading';
import Missing from './partials/missing';

// API
import { callGetOneTag } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`tags:read`]}>
    <TagContents />
  </ProtectedRoute>
);

type TagProps = {};

const TagContents: React.FC<TagProps> = (props: TagProps) => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  return (
    <Page application="portal" pageTitle="Tag">
      {id && <TagInfo />}
      {!id && <Missing />}
    </Page>
  );
};

type TagInfoProps = {};

const TagInfo: React.FC<TagInfoProps> = (props: TagInfoProps) => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  // Fetch tag info
  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`tags:read`] });
  const { data: res, error, isLoading: loading } = useQuery([`callGetOneTag`, { id }], callGetOneTag, {
    enabled: canRead,
  });
  const data = res?.data;

  if (data) return <Complete data={data} />;
  if (loading) return <Loading />;
  if (error) return <Error error={error} id={id} />;
  if (!canRead) return <Error error="You do not have permission to access tags" />;
  return null;
};

export default Wrapper;

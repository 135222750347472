import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { RBContext } from 'corigan';
import { objectIsEmpty } from 'corigan';

// Components
import { Statistic } from 'corigan';
import { Error } from 'corigan';

// API
import { callGetSistrixMarketChanges } from 'corigan';

// Local Components
import ViewTemplate from '../view-template';

declare type ReportMarketChangesProps = {};

const ReportMarketChanges = (props: ReportMarketChangesProps) => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const context = useContext(RBContext);
  const state = context?.state;
  const { apiDate } = state;

  const date = apiDate?.clone()?.startOf(`isoWeek`)?.format(`YYYY-MM-DD`);

  const params = {
    date,
    domainId,
  };

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetSistrixMarketChanges`, params],
    callGetSistrixMarketChanges,
  );
  const data = res?.data;

  return (
    <ViewTemplate controlDevices={false} controlMOM={false} loading={loading}>
      {error && <Error error={error} />}
      {data && <ReportContent data={data} />}
    </ViewTemplate>
  );
};

declare type ReportContentProps = {
  data: any;
};

const sortedBy = {
  WOW: 0,
  Quarter: 1,
  MOM: 2,
  YOY: 3,
};

const generateStats = data => {
  const hasData = !objectIsEmpty(data);
  if (!hasData) return [];

  const stats = [];

  for (const [key, value] of Object.entries(data)) {
    const figure: number = Number(value);

    const figurePercent = figure.toLocaleString(`en`, {
      style: `percent`,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    stats.push({
      change: figure,
      label: key,
      figure: figurePercent,
    });
  }

  const hasStats = stats?.length > 0;
  if (!hasStats) return [];

  const orderedStats = stats.sort((a, b) => sortedBy[a.label] - sortedBy[b.label]);
  return orderedStats;
};

const ReportContent = (props: ReportContentProps) => {
  const { data } = props;

  const context = useContext(RBContext);
  const state = context?.state;
  const { monthOnMonth } = state;

  const devices = [`mobile`, `desktop`];

  const isMobile = devices.includes(`mobile`);
  const isDesktop = devices.includes(`desktop`);

  const statsObject = useMemo(() => {
    if (!monthOnMonth) return data;
    if (objectIsEmpty(data)) return data;

    // We don't currently
    // but can manipulate the data object if 'monthOnMonth' is true
    return data;
  }, [data, monthOnMonth]);

  const { overallTop100Desktop } = statsObject;
  const { overallTop100Mobile } = statsObject;
  const { retailersTop100 } = statsObject;
  const { retailersTop100WithoutAmazon } = statsObject;
  const { allMainCompetitorsResult } = statsObject;
  const { allMainCompetitorsWithoutAmazonResult } = statsObject;
  const { internalMovementDesktop } = statsObject;
  const { internalMovementMobile } = statsObject;

  // Parse the statsObject for the statistics component
  const overallTop100DesktopStats = generateStats(overallTop100Desktop);
  const overallTop100MobileStats = generateStats(overallTop100Mobile);
  const retailersTop100Stats = generateStats(retailersTop100);
  const retailersTop100WithoutAmazonStats = generateStats(retailersTop100WithoutAmazon);
  const allMainCompetitorsResultStats = generateStats(allMainCompetitorsResult);
  const allMainCompetitorsWithoutAmazonResultStats = generateStats(allMainCompetitorsWithoutAmazonResult);
  const internalMovementDesktopStats = generateStats(internalMovementDesktop);
  const internalMovementMobileStats = generateStats(internalMovementMobile);

  // Check the 'generateStats' has returned an array with length
  const hasOverallTop100DesktopStats: boolean = isDesktop && overallTop100DesktopStats?.length > 0;
  const hasOverallTop100Mobile: boolean = isMobile && overallTop100MobileStats?.length > 0;
  const hasRetailersTop100Stats: boolean = retailersTop100Stats?.length > 0;
  const hasRetailersTop100WithoutAmazonStats: boolean = retailersTop100WithoutAmazonStats?.length > 0;
  const hasAllMainCompetitorsResultStats: boolean = allMainCompetitorsResultStats?.length > 0;
  const hasAllMainCompetitorsWithoutAmazonResultStats: boolean = allMainCompetitorsWithoutAmazonResultStats?.length > 0;
  const hasInternalMovementDesktopStats: boolean = isDesktop && internalMovementDesktopStats?.length > 0;
  const hasInternalMovementMobileStats: boolean = isMobile && internalMovementMobileStats?.length > 0;

  return (
    <React.Fragment>
      <h2>Overall Market</h2>
      <div className="report__statistics report__statistics--landscape">
        {hasOverallTop100DesktopStats && (
          <Statistic
            orientation="landscape"
            figures={overallTop100DesktopStats}
            title="Overall Top 100 Market - Desktop"
          />
        )}
        {hasOverallTop100Mobile && (
          <Statistic
            orientation="landscape"
            figures={overallTop100MobileStats}
            title="Overall Top 100 Market - Mobile"
          />
        )}
        {hasRetailersTop100Stats && (
          <Statistic orientation="landscape" figures={retailersTop100Stats} title="Retailers in Top 100" />
        )}
        {hasRetailersTop100WithoutAmazonStats && (
          <Statistic
            orientation="landscape"
            figures={retailersTop100WithoutAmazonStats}
            title="Retailers in Top 100 - Excluding Amazon"
          />
        )}
      </div>
      <h2>Internal & Market</h2>
      <div className="report__statistics report__statistics--landscape">
        {hasAllMainCompetitorsResultStats && (
          <Statistic
            orientation="landscape"
            figures={allMainCompetitorsResultStats}
            title="Internal - Main Competitor Market"
          />
        )}
        {hasAllMainCompetitorsWithoutAmazonResultStats && (
          <Statistic
            orientation="landscape"
            figures={allMainCompetitorsWithoutAmazonResultStats}
            title="Internal - Main Competitor Market - Excluding Amazon"
          />
        )}
        {hasInternalMovementDesktopStats && (
          <Statistic orientation="landscape" figures={internalMovementDesktopStats} title="Internal Movement Desktop" />
        )}
        {hasInternalMovementMobileStats && (
          <Statistic orientation="landscape" figures={internalMovementMobileStats} title="Internal Movement Mobile" />
        )}
      </div>
    </React.Fragment>
  );
};

export default ReportMarketChanges;

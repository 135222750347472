export const defaultState = {
  allowFilters: true,
  collectionType: `page`,
  csvTitle: `mastersheet`,
  filters: [],
  filtersToHide: undefined,
  selected: [],
  selectable: true,
  showSelectEverything: false,
  updating: false,
};

export default defaultState;

import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

type DnDContextProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  items: any;
  setItems(item: any): void;
};

const DnDContext: React.FC<DnDContextProps> = (props: DnDContextProps) => {
  const { children, className, items, setItems } = props;

  const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.index === destination.index) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const newItems = reorder(items, source.index, destination.index);

      setItems(newItems);
    }
  };

  return (
    <div className={className}>
      <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
    </div>
  );
};

export default DnDContext;

import * as React from 'react';
import { useCallback, useContext } from 'react';

// Particles
import { OMContext } from 'corigan';
import { ROUTES } from 'corigan';

// Icons
import { Promoted } from 'icons';
import { Search } from 'icons';

// Components
import { Chip } from 'corigan';

const RenderKeywords = ({ isLocked, suggestions, keywords, searchTerm, linked = false, toggleCreating }) => {
  const omContext = useContext(OMContext);
  const dispatch = omContext?.dispatch;
  const state = omContext?.state;
  const editedRevision: PageRevision = state.editedRevision;

  const startedEdit: boolean = state?.startedEdit;
  const shouldConfirm: boolean = startedEdit;

  const handleAdd = useCallback(
    (e, keyword) => {
      e.preventDefault();
      const current = editedRevision?.keywords;

      // If already exists in state, ignore the function
      const exists = current.some(k => k.id === keyword.id);
      if (exists) return;

      // Create new array with updated keyword
      const newKeywords = [...current, keyword];
      dispatch({ key: `keywords`, value: newKeywords });
    },
    [dispatch, editedRevision],
  );

  const validKeywords = keywords.filter(k => k?.id && k?.phrase && k?.phrase !== searchTerm);
  const exactKeyword = keywords.find(k => k?.phrase === searchTerm);
  const keywordsAndSuggestions = [exactKeyword, ...suggestions, ...validKeywords].filter(Boolean);

  const noResults = keywordsAndSuggestions?.length < 1;
  if (noResults) return null;

  const keywordRoute = ROUTES.keyword;

  return (
    <>
      {keywordsAndSuggestions.map((k, i) => {
        const suggestion = !!k?.score;
        const keyword = suggestion ? k?.keyword : k;
        const phrase = k?.phrase;
        const { id, highPriority, crawlResponse: { responseCode } = {} } = keyword ?? {};
        const disabled = isLocked;
        const key = `keyword-${i}`;
        const href = (!linked || suggestion) ? null : keywordRoute + `?id=${id}`;

        // set variant for chip based on match then highPriority
        const variant: ColourVariant = searchTerm === phrase ? `green` : suggestion ? `orange` : null;
        const onClick = (suggestion && !keyword) ? e => toggleCreating(e, phrase) : e => handleAdd(e, keyword);

        return (
          <Chip variant={variant} confirm={shouldConfirm} disabled={disabled} href={href} key={key} onClick={onClick} responseCode={responseCode}>
            {Boolean(highPriority) && <Promoted />}
            {Boolean(suggestion) && <Search />}
            {phrase}
          </Chip>
        );
      })}
    </>
    );
};

export default RenderKeywords;

import * as React from 'react';
import { useContext, useCallback, useMemo } from 'react';

// Components
import { Field } from 'corigan';

// Particles
import { OMContext } from 'corigan';
import { statuses } from 'particles';


declare type CommentProps = {
  isLocked?: boolean;
};

const Comment: React.FC<CommentProps> = ({isLocked}: CommentProps) => {
  const omContext: any = useContext(OMContext);
  const dispatch: any = omContext?.dispatch;
  const state: any = omContext?.state;

  const editedRevision: PageRevision | null = state.editedRevision;
  const lowerStatus = editedRevision?.status?.toLowerCase();
  const isRejected: boolean = lowerStatus === statuses.rejected.toLowerCase();

  const comment = editedRevision?.comment ?? ``;

  const handleChange = useCallback(
    (e, key?: string) => {
      const { id, value } = e.target;
      dispatch({ key: key ? key : id, value });
    },
    [dispatch],
  );

  return (
    <React.Fragment>
      <form>
        <fieldset disabled={isRejected || isLocked} className="m-0">
          {useMemo(() => {
            return (
              <Field
                labelClassName="mt-0"
                disabled={isRejected || isLocked}
                handleChange={handleChange}
                id="comment"
                label="Comment"
                value={comment}
                type="textarea"
              />
            );
          }, [isRejected, isLocked, handleChange, comment])}
        </fieldset>
      </form>
    </React.Fragment>
  );
};

export default Comment;

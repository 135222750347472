import React, { useState } from 'react';
import { Formik } from 'formik';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';

// Components
import { Button, Link } from 'corigan';
import { Breadcrumbs, Card, Error, Info } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

type FormErrors = {
  message?: string;
};

type FormValues = {
  message: string;
  subject?: string;
};

const subjects = [
  { label: `Functionality`, value: `functionality` },
  { label: `Access`, value: `access` },
  { label: `Other`, value: `other` },
];

const initialValues: FormValues = {
  message: ``,
  subject: subjects[0].value,
};

const Issues = () => {
  return (
    <ProtectedRoute>
      <Page application="portal" pageTitle="Report Issues">
        <Grid>
          <Row>
            <Col>
              <Breadcrumbs>
                <Link href={ROUTES.dashboard}>Dashboard</Link>
                <h1>Report Issues</h1>
              </Breadcrumbs>
            </Col>
          </Row>
          <Row>
            <Col>
              <CreateIssue />
            </Col>
          </Row>
        </Grid>
      </Page>
    </ProtectedRoute>
  );
};

const successMessage = `Your issue was successfully reported to the development team`;

const CreateIssue = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const sendIssue = async values => {
    // TODO: Replace with an API FETCH POST request
    console.info(values);
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  return (
    <Card>
      {success && (
        <Info onClose={onClose} y="lg">
          {success}
        </Info>
      )}
      {error && <Error error={error}>{error}</Error>}
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          setSuccess(null);
          try {
            await sendIssue(values);
            setSuccess(successMessage);
          } catch (error) {
            setError(error);
          }
        }}
        validate={(values: FormValues) => {
          const errors: FormErrors = {};
          const { message } = values;
          if (!message) errors.message = `A message is required`;
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <label htmlFor="subject">Subject</label>
            <select id="subject" name="subject" value={values.subject} onChange={handleChange} onBlur={handleBlur}>
              {subjects.map(({ label, value }) => (
                <option key={`subject-${value}`} label={label} value={value} />
              ))}
            </select>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              aria-invalid={touched.message && !!errors.message}
              aria-describedby="errorMessage"
            />
            {touched.message && errors.message && <Error id="errorMessage" error={errors.message} />}
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? `Sending` : `Send`} Report
            </Button>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default Issues;

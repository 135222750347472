import 'react-dates/initialize';
import * as React from 'react';
import { useContext, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

// Particles
import { RBContext } from 'corigan';

declare type DateRangeProps = {
  title: string;
};

const momentCurrentEndOfWeek = moment().endOf(`week`);
const momentThreeYearsAgo = moment().subtract(3, `years`);

const endDateId = `report-builder-end`;
const startDateId = `report-builder-start`;

const DateRange = (props: DateRangeProps) => {
  const { title } = props;
  const [focus, setFocus] = useState(null);

  const context = useContext(RBContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const { chartDateEnd, chartDateStart } = state;

  const onChange = ({ startDate, endDate }) => {
    if (startDate) dispatch({ key: `chartDateStart`, value: startDate?.clone() });
    if (endDate) dispatch({ key: `chartDateEnd`, value: endDate?.clone() });
  };

  const isOutsideRange = date => {
    const tooNew = date.isAfter(momentCurrentEndOfWeek);
    const tooOld = date.isBefore(momentThreeYearsAgo);
    const isMonday = date.isoWeekday() === 1;

    const justRight = !tooNew && !tooOld && isMonday;

    return !justRight;
  };

  return (
    <nav className="report-builder__dates">
      {title && <h2>{title}</h2>}
      <DateRangePicker
        displayFormat="DD-MM-YYYY"
        endDate={chartDateEnd} // momentPropTypes.momentObj or null,
        endDateId={endDateId} // PropTypes.string.isRequired,
        firstDayOfWeek={1}
        focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        isOutsideRange={isOutsideRange}
        minimumNights={28}
        onDatesChange={dates => onChange(dates)} // PropTypes.func.isRequired,
        onFocusChange={focusedInput => setFocus(focusedInput)} // PropTypes.func.isRequired,
        startDate={chartDateStart} // momentPropTypes.momentObj or null,
        startDateId={startDateId} // PropTypes.string.isRequired,
      />
    </nav>
  );
};

export default DateRange;

import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { toast } from 'react-toastify';

// Particles
import { OMContext } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import PageRevisionModal from '../modal';

// Atoms
import { Button } from 'corigan';

// API
import { callUpdatePageRevision } from 'corigan';

declare type ButtonProps = {
  admin?: boolean;
  text?: string;
  editedText?: string;
  loadingText?: string;
  toastMessage?: string;
  modal?: boolean;
  modalTitle?: string;
  modalText?: string;
  modalConfirm?: string;
  modalComment?: boolean;
};

declare type UnlockButtonsProps = {
  isLocked: boolean;
  data: ButtonProps;
};

const UnlockButtons: React.FC<UnlockButtonsProps> = (props: UnlockButtonsProps) => {
  const { isLocked, data } = props;

  return <UnlockButton isLocked={isLocked} data={data} />;
};

declare type UnlockProps = {
  isLocked: boolean;
  data: ButtonProps;
};

const UnlockButton: React.FC<UnlockProps> = (props: UnlockProps) => {
  const { isLocked, data } = props;
  const { admin, modal, modalTitle, modalText, modalConfirm, modalComment, text, toastMessage } = data;
  const [confirming, setConfirming] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const context: any = useContext(OMContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;
  const loadedRevision: PageRevision = state?.loadedRevision;
  const queryCache = useQueryCache();

  // define lock / unlock mutation
  const [mutate, { error, isLoading: loading }] = useMutation(callUpdatePageRevision, {
    onMutate: () => { },
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      dispatch({ type: `set`, key: `error`, value: err });
    },
    onSettled: (data, error) => {
      toast.error(error, {})
    },
    onSuccess: (data: APIResponse, variables) => {
      setSuccess(true);
      setConfirming(false);
      if (toastMessage) {
        toast.info(toastMessage, {});
      }
    },
  });

  const handleSubmit = async e => {
    try {
      if (e) e.preventDefault();

      // Gatsby build check for window
      if (!windowAvailable()) return;

      const args = {
        id: loadedRevision.id,
        unlock: true,
        locked: false,
      };

      await mutate(args);

      queryCache.invalidateQueries([`callGetPage`]);
      dispatch({ type: `set`, key: `startedEdit`, value: false });
    } catch (error) {
      toast.error(error, {});
    }
  };

  const openConfirm = e => {
    e.preventDefault();
    setConfirming(true);
  };

  const buttonFunction = modal ? openConfirm : handleSubmit;

  return (
    <>
      {modal && confirming && (
        <PageRevisionModal
          title={modalTitle}
          text={modalText}
          confirmText={modalConfirm}
          modalComment={modalComment}
          setConfirming={setConfirming}
          success={success}
          handleSubmit={handleSubmit}
          loading={loading}
          error={error}
        />
      )}
      <Button onClick={buttonFunction}>
        {text}
      </Button>
    </>
  );
};

export default UnlockButtons;

import * as React from 'react';

// Particles
import { ROUTES } from 'corigan';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import PageContent from './content';

const MissingID = () => {
  const message = `Unfortunately we could not find a page as no ID was provided`;

  return (
    <PageContent loading={false}>
      <Error error={message} />
      <ReturnLink />
    </PageContent>
  );
};

const ReturnLink = () => (
  <p>
    Please return to the <Link href={ROUTES.mastersheet}>mastersheet page</Link>
  </p>
);

export default MissingID;

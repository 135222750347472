import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import moment from 'moment';

// Particles
import { ApplicationContext } from 'corigan';
import { brandColours } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { KRContext } from 'corigan';

// Components
import { ChartBar } from 'corigan';

// Local Particles
import generateSeriesGSC from './functions/generateSeriesGSC';

// Local Paritals
import HistoryView from './partials/view';
import { StyledCharts } from '../charts.styled';
import { StyledHistory } from '../charts.styled';

declare type KeywordHistoryProps = {
  volume: Boolean;
};

const momentToday = moment();
const momentYearAgo = moment().subtract(365, `days`);

const KeywordHistory = (props: KeywordHistoryProps) => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.hostname;
  const activeDomainBrand = domain?.replace(`www.`, ``)?.split(`.`)?.[0] as BrandName;

  // Get the colour of the active domain hostname if available
  const activeDomainColour = brandColours(activeDomainBrand);

  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;

  const keyword: Keyword = state?.keyword;
  const phrase: string = keyword?.phrase;
  const pages: PageRevision[] = state?.pages;
  const view: string = state?.historyView;

  const end: moment.Moment = momentToday;
  const start: moment.Moment = momentYearAgo;

  const isCTR: boolean = view === `ctr`;
  const isImpressions: boolean = view === `impressions`;

  const canLoad: string[] = state?.canLoad;
  const showShare: boolean = canLoad.includes(`share`);

  const { dates } = getWeeksBetween(start, end);

  const options: ObjectLiteral = useMemo(() => {
    return {
      xaxis: {
        type: `datetime`,
        categories: dates,
      },
    };
  }, [dates]);

  let seriesName: string = `Search CTR`;
  if (isImpressions) seriesName = `Search Impressions`;

  const ctrSeries: any[] = useMemo(() => {
    return generateSeriesGSC({ pages, view: `ctr` })?.filter(Boolean);
  }, [pages]);

  const impressionsSeries: any[] = useMemo(() => {
    return generateSeriesGSC({ pages, view: `impressions` })?.filter(Boolean);
  }, [pages]);

  const series: any[] = isImpressions ? impressionsSeries : ctrSeries;
  const hasSeries: boolean = series?.length > 0;
  const hasCTR: boolean = ctrSeries?.length > 0;
  const hasImpressions: boolean = impressionsSeries?.length > 0;

  const title: string = `Search Console Data`;
  const id: string = `keyword-history-${view}-chart`;

  const onLoadArea = useCallback(() => {
    if (showShare) return;
    if (dispatch) dispatch({ type: `enableLoading`, value: `share` });
  }, [dispatch, showShare]);

  const intLabels = (val: number): string => String(Math.round(val));
  const floatLabels = (val: number): string =>
    val?.toLocaleString(`en`, { style: `percent`, minimumFractionDigits: 2 });

  const ctrOptions: ChartOptions = useMemo(() => {
    const optionsObject: ChartOptions = {
      ...options,
      yaxis: {
        labels: {
          formatter: floatLabels,
        },
        seriesName,
      },
    };

    if (activeDomainColour) optionsObject.colors = [activeDomainColour];

    return optionsObject;
  }, [activeDomainColour, options, seriesName]);

  const impressionsOptions: ChartOptions = useMemo(() => {
    const optionsObject: ChartOptions = {
      ...options,
      yaxis: {
        labels: {
          formatter: intLabels,
        },
        seriesName,
      },
    };

    if (activeDomainColour) optionsObject.colors = [activeDomainColour];

    return optionsObject;
  }, [activeDomainColour, options, seriesName]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <StyledHistory className="history__navigation">
          <div className="history__phrase">
            <h2>{title}</h2>
            <HistoryView />
          </div>
        </StyledHistory>
        {!hasSeries && (
          <React.Fragment>
            <p className="mt-2">No GSC data was found for the enabled pages. </p>
            <p>Try another enabling an internal page.</p>
          </React.Fragment>
        )}
        <StyledCharts>
          {hasCTR && (
            <ChartBar
              className={isCTR ? `` : `hidden`}
              id={id}
              height="300px"
              options={ctrOptions}
              onLoad={onLoadArea}
              series={ctrSeries}
            />
          )}
          {hasImpressions && (
            <ChartBar
              className={isImpressions ? `` : `hidden`}
              id={id}
              height="300px"
              options={impressionsOptions}
              onLoad={onLoadArea}
              series={impressionsSeries}
            />
          )}
        </StyledCharts>
      </React.Fragment>
    );
  }, [
    phrase,
    title,
    id,
    ctrOptions,
    ctrSeries,
    hasCTR,
    hasImpressions,
    impressionsOptions,
    impressionsSeries,
    isCTR,
    isImpressions,
  ]);
};
export default KeywordHistory;

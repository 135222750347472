import { fetchAPI } from 'particles';

type CreateManyKeywordsArgs = {
  domain: string,
  keywords: {
    excludedFromCategories?: Category[];
    highPriority?: ArgHighPriority;
    keywordId: ArgKeywordId;
    phrase: ArgPhrase;
    priorityCategory?: Category;
    uplift?: ArgUplift;
    tags?: ArgTags;
    url: ArgURL;
  }[];
};

export const callCreateManyKeywords = async (
  args: CreateManyKeywordsArgs
): Promise<{ data: Keyword[]; pagination: Pagination }> => {
  const { domain, ...parameters } = args;
  const response = await fetchAPI({ method: `PUT`, parameters, route: `keywords?domain=${domain}` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreateManyKeywords;

import ROUTES from 'routes';

import type { NavigationItem } from './navigation.types';

export const sidebarItems: NavigationItem[] = [
  {
    href: ROUTES.dashboard,
    icon: `dashboard`,
    title: `Dashboard`,
  },
  {
    href: ROUTES.tags,
    icon: `label`,
    requiredPermissions: [`tags:create`, `tags:delete`, `tags:read`, `tags:update`],
    title: `Tags`,
  },
  {
    disabled: true,
    href: ROUTES.status,
    icon: `chart`,
    title: `Service Status`,
  },
  {
    disabled: true,
    href: ROUTES.help,
    icon: `help`,
    title: `Help Center`,
  },
  {
    href: ROUTES.page_types,
    icon: `pageview`,
    requiredPermissions: [`pagetypes:create`, `pagetypes:delete`, `pagetypes:read`, `pagetypes:update`],
    title: `Page Types`,
  },
  {
    href: ROUTES.teams,
    icon: `recentActors`,
    requiredPermissions: [`teams:create`, `teams:delete`, `teams:read`, `teams:update`],
    title: `Teams`,
  },
  {
    href: ROUTES.users,
    icon: `groups`,
    requiredPermissions: [`users:create`, `users:delete`, `users:read`, `users:update`],
    title: `Users`,
  },
  {
    href: ROUTES.domains,
    icon: `domains`,
    requiredPermissions: [`domains:create`, `domains:delete`, `domains:read`, `domains:update`],
    title: `Sites`,
  },
];

export default sidebarItems;

import { fetchAPI } from 'particles';

type GetOneTeamParameters = {
  id: ArgID;
  _with?: ArgWith;
};

export const callGetOneTeam = async (queryKey, parameters: GetOneTeamParameters): Promise<{ data: Team }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `teams` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetOneTeam;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { CRContext } from 'corigan';
import { getRelease } from 'corigan';
import { isUndefined } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Link, Table } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { DomainSelector } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetCategoryCounts } from 'corigan';

declare type CategoryTrackerPageProps = {
  children?: React.ReactNode;
};

const CategoryTracker: React.FC<CategoryTrackerPageProps> = (props: CategoryTrackerPageProps) => {
  return (
    <ProtectedRoute redirect={ROUTES.competitor_research} requiredPermissions={[`categories:read`]}>
      <Page application="competitor-research">
        <PageContents />
      </Page>
    </ProtectedRoute>
  );
};

const PageContents: React.FC = () => {
  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.competitor_research}>Competitor Research</Link>
            <h1>Category Tracker</h1>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card minHeight={false}>
            <DomainSelector id="domain-selector" competitorsOnly={false} margin={false} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CategoryTrackerTable />
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

/**
 * 'dbKey' property allows a column value to be passed to CrudTable.
 * The component will favour this property if it is present.
 */
export const columns = [
  {
    align: `left`,
    dbKey: `category.id`,
    filter: false,
    sort: false,
    hide: true,
    label: `ID`,
    numeric: false,
    value: `id`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `domain`,
    filter: true,
    sort: true,
    hide: true,
    label: `Site`,
    numeric: false,
    value: `domain`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `category.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Name`,
    numeric: false,
    value: `name`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `count`,
    filter: true,
    sort: true,
    hide: false,
    label: `Count`,
    numeric: true,
    value: `count`,
    wrap: false,
  },
];

declare type CategoryOverview = {
  category: CategoryObject;
  count?: number;
  createdAt: string;
  domain: string;
  id: string;
  schemaVersion: number;
};

declare type GenerateRowsArgs = {
  data: CategoryOverview[];
  domain: string;
};

const generateRows = (args: GenerateRowsArgs) => {
  const { data, domain } = args;
  if (!data || !domain) return [];

  const formattedData = data.map((categoryParent: CategoryOverview) => {
    const { count } = categoryParent;

    const category: CategoryObject = categoryParent?.category;
    const { name, id } = category;

    const countString: string = String(count);

    const item = {
      id,
      domain,
      name,
      count: countString,
    };

    return item;
  });

  return formattedData;
};

// TODO: Compare counts to previous weeks and flag percentage changes greater than X% based on total.

const CategoryTrackerTable: React.FC = () => {
  const [mounted, setMounted] = useState<boolean>(false);

  const context = useContext(CRContext);
  const state = context?.state;
  const domain: string = state?.competitor?.id;

  const { releaseVersion } = getRelease();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=crCategoryTrackerMastersheet-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Create initial API arguments
  const initialWhere: ArgWhere = undefined;
  const initialOrderBy: ArgOrderBy = undefined;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = [`category`];

  // Read local storage values
  const localOrderBy: ArgOrderBy = localStorageRead(buildKey(`OrderBy`))
    ? localStorageRead(buildKey(`OrderBy`))
    : initialOrderBy;
  const localPage: ArgPage = localStorageRead(buildKey(`Page`)) ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = localStorageRead(buildKey(`PerPage`))
    ? localStorageRead(buildKey(`PerPage`))
    : initialPerPage;
  const localWhere: ArgWhere = localStorageRead(buildKey(`Where`)) ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }

      return newValue;
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = (value: any) => setStateValue(`Page`, value);
  const setPerPage = (value: any) => setStateValue(`PerPage`, value);
  const setWhere = (value: any) => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const whereProtected: ArgWhere = undefined;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const queryFunction = callGetCategoryCounts;
  const queryName = `callGetCategoryCounts`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const enabled: boolean = !isUndefined(state?.competitor);

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination: Pagination = res?.pagination;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data, domain });
  }, [data, domain]);

  const csvTitle = `category-tracker-${domain}`;

  if (!mounted) return null;

  return (
    <Table
      apiArgs={apiArgs}
      apiDetails={apiDetails}
      apiFunctions={apiFunctions}
      allowFilters={true}
      collectionType="page"
      columns={columns}
      csvTitle={csvTitle}
      error={error}
      id="mastersheet-category-tracker"
      items={items}
      loading={loading}
      pagination={pagination}
      selectable={false}
      small={false}
      transfer={false}
    />
  );
};

export default CategoryTracker;

import * as React from 'react';
import { useQuery } from 'react-query';
import { slugify } from 'corigan';

// Particles
import { ApplicationContext } from 'corigan';
import { dateCreateUpdate } from 'corigan';
import { getRelease } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useLocalStorage } from 'corigan';

// Components
import { Link } from 'corigan';
import { Toggle } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';
import { callMe } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.mastersheet} requiredPermissions={[`pages:read`]}>
    <TasksPageParent />
  </ProtectedRoute>
);

type TasksPageParentProps = {};

const TasksPageParent = (props: TasksPageParentProps) => {
  const { releaseVersion } = getRelease();

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our initial parameters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const localKey = React.useMemo(() => {
    return `version=${releaseVersion}&key=om-tasks-show-mine`;
  }, [releaseVersion]);

  const [showMine, setShowMine] = useLocalStorage(localKey, false);

  const handleToggleChange = () => {
    setShowMine(!showMine);
  };

  return (
    <Page application="optimisation-manager" pageTitle="Tasks">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.optimisation_manager}>Dashboard</Link>
              <Link href={ROUTES.mastersheet}>Mastersheet</Link>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="display--flex align-items--center">
              <h1 className="mr-2">Tasks</h1>
              <Toggle
                className="breadcrumb__button"
                id="showMine"
                label="My Tasks Only"
                on={showMine}
                onChange={handleToggleChange}
                small={true}
              >
                Only show your tasks
              </Toggle>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xxl={4}>
            <TaskTable
              showMine={showMine}
              status="Rejected&where[currentRejected][eq]=true"
              title="Rejections"
              description="These pages have been rejected."
            />
          </Col>
          <Col lg={6} xxl={4}>
            <TaskTable
              showMine={showMine}
              status="Briefed&orderBy=updatedAt"
              title="Briefed"
              description="These pages have been briefed to the team."
            />
          </Col>
          <Col lg={6} xxl={4}>
            <TaskTable
              showMine={showMine}
              status="Awaiting Approval"
              title="Awaiting Approval"
              description="These pages have been submitted for approval."
            />
          </Col>
          <Col lg={6} xxl={4}>
            <TaskTable
              showMine={showMine}
              status="Ready to Implement"
              title="Ready to Implement"
              description="These pages have been approved and are ready to implement."
            />
          </Col>
          <Col lg={6} xxl={4}>
            <TaskTable
              showMine={showMine}
              status="Implemented"
              title="Implemented (Crawl Pending)"
              description=" These pages have been implemented and are awaiting a crawl to verify if live."
            />
          </Col>
          <Col lg={6} xxl={4}>
            <TaskTable
              showMine={showMine}
              status="Mismatched"
              title="Does Not Match"
              description="These pages do not match the latest crawl data."
            />
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

const _with = [`assignedTo`, `currentRevision`, `page`];

declare type TaskTableProps = {
  description: string;
  showMine: boolean;
  status: ArgStatus;
  title: string;
};

const TaskTable: React.FC<TaskTableProps> = (props: TaskTableProps) => {
  const me = useQuery([`callMe`], callMe);
  const currentUser = me?.data?.data;

  let content;
  const { description, showMine, status, title } = props;
  const slug: string = slugify(title);
  const classList: string = `table__row table--${slug}__row`;

  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const whereBase: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const whereStatus: ArgWhere = `where[status][eq]=${status}`;
  const whereAppend: ArgWhere = showMine ? `&where[assignedTo.name][eq]=${currentUser?.name}` : ``;

  const where: ArgWhere = `${whereBase}&${whereStatus}${whereAppend}`;

  const linkViewAll = { href: `${ROUTES.mastersheet}?${whereStatus}${whereAppend}`, title: `View all` };

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyPageRevisions`, { perPage: 8, where, _with }],
    callGetManyPageRevisions,
  );
  const data = res?.data;
  const hasData: boolean = data?.length > 0;

  const pagination = res?.pagination;
  const total = pagination?.total;
  const hasTotal: boolean = total > 0;

  const noIssues: boolean = !loading && (!data || data?.length === 0);

  if (noIssues) {
    content = (
      <tr className={classList}>
        <td colSpan={4} className="text--nowrap">
          No pages with for this status found
        </td>
      </tr>
    );
  }

  if (error) {
    content = (
      <tr className={classList}>
        <td>
          <Error error={error} />
        </td>
      </tr>
    );
  }

  if (hasData)
    content = data.map((pageRevision: PageRevision) => {
      const { pageId, name, createdAt, updatedAt } = pageRevision;
      const page: any = pageRevision.page;
      const id = page?.id;
      const key = `status-${id}`;
      const href = `${ROUTES.page}?id=${id}&status=${status}`;
      const changedFormatted = dateCreateUpdate({ createdAt, updatedAt });
      const assignedTo = pageRevision?.assignedTo?.name;

      return (
        <tr key={key} className={classList}>
          <td className="text--nowrap">
            <Link href={href}>{pageId}</Link>
          </td>
          <td className="text--nowrap">
            <Link href={href}>{name}</Link>
          </td>
          <td className="text--nowrap">
            <Link href={href}>{changedFormatted}</Link>
          </td>
          <td className="text--nowrap">{assignedTo}</td>
        </tr>
      );
    });

  let cardTitle: string = `${title}`;
  if (!hasTotal) cardTitle += ` (0)`;
  if (hasTotal) cardTitle += ` (${total})`;

  const hasLinkAll: boolean = hasTotal;
  const cardLink = hasLinkAll ? linkViewAll : null;

  return (
    <Card info={description} loading={loading} link={cardLink} title={cardTitle}>
      <div className="table--responsive">
        <table className="table table--small m-0" id={slug}>
          <thead>
            <tr>
              <th>Page ID</th>
              <th>Page</th>
              <th>Updated</th>
              <th>Assigned To</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    </Card>
  );
};

export default Wrapper;

import * as React from 'react';

function SvgAddBusiness(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M15.3 15.8h2.2v-3.3h1.1v-2.2l-1.1-5.5H1.1L0 10.4v2.2h1.1v6.5h9.8v-6.5h4.4v3.2zm-6.6 1.1H3.3v-4.4h5.5v4.4zM1.1 1.6h16.4v2.2H1.1z"
      />
      <path fill="currentColor" d="M20.7 16.9v-3.3h-2.2v3.3h-3.2v2.2h3.2v3.3h2.2v-3.3H24v-2.2z" />
    </svg>
  );
}

export default SvgAddBusiness;

import * as React from 'react';

function SvgBarcode(props) {
  return (
    <svg aria-hidden="true" viewBox="0 0 15.999 15.999" {...props}>
      <path d="M14 16V0h2v16zM0 16V0h2v16zm11-5V0h2v11zm-5 0V0h4v11zm-3 0V0h2v11z" fill="currentColor" />
    </svg>
  );
}

export default SvgBarcode;

declare type Args = {
  currentPermissions: Permission[];
  requiredPermission?: PermissionLevelName;
  requiredPermissions?: PermissionLevelName[];
};

declare type Res = {
  missingPermissions: PermissionLevelName[];
  userHasPermission: boolean;
};

export const hasPermission = (args: Args): Res => {
  const { currentPermissions, requiredPermission, requiredPermissions } = args;

  // Build an array of all permissions we currently have
  const currentPermissionNames = currentPermissions.map(p => p.name);

  // If we have a single permission, look to see if we have that in our current permissions
  if (requiredPermission) {
    const userHasPermission: boolean = currentPermissionNames.includes(requiredPermission);
    const missingPermissions = userHasPermission ? [] : [requiredPermission];
    return { missingPermissions, userHasPermission };
  }

  const hasRequiredPermissions: boolean = requiredPermissions?.length > 0;
  if (!hasRequiredPermissions) return { missingPermissions: [], userHasPermission: true };

  // Otherwise check if we have all the permissions requested
  // We use filter instead of every because we need to record the missed permissions
  const missingPermissions = requiredPermissions.filter(name => {
    const match: boolean = currentPermissionNames.includes(name);
    return !match;
  });

  const userHasPermission: boolean = missingPermissions?.length === 0;

  return { missingPermissions, userHasPermission };
};

export default hasPermission;

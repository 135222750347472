import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';

// Particles
import { isAnObject } from 'corigan';
import { OMContext } from 'corigan';

// Atoms
import { Chip } from 'corigan';

type Props = {
  isLocked?: boolean;
};

const CurrentCategories: React.FC<Props> = (props: Props) => {
  const { isLocked } = props;

  const context = useContext(OMContext);
  const state = context?.state;
  const editedRevision: PageRevision = state.editedRevision;
  const categories = editedRevision?.preferredCategories;
  const hasCategories: boolean = categories?.length > 0;

  return (
    <React.Fragment>
      <h3>Add Preferred Categories</h3>
      {useMemo(() => {
        if (!hasCategories) return <p className="mt-1">No categories selected</p>;

        return <CategoryChips categories={categories} isLocked={isLocked} />;
      }, [isLocked, categories, hasCategories])}
    </React.Fragment>
  );
};

declare type CategoryChipsProps = {
  categories: CategoryObject[];
  isLocked: boolean;
};

const CategoryChips: React.FC<CategoryChipsProps> = (props: CategoryChipsProps) => {
  const { categories, isLocked } = props;

  const context = useContext(OMContext);
  const state = context?.state;
  const dispatch = context?.dispatch;

  const editedRevision: PageRevision = state.editedRevision;

  const handleClick = useCallback(
    (e, id) => {
      if (e) e.preventDefault();
      const current = editedRevision?.preferredCategories;

      // If already exists in state, ignore the function
      const exists = current.some(category => category.id === id);
      if (!exists) return;

      // Create new array with removed keyword
      const newCategories = current.filter(category => category.id !== id);
      dispatch({ key: `preferredCategories`, value: newCategories });
    },
    [dispatch, editedRevision],
  );

  const hasCategories: boolean = categories?.length > 0;
  if (!hasCategories) return null;

  const validCategories = categories.filter(category => isAnObject(category));
  const hasValidCategories: boolean = validCategories?.length > 0;
  if (!hasValidCategories) return null;

  const variant = !isLocked ? null : `primary`;

  const currentCategories = validCategories.map(category => {
    const { id, name } = category;

    const key = `category-current-${id}`;
    let onClick = null;
    if (!isLocked) onClick = e => handleClick(e, id);

    return (
      <Chip hasRemove={true} key={key} onClick={onClick} variant={variant}>
        {name}
      </Chip>
    );
  });

  return <section className="chip-spacer mt-1">{currentCategories}</section>;
};

export default CurrentCategories;

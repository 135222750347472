import styled from 'styled-components';

const StyledDashboard = styled.span`
  header,
  nav {
    align-items: center;
    display: flex;
  }

  header {
    margin-bottom: 12px;
    justify-content: space-between;

    label {
      margin: 0;
      margin-right: 12px;
    }
  }
`;

export default StyledDashboard;

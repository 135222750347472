import React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { hasPermission } from 'corigan';
import { parseHTML } from 'corigan';
import { useLocalStorage } from 'corigan';
import { useMyPermissions } from 'corigan';

// Atoms
import { Link } from 'corigan';
import { AddBusiness, AccountTree, Assignment, BarChart, Barcode, Cogs, CompareArrows, Domains } from 'icons';
import { Construction, Dashboard, EmojiEvents, Find, Groups, Help, Key } from 'icons';
import { Label, LibraryBooks, List, ListBulleted, Mail } from 'icons';
import { Map as MapIcon, Menu, Pageview, PeopleAlt } from 'icons';
import { Rankings, RecentActors, ShoppingCart, Store, SystemUpdateAlt, TaskAlt, Visibility, Weekend } from 'icons';

// CSS
import StyledSidebar from './sidebar.styles';

// Navigation items
import poItems from './navigation';
import crItems from './navigation/competitor-research';
import kmItems from './navigation/keyword-manager';
import krItems from './navigation/keyword-research';
import omItems from './navigation/optimisation-manager';
import rbItems from './navigation/report-builder';

// Local Partials
import ApplicationLabel from './partials/application-label';

// Assign SVGs to object with named keys
const Icons = {
  addBusiness: AddBusiness,
  accountTree: AccountTree,
  assignment: Assignment,
  barcode: Barcode,
  cart: ShoppingCart,
  chart: BarChart,
  cogs: Cogs,
  compareArrows: CompareArrows,
  construction: Construction,
  dashboard: Dashboard,
  emojiEvents: EmojiEvents,
  find: Find,
  help: Help,
  groups: Groups,
  key: Key,
  label: Label,
  libraryBooks: LibraryBooks,
  list: List,
  listBulleted: ListBulleted,
  mail: Mail,
  map: MapIcon,
  pageview: Pageview,
  recentActors: RecentActors,
  save: SystemUpdateAlt,
  sofa: Weekend,
  store: Store,
  rankings: Rankings,
  task: TaskAlt,
  visibility: Visibility,
  users: PeopleAlt,
  domains: Domains,
};

type SidebarProps = {
  /**
   * The slug of the application the sidebar is included in
   */
  application: ApplicationName;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
};

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { application, className } = props;
  const [localSidebar, setLocalSidebar] = useLocalStorage(`sidebarOpen`, true);
  const [isOpen, setOpen] = useState(localSidebar);
  const [items, setItems] = useState([]);

  const permissions = useMyPermissions();

  const filterOutUnavailable = useCallback(
    navigationItem => {
      const { requiredPermissions } = navigationItem;
      const hasRequiredPermissions = requiredPermissions?.length > 0;
      if (!hasRequiredPermissions) return true;
      const { userHasPermission } = hasPermission({ currentPermissions: permissions, requiredPermissions });
      return userHasPermission;
    },
    [permissions],
  );

  useEffect(() => {
    let sidebarItems = poItems;

    switch (application) {
      case `portal`:
        sidebarItems = poItems.filter(filterOutUnavailable);
        break;
      case `competitor-research`:
        sidebarItems = crItems.filter(filterOutUnavailable);
        break;
      case `keyword-manager`:
        sidebarItems = kmItems.filter(filterOutUnavailable);
        break;
      case `keyword-research`:
        sidebarItems = krItems.filter(filterOutUnavailable);
        break;
      case `optimisation-manager`:
        sidebarItems = omItems.filter(filterOutUnavailable);
        break;
      case `report-builder`:
        sidebarItems = rbItems.filter(filterOutUnavailable);
        break;
      default:
        sidebarItems = poItems.filter(filterOutUnavailable);
        break;
    }

    setItems(sidebarItems);
  }, [application, filterOutUnavailable, permissions]);

  const context = useContext(ApplicationContext);

  const state = context?.state;
  const title = state?.sidebar?.title;
  const hasItems = items && items.length > 0;

  const handleClick = e => {
    e.preventDefault();
    setLocalSidebar(!localSidebar);
    setOpen(!isOpen);
  };

  let classList = `sidebar`;
  if (isOpen) classList += ` sidebar--open`;
  if (className) classList += ` ${className}`;

  return (
    <StyledSidebar className={classList}>
      <header>
        <a className="hidden" href="#content">
          Skip to content
        </a>
        <Link href="/">
          <h3>{title}</h3>
        </Link>
        <button onClick={handleClick}>
          <Menu />
          {` `}
          <span>
            {isOpen ? `Close` : `Open`} <span>Menu</span>
          </span>
        </button>
      </header>
      <ApplicationLabel application={application} />
      {hasItems && (
        <nav>
          <ul>
            {items.map(item => (
              <SidebarItem {...item} key={item.title} />
            ))}
          </ul>
        </nav>
      )}
    </StyledSidebar>
  );
};

type SidebarItemProps = {
  disabled?: boolean;
  href: string;
  icon: string;
  isOpen: boolean;
  target?: string;
  title: string;
};

const SidebarItem = ({ disabled, href, icon, target, title }: SidebarItemProps) => {
  const className = ``;
  if (disabled) return null;

  const parsedTitle = parseHTML(title);

  return (
    <li className={className}>
      <Link href={href} target={target}>
        <SidebarIcon name={icon} />
        {parsedTitle}
      </Link>
    </li>
  );
};

type SidebarIconProps = {
  name: string;
};

const SidebarIcon = ({ name }: SidebarIconProps) => {
  // If icon name value doesn't match Icons object keys then return null
  if (Icons[name] === undefined) return null;

  // If icon found, return the icon in a span element
  const Icon = Icons[name];
  return (
    <span className="button__icon">
      <Icon />
      <span className="hidden">{name} icon</span>
    </span>
  );
};

export default Sidebar;

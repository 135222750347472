import * as React from 'react';

// Particles
import { parseMarkdown } from 'corigan';
import { ProtectedRoute } from 'corigan';

// Components
import { Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { ApplicationItems } from 'corigan';
import { Timeline } from 'corigan';
import { Page } from 'corigan';

declare type HomepageProps = {
  changelog: string;
  news: string;
};

const Home: React.FC<HomepageProps> = (props: HomepageProps) => {
  const { changelog, news } = props;

  const hasNews: boolean = news?.length > 0;
  const hasChangeLog: boolean = changelog?.length > 0;

  return (
    <ProtectedRoute>
      <Page application="portal">
        <Grid>
          <Row>
            <Col>
              <h1>Portal</h1>
            </Col>
          </Row>
          <Row>
            <Col xl={9} xxl={6}>
              <Card minHeight={false} padding={false}>
                <ApplicationItems />
              </Card>
            </Col>
          </Row>
          <Row>
            {hasChangeLog && (
              <Col xs={12} xl={6}>
                <Card minHeight={false}>
                  <ChangelogTimeline changelog={changelog} />
                </Card>
              </Col>
            )}
            {hasNews && (
              <Col xs={12} xl={6}>
                <Card minHeight={false}>
                  <NewsTimeline news={news} />
                </Card>
              </Col>
            )}
          </Row>
        </Grid>
      </Page>
    </ProtectedRoute>
  );
};

declare type ChangelogTimelineProps = {
  changelog: string;
};

const ChangelogTimeline: React.FC<ChangelogTimelineProps> = (props: ChangelogTimelineProps) => {
  const { changelog } = props;
  const contents = parseMarkdown(changelog);
  return <Timeline items={contents} title="Changelog" />;
};

declare type NewsTimelineProps = {
  news: string;
};

const NewsTimeline: React.FC<NewsTimelineProps> = (props: NewsTimelineProps) => {
  const { news } = props;
  const contents = parseMarkdown(news);
  return <Timeline items={contents} title="News" />;
};

export default Home;

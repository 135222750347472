import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Grid, Row, Col } from 'corigan';

type CrawlContentProps = {
  children: React.ReactNode;
  data: Crawl;
  title: string;
  subtitle?: string;
};

const CrawlContent: React.FC<CrawlContentProps> = (props: CrawlContentProps) => {
  const { children, title, subtitle } = props;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.crawls}>All Crawls</Link>
            <h1>{title}</h1>
          </Breadcrumbs>
          <h2 className="lowercase">{subtitle}</h2>
        </Col>
      </Row>
      {children}
    </Grid>
  );
};

export default CrawlContent;

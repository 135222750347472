import styled from 'styled-components';
import { shouldBeDark } from 'particles';

const StyledRevisions = styled.table`
  table-layout: fixed;

  td > button {
    /* Button Reset CSS */
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    text-decoration: underline;
    text-transform: capitalize;
  }

  .button--delete {
    opacity: 0.5;

    &:active,
    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  .revision--archived {
    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--grey100)`)};

    &:active,
    &:focus,
    &:hover {
      background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--grey100)`)};
    }
  }

  .table-overflow {
    overflow: auto;
  }

  .table-load {
    width: 4em;
  }

  .table-date {
    width: 11em;
  }

  .table-author {
    width: 10em;
  }

  .table-status {
    width: 12em;
  }

  .table-delete {
    width: 5em;
  }
`;

export default StyledRevisions;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';

import { createEditorState } from 'corigan';
import { generateID } from 'corigan';

// Atoms
import { Editor } from 'corigan';
import { SavedContent } from 'corigan';
import { HR } from 'corigan';

// Molecules
import { Keys } from 'corigan';
import { Loader } from 'corigan';

import EditorWrapper from './editor.styles';

import type { RawDraftContentState } from 'draft-js';
import { convertToRaw } from 'draft-js';

import { statuses } from 'particles';

type PageEditorProps = {
  isLocked: boolean;
};

const PageEditor: React.FC<PageEditorProps> = (props: PageEditorProps) => {
  const { isLocked } = props;

  const context = useContext(OMContext);
  const state = context?.state;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const linkedWordsEnable: boolean = domainActive?.config?.linkedWordsEnable ?? true;

  const editedRevision: PageRevision = state?.editedRevision;
  const contentBlocks = editedRevision?.contentBlocks;
  const hasBlocks: boolean = contentBlocks?.length > 0;

  return (
    <React.Fragment>
      <EditorWrapper className="page__editors">
        {!hasBlocks && (
          <div className="page__editors__empty">
            <p>No blocks currently exist for this page type.</p>
          </div>
        )}
        {hasBlocks && (
          <React.Fragment>
            <Keys id="omEditorKeys" title="Colours in the Editor">
              <li>
                <span className="page__editors__key__red">Red Text</span> - Detected keyword from current list
              </li>
              <li>
                <span className="page__editors__key__highlight page__editors__key__highlight--red">Red Highlight</span>
                {` `}- Same keyword linked twice
              </li>
              <li>
                <span className="page__editors__key__highlight page__editors__key__highlight--orange">
                  Orange Highlight
                </span>
                {` `}- Same URL linked twice, different keywords
              </li>
            </Keys>
            {contentBlocks.map((block, i) => {
              const contentBlock: any = block?.contentBlock;
              const id = contentBlock?.id;
              const key = `block-${id}`;

              return <SingleEditor block={block} i={i} key={key} isLocked={isLocked} linkedWordsEnable={linkedWordsEnable} />;
            })}
          </React.Fragment>
        )}
      </EditorWrapper>
    </React.Fragment>
  );
};

declare type SingleEditorProps = {
  block: any;
  i: number;
  isLocked: boolean;
  linkedWordsEnable: boolean;
};

const SingleEditor: React.FC<SingleEditorProps> = (props: SingleEditorProps) => {
  const { block, i, isLocked, linkedWordsEnable } = props;
  const omContext: any = useContext(OMContext);
  const dispatch: any = omContext?.dispatch;
  const state: any = omContext?.state;
  const editedRevision: PageRevision = state.editedRevision;
  const loadedRevision: PageRevision = state.loadedRevision;
  const keywords = editedRevision?.keywords;
  const linkedWords = editedRevision?.linkedWords;
  const { firstLoad } = editedRevision;

  const isFirst: boolean = i === 0;
  const contentBlock: any = block?.contentBlock;
  const cssSelector: string = contentBlock?.cssSelector;
  const id: string = contentBlock?.id;
  const name: string = contentBlock?.name;

  let headingClass: string = `mb-2 mt-4`;
  if (isFirst) headingClass = `mb-2 mt-2`;

  let className: string = `page__editor`;
  if (cssSelector) className += ` ${cssSelector}`;
  const [editorState, setEditorState] = useState<any>(null);

  const parseBlock = useCallback(
    contentBlock => {
      const { content, _id, contentBlock: block } = contentBlock;

      const contentDecoded: RawDraftContentState = contentBlock?.contentDecoded;
      const contentEncoded: RawDraftContentState = contentBlock?.contentEncoded;

      const key: string = _id ? _id : generateID();
      const text: string = content ? content : ``;
      const savedContent: SavedContent = { key, text };

      const newEditorState = createEditorState({ contentDecoded, contentEncoded, keywords, linkedWords, savedContent, firstLoad });

      const currentContent = newEditorState.getCurrentContent();
      const encodedContent = convertToRaw(currentContent);
      const newText = currentContent.getPlainText();

      const value = { content: newText, encodedContent, id: block?.id };
      dispatch({ key: `contentBlocks`, value });
      dispatch({ type: `set`, key: `startedEdit`, value: false });

      return newEditorState;
    },
    [dispatch, firstLoad, keywords, linkedWords],
  );

  useEffect(() => {
    if (!block) {
      setEditorState({
        editorState: createEditorState({ keywords, linkedWords }),
        valid: true,
      });
    }

    if (block) {
      const editorState = parseBlock(block);

      setEditorState({
        editorState,
        valid: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedRevision]);

  const handleFontChange = (): void => { };

  const handleChange = (content: any, encodedContent?: any): void => {
    if (!content) return;

    const value = { content, encodedContent, id };

    dispatch({ key: `contentBlocks`, value });
  };

  const loading: boolean = !editorState;
  const status: ArgStatus | string | null = editedRevision?.status;

  const locked: boolean = useMemo(() => {
    const lowerStatus = status?.toLowerCase();
    const isAwaiting = lowerStatus === statuses.awaitingApproval.toLowerCase();
    const isReady = lowerStatus === statuses.readyToImplement.toLowerCase();
    const isImplemented = lowerStatus === statuses.implemented.toLowerCase();

    return isLocked || isAwaiting || isReady || isImplemented;
  }, [isLocked, status]);

  return (
    <section className={className}>
      {loading && <Loader />}
      {!loading && (
        <React.Fragment>
          <h3 className={headingClass}>{name}</h3>
          <Editor
            editorState={editorState}
            handleChange={handleChange}
            handleFontChange={handleFontChange}
            keywords={keywords}
            linkedWords={linkedWords}
            locked={locked}
            setEditorState={setEditorState}
            linkedWordsEnable={linkedWordsEnable}
          />
          <HR background="#d3d3d3" className="mb-4" />
        </React.Fragment>
      )}
    </section>
  );
};

export default PageEditor;

import { css } from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const table = css`
  .table--responsive {
    min-height: 0.01%;
    overflow-x: auto;
  }

  .table--responsive {
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;

    tbody,
    tfoot,
    thead {
      > tr {
        > td,
        > th {
          /* white-space: nowrap; */
        }
      }
    }
  }

  .table__cell--wide {
    min-width: 200px;
  }

  table:not([role='presentation']) {
    border-collapse: collapse;
    margin: 24px 0;
    max-width: 100%;
    width: 100%;

    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    font-size: 1.6rem;
    -moz-font-feature-settings: “tnum”;
    -webkit-font-feature-settings: “tnum”;
    font-feature-settings: “tnum”;
    font-variant-numeric: tabular-nums;
    text-align: left;

    caption {
      padding: ${props => props.theme.spacingDefault + ` ` + props.theme.spacingSmall};
      background-color: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey700)`)};
      color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
      font-weight: 700;
      text-align: left;
    }

    td,
    th {
      padding: 16px;

      &:first-of-type {
        padding-left: 4px;
      }

      &:last-of-type {
        padding-right: 8px;
      }
    }

    td {
      vertical-align: top;
    }

    th {
      padding: 4px 16px;

      background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `#f5f6fa`)};
      color: ${() => (shouldBeDark() ? `var(--grey100)` : `#a3a6b4`)};
      font-weight: bold;
      white-space: nowrap;
    }

    tr th[scope='row'] {
      letter-spacing: initial;
      text-transform: initial;
    }

    tr {
      background-color: ${() => (shouldBeDark() ? `var(--grey800)` : `var(--white)`)};
      border-top: 1px solid ${() => (shouldBeDark() ? `var(--grey600)` : `var(--grey200)`)};

      transition: background-color 0.2s ease;

      &:active,
      &:focus,
      &:hover {
        background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `#f5f6fa`)};

        .table__actions {
          opacity: 1;
        }
      }
    }

    .tr--grouped--last {
      border-bottom: 1px solid ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey500)`)};
    }
  }

  .table__actions {
    white-space: nowrap;

    @media ${device?.lg} {
      opacity: 0.2;
      transition: 0.4s all ease;
    }

    button {
      /* Button Reset CSS */
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: relative;
      width: auto;
      overflow: visible;

      background: transparent;
      border: none;
      box-shadow: none;
      color: inherit;
      font: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: normal;
      text-decoration: none;
      text-transform: inherit;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
    }

    a,
    button {
      display: inline-block;

      cursor: pointer;

      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;
      }

      + a,
      + button {
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid ${() => (shouldBeDark() ? `var(--grey900)` : `var(--grey200)`)};
      }
    }
  }

  .table__currency {
    text-align: right;
  }

  body {
    .table--small,
    .table--small table {
      font-size: 1.2rem;
    }

    .table--small {
      td,
      th {
        padding: 8px;
      }

      th {
        padding: 4px 8px;
      }
    }
  }
`;

export default table;

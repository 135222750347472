import * as React from 'react';
import { deepMerge } from 'corigan';
import { useInView } from 'react-intersection-observer';
import { default as loadable } from '@loadable/component';

import baseOptions from './options.json';

import StyledChartArea from './area.styles';

type ChartAreaProps = {
  height?: string | number;
  id: string;
  /**
   * Allows for base options JSON configuration to be overwritten with any
   * values defined in a JSON object passed to the 'options' prop.
   */
  options?: any;
  /**
   * Accepts an array of series values, used to plot data on an Apex Chart
   */
  series: Array<any>;
  width?: string | number;
};

export const ChartArea = (props: ChartAreaProps) => {
  const { id, height, options: overwrite, series, width } = props;

  let options = { ...baseOptions };
  if (overwrite) options = deepMerge(options, overwrite);
  if (id) options.chart.id = id;

  const classList = `chart--area`;

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `450px 0px 0px 0px`,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <StyledChartArea className={classList} {...props}>
        {inView && <LoadableChart height={height} options={options} series={series} type="area" width={width} />}
      </StyledChartArea>
    </div>
  );
};

ChartArea.defaultProps = {
  height: 350,
  width: `100%`,
};

export default ChartArea;

// import react-apexcharts here
const LoadableChart = loadable(() => import(`../../../../../../../node_modules/react-apexcharts/src/react-apexcharts`));

const shouldBeDark = () => {
  const isStorybook: boolean = process.env.STORYBOOK_ENABLED === `true`;

  let enableDark = process.env.GATSBY_DARK_ALLOWED === `true`;
  if (isStorybook) enableDark = process.env.STORYBOOK_DARK_ALLOWED === `true`;

  const windowAvailable = typeof window !== `undefined`;
  if (!windowAvailable) return false;

  const prefersDark = enableDark && window?.matchMedia(`(prefers-color-scheme: dark)`)?.matches;
  return false;
};

export default shouldBeDark;

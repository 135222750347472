import * as React from 'react';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Button, Link } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page as PageTemplate } from 'corigan';

// Localised Components
import CategoryIssues from './partials/tables/category-issues';
import RankingsBest from './partials/tables/best';
import RankingsWorst from './partials/tables/worst';
import Revisions from './partials/tables/revisions';
import StatusIssues from './partials/tables/issues';

const Wrapper = () => {
  return (
    <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`pages:read`]}>
      <OptimisationManager />
    </ProtectedRoute>
  );
};

type OptimisationManagerProps = {};

const OptimisationManager = (props: OptimisationManagerProps) => {
  const { userHasPermission: canCreate } = useHasPermissions({ requiredPermissions: [`pages:create`] });

  return (
    <PageTemplate application="optimisation-manager">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Portal Dashboard</Link>
              <h1>Optimisation Manager</h1>
              {canCreate && <Button href={ROUTES.page_create}>Create a Page</Button>}
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xxl={12}>
            <StatusIssues />
          </Col>
          <Col lg={12} xxl={12}>
            <Revisions />
          </Col>
          <Col lg={6} xxl={6}>
            <RankingsBest />
          </Col>
          <Col lg={6} xxl={6}>
            <RankingsWorst />
          </Col>
        </Row>
        {/* <Row>
          <Col lg={6} xxl={7}>
            <CategoryIssues />
          </Col>
        </Row> */}
      </Grid>
    </PageTemplate>
  );
};

export default Wrapper;

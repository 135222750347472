import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { default as loadable } from '@loadable/component';

// Particles
import { deepMerge } from 'corigan';

// Components
import { Skeleton } from 'corigan';

// Localised Particles
import baseOptions from './stacked-area.json';

declare type StackedAreaProps = {
  className?: string;
  colours?: string[];
  height?: string | number;
  id: string;
  onLoad?: Function;
  options?: ChartOptions;
  series: any[];
};

export const StackedArea: React.FC<StackedAreaProps> = (props: StackedAreaProps) => {
  const [loaded, setLoaded] = useState(false);
  const { className, colours, id, onLoad, options: overwrite, series } = props;
  const hasSeries = series?.length > 0;

  const windowAvailable = typeof window !== `undefined`;

  // @ts-ignore
  const ApexCharts = window?.ApexCharts;

  let options: ChartOptions = { ...baseOptions };

  options = deepMerge(options, {
    chart: {
      events: {
        beforeMount: () => {
          setLoaded(true);
        },
      },
    },
    xaxis: {
      labels: {
        format: `MMM 'yy`
      }
    }
  });

  useEffect(() => {
    if (onLoad) onLoad();
  }, [loaded, onLoad]);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `450px 0px 0px 0px`,
    triggerOnce: true,
  });

  // Will force the state to be loaded if no method call from ApexCharts
  useEffect(() => {
    const timeoutID =
      windowAvailable &&
      window.setTimeout(() => {
        setLoaded(true);
      }, 1000);

    return () => windowAvailable && window.clearTimeout(timeoutID);
  }, [inView, windowAvailable]);

  useEffect(() => {
    if (!hasSeries) return;
    const updateChartAxis = ApexCharts?.exec && overwrite?.xaxis?.categories?.length > 0;
    if (updateChartAxis) {
      try {
        ApexCharts.exec(id, `updateOptions`, {
          xaxis: {
            categories: overwrite?.xaxis?.categories,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [ApexCharts, hasSeries, id, overwrite.xaxis.categories]);

  if (!hasSeries) return null;

  if (overwrite) options = deepMerge(options, overwrite);
  if (id) options.chart.id = id;
  if (colours) options.colors = colours;

  const hasDefinedFormatter = overwrite?.yaxis?.labels?.formatter;
  const hasLabels = options?.yaxis?.labels;
  if (!hasDefinedFormatter && hasLabels) {
    options.yaxis.labels.formatter = (val: any) => {
      const numberVal = Number(val);
      return Intl.NumberFormat(`en-GB`, { notation: `compact`, compactDisplay: `short` }).format(numberVal);
    };
  }

  return (
    <div className={className} ref={ref}>
      {inView && (
        <React.Fragment>
          {!loaded && <Skeleton height={350} />}
          <LoadableChart height={loaded ? 350 : 1} options={options} series={series} type="area" />
        </React.Fragment>
      )}
    </div>
  );
};

export default StackedArea;

// import react-apexcharts here
const LoadableChart = loadable(() => import(`../../../../../../../node_modules/react-apexcharts/src/react-apexcharts`));

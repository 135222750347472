import * as React from 'react';
import { useQuery } from 'react-query';

// Molecules
import { ChartPie } from 'corigan';
import { Error } from 'corigan';
import { Card } from 'corigan';

// API
import { callGetCrawlCategories } from 'corigan';

declare type PieCategoriesProps = {
  crawl: Crawl;
  error?: any;
  loading?: boolean;
};

const PieCategories = (props: PieCategoriesProps) => {
  const { crawl } = props;
  const hasCrawl = Boolean(crawl);

  const { data, error, isLoading: loading } = useQuery(
    [`callGetCrawlCategories`, { crawlId: crawl?.id }],
    callGetCrawlCategories,
    {
      enabled: hasCrawl,
      retry: 1, // Will retry failed requests 1 time
    },
  );

  const determining = props?.loading || loading;
  const hasData: boolean = data?.length > 0;

  const chartData = React.useMemo(() => {
    if (!hasData) return null;

    const max: number = 20;
    const reachedMax = data?.length > max;

    const entriesTopMax: any[] = data
      .slice(0, max)
      .sort((a, b) => b?.count - a?.count)
      .filter(({ name }) => name !== `browse/*`); // Get first max count arrays
    const entriesOther: any[] = reachedMax ? data.slice(max) : []; // Group the remaining
    const hasOtherEntries = entriesOther?.length > 0;

    const countTotal = data.reduce((total, current) => total + current?.count, 0);

    // Labels as an array of valid strings
    const labels: string[] = entriesTopMax.map(category => category.name).filter(Boolean);
    const hasLabels = labels?.length > 0;

    // Options used to determine the axis labels for the pie chart
    let options = {};

    if (hasLabels) {
      const allLabels = hasOtherEntries ? [...labels, `Other`] : labels;

      options = {
        labels: allLabels,
        legend: {
          position: `right`,
          horizontalAlign: `left`,
        },
        tooltip: {
          y: {
            formatter: value => {
              if (!countTotal) return value;

              const valueNumber = Number(value);
              const stringValue = value;
              const valuePercentage = valueNumber / countTotal;
              const stringPercentage = valuePercentage?.toLocaleString(`en`, {
                style: `percent`,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });

              const text = `${stringValue} (${stringPercentage})`;
              return text;
            },
          },
        },
      };
    }

    const primarySeries: number[] = entriesTopMax.map(category => category?.count).filter(Boolean);
    const otherSeries: any = reachedMax ? entriesOther.reduce((a, b) => a?.count + b?.count, 0) : 0;

    let series = [];
    const hasPrimary = primarySeries?.length > 0;
    const hasOther = otherSeries > 0;

    if (hasPrimary) series = [...primarySeries];
    if (hasOther && hasPrimary) series = [...primarySeries, ...otherSeries];

    return { options, series };
  }, [hasData, data]);

  if (!hasData && !determining) return null;

  const title = `Site Categories`;

  return (
    <Card loading={determining} minHeight={false}>
      <h2 className="mb-3">{title}</h2>
      {error && <Error error={error} />}
      {chartData && (
        <ChartPie id="competitor-site-categories-pie" options={chartData.options} series={chartData.series} />
      )}
    </Card>
  );
};

export default PieCategories;

import * as React from 'react';

function SvgLabel(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M18.5 4.2c-.5-.6-1.2-1.1-2.1-1.1H2.5C1.1 3.2 0 4.3 0 5.7v12.6c0 1.4 1.1 2.5 2.5 2.5h13.9c.8 0 1.6-.4 2.1-1.1L24 12l-5.5-7.8z"
      />
    </svg>
  );
}

export default SvgLabel;

import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { toast } from 'react-toastify';

// Particles
import ROUTES from 'routes';
import { ApplicationContext } from 'corigan';
import { removeSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Button, Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';

// API
import { callDeleteUser } from 'corigan';
import { callMe } from 'corigan';

type UserContentProps = {
  children?: React.ReactNode;
  data?: any;
  loading: boolean;
  title: string;
};

const UserContent: React.FC<UserContentProps> = (props: UserContentProps) => {
  const { children, data: dataArray, loading, title } = props;
  const data = dataArray?.[0];
  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const id = data?.id;

  const me = useQuery([`callMe`], callMe);
  const currentUser = me?.data?.data;

  const { userHasPermission: canDeleteUser } = useHasPermissions({ requiredPermissions: [`users:delete`] });

  useEffect(() => {
    document.title = `Corigan - Portal: User - ${title}`;
  }, [title]);

  const [mutate, { isLoading: mutateLoading }] = useMutation(callDeleteUser, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(err, {});

      queryCache.invalidateQueries([`callGetManyUsers`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const userData: User = data?.data;
      const id = userData?.id;

      // Parameters for single user query
      const singleWith = [`roles`, `teams`];
      const singleWhere: ArgWhere = `[_id][eq]=${id}`;

      // Parameters for inital all users query
      const mastersheetPerPage = 10;
      const mastersheetWith: ArgWith = [`roles`, `teams`, `teams.domains`];

      // Optimistically update to the new value for mastersheet page
      queryCache.setQueryData(
        [`callGetManyUsers`, { perPage: mastersheetPerPage, _with: mastersheetWith }],
        (previousUsers: APIResponse) => removeSingleCache(previousUsers, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyUsers`, { _with: singleWith, where: singleWhere }],
        (previousUsers: APIResponse) => removeSingleCache(previousUsers, id),
      );

      // Redirect to directory of users
      navigate(ROUTES.users);
    },
  });

  const deleteUser = async () => {
    // If no ID is available, function can't run
    if (!id) return;
    if (!windowAvailable()) return;

    // Check their permission level
    if (!canDeleteUser) {
      window.alert(`You do not have permission to delete users`);
      return;
    }

    try {
      // Use delete user hook
      await mutate({ id });
    } catch (error) {
      console.error(error);
    }
  };

  const isLoading: boolean = loading || mutateLoading;
  const viewingMyself: boolean = currentUser?.id === id;
  const disabled: boolean = viewingMyself || isLoading;

  let buttonText: string = `Delete User`;
  if (mutateLoading) buttonText = `Deleting User`;
  if (viewingMyself) buttonText = `Cannot Delete`;

  const handleConfirm = async () => await deleteUser();

  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Deleting a keyword is permanent, are you sure you want to delete the selected keyword?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  };

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.dashboard}>Dashboard</Link>
            <Link href={ROUTES.users}>Users</Link>
            <h1>{title}</h1>
            {canDeleteUser && (
              <Button className="breadcrumb__button" disabled={disabled} onClick={handleClick} variant="hollow">
                {buttonText}
              </Button>
            )}
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card loading={isLoading}>{children}</Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default UserContent;

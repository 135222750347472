import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';

// Particles
import { ApplicationContext } from 'corigan';

import StyledUpload from './upload.styles';

import { NoteAdd } from 'icons';

type UploadProps = {
  /**
   * The children should be used to define any information you want fedback to the user
   * relating the media uploader. e.g. The name of the file(s) being uploaded.
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  disabled?: boolean;
  label?: boolean;
  /**
   * Optional boolean to set the file uploader between a large full-focus view or an inline view
   */
  large?: boolean;
  /**
   * Should accept multiple files or not
   */
  multiple?: boolean;
  /**
   * HTML semantics for input/label association and form values
   */
  name: string;
  /**
   'onMediaSet' is a callback function which returns an array of the files input to the <Upload/> component. For single file uploads this should be destructured to get the first array item.
   */
  onMediaSet: Function;
  /**
   * Used to set the label for the input
   */
  title?: string;
  /**
   * Used to set the label for the button
   */
  buttonLabel?: string;
  /**
   * Used to set the label for the button when a file has already been selected
   */
  buttonLabelMultiple?: string;
  /**
   * Text for the confirmation modal
   */
  confirmText?: string;
};

const Upload: React.FC<UploadProps> = (props: UploadProps) => {
  const { children, className, disabled, label, large, multiple, buttonLabel = `Choose file`, buttonLabelMultiple = `Choose another file`, confirmText = `Please confirm your upload` } = props;
  const { name, onMediaSet, title } = props;

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const uploadFiles = useCallback(acceptedFiles => onMediaSet(acceptedFiles), [onMediaSet]);

  const onDrop = (acceptedFiles) => {
    const handleConfirm = () => uploadFiles(acceptedFiles);
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  }
  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onDrop,
  });

  let classList = `dropzone`;
  if (className) classList += ` ${className}`;

  return (
    <StyledUpload className={classList} large={large}>
      {label && <label htmlFor={name}>{title ? title : name}</label>}
      <div {...getRootProps({ className: `dropzone dropzone--${name}` })}>
        <input {...getInputProps({ id: name, multiple })} />
        <p className="dropzone__area">
          <NoteAdd />
          {children && (
            <React.Fragment>
              <span className="dropzone__message">{children}</span>
              {large && <span className="button dropzone__button">{buttonLabelMultiple}</span>}
            </React.Fragment>
          )}
          {!children && (
            <React.Fragment>
              <span className="dropzone__message">
                Drag and drop {multiple ? `some files` : `a file`} here, or click to select{` `}
                {multiple ? `files` : `a file`}
              </span>
              {large && <span className="button dropzone__button">{buttonLabel}</span>}
            </React.Fragment>
          )}
        </p>
      </div>
    </StyledUpload>
  );
};

// Default prop values
Upload.defaultProps = {
  disabled: false,
  label: false,
  large: false,
  multiple: false,
  name: `file`,
  title: `File`,
};

export default Upload;

import { fetchAPI } from 'particles';

type DeleteManyTagsParameters = {
  ids: [ArgID];
};

export const callDeleteManyTags = async (parameters: DeleteManyTagsParameters) => {
  const response = await fetchAPI({ method: `DELETE`, parameters, route: `tags` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callDeleteManyTags;

import { css } from 'styled-components';
import { shouldBeDark } from 'particles';

const input = css`
  button,
  input,
  label,
  textarea {
    border-radius: 4px;
  }

  input {
    max-width: 100%;
  }

  [type='search'] {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 24px;
      top: 4px;
      width: 24px;

      background-image: ${() =>
        shouldBeDark()
          ? ``
          : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2343425D' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E")`};
      cursor: pointer;
    }

    &:focus {
      outline-offset: 0;
    }
  }

  input,
  select,
  textarea,
  .dropzone__area {
    border-radius: 2px;
    display: block;
    min-height: 48px;
    padding: ${props => `${props.theme.spacingSmall} ${props.theme.spacingDefault}`};
    position: relative;
    width: 100%;

    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
    border: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    line-height: 1.5;
    transition: border-color 0.25s, box-shadow 0.25s;

    &::placeholder {
      color: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey800)`)};
      opacity: 1;
      text-transform: capitalize;
    }

    &[aria-invalid='true'] {
      background-color: ${() => (shouldBeDark() ? `var(--black)` : `transparent`)};
      border-color: var(--red);
    }

    &[disabled],
    &[readonly] {
      background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--grey100)`)};
      cursor: not-allowed;
    }

    &:focus {
      border: 1px solid ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey900)`)};
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;

    border: 0;
    border-radius: initial;
    display: initial;
    min-height: 1px;
    background-color: initial;
    color: inherit;
    line-height: inherit;
    transition: none;

    & ~ & {
      + label {
        margin-top: 12px;
      }
    }

    &:focus,
    &:focus-within,
    &:hover {
      & + label {
        &::before {
          background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--white)`)};
        }
      }
    }

    &:checked {
      &:focus,
      &:focus-within,
      &:hover {
        & + label {
          &::before {
            background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--orange)`)};
          }
        }
      }
    }

    &:focus,
    &:focus-within {
      & + label {
        &::before {
          outline: 1px dotted ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
        }
      }
    }

    & + label {
      align-items: center;
      display: flex;
      margin: 4px 0;

      font-weight: 400;

      &::before {
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 24px;
        left: 0;
        margin-right: 8px;
        top: 0;
        width: 24px;

        background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
        background-image: ${() =>
          shouldBeDark()
            ? ``
            : `url("data:image/svg+xml,%3Csvg width='186' height='186' viewBox='0 0 186 186' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='93' cy='93' r='93' fill='%23FFFFFF'/%3E%3C/svg%3E%0A")`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: 35%;
        border: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--orange)`)};
        color: white;
        opacity: 1;
        outline-offset: 2px;
        transition: 0.2s background-color ease;
      }

      &:focus,
      &:focus-within,
      &:hover {
        &::before {
          background-color: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--orange100)`)};
        }
      }

      &:focus,
      &:focus-within {
        &::before {
          outline: 1px dotted ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
        }
      }
    }

    &:checked {
      & + label {
        &::before {
          background-color: ${() => (shouldBeDark() ? `var(--white)` : `var(--orange)`)};
        }
      }
    }
  }

  input[type='checkbox'] {
    & + label {
      &::before {
        background-image: ${() =>
          shouldBeDark()
            ? ``
            : `url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0L11.5 1.5L4.51 8.5L0 4L1.5 2.5L4.51 5.5L10 0Z' fill='white'/%3E%3C/svg%3E%0A")`};
        background-size: 50%;
        border-radius: 4px;
      }
    }
  }
`;

export default input;

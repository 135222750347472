import styled from 'styled-components';

const StyledTagsOrder = styled.div`
  .tags__reorder__block__wrapper {
    padding: 14px 0px 10px 10px;

    background-color: var(--white);
    border: 1px solid var(--black);
    border-radius: 2px;

    + div {
      margin-top: 8px;
    }
  }

  .draggable__handle__icon {
    padding: 4px 16px 16px 16px !important;
  }

  .tags__item__block__title {
    margin-bottom: 6px;

    font-weight: bold;
    text-transform: capitalise;
  }
`;

export default StyledTagsOrder;

import Papa from 'papaparse';

// Parse the CSV file provided by the user
export const parseCSV = file => {
  return new Promise((resolve, reject) => {
    if (!file) reject(new Error(`No file provided`));

    Papa.parse(file, {
      header: true, // Treat the first row as field names, and use as object property names for the rows
      skipEmptyLines: true, // Ignore any empty lines
      dynamicTyping: true, // Cast values to their appropriate data types (eg. numbers as strings -> numbers)
      transformHeader: h => h.toLowerCase(),
      complete: results => {
        const data = results?.data;
        const errors = results?.errors;
        const hasErrors = errors && errors.length > 0;

        if (hasErrors) return reject(errors[0]);
        if (!hasErrors) return resolve(data);
      },
      error: err => reject(err),
    });
  });
};

export default parseCSV;

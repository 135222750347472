import * as React from 'react';
import { useMemo } from 'react';

// Particles
import { datePretty } from 'corigan';

// Atoms
import { Link } from 'corigan';

// Local Partials
import StyledTimeline from './timeline.styles';

type TimelineProps = {
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  items: {
    date: DateString;
    excerpt?: string;
    href?: string;
    id: string;
    title: string;
  }[];
  title?: string;
};

const Timeline: React.FC<TimelineProps> = (props: TimelineProps) => {
  const { className, items, title } = props;

  let classList: string = `timeline`;
  if (className) classList += ` ${className}`;

  const hasItems: boolean = items?.length > 0;
  const isSingle: boolean = items?.length === 1;

  const ordered = useMemo(() => {
    if (!hasItems) return null;
    return items.sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));
  }, [hasItems, items]);

  return (
    <StyledTimeline className={classList}>
      {hasItems && (
        <div className="timeline__items">
          {title && <h2 className="timeline__heading">{title}</h2>}
          {isSingle && <TimelineItem {...items[0]} />}
          {!isSingle && hasItems && ordered.map(TimelineItem)}
        </div>
      )}
    </StyledTimeline>
  );
};

const TimelineItem = item => {
  const { date, excerpt, href, id, title } = item;

  return (
    <div className="timeline__item" key={`timeline-item-${id}`}>
      {title && <h3 className="timeline__tile">{title}</h3>}
      {date && <time className="timeline__date">{datePretty(date)}</time>}
      {excerpt && <div className="timeline__excerpt">{excerpt}</div>}
      {href && (
        <Link className="timeline__readmore" href={href}>
          Read more
        </Link>
      )}
    </div>
  );
};

// Default prop values
Timeline.defaultProps = {};

export default Timeline;

import * as React from 'react';

import Overlay from '../overlay';

import { ApplicationItems } from 'corigan';

type ApplicationsProps = {
  showApplications: boolean;
  updateHeaderView?: Function;
};

const Applications: React.FC<ApplicationsProps> = (props: ApplicationsProps) => {
  const { showApplications, updateHeaderView } = props;

  let className: string = `header__applications`;
  if (showApplications) className += ` header__overlay--show`;

  return (
    <Overlay active={showApplications} className={className} updateHeaderView={updateHeaderView}>
      <header>
        <h2>Apps</h2>
      </header>
      <ApplicationItems />
    </Overlay>
  );
};

// Default prop values
Applications.defaultProps = {};

export default Applications;

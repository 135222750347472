import * as React from 'react';

function SvgNotifications(props) {
  return (
    <svg viewBox="0 0 16 16" aria-hidden="true" {...props}>
      <path
        d="M6 14h4a2.006 2.006 0 01-2 2 2.006 2.006 0 01-2-2zm-5-1a.945.945 0 01-1-1 .945.945 0 011-1h.5A4.354 4.354 0 003 8V5a4.952 4.952 0 015-5 4.951 4.951 0 015 5v3a4.351 4.351 0 001.5 3h.5a.945.945 0 011 1 .945.945 0 01-1 1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgNotifications;

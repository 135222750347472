import * as React from 'react';

function SvgOpenInNew(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M21.3 21.3H2.7V2.7H12V0H2.7C1.2 0 0 1.2 0 2.7v18.7C0 22.8 1.2 24 2.7 24h18.7c1.5 0 2.7-1.2 2.7-2.7V12h-2.7v9.3zM14.7 0v2.7h4.8L6.3 15.8l1.9 1.9L21.3 4.5v4.8H24V0h-9.3z" />
    </svg>
  );
}

export default SvgOpenInNew;

import { fetchAPI } from 'particles';

declare type GetReportPageInternalArgs = {
  pageId?: string;
  url?: string;
  name?: string;
  domainId: string;
};

declare type CallGetReportPageInternalData = {
  data: PerformanceComparisonPage;
};

export const callGetReportPageInternal = async (
  reqKey,
  parameters: GetReportPageInternalArgs,
): Promise<CallGetReportPageInternalData> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/pages/internal` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export type { CallGetReportPageInternalData };
export default callGetReportPageInternal;

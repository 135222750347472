import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { ROUTES } from 'corigan';
import { ProtectedRoute } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetManyCrawls } from 'corigan';

// Local Components
import CrawlCharts from '../partials/crawl-charts';
import DomainHealth from './partials/domain-health';
import LatestCrawls from './partials/latest-crawls';
import TopChanges from './partials/top-changes';
import CompetitorsTopRanking from './partials/top-ranking';

const Home = () => (
  <ProtectedRoute>
    <Page application="competitor-research">
      <PageContents />
    </Page>
  </ProtectedRoute>
);

const PageContents = () => {
  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const id = domainActive?.id;

  const where: ArgWhere = `[domain][eq]=${id}`;
  const orderBy: ArgOrderBy = `-crawlDate`;
  const page: ArgPage = undefined;
  const perPage: ArgPerPage = 1;
  const _with: ArgWith = [`domain`];

  const apiArgs = { where, orderBy, page, perPage, _with };

  const getManyCrawls = useQuery([`callGetManyCrawls`, { ...apiArgs }], callGetManyCrawls);
  const crawl = getManyCrawls?.data?.data?.[0];
  const { error, isLoading: loading } = getManyCrawls;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.dashboard}>Portal Dashboard</Link>
            <h1>Competitor Research</h1>
          </Breadcrumbs>
        </Col>
      </Row>
      <DomainHealth />
      <CrawlCharts crawl={crawl} error={error} loading={loading} />
      <Row>
        <Col>
          <LatestCrawls />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <TopChanges />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <CompetitorsTopRanking />
        </Col>
      </Row>
    </Grid>
  );
};

export default Home;

import { css } from 'styled-components';

const hidden = css`
  .hide,
  .hidden {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;

    border: 0;
  }
`;

export default hidden;

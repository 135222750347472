import { fetchAPI } from 'particles';

type callPatchVerifyMeParameters = {
  verified: boolean;
};

export const callPatchVerifyMe = async (queryKey, parameters: callPatchVerifyMeParameters): Promise<{ data: { message: string } }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `users/me` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callPatchVerifyMe;

import * as React from 'react';

import TableState from './tableState';
import type { TableProps } from '../table.types';

type TableWrapperProps = {
  children?: React.ReactNode;
  dependencies?: any;
  initialState?: TableProps;
};

const TableWrapper: React.FC<TableWrapperProps> = (props: TableWrapperProps) => {
  const { children, initialState } = props;

  return <TableState initialState={initialState}>{children}</TableState>;
};

// Default prop values
TableWrapper.defaultProps = {};

export default TableWrapper;

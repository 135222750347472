import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { datePretty } from 'corigan';
import { generateChip } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Button, Link, Table } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetManyUsers } from 'corigan';
import uniqBy from 'lodash.uniqby';

const Users = () => {
  const { userHasPermission: canCreate } = useHasPermissions({ requiredPermissions: [`users:create`] });

  return (
    <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`users:read`]}>
      <Page application="portal" pageTitle="Users">
        <Grid>
          <Row>
            <Col>
              <Breadcrumbs>
                <Link href={ROUTES.dashboard}>Dashboard</Link>
                <h1>Users</h1>
                {canCreate && <Button href={ROUTES.users_create}>Create User</Button>}
              </Breadcrumbs>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <UsersTable />
              </Card>
            </Col>
          </Row>
        </Grid>
      </Page>
    </ProtectedRoute>
  );
};

/**
 * 'dbKey' property allows a column value to be passed to CrudTable.
 * The component will favour this property if it is present.
 */
export const columns = [
  {
    align: `left`,
    dbKey: `id`,
    filter: false,
    sort: true,
    hide: true,
    label: `ID`,
    numeric: false,
    value: `id`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `name`,
    filter: true,
    sort: true,
    hide: false,
    label: `Name`,
    numeric: false,
    value: `name`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `email`,
    filter: true,
    sort: true,
    hide: false,
    label: `Email`,
    numeric: false,
    value: `email`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `roles.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Roles`,
    numeric: false,
    value: `roles`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `roles.domains`,
    filter: true,
    sort: false,
    hide: false,
    label: `Sites`,
    numeric: false,
    value: `domains`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `teams`,
    filter: true,
    sort: true,
    hide: false,
    label: `Teams`,
    numeric: false,
    value: `teams`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `createdAt`,
    filter: true,
    sort: true,
    hide: false,
    label: `Created At`,
    numeric: true,
    date: true,
    value: `createdAt`,
    wrap: false,
  },
];

const variant = `plain`;

export const generateRows = (data: User[]) => {
  const rows = data.map(user => {
    const id = { href: `${ROUTES.user}?id=${user.id}`, value: user.id };
    const name = { href: `${ROUTES.user}?id=${user.id}`, value: user.name };
    const email = user?.email;

    let domains = undefined;
    let roles = undefined;
    let teams = undefined;

    const hasRoles: boolean = user?.roles.length > 0;
    const hasTeams: boolean = user?.teams.length > 0;

    if (hasRoles) {
      roles = user?.roles?.map(r =>
        generateChip({
          value: r.name,
          variant,
        }),
      );
    }

    if (hasTeams) {
      teams = user?.teams?.map(t => {
        const { id } = t;
        const href = ROUTES.team + `?id=${id}`;
        return generateChip({
          href,
          value: t.name,
          variant: `primary`,
        });
      });

      const allDomains = user?.teams
        ?.map(t => {
          const hasDomains = t?.domains?.length > 0;
          if (!hasDomains) return null;

          const userDomains = t.domains.map(domain => {
            const { hostname } = domain;
            const value = hostname;
            const chipsArgs = {
              variant,
              value,
            };

            return generateChip(chipsArgs);
          });

          return userDomains;
        })
        .filter(Boolean);

      const hasDomains = allDomains?.length > 0;
      if (!hasDomains) return null;
      domains = Array.prototype.concat.apply([], allDomains);

      const flatSuccess = allDomains?.length > 0;
      if (!flatSuccess) return null;
      domains = uniqBy(domains, `value`);
    }

    const createdAt = datePretty(user?.createdAt);

    const item = {
      id,
      name,
      email,
      roles,
      domains,
      teams,
      createdAt,
    };

    return item;
  });

  return rows.filter(Boolean);
};

declare type UsersTableProps = {};

const UsersTable = (props: UsersTableProps) => {
  const [mounted, setMounted] = useState<boolean>(false);

  const { releaseVersion } = getRelease();
  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`users:read`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=poUsersMastersheet${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Create initial API arguments
  const initialWhere: ArgWith = undefined;
  const initialOrderBy: ArgOrderBy = undefined;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = [`roles`, `teams`, `teams.domains`];

  // Read local storage values
  const localOrderBy: ArgOrderBy = localStorageRead(buildKey(`OrderBy`))
    ? localStorageRead(buildKey(`OrderBy`))
    : initialOrderBy;
  const localPage: ArgPage = localStorageRead(buildKey(`Page`)) ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = localStorageRead(buildKey(`PerPage`))
    ? localStorageRead(buildKey(`PerPage`))
    : initialPerPage;
  const localWhere: ArgWhere = localStorageRead(buildKey(`Where`)) ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }
    },
    [buildKey],
  );

  // Create API Functions for hanlding state
  const setOrderBy = value => setStateValue(`OrderBy`, value);
  const setPage = value => setStateValue(`Page`, value);
  const setPerPage = value => setStateValue(`PerPage`, value);
  const setWhere = value => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const whereProtected: ArgWhere = undefined;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const queryFunction = callGetManyUsers;
  const queryName = `callGetManyUsers`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = canRead && hasDomain;

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data: User[] = res?.data;
  const pagination = res?.pagination;

  const hasData: boolean = data?.length > 0;
  const items = hasData ? generateRows(data) : [];

  if (!mounted) return null;

  return (
    <Table
      apiArgs={apiArgs}
      apiDetails={apiDetails}
      apiFunctions={apiFunctions}
      allowFilters={true}
      collectionType="user"
      csvTitle="users"
      columns={columns}
      error={error}
      id="mastersheet"
      items={items}
      loading={loading}
      pagination={pagination}
      small={false}
      selectable={false}
      transfer={false}
    />
  );
};

export default Users;

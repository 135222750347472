export const getRelease = () => {
  const releaseVersion = process.env.GATSBY_APP_VERSION || ``;
  let patch = ``;
  let patchKey = ``;
  let minor = ``;
  let minorKey = ``;
  let major = ``;

  let versions = [];
  if (releaseVersion) versions = releaseVersion?.split(`.`);

  const hasVersions: boolean = versions?.length > 0;

  if (hasVersions) {
    minor = versions?.[1];
    major = versions?.[0];

    patchKey = releaseVersion;
    minorKey = major + `.` + minor;

    patch = releaseVersion.replace(`${minorKey}.`, ``);
  }

  const releaseObject = {
    releaseVersion,
    patch,
    patchKey,
    minor,
    minorKey,
    major,
  };

  return releaseObject;
};

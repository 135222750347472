import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Error } from 'corigan';
import { Page } from 'corigan';

// Local components
import RequestError from './partials/error';
import Missing from './partials/missing';
import Request from './partials/request';

// API
import { callGetManyUsers } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`users:read`]}>
    <UserContents />
  </ProtectedRoute>
);

type UserProps = {};

const UserContents: React.FC<UserProps> = (props: UserProps) => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  return (
    <Page application="portal" pageTitle="User">
      {id && <UserInfo id={id} />}
      {!id && <Missing />}
    </Page>
  );
};

type UserInfoProps = {
  id: string;
};

const _with = [`roles`, `teams`];

const UserInfo: React.FC<UserInfoProps> = (props: UserInfoProps) => {
  const { id } = props;
  const where: ArgWhere = `[_id][eq]=${id}`;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`users:read`] });

  const { data: res, error, isLoading: loading } = useQuery([`callGetManyUsers`, { where, _with }], callGetManyUsers, {
    enabled: canRead,
  });
  const data: User[] = res?.data;

  if (!canRead) return <Error error="You do not have permission to access users data" />;
  if (error) return <RequestError error={error} id={id} />;
  return <Request data={data} loading={loading} />;
};

export default Wrapper;

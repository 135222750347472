import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';

// Particles
import { OMContext } from 'corigan';
import { ApplicationContext } from 'corigan';

// Atoms
import { Chip } from 'corigan';
import { Field } from 'corigan';

// Molecules
import { Error } from 'corigan';

// API
import { callGetManyCategories } from 'corigan';

declare type Props = {
  isLocked: boolean;
};

const SearchCategories: React.FC<Props> = (props: Props) => {
  const { isLocked } = props;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const omContext = useContext(OMContext);
  const state = omContext?.state;
  const editedRevision: PageRevision = state.editedRevision;
  const currentCategories = editedRevision?.preferredCategories;

  const formik = useFormik({
    initialValues: { search: `` },
    onSubmit: values => console.info(values),
  });

  const { handleBlur, handleChange, handleSubmit, isSubmitting, values } = formik;
  const { search } = values;

  const enabled: boolean = !isLocked && search?.length >= 3;

  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const where: ArgWhere = `[or][textSearch][textSearch]=${search}&where[or][name][contains]=${search}`;

  const { data: res, error, isLoading: loading } = useQuery([`callGetDomains`, { whereProtected, where }], callGetManyCategories, {
    enabled,
  });
  const categories = res?.data;

  const isBusy: boolean = loading || isSubmitting;
  const hasResponseCategories: boolean = categories?.length > 0;
  const hasCurrentCategories: boolean = currentCategories?.length > 0;
  const shouldFilter: boolean = hasResponseCategories && hasCurrentCategories;

  let validCategories: CategoryObject[] = categories;

  if (shouldFilter) {
    validCategories = categories.filter(category => {
      const alreadyEnabled: boolean = currentCategories?.some(c => c.id === category.id);
      return !alreadyEnabled;
    });
  }

  const hasResults: boolean = validCategories?.length > 0;

  return (
    <React.Fragment>
      <form autoComplete="off" className="mt-4" onSubmit={handleSubmit}>
        {error && <Error error={error} />}
        <fieldset aria-busy={isBusy} className="mb-0" disabled={isLocked}>
          <Field
            id="search"
            disabled={isLocked}
            handleChange={handleChange}
            handleBlur={handleBlur}
            label="Search Categories"
            type="search"
            value={search}
          />
        </fieldset>
      </form>
      {enabled && (
        <div className="mt-2">
          {!hasResults && <span>No results found</span>}
          {hasResults && <RenderCategories categories={validCategories} isLocked={isLocked} />}
        </div>
      )}
    </React.Fragment>
  );
};

declare type RenderCategoriesProps = {
  categories: CategoryObject[];
  isLocked: boolean;
};

const RenderCategories: React.FC<RenderCategoriesProps> = (props: RenderCategoriesProps) => {
  const { categories, isLocked } = props;

  const omContext = useContext(OMContext);
  const dispatch = omContext?.dispatch;
  const state = omContext?.state;
  const editedRevision: PageRevision = state.editedRevision;

  const handleClick = useCallback(
    (e, category) => {
      e.preventDefault();
      const current = editedRevision?.preferredCategories;

      // If already exists in state, ignore the function
      const exists: boolean = current.some(c => c.id === category.id);
      if (exists) return;

      // Create new array with updated category
      const newCategories = [...current, category];
      dispatch({ key: `preferredCategories`, value: newCategories });
    },
    [dispatch, editedRevision],
  );

  return (
    <React.Fragment>
      {categories.map(category => {
        const { id, name } = category;
        const disabled: boolean = isLocked;
        const key: string = `category-${id}`;
        const onClick = e => handleClick(e, category);

        return (
          <Chip disabled={disabled} key={key} onClick={onClick}>
            {name}
          </Chip>
        );
      })}
    </React.Fragment>
  );
};

export default SearchCategories;

export const ThemeDefault = {
  font: `"Gotham", "Nunito Sans", -apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif`,
  /* BREAKPOINTS */
  xs: `576px`,
  sm: `768px`,
  md: `992px`,
  lg: `1200px`,
  xl: `1440px`,
  xxl: `1800px`,
  /* BORDERS */
  borderRadiusInput: `3px`,
  /* SPACING */
  spacingTiny: `4px`,
  spacingSmall: `8px`,
  spacingDefault: `16px`,
  spacingLarge: `32px`,
  spacingHuge: `64px`,
  /* COLOURS */
  primary: `#1B449C`,
  secondary: `#079CCB`,
  tertiary: `#49154F`,
  quaternary: `#85C442`,
  quinary: `#81181D`,
  senary: `#F15F4C`,
  /* COLOURS - 50 SHADES OF GREY */
  bodyBackground: `#f0f0f7`,
  white: `#ffffff`,
  offWhite: `#F9F9F9`,
  grey100: `#ececef`,
  grey200: `#d9d9df`,
  grey300: `#c7c6ce`,
  grey400: `#b4b3be`,
  grey500: `#a1a1ae`,
  grey600: `#8e8e9e`,
  grey700: `#7b7b8e`,
  grey800: `#69687d`,
  grey900: `#56556d`,
  grey: `#a1a1ae`,
  black: `#43425D`,
  blackAbsolute: `#000000`,
  /* COLOURS - BLUE */
  blue000: `#e4eaf7`,
  blue050: `#c0ceeb`,
  blue100: `#8da2ce`,
  blue200: `#768fc4`,
  blue300: `#5f7cba`,
  blue400: `#4969b0`,
  blue500: `#1B449C`,
  blue600: `#183d8c`,
  blue700: `#16367d`,
  blue800: `#13306d`,
  blue900: `#10295e`,
  blue: `#1B449C`,
  /* COLOURS - PALE BLUE */
  teal100: `#6ac4e0`,
  teal200: `#51badb`,
  teal300: `#39b0d5`,
  teal400: `#20a6d0`,
  teal500: `#079CCB`,
  teal600: `#068cb7`,
  teal700: `#067da2`,
  teal800: `#056d8e`,
  teal900: `#045e7a`,
  teal: `#079CCB`,
  /* COLOURS - GREEN */
  green000: `#ecffd9`,
  green100: `#c2e2a1`,
  green200: `#b6dc8e`,
  green300: `#aad67b`,
  green400: `#9dd068`,
  green500: `#91ca55`,
  green600: `#78b03b`,
  green700: `#6a9d35`,
  green800: `#5d892e`,
  green900: `#507628`,
  green: `#85C442`,
  /* COLOURS - PURPLE */
  purple100: `#927395`,
  purple200: `#805b84`,
  purple300: `#6d4472`,
  purple400: `#5b2c61`,
  purple500: `#49154F`,
  purple600: `#421347`,
  purple700: `#3a113f`,
  purple800: `#330f37`,
  purple900: `#2c0d2f`,
  purple: `#49154F`,
  /* COLOURS - RED */
  red000: `#ffe6e6`,
  red100: `#ffdbdd`,
  red200: `#f5c6c9`,
  red300: `#d68387`,
  red400: `#8e2f34`,
  red500: `#81181D`,
  red600: `#74161a`,
  red700: `#671317`,
  red800: `#5a1114`,
  red900: `#4d0e11`,
  red: `#81181D`,
  /* COLOURS - ORANGE */
  orange100: `#f79f94`,
  orange200: `#f58f82`,
  orange300: `#f47f70`,
  orange400: `#f26f5e`,
  orange500: `#F15F4C`,
  orange600: `#d95644`,
  orange700: `#c14c3d`,
  orange800: `#a94335`,
  orange900: `#91392e`,
  orange: `#F15F4C`,
  /* COLOURS - YELLOW */
  yellow100: `#f7ed94`,
  yellow200: `#f5da82`,
  yellow300: `#f4eb70`,
  yellow400: `#f2d75e`,
  yellow500: `#eef14c`,
  yellow600: `#d9aa44`,
  yellow700: `#c1b83d`,
  yellow800: `#a98a35`,
  yellow900: `#91722e`,
  yellow: `#eef14c`,
};

export default ThemeDefault;

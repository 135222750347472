/*
This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
It will generate several classes such as:
.m-r-10 which gives margin-right 10 pixels.
.m-r-15 gives MARGIN to the RIGHT 15 pixels.
.m-t-15 gives MARGIN to the TOP 15 pixels and so on.
.p-b-5 gives PADDING to the BOTTOM of 5 pixels
.p-l-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

const createUtilities = (type = `margin`) => {
  const typeLetter = type.charAt(0); // m (margin) or p (padding)

  let utilitiesStr = ``; // Our CSS string

  const spacingAmounts = [];
  const spacingMultiple = 8;
  const utilityCount = 10;

  // If set to 8 and 10, we should get 10 multiples of 8 [8, 16, ..., 72, 80];

  for (let i = 0; i <= utilityCount; i++) {
    spacingAmounts[i] = i * spacingMultiple;
  }

  const sides = [`top`, `right`, `bottom`, `left`];

  spacingAmounts.map((space, i) => {
    sides.map(side => {
      const letter = side.charAt(0);
      utilitiesStr += `.${typeLetter}${letter}-${i} { ${type}-${side}: ${space}px !important }`;
      return null;
    });
    utilitiesStr += `.${typeLetter}x-${i} { ${type}: 0px ${space}px !important }`;
    utilitiesStr += `.${typeLetter}y-${i} { ${type}: ${space}px 0px !important }`;
    utilitiesStr += `.${typeLetter}-${i} { ${type}: ${space}px !important }`;
    return null;
  });

  return utilitiesStr;
};

export default createUtilities;

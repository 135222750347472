import React from 'react';
import { useEffect, useState } from 'react';
import { generateID } from 'helpers';
import { ChevronLeft } from 'icons';
import { ChevronRight } from 'icons';

import StyledPagination from './pagination.styles';

function chunk(arr, chunkSize) {
  const chunked = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) chunked.push(arr.slice(i, i + chunkSize));
  return chunked;
}

function range(start, end) {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
}

const minIndex = 0;

type PaginationProps = {
  /**
   * True/False to enable/disable select dropdown which
   * allows for jumping between pagination chunks
   */
  changer: boolean;
  chunkIndex: number;
  setChunkIndex: React.Dispatch<React.SetStateAction<number>>;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * The number of buttons to include in the pagination component
   */
  count: number;
  /**
   * Whether or not to show the tripple elipsis pagination jumper,
   * the jumper button will take you to the final chunk of pagination
   * buttons
   */
  jumper: boolean;
  /**
   * The maximum number of button options to show at one time
   */
  max: number;
};

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { changer, className, count, jumper, max } = props;
  const { chunkIndex, setChunkIndex } = props;

  const [chunks, setChunks] = useState([]);

  const maxIndex = chunks?.length - 1;

  const isEnd = chunkIndex === maxIndex;
  const isStart = chunkIndex === minIndex;

  const showChanger = changer;
  const showEnd = count > max;
  const showJump = jumper && !isEnd && showEnd;

  useEffect(() => {
    const countArr = range(0, count - 1);
    const calculatedChunks = chunk(countArr, max);
    setChunks(calculatedChunks);
  }, [count, max]);

  const newIndex = (value: number): void => {
    setChunkIndex(value);
  };

  const handleClickPrev = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    newIndex(chunkIndex - 1);
  };

  const handleClickNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    newIndex(chunkIndex + 1);
  };

  const handleClickEnd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    newIndex(maxIndex);
  };

  const handleChangerUpdate = e => {
    const value = e?.target?.value;
    newIndex(Number(value));
  };

  if (!chunks) return null;

  let classList = `pagination`;
  if (className) classList += ` ${className}`;

  return (
    <StyledPagination className={classList}>
      <button disabled={isStart} onClick={handleClickPrev}>
        <span className="hidden">Go back {max} pages</span> <ChevronLeft />
      </button>
      {chunks.map((_, i) => {
        return (
          <PaginationButton chunkIndex={chunkIndex} index={i} key={`pagination-${i}`} newIndex={newIndex}>
            {i + 1}
          </PaginationButton>
        );
      })}
      {showJump && (
        <React.Fragment>
          <span className="pagination__elipsis">…</span>
          <button onClick={handleClickEnd}>
            <span className="hidden">Jump to final pages</span>
            {count}
          </button>
        </React.Fragment>
      )}
      <button disabled={isEnd} onClick={handleClickNext}>
        <span className="hidden">Go forward {max} pages</span>
        <ChevronRight />
      </button>
      {showChanger && (
        <select onBlur={handleChangerUpdate} value={chunkIndex}>
          {chunks.map((c, i) => (
            <option key={generateID(`changer`)} value={i}>
              {max * (i + 1)} / page
            </option>
          ))}
        </select>
      )}
    </StyledPagination>
  );
};

// Default prop values
Pagination.defaultProps = { changer: false, count: 1, jumper: true, max: 10 };

type PaginationButtonProps = {
  children?: React.ReactNode;
  chunkIndex: number;
  index: number;
  newIndex: Function;
};

const PaginationButton: React.FC<PaginationButtonProps> = (props: PaginationButtonProps) => {
  const { children, chunkIndex, index, newIndex } = props;

  const handleClick = e => {
    if (e) e.preventDefault();
    newIndex(index);
  };

  const isActive: boolean = chunkIndex === index;

  let className = `pagination__button`;
  if (isActive) className += ` pagination__button--active`;

  let buttonText: any | number = index + 1;
  if (children) buttonText = children;

  return (
    <button className={className} onClick={handleClick}>
      {buttonText}
    </button>
  );
};

// Default prop values
PaginationButton.defaultProps = {};

export default Pagination;

import * as React from 'react';
import { useContext } from 'react';

// Particles
import { OMContext } from 'corigan';

// Organisms
import { Diff } from 'corigan';

declare type PageRevisionDiffProps = {};

const PageRevisionDiff: React.FC<PageRevisionDiffProps> = (props: PageRevisionDiffProps) => {
  const context = useContext(OMContext);
  const state = context?.state;

  const editedRevision: PageRevision | null = state?.editedRevision;
  const { diff } = editedRevision || {};

  return <Diff diff={diff} previousColumn={`Expected`} currentColumn={`Detected`} />;
};

export default PageRevisionDiff;

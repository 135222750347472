import * as React from 'react';

import { SettingsEthernet } from 'icons';

import { Tooltip } from 'corigan';

import StyledShorten from './shorten.styles';

declare type ShortenProps = {
  className?: string;
  children: any;
  divider?: string;
  href?: string;
  // Show last number of divided entries
  levels?: number;
};

const Shorten = (props: ShortenProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { className, children, divider = `/`, levels = 2 } = props;

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const full = String(children);
  const shortened = full.split(divider);
  const parts = shortened?.filter(Boolean);

  const hasParts = parts?.length > 0;
  if (!hasParts) return null;

  const showAll: boolean = open || levels >= parts.length;
  const render = showAll ? children : divider + parts.slice(-Math.abs(levels)).join(divider);

  const tooltip: string = open ? `Shorten URL` : `Show full URL`;

  return (
    <Tooltip content={tooltip} side="left">
      <StyledShorten className={className}>
        <button onClick={handleClick}>
          <span className="hidden">View{open && `ing`} All URL</span>
          <SettingsEthernet />
        </button>
        {render}
      </StyledShorten>
    </Tooltip>
  );
};

export default Shorten;

import * as React from 'react';

// Localised partials
import PageContent from './content';

const RequestLoading = () => {
  return <PageContent loading={true} />;
};

export default RequestLoading;

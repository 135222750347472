import { fetchAPI } from 'particles';

type GetStructureChangesParameters = {
  domain: string;
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
  _with?: ArgWith;
};

export const callGetStructureChanges = async (queryKey, parameters: GetStructureChangesParameters) => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `research/structure-changes` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetStructureChanges;

import * as React from 'react';

// Icons
import { ChevronDown } from 'icons';
import { IsometricBuilder, IsometricChart, IsometricClipboard } from 'icons';
import { IsometricDiagram, IsometricSearch } from 'icons';

// Particles
import { applicationDetails } from 'corigan';
import { ApplicationContext } from 'corigan';

// Atoms
import { Link } from 'corigan';

// Local Partials
import StyledApplicationLabel from './application-label.styles';

type ApplicationLabelProps = {
  application: ApplicationName;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
};

export interface CSSProperties extends React.CSSProperties {
  '--colour'?: string;
  '--colourLabel'?: string;
}

export const ApplicationLabel: React.FC<ApplicationLabelProps> = (props: ApplicationLabelProps) => {
  const { application, className } = props;

  if (!application) return null;
  const applicationNames: ApplicationName[] = [`portal`, `optimisation-manager`, `keyword-manager`, `keyword-research`, `competitor-research`, `report-builder`];
  const applications = applicationDetails({ applicationNames });

  const activeApplication = applications.find(app => app.value === application);
  if (!activeApplication) return null;

  let classList = `application-label`;
  if (className) classList += ` ${className}`;
  const { colour, colourLabel, label, route } = activeApplication;

  let style = {} as CSSProperties;

  if (colour) style = { '--colour': `${colour}` };
  if (colourLabel) style = { ...style, '--colourLabel': `${colourLabel}` };

  return (
    <StyledApplicationLabel application={application} className={classList} style={style}>
      <Link href={route}>
        <ApplicationIcon application={application} />
        <span className="hidden">Go to {label}</span>
      </Link>
      <ApplicationOptions activeApplication={activeApplication} options={applications} />
    </StyledApplicationLabel>
  );
};

// Assign SVGs to object with named keys
const Icons = {
  portal: IsometricBuilder,
  'report-builder': IsometricBuilder,
  'optimisation-manager': IsometricChart,
  'keyword-manager': IsometricClipboard,
  'competitor-research': IsometricDiagram,
  'keyword-research': IsometricSearch,
};

type ApplicationIconProps = {
  application: string;
};

const ApplicationIcon: React.FC<ApplicationIconProps> = (props: ApplicationIconProps) => {
  const { application } = props;
  // If icon name value doesn't match Icons object keys then return null
  if (Icons[application] === undefined) return null;
  // If icon found, return the icon in a span element
  const Icon = Icons[application];
  return <Icon />;
};

type RefDefinition = { current: HTMLDivElement | null };

export const ApplicationOptions = props => {
  const { activeApplication, options } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = e => {
    if (e) e.preventDefault();
    setOpen(!open);
  };

  let classList = `options`;
  if (open) classList += ` options--open`;

  let style = {} as CSSProperties;

  const myRef: RefDefinition = React.useRef();

  const handleClickOutside = e => {
    const current = myRef?.current;
    if (!current) return;

    const wasOutside = !current.contains(e.target);
    if (wasOutside) setOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => document.removeEventListener(`mousedown`, handleClickOutside);
  });

  return (
    <nav className={classList} ref={myRef}>
      <button onClick={handleClick}>
        {activeApplication.label} <ChevronDown />
      </button>
      <div className="options__links">
        {options.map(option => {
          const { colour, colourLabel, label, route } = option;
          if (!label || !route) return null;

          if (colour) style = { '--colour': `${colour}` };
          if (colourLabel) style = { ...style, '--colourLabel': `${colourLabel}` };
          const key = `application-option-sidebar-${label}`;

          return (
            <Link key={key} href={route} style={style}>
              {label}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default ApplicationLabel;

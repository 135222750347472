import { fetchAPI } from 'particles';

type PageRevisionModel = {
  domain: string,
  pageRevisions: PageRevision[];
};

export const callUpdateManyPageRevisions = async (
  args: PageRevisionModel
): Promise<{ data: PageRevision[] }> => {
  const { domain, ...parameters } = args;
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `pagerevisions?domain=${domain}` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdateManyPageRevisions;

import * as React from 'react';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Issues } from 'corigan';
import { Page } from 'corigan';

const Status = () => (
  <ProtectedRoute>
    <Page application="portal" pageTitle="Service Status">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <h1>Service Status</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card footnote="Total Records: 1.2bn" status="Online" title="SERP Crawler" variant="status">
              {/* <Chart
                series={generateSeries()}
                type="bar"
                height="85px"
                width="170px"
              /> */}
              {/* <span>Avg Pages Per Second</span> */}
            </Card>
            <Card footnote="Total Records: 1.2bn" status="Offline" title="SERP Crawler" variant="status">
              {/* <Chart
                colour="#F02D70"
                series={generateSeries()}
                type="bar"
                height="85px"
                width="170px"
              /> */}
            </Card>
            <Card footnote="Total Records: 1.2bn" status="Online" title="SERP Crawler" variant="status">
              {/* <Chart
                series={generateSeries()}
                type="bar"
                height="85px"
                width="170px"
              /> */}
            </Card>
            <Card footnote="Total Records: 1.2bn" status="Maintenance" title="SERP Crawler" variant="status">
              {/* <Chart
                colour="#079CCB"
                series={generateSeries()}
                type="bar"
                height="85px"
                width="170px"
              /> */}
            </Card>
            <Card footnote="Total Records: 1.2bn" status="Online" title="SERP Crawler" variant="status">
              {/* <Chart
                series={generateSeries()}
                type="bar"
                height="85px"
                width="170px"
              /> */}
            </Card>
          </Col>
          <Col md={8}>
            <Card title="Reported Incidents">
              <Issues items={[]} />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

export default Status;

export const statuses = {
    briefed: `Briefed`,
    draft: `Draft`,
    awaitingApproval: `Awaiting Approval`,
    rejected: `Rejected`,
    readyToImplement: `Ready to Implement`,
    implemented: `Implemented`,
    live: `Live`,
    offline: `Offline`,
    online: `Online`,
    newPage: `New Page`,
    archived: `Archived`,
    mismatched: `Mismatched`,
    maintenance: `maintenance`
}

export default statuses;

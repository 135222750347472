import styled from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const StyledStats = styled.section`
  display: flex;
  flex-direction: column;

  @media ${device?.xl} {
    align-items: flex-end;
    flex-direction: row;
  }

  .keyword__stat {
    align-items: flex-start;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 24px;
    padding-bottom: 16px;

    border-bottom: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--grey300)`)};

    @media ${device?.xl} {
      align-items: center;
      flex-direction: row;
      margin-bottom: 0;
      padding-bottom: 0;

      border-bottom: none;
    }
  }

  .keyword__stat + .keyword__stat {
    @media ${device?.xl} {
      margin-left: 24px;
      padding-left: 24px;

      border-left: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--grey300)`)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 8px;

    color: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0 0 8px;

    color: 1px solid ${() => (shouldBeDark() ? `var(--grey100)` : `var(--purple)`)};
    font-size: 28px;
    line-height: 34px;

    @media ${device?.xl} {
      margin: 0;
      margin-left: 12px;
    }
  }
`;

export default StyledStats;

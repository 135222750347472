import { fetchAPI } from 'particles';

type GetManyCrawlsParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
  _with?: ArgWith;
};

export const callGetManyCrawls = async (
  queryKey,
  parameters: GetManyCrawlsParameters,
): Promise<{ data: Crawl[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `crawls` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetManyCrawls;

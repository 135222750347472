import { fetchAPI } from 'particles';

type DeleteTagParameters = {
  id: ArgID;
};

export const callDeleteTag = async (parameters: DeleteTagParameters) => {
  const response = await fetchAPI({ method: `DELETE`, parameters, route: `tags` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callDeleteTag;

import { replaceAll } from './replaceAll';

export const toTitleCase = (phrase: string): string => {
  if (!phrase) return;
  const lower = phrase?.toLowerCase();
  const removedHyphen = replaceAll(lower, `-`, ` `);
  return removedHyphen
    ?.split(` `)
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(` `);
};

export default toTitleCase;

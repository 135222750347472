import * as React from 'react';
import { FormikErrors } from 'formik';

import { capitalizeString } from 'corigan';
import { isUndefined } from 'corigan';
import { isURL } from 'corigan';

import { NewTab } from 'corigan';

import { Error } from 'corigan';

type FieldProps = {
  autoFocus?: boolean;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  errors?: { id: string }[] | FormikErrors<any>;
  handleBlur?: (event: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleChange?: (event: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
  label?: string;
  openInTab?: boolean;
  placeholder?: string;
  required?: boolean;
  touched?: any;
  type?: string;
  value?: any;
  values?: any;

  /** Manually set top level status. */
  setStatus?(status?: any): void;
  /** Manually set errors object */
  setErrors?(errors: any): void;
  /** Manually set isSubmitting */
  setSubmitting?(isSubmitting: boolean): void;
  /** Manually set touched object */
  setTouched?(touched: any, shouldValidate?: boolean): void;
  /** Manually set values object  */
  setValues?(values: any, shouldValidate?: boolean): void;
  /** Set value of form field directly */
  setFieldValue?(field: string, value: any, shouldValidate?: boolean): void;
  /** Set error message of a form field directly */
  setFieldError?(field: string, message: string): void;
  /** Set whether field has been touched directly */
  setFieldTouched?(field: string, isTouched?: boolean, shouldValidate?: boolean): void;
  /** Validate form values */
  validateForm?(values?: any): Promise<any>;
  /** Validate field value */
  validateField?(field: string): void;
  /** Reset form */
  resetForm?(nextState?: Partial<any>): void;
  /** Submit the form imperatively */
  submitForm?(): Promise<void>;
  /** Set Formik state, careful! */
  setFormikState?(f: any);
};

const Field: React.FC<FieldProps> = (props: FieldProps) => {
  const { className, labelClassName, openInTab } = props;
  const { autoFocus, id, disabled, label, placeholder, required, type, value } = props;

  const isTextarea: boolean = type === `textarea`;

  // Formik Props
  const { handleChange, handleBlur } = props;
  const { errors, touched, values } = props;

  let classList: string = `field`;
  if (isTextarea) classList += ` field--textarea`;
  if (className) classList += ` ${className}`;

  let labelClassNames: string = ``;
  if (disabled) labelClassNames += ` label--disabled`;
  if (labelClassName) labelClassNames += ` ${labelClassName}`;

  const errorID: string = `error` + capitalizeString(id);
  const error = errors ? errors[id] : null;

  const inputValue = !isUndefined(value) ? value : values[id];
  const inputValueIsURL = isURL(inputValue);

  return (
    <React.Fragment>
      {label && (
        <React.Fragment>
          {inputValueIsURL && openInTab && (
            <div className="display--flex mt-3">
              <NewTab
                className="mr-1"
                href={inputValue}
                text="Open URL field value in a new tab"
                style={{ marginTop: `2px` }}
              />
              <label className={`${labelClassNames} mt-0`} htmlFor={id}>
                {label}
                {required && ` *`}
              </label>
            </div>
          )}
          {(!inputValueIsURL || !openInTab) && (
            <label className={labelClassNames} htmlFor={id}>
              {label}
              {required && ` *`}
            </label>
          )}
        </React.Fragment>
      )}
      {!isTextarea && (
        <input
          aria-invalid={!!error}
          aria-describedby={errorID}
          autoFocus={autoFocus}
          className={classList}
          disabled={disabled}
          id={id}
          name={id}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          type={type}
          value={inputValue}
        />
      )}
      {isTextarea && (
        <textarea
          aria-invalid={!!error}
          aria-describedby={errorID}
          autoFocus={autoFocus}
          className={classList}
          disabled={disabled}
          id={id}
          name={id}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          value={inputValue}
        />
      )}
      {touched && touched[id] && error && <Error id={errorID} error={error} />}
    </React.Fragment>
  );
};

// Default prop values
Field.defaultProps = {
  required: false,
  type: `text`,
};

export default Field;

import { isNumeric } from 'corigan';

const windowAvailable: boolean = typeof window !== `undefined`;

const getMillisecondsFromDays = (numberOfDays: number): number => {
  if (!numberOfDays) return 0;
  const hours = numberOfDays * 24;
  const minutes = hours * 60;
  const seconds = minutes * 60;
  const milliseconds = seconds * 1000;
  return milliseconds;
};

export const localStorageSet = (key: string, newValue: any, hoursToExpireFrom?: number) => {
  const now = new Date();

  let expiry = null;
  if (isNumeric(hoursToExpireFrom)) expiry = now.getTime() + getMillisecondsFromDays(hoursToExpireFrom);

  // Return a wrapped version of useState's setter function that ...
  // .persists the new value to localStorage.
  try {
    // Gatsby specific build fix
    if (!windowAvailable) return;

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item: { expiry?: number; value: any } = {
      value: newValue,
    };

    if (expiry) item.expiry = expiry;

    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.error(error);
  }

  return newValue;
};

export default localStorageSet;

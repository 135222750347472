import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { navigate } from 'gatsby-link';

// Particles
import { ApplicationContext } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { windowAvailable } from 'corigan';
import ROUTES from 'routes';

// Components
import { Table } from 'corigan';
import { Button } from 'corigan';
import { Error } from 'corigan';

// Local Paritals
import { columns } from './partials/columns';
import { generateRows } from './partials/generateRows';

// API
import { callGetReportKeywordURLRanking } from 'corigan';

declare type KeywordRanksPageProps = {
  categories?: any[];
  top?: number;
  filter?: string | string[];
  competitor?: string | string[];
};

const KeywordTable: React.FC<KeywordRanksPageProps> = (props: KeywordRanksPageProps) => {
  const { categories, top, filter, competitor } = props;
  const [mounted, setMounted] = useState<boolean>(false);

  const { releaseVersion } = getRelease();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=crKeywordsURLRanks-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  const category = Buffer.from(JSON.stringify(categories)).toString(`base64`);

  // Create initial API arguments
  const initialWhere: ArgWith = undefined;
  const initialOrderBy: ArgOrderBy = `-keyword`;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPage = 10;

  const getLocalOrderBy: boolean = Boolean(localStorageRead(buildKey(`OrderBy`)));
  const getLocalPage: boolean = Boolean(localStorageRead(buildKey(`Page`)));
  const getLocalPerPage: boolean = Boolean(localStorageRead(buildKey(`PerPage`)));
  const getLocalWhere: boolean = Boolean(localStorageRead(buildKey(`Where`)));

  // Read local storage values
  const localOrderBy: ArgOrderBy = getLocalOrderBy ? localStorageRead(buildKey(`OrderBy`)) : initialOrderBy;
  const localPage: ArgPage = getLocalPage ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = getLocalPerPage ? localStorageRead(buildKey(`PerPage`)) : initialPerPage;
  const localWhere: ArgWhere = getLocalWhere ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where' , newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }

      return newValue;
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = (value: any) => setStateValue(`Page`, value);
  const setPerPage = (value: any) => setStateValue(`PerPage`, value);
  const setWhere = (value: any) => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive.id, localOrderBy, localPerPage, localWhere]);

  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);

  const queryFunction = callGetReportKeywordURLRanking;
  const queryName = `callGetReportKeywordURLRanking`;

  const apiArgs = { orderBy, page, perPage, where, domainId: domainActive?.id, category: category, competitor: competitor, top: top, filter: filter };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const hasCategory: boolean = Boolean(category);
  const enabled: boolean = hasDomain && hasCategory;

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination = res?.pagination;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data });
  }, [data]);

  const csvTitle = `Keyword URL Ranks`;

  // return to view 1 table
  const handleClick = e => {
    e.preventDefault();
    navigate(ROUTES.coverage_map);
  };

  if (!mounted) return null;

  return (
    <div className="table--responsive">
      <Button onClick={handleClick} variant="hollow" type="button">
          Return to main table
      </Button>
      {loading && <p>Loading data...</p>}
      {error && <Error error={error} />}
      {!loading &&
        <Table
          apiArgs={apiArgs}
          apiDetails={apiDetails}
          apiFunctions={apiFunctions}
          allowFilters={true}
          filtersToHide={[`comparePage`]}
          collectionType="report"
          columns={columns}
          csvTitle={csvTitle}
          error={error}
          id="keyword-ranks"
          items={items}
          loading={loading}
          pagination={pagination}
          selectable={false}
          small={true}
          transfer={true}
          exportArgs={apiArgs}
          exportRoute={`keyword-url-ranking`}
        />
      }
    </div>
  );
};

export default KeywordTable;

import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';

// Particles
import { isUndefined } from 'corigan';
import { ApplicationContext } from 'corigan';
import { CRContext } from 'corigan';

// Molecules
import { Error } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';
import { callGetReportCompetitorCategories } from 'corigan';
import { callGetDomains } from 'corigan';

import { statuses } from 'particles';

declare type PageSearchProps = {
  formik: any;
};

const PageSearch = (props: PageSearchProps) => {
  const { formik } = props;
  const { values } = formik;
  const page: Page = values?.page;
  const search: string = values?.search;
  const { handleChange } = formik;

  const isLongEnough: boolean = search?.length >= 3;
  const shouldSearch: boolean = !isUndefined(search) && isLongEnough;

  const appContext = useContext(ApplicationContext);
  const domainActive: any = appContext?.state?.domainActive;
  const domainName = domainActive?.hostname;
  const domainId = domainActive?.id;

  const crContext = useContext(CRContext);
  const dispatch = crContext?.dispatch;

  let where: ArgWhere = `[domainType][eq]=primary&where[canCrawl][eq]=true&where[competesWith][eq]=${domainId}`;
  const { data: dRes, error: dError, isLoading: dLoading } = useQuery([`callGetDomains`, { where }], callGetDomains);
  const domains: Domain[] = dRes?.data;

  const activeDomainURL = search.includes(domainName);
  const competitorDomain = domains?.find(domain => search.includes(domain?.hostname));

  const { data: cRes, error: cError, isLoading: cLoading } = useQuery(
    [`callGetReportCompetitorCategories`, { url: search }],
    callGetReportCompetitorCategories,
    { enabled: competitorDomain },
  );
  const cData = cRes?.data;

  where = `[status][eq]=${statuses.live}&where[name][contains]=${search}&where[domain][eq]=${domainId}`;
  if (activeDomainURL) {
    where = `[status][eq]=${statuses.live}&where[url][contains]=${search}&where[domain][eq]=${domainId}`;
  } else if (competitorDomain && cData?.length) {
    const whereCategories = cData.map(cat => `&where[or][categories.category.name][contains]=${cat}`).join(``);
    where = `[status][eq]=${statuses.live}${whereCategories}&where[domain][eq]=${domainId}`;
  }
  const _with = [`currentRevision`, `page`];

  const { data: pRes, error: pError, isLoading: pLoading } = useQuery(
    [`callGetManyPageRevisions`, { where, _with }],
    callGetManyPageRevisions,
    {
      enabled: shouldSearch,
    },
  );
  const results: PageRevision[] = pRes?.data;
  const hasResults: boolean = results?.length >= 1;

  const error = dError || cError || pError;
  const loading = dLoading || cLoading || pLoading;

  const setCompetitorURL = () => {
    if (dispatch) {
      dispatch({ type: `set`, key: `competitor`, value: competitorDomain });
      dispatch({ type: `set`, key: `competitorURL`, value: Boolean(competitorDomain) && search });
    }
  };

  return (
    <React.Fragment>
      <label className="mt-0" htmlFor="search">
        Search for a Page
      </label>
      <input
        autoFocus={!page}
        className="mt-2"
        id="search"
        name="search"
        onChange={handleChange}
        required={true}
        value={search}
        type="search"
      />
      {error && <Error error={error} />}
      {isLongEnough && (
        <nav className="search__results mt-1">
          {!hasResults && !loading && <p>No results were found with that search</p>}
          {loading && <p>Loading results...</p>}
          {hasResults &&
            results.map(result => (
              <Result key={result.id} formik={formik} page={result} setCompetitorURL={setCompetitorURL} />
            ))}
        </nav>
      )}
    </React.Fragment>
  );
};

declare type ResultProps = {
  formik: any;
  page: PageRevision;
  setCompetitorURL: Function;
};

const Result = (props: ResultProps) => {
  const { formik, page, setCompetitorURL } = props;
  const { setFieldValue } = formik;

  const name = page?.name;

  const handleClick = e => {
    e.preventDefault();
    setFieldValue(`page`, page);
    setFieldValue(`search`, ``);
    setCompetitorURL();
  };

  return <button onClick={handleClick}>{name}</button>;
};

export default PageSearch;

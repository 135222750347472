import * as React from 'react';

// Particles
import { ROUTES } from 'corigan';

// Components
import { ProtectedRoute } from 'corigan';
import { Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

type PageProps = {};

const KeywordChanges: React.FC<PageProps> = (props: PageProps) => {
  return (
    <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`keywords:read`]}>
      <Page application="optimisation-manager">
        <Grid>
          <Row>
            <Col>
              <h1>Keyword Changes</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <p>List of keyword changes to be included</p>
              </Card>
            </Col>
          </Row>
        </Grid>
      </Page>
    </ProtectedRoute>
  );
};

export default KeywordChanges;

import styled from 'styled-components';
import { isLight } from 'helpers';

const validX = [`left`, `center`, `right`];
const validY = [`top`, `center`, `bottom`];

const badgeBackground = props => {
  // If a background value is specified, use that instead of theme
  if (props.background) return props.background;

  // If no variant is specified, return the primary colour in our theme
  if (!props.variant) return `var(--primary)`;

  // Dynamically determine the background colour based on props
  switch (props.variant) {
    case `plain`:
      return `var(--grey800)`;
    case `primary`:
      return `var(--primary)`;
    case `secondary`:
      return `var(--secondary)`;
    case `tertiary`:
      return `var(--tertiary)`;
    case `quaternary`:
      return `var(--green800)`;
    case `quinary`:
      return `var(--quinary)`;
    case `senary`:
      return `var(--senary)`;
    case `green`:
      return `var(--green800)`;
    case `red`:
      return `var(--red)`;
    case `orange`:
      return `var(--orange)`;
    case `yellow`:
      return `var(--yellow800)`;
    default:
      return `var(--primary)`;
  }
};

const badgeColour = props => {
  // Fallback value if we can't get access to props
  if (!props || !props.theme || !props.theme.primary) return `var(--white)`;

  if (props.background) {
    const { background } = props;
    const textColour = `var(--black)`;

    if (!background) return textColour;
    const wasLight = isLight(background);

    if (!wasLight) return `var(--white)`;
    if (wasLight) return `var(--black)`;
  }

  // If no variant is specified, return the white colour
  if (!props.variant) return `white`;

  // Dynamically determine the background colour based on props
  let colour;
  switch (props.variant) {
    case `primary`:
      colour = `var(--white)`;
      break;
    case `secondary`:
      colour = `var(--white)`;
      break;
    default:
      colour = `var(--white)`;
      break;
  }

  return colour;
};

const badgeLeft = props => {
  if (!props || !props.align || !props.align.x) return null;
  const { x } = props.align;
  if (!validX.includes(x)) return null;
  if (x === `left`) return 0;
  if (x === `center`) return `50%`;
  return null;
};

const badgeRight = props => {
  if (!props || !props.align || !props.align.x) return null;
  const { x } = props.align;
  if (!validX.includes(x)) return null;
  if (x === `right`) return 0;
  return null;
};

const badgeBottom = props => {
  if (!props || !props.align || !props.align.y) return null;
  const { y } = props.align;
  if (!validY.includes(y)) return null;
  if (y === `bottom`) return 0;
  return null;
};

const badgeTop = props => {
  if (!props || !props.align || !props.align.y) return null;
  const { y } = props.align;
  if (!validY.includes(y)) return null;
  if (y === `top`) return 0;
  if (y === `center`) return `50%`;
  return null;
};

const badgeTransform = props => {
  if (!props || !props.align || !props.align.x) return null;
  const { x, y } = props.align;
  let translate = ``;
  if (x) {
    if (!validX.includes(x)) return;
    if (x === `right`) {
      translate += `translateX(50%)`;
    } else {
      translate += `translateX(-50%)`;
    }
  }
  if (y) {
    if (!validY.includes(y)) return;
    if (y === `bottom`) {
      translate += ` translateY(50%)`;
    } else {
      translate += ` translateY(-50%)`;
    }
  }
  if (translate === ``) return null;
  return translate;
};

const badgePadding = props => {
  if (!props || !props.size) return null;

  switch (props.size) {
    case `small`:
      return `0px 4px`;
    case `medium`:
      return `4px 8px`;
    case `large`:
      return `8px 12px`;
  }
};

const badgeFontSize = props => {
  if (!props || !props.size) return null;

  switch (props.size) {
    case `small`:
      return `10px`;
    case `medium`:
      return `13px`;
    case `large`:
      return `15px`;
  }
};

export const StyledBadgeRoot = styled.div`
  position: relative;
`;

export const StyledBadge = styled.span`
  align-items: center;
  border-radius: 90px;
  bottom: ${props => badgeBottom(props)};
  display: inline-flex;
  justify-content: center;
  left: ${props => badgeLeft(props)};
  padding: ${props => badgePadding(props)};
  position: absolute;
  right: ${props => badgeRight(props)};
  top: ${props => badgeTop(props)};
  z-index: 2;

  background-color: ${props => badgeBackground(props)};
  color: ${props => badgeColour(props)};
  font-size: ${props => badgeFontSize(props)};
  transform: ${props => badgeTransform(props)};

  svg {
    height: 18px;

    fill: ${props => badgeColour(props)};
  }
`;

export const StyledBadgeLink = styled(StyledBadge).attrs({
  as: `a`,
})`
  text-decoration: none;
  transition: 0.2s background-color ease;

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    background-color: var(--blue400);
  }
`;

export default StyledBadge;

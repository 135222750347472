import { css } from 'styled-components';

const fieldset = css`
  fieldset {
    margin-bottom: 32px;
    padding: 0;

    border: none;
  }
`;

export default fieldset;

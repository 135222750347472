import styled from 'styled-components';

const EditorWrapper = styled.section`
  .page__editors__key__red {
    color: var(--red);
    font-weight: bold;
  }

  .page__editors__key__highlight {
    padding: 5px 4px;

    background-color: var(--red);
    color: var(--white);
    text-decoration: underline;
  }

  .page__editors__key__highlight--orange {
    background-color: var(--orange);
  }

  .page__editors__empty {
    padding: 16px;

    border: 1px solid var(--grey200);

    p {
      margin: 0;
    }
  }
`;

export default EditorWrapper;

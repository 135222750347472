import * as React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

// Particles
import { parseHTML } from 'corigan';

// Atoms
import { Button } from 'corigan';

// Molecules
import { Loader } from 'corigan';

// Local Partials
import StyledModal from './modal.styles';

type ModalProps = {
  children?: React.ReactNode;
  closeText?: string;
  confirmText?: string;
  content?: string;
  enableConfirm?: boolean;
  isOpen: boolean;
  loading?: boolean;
  title?: string;
  handleConfirm: Function;
  handleClose: Function;
  cancelText?: string;
};

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const { children, closeText, confirmText, content, enableConfirm, isOpen, loading, title, cancelText = `No, Cancel` } = props;
  const { handleConfirm, handleClose } = props;
  const node = useRef();

  const handleConfirmClick = async e => {
    if (e) e.preventDefault();
    await handleConfirm();
  };

  const handleCloseClick = async e => {
    if (e) e.preventDefault();
    await handleClose();
  };

  const handleClick = useCallback(
    (e: MouseEvent): void => {
      const target = e?.target as HTMLElement;

      // Inside click
      if (!node?.current) return;
      const current = node.current as HTMLElement;

      const clickedInModal = current.contains(target);
      if (clickedInModal) return;

      // Outside click
      handleClose();
    },
    [handleClose],
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent): void => {
      const { key } = event;
      const lowerKey: string = key?.toLowerCase();

      switch (lowerKey) {
        case 'escape':
          handleClose();
          break;

        case `enter`:
          handleConfirm();
          break;
      }
    },
    [handleClose],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener(`mousedown`, handleClick);
      document.addEventListener(`keydown`, handleKeyPress);
    }

    if (!isOpen) {
      document.removeEventListener(`mousedown`, handleClick);
      document.removeEventListener(`keydown`, handleKeyPress);
    }

    return () => {
      document.removeEventListener(`mousedown`, handleClick);
      document.removeEventListener(`keydown`, handleKeyPress);
    };
  }, [handleClick, handleKeyPress, isOpen]);

  let className = `modal__wrapper`;
  if (isOpen) className += ` modal--show`;

  const hasContent = content || children;
  const bodyContent = content ? parseHTML(content) : children;

  const isHidden = !isOpen;

  return (
    <StyledModal aria-hidden={isHidden} className={className}>
      <div ref={node} className="modal__content">
        {loading && <Loader className="modal__loader" type="bar" />}
        <header className="modal__header">
          {title && <h2>{title}</h2>}
          <button className="modal__header__button" onClick={handleCloseClick} type="button">
            &times;
            <span className="hide">{closeText}</span>
          </button>
        </header>
        {hasContent && <div className="modal__text">{bodyContent}</div>}
        {enableConfirm && (
          <footer className="modal__footer">
            <Button className="modal__footer__confirm" onClick={handleConfirmClick} type="button">
              {confirmText}
            </Button>
            <Button className="modal__footer__cancel" onClick={handleCloseClick}>
              {cancelText}
            </Button>
          </footer>
        )}
      </div>
    </StyledModal>
  );
};

Modal.defaultProps = {
  closeText: `Close`,
  confirmText: `Confirm`,
  enableConfirm: false,
  handleConfirm: () => console.info(`You need to provide a valid function for handleConfirm!`),
  loading: false,
};

export default Modal;

import styled from 'styled-components';
import { shouldBeDark } from 'particles';

const StyledTimeline = styled.section`
  width: 100%;

  font-size: 1.4rem;

  p,
  time {
  }

  time {
    display: block;
    margin: 8px 0 16px;
  }

  .timeline__excerpt {
    ul {
      padding-left: 16px;
    }
  }

  .timeline__heading {
    margin-bottom: 24px;
    padding-left: 12px;

    font-size: 20px;
  }

  .timeline__items {
    padding: 20px 12px 40px;

    border-left: 1px solid ${() => (shouldBeDark() ? `var(--grey600)` : `var(--purple600)`)};
  }

  .timeline__item {
    padding-left: 12px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 9px;
      left: -12px;
      width: 12px;
      height: 0.5px;
      background-color: ${() => (shouldBeDark() ? `var(--grey600)` : `var(--purple600)`)};
    }
  }

  .timeline__item + .timeline__item {
    margin-top: 48px;
  }

  .timeline__readmore {
    display: inline-flex;
    margin-top: 8px;

    text-decoration: none;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default StyledTimeline;

import * as React from 'react';

import { isAnObject } from 'corigan';
import { isString } from 'corigan';

import StyledError from './error.styles';

type DisplayErrorProps = {
  /**
   * The primary content of the Error component, replaces 'error' prop if found
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * Alternative method of setting error value for component.
   * Error describes the application error to the user
   */
  error?: any;
  id?: string;
  log?: boolean;
  /**
   * Title gives a short one liner description of the error
   */
  title?: string;
};

// Used if we want to override default error message
const handleErrorMessage = (message: string): string => {
  let formatted = message;

  switch (message) {
    case `SyntaxError: Unexpected end of JSON input`:
      formatted = `Empty JSON response or unexpected end of input`;
      break;
  }

  return formatted;
};

export const DisplayError: React.FC<DisplayErrorProps> = (props: DisplayErrorProps) => {
  const { children, className, error, id, log = false, title } = props;

  let classList = `error`;
  if (className) classList += ` ${className}`;

  const errorIsObject: boolean = Boolean(error) && isAnObject(error);
  const errorIsText: boolean = Boolean(error) && !errorIsObject;

  let message: string | React.ReactNode;

  if (errorIsText) message = error;
  if (errorIsObject) message = error.message;
  if (children) message = children;

  const hasMessage: boolean = Boolean(message);
  if (!hasMessage) return null;

  if (log && errorIsText) console.error(error);
  if (log && errorIsObject) console.error(error.message);

  const convert: boolean = !isString(message) && error instanceof Error;
  if (convert) message = handleErrorMessage(error.toString());

  return (
    <StyledError className={classList}>
      {title && <strong className="error__title">{title}</strong>}
      <p id={id}>{message}</p>
    </StyledError>
  );
};

export default DisplayError;

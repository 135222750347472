import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { convertToRaw, SelectionState } from 'draft-js';
import { ContentBlock } from 'draft-js';
import { DraftHandleValue } from 'draft-js';
import { EditorState } from 'draft-js';
import { RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';

import { isEqualObjects } from 'corigan';
import { ApplicationContext } from 'corigan';

import { applyEntity } from './helpers';
import { blockRenderMap } from './helpers';
import { blockStyleFn } from './helpers';
import { createEntity } from './helpers';
import { defaultLinkingCharacters } from './helpers';
import { addPhraseLinking } from './helpers';
import { removePhraseLinking } from './helpers';
import { scanKeywordConflicts } from './helpers';
import { getLinkedPhrases, PhraseLink } from './helpers';
import { createCombinedWordList } from './helpers';
import { differencesBetweenArrays } from 'helpers';
import { usePrevious } from 'helpers';
import { wordCounter } from 'helpers';

import Actions from './partials/actions';
import keywordDecorator from './partials/keyword-decorator';
import Toolbar from './partials/toolbar';

import { EditorStateObject } from './EditorDraftTypes';

import StyledEditor from './editor.styles';

import type { MouseEvent } from 'react';

import KeywordModalCreate from './partials/keyword-creation';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const plugins = [inlineToolbarPlugin];

type SelectionLinkState = {
  allowLinkClick: boolean;
  linkStatus: boolean;
  selectionContentBlock: ContentBlock | null;
  selectionEntityKey: string | null;
};

type CustomEditorProps = {
  allowedLinkingCharacters?: string[];
  copy?: boolean;
  defaultFontSize?: number;
  editorState: EditorStateObject;
  fontSize?: boolean;
  keywords?: Keyword[];
  linkedWords?: LinkedWord[];
  linkedWordsEnable?: boolean;
  link?: boolean;
  locked?: boolean;
  maxFontSize?: number;
  maxWordCount?: number;
  minFontSize?: number;
  redo?: boolean;
  spellCheck?: boolean;
  undo?: boolean;
  addKeywordLink?: boolean;
  handleChange?: (value, encodedContent?: any) => void;
  handleFontChange?: (size?: number) => void;
  setEditorState(item: any): void;
};

const CustomEditor: React.FC<CustomEditorProps> = (props: CustomEditorProps) => {
  const { keywords, linkedWords, linkedWordsEnable, allowedLinkingCharacters } = props;
  const { handleChange, locked, spellCheck, maxWordCount } = props;
  const { minFontSize, maxFontSize, defaultFontSize, handleFontChange } = props;
  const { setEditorState } = props;
  const { editorState, valid } = props.editorState;
  const [hasChanged, setHasChanged] = useState(false);
  const [lastEntityMap, setLastEntityMap] = useState(null);
  const [wordCount, setWordCount] = useState<number>(wordCounter(editorState.getCurrentContent().getPlainText()));
  const [selectionLinkState, setSelectionLinkState] = useState<SelectionLinkState>({
    linkStatus: false,
    allowLinkClick: false,
    selectionContentBlock: null,
    selectionEntityKey: null,
  });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const [fontSize, setFontSize] = useState(domainActive?.config?.font?.size ?? defaultFontSize);

  const combinedWordList = useMemo(() => [...keywords, ...linkedWords], [keywords, linkedWords]);
  const typedCombinedWordList = createCombinedWordList({keywords, linkedWords});

  // EDITOR FOCUS
  const editorRef = useRef(null);

  const focusEditor = (): void => {
    editorRef.current.focus();
  };

  const editorChanged = useCallback(
    (hasChangedValue: boolean, editorContent?: any, encodedContent?: any) => {
      setHasChanged(hasChangedValue);
      const shouldHandle = handleChange && editorContent;

      if (shouldHandle) handleChange(editorContent, encodedContent);
    },
    [handleChange],
  );

  useEffect((): void => {
    focusEditor();
  }, [editorRef]);

  useEffect((): void => {
    const words: string = editorState?.getCurrentContent()?.getPlainText();
    if (!words) return;

    const count: number = wordCounter(words);
    setWordCount(count);
  }, [editorState]);

  // TOOLBAR BUTTON ACTIONS
  const handleUndo = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    const newEditorState = EditorState.undo(editorState);
    const newWordCount = wordCounter(newEditorState.getCurrentContent().getPlainText());
    const newValid = !maxWordCount || newWordCount <= maxWordCount;

    setEditorState({
      editorState: newEditorState,
      valid: newValid,
    });

    const currentContent = newEditorState.getCurrentContent();
    const encodedContent = convertToRaw(currentContent);
    const text = currentContent.getPlainText();

    editorChanged(true, text, encodedContent);
  };

  const handleRedo = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    const newEditorState = EditorState.undo(editorState);

    setEditorState({
      editorState: EditorState.redo(editorState),
      valid,
    });

    const currentContent = newEditorState.getCurrentContent();
    const encodedContent = convertToRaw(currentContent);
    const text = currentContent.getPlainText();

    editorChanged(true, text, encodedContent);
  };

  const handleFontSize = (e: MouseEvent, modifier: number): void => {
    e.preventDefault();
    e.stopPropagation();

    let newFontSize = fontSize + modifier;

    const isMaximum = newFontSize >= maxFontSize;
    const isMinimum = newFontSize <= minFontSize;
    if (isMinimum) newFontSize = minFontSize;
    if (isMaximum) newFontSize = maxFontSize;

    setFontSize(newFontSize);
    handleFontChange(newFontSize);
  };

  const handleLink = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    const { allowLinkClick, selectionContentBlock, selectionEntityKey } = selectionLinkState;
    if (!allowLinkClick) return;

    const currentContent = editorState.getCurrentContent();
    const contentBlockKey = selectionContentBlock.getKey();
    const currentSelection = editorState.getSelection();
    const anchorOffset = currentSelection.getAnchorOffset();
    const focusOffset = currentSelection.getFocusOffset();
    const selectionStartAfterEnd = anchorOffset > focusOffset;

    selectionContentBlock.findEntityRanges(
      character => {
        const entityKey = character.getEntity();

        return entityKey === selectionEntityKey;
      },
      (keywordStartPosition, keywordEndPosition) => {
        // Get current keyword data
        const keywordEntity = currentContent.getEntity(selectionEntityKey);
        const { linked, url, keyword, keywordType } = keywordEntity.getData();

        // Toggle the keyword linking
        const keywordSelection = currentSelection.merge({
          anchorKey: contentBlockKey,
          anchorOffset: selectionStartAfterEnd ? keywordEndPosition : keywordStartPosition,
          focusKey: contentBlockKey,
          focusOffset: selectionStartAfterEnd ? keywordStartPosition : keywordEndPosition,
        });

        const entityData = {
          currentContent,
          phrase: keyword,
          url,
          linked: !linked,
          keywordType,
        };
        const { contentWithNewEntity, newEntityKey } = createEntity(entityData);

        let newEditorState = applyEntity(editorState, contentWithNewEntity, keywordSelection, newEntityKey, typedCombinedWordList);

        const newContent = newEditorState.getCurrentContent();

        // This will scan and update entity data
        scanKeywordConflicts({ currentContent: newContent, keywords: typedCombinedWordList });
        newEditorState = EditorState.createWithContent(newContent, keywordDecorator);

        // Reset cursor position to where it started at
        newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

        // Sets the new editor state
        const encodedContent = convertToRaw(newContent);
        const newEntityMap = encodedContent?.entityMap;
        setLastEntityMap(newEntityMap);

        // Marks that the editor has changed
        const text = currentContent.getPlainText();
        editorChanged(true, text, encodedContent);

        setEditorState({
          editorState: newEditorState,
          valid,
        });

        // Update selection link status
        const newContentBlock = newContent.getBlockForKey(contentBlockKey);
        const linkStatus = !linked;

        const updatedSelectionLinkState: SelectionLinkState = {
          linkStatus,
          allowLinkClick: true,
          selectionContentBlock: newContentBlock,
          selectionEntityKey: newEntityKey,
        };

        setSelectionLinkState(updatedSelectionLinkState);
      },
    );
  };

  const handleLinkAll = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    let newEditorState = editorState;
    const currentSelection = editorState.getSelection();
    const blankSelection = new SelectionState();

    const newUnlinkedEditorState: EditorState = removePhraseLinking(editorState, typedCombinedWordList, true);
    const newLinkedEditorState = addPhraseLinking(
      newUnlinkedEditorState,
      blankSelection,
      typedCombinedWordList,
      allowedLinkingCharacters,
    );
    const newHighlightedEditorState = addPhraseLinking(
      newUnlinkedEditorState,
      blankSelection,
      typedCombinedWordList,
      allowedLinkingCharacters,
      true,
    );

    // Determine if there were any changes in the linked keywords via phrase order or link status
    const oldLinkedPhrases: PhraseLink[] = getLinkedPhrases(editorState);
    const newLinkedPhrases: PhraseLink[] = getLinkedPhrases(newLinkedEditorState);

    if (oldLinkedPhrases.length !== newLinkedPhrases.length) {
      newEditorState = newLinkedEditorState;
    } else {
      newEditorState = newHighlightedEditorState;

      for (let i: number = 0; i < oldLinkedPhrases.length; i++) {
        if (!isEqualObjects(oldLinkedPhrases[i], newLinkedPhrases[i])) {
          newEditorState = newLinkedEditorState;
          break;
        }
      }
    }

    const newContent = newEditorState.getCurrentContent();

    // This will scan and update entity data
    scanKeywordConflicts({ currentContent: newContent, keywords: typedCombinedWordList });
    newEditorState = EditorState.createWithContent(newContent, keywordDecorator);

    // Reset cursor position to where it started at
    newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

    // Sets the new editor state
    const encodedContent = convertToRaw(newEditorState.getCurrentContent());
    const newEntityMap = encodedContent?.entityMap;
    setLastEntityMap(newEntityMap);

    // Marks that the editor has changed
    const text = newContent.getPlainText();
    editorChanged(true, text, encodedContent);

    setEditorState({
      editorState: newEditorState,
      valid,
    });
  };

  const setSelectionLinkedState = useCallback((newEditorState: EditorState): void => {
    // Gets position of currently selected text
    const selection = newEditorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const focusKey = selection.getFocusKey();
    const selectionStartPosition = selection.getStartOffset();
    let selectionEndPosition = selection.getEndOffset();

    // Checks which position is the beginning of the selection and subtracts one
    // from the end, due to characters always being after the position
    const startIsEnd = selectionStartPosition === selectionEndPosition;
    if (!startIsEnd) selectionEndPosition--;

    // Gets the entities for both the start and end positions
    const currentContent = newEditorState.getCurrentContent();
    const anchorContentBlock = currentContent.getBlockForKey(anchorKey);
    const focusContentBlock = currentContent.getBlockForKey(focusKey);
    const anchorEntityKey = anchorContentBlock.getEntityAt(selectionStartPosition);
    const focusEntityKey = focusContentBlock.getEntityAt(selectionEndPosition);

    // Create the default selection link state
    let updatedSelectionLinkState: SelectionLinkState = {
      linkStatus: false,
      allowLinkClick: false,
      selectionContentBlock: null,
      selectionEntityKey: null,
    };

    // If the entities at the start and end of the selection are part of the
    // same keyword entity, set the current selection values and linked status
    if (anchorEntityKey !== null && anchorEntityKey === focusEntityKey) {
      const { linked } = currentContent.getEntity(anchorEntityKey).getData();

      updatedSelectionLinkState = {
        linkStatus: linked,
        allowLinkClick: true,
        selectionContentBlock: anchorContentBlock,
        selectionEntityKey: anchorEntityKey,
      };
    }

    // Save values in state of the current text selection for easy retrieving
    // when the link button is clicked
    setSelectionLinkState(updatedSelectionLinkState);
  }, []);

  // HANDLE KEY COMMANDS
  const handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
    if ([`bold`, `italic`, `underline`].includes(command)) {
      const newEditorState: EditorState = RichUtils.handleKeyCommand(editorState, command);

      if (newEditorState) {
        setEditorState({
          editorState: newEditorState,
          valid,
        });

        const currentContent = newEditorState.getCurrentContent();
        const encodedContent = convertToRaw(currentContent);
        const text = currentContent.getPlainText();

        editorChanged(true, text, encodedContent);
      }

      return `handled`;
    }

    return `not-handled`;
  };

  const handleCopy = async (e: MouseEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const currentSelection = editorState.getSelection();

    // Highlights all text so it can be copied
    const blankSelection = new SelectionState();
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const newSelection = blankSelection.merge({
      anchorOffset: 0,
      focusOffset: blocks[blocks.length - 1].getText().length,
      anchorKey: blocks[0].getKey(),
      focusKey: blocks[blocks.length - 1].getKey(),
    });
    let newEditorState = EditorState.forceSelection(editorState, newSelection);

    // Sets the editor state and then copies the content. We use a promise here
    // so the editor state is actually changes before we attempt a copy
    new Promise((resolve) => {
      resolve(setEditorState({
        editorState: newEditorState,
        valid,
      }));
    }).then(() => {
      document.execCommand(`copy`);
      toast.info(`Copied content to clipboard`, {});

      // Sets the editor back to the previous selection state
      newEditorState = EditorState.forceSelection(editorState, currentSelection);

      setEditorState({
        editorState: newEditorState,
        valid,
      });
    })
  };

  const handleHTMLCopy = async (e: MouseEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const rawContentState = convertToRaw(editorState.getCurrentContent());

    let markup = draftToHtml(
      rawContentState,
    );

    markup = (markup.endsWith(`<p></p>
`)) ? markup.slice(0, -8) : markup;

    const copyArea = document.createElement(`textarea`);
    document.body.appendChild(copyArea);
    copyArea.value = markup;
    copyArea.select();
    document.execCommand(`copy`);
    document.body.removeChild(copyArea);

    toast.info(`Copied HTML content to clipboard`, {});
  };

  // EDITOR CHANGE HANDLER
  const handleEditorChange = useCallback(
    (newEditorState: EditorState): void => {
      const currentContent = newEditorState.getCurrentContent();
      const oldContent = editorState.getCurrentContent();
      let newValid = valid;

      // Only check for keyword updates if the content has changed
      // This is because the change handler is called if you move the cursor, etc
      if (currentContent !== oldContent) {
        const currentSelection = newEditorState.getSelection();

        // Removes link data from broken phrases
        newEditorState = removePhraseLinking(newEditorState, typedCombinedWordList);

        // Adds link data to phrases found in text
        newEditorState = addPhraseLinking(newEditorState, currentSelection, typedCombinedWordList, allowedLinkingCharacters);

        const newContent = newEditorState.getCurrentContent();

        // Updates the entity map
        const encodedContent = convertToRaw(newContent);
        const newEntityMap = encodedContent?.entityMap;
        setLastEntityMap(newEntityMap);

        // Updates word count
        const newWordCount = wordCounter(newContent.getPlainText());
        newValid = !maxWordCount || newWordCount <= maxWordCount;

        // Checks if any keywords have changed via deletion or linking/unlinking
        // If there have been changes, re-applies the highlighting so it is accurate
        const entityMapChanged: boolean = !isEqualObjects(lastEntityMap, newEntityMap);

        if (entityMapChanged) {
          try {
            // This will scan and update entity data
            scanKeywordConflicts({ currentContent: newContent, keywords: typedCombinedWordList });
            newEditorState = EditorState.createWithContent(newContent, keywordDecorator);
          } catch (error) {
            console.error(error);
          }
        }

        // Reset cursor position to where it started at
        newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

        // Marks that the editor has changed
        const text = currentContent.getPlainText();
        editorChanged(true, text, encodedContent);
      }

      setEditorState({
        editorState: newEditorState,
        valid: newValid,
      });

      // Check the link state of the text selection
      setSelectionLinkedState(newEditorState);
    },
    [
      typedCombinedWordList,
      allowedLinkingCharacters,
      editorState,
      lastEntityMap,
      setEditorState,
      maxWordCount,
      setSelectionLinkedState,
      editorChanged,
      valid,
    ],
  );

  // KEYWORDS PROP CHANGE EFFECT
  const prevCombinedWordList = usePrevious(combinedWordList);

  useEffect((): void => {
    // Deep compares the keywords for any changes and updates the editor
    // state to reflect removed or newly added keywords.
    if (prevCombinedWordList && differencesBetweenArrays(combinedWordList, prevCombinedWordList).length) {
      const currentSelection = editorState.getSelection();

      // Removes link data from broken phrases
      let newEditorState = removePhraseLinking(editorState, typedCombinedWordList);

      // Adds link data to phrases found in text
      newEditorState = addPhraseLinking(newEditorState, currentSelection, typedCombinedWordList, allowedLinkingCharacters);

      const newContent = newEditorState.getCurrentContent();

      // Updates the entity map
      const encodedContent = convertToRaw(newContent);
      const newEntityMap = encodedContent?.entityMap;
      setLastEntityMap(newEntityMap);

      // This will scan and update entity data
      scanKeywordConflicts({ currentContent: newContent, keywords: typedCombinedWordList });
      newEditorState = EditorState.createWithContent(newContent, keywordDecorator);

      // Reset cursor position to where it started at
      newEditorState = EditorState.forceSelection(newEditorState, currentSelection);

      // Marks that the editor has changed
      const text = newContent.getPlainText();
      editorChanged(true, text, encodedContent);

      setEditorState({
        editorState: newEditorState,
        valid,
      });
    }
  }, [allowedLinkingCharacters, editorState, editorChanged, combinedWordList, prevCombinedWordList, typedCombinedWordList, setEditorState, valid]);

  const className = !valid ? `editor__word-count--invalid` : ``;

  // define opens for keyword creation modal and get selected text
  const [isOpen, setOpen] = useState(false);
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const anchorKey = selection.getAnchorKey();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);

  return (
    <React.Fragment>
      <StyledEditor onClick={focusEditor} fontSize={fontSize} domain={domainActive}>
        <div className="editor__wrapper">
          <Actions
            {...props}
            handleCopy={handleCopy}
            handleHTMLCopy={handleHTMLCopy}
            currentFontSize={fontSize}
            hasChanged={hasChanged}
            linkStatus={selectionLinkState.linkStatus}
            handleUndo={handleUndo}
            handleRedo={handleRedo}
            handleLink={handleLink}
            handleLinkAll={handleLinkAll}
            handleFontSize={handleFontSize}
            isOpen={isOpen}
            setOpen={setOpen}
            linkedWordsEnable={linkedWordsEnable}
          />
          <Editor
            // @ts-ignore */
            editorState={editorState}
            blockStyleFn={blockStyleFn}
            blockRenderMap={blockRenderMap}
            onChange={handleEditorChange}
            handleKeyCommand={handleKeyCommand}
            plugins={plugins}
            spellCheck={spellCheck}
            ref={editorRef}
            readOnly={locked}
          />
          {!locked && (
            <Toolbar
              inlineToolbarPlugin={inlineToolbarPlugin}
              linkStatus={selectionLinkState.linkStatus}
              handleLink={handleLink}
              isOpen={isOpen}
              setOpen={setOpen}
              {...props}
            />
          )}
        </div>
        <hr className="editor__word-count--divider" />
        <div className="editor__word-count">
          <p>
            Word Count: <span className={className}>{wordCount}</span>
            {maxWordCount ? ` / ${maxWordCount}` : ``}
          </p>
        </div>
        {!locked && <KeywordModalCreate isOpen={isOpen} setOpen={setOpen} selected={selectedText.trim()} /> }
      </StyledEditor>
    </React.Fragment>
  );
};

CustomEditor.defaultProps = {
  allowedLinkingCharacters: defaultLinkingCharacters,
  copy: true,
  defaultFontSize: 16,
  fontSize: true,
  keywords: [],
  linkedWords: [],
  link: true,
  locked: false,
  maxFontSize: 24,
  maxWordCount: 0,
  minFontSize: 10,
  redo: true,
  spellCheck: true,
  undo: true,
  addKeywordLink: true,
  handleFontChange: () => console.info(`You need to provide a valid function for handleFontChange!`),
};

export default CustomEditor;

export const firebaseMethods = (firebase, windowAvailable) => {
  const auth = firebase.auth();

  return {
    getIdTokenResult: () => {
      if (!windowAvailable) return null;

      return new Promise((resolve, reject) => {
        auth.onAuthStateChanged(() => {
          const user = auth.currentUser;

          if (!user) return reject(new Error(`Unable to access current user object`));
          if (!user.getIdTokenResult?.()) {
            return reject(new Error(`Unable to access current user getIdTokenResult function`));
          }

          return user
            .getIdTokenResult()
            .then(idTokenResult => resolve(idTokenResult))
            .catch(err => reject(err.message));
        });
      });
    },
    loginUser: ({ email, password }) => {
      if (!windowAvailable) return null;
      return new Promise((resolve, reject) => {
        return auth
          .signInWithEmailAndPassword(email, password)
          .then(user => resolve(user))
          .catch(err => reject(err.message));
      });
    },
    loginUserGoogle: () => {
      if (!windowAvailable) return null;
      const provider = new firebase.firebase_.auth.GoogleAuthProvider();
      provider.addScope(`profile`);
      provider.addScope(`email`);
      return new Promise((resolve, reject) => {
        auth
          .signInWithPopup(provider)
          .then(result => {
            // TODO: Refactor with other method of Google id_token access?
            // Example: https://stackoverflow.com/a/52577606
            const isNewUser = result.additionalUserInfo.isNewUser;
            // Delete the created user
            if (isNewUser) {
              result.user.delete();
              reject(new Error(`The email address associated with that google account doesn't exist in the database.`));
            } else {
              resolve(result);
            }
          })
          .catch(err => reject(err.message));
      });
    },
    logoutUser: () => {
      return new Promise((resolve, reject) => {
        if (!windowAvailable) return reject(new Error(`No window available`));
        auth
          .signOut()
          .then(() => resolve(`Logged out`))
          .catch(err => reject(err.message));
      });
    },
    registerUser: ({ email, password }) => {
      if (!windowAvailable) return null;
      return new Promise((resolve, reject) => {
        return auth
          .createUserWithEmailAndPassword(email, password)
          .then(user => resolve(user))
          .catch(err => reject(err.message));
      });
    },
    refreshUser: setCurrentUser => {
      if (!windowAvailable) return null;
      auth.onAuthStateChanged(setCurrentUser);
    },
    sendResetEmail: email => {
      if (!windowAvailable) return null;
      return new Promise((resolve, reject) => {
        return auth
          .sendPasswordResetEmail(email)
          .then(res => resolve(res))
          .catch(err => reject(err.message));
      });
    },
    sendVerificationEmail: () => {
      if (!windowAvailable) return null;
      const isStorybook: boolean = process.env.STORYBOOK_ENABLED === `true`;

      let url = process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT;
      if (isStorybook) url = process.env.STORYBOOK_CONFIRMATION_EMAIL_REDIRECT;

      auth.currentUser.sendEmailVerification({
        url,
      });
    },
    toJSON: () => {
      if (!windowAvailable) return null;
      const user = auth.currentUser;
      return user.toJSON();
    },
    updateEmail: email => {
      if (!windowAvailable) return null;
      const user = auth.currentUser;
      return new Promise((resolve, reject) => {
        return user
          .updateEmail(email)
          .then(res => resolve(res))
          .catch(err => reject(err.message));
      });
    },
    updatePassword: password => {
      if (!windowAvailable) return null;
      const user = auth.currentUser;
      return new Promise((resolve, reject) => {
        return user
          .updatePassword(password)
          .then(res => resolve(res))
          .catch(err => reject(err.message));
      });
    },
    updateProfile: values => {
      if (!windowAvailable) return null;
      const user = auth.currentUser;

      return new Promise((resolve, reject) => {
        return user
          .updateProfile({ ...values })
          .then(res => resolve(res))
          .catch(err => reject(err.message));
      });
    },
  };
};

export default firebaseMethods;

import * as React from 'react';

function SvgIsometricDiagram(props) {
  return (
    <svg viewBox="0 0 31.43 41.02" aria-hidden="true" {...props}>
      <path
        d="M31.09 14.09c-.17-.07-2.34-1.09-2.52-1.17a.823.823 0 00-.76.14l-.17.12c-.01-.44-.15-.87-.39-1.24-.7-1.02-2.1-1.29-3.12-.59l-7.91 5.38v-2.89l6.41-4.45c.34-.23.54-.61.56-1.01l.03-6.57a.637.637 0 00-.34-.61C22.7 1.14 20.53.12 20.35.04a.776.776 0 00-.76.14L8.58 7.82c-.33.23-.54.6-.55 1.01L8 14.93v.47c-.02.24.11.48.33.6.17.07 2.35 1.08 2.52 1.16.27.1.56.04.78-.13l3.59-2.49v2.87l-8.31 5.66a5.95 5.95 0 00-2.6 4.9v1.09L.59 31.63c-.33.23-.54.6-.56 1.01l-.02 6.1v.47c-.02.25.11.48.33.6.17.07 2.34 1.08 2.52 1.17.26.09.56.04.78-.14l5.4-3.73c.33-.23.53-.6.55-1.01l.03-6.57a.623.623 0 00-.34-.6c-.17-.07-2.34-1.09-2.53-1.17a.834.834 0 00-.76.14l-.68.47v-.4c0-1.63.81-3.16 2.16-4.07l7.75-5.27v3.01l-3.72 2.57c-.33.23-.54.6-.56 1.01l-.03 6.1v.47c-.01.25.11.48.33.6.18.07 2.35 1.08 2.53 1.17.26.09.56.04.77-.14l5.4-3.73c.34-.23.54-.6.56-1.01l.03-6.57a.615.615 0 00-.35-.6c-.17-.07-2.34-1.09-2.52-1.17a.829.829 0 00-.76.14l-.68.47v-3.01l8.47-5.76c.2-.14.45-.22.7-.22.69 0 1.25.56 1.25 1.25v.66l-4.23 2.92c-.34.23-.54.6-.56 1.01l-.03 6.1v.47c-.02.25.11.48.33.6.17.07 2.35 1.08 2.52 1.17.27.09.56.04.78-.14l5.4-3.73c.33-.23.54-.6.56-1.01l.02-6.57a.607.607 0 00-.34-.6zM8.93 30.06c.03-.02.08-.01.1.02.01.01.01.03.01.04l-.01 5.89c0 .2-.1.38-.26.49L3.6 40.12c-.03.02-.08.01-.1-.02-.01-.01-.01-.03-.01-.04l.01-5.89c0-.2.09-.38.26-.49l5.17-3.62zm10.9-7.41c.04-.03.08-.02.1.02.01.01.02.02.02.04l-.01 5.88c0 .2-.1.38-.26.5l-5.17 3.61c-.04.02-.08.02-.1-.02-.01-.01-.02-.02-.02-.04l.01-5.88c0-.2.1-.39.26-.5l5.17-3.61zm-4.09-9.24l-.02.02-.5.34-3.63 2.54c-.03.02-.08.01-.1-.02-.01-.01-.01-.03-.01-.04l.01-5.88c0-.2.1-.39.26-.5l10.78-7.53c.03-.02.08-.01.1.02.01.02.01.03.01.04l-.01 5.89c0 .2-.1.38-.26.49l-6.63 4.63zm15.1 7.76c0 .2-.09.39-.26.5l-5.17 3.61c-.03.03-.08.02-.1-.02-.01-.01-.01-.02-.01-.04l.01-5.88c0-.2.1-.39.26-.5l5.17-3.61c.03-.02.08-.02.1.01.01.02.01.03.01.05l-.01 5.88z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIsometricDiagram;

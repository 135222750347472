import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { RBContext } from 'corigan';

// API
import { callGetSistrixMarket } from 'corigan';
import { callGetSistrixWeeklyComparison } from 'corigan';

// Components
import { Error } from 'corigan';

// Local Particles
import { ReportChart } from './partials';
import { ReportStatistics } from './partials';

// Local Components
import ViewTemplate from '../view-template';

declare type ReportMarketProps = {};

const ReportMarket = (props: ReportMarketProps) => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const context = useContext(RBContext);
  const state = context?.state;
  const { apiDate, monthOnMonth } = state;

  // Only recalculate on date changes in state store
  const date = useMemo(() => {
    return apiDate?.clone()?.startOf(`isoWeek`)?.format(`YYYY-MM-DD`);
  }, [apiDate]);

  const marketParams = {
    domainId,
    date,
    monthOnMonth,
  };

  const statisticsQuery = useQuery([`callGetSistrixMarket`, marketParams], callGetSistrixMarket);

  // Construct Comparison Query parameters object
  const comparisonParams = {
    domainId,
    limit: 156,
  };

  const comparisonQuery = useQuery(
    [`callGetSistrixWeeklyComparison`, comparisonParams],
    callGetSistrixWeeklyComparison,
  );

  // Are either of the queries loading? Used in card loader
  const eitherLoading: boolean = statisticsQuery?.isLoading || comparisonQuery?.isLoading;

  return (
    <ViewTemplate controlDevices={true} controlMOM={true} loading={eitherLoading}>
      <ReportStatistics
        {...props}
        data={statisticsQuery?.data?.data}
        error={statisticsQuery?.error}
        loading={statisticsQuery?.isLoading}
      />
      <ReportCharts
        {...props}
        data={comparisonQuery?.data?.data}
        error={comparisonQuery?.error}
        loading={comparisonQuery?.isLoading}
      />
    </ViewTemplate>
  );
};

declare type ReportChartsProps = {
  data: any;
  error: any;
  loading: boolean;
};

const ReportCharts = (props: ReportChartsProps) => {
  // Inherited from the useQuery function in parent component
  const data = props?.data;
  const error = props?.error;

  const context = useContext(RBContext);
  const state = context?.state;
  const { devices } = state;

  const enabledDesktop = devices.includes(`desktop`);
  const enabledMobile = devices.includes(`mobile`);

  return (
    <React.Fragment>
      {error && <Error error={error} />}
      {enabledDesktop && <ReportChart data={data} device="desktop" title="Desktop Visibility Scores" />}
      {enabledMobile && <ReportChart data={data} device="mobile" title="Mobile Visibility Scores" />}
    </React.Fragment>
  );
};

export default ReportMarket;

import React from 'react';

import { Undo, Redo, Link, SelectAll, FileCopy, FontDecrease, FontIncrease, KeywordAdd, CodeCopy } from 'icons';

import { Button } from 'corigan';
import { Tooltip } from 'corigan';

type ActionsProps = {
  editorState: any;
  handleCopy: Function;
  handleHTMLCopy: Function;
  handleUndo: Function;
  handleRedo: Function;
  handleLink: Function;
  handleLinkAll: Function;
  handleFontSize: Function;
  undo?: boolean;
  redo?: boolean;
  link?: boolean;
  locked?: boolean;
  copy?: boolean;
  fontSize?: boolean;
  currentFontSize?: number;
  linkStatus?: boolean;
  hasChanged?: boolean;
  addKeywordLink?: boolean;
  linkedWordsEnable?: boolean;
  isOpen?: boolean;
  setOpen?: Function;
};

const Actions: React.FC<ActionsProps> = (props: ActionsProps) => {
  const { undo, redo, link, copy, fontSize, linkStatus, currentFontSize } = props;
  const { locked, addKeywordLink, linkedWordsEnable, isOpen, setOpen } = props;
  const { handleHTMLCopy, handleCopy, handleUndo, handleRedo, handleLink, handleLinkAll, handleFontSize } = props;

  // define keyword click handler
  const keywordCreateHandler = e => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  return (
    <nav className="editor__toolbar editor__toolbar--static">
      <nav>
        {undo && (
          <Button
            disabled={locked}
            onClick={e => {
              handleUndo(e);
            }}
          >
            <Undo />
            <span>Undo Changes</span>
          </Button>
        )}
        {redo && (
          <Button
            disabled={locked}
            onClick={e => {
              handleRedo(e);
            }}
          >
            <Redo />
            <span>Redo Changes</span>
          </Button>
        )}
        {link && (
          <>
            <Tooltip content="Hyperlink Keyword" side="left">
              <Button
                disabled={locked}
                onClick={e => {
                  handleLink(e);
                }}
              >
                <Link {...(linkStatus && { className: `editor__link-button--active` })} />
                <span>Link Keyword</span>
              </Button>
            </Tooltip>
            <Tooltip content="Hyperlink All Keywords" side="left">
              <Button
                disabled={locked}
                onClick={e => {
                  handleLinkAll(e);
                }}
              >
                <SelectAll className="editor__link-all-button" />
                <span>Link Keyword</span>
              </Button>
            </Tooltip>
          </>
        )}
        {addKeywordLink && (
          <>
            <Tooltip content={`Create Keyword${linkedWordsEnable ? ` / Link` : ``} From Selection`} side="left">
              <Button onClick={keywordCreateHandler}>
                <KeywordAdd />
              </Button>
            </Tooltip>
          </>
        )}
        {fontSize && (
          <>
            <Button disabled={locked} onClick={e => handleFontSize(e, -1)}>
              <FontDecrease />
              <span>Decrease Font Size</span>
            </Button>
            <input
              type="text"
              disabled
              value={currentFontSize}
              {...(locked && { className: `editor__font-size--locked` })}
            />
            <Button disabled={locked} onClick={e => handleFontSize(e, 1)}>
              <FontIncrease />
              <span>Increase Font Size</span>
            </Button>
          </>
        )}
      </nav>
      <nav>
        {copy && (
          <Tooltip content="Copy to Clipboard" side="left">
            <Button
              onClick={e => {
                handleCopy(e);
              }}
            >
              <FileCopy />
              <span>Copy to Clipboard</span>
            </Button>
          </Tooltip>
        )}
        {copy && (
          <Tooltip content="Copy as HTML to Clipboard" side="left">
            <Button
              onClick={e => {
                handleHTMLCopy(e);
              }}
            >
              <CodeCopy />
              <span>Copy as HTML to Clipboard</span>
            </Button>
          </Tooltip>
        )}
      </nav>
    </nav>
  );
};

Actions.defaultProps = {
  undo: true,
  redo: true,
  link: true,
  copy: true,
  fontSize: true,
  currentFontSize: null,
  linkStatus: false,
  hasChanged: false,
};

export default Actions;

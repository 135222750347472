import 'react-dates/initialize';
import * as React from 'react';
import { useState } from 'react';

import { DateRangePicker } from 'react-dates';
import moment from 'moment';

declare type DateRangeProps = {
  dateEarliest: string;
  dateLatest: string;
  end: moment.Moment;
  start: moment.Moment;
  setStart: any;
  setEnd: any;
};

const endDateId = `top-ranking-kr-dashboard-end`;
const startDateId = `top-ranking-kr-dashboard-start`;

const DateRange: React.FC<DateRangeProps> = (props: DateRangeProps) => {
  const { end, setEnd, start, setStart } = props;
  const { dateEarliest, dateLatest } = props;

  const [focus, setFocus] = useState(null);

  const onChange = ({ startDate, endDate }) => {
    if (startDate) setStart(startDate?.clone());
    if (endDate) setEnd(endDate?.clone());
  };

  const isOutsideRange = date => {
    const tooNew = date.isAfter(moment(dateLatest).startOf(`week`));
    const tooOld = date.isBefore(moment(dateEarliest).startOf(`week`));

    const justRight = !tooNew && !tooOld;

    return !justRight;
  };

  return (
    <nav className="keyword-research__dates">
      <DateRangePicker
        displayFormat="DD-MM-YYYY"
        endDate={end} // momentPropTypes.momentObj or null,
        endDateId={endDateId} // PropTypes.string.isRequired,
        firstDayOfWeek={1}
        focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        isOutsideRange={isOutsideRange}
        minimumNights={28}
        onDatesChange={dates => onChange(dates)} // PropTypes.func.isRequired,
        onFocusChange={focusedInput => setFocus(focusedInput)} // PropTypes.func.isRequired,
        startDate={start} // momentPropTypes.momentObj or null,
        startDateId={startDateId} // PropTypes.string.isRequired,
      />
    </nav>
  );
};

export default DateRange;

import * as React from 'react';
import { Helmet } from 'react-helmet';
import uniqBy from 'lodash.uniqby';

// https://gist.github.com/SonyaMoisset/aa79f51d78b39639430661c03d9b1058#file-title-case-a-sentence-for-loop-wc-js
const kebabToTitle = stringValue => {
  if (!stringValue) return;

  stringValue = stringValue.replace(`-`, ` `);
  stringValue = stringValue.toLowerCase().split(` `);
  for (let i = 0; i < stringValue.length; i++) {
    stringValue[i] = stringValue[i].charAt(0).toUpperCase() + stringValue[i].slice(1);
  }

  return stringValue.join(` `);
};

declare type HeadProps = {
  application?: string;
  pageTitle?: string;
};

export const Head: React.FC<HeadProps> = (props: HeadProps) => {
  const { application, pageTitle } = props;

  const title = [kebabToTitle(application), kebabToTitle(pageTitle)].filter(Boolean).join(`: `);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      {!title && <title>Corigan</title>}
      {title && <title>Corigan - {title}</title>}
    </Helmet>
  );
};

export default Head;

import * as React from 'react';

function SvgIsometricClipboard(props) {
  return (
    <svg viewBox="0 0 19.8 41.6" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        d="M6.4 27.6l-1.8-1.5c-.2-.1-.2-.3-.1-.5l.1-.2c.1-.2.4-.3.6-.2h.1l1 .8L8 22.3c0-.2.2-.3.4-.2 0 0 .1 0 .1.1.1.1.1.2.1.3v.2l-2.2 4.9zm4.8-4.4c-.3 0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l5.3-3.8c.5-.3 1.1-.2 1.4.2s.2 1.1-.2 1.4L11.8 23c-.2.1-.4.2-.6.2zm-2.7 7.6c.1.1.2.2.2.3v.2l-2.3 4.9-1.8-1.5c-.2-.1-.2-.3-.1-.5l.1-.2c.1-.2.4-.3.6-.2h.1l1 .8L7.9 31c.1-.2.3-.3.6-.2zm8.8-4.4c.3.5.2 1.1-.2 1.4l-5.3 3.9c-.2.1-.4.2-.6.2-.3 0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4l5.3-3.9c.4-.4 1.1-.3 1.4.2zm-.2-15.9l-5.3 3.8c-.2.1-.4.2-.6.2-.3 0-.6-.1-.8-.4-.3-.5-.2-1.1.2-1.4l5.3-3.8c.5-.3 1.1-.2 1.4.2.3.5.2 1.1-.2 1.4zm-8.4 3.3v.2l-2.3 4.9-1.8-1.5c-.2-.1-.2-.3-.1-.5l.1-.2c.1-.2.4-.3.6-.2h.1l1 .8L8 13.6c.1-.2.3-.3.6-.2 0 0 .1 0 .1.1-.1.1 0 .2 0 .3z"
      />
      <path
        fill="currentColor"
        d="M19.4 2.2l-1.6-.8c-.3-.1-.6 0-.9.2l-1.7 1.2c0-.2-.1-.3-.2-.4-.2 0-.4 0-.5.1l-1.8 1.3v-2c0-1-.8-1.8-1.8-1.8-.4 0-.8.1-1.1.3l-.4.3c-.9.7-1.5 1.7-1.5 2.9v3.7L5.7 8.7c-.2.2-.3.4-.3.7v.4L.7 13.1c-.4.3-.6.7-.6 1.2v25.8c0 .3.1.6.4.7l1.6.8c.3.1.7 0 .9-.2L19.1 30c.4-.3.6-.7.7-1.2V3c0-.4-.1-.6-.4-.8zm-10.2.6c.2-.8.9-1.5 1.7-1.6.7 0 .9.7.6 1.6-.2.8-.9 1.5-1.7 1.6-.6 0-.9-.7-.6-1.6zm9.7 25.3c0 .5-.3.9-.7 1.2L3.1 40.1c-.3.2-.6.3-.9.2-.3-.2-.4-.4-.4-.7v-6.5l17.1-12.2v7.2zm0-7.5L1.8 32.8v-8.4l17.1-12.2v8.4zm0-8.7L1.8 24.1v-8.7c0-.5.3-.9.7-1.2l2.8-2v.7c0 .2.1.3.2.4.2.1.4 0 .5-.1L14.7 7c.2-.2.4-.4.4-.7V5.2l2.5-1.8c.2-.2.6-.3.9-.2.3.2.5.5.4.8v7.9z"
      />
    </svg>
  );
}

export default SvgIsometricClipboard;

export const updateSingleCache = (previousCollection, newItem) => {
  // Are there keywords in the cache?
  const previousData = previousCollection?.data;
  if (!previousData) return previousCollection;

  // If there are no keywords in the cache, create a new array with the new keyword
  const hasPrevious = previousData?.length > 0;
  if (!hasPrevious) return previousCollection;

  // Replace the previous keyword where an ID matches
  const newData = previousData.map(collectionItem => {
    const isMatch = collectionItem.id === newItem.id;
    if (!isMatch) return collectionItem;
    return newItem;
  });

  return {
    ...previousCollection,
    data: newData,
  };
};

export default updateSingleCache;

import { fetchAPI } from 'particles';

type GetReportSingleCategoryParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  domainId?: ArgID;
  categories?: string;
  top?: string;
  where?: ArgWhere;
};

declare type CallGetReportSingleCategoryViewData = {
  data: string[];
  pagination: Pagination;
};

export const callGetReportSingleCategoryView = async (
  queryKey,
  parameters: GetReportSingleCategoryParameters,
): Promise<{ data: CallGetReportSingleCategoryViewData[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/pages/single-category-view/` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportSingleCategoryView;

import { fetchAPI } from 'particles';

type UpdateTeamParameters = {
  id: ArgID;
  domains?: Domain[];
  name?: ArgName;
};

export const callUpdateTeam = async (parameters: UpdateTeamParameters): Promise<{ data: Team }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `teams` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdateTeam;

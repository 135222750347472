import styled from 'styled-components';
import { shouldBeDark } from 'particles';

import actions from './partials/actions/actions.styles';
import toolbar from './partials/toolbar/toolbar.styles';
import keywordDecoratorStyles from './partials/keyword-decorator/keyword-decorator.styles';
let WebFont;

const fontPicker = props => {
  if (typeof window !== `undefined`) {
    (async () => (WebFont = await import(`webfontloader`)))();
  }

  const { domain } = props;
  const hasFont: Boolean = Boolean(domain?.config?.font);
  const isGoogle: Boolean = hasFont ? Boolean(domain?.config?.font?.type === `google`) : false;
  const hasColour: Boolean = Boolean(domain?.config?.font?.colour);
  const colour: String = hasColour ? domain?.config?.font?.colour : shouldBeDark() ? `var(--white)` : `var(--black)`;

  if (!hasFont) {
    return ``;
  } else if (hasFont && isGoogle && WebFont) {
    WebFont.load({
      google: {
        families: [`${domain?.config?.font?.family}:${domain?.config?.font?.weight}`]
      }
    });
  }

  return `
    .DraftEditor-root {
      color: ${colour};
      font-family: "${domain?.config?.font?.family}";
      font-weight: ${domain?.config?.font?.weight};
    }
  `;
};

const StyledEditor = styled.div`
  position: relative;

  .editor__wrapper {
    min-height: 250px;
    padding: 20px 20px 88px 20px;

    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
    border: 1px solid ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey300)`)};
    border-radius: 2px;
  }

  .editor__paragraph {
    margin-top: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .editor__font-size {
    font-size: ${props => props.fontSize}px;
  }

  .editor__word-count {
    bottom: 16px;
    left: 20px;
    position: absolute;
  }

  .editor__word-count--invalid {
    color: var(--red);
  }

  .editor__word-count--divider {
    bottom: 32px;
    left: 20px;
    position: absolute;
    width: calc(100% - 40px);

    background-color: var(--grey300);
    color: var(--grey300);
  }

  ${props => fontPicker(props)}
  ${toolbar}
  ${actions}
  ${keywordDecoratorStyles}
`;

export default StyledEditor;

import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { differencesBetweenObjects } from 'corigan';
import { selectChange } from 'corigan';
import { windowAvailable } from 'corigan';
import { updateSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Card } from 'corigan';
import { Col } from 'corigan';
import { Field } from 'corigan';
import { Select } from 'corigan';
import { Error } from 'corigan';
import { Info } from 'corigan';

// Localised partials
import Content from './content';
import Promote from './promote';
import Stats from './stats';
import BriefedKeywordUses from './briefedKeywordUses';
import LiveKeywordUses from './liveKeywordUses';
import Tags from './tags';

// API
import { callUpdateKeyword } from 'corigan';
import { callGetManyTags } from 'corigan';

type WrapperProps = {
  data: any;
  url: string;
};

const Wrapper = (props: WrapperProps) => {
  const { data: keyword, url } = props;
  const { phrase } = keyword;
  const [title, setTitle] = useState(phrase);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const { userHasPermission: canReadPages } = useHasPermissions({ requiredPermissions: [`pages:read`] });
  const { userHasPermission: canReadTags } = useHasPermissions({ requiredPermissions: [`tags:read`] });

  // Fetch tags info
  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyTags`, { perPage: 1000, whereProtected }],
    callGetManyTags,
    {
      enabled: canReadTags,
    },
  );
  const data = res?.data;

  return (
    <Content data={keyword} title={title}>
      <Col xxl={6}>
        <Card loading={loading}>
          {error && <Error error={error} />}
          {!loading && <KeywordOverview keyword={keyword} tagsArray={data} setTitle={setTitle} urlParam={url}/>}
        </Card>
      </Col>
      {canReadPages && (
        <Col xxl={6}>
          <Card>
            <BriefedKeywordUses keyword={keyword} />
          </Card>
          <Card>
            <LiveKeywordUses keyword={keyword} />
          </Card>
        </Col>
      )}
    </Content>
  );
};

const successMessage = keyword => {
  return `The keyword "${keyword}" has been successfully updated in the system.`;
};

type FormErrors = {
  keywordId?: ArgKeywordId;
  phrase?: ArgPhrase;
  url?: ArgURL;
};

type FormValues = {
  keywordId: ArgKeywordId;
  phrase: ArgPhrase;
  tags?: [];
  url: ArgURL;
};

const buildTagObjects = (tagsArray: Tag[]) => {
  const hasTags = tagsArray.length > 0;
  if (!hasTags) return undefined;
  return tagsArray.map(({ id, name }) => ({ label: name, value: id }));
};

type RequestCompleteProps = {
  keyword: any;
  tagsArray: Tag[];
  setTitle: any;
  urlParam?: any;
};

const KeywordOverview = (props: RequestCompleteProps) => {
  const { keyword, setTitle, tagsArray, urlParam } = props;
  const { id } = keyword;

  const [success, setSuccess] = useState(null);

  const queryCache = useQueryCache();

  const { userHasPermission: canUpdateKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:update`],
  });

  const { userHasPermission: canApprovePage } = useHasPermissions({
    requiredPermissions: [`pages:approve`],
  });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = applicationContext?.dispatch;
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const hostname = domainActive?.hostname;
  const prefix: string = `https://` + hostname + `/`;

  const tagOptions = useMemo(() => {
    const formattedTags = buildTagObjects(tagsArray);
    return formattedTags;
  }, [tagsArray]);

  const initialValues = {
    ...keyword,
  };

  const hasTags = keyword?.tags?.length > 0;
  if (hasTags) initialValues.tags = buildTagObjects(initialValues.tags);

  const updateKeyword = async values => {
    if (windowAvailable() && !canUpdateKeyword) window.alert(`You do not have permission to update keywords`);
    if (!canUpdateKeyword) return;

    const { tags: tagsArray, url } = values;
    let tags = [];
    if (tagsArray) tags = tagsArray.map(t => t.value);
    return await callUpdateKeyword({ id, keywordId, tags, url });
  };

  const [mutate, { error, isLoading: loading }] = useMutation(updateKeyword, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const _with: ArgWith = [`tags`, `updatedBy`];

      const keywordData: Keyword = data?.data;
      // If createKeyword function was successful, get values from API response
      const phrase = keywordData?.phrase;
      // Let the user know the keyword has been created before redirect begins
      setSuccess(successMessage(phrase));
      setTitle(phrase);

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetKeyword`, { id, whereProtected }], data);
      queryCache.invalidateQueries([`callGetKeyword`, { id, _with }]);

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`], (previousKeywords: APIResponse) =>
        updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 10, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 25, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 50, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 100, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { perPage: 1000, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      // If Keyword ID is null, set it as the phrase
      values.keywordId = values.keywordId || values.phrase;

      const handleConfirm = () => mutate({ ...values });

      // Determine what has changed in the form
      const changedValues = differencesBetweenObjects(initialValues, values);

      // Have we changed the URL field?
      const hasChangedURL = changedValues.hasOwnProperty(`url`);

      // If no changes, update immediately
      if (!hasChangedURL) {
        await handleConfirm();
        return;
      }

      // Otherwise... confirm the changes
      const confirmText: string = `Editing this url will update all active pages that use it. Are you sure you want to update?`;
      const value = { handleConfirm, isOpen: true, message: confirmText };
      dispatch({ key: `modal`, type: `set`, value });
    },
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { phrase, url } = values;
      if (!phrase) errors.phrase = `Please specify a keyword phrase`;
      if (!url) errors.url = `Please specify a URL`;
      return errors;
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const { keywordId, highestRankingUrl, phrase, tags, url } = values;

  useEffect(() => {
    if (urlParam && String(urlParam) !== url) {
      setFieldValue(`url`, String(urlParam));
    }
  }, [urlParam, url, setFieldValue]);

  // Set the highestRankingUrl to an empty string if none provided by API
  const hasHighestRanking: boolean = Boolean(highestRankingUrl);
  const highestURL = hasHighestRanking ? highestRankingUrl : ``;

  const onSelectChange = event => {
    selectChange({
      event,
      field: `tags`,
      setFieldValue,
    });
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const onURLChange = e => {
    const value: string = e.target.value;
    let newFieldValue = value;

    const valid = newFieldValue?.startsWith(prefix);
    if (!valid) newFieldValue = prefix;

    setFieldValue(`url`, newFieldValue);
  };

  const isBusy: boolean = isSubmitting || loading;
  const locked: boolean = !canUpdateKeyword || isBusy;

  let buttonText: string = `Update Keyword`;
  if (isBusy) buttonText = `Updating Keyword`;

  const setTag = item => {
    setFieldValue(`tags`, item);
  };

  return (
    <React.Fragment>
      {success && (
        <Info onClose={onClose} y="none" className="mb-4">
          {success}
        </Info>
      )}
      {error && <Error error={error}>{error}</Error>}
      <Stats keyword={keyword} />
      <label className="mt-0" htmlFor="manage">
        Manage Keyword
      </label>
      {canUpdateKeyword && <Promote keyword={keyword} />}
      <form onSubmit={handleSubmit} aria-label="Manage a Keyword">
        <fieldset aria-busy={isBusy} disabled={locked}>
          <Field
            disabled={!canApprovePage}
            handleChange={handleChange}
            id="keywordId"
            label="Keyword ID"
            required={false}
            value={keywordId}
          />
          <Field
            disabled={true}
            handleChange={handleChange}
            id="phrase"
            label="Keyword"
            required={false}
            value={phrase}
          />
          <Field
            disabled={true}
            handleChange={handleChange}
            id="highestRankingUrl"
            label="Highest Ranking URL"
            openInTab={true}
            required={false}
            type="textarea"
            value={highestURL}
          />
          <Field
            {...formikHelpers}
            disabled={!canApprovePage}
            handleChange={onURLChange}
            id="url"
            label="URL"
            openInTab={true}
            required={true}
            type="textarea"
          />
          <label htmlFor="tags">Tags</label>
          <Select disabled={locked} onChange={onSelectChange} options={tagOptions} value={tags} />
          <label className="mt-3">
            Sort Tags
          </label>
          <p className="mb-2">You can order your tags here by dragging items. These tags will reflect how your coverage map will display, with the order of the tags being the levels in the coverage map.</p>
          <Tags tags={tags} setTags={setTag} />
          <label className="mt-3">
            Category map
          </label>
          <p className="mb-2">{tags.map((tag, i) => (
              <span key={i}>
                {tag?.label}
                {i < tags.length - 1 ? <strong> &gt; </strong> : null}
              </span>
          ))}</p>
          <button type="submit" disabled={locked}>
            {buttonText}
          </button>
        </fieldset>
      </form>
    </React.Fragment>
  );
};

export default Wrapper;

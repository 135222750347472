declare type ColourObject = {
  brand: BrandName;
  colour: string;
};

export const brandColours = (findBrand?: BrandName): string | ColourObject[] => {
  const amazon: ColourObject = { brand: `amazon`, colour: `#FF9900` };
  const argos: ColourObject = { brand: `argos`, colour: `#E63123` };
  const asda: ColourObject = { brand: `asda`, colour: `#78BE20` };
  const currys: ColourObject = { brand: `currys`, colour: `#4C13A3` };
  const debenhams: ColourObject = { brand: `debenhams`, colour: `#1a1919` };

  const dfs: ColourObject = { brand: `dfs`, colour: `#5d2583` };
  const diy: ColourObject = { brand: `diy`, colour: `#fa6600` };
  const dunelm: ColourObject = { brand: `dunelm`, colour: `#3f9c35` };
  const ebay: ColourObject = { brand: `ebay`, colour: `#771469` };
  const etsy: ColourObject = { brand: `etsy`, colour: `#f1641e` };
  const furniturevillage: ColourObject = { brand: `furniturevillage`, colour: `#2b99db` };
  const gumtree: ColourObject = { brand: `gumtree`, colour: `#73ef37` };
  const habitat: ColourObject = { brand: `habitat`, colour: `#262626` };
  const heals: ColourObject = { brand: `heals`, colour: `#1e1d1c` };
  const ikea: ColourObject = { brand: `ikea`, colour: `#fbcb03` };
  const johnlewis: ColourObject = { brand: `johnlewis`, colour: `#043e23` };
  const made: ColourObject = { brand: `made`, colour: `#545d63` };
  const next: ColourObject = { brand: `next`, colour: `#000000` };
  const sainsburys: ColourObject = { brand: `sainsburys`, colour: `#f06c00` };
  const screwfix: ColourObject = { brand: `screwfix`, colour: `#1b539f` };
  const sofology: ColourObject = { brand: `sofology`, colour: `#ec168c` };
  const wayfair: ColourObject = { brand: `wayfair`, colour: `#7f187f` };

  const prodirectsoccer: ColourObject = { brand: `prodirectsoccer`, colour: `#4a0fff` };
  const prodirectrunning: ColourObject = { brand: `prodirectrunning`, colour: `#4a0fff` };


  const jdsports: ColourObject = { brand: `jdsports`, colour: `#b30fc7` };
  const nike: ColourObject = { brand: `nike`, colour: `#ec168c` };
  const adidas: ColourObject = { brand: `adidas`, colour: `#73ef37` };
  const unisportstore: ColourObject = { brand: `unisportstore`, colour: `#ff00e0` };
  const sportsdirect: ColourObject = { brand: `sportsdirect`, colour: `#fbff00` };
  const eleventeamsports: ColourObject = { brand: `11teamsports`, colour: `#fbcb03` };
  const lovellsports: ColourObject = { brand: `lovellsports`, colour: `#ff2f2f` };
  const mandmdirect: ColourObject = { brand: `mandmdirect`, colour: `#31adff` };
  const decathlon: ColourObject = { brand: `decathlon`, colour: `#a73816` };
  const expressfootballkits: ColourObject = { brand: `expressfootballkits`, colour: `#f06c00` };
  const directsoccer: ColourObject = { brand: `directsoccer`, colour: `#1b539f` };
  const intersport: ColourObject = { brand: `intersport`, colour: `#ff2f2f` };
  const futbolemotion: ColourObject = { brand: `futbolemotion`, colour: `#fbff00` };
  const calcioshop: ColourObject = { brand: `calcioshop`, colour: `#ff2f2f` };

  // Return the objects in alphabetical order
  const brands: ColourObject[] = [
    amazon,
    argos,
    asda,
    currys,
    debenhams,
    dfs,
    diy,
    dunelm,
    ebay,
    etsy,
    furniturevillage,
    gumtree,
    habitat,
    heals,
    ikea,
    johnlewis,
    made,
    next,
    sainsburys,
    screwfix,
    sofology,
    wayfair,
    prodirectsoccer,
    prodirectrunning,
    jdsports,
    nike,
    adidas,
    unisportstore,
    sportsdirect,
    eleventeamsports,
    lovellsports,
    mandmdirect,
    decathlon,
    expressfootballkits,
    directsoccer,
    intersport,
    futbolemotion,
    calcioshop,
  ];
  const sorted = brands.sort((a, b) => a.brand.localeCompare(b.brand));

  // If we're looking for a specific brand, then return a single brand object
  if (findBrand) {
    const found = brands.find(b => b.brand === findBrand)?.colour;
    if (found) return found;
    return null;
  }

  return sorted;
};

export default brandColours;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import { ParsedQuery } from 'query-string';

// Particles
import { ApplicationContext } from 'corigan';
import { KRContext } from 'corigan';
import { fetchAPI } from 'corigan';
import { isString } from 'corigan';
import { ROUTES } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Local Partials
import StyledResearch from './research.styles';
import KeywordLookup from './partials/lookup';
import KeywordPie from './partials/market-pie';
import KeywordStats from './partials/stats';
import KeywordTables from './partials/tables';

// Local Partials - Charts
import KeywordRank from './partials/charts/rank';
import KeywordSearchConsole from './partials/charts/search-console';
import KeywordVolume from './partials/charts/volume';

// API
import { callGetReportKeywordSERP } from 'corigan';

const ParentWrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`keywords:read`]}>
    <Page application="keyword-research" pageTitle="Research">
      <StyledResearch>
        <KeywordResearchResearch />
      </StyledResearch>
    </Page>
  </ProtectedRoute>
);

declare type Args = {
  domainId: ArgID;
  id: ArgID;
};

declare type KeywordRes = {
  data: Keyword;
};

const KeywordResearchResearch: React.FC = () => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;
  const hasDomainID: boolean = Boolean(domainId);

  const { userHasPermission: canReadPages } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const searchKeywordByID = useCallback(async (args: Args): Promise<KeywordRes> => {
    const { id } = args;
    return await fetchAPI({ parameters: { id }, route: `keywords` });
  }, []);

  const queryParams: ParsedQuery<string> = useQueryParameters();
  const passedID: string | string[] = queryParams?.id;
  const hasID: boolean = isString(passedID);

  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;

  const keyword: Keyword = state?.keyword;
  const phrase: string = keyword?.phrase;
  const hasPhrase: boolean = Boolean(phrase);

  const enabled: boolean = passedID && !keyword && hasDomainID;
  const { data: res, error, isLoading: loading } = useQuery([{ id: passedID }], searchKeywordByID, {
    enabled,
  });

  const data: Keyword = res?.data;

  // Reset keyword loaded on initial render
  useEffect(() => {
    if (dispatch) dispatch({ key: `keyword`, value: null });
  }, []);

  // If we change domainId then reset the keyword in state
  useEffect(() => {
    if (dispatch) dispatch({ key: `keyword`, value: null });
  }, [dispatch, domainId]);

  // Set the keyword, if there is no keyword in the state store
  useEffect(() => {
    const shouldRun: boolean = keyword?.id !== data?.id;
    if (!shouldRun) return;

    if (dispatch) dispatch({ key: `keyword`, value: data });
  }, [data]);

  const canLoad: string[] = state?.canLoad;
  const pages: Page[] = state?.pages;
  const hasPages: boolean = pages?.length > 0;
  const showChart: boolean = hasPages && hasPhrase;

  const showStats: boolean = showChart && canLoad.includes(`stats`);
  const showHistory: boolean = showChart && canLoad.includes(`history`);
  // const showShare: boolean = showChart && canLoad.includes(`share`);
  const showVolume: boolean = showChart && canLoad.includes(`volume`);

  const title: string = `Keyword Research`;

  let classList = `kr__header`;
  if (showStats) classList += ` kr__header--loaded`;

  return (
    <React.Fragment>
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.keyword_research}>Research Portal</Link>
              <h1>{title}</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row key="row-lookup" equalHeight={true}>
          <Col className={classList} xl={12}>
            <KeywordLookup error={error} hasID={hasID} loading={loading} />
            <AnimatePresence>{showStats && <KeywordStats />}</AnimatePresence>
          </Col>
        </Row>
      </Grid>
      <AnimatePresence>
        <Grid className="mt-3">
          <Row key="row-ranks" equalHeight={false}>
            <Col xl={7}>{keyword && <KeywordTablesWrapper />}</Col>
            <Col className="position--forward" full={true} xl={5}>
              {showHistory && (
                <Card animate={true}>
                  <KeywordRank volume={false} />
                </Card>
              )}
              {showHistory && (
                <Card animate={true} className="mt-3">
                  <KeywordSearchConsole volume={false} />
                </Card>
              )}
            </Col>
          </Row>
          <Row key="row-history">
            <Col xl={7}>
              {showVolume && (
                <Card animate={true}>
                  <KeywordVolume volume={true} />
                </Card>
              )}
            </Col>
            {/* <Col full={true} xl={5}>
              {showShare && <KeywordPie />}
            </Col> */}
          </Row>
        </Grid>
      </AnimatePresence>
    </React.Fragment>
  );
};

const KeywordTablesWrapper = props => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;
  const hasDomainID: boolean = Boolean(domainId);

  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;

  const keyword: Keyword = state?.keyword;
  const pages: Page[] = state?.pages;

  const args: ObjectLiteral = {
    domainId,
    keyword: keyword?.phrase,
  };

  const options: ObjectLiteral = {
    enabled: hasDomainID,
    retry: false,
  };

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetReportKeywordSERP`, args],
    callGetReportKeywordSERP,
    options,
  );

  const data: any = res?.data;
  const currentDomains: Domain[] = data?.currentDomain;

  const hasCurrentDomainData: boolean = currentDomains?.length > 0;
  const hasPagesSet: boolean = pages?.length > 0;

  useEffect(() => {
    dispatch({ type: `resetPages`, value: true });
  }, [keyword]);

  // Set the initial current domain page to state
  useEffect(() => {
    const shouldSet: boolean = hasCurrentDomainData && !hasPagesSet;
    if (!shouldSet) return;
    dispatch({ type: `togglePage`, value: { ...currentDomains[0], isCompetitors: false } });
  }, [keyword, hasCurrentDomainData, hasPagesSet]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Error error={error} />;

  return <KeywordTables {...props} data={data} loading={loading} />;
};

export default ParentWrapper;

import * as React from 'react';

import StyledInfo from './info.styles';

import { Clear } from 'icons';

type InfoProps = {
  /**
   * The primary content of the Info component, replaces 'info' prop if found
   */
  children: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  id?: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Title gives a short one liner description of the info
   */
  title?: string;
  y?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

const Info: React.FC<InfoProps> = (props: InfoProps) => {
  const { children, className, onClose, id, title, y } = props;

  let classList = `info`;
  if (onClose) classList += ` info--close`;
  if (className) classList += ` ${className}`;

  return (
    <StyledInfo className={classList} y={y}>
      {onClose && (
        <button className="info__action" onClick={onClose}>
          <Clear />
          <span className="hidden">Close info</span>
        </button>
      )}
      <div className="info__contents">
        {title && <strong className="info__title">{title}</strong>}
        <p id={id}>{children}</p>
      </div>
    </StyledInfo>
  );
};

Info.defaultProps = {
  y: `md`,
};

export default Info;

import * as React from 'react';
import { useState } from 'react';

// Fragments
import { Button } from 'corigan';
import OverviewModel from './overview';

export const OMModelview = (page: any) => {
  const [isOpen, setOpen] = useState(false);

  // button click
  const handleClick = e => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  return  (
    <React.Fragment>
      <Button onClick={handleClick} small>
        overview
      </Button>
      <OverviewModel isOpen={isOpen} setOpen={setOpen} page={page} />
    </React.Fragment>
  );
};

export default OMModelview;

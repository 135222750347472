import moment from 'moment';
import { toTitleCase } from 'corigan';

export const generateSeries = ({ data, chartDateEnd, chartDateStart }) => {
  const hasData = data?.length > 0;
  if (!hasData) return null;

  const end = chartDateEnd?.clone()?.add(1, `days`);
  const start = chartDateStart?.clone()?.subtract(1, `days`);

  const series = data.map(entry => {
    const name = entry?.name;
    let values = entry?.values;

    const hasValues = values?.length > 0;
    if (hasValues) values = entry?.values?.map(item => ({ x: item?.date, y: item?.value }));

    const valuesBetweenDates = values?.filter(v => {
      // If value is non existent or 0, then remove it from results
      const value = v?.y;
      if (!value) return false;

      const date = moment(v.x);

      const tooNew = date.isAfter(end);
      const tooOld = date.isBefore(start);
      const justRight = !tooNew && !tooOld;
      return justRight;
    });

    const hasValuesBetweenDates = valuesBetweenDates?.length > 0;
    if (!hasValuesBetweenDates) values = [];

    const data = valuesBetweenDates;

    return {
      name: toTitleCase(name),
      data,
    };
  });

  return series;
};

export default generateSeries;

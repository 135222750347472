import { css } from 'styled-components';

const apexCharts = css`
  .apexcharts-text {
    font-family: var(--font) !important;
  }

  .apexcharts-toolbar {
    z-index: 0 !important;
  }
`;

export default apexCharts;

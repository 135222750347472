import { fetchAPI } from 'particles';

declare type GetCrawlCategoriesParameters = {
  crawlId: ArgID;
};

declare type SubFolder = {
  crawlCategoryId: string;
  name: string;
  count: number;
};

declare type GetCrawlCategoriesResponse = SubFolder[];

export const callGetCrawlCategories = async (
  queryKey,
  parameters: GetCrawlCategoriesParameters,
): Promise<GetCrawlCategoriesResponse> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `crawls/reports/categories` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetCrawlCategories;

import { fetchAPI } from 'particles';

declare type GetReportDomainGSCArgs = {
  domain: string;
};

declare type Total = {
  total_clicks: number;
  total_impressions: number;
  average_ctr: number;
  date: string;
};

declare type TopQueries = {
  current: any[];
};

declare type TopMovers = {
  up: any[];
  down: any[];
};

declare type Data = {
  totals: Total[];
  topQueries: TopQueries;
  topMovers: TopMovers;
};

declare type Response = {
  data: Data;
};

export const callGetReportDomainGSC = async (reqKey, parameters: GetReportDomainGSCArgs): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/domain/gsc` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportDomainGSC;

import * as React from 'react';

// Components
import { Loader } from 'corigan';

// Localised partials
import Content from './content';

const RequestLoading = () => {
  return (
    <Content data={null} title="Fetching keyword info">
      <Loader />
    </Content>
  );
};

export default RequestLoading;

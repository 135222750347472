import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Button, Link, Table } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetManyTags } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`tags:read`]}>
    <TagsPage />
  </ProtectedRoute>
);

type TagsPageProps = {};

const TagsPage = (props: TagsPageProps) => {
  const { userHasPermission: canCreate } = useHasPermissions({ requiredPermissions: [`tags:create`] });

  return (
    <Page application="portal" pageTitle="Tags">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <h1>Tags</h1>
              {canCreate && <Button href={ROUTES.tags_create}>Create Tag</Button>}
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <TagsTable />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

/**
 * 'dbKey' property allows a column value to be passed to CrudTable.
 * The component will favour this property if it is present.
 */
export const columns = [
  {
    align: `left`,
    dbKey: `id`,
    filter: false,
    sort: true,
    hide: true,
    label: `ID`,
    numeric: false,
    value: `id`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `name`,
    filter: true,
    sort: true,
    hide: false,
    label: `Name`,
    numeric: false,
    value: `name`,
    wrap: false,
  },
];

export const generateRows = (data: Tag[]) => {
  return data.map(tag => {
    const id = { href: `${ROUTES.tag}?id=${tag.id}`, value: tag.id };
    const name = { href: `${ROUTES.tag}?id=${tag.id}`, value: tag.name };

    const item = {
      id,
      name,
      originalObject: tag,
    };

    return item;
  });
};

type TagsTableProps = {};

const TagsTable = (props: TagsTableProps) => {
  const [mounted, setMounted] = useState<boolean>(false);

  const { releaseVersion } = getRelease();
  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`tags:read`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=poTagsMasterheet${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Create initial API arguments
  const initialWhere: ArgWhere = undefined;
  const initialOrderBy: ArgOrderBy = undefined;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = undefined;

  // Read local storage values
  const localOrderBy: ArgOrderBy = localStorageRead(buildKey(`OrderBy`))
    ? localStorageRead(buildKey(`OrderBy`))
    : initialOrderBy;
  const localPage: ArgPage = localStorageRead(buildKey(`Page`)) ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = localStorageRead(buildKey(`PerPage`))
    ? localStorageRead(buildKey(`PerPage`))
    : initialPerPage;
  const localWhere: ArgWhere = localStorageRead(buildKey(`Where`)) ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }
    },
    [buildKey],
  );

  // Create API Functions for hanlding state
  const setOrderBy = value => setStateValue(`OrderBy`, value);
  const setPage = value => setStateValue(`Page`, value);
  const setPerPage = value => setStateValue(`PerPage`, value);
  const setWhere = value => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const queryFunction = callGetManyTags;
  const queryName = `callGetManyTags`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = canRead && hasDomain;

  // Fetch tags info
  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination = res?.pagination;

  const hasData: boolean = data?.length > 0;
  const items = hasData ? generateRows(data) : [];

  if (!mounted) return null;

  return (
    <Table
      apiArgs={apiArgs}
      apiDetails={apiDetails}
      apiFunctions={apiFunctions}
      allowFilters={true}
      collectionType="tag"
      csvTitle="tags"
      columns={columns}
      error={error}
      id="mastersheet"
      items={items}
      loading={loading}
      pagination={pagination}
      selectable={true}
      small={false}
      transfer={false}
    />
  );
};

export default Wrapper;

import * as React from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

// Particles
import { statuses } from 'particles';

declare type PageInfoProps = {
  status: string;
  newLockedUserId: string;
  currentLockedUserId: string;
  userId: string;
  locked: boolean;
};

const PageInfo: React.FC<PageInfoProps> = ({status, newLockedUserId, currentLockedUserId, userId, locked}: PageInfoProps) => {
  useEffect(() => {
    const lowerStatus = status?.toLowerCase();

    let message = ``;
    switch (lowerStatus) {
      case statuses.archived.toLowerCase():
        message = `You are currently viewing an archived revision`;
        break;
      case statuses.awaitingApproval.toLowerCase():
        message = `You are currently reviewing a submitted revision`;
        break;
      case statuses.briefed.toLowerCase():
        message = `You are currently viewing a briefed revision`;
        break;
      case statuses.draft.toLowerCase():
        message = `You are currently viewing a draft revision`;
        break;
      case statuses.live.toLowerCase():
        message = `You are currently viewing the latest live data`;
        break;
      case statuses.mismatched.toLowerCase():
        message = `You are currently viewing a mismatched revision`;
        break;
      case statuses.newPage.toLowerCase():
        message = `You are currently viewing a new page revision`;
        break;
      case statuses.rejected.toLowerCase():
        message = `You are currently viewing a rejected revision`;
        break;
      case statuses.readyToImplement.toLowerCase():
        message = `You are currently viewing a ready to implement revision`;
        break;
      case statuses.implemented.toLowerCase():
        message = `You are currently viewing the latest implemented data`;
        break;
    }

    if (!message) return;
    toast.info(message, {});
  }, [status]);

  useEffect(() => {
    if (newLockedUserId && newLockedUserId !== currentLockedUserId) {
      if (newLockedUserId === userId) return;

      toast.info(`The revision you are viewing is currently locked`, {});
    }
  }, [locked, newLockedUserId, currentLockedUserId, userId]);

  return null;
};

export default PageInfo;

import { fetchAPI } from 'particles';

type TableArgumentsToCSVArguments = {
  apiArgs: APIArgs;
  route: string;
};

export const callTableArgumentsToCSV = async (parameters: TableArgumentsToCSVArguments) => {
  const response = await fetchAPI({
    method: `GET`,
    parameters: { ...parameters.apiArgs },
    route: parameters.route,
  });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callTableArgumentsToCSV;

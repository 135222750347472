import { toTitleCase } from 'corigan';

export const generateStats = ({ changeLabel, data }) => {
  let allStats = [];

  const hasData = data?.length > 0;
  if (!hasData) return allStats;

  allStats = data.map(val => {
    const label = val?.name;
    // Round the figure to a whole number
    const figure = Math.ceil(Number(val?.value));

    const result = {
      change: val?.changePercentage,
      changeLabel,
      figure,
      label: toTitleCase(label),
    };
    return result;
  });

  return allStats;
};

export default generateStats;

import { useEffect, useState } from 'react';
import getFirebase from '../getFirebase'; // import our getFirebase function

export default function useFirebase() {
  const windowAvailable = typeof window !== `undefined`;
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    if (windowAvailable) setInstance(getFirebase());
  }, [windowAvailable]);

  return instance;
}

// Splits an array into an array of arrays :o
// E.g. arrayChunk([1, 2, 3], 1)
// Returns [[1], [2], [3]]

export function arrayChunk<T>(array: T[], chunkSize: number): T[][] {
  const R = [];
  for (let i = 0, len = array.length; i < len; i += chunkSize) R.push(array.slice(i, i + chunkSize));
  return R;
}

export default arrayChunk;

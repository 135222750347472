import * as React from 'react';
import { useContext, useState } from 'react';

// Components
import { Button } from 'atoms';

// Local Components
import { TableContext } from 'atoms';
import SelectedTagModal from './modal';

export type SelectedTagProps = {
  apiArgs: {
    orderBy?: ArgOrderBy | undefined;
    page?: ArgPage | undefined;
    perPage?: ArgPerPage | undefined;
    where?: ArgWhere | undefined;
    _with?: ArgWith | undefined;
  };
}

const SelectedTag: React.FC<SelectedTagProps> = (props: SelectedTagProps) => {
  const { apiArgs } = props;

  const [tagging, setTagging] = useState(false);
  const context = useContext(TableContext);
  const state = context?.state;
  const selected = state?.selected;

  const hasSelected = selected && selected.length > 0;
  if (!hasSelected) return null;

  const handleClick = e => {
    e.preventDefault();
    setTagging(!tagging);
  };

  return (
    <React.Fragment>
      <Button className="table__delete" onClick={handleClick} type="button">
        <span>Tag{tagging && `ging`}</span>
      </Button>
      {tagging && <SelectedTagModal setTagging={setTagging} tagging={tagging} apiArgs={apiArgs} />}
    </React.Fragment>
  );
};

export default SelectedTag;

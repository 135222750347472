import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import StyledHeader from './header.styles';

import Applications from './partials/applications';
import Domains from './partials/domains';
import Notifications from './partials/notifications';
import HeaderSearch from './partials/search';
import Support from './partials/support';
import User from './partials/user';

import { ApplicationContext } from 'corigan';
import { applicationDetails } from 'corigan';
import { ROUTES } from 'corigan';
import { callMe } from 'corigan';

import { Apps, AccountCircle } from 'icons';
import { ChevronDown, Help, Notifications as NotificationsIcon } from 'icons';

import { Link } from 'atoms';

type HeaderProps = {
  application: ApplicationName;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
};

declare type HeaderModes = 'applications' | 'default' | 'domains' | 'notifications' | 'support' | 'user';

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { application, className } = props;
  const context: ApplicationContextProps = useContext(ApplicationContext);

  // Used to determine the current application colours
  const applications = applicationDetails();
  const activeApplication = applications.find(app => app.value === application);
  const isPortal = application === `portal`;

  // Get colour values from the active application
  const colour = activeApplication?.colour;
  const colourLabel = activeApplication?.colourLabel;

  // Set active application colours as CSS Properties on header component
  let style = {} as CSSProperties;

  if (colour) style = { '--border-colour': `var(--grey200)` };
  if (colour && !isPortal) style = { '--border-colour': `${colour}` };
  if (colourLabel) style = { ...style, '--colour': `${colourLabel}` };

  // Get the user information
  const { data: res, isLoading: loading } = useQuery([`callMe`], callMe);
  const user = res?.data;

  const avatar = context?.state?.currentUser?.photoURL;
  const domains = context?.state?.domains;
  const domainActive: any = context?.state?.domainActive;
  const domainName = domainActive?.hostname;
  const emailAddress = user?.email;
  const name = user?.name;
  const profile: string = ROUTES.profile;

  const hasDomains: boolean = domains?.length > 0;
  const displayName: string = name ? name : emailAddress;

  const [mode, setMode] = useState<HeaderModes>(`default`);
  const [errorAvatar, setErrorAvatar] = useState<boolean>(false);

  const updateHeaderView = value => {
    const isCurrent = mode === value;
    setTimeout(() => {
      isCurrent ? setMode(`default`) : setMode(value);
    }, 100);
  };

  const handleClickApplications = e => {
    e.preventDefault();
    updateHeaderView(`applications`);
  };

  const handleClickDomains = e => {
    e.preventDefault();
    if (hasDomains) updateHeaderView(`domains`);
  };

  const handleClickNotifications = e => {
    e.preventDefault();
    updateHeaderView(`notifications`);
  };

  const handleClickSupport = e => {
    e.preventDefault();
    updateHeaderView(`support`);
  };

  const handleClickUser = e => {
    e.preventDefault();
    updateHeaderView(`user`);
  };

  const showApplications: boolean = mode === `applications`;
  const showDomains: boolean = mode === `domains`;
  const showNotifications: boolean = mode === `notifications`;
  const showSupport: boolean = mode === `support`;
  const showUserNavigation: boolean = mode === `user`;

  let classList: string = `header`;
  if (className) classList += ` ${className}`;

  let toViewNotification: boolean = false;
  const items = context?.state?.notifications?.items;
  const hasItems: boolean = items?.length > 0;
  if (hasItems) toViewNotification = items.some(n => n.status === `Unread`);

  const showUser: boolean = !loading;
  const defaultAvatar: boolean = !avatar || errorAvatar;
  const showAvatar: boolean = avatar && !errorAvatar;

  return (
    <StyledHeader className={classList} toViewNotification={toViewNotification} style={style}>
      <HeaderSearch />
      <nav className="header__actions">
        <nav className="header__action header__action__notifications">
          <button
            className={showNotifications ? `active` : `header__action__button`}
            onClick={handleClickNotifications}
          >
            <NotificationsIcon />
            <span className="hidden">Notifications</span>
          </button>
          <Notifications showNotifications={showNotifications} updateHeaderView={updateHeaderView} />
        </nav>
        <nav className="header__action header__action__support">
          <button className={showSupport ? `active` : `header__action__button`} onClick={handleClickSupport}>
            <Help />
            <span className="hidden">Support</span>
          </button>
          <Support showSupport={showSupport} updateHeaderView={updateHeaderView} />
        </nav>
        <nav className="header__action header__action__applications">
          <button className={showApplications ? `active` : `header__action__button`} onClick={handleClickApplications}>
            <Apps />
            <span className="hidden">Applications Draw</span>
          </button>
          <Applications showApplications={showApplications} updateHeaderView={updateHeaderView} />
        </nav>
      </nav>
      <nav className="header__domains header__action__domains">
        <span className="header__domains__wrap">
          <button
            className={
              showDomains
                ? `header__action__button header__domain__toggle active`
                : `header__action__button header__domain__toggle`
            }
            onClick={handleClickDomains}
          >
            <span className="header__domain">{domainName ? domainName : `Select Site`}</span>
            <ChevronDown />
          </button>
          {hasDomains && <Domains showDomains={showDomains} updateHeaderView={updateHeaderView} />}
        </span>
      </nav>
      {showUser && (
        <nav className="header__user">
          <span className="header__user__wrap">
            <button
              className={
                showUserNavigation
                  ? `header__action__button header__user__toggle active`
                  : `header__action__button header__user__toggle`
              }
              onClick={handleClickUser}
            >
              <span className="header__username">{displayName}</span>
              <ChevronDown />
            </button>
            <User showUserNavigation={showUserNavigation} updateHeaderView={updateHeaderView} />
          </span>
          <Link className="header__avatar" href={profile}>
            {showAvatar && (
              <img alt={`User avatar for ${displayName}`} onError={() => setErrorAvatar(true)} src={avatar} />
            )}
            {defaultAvatar && <AccountCircle />}
          </Link>
        </nav>
      )}
    </StyledHeader>
  );
};

// Default prop values
Header.defaultProps = {};

export default Header;

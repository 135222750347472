import * as React from 'react';

// Localised partials
import Content from './content';

const RequestLoading = () => {
  return <Content data={null} loading={true} title="Fetching tag info" />;
};

export default RequestLoading;

import { css } from 'styled-components';

const id = css`
  /* https://www.bram.us/2020/03/01/prevent-content-from-being-hidden-underneath-a-fixed-header-by-using-scroll-margin-top/  */
  :target {
    scroll-margin-top: 4rem;
  }
`;

export default id;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { navigate } from 'gatsby-link';
import { useQuery } from 'react-query';
import moment from 'moment';

// Particles
import { getWeeksBetween } from 'corigan';
import { brandColours } from 'corigan';
import { KRContext } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { BarChart, Pageview } from 'icons';
import { Button, NewTab, Sparkline } from 'corigan';
import { Tooltip } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';

import { statuses } from 'particles';

// Get an array of the last 12 month dates formatted
const today: moment.Moment = moment();

declare type RankData = {
  average_position: number;
  month: number;
  url: string;
  year: number;
};

declare type SearchData = {
  average_ctr: number;
  total_impressions: number;
  device: string;
  month: number;
  path: string;
  year: number;
};

declare type DataRowProps = {
  domain: string;
  position: number;
  rankWeekly: RankData[];
  rankMonthly: RankData[];
  gscWeekly: SearchData[];
  gscMonthly: SearchData[];
  title: string;
  url: string;
};

const DataRow = (props: DataRowProps) => {
  const rankWeekly: RankData[] = props?.rankWeekly;
  const position: number = props?.position;
  const title: string = props?.title;
  const url: string = props?.url;

  const { userHasPermission: canReadPages } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const isCompetitors: boolean = props?.gscMonthly !== undefined;
  const enableQuery = canReadPages && !isCompetitors;

  // Create a formated position string based on value
  let formattedPosition: string = ``;
  if (position) formattedPosition = String(position);

  // Do we have Rank position data?
  const hasRank: boolean = rankWeekly?.length >= 2;
  const getPosition = (rank: RankData): number => Math.round(rank.average_position);
  const seriesRank: number[] | undefined = useMemo(() => {
    if (!hasRank) return [];
    return rankWeekly.map(getPosition);
  }, [hasRank, rankWeekly]);

  // Query to see if the keyword is being used in an Optimisation Manager page
  const where: ArgWhere = `[url][eq]=${url}&where[status][eq]=${statuses.live}`;
  const perPage: ArgPerPage = 1;
  const options: ObjectLiteral = {
    enabled: enableQuery,
    retry: false,
  };
  const args: ObjectLiteral = { perPage, where };

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyPageRevisions`, args],
    callGetManyPageRevisions,
    options,
  );
  const data: ObjectLiteral = res?.data;

  // If we have a results from the query
  // then get the pageID value and revision ID
  const hasData: boolean = data?.length > 0;
  const pageId: string = hasData && data[0]?.pageId;
  const id: string = hasData && data[0]?.page;

  const hasID: boolean = isCompetitors && hasData && !loading;
  const hasURL: boolean = isCompetitors && !hasData && !loading;
  const showOM: boolean = isCompetitors && hasData && !error && !loading;

  const weeksAgo = seriesRank?.length;

  // What is the date x number of weeks previous to today
  const startDate: moment.Moment = useMemo(() => {
    return moment(today).subtract(weeksAgo, `weeks`);
  }, [weeksAgo]);

  // Get an array of ISO dates from the dates between now and x number of weeks previous to today
  const { dates } = useMemo(() => {
    return getWeeksBetween(startDate, today);
  }, [startDate]);

  // Return these dates as formatted date strings for the x-axis of a sparkline tooltip
  const categories = useMemo(() => {
    const hasDates: boolean = dates?.length > 0;
    if (!hasDates) return [];

    return dates?.map(date => (date ? moment(date).format(`Do MMM`) : ``));
  }, [dates]);

  // Convert the series to { x: formatted date, y: rank position }
  const series: { x: string; y: number }[] = useMemo(() => {
    const hasCategories: boolean = categories?.length > 0;
    if (!hasCategories) return null;

    const hasSeries: boolean = seriesRank?.length > 0;
    if (!hasSeries) return null;

    return seriesRank.map((rank, i) => ({
      x: categories?.[i],
      y: rank,
    }));
  }, [categories, seriesRank]);

  // Get the colour of the URL brand if available
  const brandingColour = useMemo(() => {
    if (!url) return null;

    const urlObject = new URL(url);
    if (!urlObject) return null;

    const { hostname } = urlObject;

    const brand = hostname?.replace(`www.`, ``)?.split(`.`)?.[0];
    return brandColours(brand);
  }, [url]) as string;

  // Memoise the options to prevent re-render
  const sparklineOptions: ChartOptions = useMemo(() => {
    const options: ChartOptions = {
      tooltip: {
        y: {
          title: {
            formatter: () => `Position`,
          },
        },
      },
      xaxis: {
        categories,
      },
      yaxis: {
        reversed: true,
      },
    };

    if (brandingColour) options.colors = [brandingColour];

    return options;
  }, [brandingColour, categories]);

  const classList: string = useMemo(() => {
    if (isCompetitors) return `background--green000`;
    return ``;
  }, [isCompetitors]);

  return (
    <tr className={classList}>
      <td className="text--nowrap text--center">{formattedPosition}</td>
      <td>{title}</td>
      {loading && <td />}
      {hasID && <td>{pageId}</td>}
      {hasURL && (
        <td className="text--wrap-all">
          <a href={url} target="_blank" rel="noopener norefferer">
            {url}
          </a>
        </td>
      )}
      {!isCompetitors && (
        <td className="text--wrap-all">
          <a href={url} target="_blank" rel="noopener norefferer">
            {url}
          </a>
        </td>
      )}
      <td>
        {hasRank && (
          <Sparkline id={`rank-${url}`} options={sparklineOptions} series={series} height="40px" width="120px" />
        )}
      </td>
      <td className="pl-2 text--nowrap">
        <History {...props} />
        {showOM && <OMLink id={id} />}
      </td>
    </tr>
  );
};

declare type OMLinkProps = {
  id: string;
};

const OMLink: React.FC<OMLinkProps> = (props: OMLinkProps) => {
  const { id } = props;
  const redirect: string = `${ROUTES.page}?id=${id}`;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    navigate(redirect);
  };

  return (
    <Tooltip content="View in Optimisation Manager" side="left">
      <button onClick={handleClick}>
        <Pageview />
        <span className="hidden">View Page in Optimisation Manager</span>
      </button>
    </Tooltip>
  );
};

declare type HistoryRowProps = DataRowProps;

const History: React.FC<HistoryRowProps> = (props: HistoryRowProps) => {
  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;

  const state: any = context?.state;
  const pages: PageRevision[] = state?.pages;
  const numberOfActivePages: number = pages?.length;

  const url: string = props?.url;
  const isActive: boolean = pages?.length > 0 && pages.some(page => page.url === url);

  const cannotRemove: boolean = numberOfActivePages === 1 && isActive;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e) e.preventDefault();
      const value: HistoryRowProps = { ...props };
      if (dispatch) dispatch({ type: `togglePage`, value });
    },
    [dispatch, props],
  );

  let variant: any = `primary`;
  if (isActive) variant = `green`;

  let tooltipText: string = `Add to History Chart`;
  if (isActive) tooltipText = `Remove from History Chart`;
  if (cannotRemove) tooltipText = `Active`;

  return useMemo(() => {
    return (
      <Tooltip content={tooltipText} side="left">
        <Button onClick={handleClick} variant={variant}>
          <BarChart />
          <span className="hidden">{tooltipText}</span>
        </Button>
      </Tooltip>
    );
  }, [handleClick, tooltipText, variant]);
};

export default DataRow;

import * as React from 'react';
import { useContext } from 'react';

// Icons
import { Trash } from 'icons';

// Particles
import { OMContext } from 'corigan';
import { Button } from 'corigan';

declare type PageCanonicalProps = {
  locked: boolean;
};

const PageCanonicals: React.FC<PageCanonicalProps> = (props: PageCanonicalProps) => {
  const locked = props?.locked;

  return (
    <section className="page__canonicals mt-4">
      <hr />
      <h3 className="m-0 mb-2">Linked Pages</h3>
      <CanonicalsTable locked={locked} />
    </section>
  );
};

declare type CanonicalsTableProps = {
  locked: boolean;
};

const CanonicalsTable: React.FC<CanonicalsTableProps> = (props: CanonicalsTableProps) => {
  const locked = props?.locked;

  const context = useContext(OMContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const editedRevision = state?.editedRevision;
  const canonicals = editedRevision?.canonicals || [];
  const hasCanonicals: boolean = canonicals?.length > 0;

  const onClickAdd = React.useCallback(
    e => {
      if (e) e.preventDefault();

      dispatch({ type: `canonicalAdd`, value: true });
    },
    [dispatch],
  );

  const onClickRemove = React.useCallback(
    (e, canonicalEntry: PageRevisionCanonical) => {
      if (e) e.preventDefault();

      dispatch({ type: `canonicalRemove`, value: canonicalEntry });
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (e, canonicalEntry: PageRevisionCanonical) => {
      const { value } = e.target;
      dispatch({ type: `canonicalUpdate`, value: { canonicalEntry, value } });
    },
    [dispatch],
  );

  return (
    <React.Fragment>
      {hasCanonicals && (
        <form>
          <fieldset disabled={locked} className="m-0">
            <table>
              <thead>
                <tr>
                  <th>URL</th>
                  <th>HTTP Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {canonicals?.map((canonicalEntry, i) => {
                  const id = canonicalEntry?.id || canonicalEntry?.createdAt;

                  const key: string = `linked-canonicals-entry-${id}`;
                  const identifier: string = key;

                  const handleRemove = e => onClickRemove(e, canonicalEntry);

                  return (
                    <tr key={key}>
                      <td>
                        <CanonicalURL
                          canonicals={canonicals}
                          canonicalEntry={canonicalEntry}
                          identifier={identifier}
                          i={i}
                          handleChange={handleChange}
                        />
                      </td>
                      <td>{canonicalEntry?.status || `Pending`}</td>
                      <td>
                        <Button className="m-0" variant="hollow" type="button" onClick={handleRemove}>
                          <span className="hidden">Remove Canonical URL</span>
                          <Trash />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </fieldset>
        </form>
      )}
      <button className="mt-1" disabled={locked} type="button" onClick={onClickAdd}>
        Add Canonical URL
      </button>
    </React.Fragment>
  );
};

declare type CanonicalURLProps = {
  canonicals: PageRevisionCanonical[];
  canonicalEntry: PageRevisionCanonical;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, canonicalEntry: PageRevisionCanonical) => void;
  identifier: string;
  i: number;
};

const CanonicalURL: React.FC<CanonicalURLProps> = (props: CanonicalURLProps) => {
  const { canonicalEntry, handleChange, identifier } = props;

  const inputRef = React.useRef(null);

  return (
    <input
      ref={inputRef}
      className="m-0"
      id={identifier}
      name={identifier}
      onChange={e => handleChange(e, canonicalEntry)}
      type="url"
      value={canonicalEntry.url}
    />
  );
};

export default PageCanonicals;

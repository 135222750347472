import * as React from 'react';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { toast } from 'react-toastify';

// Particles
import { callDeleteManyDomains, removeCollectionCache } from 'corigan';
import { ApplicationContext } from 'corigan';
import { callDeleteManyKeywords } from 'corigan';
import { callDeleteManyPages } from 'corigan';
import { callDeleteManyTags } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Button } from 'corigan';

// Local Components
import { TableContext } from 'corigan';

declare type SelectedDeleteProps = {
  apiArgs: {
    orderBy?: ArgOrderBy | undefined;
    page?: ArgPage | undefined;
    perPage?: ArgPerPage | undefined;
    where?: ArgWhere | undefined;
    _with?: ArgWith | undefined;
  };
};

const SelectedDelete = (props: SelectedDeleteProps) => {
  const { apiArgs } = props;
  const queryCache = useQueryCache();
  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const context = useContext(TableContext);
  const state = context?.state;
  const collectionType = state?.collectionType;
  const selected = state?.selected;
  const hasSelected: boolean = selected?.length > 0;

  const { userHasPermission: canDeleteKeywords } = useHasPermissions({
    requiredPermissions: [`keywords:delete`],
  });
  const { userHasPermission: canDeletePages } = useHasPermissions({
    requiredPermissions: [`pages:delete`],
  });
  const { userHasPermission: canDeleteTags } = useHasPermissions({
    requiredPermissions: [`tags:delete`],
  });
  const { userHasPermission: canDeleteDomains } = useHasPermissions({
    requiredPermissions: [`domains:delete`],
  });

  const [keywordsMutate, { isLoading: keywordsLoading }] = useMutation(callDeleteManyKeywords, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to delete ${collectionType}s`, {});

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const ids: [ArgID] = variables?.ids;

      // Optimistically update to the new value
      ids.map(id => queryCache.invalidateQueries([`callGetKeyword`, { id }]));

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 10 }],
        (previousKeywords: APIResponse) => removeCollectionCache(previousKeywords, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 25, }],
        (previousKeywords: APIResponse) => removeCollectionCache(previousKeywords, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 50 }],
        (previousKeywords: APIResponse) => removeCollectionCache(previousKeywords, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 100 }],
        (previousKeywords: APIResponse) => removeCollectionCache(previousKeywords, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 1000 }],
        (previousKeywords: APIResponse) => removeCollectionCache(previousKeywords, ids),
      );

      toast.success(`Keyword(s) successfully deleted`, {});
    },
  });

  const [pagesMutate, { isLoading: pagesLoading }] = useMutation(callDeleteManyPages, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to delete ${collectionType}s`, {});

      queryCache.invalidateQueries([`callGetManyPages`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const ids: [ArgID] = variables?.ids;

      // Optimistically update to the new value
      ids.map(id => queryCache.invalidateQueries([`callGetOnePageRevision`, { id }]));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`, { ...apiArgs, perPage: 10 }], (previousPages: APIResponse) =>
        removeCollectionCache(previousPages, ids, `page`),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`, { ...apiArgs, perPage: 25 }], (previousPages: APIResponse) =>
        removeCollectionCache(previousPages, ids, `page`),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`, { ...apiArgs, perPage: 52 }], (previousPages: APIResponse) =>
        removeCollectionCache(previousPages, ids, `page`),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`, { ...apiArgs, perPage: 100 }], (previousPages: APIResponse) =>
        removeCollectionCache(previousPages, ids, `page`),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`, { ...apiArgs, perPage: 1000 }], (previousPages: APIResponse) =>
        removeCollectionCache(previousPages, ids, `page`),
      );

      toast.success(`Page(s) successfully deleted`, {});
    },
  });

  const [tagsMutate, { isLoading: tagsLoading }] = useMutation(callDeleteManyTags, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to delete ${collectionType}s`, {});

      queryCache.invalidateQueries([`callGetManyTags`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const ids: [ArgID] = variables?.ids;

      // Optimistically update to the new value
      ids.map(id => queryCache.invalidateQueries([`callGetOneTag`, { id }]));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { ...apiArgs, perPage: 10 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { ...apiArgs, perPage: 25 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { ...apiArgs, perPage: 50 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { ...apiArgs, perPage: 100 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { ...apiArgs, perPage: 1000 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      toast.success(`Tag(s) successfully deleted`, {});
    },
  });

  const [domainsMutate, { isLoading: domainsLoading }] = useMutation(callDeleteManyDomains, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to delete ${collectionType}s`, {});

      queryCache.invalidateQueries([`callGetDomains`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const ids: [ArgID] = variables?.ids;

      // Optimistically update to the new value
      ids.map(id => queryCache.invalidateQueries([`callGetDomains`, { id }]));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetDomains`, { ...apiArgs, perPage: 10 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetDomains`, { ...apiArgs, perPage: 25 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetDomains`, { ...apiArgs, perPage: 50 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetDomains`, { ...apiArgs, perPage: 100 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetDomains`, { ...apiArgs, perPage: 1000 }], (previousTags: APIResponse) =>
        removeCollectionCache(previousTags, ids),
      );

      toast.success(`Dmains(s) successfully deleted`, {});
    },
  });

  // Return null instead of button if the user can't delete the items anyway
  switch (collectionType) {
    case `keyword`:
      if (!canDeleteKeywords) return null;
      break;
    case `page`:
      if (!canDeletePages) return null;
      break;
    case `tag`:
      if (!canDeleteTags) return null;
      break;
    case `domain`:
      if (!canDeleteDomains) return null;
      break;
  }

  const handleConfirm = async () => {
    // Confirm the user wants to delete the records
    if (!windowAvailable()) return;

    let apiArgs = { ids: selected };

    try {
      switch (collectionType) {
        case `keyword`:
          if (!canDeleteKeywords) {
            if (windowAvailable()) alert(`You do not have permission to delete keywords`);
            return;
          }

          const keywordIDs = selected.map(k => k.id).filter(Boolean);
          apiArgs = { ...apiArgs, ids: keywordIDs };
          await keywordsMutate(apiArgs);
          break;
        case `page`:
          if (!canDeletePages) {
            if (windowAvailable()) alert(`You do not have permission to delete pages`);
            return;
          }

          const pageIDs = selected.map(p => p.page.id).filter(Boolean);
          apiArgs = { ...apiArgs, ids: pageIDs };
          await pagesMutate(apiArgs);
          break;
        case `tag`:
          if (!canDeleteTags) {
            if (windowAvailable()) alert(`You do not have permission to tags pages`);
            return;
          }

          const tagIDs = selected.map(p => p.id).filter(Boolean);
          apiArgs = { ...apiArgs, ids: tagIDs };
          await tagsMutate(apiArgs);
          break;
        case `domain`:
          if (!canDeleteDomains) {
            if (windowAvailable()) alert(`You do not have permission to sites pages`);
            return;
           }
  
            const domainIDs = selected.map(p => p.id).filter(Boolean);
            apiArgs = { ...apiArgs, ids: domainIDs };
            await domainsMutate(apiArgs);
            break;  
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = async e => {
    if (e) e.preventDefault();

    const confirmText: string = `Deleting a ${collectionType} is permanent, are you sure you want to delete the selected ${collectionType}s?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  };

  if (!hasSelected) return null;

  const loading: boolean = keywordsLoading || pagesLoading || tagsLoading || domainsLoading;
  let buttonText: string = `Delete`;
  if (loading) buttonText = `Deleting`;

  return (
    <React.Fragment>
      <Button className="table__delete" disabled={loading} onClick={handleClick} type="button" variant="hollow">
        {buttonText}
      </Button>
    </React.Fragment>
  );
};

export default SelectedDelete;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

// Icons
import { Promoted } from 'icons';

// Particles
import { ApplicationContext } from 'corigan';
import ROUTES from 'routes';
import { addSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';
import { OMContext } from 'corigan';

// Atoms
import { Field } from 'corigan';
import { Link } from 'corigan';
import { Select } from 'corigan';
import { Toggle } from 'corigan';
import { Modal } from 'corigan';

// Molecules
import { Card } from 'corigan';
import { Error } from 'corigan';

// Organisms
import { Col } from 'corigan';
import { Grid } from 'corigan';
import { Row } from 'corigan';

// API
import { callCreateKeyword } from 'corigan';
import { callGetManyTags } from 'corigan';

declare type KeywordModelProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  selected: string;
};

const selectChange = ({ event, field, setFieldValue }) => {
  const noValueProvided = !event || !field;
  if (noValueProvided) setFieldValue([]);
  setFieldValue(field, event);
};

const successMessage = keyword => {
  return `The keyword "${keyword}" has been successfully created in the system.`;
};

type FormErrors = {
  phrase?: ArgPhrase;
  url?: ArgURL;
};

type FormValues = {
  keywordId: ArgKeywordId,
  phrase: ArgPhrase;
  url: ArgURL;
  tags?: [];
  highPriority: boolean;
};

const buildTagObjects = (tagsArray: Tag[]) => {
  return tagsArray.map(({ id, name }) => ({ label: name, value: id }));
};

const KeywordModalCreate = (props: KeywordModelProps) => {
  const { isOpen, setOpen, selected } = props;
  const queryCache = useQueryCache();
  const context = useContext(OMContext);
  const state = context?.state;
  const dispatch = context?.dispatch;

  const { userHasPermission: canReadTags } = useHasPermissions({ requiredPermissions: [`tags:read`] });
  const { userHasPermission: canCreateKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:create`],
  });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const linkedWordsEnable: boolean = domainActive?.config?.linkedWordsEnable ?? true;
  const hostname = domainActive?.hostname;
  const prefix: string = `https://` + hostname + `/`;

  const [addKeyword, setAddKeyword] = useState(true);

  const editedRevision: PageRevision = state?.editedRevision;

  // Fetch tags info
  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const { data: res, error: tagsError, isLoading: tagsLoading } = useQuery([`callGetManyTags`, { perPage: 1000, whereProtected }], callGetManyTags, {
    enabled: canReadTags,
  });
  const tagsArray = res?.data;

  const pageTitle = linkedWordsEnable ? `Create Keyword / Link from selection` : `Create Keyword from selection`;

  const tagOptions = useMemo(() => {
    if (!tagsArray) return [];
    const formattedTags = buildTagObjects(tagsArray);
    return formattedTags;
  }, [tagsArray]);

  const createKeyword = async values => {
    // Check their permission level
    if (!windowAvailable()) return;
    if (!canCreateKeyword) {
      window.alert(`You do not have permission to create a keyword`);
      return;
    }

    const { keywordId, phrase, url, tags: tagsArray, highPriority } = values;
    let tags = undefined;

    // If no keywordId, phrase or URL is available, function can't run
    if (!keywordId) {
      console.error(`No keyword ID provided`);
      return;
    }

    if (!phrase) {
      console.error(`No phrase provided`);
      return;
    }

    if (!url) {
      console.error(`No phrase provided`);
      return;
    }

    if (tagsArray) tags = tagsArray.map(t => t.value);
    return await callCreateKeyword({ keywordId, phrase, url, tags, highPriority, domain: domainActive.id });
  };

  const [mutate, { error, isLoading: loading }] = useMutation(createKeyword, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const _with: ArgWith = [`tags`];
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const keywordData: Keyword = data?.data;
      const phrase = keywordData?.phrase;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`], (previousKeywords: APIResponse) =>
        addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 10 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 25 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 50 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 100 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // add new keyword to page revision
      const current = editedRevision?.keywords;
      const newKeywords = [...current, keywordData];
      dispatch({ key: `keywords`, value: newKeywords });

      toast.info(successMessage(phrase), {});

      handleClose();
    },
  });

  // add selected to initial phrase value
  const initialValues: FormValues = {
    keywordId: selected,
    phrase: selected,
    url: ``,
    tags: [],
    highPriority: false,
  };

  const handleAddLinkedWord = ({phrase, url}) => {
    // add new linked word to page revision
    const current = editedRevision?.linkedWords || [];
    const newLinkedWords = [...current, { phrase, url }];

    dispatch({ key: `linkedWords`, value: newLinkedWords });

    handleClose();
  }

  const onSubmitKeyword = async values => {
    // If Keyword ID is null, set it as the phrase
    values.keywordId = values.keywordId || values.phrase;

    await mutate({ ...values })
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async values => addKeyword ? onSubmitKeyword(values) : handleAddLinkedWord(values),
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { phrase, url } = values;
      if (!phrase) errors.phrase = `Please specify a keyword`;
      if (!url) errors.url = `Please specify a URL`;
      return errors;
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };
  const { keywordId, phrase, tags, highPriority } = values;

  const handleToggleChange = () => {
    setFieldValue(`highPriority`, !highPriority);
  };

  const onSelectChange = event => {
    selectChange({
      event,
      field: `tags`,
      setFieldValue,
    });
  };

  const onURLChange = e => {
    const value: string = e.target.value;
    let newFieldValue = value;

    const valid = !addKeyword || newFieldValue?.startsWith(prefix);
    if (!valid) newFieldValue = prefix;

    setFieldValue(`url`, newFieldValue);
  };

  useEffect(() => {
    setFieldValue(`keywordId`, selected);
    setFieldValue(`phrase`, selected);
    setFieldValue(`url`, prefix);
  }, [prefix, selected, setFieldValue]);

  const isBusy: boolean = isSubmitting || loading;
  const locked: boolean = !canCreateKeyword || isBusy;

  let buttonText: string = addKeyword ? `Create Keyword` : `Create Link`;
  if (isBusy) buttonText = addKeyword ? `Creating Keyword` : `Creating Link`;

  const PromotedLabel = (
    <React.Fragment>
      <Promoted />
      {highPriority ? `Promoted` : `Promote`}
    </React.Fragment>
  );

  const AddTypeLabel = (
    <React.Fragment>
      {addKeyword ? `Adding selection as new keyword` : `Adding selection as link`}
    </React.Fragment>
  );

  // model open close functions
  const handleClose = () => {
    setOpen(false);
    formik.resetForm({
      values: { keywordId: selected, url: prefix, phrase: selected, tags: [], highPriority: false },
    });
  };

  return (
    <Modal handleConfirm={handleClose} handleClose={handleClose} isOpen={isOpen} title={pageTitle} enableConfirm={false}>
      <Grid>
        {linkedWordsEnable && (
          <Toggle id="editorPromoted" label={AddTypeLabel} onChange={() => { setAddKeyword(!addKeyword)}} on={addKeyword} />
        )}
        <Row>
          <Col xl={12}>
            <Card minHeight={false} loading={tagsLoading}>
              {!canCreateKeyword && (
                <React.Fragment>
                  <Error error="You do not have permission to create a keyword" />
                  <Link href={ROUTES.keyword_manager}>Return to all keywords</Link>
                </React.Fragment>
              )}
              {tagsError && <Error error={tagsError} />}
              {canCreateKeyword && (
                <form onSubmit={handleSubmit} aria-label="Create a Keyword" className="form--trimmed">
                  <fieldset aria-busy={locked} disabled={locked}>
                    {error && <Error error={error}>{error}</Error>}
                    {addKeyword && <Field handleChange={handleChange} id="keywordId" label="Keyword ID" required={false} value={keywordId} />}
                    <Field handleChange={handleChange} id="phrase" label="Keyword" required={true} value={phrase} />
                    <Field {...formikHelpers} handleChange={onURLChange} id="url" label="URL" required={true} type="url" />
                    {addKeyword && (
                      <React.Fragment>
                        <label htmlFor="tags">Tags</label>
                        <Select disabled={locked} onChange={onSelectChange} options={tagOptions} value={tags} />
                        <div className="display--block my-1">
                          <Toggle id="editorPromoted" label={PromotedLabel} onChange={handleToggleChange} on={highPriority} />
                        </div>
                      </React.Fragment>
                    )}
                      <button type="submit">{buttonText}</button>
                  </fieldset>
                </form>
              )}
            </Card>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};

export default KeywordModalCreate;

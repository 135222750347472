import type { ContentState } from 'draft-js';

import { getKeywordsDetails } from './getKeywordsDetails';

declare type DetailedKeyword = (Keyword | LinkedWord) & KeywordDetails;
declare type KeywordDetails = {
  matchesCount?: number;
  warning?: string;
};

declare type Args = {
  currentContent: ContentState;
  keywords: Array<Keyword | LinkedWord>;
};

declare type Res = {
  updatedContent: ContentState;
};

const scanKeywordConflicts = (args: Args): Res => {
  const { currentContent, keywords } = args;
  const contentBlockMap = currentContent.getBlockMap();
  const editorKeywords: DetailedKeyword[] = [...keywords];

  // Loops through each content block
  const keywordEntities = [];

  contentBlockMap.forEach(contentBlock => {
    const callback = position => {
      const entityKey = contentBlock.getEntityAt(position);
      const linkInstance = currentContent.getEntity(entityKey);

      const data: {
        keyword: string;
        linked: boolean;
        url: string;
        matchesCount?: number;
        warning?: string;
      } = linkInstance.getData();

      const keyword = data?.keyword;
      keywordEntities.push({ data, entityKey, keyword });
    };

    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      if (!entityKey) return false;

      const entityLookup = currentContent?.getEntity(entityKey);
      const entityData = entityLookup?.data;

      const isKeyword: boolean = Boolean(entityData?.keyword);
      return isKeyword;
    }, callback);

    const keywordsDetails = getKeywordsDetails({ editorKeywords, entities: keywordEntities });

    keywordEntities.forEach(entity => {
      const newEntity = { ...entity };
      const { entityKey } = newEntity;

      const link: DetailedKeyword = keywordsDetails.find(keyword => {
        return keyword.phrase === newEntity.keyword;
      });

      const matchesCount: number = link?.matchesCount ? link.matchesCount : 0;
      const warning: string = link?.warning;

      currentContent.mergeEntityData(entityKey, { matchesCount, warning });
    });
  });

  const updatedContent = currentContent;
  return { updatedContent };
};

export { scanKeywordConflicts };
export default scanKeywordConflicts;

import * as React from 'react';

// Components
import { Error } from 'corigan';

declare type TableErrorProps = { error: any };

const TableError: React.FC<TableErrorProps> = (props: TableErrorProps) => {
  const { error } = props;

  return (
    <tr>
      <td>
        <Error error={error} />
      </td>
    </tr>
  );
};

export default TableError;

import * as React from 'react';
import { useContext } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Atoms
import { Button } from 'corigan';

// API
import { callGetPageRevision } from 'corigan';

// Localised partials
import { revisionWith } from '../../../_with';

declare type AwaitPageProps = {
  permissions: PermissionLevelName[];
  hideNoPermissions: boolean;
  text?: string;
  status: string;
};

const AwaitPage: React.FC<AwaitPageProps> = (props: AwaitPageProps) => {
  const { permissions, hideNoPermissions } = props;

  const { userHasPermission } = useHasPermissions({ requiredPermissions: permissions });
  if (!userHasPermission && hideNoPermissions) return null;

  return <AwaitingButton userHasPermission={userHasPermission} {...props} />;
};

declare type AwaitProps = {
  userHasPermission: boolean;
  text?: string;
  status: string;
};

const _with: ArgWith = revisionWith;

const AwaitingButton: React.FC<AwaitProps> = (props: AwaitProps) => {
  const { text, status } = props;
  let id: string;
  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const appDispatch: any = appContext?.dispatch;
  const omContext: OMContextProps = useContext(OMContext);
  const omDispatch: any = omContext?.dispatch;
  const state = omContext?.state;
  const revisionStatusArray = state?.revisionStatusArray;

  const buttonText: string = text ?? `View ${status} Revision`;

  // check against revisionStatusArray in state to show button and set id
  let showbutton = false;
  if(revisionStatusArray.length > 0){
    for (const revision of revisionStatusArray) {
      if (revision.status === status) {
        id = revision.id;
        showbutton = true;
      }
    }
  }

  const handleRevisionSwitch = async () => {
    // If no ID is available, function can't run
    if (!id) {
      console.error(`No ID provided to handleRevisionSwitch function`);
      return;
    }

    // Confirm the user wants to override the current state of the page
    if (!windowAvailable()) {
      console.error(`No windowAvailable to handleRevisionSwitch function`);
      return;
    }

    try {
      const response = await callGetPageRevision(undefined, { id, _with });
      const value = response?.data;

      omDispatch({ type: `load`, value });

      //  Switch the tab focus
      const tabID = `om-page-content`;
      const state = appContext?.state;
      const tabs = state?.tabs;
      const hasTabs = tabs.length > 0;
      const otherTabs = !hasTabs ? [] : tabs.filter(tab => tab.id !== tabID);
      const hasOtherTabs = otherTabs.length > 0;

      if (!hasOtherTabs) {
        appDispatch({ type: `set`, key: `tabs`, value: [{ id: tabID, focus: 1 }] });
        return;
      }

      const newTabsState = [...otherTabs, { id: tabID, focus: 1 }];
      appDispatch({ type: `set`, key: `tabs`, value: newTabsState });
      omDispatch({ type: `set`, key: `error`, value: null });
    } catch (error) {
      console.error(error);
      omDispatch({ type: `set`, key: `error`, value: error });
    }
  };

  const handleConfirm = async () => await handleRevisionSwitch();

  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Loading a revision will remove the current state of the page. Are you sure you want to remove the edited
          content, and load a revision?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    appDispatch({ key: `modal`, type: `set`, value });
  };

  return (
    <>
    {showbutton && (
      <Button onClick={handleClick}>
        {buttonText}
      </Button>
    )}
    </>
  );
};

export default AwaitPage;

import { fetchAPI } from 'particles';

declare type GetReportPageCompetitorArgs = {
  url: string;
  internal: boolean;
  competitor: string;
  domainId: string;
};

declare type CallGetReportPageCompetitorData = {
  data: PerformanceComparisonPage;
};

export const callGetReportPageCompetitor = async (
  reqKey,
  parameters: GetReportPageCompetitorArgs,
): Promise<CallGetReportPageCompetitorData> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/pages/competitor` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export type { CallGetReportPageCompetitorData };
export default callGetReportPageCompetitor;

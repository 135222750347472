import styled from 'styled-components';
import { hexToRGB } from 'helpers';
import { shouldBeDark } from 'particles';

const infoBackground = props => {
  const hex = shouldBeDark() ? props.theme.white : props.theme.primary;
  const rgb = hexToRGB(hex);
  return `rgba(${rgb}, 0.1)`;
};

const infoSpacing = props => {
  if (!props.y) return `16px 0`;

  switch (props.y) {
    case `none`:
      return `0`;
    case `xs`:
      return `4px 0`;
    case `sm`:
      return `8px 0`;
    case `md`:
      return `16px 0`;
    case `lg`:
      return `24px 0`;
    case `xl`:
      return `32px 0`;
    case `xxl`:
      return `48px 0`;
    default:
      return `16px 0`;
  }
};

const InfoStyles = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row-reverse;
  margin: ${props => infoSpacing(props)};
  padding: 16px;

  background-color: ${props => infoBackground(props)};
  border: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};

  border-radius: 2px;

  .info__action {
    /* Button Reset CSS */
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    margin-left: auto;
    width: auto;
  }

  p {
    margin: 0;
  }

  strong {
    display: block;
    margin-bottom: 1rem;
  }

  .info__contents {
    margin-right: auto;

    p,
    strong {
      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};
    }
  }

  &.info--close {
    .info__contents {
      padding-right: 8px;
    }
  }
`;

export default InfoStyles;

import * as React from 'react';

// Components
import { OpenInNew } from 'icons';
import { Link } from 'corigan';

import StyledNewTab from './new-tab.styles';

declare type NewTabProps = {
  className?: string;
  href: string;
  style?: any;
  text: string;
  variant?: 'tiny' | 'small' | 'default' | 'large' | 'largest';
};

const NewTab = (props: NewTabProps) => {
  const { className, href, style, text, variant } = props;

  return (
    <StyledNewTab className={className} style={style} variant={variant}>
      <Link href={href} target="_blank" rel="noopener norefferer">
        <OpenInNew />
        <span className="hide">{text}</span>
      </Link>
    </StyledNewTab>
  );
};

export default NewTab;

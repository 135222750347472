import { css } from 'styled-components';

const main = css`
  main {
    flex: 1;
    margin: 0 auto;
    width: 100%;
  }

  .wrapper__contents main {
    max-width: 1920px;
  }
`;

export default main;

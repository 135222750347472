export const deepMerge = (target, source) => {
  Object.entries(source).forEach(([key, value]) => {
    if (value && typeof value === `object` && !Array.isArray(value)) {
      deepMerge((target[key] = target[key] || {}), value);
      return;
    }

    if (target && typeof target === `object` && !Array.isArray(target)) target[key] = value;
  });
  return target;
};

export default deepMerge;

import styled from 'styled-components';
import { device } from 'particles';

const Styledhalf = styled.section`
  @media ${device?.lg} {
    display: flex;
  }

  > * {
    flex: 1;
    width: 100%;
  }

  a {
    display: inline-block;

    color: var(--grey600);
    text-decoration: none;
    transition: 0.2s color ease;

    &:active,
    &:focus,
    &:hover {
      color: var(--black);
    }

    + a {
      @media ${device?.xs} {
        margin-left: 8px;
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  h1 {
    margin: 0;
    margin-bottom: 16px;

    font-size: 32px;
    font-weight: 700;
    line-height: 21px;
  }

  p {
    font-size: 18px;
    color: var(--grey600);
  }

  &.half--titled {
    h1 {
      font-size: 35px;
      letter-spacing: 5px;
      text-transform: uppercase;
    }
  }

  .half__contents {
    max-width: 480px;
    width: 100%;
  }

  .half__footer {
    nav {
      display: flex;

      a {
        color: var(--grey800);
        font-size: 14px;
        line-height: 15px;

        + a {
          margin-left: 8px;

          @media ${device?.xs} {
            margin-left: 12px;
          }
        }
      }
    }
  }

  .half__header {
    margin-bottom: 24px;

    text-align: center;

    @media ${device?.xs} {
      margin-bottom: 32px;
    }

    p {
      margin-top: 12px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .half__media {
    position: relative;

    background-color: #e9a500;

    img,
    picture,
    video {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;

      object-fit: cover;
    }
  }

  .half__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding: 32px;
  }
`;
export default Styledhalf;

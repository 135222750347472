import * as React from 'react';

function SvgIsometricBuilder(props) {
  return (
    <svg viewBox="0 0 40.16 42.53" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        d="M16.71 15.6L5.12 23.8c-.17.12-.37.18-.57.18a.996.996 0 01-.58-1.81l11.58-8.2a.999.999 0 011.4.24c.32.45.21 1.07-.24 1.39zM19.6 19.95L5.12 30.2c-.17.12-.37.18-.57.18a.994.994 0 01-.58-1.81l14.48-10.25a.994.994 0 011.39.23c.32.45.21 1.08-.24 1.4zM14.31 29.18l-9.19 6.5a.93.93 0 01-.57.19c-.32 0-.62-.15-.82-.43a.996.996 0 01.24-1.39l9.18-6.5c.45-.32 1.07-.21 1.39.24s.22 1.07-.23 1.39z"
      />
      <path
        fill="currentColor"
        d="M40.15 22.83v-2h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1l-.24-.18.58-.41c.33-.23.53-.59.55-.99l.03-6.47a.608.608 0 00-.34-.59c-.17-.07-2.3-1.07-2.49-1.15a.794.794 0 00-.74.14l-5.58 3.91c-.08.06-.16.12-.22.2v.01a.72.72 0 00-.21.12L.58 18.44c-.33.23-.54.59-.55.99L0 40.8v.46c-.02.25.11.48.32.59.17.08 1.19.55 1.37.64.26.09.55.04.76-.13l19.92-14.02c.33-.23.53-.6.55-1l.02-14.15c.11.05.18.08.21.1a.8.8 0 00.77-.14l2.97-2.08v14.87l1.26.89v.01h2v-1h-1v-14h1v1h1v1h1v1h1v1h1v1h1v1h1v1h1v1h1v1h1v1h-4v2h1v2h1v1.99h1v-2h-1v-2h-1v-1h4zm-17.97-10l-.02 13.97c-.01.4-.22.76-.54.99l-19 13.45a.8.8 0 01-.77.14c-.01 0-.01-.01-.02-.01a.634.634 0 01-.3-.58l.03-20.41c.01-.4.22-.76.55-.99L20.37 6.47l.74-.53c.2-.16.47-.23.72-.15h.01c0 .01.01.01.01.01.01 0 .02.01.02.01.22.11.34.34.32.58l-.01 6.44zm4.38-3.73l-2.08-1.7.33-.7L26.4 8l1.75-3.76.49.4-2.08 4.46z"
      />
      <path
        fill="currentColor"
        d="M31.15 25.83l-1 .01v-1h1zm0-1.99h1v.99h-1zm3 2h1v1.99h-1zm-1-2h1v1.99h-1zm-1-1h1v.99h-1zm3 5h2v.99h-2z"
      />
    </svg>
  );
}

export default SvgIsometricBuilder;

import * as React from 'react';

function SvgList(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M.1 13.3h2.6v-2.6H.1v2.6zm0 5.3h2.6V16H.1v2.6zM.1 8h2.6V5.4H.1V8zm5.3 5.3h18.5v-2.6H5.4v2.6zm0 5.3h18.5V16H5.4v2.6zm0-13.2V8h18.5V5.4H5.4z"
        fill="currentColor"
      />
      <path d="M0 0h24v24H0V0z" fill="none" />
    </svg>
  );
}

export default SvgList;

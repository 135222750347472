import * as React from 'react';

function SvgSystemUpdateAlt(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 16.9l4.4-4.4h-3.3V2.7h-2.2v9.8H7.6l4.4 4.4zm9.8-14.2h-6.5v2.2h6.5v15.3H2.2V4.9h6.5V2.7H2.2C1 2.7 0 3.7 0 4.9v15.3c0 1.2 1 2.2 2.2 2.2h19.6c1.2 0 2.2-1 2.2-2.2V4.9c0-1.2-1-2.2-2.2-2.2z"
      />
    </svg>
  );
}

export default SvgSystemUpdateAlt;

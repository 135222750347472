import * as React from 'react';
import { ItalicButton, BoldButton, UnderlineButton, UnorderedListButton, OrderedListButton } from 'draft-js-buttons';
import { InlineToolBarPlugin } from 'draft-js-inline-toolbar-plugin';
import { Button } from 'atoms';
import { Link } from 'icons';
import { KeywordAdd } from 'icons';

type ToolbarProps = {
  inlineToolbarPlugin: InlineToolBarPlugin;
  handleLink: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  link?: boolean;
  linkStatus?: boolean;
  addKeywordLink?: boolean;
  isOpen?: boolean;
  setOpen?: Function;
};

const Toolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const { inlineToolbarPlugin, handleLink, linkStatus, link, isOpen, setOpen, addKeywordLink } = props;
  const { InlineToolbar } = inlineToolbarPlugin;

  // define keyword click handler
  const keywordCreateHandler = e => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  // define keyword create button
  const KeywordCreateButton = () => {
    return (
      <div className="editor__toolbar__link" onMouseDown={e => e.preventDefault()} role="toolbar">
        <Button onClick={keywordCreateHandler}>
          <KeywordAdd />
        </Button>
      </div>
    );
  };

  return (
    <InlineToolbar>
      {externalProps => (
        <React.Fragment>
          {/* TS-Ignore needed because the inline toolbar props do not line up with the draft js button props */}
          {/* @ts-ignore */}
          <BoldButton {...externalProps} />
          {/* @ts-ignore */}
          <ItalicButton {...externalProps} />
          {/* @ts-ignore */}
          <UnderlineButton {...externalProps} />
          {/* @ts-ignore */}
          <UnorderedListButton {...externalProps} />
          {/* @ts-ignore */}
          <OrderedListButton {...externalProps} />
          {link && <LinkButton handleLink={handleLink} linkStatus={linkStatus} />}
          {/* @ts-ignore */}
          {addKeywordLink && <KeywordCreateButton />}
        </React.Fragment>
      )}
    </InlineToolbar>
  );
};

Toolbar.defaultProps = {
  link: true,
  linkStatus: false,
};

declare type LinkButtonProps = {
  handleLink: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  linkStatus?: boolean;
};

const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const { handleLink, linkStatus } = props;
  let className = `editor__toolbar__link__button`;
  if (linkStatus) className += ` editor__toolbar__link__button--active`;

  return (
    <div className="editor__toolbar__link" onMouseDown={e => e.preventDefault()} role="toolbar">
      <Button className={className} onClick={handleLink}>
        <Link />
      </Button>
    </div>
  );
};

export default Toolbar;

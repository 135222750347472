import * as React from 'react';

// Icons
import { ArrowDown } from 'icons';

// Local Particles
import StyledChange from './change.styles';

declare type StatisticProps = {
  value: number;
};

const Change: React.FC<StatisticProps> = (props: StatisticProps) => {
  const { value } = props;

  const isNegative: boolean = value < 0;

  let classList: string = ``;
  if (isNegative) classList = `change--negative`;

  return (
    <StyledChange className={classList}>
      <p className="change">
        {value}
        <ArrowDown />
      </p>
    </StyledChange>
  );
};

export default Change;

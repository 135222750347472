import { fetchAPI } from 'particles';

type GetKeywordParameters = {
  id: ArgID;
  _with?: ArgWith;
};

export const callGetKeyword = async (queryKey, parameters: GetKeywordParameters): Promise<{ data: Keyword }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `keywords` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetKeyword;

import { fetchAPI } from 'particles';

declare type callGetExportRankingsMovementArgs = {
  where: ArgWhere;
  domain: ArgWhere;
};

declare type Response = {
  data: string;
};

export const callGetExportRankingsMovement = async (parameters: callGetExportRankingsMovementArgs): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `export/serp/movement` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetExportRankingsMovement;

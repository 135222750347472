import * as React from 'react';

import StyledAvatar from './avatar.styles';

type AvatarProps = {
  alt?: string;
  /**
   * The primary content of the Avatar component, replaces 'avatar' prop if found
   */
  children?: any;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  src?: string;
  y?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { alt, className, size, src } = props;
  let classList = `avatar`;
  if (className) classList += ` ${className}`;

  return (
    <StyledAvatar className={classList} size={size}>
      <img alt={alt} src={src} />
    </StyledAvatar>
  );
};

Avatar.defaultProps = {
  size: `md`,
  y: `md`,
};

export default Avatar;

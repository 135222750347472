import * as React from 'react';

// Particles
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

const CompetitorNews = () => (
  <ProtectedRoute>
    <Page application="competitor-research">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.competitor_research}>Competitor Research</Link>
              <h1>Competitor News</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <p>
                Basically fetching news on a brand term from IBM, we should build it even if its not being delivered in
                the final version.
              </p>
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

export default CompetitorNews;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { getRelease } from 'corigan';
import { useLocalStorage } from 'corigan';

import StyledKeys from './keys.styles';

type KeysProps = {
  bullets?: boolean;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * The innercontent of the Keys element
   */
  children?: React.ReactNode;
  id: string;
  initialOpen?: boolean;
  title?: string;
};

const Keys = (props: KeysProps) => {
  const { bullets = true, className, children, id, initialOpen = true, title } = props;

  const { releaseVersion } = getRelease();
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback((): string => {
    return `version=${releaseVersion}&key=${id}&domainActive=${domainActive?.id}`;
  }, [domainActive?.id, id, releaseVersion]);

  const [open, setOpen] = useLocalStorage(buildKey(), initialOpen);

  let classList = `keys`;
  if (className) classList += ` ${className}`;
  if (open) classList += ` keys--open`;
  if (bullets) classList += ` keys--bullets`;

  const handleClick = e => {
    if (e) e.preventDefault();
    setOpen(!open);
  };

  return (
    <StyledKeys className={classList}>
      {title && (
        <button onClick={handleClick}>
          <span>{title}</span>
        </button>
      )}
      <ul>{children}</ul>
    </StyledKeys>
  );
};

// Default prop values
Keys.defaultProps = {};

export default Keys;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { updateSingleCache } from 'corigan';
import { updateCollectionCache } from 'corigan';
import { arrayMerge } from 'corigan';
import { callUpdateManyPageRevisions } from 'corigan';
import { callUpdateKeyword } from 'corigan';
import { fetchAPI, isUndefined } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { TableContext } from 'atoms';
import { Button, Chip } from 'corigan';
import { Error } from 'corigan';
import { Modal } from 'corigan';
import { statuses } from 'particles';

declare type FormMode = 'add' | 'remove';

declare type TagModalProps = {
  setTagging: any;
  tagging: boolean;
  apiArgs: {
    orderBy?: ArgOrderBy | undefined;
    page?: ArgPage | undefined;
    perPage?: ArgPerPage | undefined;
    where?: ArgWhere | undefined;
    _with?: ArgWith | undefined;
  };
};

const TagModal: React.FC<TagModalProps> = (props: TagModalProps) => {
  const { setTagging, tagging, apiArgs } = props;
  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = appContext?.state?.domainActive;

  const { userHasPermission: canUpdateKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:update`],
  });
  const { userHasPermission: canUpdatePage } = useHasPermissions({
    requiredPermissions: [`pages:update`],
  });

  const title = `Manage Tags`;

  const context = useContext(TableContext);
  const state = context?.state;
  const collectionType = state?.collectionType;
  const selected = state?.selected;

  const [mutateKeyword, { error: errorKeyword, isLoading: loadingKeyword }] = useMutation(callUpdateKeyword, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => { },
    onSuccess: (data: APIResponse, variables) => {

      // If callUpdateKeyword function was successful, get values from API response
      const keywordData: Keyword = data?.data;
      const id = keywordData?.id;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetKeyword`, { id }], keywordData);

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 10 }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 25 }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 50 }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 100 }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { ...apiArgs, perPage: 1000 }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      handleClose();
    },
  });

  const [mutatePage, { error: errorPage, isLoading: loadingPage }] = useMutation(callUpdateManyPageRevisions, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyPageRevisions`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => { },
    onSuccess: (data: APIResponse, variables) => {
      // If callUpdateManyPageRevisions function was successful, get values from API response
      const pageRevisionsData: PageRevision = data?.data;

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyPageRevisions`, { ...apiArgs, perPage: 10 }],
        (previousPages: APIResponse) => updateCollectionCache(previousPages, pageRevisionsData),
      );

      queryCache.setQueryData(
        [`callGetManyPageRevisions`, { ...apiArgs, perPage: 25 }],
        (previousPages: APIResponse) => updateCollectionCache(previousPages, pageRevisionsData),
      );

      queryCache.setQueryData(
        [`callGetManyPageRevisions`, { ...apiArgs, perPage: 50 }],
        (previousPages: APIResponse) => updateCollectionCache(previousPages, pageRevisionsData),
      );

      queryCache.setQueryData(
        [`callGetManyPageRevisions`, { ...apiArgs, perPage: 100 }],
        (previousPages: APIResponse) => updateCollectionCache(previousPages, pageRevisionsData),
      );

      queryCache.setQueryData(
        [`callGetManyPageRevisions`, { ...apiArgs, perPage: 1000 }],
        (previousPages: APIResponse) => updateCollectionCache(previousPages, pageRevisionsData),
      );

      handleClose();
    },
  });

  const handleSubmit = async values => {
    const mode: FormMode = values?.mode;
    const shouldAdd: boolean = mode === `add`;
    const souldRemove: boolean = mode === `remove`;

    const tagObjects = values?.tags;
    const hasTags: boolean = tagObjects?.length > 0;

    if (!hasTags) return;

    const tags = tagObjects.map(tag => tag.id);
    const hasSelected: boolean = selected?.length > 0;

    switch (collectionType) {
      case `keyword`:
        if (!canUpdateKeyword) break;
        if (!hasSelected) break;

        await Promise.all(
          selected.map(async keyword => {
            const id = keyword.id;
            const keywordParms: any = { id };

            const hasCurrent = keyword?.tags?.length > 0;
            const current = hasCurrent ? keyword.tags.map(({ id }) => id) : [];

            let updated = [];
            if (shouldAdd) updated = arrayMerge(current, tags);
            if (souldRemove) updated = current.filter(tag => !tags.includes(tag));

            await mutateKeyword({ ...keywordParms, tags: updated, _with: [`tags`] });
          }),
        );
        break;
      case `page`:
        if (!canUpdatePage) break;
        if (!hasSelected) break;

        const pagerevisions = selected.map(revision => {
          const hasTags = revision?.tags?.length > 0;
          const currentTags = !hasTags ? [] : revision?.tags.map(t => t.id);
          const hasAtLeastOne = tags.some(tag => currentTags.includes(tag));

          // If the tags are the same then return null to the array (we skip this in a filter)
          const skipRevision = tags.every(tag => currentTags.includes(tag));
          if (shouldAdd && skipRevision) return null;

          // If the tags are empty, we have no tags to remove (skip revision)
          if (souldRemove && (!hasTags || !hasAtLeastOne)) return null;

          let updated = [];
          if (shouldAdd) updated = arrayMerge(currentTags, tags);
          if (souldRemove) updated = currentTags.filter(tag => !tags.includes(tag));

          return {
            _id: revision?.id,
            tags: updated,
          };
        });

        // Remove 'null' from array where we skipped the revision creation
        const validRevisions = pagerevisions.filter(Boolean);

        const hasRevisions = validRevisions?.length > 0;
        if (!hasRevisions) break;

        const pageParams: any = {
          domain: domainActive?.id,
          pageRevisions: validRevisions,
          _with: apiArgs._with,
        };

        await mutatePage(pageParams);
        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      mode: `add`,
      searchTag: ``,
      tags: [],
    },
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setTagging(false);
  };
  const handleConfirm = () => {
    setTagging(false);
  };

  const isBusy = loadingKeyword || loadingPage;
  let error = errorKeyword;
  if (errorPage) error = errorPage;

  if (!canUpdateKeyword && collectionType === `keyword`) return null;
  if (!canUpdatePage && collectionType === `page`) return null;

  return (
    <Modal handleClose={handleClose} handleConfirm={handleConfirm} isOpen={tagging} loading={isBusy} title={title}>
      <SearchTag formik={formik} isLocked={isBusy} submitError={error} />
    </Modal>
  );
};

const searchTagText = async ({ name, domainActive }) => {
  return await fetchAPI({
    parameters: {
      whereProtected: `[domain][eq]=${domainActive?.id}`,
      where: `[or][textSearch][textSearch]=${name}&where[or][name][contains]=${name}`,
    },
    route: `tags`,
  });
};

declare type SearchTagProps = {
  formik: any;
  isLocked: boolean;
  submitError: any;
};

const SearchTag: React.FC<SearchTagProps> = (props: SearchTagProps) => {
  const { formik, isLocked, submitError } = props;
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [, setSearching] = useState(false);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const { handleChange, handleSubmit, values } = formik;
  const searchTag = values?.searchTag;
  const hasData = results?.length > 0;
  const currentTags = values?.tags;
  const hasCurrent = currentTags?.length > 0;
  const context = useContext(TableContext);
  const state = context?.state;

  const selectedRows = state.selected.map(item => {
    return item.tags.map(tagItem => {
      return { id: tagItem.id, tagName: tagItem.name }
    })
  }).flat();

  let selectedTags = [];
  const map = new Map();
  for (const item of selectedRows) {
    if (!map.has(item.id)) {
      map.set(item.id, true);    // set any value to Map
      selectedTags.push({
        id: item.id,
        name: item.tagName
      });
    }
  }

  useEffect(() => {
    setError(null);
    const isLongEnough = searchTag.length >= 3;

    const dontSearch = isUndefined(searchTag) || !isLongEnough;
    if (dontSearch) {
      setSearching(false);
      setResults([]);
      return;
    }

    const callAsyncSearch = async () => {
      setSearching(true);
      try {
        const res = await searchTagText({ name: searchTag, domainActive });
        setResults(res.data);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setSearching(false);
      }
    };
    callAsyncSearch();
  }, [searchTag, domainActive]);

  const handleClick = (e, tag, remove, isExistingTags = false) => {
    if (e) e.preventDefault();
    const id = tag?.id;
    const name = tag?.name;
    const tagObject = {
      id,
      name,
    };

    let newTags = [...formik.values.tags];
    const exists = newTags.some(tag => tag.id === id);
    const addNewTag = !remove && !exists;
    const removeOldTag = remove && exists;

    if (isExistingTags) {
      const updatedSearchTags = [...selectedTags].filter(tag => tag.id !== id);
      selectedTags = updatedSearchTags;
    }
    if (addNewTag) newTags = [...newTags, tagObject];
    if (removeOldTag) newTags = newTags.filter(tag => tag.id !== id);

    formik.setFieldValue(`tags`, newTags);
  };

  const handleClickAttach = e => {
    const newMode: FormMode = `add`;
    if (e) e.preventDefault();
    formik.setFieldValue(`mode`, newMode, false);
    formik.handleSubmit();
  };

  const handleClickRemove = e => {
    const newMode: FormMode = `remove`;
    if (e) e.preventDefault();
    formik.setFieldValue(`mode`, newMode, false);
    formik.handleSubmit();
  };

  return (
    <form autoComplete="off" className="mt-4" onSubmit={handleSubmit}>
      {submitError && <Error error={submitError} />}
      {selectedTags ?
        <React.Fragment>
          <label htmlFor="searchTag">Current Tags</label>
          {selectedTags.map(selectedTagItem => {
            return (<Chip
              key={`remove-tag-${selectedTagItem.id}`}
              className="mt-0 mr-1"
              disabled={isLocked}
              onClick={e => handleClick(e, selectedTagItem, false, true)}
            >
              {selectedTagItem.name}
            </Chip>)
          })}
        </React.Fragment> : null}

      {hasCurrent && (
        <React.Fragment>
          <label htmlFor="searchTag">Tags to Update</label>
          <nav className="mb-3">
            {currentTags.map(tag => {
              const id = tag?.id;
              const name = tag?.name;

              if (!id || !name) return null;

              return (
                <Chip
                  key={`remove-tag-${id}`}
                  className="mt-0"
                  disabled={isLocked}
                  onClick={e => handleClick(e, tag, true)}
                >
                  {name}
                </Chip>
              );
            })}
          </nav>
        </React.Fragment>
      )}
      <label htmlFor="searchTag">Search For Tags</label>
      <input
        id="searchTag"
        name="searchTag"
        disabled={isLocked}
        onChange={handleChange}
        value={searchTag}
        type="search"
      />
      <div className="mt-2">
        {error && <Error error={error} />}
        {hasData &&
          results.map(tag => {
            const id = tag?.id;
            const name = tag?.name;

            if (!id || !name) return null;

            return (
              <Chip
                key={`add-tag-${id}`}
                className="mt-0"
                disabled={isLocked}
                onClick={e => handleClick(e, tag, false)}
              >
                {name}
              </Chip>
            );
          })}
      </div>
      <Button disabled={!hasCurrent} onClick={handleClickAttach} type="submit">
        Attach New Tags
      </Button>
      <Button className="ml-1" disabled={!hasCurrent} onClick={handleClickRemove} type="button">
        Remove Tags
      </Button>
    </form>
  );
};

export default TagModal;

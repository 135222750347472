import { isArray } from 'corigan';
import { ROUTES } from 'corigan';
import { ThemeDefault } from 'corigan';

declare type BrandInfo = {
  colour: string;
  colourLabel: string;
  label: string;
  route: string;
  value: ApplicationName;
};

declare type Args = {
  applicationName?: ApplicationName;
  applicationNames?: ApplicationName[];
};

export const applicationDetails = (args?: Args): BrandInfo | BrandInfo[] => {
  const applicationName = args?.applicationName;
  const applicationNames = args?.applicationNames;

  const portal: BrandInfo = {
    colour: ThemeDefault.primary,
    colourLabel: `#057a9f`,
    label: `Portal`,
    route: ROUTES.dashboard,
    value: `portal`,
  };

  const optimisationManager: BrandInfo = {
    colour: ThemeDefault.orange,
    colourLabel: `#c04c3d`,
    label: `Optimisation Manager`,
    route: ROUTES.optimisation_manager,
    value: `optimisation-manager`,
  };

  const keywordManager: BrandInfo = {
    colour: `#e9a500`,
    colourLabel: `#946900`,
    label: `Keyword Manager`,
    route: ROUTES.keyword_manager,
    value: `keyword-manager`,
  };

  const keywordResearch: BrandInfo = {
    colour: ThemeDefault.green,
    colourLabel: `#547c2a`,
    label: `Keyword Research`,
    route: ROUTES.keyword_research,
    value: `keyword-research`,
  };

  const competitorResearch: BrandInfo = {
    colour: ThemeDefault.teal,
    colourLabel: `#057a9f`,
    label: `Competitor Research`,
    route: ROUTES.competitor_research,
    value: `competitor-research`,
  };

  const reportBuilder: BrandInfo = {
    colour: ThemeDefault.blue,
    colourLabel: ThemeDefault.blue,
    label: `Report Builder`,
    route: ROUTES.report_builder,
    value: `report-builder`,
  };

  // Return the objects in order
  const apps: BrandInfo[] = [
    portal,
    optimisationManager,
    keywordManager,
    keywordResearch,
    competitorResearch,
    reportBuilder,
  ];

  const hasArgs: boolean = Boolean(applicationName) || Boolean(applicationNames);
  if (!hasArgs) return apps;

  const hasApplicationName: boolean = Boolean(applicationName);
  const hasApplicationNames: boolean = applicationNames?.length > 0 && isArray(applicationNames);
  const noValidArgs: boolean = !hasApplicationName || !hasApplicationName;

  if (noValidArgs) return apps;

  // If we're looking for specific applications, then filter
  if (hasApplicationNames) {
    const filtered = apps.filter(app => applicationNames.includes(app.value));
    if (filtered) return filtered;
    return null;
  }

  // If we're looking for a specific application, then return a single application object
  if (hasApplicationName) {
    const found = apps.find(app => app.value === applicationName);
    if (found) return found;
    return null;
  }

  return apps;
};

export default applicationDetails;

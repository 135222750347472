import * as React from 'react';

import StyledBadge, { StyledBadgeLink, StyledBadgeRoot } from './badge.styles';

type BadgeProps = {
  /**
   * Controls the alignment (x/y-axis) of the badge absolute to the element being wrapped
   */
  align?: {
    x: 'left' | 'center' | 'right';
    y: 'top' | 'center' | 'bottom';
  };
  /**
   * Accepts a Hexadecimal or RGBA string to override default background colours
   */
  background?: string;
  /**
   * The element in which to position the badge relative to
   */
  children: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * The innercontent of the Badge element
   */
  content: React.ReactNode;
  /**
   * Optional 'href' prop to link the badge to a page or action
   */
  href?: string;
  /**
   * Predefined variations of the badge component
   */
  variant?: ColourVariant;
  /**
   * Predefined sizes of the badge component
   */
  size?: 'small' | 'medium' | 'large';
};

const Badge: React.FC<BadgeProps> = (props: BadgeProps) => {
  const { children, content, href } = props;
  const data = { ...props };
  delete data.children;
  delete data.content;

  if (href) {
    return (
      <StyledBadgeRoot className="badge-root">
        <StyledBadgeLink {...data}>
          {content}
        </StyledBadgeLink>
        {children}
      </StyledBadgeRoot>
    );
  }

  return (
    <StyledBadgeRoot className="badge-root">
      <StyledBadge {...data}>
        {content}
      </StyledBadge>
      {children}
    </StyledBadgeRoot>
  );
};

Badge.defaultProps = {
  align: {
    x: `right`,
    y: `top`,
  },
  variant: `primary`,
};

export default Badge;

import React from 'react';
import { useContext, useMemo } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';

// Local Partials
import Overlay from '../overlay';

type DomainsProps = {
  showDomains: boolean;
  updateHeaderView: Function;
};

const Domains: React.FC<DomainsProps> = (props: DomainsProps) => {
  const { showDomains, updateHeaderView } = props;

  let className: string = `header__domain__buttons`;
  if (showDomains) className += ` header__overlay--show`;

  return (
    <Overlay active={showDomains} className={className} updateHeaderView={updateHeaderView}>
      <div className="header__domain__options">
        <DomainsOptions updateHeaderView={updateHeaderView} />
      </div>
    </Overlay>
  );
};

type DomainsOptionsProps = {
  updateHeaderView: Function;
};

const DomainsOptions: Function = (props: DomainsOptionsProps) => {
  const { updateHeaderView } = props;

  const omContext = useContext(OMContext);
  const startedEdit: boolean = Boolean(omContext?.state?.startedEdit);

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const domains = appContext?.state?.domains;
  const hasDomains: boolean = domains?.length > 0;

  // Determines if we should confirm the user wants to leave the page with unsaved changes
  const shouldConfirm: boolean = startedEdit;
  const confirmText = `Are you sure you want to update your domain? Doing so will leave this page, and unsaved changes will be lost.`;

  return useMemo(() => {
    return (
      <React.Fragment>
        {hasDomains &&
          domains.map(domain => {
            const { id, hostname } = domain;
            const className = `user__domain text--initial`;

            const handleConfirm = () => {
              dispatch({ key: `domainActive`, type: `set`, value: domain });
              updateHeaderView(`default`);
            };

            const handleClick = e => {
              if (e) e.preventDefault();

              if (!shouldConfirm) {
                handleConfirm();
                return;
              }

              const value = { handleConfirm, isOpen: true, message: confirmText };
              dispatch({ key: `modal`, type: `set`, value });
            };

            return (
              <button className={className} onClick={handleClick} key={`domain-${id}`}>
                <span className="user__domain__title">{hostname}</span>
              </button>
            );
          })}
      </React.Fragment>
    );
  }, [dispatch, domains, hasDomains, updateHeaderView]);
};

export default Domains;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

// Particles
import { copyRawTextToClipboard } from 'corigan';
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';
import { objectIsEmpty } from 'corigan';

// Components
import { Chip } from 'corigan';
import { Tooltip } from 'corigan';

// Helpers
import { cleanTextRegExp } from 'helpers';

declare type Props = {
  isLocked: boolean;
};

const CurrentKeywords: React.FC<Props> = (props: Props) => {
  const { isLocked } = props;
  const omContext = useContext(OMContext);
  const dispatch = omContext?.dispatch;
  const state = omContext?.state;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const linkedWordsEnable: boolean = domainActive?.config?.linkedWordsEnable ?? true;

  const editedRevision: PageRevision = state.editedRevision;
  const contentBlocks = editedRevision?.contentBlocks;
  const hasContentBlocks: boolean = contentBlocks?.length > 0;
  const keywords: Keyword[] = editedRevision?.keywords;
  const linkedWords = editedRevision?.linkedWords || [];

  const handleKeywordClick = useCallback(
    (e, id: string) => {
      e.preventDefault();
      const current: Keyword[] = editedRevision?.keywords;

      // If already exists in state, ignore the function
      const exists: boolean = current.some(k => k.id === id);
      if (!exists) return;

      // Create new array with removed keyword
      const newKeywords: Keyword[] = current.filter(k => k.id !== id);
      dispatch({ key: `keywords`, value: newKeywords });
    },
    [dispatch, editedRevision],
  );

  const handleLinkedWordClick = useCallback(
    (e, index: number) => {
      e.preventDefault();
      const current: LinkedWord[] = [...editedRevision?.linkedWords];

      // If already exists in state, ignore the function
      if (index > current.length) return;

      // Create new array with removed keyword
      const newLinkedWords: LinkedWord[] = current.filter((k, i) => i !== index);
      dispatch({ key: `linkedWords`, value: newLinkedWords });
    },
    [dispatch, editedRevision],
  );

  const handleChipClick = useCallback((e, url: string) => {
    if (e) e.preventDefault();
    const isChipClicked: boolean = e.target === e.currentTarget;
    if (!isChipClicked) return;

    try {
      copyRawTextToClipboard(url);
      toast.info(`Copied content to clipboard`, {});
    } catch (error) {
      console.error(error);
      toast.error(error.message, {});
    }
  }, []);

  return useMemo(() => {
    const hasKeywords: boolean = keywords?.length > 0;
    const hasLinkedWords: boolean = linkedWords?.length > 0;
    if (!(hasKeywords || hasLinkedWords)) {
      return (
        <p className="text--small">There are currently no added keywords. Please search and add some.</p>
      );
    }

    // define sorting variables
    const keywordsIndexes = [];
    let index = 0;

    // sort through contentblocks and get indexes for keywords
    if (hasContentBlocks) {
      contentBlocks.forEach(block => {
        keywords.forEach(keywordItem => {
          const indexPhrase = block?.content?.toLowerCase().indexOf(keywordItem?.phrase.toLowerCase());
          if (indexPhrase > -1 && !keywordsIndexes.some(({ keyword }) => keyword === keyword?.phrase)) {
            keywordsIndexes.push({
              keyword: keywordItem?.phrase,
              index: index + indexPhrase,
            });
          }
        });
        index += block?.content?.length;
      });
    }

    // add keywords not in contentblocks if they exist
    keywords.forEach(keyword => {
      if (keywordsIndexes.some(index => index?.keyword === keyword?.phrase) === false) {
        index += 1;
        keywordsIndexes.push({
          keyword: keyword?.phrase,
          index: index,
        });
      }
    });

    // sort keywordsIndexes array by indexes
    const kwsort = keywordsIndexes.sort((a, b) => { return a.index - b.index });

    // reduce kwsort array to sorter array of ordered keyword phrases for sorting main array
    const sorter = kwsort.map((keyword) => { return keyword?.keyword });

    // sort keywords array
    keywords.sort((a, b) => {
      return sorter.indexOf(a.phrase) - sorter.indexOf(b.phrase);
    });

    const linkedKeywordChips = [];
    const unlinkedKeywordChips = [];
    const headerContent: String = linkedWordsEnable ? `keyword / linked word` : `keyword`;

    keywords.forEach(keyword => {
      const { id, phrase, url, crawlResponse: { responseCode } = {} } = keyword;
      const key: string = `keyword-current-${id}`;
      let linked = false;

      let onClick = null;
      if (!isLocked) onClick = e => handleKeywordClick(e, id);

      const onChipClick = e => handleChipClick(e, url);

      let variant: 'primary' | 'orange' | 'plain' | 'red' = `primary`;
      if (isLocked) variant = null;

      if (hasContentBlocks) {
        let keywordDetails = undefined;

        contentBlocks.forEach(block => {
          const hasEntityMap: boolean = !objectIsEmpty(block?.contentEncoded?.entityMap);
          if (!hasEntityMap) return;

          const entityMap = Object.entries(block?.contentEncoded?.entityMap).map(
            ([index, object]: [string, ObjectLiteral]) => {
              const formatted = { key: index, ...object };
              return formatted;
            },
          );

          const entity = entityMap?.find(entity => {
            const keywordType = entity?.data?.keywordType;
            const keyword = entity?.data?.keyword;
            const match = keywordType === `keyword` && cleanTextRegExp(keyword) === cleanTextRegExp(phrase);
            return match;
          });

          if (entity) keywordDetails = entity?.data;
        });

        if (keywordDetails) {
          const { matchesCount, warning } = keywordDetails;
          const usedMoreThanOnce: boolean = matchesCount >= 2;
          if (usedMoreThanOnce) variant = `red`;
          if (warning) variant = `orange`;
          linked = keywordDetails.linked;
        } else {
          variant = `plain`;
        }
      }

      const keywordList = linked ? linkedKeywordChips : unlinkedKeywordChips;

      keywordList.push(
        <Tooltip content={url} key={key} side="left">
          <Chip onChipClick={onChipClick} onClick={onClick} variant={variant} responseCode={responseCode}>
            {phrase}
          </Chip>
        </Tooltip>
      );
    });

    const linkedWordChips = [];;
    const unlinkedWordChips = [];

    if (linkedWordsEnable) {
      linkedWords.forEach((linkedWord, index) => {
        const { phrase, url } = linkedWord;
        let linked = false;

        let onClick = null;
        if (!isLocked) onClick = e => handleLinkedWordClick(e, index);

        const onChipClick = e => handleChipClick(e, url);

        let variant: 'primary' | 'orange' | 'plain' | 'red' = `primary`;
        if (isLocked) variant = null;

        if (hasContentBlocks) {
          let keywordDetails = undefined;

          contentBlocks.forEach(block => {
            const hasEntityMap: boolean = !objectIsEmpty(block?.contentEncoded?.entityMap);
            if (!hasEntityMap) return;

            const entityMap = Object.entries(block?.contentEncoded?.entityMap).map(
              ([index, object]: [string, ObjectLiteral]) => {
                const formatted = { key: index, ...object };
                return formatted;
              },
            );

            const entity = entityMap?.find(entity => {
              const keywordType = entity?.data?.keywordType;
              const keyword = entity?.data?.keyword;
              const match = keywordType === `linkedWord` && cleanTextRegExp(keyword) === cleanTextRegExp(phrase);
              return match;
            });

            if (entity) keywordDetails = entity?.data;
          });

          if (keywordDetails) {
            const { matchesCount, warning } = keywordDetails;
            const usedMoreThanOnce: boolean = matchesCount >= 2;
            if (usedMoreThanOnce) variant = `red`;
            if (warning) variant = `orange`;
            linked = keywordDetails.linked;
          } else {
            variant = `plain`;
          }
        }

        const wordList = linked ? linkedWordChips : unlinkedWordChips;

        wordList.push(
          <Tooltip content={url} key={index} side="left">
            <Chip onChipClick={onChipClick} onClick={onClick} variant={variant}>
              {phrase}
            </Chip>
          </Tooltip>
        );
      })
    }

    return (
      <React.Fragment>
        <p className="mb-3 text--small">Clicking on a {headerContent} copies its URL to the clipboard.</p>
        <span className="text--small" >Linked Keywords</span>
        <hr className="mb-2 mt-0" />
        <div className="chip-spacer__response-code">{linkedKeywordChips}</div>
        <div className="m-3"></div>
        <span className="text--small mt-1" >Unlinked Keywords</span>
        <hr className="mb-2 mt-0" />
        <div className="chip-spacer__response-code">{unlinkedKeywordChips}</div>
        {linkedWordsEnable && (
          <div>
            <div className="m-3"></div>
            <span className="text--small mt-1" >Linked Words</span>
            <hr className="mb-2 mt-0" />
            <div className="chip-spacer__response-code">{linkedWordChips}</div>
            <div className="m-3"></div>
            <span className="text--small mt-1" >Unlinked Words</span>
            <hr className="mb-2 mt-0" />
            <div className="chip-spacer__response-code">{unlinkedWordChips}</div>
          </div>
        )}
      </React.Fragment>
    );
  }, [keywords, linkedWords, hasContentBlocks, linkedWordsEnable, contentBlocks, isLocked, handleKeywordClick, handleChipClick, handleLinkedWordClick]);
};

export default CurrentKeywords;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { navigate } from 'gatsby';

// Particles
import { ApplicationContext } from 'particles';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useIsMount } from 'corigan';

// Components
import { Col } from 'corigan';
import { Grid } from 'corigan';
import { Row } from 'corigan';
import { Page } from 'corigan';

const DeepDive = () => {
  const isMount = useIsMount();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  useEffect(() => {
    if (isMount) return;
    navigate(ROUTES.keyword_manager);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainActive]);

  return (
    <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`keywords:read`]}>
      <Page application="keyword-manager">
        <PageContents />
      </Page>
    </ProtectedRoute>
  );
};

const PageContents = () => {
  const { userHasPermission: canReadKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:read`],
  });
  console.info(canReadKeyword);

  return (
    <Grid>
      <Row>
        <Col>
          <h1>Keyword Manager</h1>
          <p>Explore data collected on a term, rank, urls (competitor), fast movers, search volumes.&nbsp;</p>
        </Col>
      </Row>
    </Grid>
  );
};

export default DeepDive;

import * as React from 'react';

import illustration from 'assets/images/shopping-illustration.png';

import StyledHalf from './half.styles';

import { Page } from 'templates';

import { Link } from 'atoms';

type HalfProps = {
  children?: any;
  footer?: {
    href: string;
    target?: string;
    title: string;
  }[];
  intro?: string;
  media?: {
    alt: string;
    src: string;
  };
  title?: string;
};

const Half: React.FC<HalfProps> = (props: HalfProps) => {
  const { children, footer, intro, media, title = `Corigan` } = props;

  const hasFooter: boolean = footer?.length > 0;
  const hasHeader: boolean = Boolean(intro) || Boolean(title);
  let isSiteTitle: boolean = false;

  const lowerTitle = title?.toLowerCase();
  if (lowerTitle === `corigan`) isSiteTitle = true;

  let classList: string = `half`;
  if (isSiteTitle) classList += ` half--titled`;

  return (
    <Page simple={true}>
      <StyledHalf className={classList}>
        <div className="half__media">
          {!media && <img alt="Illustration of people shopping" src={illustration} />}
          {media && <img alt={media.alt} src={media.src} />}
        </div>
        <div className="half__wrapper">
          <span />
          <div className="half__contents">
            {hasHeader && (
              <header className="half__header">
                {title && <h1>{title}</h1>}
                {intro && <p>{intro}</p>}
              </header>
            )}
            {children}
          </div>
          <footer className="half__footer">
            {hasFooter && (
              <nav>
                {footer.map(item => (
                  <Link key={`half-footer-item-${item.title}-${item.href}`} href={item.href} target={item.target}>
                    {item.title}
                  </Link>
                ))}
              </nav>
            )}
          </footer>
        </div>
      </StyledHalf>
    </Page>
  );
};

Half.defaultProps = {
  footer: [
    {
      href: `/terms-of-use`,
      title: `Terms of use`,
    },
    {
      href: `/privacy-policy`,
      title: `Privacy policy`,
    },
  ],
};

export default Half;

import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { datePretty } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Card } from 'corigan';
import { Chip } from 'corigan';
import { Link } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';

// Local partials
import TableError from '../error';
import { statuses } from 'particles';

declare type StatusCodeProps = {
  code: number;
  text: string;
};

export const StatusCode: React.FC<StatusCodeProps> = (props: StatusCodeProps) => {
  const { code, text } = props;
  if (!code) return null;

  // Get the code type 1xx, 2xx, 3xx, 4xx, 5xx
  const codeType = code.toString()[0];
  switch (codeType) {
    case `1`:
      return <Chip variant="green">{text}</Chip>;
    case `2`:
      return <Chip variant="green">{text}</Chip>;
    case `3`:
      return <Chip variant="orange">{text}</Chip>;
    case `4`:
      return <Chip variant="red">{text}</Chip>;
    case `5`:
      return <Chip variant="red">{text}</Chip>;
    default:
      return <Chip variant="plain">{text}</Chip>;
  }
};

const _with = [`currentRevision`, `page`];

declare type TableRequestProps = {};

const StatusIssues: React.FC<TableRequestProps> = (props: TableRequestProps) => {
  const linkStatus = { href: ROUTES.status_issues, title: `View all` };
  let content;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const where: ArgWhere = `[domain][eq]=${domainActive?.id}&where[page.crawlResponse.responseCode][gte]=300&where[status][eq]=${statuses.live}`;

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyPageRevisions`, { perPage: 5, where, _with }],
    callGetManyPageRevisions,
  );
  const data = res?.data;

  if (!canRead) return <TableError error="You do not have permission to view page revisions" />;
  if (error) content = <TableError error={error} />;

  const noIssues: boolean = !loading && (!data || data?.length === 0);

  if (noIssues) {
    content = (
      <tr className="table__row table--best__row">
        <td colSpan={4} className="text--nowrap">
          No issues found
        </td>
      </tr>
    );
  }

  const hasData: boolean = data?.length > 0;

  if (hasData)
    content = data.map((pageRevision: PageRevision) => {
      const { pageId, name } = pageRevision;
      const page: any = pageRevision.page;
      const status: string = pageRevision.status;
      const id = page?.id;
      const key = `status-${id}`;
      const href = `${ROUTES.page}?id=${id}&status=${status}`;
      const crawlResponse = page?.crawlResponse;
      const lastCrawled = crawlResponse?.lastCrawled;
      const response = crawlResponse?.responseCode;
      const previousResponse = crawlResponse?.previousCode ? crawlResponse?.previousCode : 0;

      const dateFormatted = !lastCrawled ? `` : datePretty(lastCrawled);

      let responseString;
      if(previousResponse > 0 && previousResponse !== response) {
        responseString = `${previousResponse} -> ${response}`;
      } else {
        responseString = `${response}`;
      }

      return (
        <tr key={key} className="table__row table--best__row">
          <td className="text--nowrap">
            <Link href={href}>{pageId}</Link>
          </td>
          <td className="text--nowrap">
            <Link href={href}>{name}</Link>
          </td>
          <td>
            <StatusCode code={response} text={responseString}></StatusCode>
          </td>
          <td>{dateFormatted}</td>
        </tr>
      );
    });

  return (
    <Card loading={loading} link={linkStatus} title="Status Changes">
      <table className="table table--small m-0" id="table-status" style={{tableLayout: `fixed`}}>
        <thead>
          <tr>
            <th>Page ID</th>
            <th>Page</th>
            <th>HTTP Status</th>
            <th>Last Crawled</th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
    </Card>
  );
};

export default StatusIssues;

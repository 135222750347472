import styled from 'styled-components';
import { device } from 'particles';

const StyledGrid = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media ${device?.xs} {
    .container {
      max-width: 540px;
    }
  }

  @media ${device?.sm} {
    .container {
      max-width: 720px;
    }
  }

  @media ${device?.md} {
    .container {
      max-width: 960px;
    }
  }

  @media ${device?.lg} {
    .container {
      max-width: 1140px;
    }
  }
`;

export default StyledGrid;

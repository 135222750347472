import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import moment from 'moment';

// Particles
import { brandColours } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { KRContext } from 'corigan';

// Components
import { ChartStackedArea } from 'corigan';

// Local Particles
import generateSeriesRank from './functions/generateSeriesRank';

// Local Paritals
import { StyledCharts } from '../charts.styled';
import { StyledHistory } from '../charts.styled';

declare type KeywordHistoryProps = {
  volume: Boolean;
};

const momentToday = moment();
const momentYearAgo = moment().subtract(365, `days`);

const KeywordHistory = (props: KeywordHistoryProps) => {
  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;

  const keyword: Keyword = state?.keyword;
  const phrase: string = keyword?.phrase;
  const pages: PageRevision[] = state?.pages;

  const end: moment.Moment = momentToday;
  const start: moment.Moment = momentYearAgo;

  const canLoad: string[] = state?.canLoad;
  const showVolume: boolean = canLoad.includes(`volume`);

  const { dates } = getWeeksBetween(start, end);

  const options: ObjectLiteral = useMemo(() => {
    return {
      xaxis: {
        type: `datetime`,
        categories: dates,
      },
    };
  }, [dates]);

  const seriesName: string = `Search Volume`;

  const rankSeries: any[] = useMemo(() => {
    return generateSeriesRank({ pages });
  }, [pages]);

  // Fixes bug when switching between views by removing undefined values
  const series: any[] = rankSeries?.filter(Boolean);
  const hasSeries: boolean = series?.length > 0;

  const title: string = `Average Weekly Rank Position`;
  const id: string = `keyword-history-history-chart`;

  const onLoadArea = useCallback(() => {
    if (showVolume) return;
    if (dispatch) dispatch({ type: `enableLoading`, value: `volume` });
  }, [dispatch, showVolume]);

  // Get an array of the brand colours, with their names
  const brandingColours = brandColours();

  // Create a cached array of colours
  const colours: string[] = useMemo(() => {
    // Loop over each enabled series
    const value = series.map(plot => {
      // Get the name from the plot (e.g. amazon.com)
      const { name } = plot;

      // Look up the brand colours array, and see if there's a match
      const definedColour = brandingColours.find(brandObject => {
        const { brand } = brandObject;

        // Lowercase strings for comparison
        const lowerBrand: string = brand?.toLowerCase();
        const lowerName: string = name?.toLowerCase();

        // Was there a match? Searching enabled plot for substring of brand name
        // E.g. does www.amazon.co.uk include amazon?
        const found: boolean = lowerName.includes(lowerBrand);
        return found;
      });

      // If found, return the defined colour
      if (definedColour?.colour) return definedColour.colour;

      // Otherwise return a shade of grey
      return `#afafaf`;
    });

    return value;
  }, [brandingColours, series]);

  const intLabels = (val: number): string => String(Math.round(val));

  const rankOptions: ChartOptions = useMemo(() => {
    const optionsObject: ChartOptions = {
      ...options,
      yaxis: {
        labels: {
          formatter: intLabels,
        },
        min: 1,
        reversed: true,
        seriesName,
      },
    };

    if (colours) optionsObject.colors = colours;

    return optionsObject;
  }, [colours, options, seriesName]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <StyledHistory className="history__navigation">
          <div className="history__phrase">
            {!phrase && <h2>{title}</h2>}
            {phrase && (
              <h2>
                {title} - {phrase}
              </h2>
            )}
          </div>
        </StyledHistory>
        {!hasSeries && <p>No series data was found for {title}</p>}
        <StyledCharts>
          {hasSeries && (
            <ChartStackedArea id={id} height="300px" options={rankOptions} onLoad={onLoadArea} series={series} />
          )}
        </StyledCharts>
      </React.Fragment>
    );
  }, [phrase, title, id, hasSeries, onLoadArea, rankOptions, series]);
};
export default KeywordHistory;

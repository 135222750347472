const windowAvailable: boolean = typeof window !== `undefined`;

export const localStorageRead = (key: string) => {
  try {
    // Gatsby specific build fix
    if (!windowAvailable) return undefined;

    // Get from local storage by key
    const item = window.localStorage.getItem(key);

    const wasUndefined = item === `undefined`;
    if (wasUndefined) return undefined;

    const itemParsed = JSON.parse(item);
    if (!itemParsed) return undefined;

    const { value } = itemParsed;

    const valueNull = value === `null`;
    if (valueNull) return null;

    const valueUndefined = value === `undefined`;
    if (valueUndefined) return undefined;

    const now = new Date();
    const hasExpiry: boolean = Boolean(itemParsed?.expiry);

    // compare the expiry time of the item with the current time
    if (hasExpiry && now.getTime() > itemParsed.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return undefined;
    }

    // Parse stored json
    return value ? value : undefined;
  } catch (error) {
    // If error also return initialValue
    console.error(error);

    return undefined;
  }
};

export default localStorageRead;

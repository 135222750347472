import * as React from 'react';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

const Help = () => (
  <ProtectedRoute>
    <Page application="portal" pageTitle="Help Center">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <h1>Help Center</h1>
            </Breadcrumbs>
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

export default Help;

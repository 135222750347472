import * as React from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

type DnDDroppableProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  droppableId: string;
};

const DnDDroppable: React.FC<DnDDroppableProps> = (props: DnDDroppableProps) => {
  const { children, className, droppableId } = props;

  return (
    <Droppable droppableId={droppableId}>
      {(provided: DroppableProvided) => (
        <div
          className={className}
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...provided.droppablePlaceholder}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DnDDroppable;

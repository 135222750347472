const windowAvailable: boolean = typeof window !== `undefined`;

export const localStorageRemove = async (key: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      // Gatsby specific build fix
      if (!windowAvailable) {
        resolve(true);
        return;
      }

      // Get from local storage by key
      const hasItem: boolean = Boolean(window.localStorage.hasOwnProperty(key));
      if (!hasItem) {
        resolve(true);
        return;
      }

      window.localStorage.removeItem(key);
      resolve(true);
    } catch (error) {
      // If error also return initialValue
      console.error(error);

      reject(error.message);
      return;
    }
  });
};

export default localStorageRemove;

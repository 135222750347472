import { useState } from 'react';

const windowAvailable: boolean = typeof window !== `undefined`;

export const useLocalStorage = (key: string, initialValue: any, alternate: boolean = false) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (alternate) return initialValue;

    try {
      // Gatsby specific build fix
      if (!windowAvailable) return initialValue;

      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      const wasUndefined = item === `undefined`;
      if (wasUndefined) return undefined;

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // .persists the new value to localStorage.

  const setValue = value => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;

    if (alternate) {
      setStoredValue(valueToStore);
      return;
    }

    try {
      // Save state
      setStoredValue(valueToStore);

      // If undefined then we will skip local storage value
      const wasUndefined: boolean = valueToStore === `undefined`;

      // Gatsby specific build fix
      if (wasUndefined) return;
      if (!windowAvailable) return;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;

import * as React from 'react';

// Components
import { Loader } from 'corigan';
import { Col, Row } from 'corigan';

// Localised partials
import Content from './content';

const RequestLoading = () => {
  return (
    <Content data={null} title="Fetching crawl info">
      <Row>
        <Col>
          <Loader />
        </Col>
      </Row>
    </Content>
  );
};

export default RequestLoading;

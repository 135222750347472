import * as React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

// Components
import { Button } from 'corigan';

// API
import { callGetExportRankingsMovement } from 'corigan';

type DailySERPRankingsExportProps = {
    where: ArgWhere;
    loading: boolean;
    domain: ArgWhere;
};

const DailySERPRankingsExport = (props: DailySERPRankingsExportProps) => {
  // export section
  const {where, loading, domain} = props;

  const apiArgs = { where, domain };

  const [movementRankingsExport, { isLoading }] = useMutation(callGetExportRankingsMovement, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      toast.error(`Unable to create export file`, {});
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {},
  });

  const handleExport = async e => {
    e.preventDefault();
    // fetch url
    const {data} = await movementRankingsExport(apiArgs);
    // generate link for download
    const link = document.createElement(`a`);
    link.setAttribute(`href`, data);
    link.setAttribute(`download`, data);
    // Append the Anchor tag in the actual web page or application
    document.body.appendChild(link);
    // Trigger the click event of the Anchor link
    link.click();
    // Remove the Anchor link form the web page or application
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <Button disabled={loading || isLoading} onClick={handleExport}>Export Results</Button>
    </React.Fragment>
  );
};

export default DailySERPRankingsExport;

import styled from 'styled-components';
import { device } from 'particles';
import { hexToRGB } from 'helpers';
import { shouldBeDark } from 'particles';

import button from 'styles/base/button';

const opaqueBackground = hex => {
  const rgb = hexToRGB(hex);
  return `rgba(${rgb}, 0.3)`;
};

const StyledModal = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -99;

  background-color: ${props => opaqueBackground(props.theme.black)};
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: initial;

  p {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .modal__content {
    padding: 24px;
    position: relative;
    margin: 0 auto;
    max-width: 80%;
    width: 100%;
    max-height: calc(100vh - 210px);
    overflow-y: auto;

    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    transform: translateY(20px);
    transition: transform 0.3s ease;

    // @media ${device?.sm} {
    //   max-width: 500px;
    // }

    // @media ${device?.xl} {
    //   max-width: 632px;
    //   padding: 32px;
    // }
  }

  .modal__footer {
    margin-top: 12px;

    button {
      ${button}

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: ${() => (shouldBeDark() ? `transparent` : `var(--black)`)};

        &::before {
          background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
        }
      }
    }

    button + button {
      margin-left: 4px;
    }
  }

  .modal__header {
    align-items: flex-start;
    display: flex;
    margin-bottom: 12px;
  }

  .modal__header__button {
    margin-left: auto;
    overflow: visible;

    font-size: 24px;
    font-weight: bold;
    line-height: 14px;
  }

  .modal__header__button {
    padding: 0;

    border: none;
    background-color: transparent;
    border-bottom: none;
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--grey700)`)};
    text-decoration: none;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      border-bottom: none;
      text-decoration: none;
    }
  }

  .modal__loader {
    height: 2px;
    padding: 0;

    > div {
      max-width: 100%;
      width: 100%;
    }
  }

  .modal__text {
    nav {
      display: flex;
      margin-top: 16px;

      > * ~ * {
        margin-left: 8px;
      }
    }
  }

  &.modal--show {
    visibility: visible;
    z-index: 101;

    opacity: 1;

    .modal__content {
      transform: translateY(0px);
    }
  }
`;

export default StyledModal;

import { fetchAPI } from 'particles';

declare type GetReportCompetitorCategoriesArgs = {
  url: string;
};

declare type CallGetReportCompetitorCategoriesData = {
  data: string[];
};

export const callGetReportCompetitorCategories = async (
  reqKey,
  parameters: GetReportCompetitorCategoriesArgs,
): Promise<CallGetReportCompetitorCategoriesData> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/pages/competitor-categories` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export type { CallGetReportCompetitorCategoriesData };
export default callGetReportCompetitorCategories;

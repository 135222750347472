import styled from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const StyledStatistic = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.2s color ease;

    * {
      transition: 0.2s color ease;
    }

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      color: ${() => (shouldBeDark() ? `var(--grey300)` : `var(--blue)`)};

      .statistic__change,
      .statistic__label {
        color: ${() => (shouldBeDark() ? `var(--grey300)` : `var(--blue700)`)};
      }

      .statistic__label {
        text-decoration: underline;
      }
    }
  }

  h3 {
    margin-bottom: 12px;

    font-size: 2.4rem;
  }

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  svg {
    height: 12px;
    margin-left: 4px;

    color: var(--green);
    transform: rotate(45deg);
  }

  .statistic__change,
  .statistic__label {
    margin-bottom: 4px;

    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    font-size: 1.4rem;
    font-weight: 400;

    @media ${device?.xxl} {
      font-size: 1.8rem;
    }
  }

  .statistic__figure {
    margin-bottom: 8px;
    font-size: 2.4rem;

    @media ${device?.xxl} {
      font-size: 3.2rem;
    }

    &.statistic--negative {
      svg {
        color: var(--red);
        transform: rotate(225deg);
      }
    }
  }

  .statistic__change {
    align-items: center;
    display: flex;

    line-height: 1;
  }

  .statistic__change__label {
    display: block;
    margin-top: 6px;

    font-size: 1.2rem;

    @media ${device?.xxl} {
      font-size: 1.6rem;
    }
  }

  .statistic__label {
  }

  &.statistic--negative {
    .statistic__change {
      svg {
        color: var(--red);
        transform: rotate(225deg);
      }
    }
  }

  &.statistic--large {
    grid-column: span 2;
  }

  &.statistic--landscape {
    grid-column: span 3;

    h3 {
      margin-bottom: 16px;

      font-size: 1.6rem;
    }

    .statistic__figures {
      display: flex;
      flex-wrap: wrap;

      div {
        width: calc(25% - 8px);
      }

      div:nth-child(1n + 2),
      div:nth-child(1n + 3),
      div:nth-child(1n + 4) {
        margin-left: 8px;
        padding-left: 8px;

        border-left: 1px solid ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey200)`)};
      }

      div:nth-child(1n + 5) {
        margin-top: 16px;
      }

      div:nth-child(4n + 1) {
        margin-left: 0;
        padding-left: 0;

        border-left: none;
      }
    }

    .statistic__change__label {
      margin-top: 0;

      font-size: 1.2rem;

      @media ${device?.md} {
        font-size: 1.6rem;
      }

      @media ${device?.xxl} {
        font-size: 1.2rem;
      }
    }

    .statistic__figure {
      margin-bottom: 0;
      font-size: 1.4rem;

      @media ${device?.md} {
        font-size: 2rem;
      }

      @media ${device?.xxl} {
        font-size: 1.6rem;
      }
    }

    .statistic__label {
      font-size: 1.2rem;

      @media ${device?.md} {
        font-size: 1.6rem;
      }

      @media ${device?.xxl} {
        font-size: 1.2rem;
      }
    }
  }

  &.statistic--title-lower {
    h3,
    h4 {
      text-transform: lowercase;
    }
  }
`;

export default StyledStatistic;

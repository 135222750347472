export const generateChip = (chipArgs: ChipProperties) => {
  const { bold, condition, href, value, variant, where } = chipArgs;

  // If any of the values in chipArgs are defined, add them to 'chip' object
  const chip = {
    ...(bold && { bold }),
    ...(condition && { condition }),
    ...(href && { href }),
    ...(value && { value }),
    ...(variant && { variant }),
    ...(where && { where }),
  };
  return chip;
};

export default generateChip;

import * as React from 'react';

function SvgVisibility(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 3.8C6.5 3.8 1.9 7.2 0 12c1.9 4.8 6.5 8.2 12 8.2s10.1-3.4 12-8.2c-1.9-4.8-6.5-8.2-12-8.2zm0 13.7c-3 0-5.5-2.4-5.5-5.5S9 6.5 12 6.5 17.5 9 17.5 12 15 17.5 12 17.5zm0-8.8c-1.8 0-3.3 1.5-3.3 3.3s1.5 3.3 3.3 3.3 3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3z"
      />
    </svg>
  );
}

export default SvgVisibility;

import { fetchAPI } from 'particles';

type GetReportKeywordSERPArgs = {
  domain: ArgID;
};

export const callGetReportKeywordSERPExport = async (parameters: GetReportKeywordSERPArgs) => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `/reports/keywords/serp/export` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportKeywordSERPExport;

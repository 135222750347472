import * as React from 'react';

function SvgIsometricSearch(props) {
  return (
    <svg viewBox="0 0 38.22 36.02" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        d="M34.54 2.17c-.73-.44-1.52-.74-2.37-.88-.18-.14-.36-.26-.55-.38C30.61.3 29.46 0 28.23 0c-4.24 0-9.4 3.49-12.84 9.15-2.12 3.49-3.29 7.28-3.3 10.67-.01 1.77.3 3.33.91 4.62l-2.61 1.83c-.02 0-.03-.01-.05-.01-.57-.1-1.14.03-1.61.36l-7.8 5.46c-.58.41-.93 1.07-.93 1.77 0 .58.23 1.12.64 1.53.41.41.95.64 1.53.64.44 0 .87-.14 1.24-.39l7.8-5.46c.47-.34.78-.83.89-1.4v-.04l2.71-1.89a6.335 6.335 0 001.38.85c.51.65 1.12 1.21 1.83 1.64 1.11.68 2.36 1 3.68 1 4.38 0 9.57-3.53 13.13-9.38 4.63-7.62 4.5-15.87-.29-18.78zM2.83 34.81c-.45.31-1.1.25-1.48-.13-.22-.22-.35-.52-.35-.83 0-.38.19-.73.5-.95l.05-.03c.05.07.13.12.22.14.7.12 1.15.77 1.04 1.47-.02.1.02.21.09.28l-.07.05zm7.8-5.46l-3.28 2.29-3.77 2.65c.01-.87-.52-1.63-1.32-1.92l3.75-2.63 3.29-2.3a1.149 1.149 0 01.87-.19c.3.05.57.22.75.48.18.25.24.56.19.87-.05.3-.22.57-.48.75zm5.29-13.76c-.91 2.67-1.22 5.21-.97 7.39l.04.44c-.05-.01-.1-.01-.15-.01-.36 0-.68.09-.91.24-.46-1.07-.68-2.36-.68-3.82.01-3.19 1.12-6.76 3.14-10.07C20.01 3.8 25.55.36 29.52 1.3c.39.08.77.22 1.13.4-.5.01-1.02.08-1.53.19-2.32.49-4.73 1.9-6.9 3.91-2.69 2.51-5 5.97-6.3 9.79zm19.14-6.72c.07 1.8-.24 3.84-.96 5.95-2.06 6.07-6.8 11.18-11.31 12.59-.01.01-.02.01-.02.01-.8.25-1.6.38-2.37.38-.37 0-.75-.04-1.13-.12-.01.01-.02 0-.02 0-.05-.01-.1-.02-.14-.03-.01 0-.01-.01-.01-.01-.01 0-.03-.01-.04-.02-.11-.07-.22-.14-.32-.22-.14-.09-.27-.2-.39-.32-1.31-1.2-1.96-3.21-1.96-5.58 0-.22.01-.45.02-.68v-.01c.14-2.84 1.16-6.13 3.04-9.22 1.97-3.24 4.57-5.82 7.33-7.26.34-.18.69-.34 1.02-.48 1.09-.44 2.14-.67 3.09-.67.59 0 1.15.09 1.67.27.02 0 .04.01.07.02l.39.15c.01.01.02.02.03.02.31.29.58.62.82.98.01.02.02.03.02.05.7 1.09 1.1 2.52 1.17 4.16v.04z"
      />
    </svg>
  );
}

export default SvgIsometricSearch;

import 'react-dates/initialize';
import * as React from 'react';
import { useContext, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

// Particles
import { copyRawTextToClipboard } from 'corigan';
import { RBContext } from 'corigan';

// Components
import { Button } from 'corigan';
import { Toggle } from 'corigan';
import { GapGroup } from 'corigan';

declare type ReportControlsProps = {
  controlDevices: boolean;
  controlMOM: boolean;
};

const momentCurrentWeek = moment().endOf(`week`);
const momentThreeYearsAgo = moment().subtract(3, `years`);

const ReportControls = (props: ReportControlsProps) => {
  const { controlDevices, controlMOM } = props;
  const [shareButtonText, setShareButtonText] = useState<string>(`Share`);
  const [focused, setFocused] = useState<boolean>(false);

  const context: any = useContext(RBContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;
  const { apiDate, chartDateEnd, chartDateStart, devices, monthOnMonth } = state;

  // eslint-disable-next-line no-restricted-globals
  const url: string = typeof window !== `undefined` ? location.protocol + `//` + location.host + location.pathname : ``;

  const onChange = date => {
    if (!date) return;

    const value = date?.clone();
    dispatch({ key: `apiDate`, value });
  };

  // Set the new value to the state store
  const handleMobileToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (dispatch) dispatch({ type: `toggleMobile`, value: true });
  };

  // Set the new value to the state store
  const handleDesktopToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (dispatch) dispatch({ type: `toggleDesktop`, value: true });
  };

  const handleMOMView = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // Invert the current values of MOM
    const value = !monthOnMonth;

    // Set the new value to the state store
    if (dispatch) dispatch({ type: `setMOM`, value });
  };

  const handleShareClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    const apiISO = apiDate?.clone()?.toISOString();
    const currentMOM = monthOnMonth;
    const currentDevicesString = devices.join();

    const endISO: string = chartDateEnd?.clone()?.toISOString();
    const startISO: string = chartDateStart?.clone()?.toISOString();

    const urlWithParameters: string = `${url}?apiDate=${apiISO}&chartDateEnd=${endISO}&chartDateStart=${startISO}&mom=${currentMOM}&devices=${currentDevicesString}`;
    copyRawTextToClipboard(urlWithParameters);

    setShareButtonText(`Copied`);

    setTimeout(() => {
      setShareButtonText(`Share`);
    }, 4000);
  };

  const isOutsideRange = date => {
    const tooNew = date.isAfter(momentCurrentWeek);
    const tooOld = date.isBefore(momentThreeYearsAgo);
    const isMonday = date.isoWeekday() === 1;

    const justRight = !tooNew && !tooOld && isMonday;

    return !justRight;
  };

  const mobileOn = devices.includes(`mobile`);
  const desktopOn = devices.includes(`desktop`);

  return (
    <nav className="report-builder__controls">
      <div className="report-builder__controls--left">
        <GapGroup gap={24}>
          {controlMOM && (
            <Toggle id="mom-toggle" label="Month on Month" onChange={handleMOMView} on={monthOnMonth}>
              Month on Month
            </Toggle>
          )}
          {controlDevices && (
            <React.Fragment>
              <Toggle id="mobile-toggle" label="Mobile" onChange={handleMobileToggle} on={mobileOn}>
                View mobile stats
              </Toggle>
              <Toggle id="desktop-toggle" label="Desktop" onChange={handleDesktopToggle} on={desktopOn}>
                View desktop stats
              </Toggle>
            </React.Fragment>
          )}
        </GapGroup>
      </div>
      <div className="report-builder__controls--right">
        <Button onClick={handleShareClick}>{shareButtonText}</Button>
        <SingleDatePicker
          id="apiDatePicker"
          date={apiDate}
          onDateChange={onChange}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          firstDayOfWeek={1}
          numberOfMonths={1}
          isOutsideRange={isOutsideRange}
          displayFormat="DD-MM-YYYY"
        />
      </div>
    </nav>
  );
};

export default ReportControls;

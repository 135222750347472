import { EditorState } from 'draft-js';

import { applyEntity } from './index';

export const removePhraseLinking = (
  newEditorState: EditorState,
  keywords: Array<Keyword | LinkedWord>,
  deleteAll?: boolean,
): EditorState => {
  // Gets an array of the editor content blocks, usually defined as each new line (not text wrapped)
  let currentContent = newEditorState.getCurrentContent();
  const contentBlockMap = currentContent.getBlockMap();
  const currentSelection = newEditorState.getSelection();

  // Loops through each content block
  contentBlockMap.forEach(contentBlock => {
    const contentBlockKey = contentBlock.getKey();

    // Check character in the content block for a keyword link
    contentBlock.findEntityRanges(
      character => {
        const entityKey: string = character.getEntity();
        const { keywordType } = entityKey !== null && currentContent.getEntity(entityKey).getData();

        return keywordType;
      },
      // If a keyword entity exists, check if the keyword is still in the list
      (entityKeywordStartPosition, entityKeywordEndPosition) => {
        let shouldDelete: boolean = deleteAll;

        if (!deleteAll) {
          // Get the keyword assigned to the entity
          const entityKey: string = contentBlock.getEntityAt(entityKeywordStartPosition);
          const { keyword: entityKeyword, url } = currentContent.getEntity(entityKey).getData();

          // Does the keyword saved in the entity still match the text
          const text: string = contentBlock.getText();
          const currentKeywordText: string = text.slice(entityKeywordStartPosition, entityKeywordEndPosition);
          const textChanged: boolean = entityKeyword?.toLowerCase() !== currentKeywordText?.toLowerCase();

          // Has the keyword or url saved in the entity been removed from the keyword list
          const keywordChanged: boolean = !keywords.some(
            keyword => keyword.phrase === entityKeyword && keyword.url === url,
          );

          shouldDelete = keywordChanged || textChanged;
        }

        // Delete the entity link
        if (shouldDelete) {
          currentContent = newEditorState.getCurrentContent();

          const keywordSelection = currentSelection.merge({
            anchorKey: contentBlockKey,
            anchorOffset: entityKeywordStartPosition,
            focusKey: contentBlockKey,
            focusOffset: entityKeywordEndPosition,
          });

          newEditorState = applyEntity(newEditorState, currentContent, keywordSelection, null, keywords);
        }
      },
    );
  });

  return newEditorState;
};

export default removePhraseLinking;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

// Icons
import { Promoted } from 'icons';

// Atoms
import { Toggle } from 'corigan';

// Particles
import { OMContext } from 'corigan';
import { ApplicationContext } from 'particles';
import { addSingleCache, windowAvailable } from 'corigan';
import { callCreateKeyword } from 'corigan';
import { fetchAPI } from 'corigan';
import { isURL } from 'corigan';
import { useHasPermissions } from 'corigan';
import { getRelease } from 'corigan';
import { useLocalStorage } from 'corigan';

// Components
import { Button } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import RenderKeywords from '../render';

declare type searchKeywordTextArgs = {
  phrase: string;
  whereProtected: ArgWhere;
  searchPromoted: boolean;
};

const searchKeywordText = async (args: searchKeywordTextArgs): Promise<any> => {
  const { phrase, whereProtected, searchPromoted } = args;

  return await fetchAPI({
    parameters: {
      search: phrase,
      whereProtected,
      ...(searchPromoted && { where: `[highPriority][eq]=true` }),
    },
    route: `keywords`,
  });
};

declare type searchSuggestedKeywordsArgs = {
  phrase: string;
  limit: number;
};

const searchSuggestedKeywords = async (args: searchSuggestedKeywordsArgs): Promise<any> => {
  const { phrase, limit } = args;

  return await fetchAPI({
    parameters: {
      phrase,
      limit
    },
    route: `keywords/suggested`,
  });
};

const initialValues = {
  searchKeyword: ``,
  createKeyword: ``,
  url: ``,
  keywordId: ``,
};

declare type SearchKeywordProps = {
  isLocked?: boolean;
};

const SearchKeyword: React.FC<SearchKeywordProps> = (props: SearchKeywordProps) => {
  const { isLocked } = props;

  const context: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: any = context?.state?.domainActive;
  const domainName: string = domainActive?.hostname;
  const defaultPromoted: boolean = domainActive?.config?.defaultPromoted;
  const showSuggestedKeywords: boolean = domainActive?.config?.showSuggestedKeywords;
  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;

  const queryCache = useQueryCache();
  const [creating, setCreating] = useState<boolean>(false);

  const omContext: any = useContext(OMContext);
  const dispatch: any = omContext?.dispatch;
  const state: any = omContext?.state;
  const editedRevision: PageRevision = state.editedRevision;

  const { userHasPermission: canCreateKeyword } = useHasPermissions({ requiredPermissions: [`keywords:create`] });


  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our initial parameters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const { releaseVersion } = getRelease();
  const localKey = React.useMemo(() => {
    return `version=${releaseVersion}&key=om-keyword-search-promoted`;
  }, [releaseVersion]);

  const [searchPromoted, setSearchPromoted] = useLocalStorage(localKey, defaultPromoted);

  const handleToggleChange = () => {
    setSearchPromoted(!searchPromoted);
  };

  const handleAdd = useCallback(
    (keyword: Keyword) => {
      const current = editedRevision?.keywords;

      // If already exists in state, ignore the function
      const exists = current.some(k => k.id === keyword.id);
      if (exists) return;

      // Create new array with updated keyword
      const newKeywords = [...current, keyword];
      dispatch({ key: `keywords`, value: newKeywords });
    },
    [dispatch, editedRevision],
  );

  const [mutate, { error, isLoading: loading }] = useMutation(callCreateKeyword, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValuek) => {
      console.error(err);

      toast.error(`Unable to create keyword`, {});

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const _with: ArgWith = [`tags`];
      const keywordData: Keyword = data?.data;

      handleAdd(keywordData);

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`], (previousKeywords: APIResponse) =>
        addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 10, whereProtected }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 25, whereProtected }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 50, whereProtected }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 100, whereProtected }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );
    },
  });

  const onSubmit = async (values: any): Promise<void> => {
    if (windowAvailable() && !canCreateKeyword) window.alert(`You do not have permission to create a keyword`);
    if (!canCreateKeyword) return;

    const phrase: string = values?.createKeyword;
    const url: string = values?.url;

    const shouldMutate: boolean = Boolean(phrase) && Boolean(url);
    if (!shouldMutate) return;

    await mutate({ phrase, keywordId, url, domain: domainActive.id });
    setCreating(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const { handleChange, handleSubmit, setFieldValue } = formik;
  const { values } = formik;

  const searchKeyword: string = values?.searchKeyword;
  const createKeyword: string = values?.createKeyword;
  const url: string = values?.url;
  const keywordId : string = values?.keywordId;

  const isLongEnough: boolean = searchKeyword.length >= 3;
  const showResults: boolean = isLongEnough;

  const { data: searchRes, error: searchError } = useQuery(
    [{ phrase: searchKeyword, whereProtected, searchPromoted }],
    searchKeywordText,
    { enabled: isLongEnough },
  );
  const searchData: any = searchRes?.data ?? [];

  const { data: suggestionRes } = useQuery(
    [{ phrase: searchKeyword, limit: 3 }],
    searchSuggestedKeywords,
    { enabled: isLongEnough && showSuggestedKeywords },
  );
  const suggestionData: any = suggestionRes?.data ?? [];

  const toggleCreating = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, createPhrase: string): void => {
    if (e) e.preventDefault();
    const shouldReset: boolean = creating === true;

    if (shouldReset) {
      createPhrase = ``
    }
    setFieldValue(`createKeyword`, createPhrase);
    setFieldValue(`url`, `https://${domainName}/`);
    setFieldValue(`keywordId`, createPhrase);

    setCreating(!creating);
  };

  const hasResults: boolean = searchData?.length >= 1 || suggestionData?.length >= 1;
  const validURL: boolean = url && isURL(url);
  const canSubmit: boolean = creating && validURL;

  let createButtonText: string = `Create Keyword`;
  let keywordLabel: string = `Search Keywords`;

  if (creating) createButtonText = `Stop Creating`;
  if (creating) keywordLabel = `New Keyword Phrase`;

  const isBusy: boolean = loading;

  let type: 'search' | 'text' = `search`;
  if (creating) type = `text`;

  const PromotedLabel = (
    <React.Fragment>
      <Promoted />
      Promoted
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {searchError && <Error error={searchError} />}
      {error && <Error error={error} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <fieldset aria-busy={isBusy} className="mb-0" disabled={isBusy}>
          <label htmlFor="searchKeyword">{keywordLabel}</label>
          {!creating && (
            <div className="display--flex">
              <input
                id="searchKeyword"
                name="searchKeyword"
                disabled={isLocked}
                onChange={handleChange}
                value={searchKeyword}
                type={type}
              />
              <Toggle className="ml-2" id="promoted" label={PromotedLabel} onChange={handleToggleChange} on={searchPromoted} />
            </div>
          )}
          {creating && (
            <React.Fragment>
              <div className="display--flex">
                <input
                  id="createKeyword"
                  name="createKeyword"
                  disabled={isLocked}
                  onChange={handleChange}
                  required={creating}
                  value={createKeyword}
                  type={type}
                />
              </div>
              <label htmlFor="url">New Keyword ID</label>
              <input
                id="keywordId"
                name="keywordId"
                disabled={isLocked}
                onChange={handleChange}
                required={true}
                value={keywordId}
                type="text"
              />
              <label htmlFor="url">New Keyword URL</label>
              <input
                id="url"
                name="url"
                disabled={isLocked}
                onChange={handleChange}
                required={true}
                value={url}
                type="url"
              />
            </React.Fragment>
          )}
          {showResults && (
            <React.Fragment>
              {!creating && hasResults && (
                <div className="chip-spacer__response-code mt-2">
                  <RenderKeywords linked={false} isLocked={isLocked} suggestions={suggestionData} keywords={searchData} searchTerm={searchKeyword} toggleCreating={toggleCreating} />
                </div>
              )}
              {!creating && !hasResults && (
                <div className="mt-2 display--flex">
                  <span>No results found</span>
                </div>
              )}
            </React.Fragment>
          )}
          <div className="mt-2">
            {showResults && canCreateKeyword && (
              <div className="display--inline mr-1">
                {creating && (
                  <button className="mr-1" disabled={!canSubmit} type="submit">
                    Submit Keyword
                  </button>
                )}
                <Button onClick={e => toggleCreating(e, searchKeyword)} variant={creating ? `hollow` : null}>
                  {createButtonText}
                </Button>
              </div>
            )}
          </div>
        </fieldset>
      </form>
    </React.Fragment>
  );
};

export default SearchKeyword;

import { fetchAPI } from 'particles';

declare type callGetExportDailyMovementSERPArgs = {
  where: ArgWhere;
  domain: ArgWhere;
};

declare type Response = {
  data: string;
};

export const callGetExportDailySERPRankings = async (parameters: callGetExportDailyMovementSERPArgs): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `export/daily/serp` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetExportDailySERPRankings;

import { css } from 'styled-components';

const avatar = css`
  .avatar,
  .circle {
    border-radius: 50%;

    object-fit: cover;
  }
`;

export default avatar;

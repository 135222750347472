import { useState, useEffect, useRef } from 'react';

export const useComponentVisible = (
  initialIsVisible: boolean,
): {
  ref: React.MutableRefObject<any>;
  isComponentVisible: boolean;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener(`click`, handleClickOutside, true);
    return () => {
      document.removeEventListener(`click`, handleClickOutside, true);
    };
  });

  const output = { ref, isComponentVisible, setIsComponentVisible };
  return output;
};

export default useComponentVisible;

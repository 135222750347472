import { fetchAPI } from 'particles';

type CreateUserParameters = {
  email: ArgEmail;
  name?: ArgName;
  roles?: ArgRoles;
  teams?: ArgTeams;
};

export const callCreateUser = async (parameters: CreateUserParameters): Promise<{ data: User }> => {
  const response = await fetchAPI({ method: `POST`, parameters, route: `users` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreateUser;

import { generateChip } from 'corigan';
import { dateCreateUpdate } from 'corigan';
import { ROUTES } from 'corigan';

const _ = undefined;

export const generateRows = data => {
  return data.map(keywordChange => {
    const { startPosition, endPosition, positionChange } = keywordChange;
    const keyword = keywordChange?.keyword;
    const pageRevision = keywordChange?.pagerevision;
    const parentPage: Page = pageRevision?.page;
    const assignee = pageRevision?.assignedTo?.name;
    const createdAt = pageRevision?.createdAt;
    const updatedAt = pageRevision?.updatedAt;
    const parentID = parentPage?.id;
    const wordCount = pageRevision?.contentWordCount;
    let updatedBy;

    if(parentPage?.updatedBy?.[`name`] && parentPage?.updatedBy?.[`name`] !== `System`) {
      updatedBy = parentPage?.updatedBy[`name`];
    } else if(pageRevision?.updatedBy?.[`name`] && pageRevision?.updatedBy?.[`name`] !== `System`){
      updatedBy = pageRevision?.updatedBy[`name`];
    } else {
      updatedBy = `Not updated`;
    }

    const { url } = pageRevision;
    const { canonicals: revisionCanonicals } = pageRevision;
    const { categories: revisionCategories } = pageRevision;
    const { pageId: pageIdString } = pageRevision;
    const { name: revisionName } = pageRevision;
    const { tags: revisionTags } = pageRevision;
    const { status: revisionStatus } = pageRevision;
    const { pageType: revisionPageType } = pageRevision;

    let categories;
    let canonicals;
    let tags;
    const contentText = ``;
    const keywordCount = pageRevision?.keywordCount ? pageRevision?.keywordCount : 0;

    const hasCanonicals: boolean = revisionCanonicals?.length > 0;
    const hasCategories: boolean = revisionCategories?.length > 0;
    const hasTags: boolean = revisionTags?.length > 0;

    const name = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: revisionName };
    const pageId = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: pageIdString };
    const id = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: parentID };

    if (hasCanonicals) {
      canonicals = revisionCanonicals
        .filter(c => c?.url)
        .map(c => ({
          component: `link`,
          href: c.url,
          target: `_blank`,
          value: c.url,
        }));
    }

    if (hasCategories) {
      categories = revisionCategories
        .filter(c => c?.category?.name)
        .map(c =>
          generateChip({
            condition: `contains`,
            href: _,
            value: c.category.name,
            where: `categories.category.name`,
          }),
        );
    }

    const keywordChip = [generateChip({
      condition: `contains`,
      href: _,
      value: keyword.phrase,
      where: `keywords.phrase`,
    })];

    if (hasTags) {
      tags = pageRevision.tags.map(t =>
        generateChip({
          condition: `contains`,
          href: _,
          value: t.name,
          where: `tags.name`,
        }),
      );
    }

    const pageTypeName = revisionPageType?.name;
    const pageType = [
      generateChip({
        condition: `contains`,
        href: _,
        value: pageTypeName,
        where: `pageType.name`,
      }),
    ];

    let assignedTo = null;
    if (assignee) {
      assignedTo = [
        generateChip({
          condition: `eq`,
          href: _,
          value: String(assignee),
          where: `assignedTo.name`,
        }),
      ];
    }

    const positionChangeObj = {
      value: positionChange,
      type: positionChange > 0 ? `best` : positionChange < 0 ? `worst` : null,
    }

    const changedFormatted = dateCreateUpdate({ createdAt, updatedAt });

    const item = {
      id,
      pageId,
      name,
      url,
      canonicals,
      assignedTo,
      pageType,
      categories,
      tags,
      keyword: keywordChip,
      startPosition,
      endPosition,
      positionChange: positionChangeObj,
      keywordCount,
      wordCount,
      changedFormatted,
      updatedBy,
      contentText,
      originalObject: pageRevision,
    };

    return item;
  });
};

export default generateRows;

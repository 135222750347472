import * as React from 'react';

// Particles
import { ROUTES } from 'corigan';

// Components
import { Chip } from 'corigan';

export const getChip = (key, keyword) => {
  const { id, phrase } = keyword;
  const invalidKeyword = !id || !phrase;
  if (invalidKeyword) return null;

  const chipKey = `${key}-${phrase}-${id}`;
  const href = ROUTES.keyword + `?id=` + id;

  const isLink = href;
  const variant = isLink ? `primary` : undefined;

  return (
    <Chip href={href} key={chipKey} variant={variant}>
      {phrase}
    </Chip>
  );
};

export default getChip;

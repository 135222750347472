import * as React from 'react';

function SvgStore(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M22.7 1.3H1.3V4h21.3V1.3zM24 14.7V12l-1.3-6.7H1.3L0 12v2.7h1.3v8h13.3v-8H20v8h2.7v-8H24zM12 20H4v-5.3h8V20z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgStore;

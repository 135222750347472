import { css } from 'styled-components';

const iframe = css`
  .embed {
    height: 0;
    position: relative;
    padding-top: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export default iframe;

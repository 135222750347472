import { useEffect, useRef } from 'react';

// Use this for comparing previous values in a useEffect hook, useful for when
// deep comparing objects or arrays is needed due to useEffect only doing
// shallow comparisons

// EXAMPLE USAGE:
// const prevValue = usePrevious(value)
// useEffect(() => {
//   if (prevValue && differencesBetweenArrays(value, prevValue).length) {
//     ...logic here
//   }
// }, [value, prevValue, differencesBetweenArrays]);

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;

import { css } from 'styled-components';

const list = css`
  .list--unstyled {
    list-style-type: none;
    list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
    padding-left: 0;
  }
`;

export default list;

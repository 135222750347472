import * as React from 'react';

function SvgApps(props) {
  return (
    <svg viewBox="0 0 22 22" aria-hidden="true" {...props}>
      <g transform="translate(-1395 -24)" fill="currentColor">
        <circle cx={3} cy={3} r={3} transform="translate(1395 24)" />
        <circle cx={3} cy={3} r={3} transform="translate(1403 24)" />
        <circle cx={3} cy={3} r={3} transform="translate(1411 24)" />
        <circle cx={3} cy={3} r={3} transform="translate(1395 32)" />
        <circle cx={3} cy={3} r={3} transform="translate(1403 32)" />
        <circle cx={3} cy={3} r={3} transform="translate(1411 32)" />
        <circle cx={3} cy={3} r={3} transform="translate(1395 40)" />
        <circle cx={3} cy={3} r={3} transform="translate(1403 40)" />
        <circle cx={3} cy={3} r={3} transform="translate(1411 40)" />
      </g>
    </svg>
  );
}

export default SvgApps;

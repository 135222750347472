import * as React from 'react';
import { useContext } from 'react';

// Particles
import { ApplicationContext } from 'corigan';

// Molecules
import { Modal } from 'corigan';

const ModalWrapper = () => {
  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const modalData = appContext?.state?.modal;
  const handleConfirm = modalData?.handleConfirm;
  const isOpen = modalData?.isOpen;
  const message = modalData?.message;

  const onConfirm = () => {
    const currentData = { ...modalData };
    const value = { ...currentData, isOpen: false };
    dispatch({ key: `modal`, type: `set`, value });
    handleConfirm();
  };

  const onClose = () => {
    const currentData = { ...modalData };
    const value = { ...currentData, isOpen: false };
    dispatch({ key: `modal`, type: `set`, value });
  };

  return (
    <Modal
      confirmText="Yes, I am sure"
      enableConfirm={true}
      handleConfirm={onConfirm}
      handleClose={onClose}
      isOpen={isOpen}
      title="Are you sure?"
    >
      <p>{message}</p>
    </Modal>
  );
};

export { ModalWrapper };
export default ModalWrapper;

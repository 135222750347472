import * as React from 'react';
import { useContext } from 'react';

// Helpers
import { generateID } from 'corigan';
import { isArray } from 'corigan';

// Components
import { Error } from 'corigan';
import { Loader } from 'corigan';

// Styles
import { StyledTH } from './table.styles';
import { StyledTableWrapper } from './table.styles';

// Local partials
import Filters from './parts/filters';
import TableBody from './parts/body';
import TableHeading from './parts/heading';
import TablePagination from './parts/pagination';
import TableSkeleton from './parts/skeleton';
import TableTransfer from './parts/transfer';

import TableContext from './context/tableContext';
import TableWrapper from './context/tableWrapper';

import type { TableContextProps } from './table.types';
import type { TableProps } from './table.types';

const TableParent: React.FC<TableProps> = (props: TableProps) => {
  const { children, className, ...tableProps } = props;
  const initialState = { ...tableProps };

  return (
    <TableWrapper initialState={initialState}>
      <Table className={className} {...tableProps}>
        {children}
      </Table>
    </TableWrapper>
  );
};

// Default prop values
TableParent.defaultProps = {
  allowFilters: true,
  responsive: true,
  small: true,
  transfer: true,
};

const getItemSelectValue = (item: any): any => {
  let selectValue: any = item?.id?.value;
  const hasCollectionObject: boolean = item?.originalObject;
  if (hasCollectionObject) selectValue = item.originalObject;
  return selectValue;
};

const Table: React.FC<TableProps> = (props: TableProps) => {
  const { allowFilters, className, error, items: itemsBasic, itemsGrouped, loading } = props;
  const { pagination, responsive, selectable, transfer, small } = props;
  const { apiArgs, apiFunctions, exportArgs, exportRoute } = props;

  const hasGrouped: boolean = itemsGrouped?.length > 0;
  const items = hasGrouped ? itemsGrouped : itemsBasic;

  const context: TableContextProps = useContext(TableContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;
  const { columns, selected } = state;

  const selectedIDs: string[] = selected?.map(entity => entity?.page?.id || entity?.id || entity).filter(Boolean);

  let classList: string = `table`;
  if (className) classList += ` ${className}`;
  if (responsive) classList += ` table--responsive`;
  if (small) classList += ` table--small`;

  const hasColumns: boolean = columns?.length > 0;
  const hasItems: boolean = items?.length > 0;
  const hasPagination: boolean = !loading && pagination && true;
  const hasTransfer: boolean = !loading && transfer;
  const hasSelect: boolean = selectable === true;

  const visibleColumns: any[] = hasColumns && columns.filter(c => !c.hide);
  const hasVisibleColumns: boolean = visibleColumns?.length > 0;

  const itemSelectValues: any[] = React.useMemo(() => {
    if (!hasItems) return [];

    const isIterable = isArray(items) && items.length > 0;
    if (!isIterable) return [];

    const itemsAreGrouped = items.every(group => isArray(group));
    if (!itemsAreGrouped) return items.map(getItemSelectValue).filter(Boolean);

    const grouped = items
      .map(group => {
        const hasGroupedItems: boolean = group?.length > 0;
        if (!hasGroupedItems) return null;

        return group.map(getItemSelectValue).filter(Boolean);
      })
      .filter(Boolean);
    const flattened = grouped.flat();
    const unique = [...new Set(flattened)];
    return unique;
  }, [hasItems, items]);

  const selectedAll: boolean = itemSelectValues.every(item => {
    const isPage: boolean = Boolean(item?.page?.id);
    const hasRootID: boolean = Boolean(item?.id);
    const compare: string = isPage ? item.page.id : hasRootID ? item.id : item;
    const wasMatch: boolean = selectedIDs.includes(compare);
    return wasMatch;
  });

  const handleSelectAll = (): void => {
    if (!selectedAll) {
      dispatch({ type: `itemsSelectAllAdd`, value: itemSelectValues });
    } else {
      dispatch({ type: `itemsSelectAllClear`, value: itemSelectValues });
    }
  };

  const hasButtons = hasGrouped
    ? itemsGrouped?.some(itemGroup => itemGroup.some(item => Boolean(item?.buttons?.length)))
    : items?.some(item => Boolean(item?.buttons?.length));

  return (
    <StyledTableWrapper className={classList}>
      {error && <Error error={error} />}
      {loading && <Loader className="table__loader" type="bar" />}
      {allowFilters && <Filters apiArgs={apiArgs} />}
      <table>
        {hasVisibleColumns && (
          <thead>
            <tr>
              {hasSelect && (
                <StyledTH>
                  <input
                    id="select-all"
                    name="select-all"
                    checked={hasItems && selectedAll}
                    onChange={handleSelectAll}
                    type="checkbox"
                  />
                  <label htmlFor="select-all" />
                </StyledTH>
              )}
              {visibleColumns.map((column, index) => (
                <TableHeading key={generateID()} {...column} apiArgs={apiArgs} apiFunctions={apiFunctions} index={index} />
              ))}
              {hasButtons && <StyledTH />}
            </tr>
          </thead>
        )}
        {hasItems && (
          <TableBody columns={visibleColumns} items={itemsBasic} itemsGrouped={itemsGrouped} selectable={selectable} />
        )}
      </table>
      <footer className="table__footer">
        {hasTransfer && <TableTransfer apiArgs={exportArgs} route={exportRoute} />}
        {hasPagination && <TablePagination apiArgs={apiArgs} apiFunctions={apiFunctions} pagination={pagination} />}
      </footer>
    </StyledTableWrapper>
  );
};

export { TableContext };
export { TablePagination };
export { TableSkeleton };
export { TableWrapper };
export default TableParent;

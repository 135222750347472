import { replaceAll } from './replaceAll';

export const toStartCase = (phrase: string): string => {
  if (!phrase) return;
  const removedCamelCase = phrase.replace( /([A-Z])/g, ` $1` );
  const lower = removedCamelCase?.toLowerCase();
  const removedHyphen = replaceAll(lower, `-`, ` `);
  return removedHyphen.charAt(0).toUpperCase() + removedHyphen.slice(1);
};

export default toStartCase;

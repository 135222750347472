import * as React from 'react';
import { CategoryTable } from '../category-table';
import { KeywordTable } from '../keyword-table';
import { isString } from 'corigan';

declare type TableHandlerProps = {
  categories?: any[];
  top?: number;
  query?: any;
};

const TableHandler: React.FC<TableHandlerProps> = (props: TableHandlerProps) => {
  const { categories, top, query } = props;
  const passedCompetitor: string | string[] = query?.competitor;
  const passedFilter: string | string[] = query?.filter;
  const hasPassedCompetitor: boolean = isString(passedCompetitor);
  const hasPassedFilter: boolean = isString(passedFilter);

  return (
    <section className="coverage__table">
      {!hasPassedCompetitor && !hasPassedFilter &&
        <CategoryTable categories={categories} top={top} />
      }
      {hasPassedCompetitor && hasPassedFilter &&
        <KeywordTable categories={categories} top={top} competitor={passedCompetitor} filter={passedFilter} />
      }
    </section>
  );
};

export default TableHandler;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { KRContext } from 'corigan';
import { windowAvailable } from 'corigan';
import { useDebounce } from 'corigan';

// Icons
import { Search as SearchIcon } from 'icons';

// Components
import { Button } from 'corigan';

import { Card } from 'corigan';
import { Error } from 'corigan';

// API
import { callGetManyKeywords } from 'corigan';

declare type Args = { phrase: string };

declare type Res = { data: Keyword[] };

const initialValues = {
  page: 1,
  search: ``,
};

declare type KeywordLookupProps = {
  error?: any;
  hasID: boolean;
  loading?: boolean;
};

const onSubmit = async values => console.info(values);

const KeywordLookup = (props: KeywordLookupProps) => {
  const [searchingAgain, setSearchingAgain] = useState<boolean>(false);
  const hasID = props?.hasID;
  const parentError = props?.error;
  const parentLoading = props?.loading;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;
  const hasDomainID: boolean = Boolean(domainId);

  const context: any = useContext(KRContext);
  const state: any = context?.state;
  const activeKeyword: Keyword = state?.keyword;
  const keyword: string = activeKeyword?.phrase;
  const hasKeyword: boolean = Boolean(keyword);

  const formik = useFormik({
    initialValues,
    onSubmit,
  });
  const { handleChange, handleSubmit, setFieldValue, values } = formik;
  const page = values?.page;
  const search = values?.search;

  useEffect(() => {
    document.title = `Corigan - Optimisation Manager - ${keyword}`;
  }, [keyword]);

  // Stops API request on every change, waits 300ms for a settle
  const phrase = useDebounce(search, 300);

  // Reset the search keyword on domain change
  useEffect(() => {
    setFieldValue(`search`, ``);
  }, [domainId, setFieldValue]);

  // Reset the search keyword on domain change
  useEffect(() => {
    setFieldValue(`page`, 1);
  }, [search, setFieldValue]);

  const perPage: ArgPerPage = 5;
  const whereProtected: ArgWhere = `[domain][eq]=${domainId}`;
  const where: ArgWhere = `[or][textSearch][textSearch]=${phrase}&where[or][phrase][contains]=${phrase}`;

  // Toggles if the query will fire
  const searchLongEnough: boolean = search.length >= 1;
  const enabled: boolean = hasDomainID && searchLongEnough;

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyKeywords`, { page, perPage, whereProtected, where }],
    callGetManyKeywords,
    {
      enabled,
    },
  );

  const keywords = res?.data;
  const pagination = res?.pagination;

  const hasResults: boolean = keywords?.length >= 1;
  const noResults: boolean = !hasResults && !loading;

  const autofocus: boolean = !hasID;
  const isBusy: boolean = parentLoading || loading;

  const lastPage = pagination?.lastPage;
  const nextPage = pagination?.nextPage;
  const prevPage = pagination?.prevPage;
  const hasNextPage: boolean = Boolean(nextPage);
  const hasPrevPage: boolean = Boolean(prevPage);
  const hasPagination = hasNextPage || hasPrevPage;

  const canGoPrev: boolean = page > 1;
  const canGoNext: boolean = page < lastPage;

  const handleNextPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    if (!canGoNext) return;

    setFieldValue(`page`, page + 1);
  };

  const handlePrevPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    if (!canGoPrev) return;

    setFieldValue(`page`, page - 1);
  };

  const isSearching: boolean = !hasKeyword || searchingAgain;
  const showSearched = hasKeyword;

  const handleClickSearchAgain = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    setSearchingAgain(!searchingAgain);
    formik.resetForm();
  };

  let classList = `kr__search`;
  if (searchingAgain) classList += ` kr__search--again`;
  if (isSearching) classList += ` kr__search--searching`;
  if (showSearched) classList += ` kr__search--searched`;

  return (
    <Card className={classList} minHeight={false} loading={isBusy}>
      {showSearched && (
        <nav className="kr__searched">
          <span className="kr__searched__keyword">
            Keyword: <span className="kr__searched__phrase">{activeKeyword?.phrase}</span>
          </span>
          <Button className="kr__searched__reset" onClick={handleClickSearchAgain}>
            <SearchIcon /> Search Again
          </Button>
        </nav>
      )}
      {isSearching && (
        <div className="kr__search__form">
          <form autoComplete="off" className="mt-0" onSubmit={handleSubmit}>
            <fieldset className="mb-0">
              <label className="mt-0" htmlFor="search">
                Search for a Keyword
              </label>
              <input
                id="search"
                name="search"
                autoFocus={autofocus}
                onChange={handleChange}
                required={true}
                value={search}
                type="search"
              />
            </fieldset>
          </form>
          {parentError && <Error error={parentError} />}
          {error && <Error error={error} />}
          {enabled && (
            <nav className="search__results mt-1">
              {noResults && <p>No keywords were found with that phrase</p>}
              {hasResults &&
                keywords.map(keyword => (
                  <Result key={keyword.id} formik={formik} keyword={keyword} setSearchingAgain={setSearchingAgain} />
                ))}
              {hasPagination && (
                <div className="search__pagination">
                  <button disabled={!canGoPrev} onClick={handlePrevPage}>
                    Previous Results
                  </button>
                  <button disabled={!canGoNext} onClick={handleNextPage}>
                    More Results
                  </button>
                </div>
              )}
            </nav>
          )}
        </div>
      )}
    </Card>
  );
};

declare type ResultProps = {
  formik: any;
  keyword: Keyword;
  setSearchingAgain: any;
};

const Result = (props: ResultProps) => {
  const { formik, keyword, setSearchingAgain } = props;
  const { phrase } = keyword;

  const context = useContext(KRContext);
  const dispatch = context?.dispatch;

  const canPushHistory: boolean = windowAvailable() && Boolean(window?.history?.pushState);
  const enableNavigation: boolean = false;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    setSearchingAgain(false);
    dispatch({ key: `keyword`, value: keyword });

    // TODO: enable navigation between loaded keywords
    if (canPushHistory && enableNavigation) {
      const newurl = window.location.origin + window.location.pathname + `?id=${keyword.id}`;
      window.history.pushState({ path: newurl }, ``, newurl);
    }

    formik.resetForm();
  };

  return <button onClick={handleClick}>{phrase}</button>;
};

export default KeywordLookup;

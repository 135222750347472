import styled from 'styled-components';
import { device } from 'particles';

const StyledSEOPreview = styled.section`
  padding: 16px;
  width: 100%;

  border: 1px solid var(--grey200);

  @media ${device?.xl} {
    max-width: 480px;
  }

  &.seo-preview {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 400;
      text-transform: initial;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p,
  time {
    font-size: 1.4rem;
  }

  time {
    display: block;
    margin: 8px 0 16px;
  }
`;

export default StyledSEOPreview;

import * as React from 'react';

// Local partials
import ColumnControls from './columns';

const TableControls: React.FC = () => {
  return (
    <nav className="table__controls">
      <ColumnControls />
    </nav>
  );
};

export default TableControls;

import styled from 'styled-components';

export const FilterWrapper = styled.nav`
  button {
    flex-shrink: 0;
  }

  .filters__wrapper {
    align-items: flex-end;
    display: flex;
  }

  form ~ form,
  .filter__clear {
    margin-top: 24px;
  }

  .filter__forms {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    form ~ form {
      width: 100%;
    }

    select[name='condition'] {
      min-width: 201px;
    }

    .table-button--inline {
      justify-content: center;
      min-width: 66px;

      border-color: #dbdbdb;
      line-height: 14px;
    }

    div {
      button {
        margin-top: calc(var(--spacingDefault) - 4px);
      }
    }

    .filter__controls {
      margin-left: auto;
      margin-bottom: auto;
      display: flex;
    }
  }

  .filter__error {
    margin-bottom: auto;
  }

  .filter__search {
    button {
      margin-right: 8px;
    }
  }
`;

export default FilterWrapper;

import styled from 'styled-components';

const StyledOverview = styled.section`
  padding-bottom: 32px;
  width: 100%;

  h2 ~ h2 {
    margin-bottom: 16px;
    margin-top: 32px;
    padding-top: 32px;

    border-top: 1px solid var(--grey100);
  }

  .overview__link {
    color: var(--grey700);
    font-size: 14px;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .overview__title {
    display: flex;

    span {
      display: inline-block;
      margin-left: auto;
    }
  }

  .pagination {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .table--orderby {
    th {
      button {
        text-transform: initial;

        svg {
          margin-left: 4px;
        }
      }

      .button--descending {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export default StyledOverview;

import { EditorState, SelectionState, Modifier, ContentState } from 'draft-js';

import { scanKeywordConflicts } from './scanKeywordConflicts';

export const applyEntity = (
  newEditorState: EditorState,
  contentWithNewEntity: ContentState,
  keywordSelection: SelectionState,
  newEntityKey: string | null,
  keywords: Array<Keyword | LinkedWord>,
): EditorState => {
  const contentWithEntityApplied = Modifier.applyEntity(contentWithNewEntity, keywordSelection, newEntityKey);

  // Scans the existing keys (with the new one) and updates properties accordingly
  const { updatedContent } = scanKeywordConflicts({
    currentContent: contentWithEntityApplied,
    keywords,
  });

  // Creates the new editor state with the new linked keyword
  newEditorState = EditorState.push(newEditorState, updatedContent, `apply-entity`);

  return newEditorState;
};

export default applyEntity;

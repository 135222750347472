import * as React from 'react';
import { motion } from 'framer-motion';

import StyledCard from './card.styles';

import { ArrowDown } from 'icons';
import { HelpQuestion } from 'icons';

import { Link } from 'atoms';
import { Tooltip } from 'atoms';

import { Loader } from 'molecules';

type CardProps = {
  animate?: Boolean;
  /**
   * The primary content of the Card component
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  footnote?: string;
  info?: string;
  /**
   * Link object with 'a' attribute values
   * 'href', 'target', and 'title'.
   */
  loading?: boolean;
  link?: {
    href: string;
    target?: string;
    title: string;
  };
  minHeight?: boolean;
  padding?: boolean;
  /**
   * Statistic object with data related to the context of the Card
   * 'title' gives a short one liner description of the card
   * 'figure' is the amount in which the card describes (e.g. '2000') leads
   */
  statistic?: {
    figure?: string;
    positive?: boolean;
    title?: string;
  };
  status?: string;
  /**
   * Title gives a short one liner description of the card
   */
  title?: string;
  /**
   * Variant enables the variation 'default' or 'statistic'.
   */
  variant?: 'default' | 'statistic' | 'status';
};

const Card: React.FC<CardProps> = (props: CardProps) => {
  const { animate, children, className, info, link, loading, padding, title, variant } = props;
  const hasHeader = link || (title && variant !== `status`);
  const data = { ...props };
  delete data.loading;
  if (data?.title) delete data.title;

  let classList = `card`;
  classList += ` card--${variant}`;
  if (!children) classList += ` card--focus`;
  if (className) classList += ` ${className}`;

  const cardContent = () => {
    switch (variant) {
      case `statistic`:
        return <CardStatistic {...props} />;
      case `status`:
        return <CardStatus {...props} />;
      default:
        return children;
    }
  };

  const CardElement = (
    <StyledCard {...data} className={classList} padding={padding}>
      {loading && <Loader className="card__loader" type="bar" />}
      {hasHeader && (
        <header className="card__header">
          {info && (
            <Tooltip className="card__tooltip" content={info} side="right">
              <div className="card__tooltip__icon">
                <HelpQuestion />
              </div>
            </Tooltip>
          )}
          {title && <h2 className="card__title">{title}</h2>}
          {link && (
            <Link href={link.href} target={link.target}>
              {link.title}
            </Link>
          )}
        </header>
      )}
      {cardContent()}
    </StyledCard>
  );

  if (!animate) return CardElement;
  return (
    <motion.div
      initial={{ opacity: 0, transform: `translateY(20px)` }}
      animate={{ opacity: 1, transform: `translateY(0px)` }}
      exit={{ opacity: 0, transform: `translateY(20px)` }}
    >
      {CardElement}
    </motion.div>
  );
};

// Default prop values
Card.defaultProps = {
  animate: false,
  loading: false,
  minHeight: true,
  padding: true,
  variant: `default`,
};

type CardStatisticProps = {
  animate?: Boolean;
  children?: React.ReactNode;
  statistic?: {
    figure?: any;
    positive?: boolean;
    title?: any;
  };
};

const CardStatistic: React.FC<CardStatisticProps> = (props: CardStatisticProps) => {
  const { animate, children, statistic } = props;
  const { figure, title } = statistic;
  let classList = `card__statistic`;
  if (!children) classList += ` card__statistic--focus`;

  const CardElement = (
    <div className={classList}>
      <div className="card__statistic__overview">
        {title && <h3 className="card__statistic__title">{title}</h3>}
        {figure && (
          <h4 className="card__statistic__figure">
            <ArrowDown />
            {figure}
          </h4>
        )}
      </div>
      {children && <div className="card__statistic__content">{children}</div>}
    </div>
  );

  if (!animate) return CardElement;
  return (
    <motion.div
      initial={{ opacity: 0, transform: `translateY(20px)` }}
      animate={{ opacity: 1, transform: `translateY(0px)` }}
      exit={{ opacity: 0, transform: `translateY(20px)` }}
    >
      {CardElement}
    </motion.div>
  );
};

// Default prop values
CardStatistic.defaultProps = {};

type CardStatusProps = {
  animate?: Boolean;
  children?: React.ReactNode;
  footnote?: string;
  status?: string;
  title?: string;
};

const CardStatus: React.FC<CardStatusProps> = (props: CardStatusProps) => {
  const { animate, children, footnote, status, title } = props;
  let classList = `card__status`;
  if (children) classList += ` card__status--default`;
  if (!children) classList += ` card__status--focus`;

  const CardMeta = () => (
    <React.Fragment>
      {title && <h2 className="card__title">{title}</h2>}
      {status && <h3 className="card__status__title">{status}</h3>}
      {footnote && <p className="card__status__footnote">{footnote}</p>}
    </React.Fragment>
  );

  const CardElement = (
    <div className={classList}>
      {!children && <CardMeta />}
      {children && (
        <header className="card__status__header">
          <CardMeta />
        </header>
      )}
      {children && <div className="card__status__content">{children}</div>}
    </div>
  );

  if (!animate) return CardElement;
  return (
    <motion.div
      initial={{ opacity: 0, transform: `translateY(20px)` }}
      animate={{ opacity: 1, transform: `translateY(0px)` }}
      exit={{ opacity: 0, transform: `translateY(20px)` }}
    >
      {CardElement}
    </motion.div>
  );
};

// Default prop values
CardStatus.defaultProps = {};

export default Card;

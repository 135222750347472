import styled from 'styled-components';

const StyledEditorBlocksAdmin = styled.div`
  .editor-blocks__block__wrapper {
    padding: 20px 0px 20px 20px;

    background-color: var(--white);
    border: 1px solid var(--black);
    border-radius: 2px;

    + div {
      margin-top: 8px;
    }
  }

  .editor-blocks__close {
    position: absolute;
    padding: 16px;
    right: 0;
    top: -20px;
    color: #aaa;
    font-size: 24px;
    font-weight: bold;
    line-height: 14px;
    z-index: 2;
  }

  .editor-blocks__close-button {
    padding: 0;
    border: transparent;
    background-color: transparent;
    color: #43425d;
  }

  .editor-blocks__block__title {
    margin-bottom: 8px;

    font-weight: bold;
    text-transform: uppercase;
  }

  .editor-blocks__add-button {
    background-color: var(--white);
    border-color: var(--black);
    border-radius: 0;
    padding: 8px 16px 6px 16px;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--black);
    margin-top: 10px;

    svg {
      position: relative;
      right: 6px;
      bottom: 1px;
    }
  }
`;

export default StyledEditorBlocksAdmin;

import styled from 'styled-components';
import { hexToRGB } from 'helpers';
import { shouldBeDark } from 'particles';

const initial = `16px`;

const handleHex = (hex, opaque = true) => {
  const rgb = hexToRGB(hex);
  return `rgba(${rgb}, ${opaque ? `0.3` : `0.7`})`;
};

const StyledAccordion = styled.section`
  .accordion__item__toggle {
    /* Button Reset CSS */
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    padding: ${initial};
    width: 100%;

    background-color: transparent;
    transition: 0.2s background-color ease, 0.2s border-color ease, 0.2s color ease;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => handleHex(props.theme.grey300)};
      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--purple)`)};

      outline: none;
      text-decoration: none;
    }

    svg {
      height: 24px;

      transform: rotate(0deg);
      transition: 0.2s transform ease;
    }
  }

  .accordion__item {
    border: 1px solid ${props => handleHex(props.theme.black)};
    transition: 0.2s border-color ease;
  }

  .accordion__item + .accordion__item {
    margin-top: 16px;
  }

  .accordion__item__content {
    display: none;
  }

  .accordion__item__heading {
    margin: 0;
  }

  .accordion__item--active {
    border-color: ${props => handleHex(props.theme.black, false)};

    .accordion__item__content {
      display: block;
      margin-top: ${initial};
      padding: ${initial};
      padding-top: 0;

      animation-name: fadeIn;
      animation-duration: 0.3s;
    }

    .accordion__item__toggle {
      background-color: ${props => handleHex(props.theme.grey300)};

      svg {
        transform: rotate(180deg);
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default StyledAccordion;

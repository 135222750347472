import * as React from 'react';

function SvgConstruction(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M14.204 15.857l2.545-2.545 7.283 7.283-2.545 2.546zM18.7 9.6c2.3 0 4.2-1.9 4.2-4.2 0-.7-.2-1.4-.5-1.9l-3.3 3.3L17.3 5l3.3-3.3c-.6-.3-1.2-.5-1.9-.5-2.3 0-4.2 1.9-4.2 4.2 0 .5.1 1 .3 1.4L12.4 9l-2.2-2.2.9-.8-1.7-1.7L12 1.7C10.6.3 8.3.3 6.9 1.7L2.6 6l1.7 1.7H.9l-.9.9 4.3 4.3.9-.9V8.6l1.7 1.7.9-.9 2.2 2.2-9 9 2.6 2.6L17.3 9.3c.4.2.9.3 1.4.3z"
      />
    </svg>
  );
}

export default SvgConstruction;

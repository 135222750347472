import React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { navigate } from 'gatsby-link';

// Particles
import { ApplicationContext } from 'corigan';
import { hasPermission } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageRemove } from 'corigan';
import { localStorageSet } from 'corigan';
import { useMyPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Atoms
import { Link } from 'corigan';

// Molecules
import { Error } from 'corigan';
import { Loader } from 'corigan';

declare type ProtectedRouteProps = {
  children: any;
  // Where does the user get redirected to if they don't have permission?
  redirect?: string;
  requiredPermissions?: PermissionLevelName[];
};

const isValidTokenTimestamp = (expirationValue: string): boolean => {
  if (!expirationValue) return false;

  const expirationDate: Date = new Date(expirationValue);
  const expirationTime: number = expirationDate.getTime();

  const now: Date = new Date();
  const currentTime: number = now.getTime();

  const isValid: boolean = currentTime < expirationTime;
  return isValid;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { children, redirect, requiredPermissions } = props;
  const [attempt, setAttempt] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  // Accessing global application context state store
  const context: ApplicationContextProps = useContext(ApplicationContext);

  // Function made available by Firebase SDK
  const getIdTokenResult = context?.firebase?.getIdTokenResult;

  // The current value of the localTokenResult
  const localTokenResult: IdTokenResult = localStorageRead(`localTokenResult`);

  // Helper function to set the localTokenResult value to localStorage
  const setLocalTokenResult = useCallback((value: any) => localStorageSet(`localTokenResult`, value), []);

  // The permissions of the currently logged in user
  const currentPermissions: Permission[] = useMyPermissions();
  // Does the user have any permissions for us to compare against when loading a page?
  const hasPermissions: boolean = currentPermissions?.length > 0;

  const handleUnverified = useCallback(() => {
    setLocalTokenResult(null);
    navigate(`/login`);
  }, [setLocalTokenResult]);

  const verifyToken = useCallback(() => {
    // If we have no localTokenResult, we can't see if it still valid
    if (!localTokenResult) return false;

    // Get the datetime stamp from the localTokenResult object
    const expirationTime: string = localTokenResult?.expirationTime;
    // If no datetime is found, then set the localStorage value to null as we can't verify
    if (!expirationTime) setLocalTokenResult(null);

    // Determine if the current time is less than the expiration time
    const isValid: boolean = isValidTokenTimestamp(expirationTime);

    return isValid;
  }, [localTokenResult, setLocalTokenResult]);

  // This sideEffect is to verify the localTokenResult hasn't expired
  useEffect(() => {
    // If we have an expired token, set a new result
    async function getNewTokenResult() {
      try {
        // Get the result of the users' token result from Firebase SDK
        const result = await getIdTokenResult();

        // Set the response to localStorage
        setLocalTokenResult(result);
      } catch (error) {
        console.error(error);

        // If something went wrong, log the user out
        handleUnverified();
        return;
      }
    }

    // Has our token expired?
    const isValid = verifyToken();

    // If it is not less than, then it has expired and we need to set a new result
    if (!isValid) getNewTokenResult();
  }, [handleUnverified, getIdTokenResult, setLocalTokenResult, verifyToken]);

  useEffect(() => {
    // If we aren't loading, we shouldn't be fetching the result
    if (!loading) return;

    // If we don't have the SDK function from firebase, we can't run
    if (!getIdTokenResult) {
      setLoading(false);
      return;
    }

    // If the window object is unavailable, we can't run 'getIdTokenResult'
    if (!windowAvailable()) return;

    const isValid: boolean = verifyToken();

    if (isValid) {
      setLoading(false);
      return;
    }

    async function fetchTokenRes() {
      try {
        // Get the result of the users' token result from Firebase SDK
        const result = await getIdTokenResult();

        // Set the response to localStorage
        setLocalTokenResult(result);
      } catch (error) {
        console.error(error);
        handleUnverified();
        return;
      } finally {
        setAttempt(a => a + 1);
        const finalAttempt: boolean = attempt === 3;
        if (finalAttempt) setLoading(false);
      }
    }

    fetchTokenRes();
  }, [attempt, getIdTokenResult, handleUnverified, loading, setLocalTokenResult, verifyToken]);

  // If the application is loading, we should return the loader component
  if (loading) return <Loader />;

  // Is the current user authorized?
  // As in do we have a current user set to our state object?
  const currentUser = context?.state?.currentUser;
  const isAuthorized: boolean = Boolean(currentUser);
  const needsToLogin: boolean = !isAuthorized;

  // If the user hasn't logged in yet, tell them to log in
  // Otherwise, does the user have any permissions for us to use in our application?
  // If not create a timeout loader to wait for any changes to their permissions
  if (needsToLogin || !hasPermissions) return <TimeoutLoader hasPermissions={hasPermissions} />;

  const { missingPermissions, userHasPermission } = hasPermission({ currentPermissions, requiredPermissions });
  if (!userHasPermission) return <NotAuthorized redirect={redirect} missing={missingPermissions} />;

  const newProps = {
    ...props,
  };

  delete newProps.children;

  // Passed all authorization, can access content
  return React.cloneElement(children, newProps);
};

declare type TimeoutLoaderProps = {
  hasPermissions: boolean;
};

const TimeoutLoader: React.FC<TimeoutLoaderProps> = (props: { hasPermissions: boolean }) => {
  const { hasPermissions } = props;

  const performReload = () => {
    // Delete the localTokenResult
    localStorageRemove(`localTokenResult`);

    // If no window is available, we can't reload it
    if (!windowAvailable()) return;

    // Reload the page
    window.location.reload();
  };

  // Redirect the user after 5 seconds
  useEffect(() => {
    if (hasPermissions) return;
    const timer = setTimeout(() => {
      performReload();
    }, 5000);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => clearTimeout(timer);
  }, [hasPermissions]);

  return <Loader />;
};

ProtectedRoute.defaultProps = {
  roles: [`user`],
};

type NotAuthorizedProps = {
  missing?: string[];
  redirect?: string;
};

const NotAuthorized = (props: NotAuthorizedProps) => {
  const { missing, redirect } = props;
  const hasMissing: boolean = missing?.length > 0;

  // Redirect the user after 2 seconds
  useEffect(() => {
    if (!redirect) return;
    const timer = setTimeout(() => {
      navigate(redirect);
    }, 2000);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => clearTimeout(timer);
  }, [redirect]);

  const RequiresBlock = () => {
    if (!hasMissing) return null;
    return <span> Requires {missing.join(`, `)} permission.</span>;
  };

  return (
    <Error error="Sorry, you are not permitted to view this material." title="Unauthorized Access">
      Sorry, you are not permitted to view this material. {hasMissing && <RequiresBlock />} Please return to the{` `}
      <Link href="/">homepage</Link>.
    </Error>
  );
};

NotAuthorized.defaultProps = {
  missing: [`user`],
};

// TODO: Create an improved message design
const message = ``;

const LoginMessage = () => {
  navigate(`/login`);

  return <p>{message}</p>;
};

export default ProtectedRoute;

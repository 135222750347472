import { dateShort } from 'corigan';
import { datePretty } from 'corigan';

type dateCreateUpdateArgs = {
  createdAt: string;
  short?: boolean;
  updatedAt?: string;
};

export const dateCreateUpdate = ({ createdAt, short = false, updatedAt }: dateCreateUpdateArgs) => {
  const hasUpdateDate = updatedAt;
  const hasCreatedDate = createdAt;
  const hasADate = hasUpdateDate || hasCreatedDate;

  let keywordDate;
  if (hasADate && hasUpdateDate) keywordDate = updatedAt;
  if (hasADate && !hasUpdateDate && hasCreatedDate) keywordDate = createdAt;

  const dateAt = hasADate && keywordDate;
  const dateFormatter = short ? dateShort : datePretty;
  const dateFormatted = !hasADate ? null : dateFormatter(dateAt);

  return dateFormatted;
};

export default dateCreateUpdate;

import * as React from 'react';
import { useContext } from 'react';
import { useCallback } from 'react';

// Particles
import { OMContext } from 'corigan';

// Components
import { Modal } from 'corigan';
import { Field } from 'corigan';
import { Error } from 'corigan';

declare type PageRevisionModalProps = {
  title: string;
  text: string;
  confirmText: string;
  modalComment?: boolean;
  setConfirming: React.Dispatch<React.SetStateAction<boolean>>;
  success: boolean;
  handleSubmit: Function;
  loading: boolean;
  error: any;
};

const PageRevisionModal: React.FC<PageRevisionModalProps> = (props: PageRevisionModalProps) => {
  const { title, text, confirmText, modalComment, setConfirming, success, handleSubmit, loading, error } = props;

  const handleClose = e => {
    if (e) e.preventDefault();
    setConfirming(false);
  };

  return (
    <Modal
      title={title}
      content={text}
      confirmText={confirmText}
      enableConfirm={!success}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
      isOpen={true}
      loading={loading}
    >
      {error && <Error error={error} />}
      {!success && modalComment && <RevisionOverview handleSubmit={handleSubmit} />}
    </Modal>
  );
};

declare type RevisionOverviewProps = {
  handleSubmit: any;
};

const RevisionOverview: React.FC<RevisionOverviewProps> = (props: RevisionOverviewProps) => {
  const { handleSubmit } = props;
  const context: any = useContext(OMContext);
  const state: any = context?.state;
  const dispatch: any = context?.dispatch;

  const editedRevision: PageRevision = state.editedRevision;
  const { comment = `` } = editedRevision;

  const handleChange = useCallback(
    (e, key?: string) => {
      const { id, value } = e.target;
      dispatch({ key: key ? key : id, value });
    },
    [dispatch],
  );

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} aria-label="Save Revision Comment" className="form--trimmed">
        {React.useMemo(() => {
          return (
            <Field
              autoFocus={true}
              handleChange={handleChange}
              id="comment"
              label="Revision Comment"
              value={comment}
            />
          );
        }, [handleChange, comment])}
      </form>
    </React.Fragment>
  );
}

export default PageRevisionModal;

import styled from 'styled-components';

const tooltipLeft = props => {
  const isRight = props?.side === `right`;
  if (isRight) return `100%`;
  return null;
};

const tooltipRight = props => {
  const isLeft = props?.side === `left`;
  if (isLeft) return `100%`;
  return null;
};

export const StyledTooltipRoot = styled.span`
  display: inline-block;
  position: relative;

  &:hover {
    .tooltip {
      display: inline-flex;

      animation-name: fadeIn;
      animation-duration: 0.3s;
    }
  }

  &.tooltip--large {
    .tooltip {
      width: 260px;

      white-space: initial;

      /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    &.tooltip--url {
      .tooltip {
        width: 400px;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const tooltipTop = props => {
  const { align = `center` } = props;
  const lowerAlign = align?.toLowerCase();

  switch (lowerAlign) {
    case `top`:
      return `0%`;
    case `center`:
      return `50%`;
    case `bottom`:
      return `100%`;
    default:
      return `50%`;
  }
};

const tooltipTransform = props => {
  const { align = `center`, side } = props;
  const lowerAlign = align?.toLowerCase();
  const lowerSide = side?.toLowerCase();

  let y = `-50%`;

  switch (lowerAlign) {
    case `top`:
      y = `translateY(0%)`;
      break;
    case `center`:
      y = `translateY(-50%)`;
      break;
    case `bottom`:
      y = `translateY(-100%)`;
      break;
    default:
      y = `translateY(-50%)`;
      break;
  }

  let x = `translateX(-4px)`;

  switch (lowerSide) {
    case `left`:
      x = `translateX(-4px)`;
      break;
    case `right`:
      x = `translateX(4px)`;
      break;
    default:
      x = `translateX(-4px)`;
      break;
  }

  return `${y} ${x}`;
};

export const StyledTooltip = styled.span`
  pointer-events: none;

  align-items: center;
  display: none;
  justify-content: center;
  left: ${props => tooltipLeft(props)};
  padding: 8px 12px;
  position: absolute;
  right: ${props => tooltipRight(props)};
  top: ${props => tooltipTop(props)};
  z-index: 2;

  background-color: var(--grey700);
  color: var(--white);
  font-size: 13px;
  transform: ${props => tooltipTransform(props)};
  white-space: nowrap;

  svg {
    height: 18px;

    fill: var(--white);
  }
`;

export default StyledTooltip;

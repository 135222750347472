import * as React from 'react';

import StyledRow from './row.styles';

type RowProps = {
  /**
   * The primary content of the Row component, expects a 'Col' component
   */
  children: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  equalHeight?: boolean;
};

export const Row: React.FC<RowProps> = (props: RowProps) => {
  const { className, equalHeight } = props;

  let classList = `row`;
  if (className) classList += ` ` + className;
  if (equalHeight) classList += ` eq-height`;

  return (
    <StyledRow {...props} className={classList}>
      {props.children}
    </StyledRow>
  );
};

// Default prop values
Row.defaultProps = {};

export default Row;

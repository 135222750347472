import * as React from 'react';
import { useContext } from 'react';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { callSearch } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card, Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

const SearchPage = () => (
  <ProtectedRoute>
    <Page application="portal" pageTitle="Search">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <h1>Search</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <SearchContents />
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

const initialValues = {
  search: ``,
};

const SearchContents = () => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const formik = useFormik({
    initialValues,
    onSubmit: values => console.info(values),
  });

  const { handleChange, handleSubmit, values } = formik;

  const term = values?.search;
  const isLongEnough = term?.length >= 3;
  const showResults = isLongEnough;

  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const { data: res, error, isLoading: loading } = useQuery([`callSearch`, { whereProtected, term }], callSearch, {
    enabled: isLongEnough,
  });
  const data = res?.data;

  const resetForm = () => {
    formik.resetForm({
      values: initialValues,
    });
  };

  return (
    <Card loading={loading} minHeight={false}>
      <form autoComplete="off" className="search" onSubmit={handleSubmit}>
        <fieldset className="mb-0">
          <label className="mt-0" htmlFor="search">
            Search Term
          </label>
          <input
            id="search"
            name="search"
            onChange={handleChange}
            placeholder="Search keywords, URLs and more"
            type="search"
            value={term}
          />
        </fieldset>
        {error && <Error error={error} />}
        {showResults && !loading && <RenderResults resetForm={resetForm} results={data} />}
      </form>
    </Card>
  );
};

declare type RenderResultsProps = {
  resetForm: any;
  results?: {
    keywords: any[];
    pageRevisions: any[];
    tags: any[];
  };
};

const RenderResults = ({ resetForm, results }: RenderResultsProps) => {
  const { userHasPermission: canReadKeywords } = useHasPermissions({ requiredPermissions: [`keywords:read`] });
  const { userHasPermission: canReadPages } = useHasPermissions({ requiredPermissions: [`pages:read`] });
  const { userHasPermission: canReadTags } = useHasPermissions({ requiredPermissions: [`tags:read`] });

  const hasKeywords: boolean = canReadKeywords && results?.keywords?.length > 0;
  const hasPages: boolean = canReadPages && results?.pageRevisions?.length > 0;
  const hasTags: boolean = canReadTags && results?.tags?.length > 0;
  const hasResults: boolean = hasKeywords || hasPages || hasTags;

  return (
    <nav className="search__results">
      {!hasResults && <p>Sorry, we couldn&apos;t find anything with that search term.</p>}
      {hasResults && (
        <React.Fragment>
          {hasKeywords &&
            results.keywords.map(result => (
              <Result key={result.id} resetForm={resetForm} result={result} variant="keyword" />
            ))}
          {hasPages &&
            results.pageRevisions.map(result => (
              <Result key={result.id} resetForm={resetForm} result={result} variant="page" />
            ))}
          {hasTags &&
            results.tags.map(result => <Result key={result.id} resetForm={resetForm} result={result} variant="tag" />)}
        </React.Fragment>
      )}
    </nav>
  );
};

declare type ResultProps = {
  resetForm: any;
  result: any;
  variant: 'keyword' | 'page' | 'tag';
};

const Result = ({ resetForm, result, variant }: ResultProps) => {
  const id = result?.id;
  const pageID = result?.page;

  let href = ``;
  let text = ``;

  switch (variant) {
    case `page`:
      href = ROUTES.page + `?id=${pageID}`;
      text = result?.name;
      break;
    case `keyword`:
      href = ROUTES.keyword + `?id=${id}`;
      text = result?.phrase;
      break;
    case `tag`:
      href = ROUTES.tag + `?id=${id}`;
      text = result?.name;
      break;
    default:
      href = ``;
      text = ``;
      break;
  }

  const noLink = href === ``;
  const noText = text === ``;
  const invalidResult = noLink || noText;

  if (invalidResult) return null;

  const key = `result-${id}`;

  return (
    <Link onClick={resetForm} key={key} href={href}>
      {text}
    </Link>
  );
};

export default SearchPage;

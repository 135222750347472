declare type GenerateSeriesGSCArgs = {
  pages?: any[];
  view: 'ctr' | 'impressions';
};

declare type GSCData = { average_ctr?: number; total_impressions?: number; date: string };

const cleanArray = (array: any[]): any[] => {
  const hasArray: boolean = array?.length > 0;
  if (!hasArray) return [];

  return array.filter(Boolean);
};

export const generateSeriesGSC = (props: GenerateSeriesGSCArgs) => {
  const { pages, view } = props;

  let dirtyData = [];
  const hasPages: boolean = pages?.length > 0;

  // Determine booleans based on the view of the application
  const isCTR: boolean = view === `ctr`;
  const isImpressions: boolean = view === `impressions`;

  if (!hasPages) return null;

  // Loop through each page
  dirtyData = pages?.map((page: any) => {
    // Get the url value, to use in the axis series plotting
    const name = page?.url;
    // Get the GSC weekly data from the page
    const gscWeekly: GSCData[] = page?.gscWeekly;

    // Do we have GSC date to loop through?
    const hasGSC: boolean = gscWeekly?.length > 0;
    const isItterable: boolean = hasGSC && (isCTR || isImpressions);

    if (!isItterable) return null;

    // If we have iterrable data
    // For each array value, loop through the item
    const dirtyData = gscWeekly?.map(gsc => {
      // Get the CTR value
      const averageCTR: number = gsc?.average_ctr;
      // Get the Impressions value
      const totalImpressions: number = gsc?.total_impressions;

      // Get the corresponding date
      const date: string = gsc?.date;
      if (!date) return null;

      let value: number = averageCTR;
      if (isImpressions) value = totalImpressions;
      if (!value) return null;

      const newItem: { x: string; y: number } = {
        x: date,
        y: value,
      };

      return newItem;
    });
    const cleanData = cleanArray(dirtyData);

    return { name, data: cleanData };
  });

  const cleanData = cleanArray(dirtyData);
  return cleanData;
};

export default generateSeriesGSC;

import { css } from 'styled-components';

const hr = css`
  hr {
    height: 1px;
    margin: 24px auto;
    width: 100%;

    background-color: var(--grey600);
    border: none;
    color: var(--grey600);
    outline: 0;
  }

  .hr--text {
    margin: 27px auto;
    height: 1px;
    position: relative;

    background-color: var(--grey800);
    background-color: currentColor;
    color: var(--grey800);
    color: currentColor;
    text-align: center;

    &:after {
      content: attr(data-content);
      display: inline-block;
      padding: 0 12px;
      position: relative;
      top: 50%;

      background-color: var(--white);
      font-size: 15px;
      line-height: 25px;
      font-weight: 700;
      letter-spacing: 0px;
      color: var(--grey800);
      opacity: 1;
      transform: translateY(-50%);
    }
  }
`;

export default hr;

export const updateCollectionCache = (previousCollection, newItems) => {
  // Are there keywords in the cache?
  const previousData = previousCollection?.data;
  if (!previousData) return previousCollection;

  // If there are no keywords in the cache, create a new array with the new keyword
  const hasPrevious = previousData?.length > 0;
  if (!hasPrevious) return previousCollection;

  // Get an array of the new item IDs
  const newIDs = newItems.map(item => item?.id).filter(Boolean);

  // Replace the previous keyword where an ID matches
  const removedData = previousData.filter(collectionItem => {
    const isMatch = newIDs.includes(collectionItem.id);
    return !isMatch;
  });

  let newData = [];
  const hasRemoved = removedData?.length > 0;
  if (!hasRemoved) newData = [...newItems];
  if (hasRemoved) newData = [...removedData, ...newItems];

  return {
    ...previousCollection,
    data: newData,
  };
};

export default updateCollectionCache;

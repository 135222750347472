import * as React from 'react';

function SvgPeopleAlt(props) {
  return (
    <svg
      id="people-alt_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.people-alt_svg__st1{fill-rule:evenodd;clip-rule:evenodd}'}</style>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        className="people-alt_svg__st1"
        fill="currentColor"
        d="M17.1 13.2c1.5 1 2.5 2.4 2.5 4.2v3.2h4.3v-3.2c0-2.3-3.9-3.7-6.8-4.2z"
      />
      <circle className="people-alt_svg__st1" fill="currentColor" cx={8.8} cy={7.7} r={4.3} />
      <path
        className="people-alt_svg__st1"
        fill="currentColor"
        d="M15.2 12c2.4 0 4.3-1.9 4.3-4.3s-1.9-4.3-4.3-4.3c-.5 0-1 .1-1.4.3.9 1.1 1.4 2.5 1.4 4.1s-.5 3-1.4 4.1c.5 0 .9.1 1.4.1zm-6.4 1.1c-2.9 0-8.7 1.5-8.7 4.3v3.2h17.3v-3.2c0-2.9-5.8-4.3-8.6-4.3z"
      />
    </svg>
  );
}

export default SvgPeopleAlt;

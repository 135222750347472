import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  table {
    position: relative;

    -moz-font-feature-settings: “tnum”;
    -webkit-font-feature-settings: “tnum”;
    font-feature-settings: “tnum”;
    font-variant-numeric: tabular-nums;
  }

  .table__controls {
    margin-left: 8px;
    text-align: right;
  }

  .table__delete {
    margin-left: 8px;
  }

  .table__uses {
    margin-left: 8px;
  }

  .table__footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .table__button--everything {
    margin: 24px 0 0;
    padding: 16px;

    background-color: var(--grey100);

    button {
      /* Button Reset CSS */
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: relative;
      width: auto;
      overflow: visible;

      background: transparent;
      border: none;
      box-shadow: none;
      color: inherit;
      font: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: normal;
      text-decoration: none;
      text-transform: inherit;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      margin-left: 8px;
      padding-bottom: 5px;

      border-bottom: 1px solid var(--blue100);
      color: var(--blue);
      font-weight: 500;
    }

    span {
      span {
        font-weight: 500;
      }
    }
  }

  .table__loader {
    height: 2px;
    padding: 0;

    > div {
      max-width: 100%;
      width: 100%;
    }
  }

  .table__pagination {
    align-items: center;
    display: flex;

    > * {
      margin-bottom: 0;
      margin-top: 0;

      + * {
        margin-left: 8px;
      }
    }

    select {
      min-width: 100px;
      width: auto;
    }
  }

  .table__transfer {
    button + button {
      margin-left: 8px;
    }
  }

  .chip {
    margin-bottom: 6px;
    margin-right: 4px;
  }

  .compare__button {
    margin-right: 10px;

    svg {
      width: 18px;
    }
  }
`;

export const StyledTH = styled.th`
  padding: ${props => (props.filter ? `0` : undefined)};

  button {
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    padding: 8px 0;
    width: 100%;

    text-transform: initial;
  }

  svg {
    height: 14px;
    margin-left: 4px;
  }

  &.th--ascending {
    svg {
      transform: rotate(0deg);
    }
  }

  &.th--descending {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export default StyledTableWrapper;

export const addCollectionCache = (previousCollection, newItems) => {
  const data = previousCollection?.data;
  if (!data) return previousCollection;

  const hasExistingData = data?.length > 0;
  if (!hasExistingData) return { ...previousCollection, data: [...newItems] };

  const newData = [...data, ...newItems];

  return {
    ...previousCollection,
    data: newData,
  };
};

export default addCollectionCache;

import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';

import { Filter } from 'icons';

import { Button } from 'corigan';
import { Modal } from 'corigan';
import { Tooltip } from 'corigan';

import TableContext from '../../../context/tableContext';

import type { TableContextProps } from '../../../table.types';

declare type ColumnsModalProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
};

const ColumnsModal: React.FC<ColumnsModalProps> = (props: ColumnsModalProps) => {
  const { isOpen, setOpen } = props;

  const context: TableContextProps = useContext(TableContext);
  const dispatch = context?.dispatch;

  const state = context?.state;
  const { columns } = state;
  const usableColumns = columns.filter(column => !column.disabled);

  const handleChange = value => {
    if (!dispatch) return;
    const type = `columnToggle`;
    dispatch({ type, value });
  };

  const handleConfirm = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal handleConfirm={handleConfirm} handleClose={handleClose} isOpen={isOpen} title="Manage Table Columns">
      {usableColumns.map(col => {
        const { hide, label, value } = col;
        const key = `modal-col-${value}`;
        const isChecked = !hide;

        return (
          <React.Fragment key={key}>
            <input checked={isChecked} id={value} onChange={() => handleChange(value)} name={value} type="checkbox" />
            <label htmlFor={value}>{label}</label>
          </React.Fragment>
        );
      })}
    </Modal>
  );
};

const ColumnControls = () => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  let buttonText = `Manage Columns`;
  if (isOpen) buttonText = `Close Columns`;

  return (
    <React.Fragment>
      <Tooltip content="Manage filters" side="left">
        <Button onClick={handleClick} variant="hollow" type="button">
          <span className="hidden">{buttonText}</span>
          <Filter />
        </Button>
      </Tooltip>
      <ColumnsModal isOpen={isOpen} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default ColumnControls;

import styled from 'styled-components';
import { device } from 'particles';
import { breakpoints } from 'particles';

const handleBreakpoints = props => {
  let styles = ``;

  Object.keys(breakpoints).map(size => {
    const foundSize = props[size];
    if (!foundSize) return null;

    styles += `
      @media ${device[size]} {
        ${handleSize(foundSize)};
      }
     `;
    return null;
  });

  return styles;
};

function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const handleSize = size => {
  const percentage = (100 / 12) * size;
  const fixedPercentage = round(percentage, 5);

  let css = ``;
  css += `flex: 0 0 ${fixedPercentage}%;`;
  css += `max-width: calc(${fixedPercentage}% - var(--gap));`;

  return css;
};

const StyledCol = styled.div`
  --gap: 15px;

  flex: 0 0 auto;
  margin-bottom: var(--gap) !important;
  margin-left: var(--gap) !important;
  max-width: 100%;
  min-height: 1px;
  position: relative;
  width: 100%;

  @media ${device?.sm} {
    flex-basis: 0;
    flex-grow: 1;
    margin: 0;
    max-width: 100%;
  }

  ${props => handleBreakpoints(props)}

  &.col--full {
    > *:only-child {
      height: 100%;
    }
  }
`;

export default StyledCol;

import * as React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useReducer } from 'react';

import ApplicationContext from './applicationContext';
import applicationReducer from './applicationReducer';
import defaultState from './defaultState';

import { getRelease } from 'corigan';
import { windowAvailable } from 'corigan';
import { useMyDomains } from 'corigan';

declare type ApplicationStateProps = {
  children: React.ReactNode;
  firebase: any;
};

// Create global state provider to handle state and reducer dispatch events
const ApplicationState = ({ children, firebase }: ApplicationStateProps) => {
  const hasWindow: boolean = windowAvailable();
  const { releaseVersion } = getRelease();

  const initialState = { ...defaultState };

  const domainActive = hasWindow && JSON.parse(window.localStorage.getItem(`domainActive`));
  if (domainActive) initialState.domainActive = domainActive;

  const [state, dispatch] = useReducer(applicationReducer, initialState);

  const hasActiveDomain = Boolean(state?.domainActive);
  const foundDomains = useMyDomains();

  const setCurrentUser = useCallback(
    (user: User) => {
      // If we don't have the window object, we can't modify localStorage
      if (!hasWindow) return;

      const value = user ? user : null;
      const data = {
        key: `currentUser`,
        type: `set`,
        value,
      };

      dispatch(data);
    },
    [dispatch, hasWindow],
  );

  // Check firebase for authentication state change, and set user value
  useEffect(() => {
    if (firebase?.refreshUser) firebase.refreshUser(setCurrentUser);
  }, [firebase, setCurrentUser]);

  // Set the active domain for the user
  useEffect(() => {
    if (!foundDomains) return;
    dispatch({ key: `domains`, type: `set`, value: foundDomains });

    if (hasActiveDomain) return;
    const firstDomain = foundDomains?.[0];
    dispatch({ key: `domainActive`, type: `set`, value: firstDomain });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundDomains]);

  // Remove old keys (prev versions)
  useEffect(() => {
    // If we don't have the window object, we can't modify localStorage
    if (!hasWindow) return;

    const entries = Object.entries(window.localStorage);
    const hasEntries = entries?.length > 0;
    if (!hasEntries) return;

    // Get localStorage keys
    const keys = entries.map(entry => entry[0]);
    const hasKeys = keys?.length > 0;
    if (!hasKeys) return;

    // Get an array of the versioned keys
    const versionedKeys = keys.filter(key => key.startsWith(`version=`));
    const hasVersionedKeys = versionedKeys?.length > 0;
    if (!hasVersionedKeys) return;

    // Get an array of the old keys
    const oldKeys = versionedKeys.filter(key => {
      const isValid = key.startsWith(`version=${releaseVersion}`);
      return !isValid;
    });
    const hasOldKeys = oldKeys?.length > 0;
    if (!hasOldKeys) return;

    oldKeys.forEach(key => {
      const hasKey: boolean = window.localStorage.hasOwnProperty(key);
      if (hasKey) window.localStorage.removeItem(key);
    });
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        firebase,
        state,
        dispatch,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationState;

/**
 * 'dbKey' property allows a column value to be passed to CrudTable.
 * The component will favour this property if it is present.
 */
export const columns = [
  {
    align: `left`,
    dbKey: `id`,
    filter: false,
    sort: true,
    hide: true,
    label: `ID`,
    numeric: false,
    value: `id`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `pageId`,
    filter: true,
    sort: true,
    hide: true,
    label: `Page ID`,
    numeric: false,
    value: `pageId`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `name`,
    filter: true,
    sort: true,
    hide: false,
    label: `Name`,
    numeric: false,
    value: `name`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `title`,
    filter: true,
    sort: true,
    hide: false,
    label: `Title`,
    numeric: false,
    value: `title`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `metaDescription`,
    filter: true,
    sort: true,
    hide: false,
    label: `Meta Description`,
    numeric: false,
    value: `metaDescription`,
    wrap: true,
  },
  {
    align: `left`,
    dbKey: `url`,
    filter: true,
    sort: true,
    hide: true,
    label: `URL`,
    numeric: false,
    value: `url`,
    wrap: false,
  },
  {
    align: `left`,
    chip: true,
    dbKey: `canonicals.url`,
    filter: true,
    sort: false,
    hide: true,
    label: `Child Pages`,
    numeric: false,
    value: `canonicals`,
    wrap: true,
  },
  {
    align: `left`,
    dbKey: `assignedTo.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Assigned To`,
    numeric: false,
    value: `assignedTo`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `pageType.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Type`,
    numeric: false,
    value: `pageType`,
    wrap: false,
  },
  {
    align: `left`,
    chip: true,
    dbKey: `categories.category.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Categories`,
    numeric: false,
    value: `categories`,
  },
  {
    align: `left`,
    chip: true,
    dbKey: `tags.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Tags`,
    numeric: false,
    value: `tags`,
  },
  {
    align: `left`,
    chip: true,
    dbKey: `keywords.phrase`,
    filter: true,
    sort: false,
    hide: false,
    label: `Keywords`,
    numeric: false,
    value: `keywords`,
  },
  {
    align: `left`,
    dbKey: `keywordCount`,
    filter: true,
    sort: true,
    hide: false,
    label: `Keyword Count`,
    numeric: true,
    value: `keywordCount`,
  },
  {
    align: `center`,
    dbKey: `page.crawlResponse.responseCode`,
    filter: true,
    sort: false,
    hide: false,
    label: `HTTP Status`,
    numeric: true,
    value: `status`,
  },
  {
    align: `left`,
    dbKey: `contentWordCount`,
    filter: true,
    sort: true,
    hide: false,
    label: `Word Count`,
    numeric: true,
    value: `wordCount`,
    wrap: false,
  },
  {
    align: `right`,
    dbKey: `updatedAt`,
    filter: true,
    sort: true,
    hide: false,
    label: `Updated`,
    numeric: true,
    date: true,
    value: `changedFormatted`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `updatedBy.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Updated By`,
    numeric: false,
    value: `updatedBy`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `status`,
    filter: true,
    sort: true,
    hide: false,
    label: `Status`,
    numeric: false,
    value: `currentPageStatus`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `contentBlocks.content`,
    filter: true,
    sort: false,
    hide: true,
    label: `Content Text`,
    numeric: false,
    value: `contentText`,
    wrap: true,
  },
  {
    align: `right`,
    dbKey: `implementedAt`,
    filter: true,
    sort: true,
    hide: false,
    label: `Implemented`,
    numeric: true,
    date: true,
    value: `implementedAt`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `model`,
    filter: false,
    sort: false,
    hide: false,
    label: `Overview`,
    numeric: false,
    value: `overview`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `currentRejected`,
    filter: true,
    sort: true,
    hide: false,
    label: `Current Rejected`,
    numeric: false,
    value: `currentRejected`,
    wrap: false,
  },
];

export default columns;

import { fetchAPI } from 'particles';

type GetSistrixBrowseWeeklyComparisonArguments = {
  domainId: ArgID;
  limit: number;
};

export const callGetSistrixBrowseWeeklyComparison = async (
  reqKey,
  parameters: GetSistrixBrowseWeeklyComparisonArguments,
) => {
  const response = await fetchAPI({
    method: `GET`,
    parameters,
    route: `sistrix/market/subfoldersBrowseWeeklyComparison`,
  });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetSistrixBrowseWeeklyComparison;

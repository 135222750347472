import ROUTES from 'routes';

export const defaultState = {
  applications: {
    items: [
      {
        id: `om`,
        icon: `chart`,
        href: ROUTES?.optimisation_manager,
        title: `Optimisation Manager`,
      },
      {
        id: `km`,
        icon: `clipboard`,
        href: ROUTES?.keyword_manager,
        title: `Keyword Manager`,
      },
      {
        id: `kr`,
        icon: `search`,
        href: ROUTES?.keyword_research,
        title: `Keyword Research`,
      },
      {
        id: `cr`,
        icon: `diagram`,
        href: ROUTES?.competitor_research,
        title: `Competitor Research`,
      },
      {
        id: `rb`,
        icon: `builder`,
        href: ROUTES?.report_builder,
        title: `Report Builder`,
      },
    ],
  },
  domainActive: ``,
  domains: [],
  chunkIndex: 0,
  modalData: {
    handleConfirm: () => {},
    isOpen: false,
    message: ``,
  },
  refresh: 0,
  sidebar: {
    title: `Corigan`,
  },
  tabs: [],
};

export default defaultState;

import { dateCreateUpdate } from 'corigan';
import { generateChip } from 'corigan';
import { ROUTES } from 'corigan';

const generateTag = tag => {
  const id = tag?.id;
  const name = tag?.name;

  const invalidProperties = !id || !name;
  if (invalidProperties) return;

  const value = name;

  return generateChip({
    condition: `contains`,
    href: undefined,
    value,
    where: `tags.name`,
  });
};

export const generateRows = (data: Keyword[]) => {
  return data.map(keyword => {
    const currentSerpPosition = keyword?.currentSerpPosition && keyword?.currentSerpPosition !== 9999 ? keyword?.currentSerpPosition : `100+`;
    const highPriority = keyword?.highPriority;
    const highestRanking = keyword?.highestRankingUrl;
    const id = { href: `${ROUTES.keyword}?id=${keyword.id}`, value: keyword.id };
    const keywordId = { href: `${ROUTES.keyword}?id=${keyword.id}`, value: keyword.keywordId };
    const phrase = { href: `${ROUTES.keyword}?id=${keyword.id}`, value: keyword.phrase };
    const searchVolume = keyword?.searchVolume?.volume ?? 0;
    const url = keyword?.url;
    const keywordBriefedUseCount = keyword?.keywordBriefedUseCount ?? 0;
    const keywordLiveUseCount = keyword?.keywordLiveUseCount ?? 0;
    const status = keyword?.status ?? `Inactive`;
    const response = keyword?.crawlResponse?.responseCode ?? `Unknown`;

    let updatedBy;

    if(keyword?.updatedBy?.[`name`] && keyword?.updatedBy?.[`name`] !== `System`) {
      updatedBy = keyword?.updatedBy[`name`];
    } else {
      updatedBy = `Not updated`;
    }

    const tags = keyword?.tags.map(generateTag);

    const { createdAt, updatedAt } = keyword;
    const dateFormatted = dateCreateUpdate({ createdAt, updatedAt });

    let priorityValue: any = ``;
    priorityValue = [
      generateChip({
        condition: `eq`,
        href: undefined,
        value: Boolean(highPriority).toString(),
        where: `highPriority`,
      }),
    ];

    const mismatchedState: any = keyword?.highestRankingMatchStatus ?? `Unknown`;
    const matchVariant = mismatchedState === `Unknown` ? `plain` : mismatchedState === `Mismatched` ? `red` : `green`;

    const mismatchedItem = [
      generateChip({
        condition: `eq`,
        href: undefined,
        value: String(mismatchedState),
        variant: matchVariant,
        where: `highestRankingMatchStatus`,
      }),
    ];

    let responseVariant = `green`;
    if (response && response >= 300) responseVariant = `orange`;
    if (response && response >= 400) responseVariant = `red`;
    if (response && response === `Unknown`) responseVariant = `plain`;

    let httpStatus = null;
    if (response) {
      httpStatus = [
        generateChip({
          href: undefined,
          value: String(response),
          variant: responseVariant,
        }),
      ];
    }

    const item = {
      id,
      keywordId,
      phrase,
      url,
      tags,
      status,
      httpStatus,
      currentSerpPosition,
      searchVolume,
      highPriority: priorityValue,
      keywordBriefedUseCount,
      keywordLiveUseCount,
      highestRanking,
      mismatchedItem,
      dateFormatted,
      updatedBy,
      originalObject: keyword,
    };

    return item;
  });
};

import * as React from 'react';

// Particles
import { ThemeDefault } from 'corigan';

// Molecules
import { ChartPie } from 'corigan';
import { Error } from 'corigan';
import { Card } from 'corigan';

declare type PieStatusProps = {
  crawl: Crawl;
  error?: any;
  loading?: boolean;
};

const PieStatus = (props: PieStatusProps) => {
  const { crawl, error, loading } = props;

  const statistics = crawl?.statistics;
  const hasStatistics: boolean = statistics?.length > 0;

  const title = `Site Status Codes`;

  const chartData = React.useMemo(() => {
    if (!hasStatistics) return null;

    const dataStatusOnly = statistics.filter(category => category?.name?.toLowerCase()?.includes(`http`));
    const hasStatuses = dataStatusOnly?.length > 0;
    if (!hasStatuses) return null;

    const orderedStatistics = dataStatusOnly.sort((a, b) => b?.value - a?.value);
    const statusTotal = dataStatusOnly.reduce((total, current) => total + current?.value, 0);

    const labels: string[] = orderedStatistics.map(stat => stat?.name).filter(Boolean);
    const hasLabels = labels?.length > 0;
    if (!hasLabels) return null;

    const green = ThemeDefault?.green500;
    const yellow = ThemeDefault?.yellow600;
    const orange = ThemeDefault?.orange200;
    const red = ThemeDefault?.red;

    const options: { labels: string[] } = {
      colors: [green, yellow, orange, red],
      labels,
      legend: {
        position: `right`,
        horizontalAlign: `left`,
      },
      tooltip: {
        y: {
          formatter: value => {
            if (!statusTotal) return value;

            const valueNumber = Number(value);
            const stringValue = value;
            const valuePercentage = valueNumber / statusTotal;
            const stringPercentage = valuePercentage?.toLocaleString(`en`, {
              style: `percent`,
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            });

            const text = `${stringValue} (${stringPercentage})`;
            return text;
          },
        },
      },
    };

    const series: number[] = orderedStatistics.map(stat => stat?.value).filter(Boolean);
    const hasSeries = series?.length > 0;
    if (!hasSeries) return null;

    return { options, series };
  }, [hasStatistics, statistics]);

  if (!hasStatistics && !loading) return null;

  return (
    <Card loading={loading} minHeight={false}>
      <h2 className="mb-3">{title}</h2>
      {error && <Error error={error} />}
      {chartData && <ChartPie id="competitor-site-status-pie" options={chartData.options} series={chartData.series} />}
    </Card>
  );
};

export default PieStatus;

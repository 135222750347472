import { fetchAPI } from 'particles';

type GetReportKeywordURLRankingParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  domainId?: ArgID;
  category?: string;
  competitor?: string;
  top?: string;
  filter?: string;
  where?: ArgWhere;
};

declare type CallGetReportKeywordURLRankingData = {
  data: string[];
  pagination: Pagination;
};

export const callGetReportKeywordURLRanking = async (
  queryKey,
  parameters: GetReportKeywordURLRankingParameters,
): Promise<{ data: CallGetReportKeywordURLRankingData[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/pages/keyword-url-ranking/` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportKeywordURLRanking;

import { ROUTES } from 'corigan';

export const generateRows = ({ data }) => {
    if (!data) return [];

    const formattedData = data.map(row => {
      const prefURLNoProtocol = row?.prefURL !== `Not Ranking` ? row?.prefURL.split(`/`).slice(2).join(`/`) : `Not Ranking`;
      const topURLNoProtocol = row?.topURL !== `Not Ranking` ?  row?.topURL.split(`/`).slice(2).join(`/`) : `Not Ranking`;

      // define keyword research chip
      let keyword;
      if(row?.prefURL === `Not Ranking`){
        // check and define the result url param for keyword create button
        const itemURL = (row?.topURL !== `na`) ? `&url=${row?.topURL}`: ``;
        // check if keyword exists and define create chip accordingly
        keyword = [{
          href: `${ROUTES.keywords_create}?phrase=${row?.keyword}${itemURL}`,
          value: row?.keyword,
          variant: `red`,
          tooltip: `This keyword does not exist for you. Click here to create - ${row?.keyword}`,
          tooltipAlign: `center`,
          tooltipSide: `right`,
        }];
      } else if (row?.keywordResearch !== `na`) {
        keyword = [{
          href: `${ROUTES.research}?id=${row?.keywordResearch}`,
          value: row?.keyword,
          variant: `green`,
          tooltip: `Veiw the research for this keyword - ${row?.keyword}`,
          tooltipAlign: `center`,
          tooltipSide: `right`,
        }]
      } else {
        keyword = row?.keyword
      }

      // define volume item
      const searchVolume = (row?.searchVolume !== `na`) ? row?.searchVolume : ``;

      // define competitor rank url chip
      const compRank = (row?.comparePageUrl !== `na` && row?.compRank > 0) ?
        [{
          href: row?.comparePageUrl,
          value: row?.compRank,
          tooltip: `View competitors page - ${row?.comparePageUrl}`,
          tooltipAlign: `center`,
          tooltipSide: `right`,
        }] : ``;

      // define pref url page chip
      let prefRank;
      if (row?.prefURL !== `Not Ranking` && row?.prefRank > 0) {
        prefRank = [{
          href: row?.prefURL,
          value: row?.prefRank,
          tooltip: `View pref URL page - ${row?.prefURL}`,
          tooltipAlign: `center`,
          tooltipSide: `right`,
        }];
      } else if (row?.prefURL !== `Not Ranking`) {
        prefRank = [{
          href: row?.prefURL,
          value: `Not ranked`,
          tooltip: `Your keyword's pref URL is not ranked. View the  -${row?.prefURL}`,
          tooltipAlign: `center`,
          tooltipSide: `right`,
        }];
      }

      // define top rank url chip
      const topRank = [{
          href: row?.topURL,
          value: row?.topRank,
          tooltip: `View top page - ${row?.topURL}`,
          tooltipAlign: `center`,
          tooltipSide: `right`,
        }];

      // define compare pages chip
      let comparePage;
      if (row?.comparePageUrl !== `na` && row?.comparePage !== `na` && row?.prefURL !== `Not Ranking` && row?.prefRank > 0 && topURLNoProtocol !== prefURLNoProtocol) {
        comparePage = [{
          href: `${ROUTES.performance_comparison}?competitor=${row?.comparePage}&compurl=${btoa(row?.comparePageUrl)}&prefurl=${btoa(row?.topURL)}`,
          value: `Compare top URL`,
          tooltip: `Compare top URL - ${row?.topURL} against ${row?.comparePageUrl}`,
          tooltipAlign: `center`,
          tooltipSide: `left`,
        }, {
          href: `${ROUTES.performance_comparison}?competitor=${row?.comparePage}&compurl=${btoa(row?.comparePageUrl)}&prefurl=${btoa(row?.prefURL)}`,
          value: `Compare pref URL`,
          tooltip: `Compare pref URL - ${row?.prefURL} against ${row?.comparePageUrl}`,
          tooltipAlign: `center`,
          tooltipSide: `left`,
        }];
      } else if (row?.comparePageUrl !== `na` && row?.comparePage !== `na`) {
        comparePage = [{
          href: `${ROUTES.performance_comparison}?competitor=${row?.comparePage}&compurl=${btoa(row?.comparePageUrl)}&prefurl=${btoa(row?.topURL)}`,
          value: `Compare top URL`,
          tooltip: `Compare top URL - ${row?.topURL} against ${row?.comparePageUrl}`,
          tooltipAlign: `center`,
          tooltipSide: `left`,
        }];
      }

      // define brief page chip
      let briefPage;
      if (row?.pageid !== `na`) {
        briefPage= [{
          href: `${ROUTES.page}?id=${row?.pageid}&status=${row?.pageStatus}`,
          value: `${row?.pageStatus} page`,
          tooltip: `View ${row?.pageStatus} pagerevision for - ${row?.prefURL}`,
          tooltipAlign: `center`,
          tooltipSide: `left`,
        }];
      } else if (row?.prefURL !== `Not Ranking`) {
        briefPage= [{
          href: `${ROUTES.page_create}?url=${btoa(row?.prefURL)}`,
          value: `Create page`,
          tooltip: `The pref URL does not exist as a page in the system. You can create page for the pref URL here - ${row?.prefURL}`,
          tooltipAlign: `center`,
          tooltipSide: `left`,
        }];
      }

      // build row
      const item = {
        keyword,
        searchVolume,
        compRank,
        prefRank,
        topRank,
        comparePage,
        briefPage,
      };

      return item;
    });

    return formattedData;
  };

import { css } from 'styled-components';
import { device } from 'particles';

const heading = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    font-weight: 700;
    line-height: 1.25;
    text-transform: capitalize;

    & + p {
      margin-top: 16px;
    }
  }

  h1,
  .h1 {
    font-size: 24px;

    @media ${device?.lg} {
      font-size: 28px;
      line-height: 40px;
    }

    &.lowercase {
      text-transform: lowercase;
    }
  }

  h2,
  .h2 {
    font-size: 20px;

    @media ${device?.lg} {
      font-size: 24px;
    }
  }

  h3,
  .h3 {
    font-size: 20px;

    @media ${device?.lg} {
      font-size: 24px;
    }
  }

  h4,
  .h4 {
    font-size: 20px;

    @media ${device?.lg} {
      font-size: 24px;
    }
  }

  h5,
  .h5 {
    font-size: 20px;

    @media ${device?.lg} {
      font-size: 24px;
    }
  }

  h6,
  .h6 {
    font-size: 20px;

    @media ${device?.lg} {
      font-size: 24px;
    }
  }
`;

export default heading;

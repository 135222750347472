import * as React from 'react';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { navigate } from 'gatsby-link';

// Particles
import { addSingleCache, windowAvailable } from 'corigan';
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';
import { buildSelectObjects } from 'corigan';
import { selectChange } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Field, Link, Select } from 'corigan';
import { Breadcrumbs, Card, Error, Info } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetDomains } from 'corigan';
import { callGetPermissions } from 'corigan';
import { callCreateTeam } from 'corigan';

const TeamsCreate = () => (
  <ProtectedRoute redirect={ROUTES.teams} requiredPermissions={[`teams:create`]}>
    <Page application="portal" pageTitle="Create Team">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.teams}>Teams</Link>
              <h1>Create Team</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <CreateTeamForm />
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

const successMessage = (team: string): string => {
  return `The team "${team}" has been successfully created in the system.`;
};

type FormErrors = {
  domains?: any;
  name?: ArgName;
  permissions?: any;
};

type FormValues = {
  domains: ObjectLiteral[];
  name: ArgURL;
  permissions: ObjectLiteral[];
};

type CreateTeamProps = {};

const CreateTeamForm: React.FC<CreateTeamProps> = (props: CreateTeamProps) => {
  const [success, setSuccess] = useState<string>(null);
  const queryCache = useQueryCache();

  const domains = useQuery([`callGetDomains`], callGetDomains);
  const permissions = useQuery([`callGetPermissions`], callGetPermissions);

  const { userHasPermission: canCreate } = useHasPermissions({ requiredPermissions: [`teams:create`] });

  const createTeam = async values => {
    if (windowAvailable() && !canCreate) window.alert(`You do not have permission to create teams.`);

    const { domains: unparsedDomains, name } = values;
    const domains = unparsedDomains.map(({ value }) => value);

    const fnArgs = {
      domains,
      name,
    };

    if (canCreate) return await callCreateTeam(fnArgs);
  };

  const [mutate, { error, isLoading: loading }] = useMutation(createTeam, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyTeams`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      // If updateTeam function was successful, get values from API response
      const teamData: Team = data?.data;
      const id = teamData?.id;
      const latestName = teamData?.name;

      // Parameters for single tean query
      const singleWith = [`domains`];

      // Parameters for inital all teans query
      const mastersheetPerPage = 10;
      const mastersheetWith: ArgWith = [`domains`];

      // Optimistically update to the new value for mastersheet page
      queryCache.setQueryData(
        [`callGetManyTeams`, { perPage: mastersheetPerPage, _with: mastersheetWith }],
        (previousTeams: APIResponse) => addSingleCache(previousTeams, teamData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetOneTeam`, { _with: singleWith, id }], (previousTeams: APIResponse) =>
        addSingleCache(previousTeams, teamData),
      );

      // Let the user know the team has been updated and update UI
      setSuccess(successMessage(latestName));

      // Redirect to the new team page
      navigate(ROUTES.team + `?id=${id}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      domains: undefined,
      name: ``,
      permissions: undefined,
    },
    onSubmit: async values => await mutate(values),
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { domains, name } = values;
      const hasDomains = domains?.length > 0;
      if (!hasDomains) errors.domains = `Please add at least one domain`;
      if (!name) errors.name = `Please specify a team name`;
      return errors;
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  const { errors, touched, values, handleChange, handleBlur, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const domainsOptions = useMemo(() => {
    const data = domains?.data?.data;
    const hasData: boolean = data?.length > 0;
    if (!hasData) return [];

    const selectData = data.map(({ hostname, id }) => ({ id, name: hostname }));
    const allDomains = buildSelectObjects(selectData);
    const hasDomainOptions: boolean = allDomains?.length > 0;
    if (!hasDomainOptions) return [];

    return allDomains;
  }, [domains.data]);

  const permissionsOptions = useMemo(() => {
    const data = permissions?.data?.data;
    const hasData: boolean = data?.length > 0;
    if (!hasData) return [];

    const selectData = data.map(({ name, id }) => ({ id, name }));
    const allPermissions = buildSelectObjects(selectData);
    const hasDomainOptions: boolean = allPermissions?.length > 0;
    if (!hasDomainOptions) return [];

    return allPermissions;
  }, [permissions.data]);

  const handleDomainChange = event => {
    selectChange({
      event,
      field: `domains`,
      setFieldValue,
    });
  };

  const handlePermissionsChange = event => {
    selectChange({
      event,
      field: `permissions`,
      setFieldValue,
    });
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const isBusy: boolean = loading || domains?.isLoading || permissions?.isLoading;
  const locked: boolean = !canCreate || isBusy;

  let buttonText: string = `Create Team`;
  if (isBusy) buttonText = `Creating Team`;

  return (
    <Card loading={isBusy}>
      <section className="team__create">
        <h2>Team Details</h2>
        <form onSubmit={handleSubmit} aria-label="Create a Team">
          <fieldset className="mb-0" aria-busy={isSubmitting} disabled={locked}>
            {success && (
              <Info onClose={onClose} y="lg">
                {success}
              </Info>
            )}
            {error && <Error error={error}>{error}</Error>}
            <Field {...formikHelpers} id="name" label="Name" required={true} />
            <label htmlFor="domains">Sites</label>
            {domains.error && <Error id="domainsSelectError" error={domains.error} />}
            <Select
              id="domains"
              name="domains"
              disabled={locked}
              onChange={handleDomainChange}
              options={domainsOptions}
              value={values.domains}
            />
            {touched[`domains`] && errors[`domains`] && <Error id="domainsError" error={errors[`domains`]} />}
            <label htmlFor="permissions">Permissions</label>
            {permissions.error && <Error id="permissionsSelectError" error={permissions.error} />}
            <Select
              id="permissions"
              name="permissions"
              disabled={locked}
              onChange={handlePermissionsChange}
              options={permissionsOptions}
              value={values.permissions}
            />
            {touched[`permissions`] && errors[`permissions`] && (
              <Error id="permissionsError" error={errors[`permissions`]} />
            )}
            <button type="submit" disabled={locked}>
              {buttonText}
            </button>
          </fieldset>
        </form>
      </section>
    </Card>
  );
};

export default TeamsCreate;

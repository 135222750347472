import React from 'react';
import { useContext } from 'react';

import { Add } from 'icons';
import { Delete } from 'icons';

import { Button } from 'corigan';
import { TableContext } from 'corigan';

import { conditionOptions } from '../data';

type ManageFilterProps = {
  id: string;
  formik: any;
  hideDelete: boolean;
  showLabels: boolean;
  lastFilter: boolean;
  addOrButton: boolean;
  addAndButton: boolean;
  handleAddFilter: Function;
  handleDeleteFilter: Function;
};

const ManageFilter: React.FC<ManageFilterProps> = (props: ManageFilterProps) => {
  const { id, formik, hideDelete, showLabels, lastFilter, addOrButton, addAndButton, handleAddFilter, handleDeleteFilter } = props;
  const context = useContext(TableContext);
  const state = context?.state;

  const { columns } = state;
  const hasColumns = columns?.length > 0;

  const { values, errors, touched } = formik;
  const { handleChange, handleBlur, setFieldValue } = formik;

  const fieldOptions = hasColumns && columns.filter(({ filter }) => filter);
  const hasFieldOptions = fieldOptions?.length > 0;

  const activeCondition = conditionOptions.find(condition => condition?.value === values[id]?.condition);
  const activeFilter = fieldOptions.find(filter => filter?.dbKey === values[id]?.field);
  const filterIsNumeric: boolean = activeFilter?.numeric;
  const filterIsDate: boolean = activeFilter?.date;

  // Array of condition options that support numeric values
  const numericConditions = React.useMemo(() => {
    if (!conditionOptions) return [];

    const filtered = conditionOptions.filter(f => f.numeric);
    return filtered;
  }, []);

  // Array of condition options that support string values
  const stringConditions = React.useMemo(() => {
    if (!conditionOptions) return [];

    const filtered = conditionOptions.filter(f => f.string);
    return filtered;
  }, []);

  // If the filter is numeric, only show options which support that, same for strings
  const conditions = filterIsNumeric ? numericConditions : stringConditions;

  // On change of available conditions, set the first available one to the form values
  React.useEffect(() => {
    if (!activeCondition) return;

    const skipUpdate = filterIsNumeric ? activeCondition.numeric : activeCondition.string;
    if (skipUpdate) return;
    setFieldValue(id + `.condition`, conditions?.[0]?.value);
  }, [id, activeCondition, conditions, filterIsNumeric, setFieldValue]);

  if (!hasFieldOptions) return null;

  const addFilter = (e: React.MouseEvent<HTMLButtonElement>, or: boolean): void => {
    e.preventDefault();
    handleAddFilter(or);
  }

  const deleteFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    handleDeleteFilter(id, false);
  }

  return (
    <div className="form--fields">
      <div>
        { showLabels && <label htmlFor="field">Field</label>}
        <select
          id={`field${id}`}
          name={`${id}.field`}
          value={values[id].field}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {fieldOptions.map(({ dbKey, label, value }) => (
            <option key={`${label}-${value}`} value={dbKey}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        { showLabels && <label htmlFor="field">Condition</label>}
        <select
          id={`condition${id}`}
          name={`${id}.condition`}
          value={values[id].condition}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {conditions.map(({ label, value }) => (
            <option key={`${label}-${value}`} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        { showLabels && <label htmlFor="field">Value</label>}
        <input
          type={filterIsDate ? `date` : `text`}
          id={`value${id}`}
          name={`${id}.value`}
          value={values[id].value}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-invalid={touched[id]?.value && !!errors[id]?.value}
        />
      </div>
      { !hideDelete &&
        <Button onClick={(e) => deleteFilter(e)} type="button">
          <Delete />
        </Button> }
      { lastFilter &&
        <React.Fragment>
          { addAndButton &&
            <Button onClick={(e) => { addFilter(e, false) }} type="button">
              <Add />&nbsp; AND
            </Button> }
          { addOrButton &&
            <Button onClick={(e) => { addFilter(e, true) }} type="button">
              <Add />&nbsp; OR
            </Button> }
        </React.Fragment>}
    </div>
  );
};

// Default prop values
ManageFilter.defaultProps = {};

export default ManageFilter;

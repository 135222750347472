import * as React from 'react';
import { useContext } from 'react';

// Particles
import { OMContext } from 'corigan';
import { objectIsEmpty } from 'corigan';

// Components
import { SEOPreview } from 'corigan';

const PageMeta = () => {
  const context = useContext(OMContext);
  const state = context?.state;
  const metaData = state?.metaData;

  if (!metaData || objectIsEmpty(metaData)) return null;

  return (
    <section className="page__meta">
      <h3>Page Meta Data</h3>
      <SEOPreview className="mt-2" {...metaData} />
    </section>
  );
};

export default PageMeta;

import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Formik } from 'formik';
import { navigate } from 'gatsby-link';

// Particles
import { ApplicationContext } from 'corigan';

// Icons
import { Google } from 'corigan';

// Atoms
import { Button } from 'corigan';
import { HR } from 'corigan';
import { Link } from 'corigan';

// Molecules
import { Error } from 'corigan';
import { Info } from 'corigan';

// Templates
import { Half } from 'corigan';

type FormErrors = {
  email?: string;
  password?: string;
};

type FormValues = {
  email: string;
  remember?: boolean;
  password: string;
};

const initialValues: FormValues = { email: ``, password: ``, remember: false };

const Login = () => {
  const context: ApplicationContextProps = useContext(ApplicationContext);
  const loginUser = context?.firebase?.loginUser;
  const loginUserGoogle = context?.firebase?.loginUserGoogle;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const isAuthorized = context?.state?.currentUser;
  if (isAuthorized) navigate(`/eula`);

  const handleGoogleClick = async () => {
    setSuccess(null);
    setError(null);
    try {
      await loginUserGoogle();
      setSuccess(`Logged in successfully`);
      navigate(`/eula`);
    } catch (error) {
      setError(error);
    }
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  return (
    <Half intro="Welcome back! Please login to your account." title="Corigan">
      {success && (
        <Info onClose={onClose} y="lg">
          {success}
        </Info>
      )}
      {error && <Error error={error}>{error}</Error>}
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          setSuccess(null);
          const { email, password } = values;

          try {
            await loginUser({ email, password });
            setSuccess(`Logged in successfully`);
            navigate(`/eula`);
          } catch (error) {
            setError(error);
          }
        }}
        validate={values => {
          setError(null);
          const errors: FormErrors = {};
          const { email, password } = values;
          if (!email) errors.email = `A email is required`;
          if (!password) errors.password = `A password is required`;
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {loginUserGoogle && (
              <React.Fragment>
                <Button disabled={isSubmitting} onClick={handleGoogleClick} type="button" variant="hollow">
                  <Google className="mr-2" /> <span>Sign in with Google</span>
                </Button>
                <HR background="#f0f0f7">OR</HR>
              </React.Fragment>
            )}
            <input
              type="email"
              placeholder="Email"
              id="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              aria-invalid={touched.email && !!errors.email}
              aria-describedby="errorEmail"
            />
            {touched.email && errors.email && <Error id="errorEmail" error={errors.email} />}
            <input
              type="password"
              placeholder="Password"
              id="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              aria-invalid={touched.password && !!errors.password}
              aria-describedby="errorPassword"
            />
            {touched.password && errors.password && <Error id="errorPassword" error={errors.password} />}
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? `Logging In` : `Login`}
            </Button>
            <footer className="form__footer">
              <Link to="/reset-password">Forgot password</Link>
            </footer>
          </form>
        )}
      </Formik>
    </Half>
  );
};

export default Login;

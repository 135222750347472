import * as React from 'react';

import { DnDContext, DnDDroppable, DnDDraggable } from 'atoms';
import StyledTagsOrder from './tags.styles';

type TagsProps = {
  tags: Tag[];
  setTags(item: any): void;
};

const TagDnDOrdering: React.FC<TagsProps> = (props: TagsProps) => {
  const { tags, setTags } = props;

  const drawTagItem = (tag, index) => {
    const { label } = tag;
    let category = `Subcategory ${index}`;

    if (index === 0) {
      category = `Main Category`;
    }

    return (
      <>
        <div className="tags__item__block__title">{category} - {label}</div>
      </>
    );
  };

  return (
    <StyledTagsOrder>
      <DnDContext items={tags} setItems={setTags}>
        <DnDDroppable droppableId="droppable">
          {!!tags.length &&
            tags.map((item, index) => (
              <DnDDraggable
                draggableId={`draggable-${index}`}
                key={`draggable-${index}`}
                index={index}
                className="tags__reorder__block__wrapper"
                dragHandle
              >
                {drawTagItem(item, index)}
              </DnDDraggable>
            ))}
        </DnDDroppable>
      </DnDContext>
    </StyledTagsOrder>
  );
};

export default TagDnDOrdering;

import type { ContentState } from 'draft-js';

declare type Args = {
  currentContent: ContentState;
  phrase: string;
  linked: boolean;
  url: string;
  keywordType: 'keyword' | 'linkedWord'
};

export const createEntity = (
  args: Args,
): {
  contentWithNewEntity: ContentState;
  newEntityKey: string;
} => {
  const { currentContent, phrase: keyword, linked, url, keywordType } = args;

  // Sets entity data
  const isLink: boolean = url && linked;
  const entityType: 'LINK' | 'UNLINKED' = isLink ? `LINK` : `UNLINKED`;
  const entityData = {
    keyword,
    linked: isLink,
    url,
    keywordType,
  };

  const contentWithNewEntity = currentContent.createEntity(entityType, `MUTABLE`, entityData);

  // Applies the entity data to the text selection
  const newEntityKey = contentWithNewEntity.getLastCreatedEntityKey();

  return { contentWithNewEntity, newEntityKey };
};

export default createEntity;

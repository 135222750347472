import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

// Particles
import { OMContext } from 'corigan';
import { dateCreateUpdate } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Error, Loader } from 'corigan';

// API
import { callGetOnePageRevisions } from 'corigan';

// Localised partials
import DeleteRevision from './DeleteRevision';
import LoadRevision from './LoadRevision';

import StyledRevisions from './revisions.styles';

import { statuses } from 'particles';

type PageRevisionsProps = {
  drafts?: boolean;
  isLocked?: boolean;
};

const perPage = 1000;
const _with = [`createdBy`];

const PageRevisions: React.FC<PageRevisionsProps> = (props: PageRevisionsProps) => {
  const { drafts, isLocked } = props;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const context = useContext(OMContext);
  const state = context?.state;
  const dispatch: any = context?.dispatch;
  const id = state?.id;

  const hasID: boolean = Boolean(id);
  const enabled = canRead && hasID;

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetOnePageRevisions`, { drafts, id, perPage, _with }],
    callGetOnePageRevisions,
    { enabled },
  );

  const revisions = res?.data;
  const hasRevisions = revisions?.length > 0;

  const canDelete: boolean = hasRevisions && revisions.some(revision => revision?.status?.toLowerCase() === statuses.draft.toLowerCase());

  // do the check push and dispatch for revisionStatusArray
  React.useEffect(() => {
    if (!drafts) {
      // check if any revisions have a awaiting approval status and set id
      const revisionStatusArray = [];
      if(hasRevisions){
        for(const revision of revisions) {
          revisionStatusArray.push({ status: revision?.status, id: revision?.id });
        }
      }
      // dispatch set revisionStatusArray to state
      dispatch({ type: `set`, key: `revisionStatusArray`, value: revisionStatusArray });
    }
  }, [dispatch, revisions, hasRevisions, drafts])

  if (loading) return <Loader />;
  if (error) return <Error error={error} />;
  if (!canRead) return <Error error="You do not have permission to access page revisions" />;

  return (
    <section className="page__revisions" data-locked={isLocked}>
      <StyledRevisions className="mt-0">
        <thead>
          <tr>
            <th className="table-load" />
            <th>Comment</th>
            <th className="table-date">Date</th>
            <th className="table-author">Author</th>
            <th className="table-status">Status</th>
            {canDelete && <th className="table-delete" /> }
          </tr>
        </thead>
        {hasRevisions && <Revisions revisions={revisions} />}
      </StyledRevisions>
    </section>
  );
};

declare type RevisionsProps = {
  revisions: any[];
};

const Revisions: React.FC<RevisionsProps> = (props: RevisionsProps) => {
  const { revisions } = props;

  const orderedRevisions = useMemo(() => {
    return revisions.sort((a, b) => b.status.length - a.status.length).reverse();
  }, [revisions]);

  const hasRevision: boolean = orderedRevisions?.length > 0;

  return (
    <tbody>
      {hasRevision &&
        orderedRevisions.map(revision => {
          const { id, page, comment, status } = revision;
          const { createdAt, updatedAt } = revision;
          const dateFormatted: string = dateCreateUpdate({ createdAt });

          const userName: string = revision?.createdBy?.name;

          const lowerStatus: string = status?.toLowerCase();

          const isDraft: boolean = lowerStatus === statuses.draft.toLowerCase();
          const canDelete: boolean = isDraft;

          let classList: string = `revision revision--${lowerStatus}`;

          switch (lowerStatus) {
            case statuses.archived.toLowerCase():
              break;
            case statuses.awaitingApproval.toLowerCase():
              classList += ` background--red000`;
              break;
            case statuses.draft.toLowerCase():
              break;
            case statuses.live.toLowerCase():
              classList += ` background--green000`;
              break;
            case statuses.implemented.toLowerCase():
              classList += ` background--blue000`;
              break;
          }

          return (
            <tr className={classList} key={id}>
              <td className="text--nowrap" data-comment={comment}>
                <LoadRevision id={id} status={status} />
              </td>
              <td className="table-overflow">{comment}</td>
              <td className="text--nowrap">{dateFormatted}</td>
              <td className="text--nowrap table-overflow">{userName}</td>
              <td className="text--nowrap">{status}</td>
              {canDelete && <td className="text--nowrap"><DeleteRevision id={id} page={page} /></td>}
            </tr>
          );
        })}
    </tbody>
  );
};

export default PageRevisions;

import * as React from 'react';

function SvgPromoted(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 24' fill="currentColor" {...props}>
      <path d="m6.8125 8.3333c0-2.5313 2.0986-4.5833 4.6875-4.5833s4.6875 2.052 4.6875 4.5833c0 2.5314-2.0986 4.5834-4.6875 4.5834s-4.6875-2.052-4.6875-4.5834zm-2.8125 0c0 2.6107 1.395 4.9025 3.4961 6.2019l-1.9961 7.4172 2.3187-1.3028 1.1099 2.3504 1.98-7.3553c0.1952 0.0149 0.3923 0.022 0.5914 0.022s0.3962-0.0071 0.5914-0.022l1.98 7.3553 1.1099-2.3504 2.3187 1.3028-1.9961-7.4172c2.101-1.2996 3.4961-3.5913 3.4961-6.2019 0-4.0501-3.3578-7.3333-7.5-7.3333s-7.5 3.2832-7.5 7.3333z" />
    </svg>
  );
}

export default SvgPromoted;

import * as React from 'react';

function SvgMenu(props) {
  return (
    <svg viewBox="0 0 20.9 16" {...props}>
      <path d="M.01 1h20.9M.01 8h20.9M.01 15h20.9" stroke="currentColor" fill="none" strokeWidth={2} />
    </svg>
  );
}

export default SvgMenu;

import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Error } from 'corigan';
import { Page } from 'corigan';

// Local components
import Complete from './partials/complete';
import RequestError from './partials/error';
import Loading from './partials/loading';
import Missing from './partials/missing';

// API
import { callGetOneTeam } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`teams:read`]}>
    <TeamContents />
  </ProtectedRoute>
);

type TeamProps = {};

const TeamContents: React.FC<TeamProps> = (props: TeamProps) => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  return (
    <Page application="portal" pageTitle="Team">
      {id && <TeamInfo id={id} />}
      {!id && <Missing />}
    </Page>
  );
};

type TeamInfoProps = {
  id: string;
};

const _with: ArgWith = [`domains`];

const TeamInfo: React.FC<TeamInfoProps> = (props: TeamInfoProps) => {
  const { id } = props;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`teams:read`] });

  const { data: res, error, isLoading: loading } = useQuery([`callGetOneTeam`, { id, _with }], callGetOneTeam, {
    enabled: canRead,
  });
  const data = res?.data;

  if (!canRead) return <Error error="You do not have permission to read teams information" />;
  if (loading) return <Loading />;
  if (error) return <RequestError error={error} id={id} />;
  if (data) return <Complete data={data} />;
  return null;
};

export default Wrapper;

import styled from 'styled-components';
import { device } from 'particles';

const StyledIssues = styled.section`
  padding-left: 41px;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 24px;
    left: 10px;
    width: 1px;
    height: 100%;

    background-color: var(--grey400);
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
  }

  p {
    max-width: 45ch;

    font-size: 16px;
  }

  .issues__group + .issues__group {
    margin-top: 64px;
  }

  .issues__group__meta {
    margin-top: 8px;

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-transform: uppercase;

    span + span {
      margin-left: 4px;
    }
  }

  .issues__items {
    margin-top: 32px;

    @media ${device?.xl} {
      margin-top: 24px;
    }
  }

  .issues__item {
    grid-column: 6 span;
  }

  .issues__item + .issues__item {
    margin-top: 32px;
  }
`;

const groupBackground = props => {
  if (!props.status) return `var(--grey700)`;
  const lower = props.status.toLowerCase();
  switch (lower) {
    case `online`:
      return `#78E7B0`;
    case `offline`:
      return `#F02D70`;
    case `maintenance`:
      return `var(--teal)`;
    default:
      return `var(--grey700)`;
  }
};

const groupBorder = props => {
  if (!props.status) return `var(--grey300)`;
  const lower = props.status.toLowerCase();
  switch (lower) {
    case `online`:
      return `#78E7B0`;
    case `offline`:
      return `#F02D70`;
    case `maintenance`:
      return `var(--teal300)`;
    default:
      return `var(--grey300)`;
  }
};

export const StyledIssuesGroup = styled.div`
  position: relative;

  &::before,
  &::after {
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: -40px;
    width: 20px;
    height: 20px;
  }

  &::before {
    background-color: ${props => groupBackground(props)};
    border: 2px solid var(--white);
  }

  &::after {
    top: 6px;
    left: -42px;
    width: 24px;
    height: 24px;

    border: 2px solid ${props => groupBorder(props)};

    animation: 15s ease 0s infinite forwards pulseFade;
  }

  @keyframes pulseFade {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    85% {
      opacity: 1;
      transform: scale(1);
    }
    90% {
      opacity: 0;
      transform: scale(2);
    }
    94.9% {
      opacity: 0;
      transform: scale(0);
    }
    95% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export default StyledIssues;

import * as React from 'react';

import StyledGrid from './grid.styles';

type GridProps = {
  /**
   * The primary content of the Grid component, expects a 'Row' component
   */
  children: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
};

export const Grid: React.FC<GridProps> = (props: GridProps) => (
  <StyledGrid {...props} className={props.className ? `grid ${props.className}` : `grid`}>
    {props.children}
  </StyledGrid>
);

// Default prop values
Grid.defaultProps = {};

export default Grid;

import * as React from 'react';

function SvgCompareArrows(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M8.4 14.4H0v2.4h8.4v3.6l4.8-4.8-4.8-4.8v3.6zm7.2-1.2V9.6H24V7.2h-8.4V3.6l-4.8 4.8 4.8 4.8z"
      />
    </svg>
  );
}

export default SvgCompareArrows;

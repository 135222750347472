import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { navigate } from 'gatsby';

// Particles
import { ApplicationContext } from 'particles';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useIsMount } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Error } from 'corigan';
import { Page } from 'corigan';

// Localised partials
import Complete from './partials/complete';
import RequestError from './partials/error';
import Loading from './partials/loading';
import Missing from './partials/missing';

// API
import { callGetKeyword } from 'corigan';

const PageWrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`keywords:read`]}>
    <KeywordContents />
  </ProtectedRoute>
);

type KeywordProps = {};

const KeywordContents: React.FC<KeywordProps> = (props: KeywordProps) => {
  const queryParameters = useQueryParameters();
  const { id, url } = queryParameters;

  const isMount = useIsMount();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  useEffect(() => {
    if (isMount) return;
    navigate(ROUTES.keyword_manager);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainActive]);

  return (
    <Page application="keyword-manager" pageTitle="Keyword">
      {id && <KeywordInfo id={id} url={url} />}
      {!id && <Missing />}
    </Page>
  );
};

type KeywordInfoProps = {
  id?: ArgID;
  url?: any;
};

const _with: ArgWith = [
  `tags`,
  `updatedBy`,
];

const KeywordInfo: React.FC<KeywordInfoProps> = (props: KeywordInfoProps) => {
  const { id, url } = props;

  const { userHasPermission: canReadKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:read`],
  });

  const { data: res, error, isLoading: loading } = useQuery([`callGetKeyword`, { id, _with }], callGetKeyword, {
    enabled: canReadKeyword,
  });
  const data = res?.data;

  if (!canReadKeyword) return <Error error="You don't have permission to read keywords" />;
  if (loading) return <Loading />;
  if (data) return <Complete data={data} url={url} />;
  if (error) return <RequestError error={error} id={id} />;
};

export default PageWrapper;

import { EditorState } from 'draft-js';

type PhraseLink = {
  phrase: string;
  type: string;
};

export const getLinkedPhrases = (editorState: EditorState): PhraseLink[] => {
  // Gets an array of the editor content blocks, usually defined as each new line (not text wrapped)
  const currentContent = editorState.getCurrentContent();
  const contentBlockMap = currentContent.getBlockMap();

  const phraseList: PhraseLink[] = [];

  // Loops through each content block
  contentBlockMap.forEach(contentBlock => {
    // Check character in the content block for a keyword link
    contentBlock.findEntityRanges(
      character => {
        const entityKey: string = character.getEntity();
        const hasEntity: boolean = entityKey !== null;

        return hasEntity;
      },
      // If a keyword entity exists, add it to the phrase list
      (entityKeywordStartPosition, entityKeywordEndPosition) => {
        // Get the keyword assigned to the entity
        const entityKey: string = contentBlock.getEntityAt(entityKeywordStartPosition);
        const { keyword: phrase } = currentContent.getEntity(entityKey).getData();
        const type: string = currentContent.getEntity(entityKey).getType();

        phraseList.push({ phrase, type });
      },
    );
  });

  return phraseList;
};

export type { PhraseLink };
export default getLinkedPhrases;

import { Map as map } from 'immutable';

/**
 * Block renderer for the editor
 * Sets 'unstyled' elements as <section>
 */
export const blockRenderMap = map({
  unstyled: {
    element: `section`,
  },
});

/**
 * Set the classes for the editor blocks
 * 'Unstyled' blocks are treated as paragraphs,
 * but rendered as <section>
 *
 * @param {Immutable\Record} contentBlock Immutable Record @see https://draftjs.org/docs/api-reference-content-block
 */
export const blockStyleFn = contentBlock => {
  const type = contentBlock.getType();

  const isUnstyled = type === `unstyled`;
  if (isUnstyled) return `editor__paragraph editor__font-size`;

  return ``;
};

import * as React from 'react';

import StyledLoader from './loader.styles';

import { StyledDefault } from './loader.styles';
import { StyledProgress } from './loader.styles';
import { StyledBar } from './loader.styles';
import { StyledBarProgress } from './loader.styles';

type LoaderProps = {
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  fixed?: boolean;
  /**
   * When set, converts the loader to a determinate type, which
   * updates in relation to the value found in the percentage prop.
   * Valid values range from 0 - 100.
   */
  percentage?: percentage;
  /**
   * Used to set the type of loader to use, defaults to 'circle' style
   */
  type?: 'bar' | 'default';
};

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { className, fixed, percentage, type } = props;
  const isDefault = type === `default`;
  const isBar = type === `bar`;

  let classList = `loader`;
  if (className) classList += ` ${className}`;

  return (
    <StyledLoader className={classList}>
      {isDefault && !percentage && <Default fixed={fixed} />}
      {isDefault && percentage && <DefaultProgress {...props} />}
      {isBar && !percentage && <Bar fixed={fixed} />}
      {isBar && percentage && <BarProgress {...props} />}
    </StyledLoader>
  );
};

Loader.defaultProps = {
  fixed: true,
  type: `default`,
};

type DefaultProps = {
  fixed?: boolean;
};

const Default = ({ fixed }: DefaultProps) => (
  <StyledDefault className="loader__container" fixed={fixed}>
    <svg className="loader__vector" viewBox="25 25 50 50">
      <circle className="path" cx="50" cy="50" fill="none" r="20" strokeMiterlimit="10" />
    </svg>
    <span className="hidden">Loading...</span>
  </StyledDefault>
);

type DefaultProgressProps = {
  fixed?: boolean;
  percentage?: percentage;
};

const DefaultProgress: React.FC<DefaultProgressProps> = (props: DefaultProgressProps) => {
  const { percentage } = props;

  return (
    <StyledProgress {...props} className="loader__container">
      <svg className="loader__vector" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" fill="none" r="20" strokeMiterlimit="10" />
      </svg>
      <span className="hidden">Loaded {percentage}%</span>
    </StyledProgress>
  );
};

DefaultProgress.defaultProps = {};

declare type BarProps = {
  fixed: boolean;
};

const Bar: React.FC<BarProps> = ({ fixed }: BarProps) => (
  <StyledBar fixed={fixed}>
    <ProgressAmount />
  </StyledBar>
);

type BarProgressProps = {
  fixed?: boolean;
  percentage?: percentage;
};

const BarProgress: React.FC<BarProgressProps> = (props: BarProgressProps) => {
  const { fixed, percentage } = props;

  return (
    <StyledBarProgress percentage={percentage} fixed={fixed}>
      <ProgressAmount percentage={percentage} />
    </StyledBarProgress>
  );
};

BarProgress.defaultProps = {};

type ProgressAmountProps = {
  percentage?: number;
};

const ProgressAmount: React.FC<ProgressAmountProps> = ({ percentage }: ProgressAmountProps) => (
  <div className="loader__amount">{percentage && <span className="hidden">Loaded {percentage}%</span>}</div>
);

ProgressAmount.defaultProps = {};

export default Loader;

import * as React from 'react';
import { parseHTML } from 'particles';

type HeadingProps = {
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * The innercontent of the Heading element
   */
  children?: React.ReactNode;
  /**
   * The innerHTML of the Heading element, used in place of 'children' when using a
   * CMS editor with a field for innerHTML
   */
  innerHTML?: string;
  /**
   * The HTML heading element rendered, default (h2)
   */
  level?: HeadingLevels;
  /**
   * The heading className applied to style the base HTML element to match a different level
   * styles. e.g. You could have a Heading level 2, styled with variant 6, to style the h2 as a h6
   */
  variant?: HeadingLevels;
};

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Heading = (props: HeadingProps) => {
  const { className, children, innerHTML, level, variant } = props;
  const HeadingTag = `h${level}` as HeadingTag;

  let classList = `heading`;
  if (className) classList += ` ${className}`;
  if (variant) classList += ` h${variant}`;

  if (innerHTML) return parseHTML(innerHTML);

  return <HeadingTag className={classList}>{children}</HeadingTag>;
};

// Default prop values
Heading.defaultProps = {
  level: 2,
};

export default Heading;

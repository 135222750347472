import styled from 'styled-components';

const StyledFooter = styled.footer`
  padding: 24px;

  a {
    color: inherit;
    text-decoration: none;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      text-decoration: underline;
    }
  }

  nav {
    a {
      transition: 0.2s color ease, 0.2s opacity ease;
    }
  }

  .footer__contents {
    text-align: center;

    > nav:last-of-type {
      margin-top: 24px;

      > * {
        opacity: 0.5;
      }

      > * + * {
        margin-left: 12px;
      }

      > a {
        &:active,
        &:focus,
        &:focus-within,
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

export default StyledFooter;

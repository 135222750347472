import { EditorState, ContentState, convertFromRaw, RawDraftContentState, RawDraftContentBlock, SelectionState } from 'draft-js';
import { addPhraseLinking, defaultLinkingCharacters, createCombinedWordList } from './index';
import { SavedContent } from '../EditorDraftTypes';
import keywordDecorator from '../partials/keyword-decorator';

import { objectIsEmpty } from 'corigan';

type createEditorStateArgs = {
  allowedLinkingCharacters?: any[];
  contentDecoded?: RawDraftContentState;
  contentEncoded?: RawDraftContentState;
  keywords?: any[];
  linkedWords?: any[];
  savedContent?: SavedContent;
  firstLoad?: boolean;
};

export const createEditorState = (args: createEditorStateArgs): EditorState => {
  const { allowedLinkingCharacters = defaultLinkingCharacters } = args;
  const { keywords = [], linkedWords = [], contentDecoded, contentEncoded, savedContent, firstLoad } = args;

  const typedCombinedWordList = createCombinedWordList({keywords, linkedWords});

  let newEditorState: EditorState = EditorState.createEmpty(keywordDecorator);
  const blankSelection = new SelectionState();

  const hasDecodedField: boolean = !objectIsEmpty(contentDecoded);
  const shouldConvertDirectly: boolean = hasDecodedField && contentDecoded?.blocks?.length > 0;
  const hasEncodedField: boolean = !objectIsEmpty(contentEncoded);
  const shouldDecode: boolean = !shouldConvertDirectly && hasEncodedField && contentEncoded?.blocks?.length > 0;
  const shouldParse: boolean = !shouldConvertDirectly && !shouldDecode && !objectIsEmpty(savedContent);

  // Run this if the content has already been decoded for us to create content with
  if (shouldConvertDirectly) {
    try {
      const convertedRaw: ContentState = convertFromRaw(contentDecoded);
      newEditorState = EditorState.createWithContent(convertedRaw, keywordDecorator);
      newEditorState = addPhraseLinking(newEditorState, blankSelection, typedCombinedWordList, allowedLinkingCharacters, !firstLoad);
      newEditorState = EditorState.moveSelectionToEnd(newEditorState);
    } catch (error) {
      console.error(error);
    }
  }

  // Run if we are decoding a detailed object, parsing the draft-js encoding
  if (shouldDecode) {
    const entityMapAPI: ObjectLiteral = contentEncoded?.entityMap;

    // Decode the formatted (encoded data from the API)
    const hasEntityMapAPI: boolean = entityMapAPI?.length > 0;
    const entityMap = { ...entityMapAPI };

    if (hasEntityMapAPI) {
      for (let i = 0; i < entityMap.length; i++) {
        const value = entityMap[i];

        const position = value[`key`];
        if (position) delete value[`key`];

        entityMap[position] = value;
      }
    }

    const contentDecoded: RawDraftContentState = { ...contentEncoded, entityMap };

    try {
      const convertedRaw: ContentState = convertFromRaw(contentDecoded);
      newEditorState = EditorState.createWithContent(convertedRaw, keywordDecorator);
      newEditorState = addPhraseLinking(newEditorState, blankSelection, typedCombinedWordList, allowedLinkingCharacters, !firstLoad);
      newEditorState = EditorState.moveSelectionToEnd(newEditorState);
    } catch (error) {
      console.error(error);
    }
  }

  // Handle content as a basic string
  if (shouldParse) {
    const { key, text } = savedContent;

    try {
      const textBlocks = text.split(`\n`);
      const blocks = textBlocks.map(
        (text, index): RawDraftContentBlock => ({
          data: {},
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          key: `${key}-${index}`,
          text,
          type: `unstyled`,
        }),
      );

      const newContentState: RawDraftContentState = {
        blocks,
        entityMap: {},
      };

      const convertedRaw: ContentState = convertFromRaw(newContentState);
      newEditorState = EditorState.createWithContent(convertedRaw, keywordDecorator);
      newEditorState = addPhraseLinking(newEditorState, blankSelection, typedCombinedWordList, allowedLinkingCharacters, !firstLoad);
      newEditorState = EditorState.moveSelectionToEnd(newEditorState);
    } catch (error) {
      console.error(error);
    }
  }

  return newEditorState;
};

export default createEditorState;

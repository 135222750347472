import 'react-dates/initialize';
import * as React from 'react';
import { useState, useMemo } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { useFormik } from 'formik';

// Atoms
import { Error } from 'corigan';

import './form.css';

declare type selectType = {
  label: string;
  value: string;
}

declare type RankingsTableFilterProps = {
  loading: boolean;
  setWhere: Function;
  where: {
    searchText: string;
    searchType: string;
    fromDate: string;
    toDate: string;
  };
  types: selectType[];
  enabled: boolean;
};

type FormErrors = {
  searchText?: string;
  searchType?: string;
  fromDate?: string;
  toDate?: string;
};

type FormValues = {
  searchText: string;
  searchType: string;
  fromDate: string;
  toDate: string;
};

const momentToday = moment();
const momentThreeYearsAgo = moment().subtract(3, `years`);

const endDateId = `fromDateRange`;
const startDateId = `toDateRange`;

/**
 * build and set where string from form
 * @param {object} newWhere the built whre object from form values
 * @returns where object
 */
const whereBuilder = (newWhere) => {
  let tempWhere = ``;
  // eslint-disable-next-line guard-for-in
  for (const key in newWhere) {
    if (newWhere[key]) {
      const value = (typeof newWhere[key] === `string`) ? newWhere[key] : newWhere[key].value;
      tempWhere = `${tempWhere}&${key}=${value}`
    }
  }
  return tempWhere;
}

// define PICKABLE date range
const isOutsideRange = date => {
  const tooNew = date.isAfter(momentToday);
  const tooOld = date.isBefore(momentThreeYearsAgo);
  const justRight = !tooNew && !tooOld;
  return !justRight;
};

const RankingsTableFilter = (props: RankingsTableFilterProps) => {
  const { setWhere, types, loading, where, enabled } = props;
  const [focus, setFocus] = useState(null);
  const [tableDateStart, setTableDateStart] = useState(null);
  const [tableDateEnd, setTableDateEnd] = useState(null);

  const initialValues: FormValues = {
    searchText: where.searchText,
    searchType: where.searchType,
    fromDate: where.fromDate,
    toDate: where.toDate,
  };

  // define formik form functions
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const whereString = whereBuilder(values);
      setWhere(whereString);
    },
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { searchType, fromDate, toDate } = values;
      if (!searchType) errors.searchType = `Please select a type`;
      if (!fromDate) errors.fromDate = `Please select a start date`;
      if (!toDate) errors.toDate = `Please select a end date`;
      const startDate = moment(fromDate);
      const endDate = moment(toDate);
      if (startDate.isSameOrAfter(endDate)) errors.toDate = `Start date must be before end date`;
      const endLimit = endDate.subtract(1, `M`);
      const overAMonth = startDate.isBefore(endLimit);
      if (overAMonth) {
        errors.fromDate = `Max one month range`;
        errors.toDate = `Max one month range`;
      }
      return errors;
    },
  });

  // define formik variable and functions
  const { values, handleSubmit, handleChange, handleBlur, isSubmitting, setFieldValue, errors } = formik;

  const { searchText, searchType, fromDate, toDate } = values;

  // Set previous filters for date picker
  useMemo(() => {
    if(fromDate){
      setTableDateStart(moment(fromDate));
    }
    if(toDate){
      setTableDateEnd(moment(toDate));
    }
  }, [fromDate, toDate]);

  // handle date picker change
  const onChange = ({ startDate, endDate }) => {
    if (startDate) {
      setFieldValue(`fromDate`, moment(startDate?.clone()).format(`YYYY-MM-DD`));
      setTableDateStart(startDate?.clone());
    };
    if (endDate) {
      setFieldValue(`toDate`, moment(endDate?.clone()).format(`YYYY-MM-DD`));
      setTableDateEnd(endDate?.clone());
    };
  };

  // define and check if page is busy for disabling
  const isBusy: boolean = isSubmitting || loading;
  return (
    <div>
      <form onSubmit={handleSubmit} aria-label="table filter" className="form--trimmed">
        <div className="form--horizontal">
          <div className="form--fields">
            <div>
              <label htmlFor="searchText">Value</label>
              <input
                className="form__margin"
                type="text"
                id="searchText"
                name="searchText"
                value={searchText}
                onBlur={handleBlur}
                onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="searchType">Type</label>
              <select
                className="form__margin"
                id="searchType"
                name="searchType"
                value={searchType}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {types.map(({ label, value }) => (
                  <option key={`${label}-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="searchDate">Date range (max 1 month)</label>
              <div className="form__margin">
                <DateRangePicker
                  disabled={isBusy}
                  displayFormat="DD-MM-YYYY"
                  endDate={tableDateEnd}
                  endDateId={endDateId}
                  firstDayOfWeek={1}
                  minimumNights={1}
                  focusedInput={focus}
                  isOutsideRange={isOutsideRange}
                  onDatesChange={dates => onChange(dates)}
                  onFocusChange={focusedInput => setFocus(focusedInput)}
                  startDate={tableDateStart}
                  startDateId={startDateId}
                />
              </div>
            </div>
            <div>
              <button
                className='form__margin'
                disabled={isBusy}
                type="submit"
              >Search</button>
            </div>
          </div>
        </div>
      </form>
      {enabled && Boolean(Object.keys(errors).length) && <Error className='mb-0' error={Object.values(errors)[0]} />}
    </div>
  );
};

export default RankingsTableFilter;

import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';

// Particles
import { callGetManyKeywordsUses } from 'corigan';
import { datePretty } from 'corigan';
import { exportTableToCSV } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Button } from 'corigan';

// Local Components
import { TableContext } from 'corigan';

const columns: {
  label: string;
  value: string;
}[] = [
  { value: `keyword`, label: `Keyword` },
  { value: `title`, label: `Page Title` },
  { value: `id`, label: `Page ID` },
  { value: `url`, label: `Page URL` },
];

const SelectedUses = () => {
  const [exporting, setExporting] = React.useState<boolean>(false);
  const context = useContext(TableContext);
  const state = context?.state;
  const selected = state?.selected;
  const hasSelected: boolean = selected?.length > 0;

  const { userHasPermission: canReadKeywords } = useHasPermissions({
    requiredPermissions: [`keywords:read`],
  });

  const keywordsArray: Keyword[] = selected.map(k => k.id);
  const keywords: string = keywordsArray.join(`,`);
  const enabled = canReadKeywords && keywords?.length > 0;

  const { data: res, isLoading: loading } = useQuery(
    [`callGetManyKeywordsUses`, { keywords }],
    callGetManyKeywordsUses,
    { enabled },
  );
  const keywordUses = res;
  const hasKeywordUses: boolean = keywordUses?.length > 0;

  const uses = !hasKeywordUses
    ? []
    : keywordUses.filter(use => {
        const { keyword } = use;

        const hasKeyword: boolean = Boolean(keyword);
        if (!hasKeyword) return false;

        return true;
      });

  // Don't render the button if no content can be exported by user
  const hasUses: boolean = uses?.length > 0;
  if (!canReadKeywords) return null;
  if (!hasSelected) return null;

  const handleClick = async e => {
    if (e) e.preventDefault();
    if (!hasUses) return;

    const datestamp: string = datePretty(new Date());
    const csvTitle: string = `keyword-uses-${datestamp}`;

    try {
      const items = uses.map(use => {
        const { keyword, pageRevisions } = use;
        const { phrase } = keyword;

        // If we don't have revisions, just return the keyword with no data
        const hasRevisions: boolean = pageRevisions?.length > 0;
        if (!hasRevisions) return [{ keyword: phrase, title: ``, id: ``, url: `` }];

        const items = pageRevisions.map((revision: PageRevision) => {
          const { pageId, name, url } = revision;
          const keyword = phrase;

          return { keyword, title: name, id: pageId, url };
        });

        return items;
      });

      const hasItems: boolean = items?.length > 0;
      if (!hasItems) return;

      // Flatten the array of arrays to a single array of values
      const flatItems = Array.prototype.concat.apply([], items);
      const flatSuccess: boolean = flatItems?.length > 0;
      if (!flatSuccess) return;

      // Only get valid items (no null values)
      const validItems = flatItems.filter(Boolean);
      const hasValidItems: boolean = validItems?.length > 0;
      if (!hasValidItems) return;

      await exportTableToCSV(csvTitle, columns, validItems);
    } catch (error) {
      console.error(error);
    } finally {
      setExporting(false);
    }
  };

  const isBusy: boolean = loading || exporting;
  const isDisabled: boolean = !hasUses || isBusy;

  let buttonText: string = `Export Uses`;
  if (exporting) buttonText = `Exporting`;
  if (loading) buttonText = `Determining`;

  return (
    <React.Fragment>
      <Button className="table__uses" disabled={isDisabled} onClick={handleClick} type="button" variant="hollow">
        {buttonText}
      </Button>
    </React.Fragment>
  );
};

export default SelectedUses;

export const defaultState: OMContextProps['state'] = {
  client: null,
  activeRevision: null,
  currentRevision: null,
  editedRevision: null,
  editorLoadedContent: false,
  loadedRevision: null,
  implementedRevision: null,
  metaData: {
    description: null,
    title: null,
    updatedAt: null,
  },
  id: null,
  startedEdit: false,
  crawlResponse: null,
  status: null,
  createdAt: null,
  schemaVersion: null,
  locked: false,
  currentStatus: null,
};

export default defaultState;

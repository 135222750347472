import * as React from 'react';

import StyledToggle from './toggle.styles';
import { Promoted } from 'icons';

type ToggleProps = {
  /**
   * The element in which to position the Toggle relative to
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  colour?: string;
  id: string;
  label?: React.ReactNode;
  on: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  small?: boolean;
  value?: string;
};

export interface CSSProperties extends React.CSSProperties {
  colour?: string;
}

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
  const { children, className, colour, id, label, on, onChange, small, value } = props;

  let classList = `toggle`;
  if (small) classList += ` toggle--small`;
  if (on) classList += ` toggle--on`;
  if (className) classList += ` ${className}`;

  let style = {};
  if (colour) style = { '--primary': `${colour}` } as CSSProperties;

  return (
    <StyledToggle className={classList} small={small} style={style}>
      <input type="checkbox" id={id} name={id} checked={on} onChange={onChange} value={value} />
      <label htmlFor={id} className="toggle__label">
        <div className="toggle__switch">
          <div className="toggle__dot" />
        </div>
        {children && <span className="hidden">{children}</span>}
        {label && <span className="toggle__descriptor">{label}</span>}
      </label>
    </StyledToggle>
  );
};

Toggle.defaultProps = {
  on: false,
};

export default Toggle;

import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';

// Particles
import { OMContext } from 'corigan';

// Atoms
import { Chip } from 'corigan';

type Props = {};

const DetectedCategories: React.FC<Props> = (props: Props) => {
  const context = useContext(OMContext);
  const state = context?.state;

  const editedRevision: PageRevision = state.editedRevision;
  const detectedCategories = editedRevision?.categories;

  return useMemo(() => {
    const hasDetectedCategories: boolean = detectedCategories?.length > 0;
    if (!hasDetectedCategories) return null;

    const filteredCategories = detectedCategories.filter(category => !!category?.category);

    const detected = filteredCategories.map(details => {
      const category: CategoryObject = details?.category;
      const { id, name } = category ?? {};

      const key = `category-detected-${id}`;

      return <Chip key={key}>{name}</Chip>;
    });

    return (
      <React.Fragment>
        <h3 className="mt-1 mb-2">Detected Categories</h3>
        <section className="chip-spacer mb-3">{detected}</section>
      </React.Fragment>
    );
  }, [detectedCategories]);
};

export default DetectedCategories;

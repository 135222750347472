import * as React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ROUTES } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Organisms
import { Diff } from 'corigan';

// API
import { callGetCrawlPageDiffs } from 'corigan';

const CrawlPageDiff = () => (
  <ProtectedRoute>
    <Page application="competitor-research" pageTitle="Crawl Differences">
      <PageContents />
    </Page>
  </ProtectedRoute>
);

const PageContents = () => {
  const queryParameters = useQueryParameters();
  const { crawlId, category, url } = queryParameters;

  const { data, error, isLoading: loading } = useQuery(
    [`callGetCrawlPageDiffs`, { crawlId, url }],
    callGetCrawlPageDiffs,
  );

  useEffect(() => {
    document.title = `Corigan - Competitor Research: Crawl Differences - ${url}`;
  }, [url]);


  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={`${ROUTES.crawl}?id=${crawlId}`}>Crawl Category List</Link>
            <span className="breadcrumb__heading">
              <div className="display--flex align-items--center">
                <h1 className="mr-2">Crawl Page Difference</h1>
              </div>
            </span>
            <p>{url}</p>
          </Breadcrumbs>
          {error && <Error error={error} />}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card loading={loading}>
            <Diff diff={data} loading={loading} />
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default CrawlPageDiff;

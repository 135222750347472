import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';

// Particles
import { dateShort } from 'helpers';
import { RBContext } from 'corigan';
import { replaceAll } from 'helpers';

// Components
import { Skeleton } from 'corigan';
import { Statistic } from 'corigan';
import { Error } from 'corigan';

declare type ReportStatisticsProps = {
  data: any;
  error: any;
  loading: boolean;
};

const generateStats = ({ changeLabel, data }) => {
  let allStats = [];

  const hasData: boolean = data?.length > 0;
  if (!hasData) return allStats;

  allStats = data.map(val => {
    const figure = Math.ceil(Number(val?.value));

    const result = {
      change: val?.changePercentage,
      changeLabel,
      figure,
      label: val?.name,
    };
    return result;
  });

  return allStats;
};

const ReportStatistics = (props: ReportStatisticsProps) => {
  // Inherited from the useQuery function in parent component
  const data = props?.data;
  const error = props?.error;
  const loading = props?.loading;

  const context = useContext(RBContext);
  const state = context?.state;
  const { apiDate, devices, monthOnMonth } = state;

  const enabledDesktop = devices.includes(`desktop`);
  const enabledMobile = devices.includes(`mobile`);

  const prettyDate = useMemo(() => {
    return dateShort(apiDate?.clone()?.toISOString());
  }, [apiDate]);

  // Destructured from the data response if available
  const competitors = data?.competitors;
  const primary = data?.primary;
  const hasPrimary = primary?.length > 0;

  const isPrimary = (domainData, expected): boolean => {
    const isPrimary = domainData?.domainType === `primary`;
    const response = expected === isPrimary;
    return response;
  };

  const primaryCompetitors: any[] = competitors?.filter(d => isPrimary(d, true));
  const hasPrimaryCompetitors: boolean = primaryCompetitors?.length > 0;
  const otherCompetitors: any[] = competitors?.filter(d => isPrimary(d, false));

  // Will sort domains by 'sortOrder' key value
  const sortDomains = domains => {
    const hasDomains = domains?.length > 0;
    if (!hasDomains) return [];

    // Ensure all objects have a sortOrder property, if they don't...
    // Set the sortOrder at 1000, so it's ordered in the last items
    const fallbackDomains = domains.map(domainData => {
      if (domainData.hasOwnProperty(`sortOrder`)) return domainData;
      return { ...domainData, sortOrder: 1000 };
    });

    // Specific ordering, from 'sortOrder' key
    const sorted = fallbackDomains.sort((a, b) => Number(a?.sortOrder) - Number(b?.sortOrder));
    return sorted;
  };

  // Sorts the other competitors by 'sortOrder'.
  const otherSorted = sortDomains(otherCompetitors);

  // Initialize an array 'allMain' which contains all primary domains from 'main' and 'competitors'.
  let allMain: any[] = [];

  // If we have primary values, spread them into our empty array
  if (hasPrimary) allMain = [...primary];

  // Determines whether or not the 'allMain' array has items to spread later
  const hasMain: boolean = allMain?.length > 0;

  // Combine primary domain and competitor data
  if (hasPrimaryCompetitors) {
    // If we have main existing, then spread in front
    if (hasMain) allMain = [...allMain, ...primaryCompetitors];

    // If not, spread only the competitors that are deemed 'primary'
    if (!hasMain) allMain = [...primaryCompetitors];

    // Ensure all objects have a sortOrder property, if they don't...
    // Set the sortOrder at 1000, so it's ordered in the last items
    allMain = sortDomains(allMain);
  }

  let changeLabel = ``;
  if (!monthOnMonth) changeLabel = `than prev week`;
  if (monthOnMonth) changeLabel = `than prev month`;

  const mainStats = generateStats({ changeLabel, data: allMain });

  const cleanLabel = stat => {
    let cleanName = replaceAll(stat?.label, ` mobile`, ``);
    cleanName = replaceAll(cleanName, ` desktop`, ``);
    return { ...stat, label: cleanName };
  };

  // Filter by device (mobile/desktop)
  let filteredMobileStats = mainStats?.filter(({ label }) => label?.includes(`mobile`));
  filteredMobileStats = filteredMobileStats.map(cleanLabel);
  let filteredDesktopStats = mainStats?.filter(({ label }) => label?.includes(`desktop`));
  filteredDesktopStats = filteredDesktopStats.map(cleanLabel);

  const hasFilteredMobile: boolean = filteredMobileStats?.length > 0;
  const hasFilteredDesktop: boolean = filteredDesktopStats?.length > 0;

  const otherStats = generateStats({ changeLabel, data: otherSorted });
  // Filter by device (mobile/desktop)
  let filteredMobileOther = otherStats?.filter(({ label }) => label?.includes(`mobile`));
  filteredMobileOther = filteredMobileOther.map(cleanLabel);
  // Order the stats in alphabetical order
  filteredMobileOther = filteredMobileOther.sort((a, b) => parseFloat(b.figure) - parseFloat(a.figure));
  // Filter by device (mobile/desktop)
  let filteredDesktopOther = otherStats?.filter(({ label }) => label?.includes(`desktop`));
  filteredDesktopOther = filteredDesktopOther.map(cleanLabel);
  // Order the stats in alphabetical order
  filteredDesktopOther = filteredDesktopOther.sort((a, b) => parseFloat(b.figure) - parseFloat(a.figure));

  const hasFilteredMobileOther: boolean = filteredDesktopOther?.length > 0;
  const hasFilteredDesktopOther: boolean = filteredDesktopOther?.length > 0;

  return (
    <React.Fragment>
      {error && <Error error={error} />}
      {(hasFilteredMobile || hasFilteredDesktop) && <h2>Primary Competitors - {prettyDate}</h2>}
      {loading && <Skeleton height={150} />}
      {loading && <Skeleton height={150} />}
      {hasFilteredMobile && enabledMobile && (
        <React.Fragment>
          {!loading && <h3>Mobile</h3>}
          <div className="report__statistics">
            {filteredMobileStats.map(s => (
              <Statistic {...s} key={`${s?.label}-${s?.figure}`} titleLower={true} />
            ))}
          </div>
        </React.Fragment>
      )}
      {hasFilteredDesktop && enabledDesktop && (
        <React.Fragment>
          {!loading && <h3>Desktop</h3>}
          <div className="report__statistics">
            {filteredDesktopStats.map(s => (
              <Statistic {...s} key={`${s?.label}-${s?.figure}`} titleLower={true} />
            ))}
          </div>
        </React.Fragment>
      )}
      {(hasFilteredMobileOther || hasFilteredDesktopOther) && <h2>Other Competitors - {prettyDate}</h2>}
      {loading && <Skeleton height={150} />}
      {loading && <Skeleton height={150} />}
      {loading && <Skeleton height={150} />}
      {loading && <Skeleton height={150} />}
      {hasFilteredMobileOther && enabledMobile && (
        <React.Fragment>
          <h3>Mobile</h3>
          <div className="report__statistics">
            {filteredMobileOther.map(s => (
              <Statistic {...s} key={`${s?.label}-${s?.figure}`} titleLower={true} />
            ))}
          </div>
        </React.Fragment>
      )}
      {hasFilteredDesktopOther && enabledDesktop && (
        <React.Fragment>
          <h3>Desktop</h3>
          <div className="report__statistics">
            {filteredDesktopOther.map(s => (
              <Statistic {...s} key={`${s?.label}-${s?.figure}`} titleLower={true} />
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { ReportStatistics };
export default ReportStatistics;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

// Particles
import { OMContext } from 'corigan';

// Components
import { Field } from 'corigan';
import { Error } from 'corigan';

// API
import { callGetManyPageTypes } from 'corigan';

// Localised partials
import PageCanonicals from '../canonicals';
import PageMeta from '../meta';
// import PageStatistics from './statistics';

import { statuses } from 'particles';

type PageOverviewProps = {
  isLocked: boolean;
};

const PageOverview = (props: PageOverviewProps) => {
  const context = useContext(OMContext);
  const dispatch = context?.dispatch;
  const state = context?.state;

  const { isLocked } = props;

  const editedRevision: PageRevision | null = state?.editedRevision;
  const name = editedRevision?.name;
  const pageId = editedRevision?.pageId;
  const pageType = editedRevision?.pageType;
  const status: ArgStatus | string | null = editedRevision?.status;
  const title = editedRevision?.title;
  const metaDescription = editedRevision?.metaDescription;
  const url = editedRevision?.url;

  const handleChange = useCallback(
    (e, key?: string) => {
      const { id, value } = e.target;
      dispatch({ key: key ? key : id, value });
    },
    [dispatch],
  );

  const locked: boolean = useMemo(() => {
    const lowerStatus = status?.toLowerCase();
    const isAwaiting = lowerStatus === statuses.awaitingApproval.toLowerCase();
    const isReady = lowerStatus === statuses.readyToImplement.toLowerCase();
    const isImplemented = lowerStatus === statuses.implemented.toLowerCase();
    const isLive = lowerStatus === statuses.live.toLowerCase();

    const shouldLock: boolean = isLocked || isAwaiting || isReady || isImplemented || isLive;
    return shouldLock;
  }, [isLocked, status]);

  if (!editedRevision) return null;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
  };

  return (
    <section className="page__overview">
      <form onSubmit={onSubmit} aria-label="Update a Page" className="form--trimmed">
        <fieldset>
          <Fields
            handleChange={handleChange}
            locked={locked}
            name={name}
            pageId={pageId}
            pageType={pageType}
            title={title}
            metaDescription={metaDescription}
            url={url}
            status={status}
          />
        </fieldset>
      </form>
      <PageMeta />
      <PageCanonicals locked={locked} />
    </section>
  );
};

declare type FieldsProps = {
  handleChange: any;
  locked: boolean;
  name: string;
  pageId: string;
  pageType: PageType;
  title: string;
  metaDescription: string;
  url: string;
  status: string;
};

const Fields: React.FC<FieldsProps> = (props: FieldsProps) => {
  const { handleChange, locked, name, pageId, pageType, title, metaDescription, url, status } = props;

  const { data: res, error } = useQuery([`callGetManyPageTypes`], callGetManyPageTypes, {});
  const pageTypes: PageType[] = res?.data;
  const hasTypes: boolean = pageTypes?.length > 0;

  // PageId and URL mandatory passed briefed stage
  const lowerStatus = status?.toLowerCase();
  const isNewPage: boolean = lowerStatus === statuses.newPage.toLowerCase();
  const isDraft: boolean = lowerStatus === statuses.draft.toLowerCase();
  const isLive = lowerStatus === statuses.live.toLowerCase();
  const isImplemented: boolean = lowerStatus === statuses.implemented.toLowerCase();
  const statusRequired: boolean = !isNewPage && !isDraft;

  const liveOrImplemented: boolean = isLive || isImplemented;

  return (
    <React.Fragment>
      {useMemo(() => {
        return (
          <Field
            disabled={!liveOrImplemented && locked}
            handleChange={handleChange}
            id="pageId"
            label="Page ID"
            value={pageId}
          />
        );
      }, [locked, liveOrImplemented, handleChange, pageId])}
      {useMemo(() => {
        return (
          <Field
            disabled={!liveOrImplemented && locked}
            handleChange={handleChange}
            id="name"
            label="Page Name"
            required={true}
            value={name}
          />
        );
      }, [locked, liveOrImplemented, handleChange, name])}
      {useMemo(() => {
        return (
          <Field
            disabled={!liveOrImplemented && locked}
            handleChange={handleChange}
            id="title"
            label="Page Title"
            required={statusRequired}
            value={title ? title : ``}
          />
        );
      }, [locked, liveOrImplemented, handleChange, statusRequired, title])}
      {useMemo(() => {
        return (
          <Field
            disabled={!liveOrImplemented && locked}
            handleChange={handleChange}
            id="metaDescription"
            label="Meta Description"
            value={metaDescription ? metaDescription : ``}
          />
        );
      }, [locked, liveOrImplemented, handleChange, metaDescription])}
      {useMemo(() => {
        return (
          <Field
            disabled={!liveOrImplemented && locked}
            handleChange={handleChange}
            id="url"
            label="URL"
            required={statusRequired}
            openInTab={true}
            type="url"
            value={url}
          />
        );
      }, [locked, liveOrImplemented, statusRequired, handleChange, url])}
      {error && <Error error={error} />}
      {useMemo(() => {
        if (!hasTypes) return null;
        return (
          <React.Fragment>
            <label htmlFor="pageType">Page Type</label>
            <select disabled={true} id="pageType" name="pageType" value={pageType?.id}>
              {hasTypes &&
                pageTypes.map(({ id, name }) => (
                  <option key={`${name}-${id}`} value={id}>
                    {name}
                  </option>
                ))}
            </select>
          </React.Fragment>
        );
      }, [hasTypes, pageType, pageTypes])}
    </React.Fragment>
  );
};

export default PageOverview;

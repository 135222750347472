import styled from 'styled-components';

const StyledKeys = styled.div`
  margin-bottom: 32px;
  margin-top: 16px;

  button {
    /* Button Reset CSS */
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    align-items: center;
    display: flex;

    color: var(--primary);
    font-weight: bold;
    text-transform: initial;
  }

  li {
    margin-top: 16px;
  }

  li + li {
    margin-top: 8px;
  }

  ul {
    display: none;
    margin-left: 0;
    padding-left: 0;

    list-style: none;
  }

  &.keys--open {
    ul {
      display: block;
    }
  }

  &.keys--bullets {
    ul {
      margin-bottom: 0;
      margin-left: initial;
      padding-left: 16px;

      list-style: initial;
    }
  }
`;

export default StyledKeys;

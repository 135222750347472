import { EditorState, SelectionState } from 'draft-js';

import { createEntity, applyEntity } from './index';
import { escapeRegExp, cleanTextRegExp } from 'helpers';

export const addPhraseLinking = (
  newEditorState: EditorState,
  currentSelection: SelectionState,
  keywords: Array<Keyword | LinkedWord>,
  allowedLinkingCharacters: string[],
  highlight?: boolean,
): EditorState => {
  // Gets an array of the editor content blocks, usually defined as each new line (not text wrapped)
  let currentContent = newEditorState.getCurrentContent();
  const contentBlockMap = currentContent.getBlockMap();
  const currentAnchorKey = currentSelection.getAnchorKey();
  const selectionStartPosition = currentSelection.getStartOffset();
  const selectionEndPosition = currentSelection.getEndOffset();

  // This determines if the function should be ran again at the end to check
  // if there might be more matches (more on this below)
  let loop = false;

  // Loops through each content block
  contentBlockMap.forEach(contentBlock => {
    const rawText = contentBlock.getText().toLowerCase();
    const text = cleanTextRegExp(rawText);
    const anchorKey = contentBlock.getKey();

    for (const keyword of keywords) {
      const { phrase: rawPhrase, url, keywordType } = keyword;
      const phrase = cleanTextRegExp(rawPhrase)

      // Make sure there are no empty keywords, otherwise it matches infinitely
      const noPhrase: boolean = phrase === ``;
      if (noPhrase) continue;

      // If we have no escaped phrase then exit
      const escapedPhrase = escapeRegExp(phrase);
      if (!escapedPhrase) break;

      // Sets regex to globally search for the keyword
      let matchArr;
      const regex: RegExp = new RegExp(escapedPhrase.toLowerCase(), `g`);

      // Keeps checking as matches for the keyword can still be found
      while ((matchArr = regex.exec(text)) !== null) {
        // Get the positions in text for the start and end of the keyword
        const keywordStartPosition = matchArr.index;
        const keywordEndPosition = keywordStartPosition + phrase.length;

        // Check if keyword is not part of another word / has the allowed
        // linking characters on either side. Such as only linking words with
        // a space or period on either side of the word, also takes into
        // account keywords at the start and end of lines
        const beforeKeyword: string = text.charAt(keywordStartPosition - 1);
        const afterKeyword: string = text.charAt(keywordEndPosition);

        // Cursor is at end of word calculation
        const startSelectionAtEnd: boolean = selectionStartPosition === keywordEndPosition;
        const endSelectionAtEnd: boolean = selectionEndPosition === keywordEndPosition;
        const isCursorOnWord: boolean = currentAnchorKey === anchorKey && (startSelectionAtEnd || endSelectionAtEnd);

        // Keyword is at beginning or end of line
        const isStartOfLine: boolean = keywordStartPosition === 0;
        const isEndOfLine: boolean = keywordEndPosition === text.length;

        // Check if the character before and after is a valid character
        const isBeforeAllowed: boolean = isStartOfLine || allowedLinkingCharacters.some(char => char === beforeKeyword);
        const isAfterAllowed: boolean = (isEndOfLine && !isCursorOnWord) || allowedLinkingCharacters.some(char => char === afterKeyword);
        const isNotAllowed: boolean = !isBeforeAllowed || !isAfterAllowed;

        if (isNotAllowed) continue;

        // Find if there are any entities set at the start and end locations of the keyword
        currentContent = newEditorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const keywordStartEntityKey = currentContentBlock.getEntityAt(keywordStartPosition);
        const keywordEndEntityKey = currentContentBlock.getEntityAt(keywordEndPosition - 1);

        // If we only want to highlight, disable linked
        const linked: boolean = highlight ? false : true;

        // If there are no entities set, we set a keyword entity, else we
        // check if the currently found keyword is a better match

        // A simple example of this is the text "blue kitchen appliance"
        // The keyword "blue kitchen" is a keyword already linked, but "kitchen appliance"
        // has been found. "kitchen appliance" is a longer keyword, so it is
        // assumed to be a better match.
        if (keywordStartEntityKey === null && keywordEndEntityKey === null) {
          const keywordSelection = currentSelection.merge({
            anchorKey,
            anchorOffset: keywordStartPosition,
            focusKey: anchorKey,
            focusOffset: keywordEndPosition,
          });

          const { contentWithNewEntity, newEntityKey } = createEntity({
            currentContent,
            linked,
            phrase,
            url,
            keywordType,
          });
          newEditorState = applyEntity(newEditorState, contentWithNewEntity, keywordSelection, newEntityKey, keywords);
        } else {
          // Gets the keywords saved to the start and end of the currently found keyword
          const entityFromVal = (c, val) => (!val ? {} : c.getEntity(val).getData());
          const { keyword: startEntityKeyword } = entityFromVal(currentContent, keywordStartEntityKey);
          const { keyword: endEntityKeyword } = entityFromVal(currentContent, keywordEndEntityKey);

          // Gets the length of each keyword. If the combined length of these
          // keywords is shorter than the found keyword, replace them.
          const startEntityKeywordLength: number = startEntityKeyword ? startEntityKeyword.length : 0;
          const endEntityKeywordLength: number = endEntityKeyword ? endEntityKeyword.length : 0;

          if (phrase.length > startEntityKeywordLength + endEntityKeywordLength) {
            let keywordSelectionStartPosition = keywordStartPosition;
            let keywordSelectionEndPosition = keywordEndPosition;

            // Loop through content searching for the already existing
            // keywords to get their actual start and end positions
            contentBlock.findEntityRanges(
              character => {
                const entityKey = character.getEntity();
                const isCurrentKeywordEntity =
                  entityKey !== null && (entityKey === keywordStartEntityKey || entityKey === keywordEndEntityKey);

                return isCurrentKeywordEntity;
              },
              (entityKeywordStartPosition, entityKeywordEndPosition) => {
                keywordSelectionStartPosition =
                  entityKeywordStartPosition < keywordSelectionStartPosition
                    ? entityKeywordStartPosition
                    : keywordSelectionStartPosition;
                keywordSelectionEndPosition =
                  entityKeywordEndPosition > keywordSelectionEndPosition
                    ? entityKeywordEndPosition
                    : keywordSelectionEndPosition;
              },
            );

            // Wipe the links from the entire range between those positions
            const oldKeywordSelection = currentSelection.merge({
              anchorKey,
              anchorOffset: keywordSelectionStartPosition,
              focusKey: anchorKey,
              focusOffset: keywordSelectionEndPosition,
            });

            newEditorState = applyEntity(newEditorState, currentContent, oldKeywordSelection, null, keywords);

            // Apply the currently found keyword link to the now clear selection
            currentContent = newEditorState.getCurrentContent();

            const keywordSelection = currentSelection.merge({
              anchorKey,
              anchorOffset: keywordStartPosition,
              focusKey: anchorKey,
              focusOffset: keywordEndPosition,
            });

            const { contentWithNewEntity, newEntityKey } = createEntity({
              currentContent,
              linked,
              phrase,
              url,
              keywordType,
            });

            newEditorState = applyEntity(
              newEditorState,
              contentWithNewEntity,
              keywordSelection,
              newEntityKey,
              keywords,
            );

            // Because existing keywords were removed, we should check the
            // keyword list again for matches. Using the example above, "blue"
            // used to belong to a keyword but was unmatched, but it might
            // still match another keyword.
            loop = true;
          }
        }
      }
    }
  });

  if (loop) {
    newEditorState = addPhraseLinking(newEditorState, currentSelection, keywords, allowedLinkingCharacters, highlight);
  }

  return newEditorState;
};

export default addPhraseLinking;

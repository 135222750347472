import { css } from 'styled-components';

import GothamBookTTF from 'particles/fonts/gotham/GothamBook.ttf';
import GothamBookWOFF from 'particles/fonts/gotham/GothamBook.woff';
import GothamBookWOFF2 from 'particles/fonts/gotham/GothamBook.woff2';

import GothamMediumTTF from 'particles/fonts/gotham/GothamMedium.ttf';
import GothamMediumWOFF from 'particles/fonts/gotham/GothamMedium.woff';
import GothamMediumWOFF2 from 'particles/fonts/gotham/GothamMedium.woff2';

import GothamBoldTTF from 'particles/fonts/gotham/GothamBold.ttf';
import GothamBoldWOFF from 'particles/fonts/gotham/GothamBold.woff';
import GothamBoldWOFF2 from 'particles/fonts/gotham/GothamBold.woff2';

const fonts = () => css`
  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src:
    /* Super Modern Browsers */ url(${GothamBookWOFF2}) format('woff2'),
      /* Pretty Modern Browsers */ url(${GothamBookWOFF}) format('woff'),
      /* Safari, Android, iOS */ url(${GothamBookTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src:
    /* Super Modern Browsers */ url(${GothamMediumWOFF2}) format('woff2'),
      /* Pretty Modern Browsers */ url(${GothamMediumWOFF}) format('woff'),
      /* Safari, Android, iOS */ url(${GothamMediumTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src:
    /* Super Modern Browsers */ url(${GothamBoldWOFF2}) format('woff2'),
      /* Pretty Modern Browsers */ url(${GothamBoldWOFF}) format('woff'),
      /* Safari, Android, iOS */ url(${GothamBoldTTF}) format('truetype');
  }
`;

export default fonts;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { CRContext } from 'corigan';
import { dateFormal } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Link, Table } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { DomainSelector } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetManyCrawls } from 'corigan';

declare type CrawlsPageProps = {
  children?: React.ReactNode;
};

const Crawls: React.FC<CrawlsPageProps> = (props: CrawlsPageProps) => {
  return (
    <ProtectedRoute>
      <Page application="competitor-research" pageTitle="Crawls">
        <PageContents />
      </Page>
    </ProtectedRoute>
  );
};

const PageContents: React.FC = () => {
  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.competitor_research}>Competitor Research</Link>
            <h1>Crawls</h1>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card minHeight={false}>
            <DomainSelector
              allowAll={true}
              competitorsOnly={false}
              id="domain-selector"
              horizontal={true}
              label="Filter by Site"
              margin={false}
            />
            <CrawlTable />
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export const columns = [
  {
    align: `right`,
    dbKey: `crawlDate`,
    filter: true,
    sort: true,
    hide: false,
    label: `Crawl Date`,
    numeric: true,
    date: true,
    value: `crawlDate`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `domain.hostname`,
    filter: true,
    sort: true,
    hide: false,
    label: `Site`,
    numeric: false,
    value: `hostname`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `status`,
    filter: true,
    sort: true,
    hide: false,
    label: `Status`,
    numeric: false,
    value: `status`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `totalUrls`,
    filter: true,
    sort: true,
    hide: false,
    label: `Total URLs`,
    numeric: true,
    value: `totalUrls`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `urlsChanged`,
    filter: true,
    sort: true,
    hide: false,
    label: `URLs Changed`,
    numeric: false,
    value: `urlsChanged`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `percentageDifference`,
    filter: true,
    sort: true,
    hide: false,
    label: `% difference`,
    numeric: false,
    value: `percentageDifference`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `notes`,
    filter: true,
    sort: true,
    hide: false,
    label: `Notes`,
    numeric: false,
    small: true,
    value: `notes`,
    wrap: true,
  },
];

const generateRows = ({ data }) => {
  if (!data) return [];

  const formattedData = data.map(crawl => {
    const date = dateFormal(crawl.crawlDate);
    const notes = crawl?.notes;
    const crawlDate = crawl.status === `complete` ? { href: `${ROUTES.crawl}?id=${crawl.id}`, value: date } : date;
    const hostname = crawl.domain.hostname;
    const totalUrls = crawl?.totalUrls?.toLocaleString();
    const status = crawl.status;

    let urlsChanged: string = `N/A`;
    if (crawl?.urlsChanged) urlsChanged = crawl?.urlsChanged?.toLocaleString();

    let percentageDifference: string = `N/A`;
    if (crawl?.percentageDifference) percentageDifference = `${(crawl.percentageDifference * 100).toFixed(2)}%`;

    const item = {
      crawlDate,
      hostname,
      status,
      totalUrls,
      urlsChanged,
      percentageDifference,
      notes,
    };

    return item;
  });

  return formattedData;
};

const CrawlTable: React.FC = () => {
  const [mounted, setMounted] = useState<boolean>(false);

  const { releaseVersion } = getRelease();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=crCrawlsMastersheet-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  const context = useContext(CRContext);
  const state = context?.state;
  const hasCompetitor: boolean = Boolean(state?.competitor);
  const competitor = state?.competitor?.id;
  const isAll = state?.competitor?.hostname === `all`;

  // Create initial API arguments
  const initialWhere: ArgWhere = !isAll && competitor ? `[domain][eq]=${competitor}` : undefined;
  const initialOrderBy: ArgOrderBy = `-crawlDate`;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = [`domain`];

  // Read local storage values
  const localOrderBy: ArgOrderBy = localStorageRead(buildKey(`OrderBy`))
    ? localStorageRead(buildKey(`OrderBy`))
    : initialOrderBy;
  const localPage: ArgPage = localStorageRead(buildKey(`Page`)) ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = localStorageRead(buildKey(`PerPage`))
    ? localStorageRead(buildKey(`PerPage`))
    : initialPerPage;
  const localWhere: ArgWhere = localStorageRead(buildKey(`Where`)) ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }

      return newValue;
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = (value: any) => setStateValue(`Page`, value);
  const setPerPage = (value: any) => setStateValue(`PerPage`, value);
  const setWhere = (value: any) => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage

    if (isAll) stateSetWhere(undefined);
    if (competitor) stateSetWhere(`[domain][eq]=${competitor}`);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [competitor, isAll]);

  const whereProtected: ArgWhere = undefined;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const queryFunction = callGetManyCrawls;
  const queryName = `callGetManyCrawls`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = hasDomain && hasCompetitor;

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination = res?.pagination;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data });
  }, [data]);

  const csvTitle = `crawls`;

  if (!mounted) return null;

  return (
    <Table
      apiArgs={apiArgs}
      apiDetails={apiDetails}
      apiFunctions={apiFunctions}
      allowFilters={false}
      collectionType="crawl"
      columns={columns}
      csvTitle={csvTitle}
      error={error}
      id="mastersheet-crawls"
      items={items}
      loading={loading}
      pagination={pagination}
      selectable={false}
      small={false}
      transfer={false}
    />
  );
};

export default Crawls;

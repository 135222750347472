export const columns = [
  {
    align: `left`,
    dbKey: `phrase`,
    filter: false,
    sort: true,
    hide: false,
    label: `Keywords`,
    numeric: false,
    value: `phrase`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `volume`,
    filter: false,
    sort: true,
    hide: false,
    label: `Search volume`,
    numeric: true,
    value: `volume`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `baseDate`,
    filter: false,
    sort: false,
    hide: false,
    label: `Start Date`,
    numeric: false,
    value: `baseDate`,
    wrap: false,
},
  {
    align: `left`,
    dbKey: `compareDate`,
    filter: false,
    sort: false,
    hide: false,
    label: `Compare Date`,
    numeric: false,
    value: `compareDate`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `average`,
    filter: false,
    sort: false,
    hide: false,
    label: `Movement`,
    numeric: false,
    value: `average`,
    wrap: false,
    tooltip: `Difference in position between the two specified dates`
  },
];

export default columns;

import { fetchAPI } from 'particles';

type GetCrawlParameters = {
  id: ArgID;
  _with?: ArgWith;
};

export const callGetCrawl = async (queryKey, parameters: GetCrawlParameters) => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `crawls` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetCrawl;

import * as React from 'react';

function SvgShoppingCart(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M7.2 19.2c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4c0-1.3-1.1-2.4-2.4-2.4zM0 0v2.4h2.4l4.3 9.1-1.6 2.9c-.2.3-.3.7-.3 1.2 0 1.3 1.1 2.4 2.4 2.4h14.4v-2.4H7.7c-.2 0-.3-.1-.3-.3v-.1l1.1-2h8.9c.9 0 1.7-.5 2.1-1.2l4.3-7.8c.2-.2.2-.4.2-.6 0-.7-.5-1.2-1.2-1.2H5.1L3.9 0H0zm19.2 19.2c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgShoppingCart;

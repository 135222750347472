import ROUTES from 'routes';

import type { NavigationItem } from '../navigation.types';

export const sidebarItems: NavigationItem[] = [
  {
    href: ROUTES.competitor_research,
    icon: `dashboard`,
    title: `Dashboard`,
  },
  {
    href: ROUTES.crawls,
    icon: `construction`,
    title: `Crawls`,
  },
  {
    href: ROUTES.performance_comparison,
    icon: `compareArrows`,
    title: `Performance Comparison`,
  },
];

export default sidebarItems;

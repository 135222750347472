import * as React from 'react';

// Particles
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Button, Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Mastersheet specific imports
import { OMMastersheetTable } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';

// Local Partials
import { columns } from '../table-pages/data/pages/columns';
import { generateRows } from '../table-pages/data/pages/generateRows';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`pages:read`]}>
    <Mastersheet />
  </ProtectedRoute>
);

const initialWhere: ArgWhere = undefined;
const initialWith: ArgWith = [
  `assignedTo`,
  `categories.category`,
  `keywords`,
  `page`,
  `pageType`,
  `tags`,
  `updatedBy`,
];

type MastersheetProps = {};

const Mastersheet: React.FC<MastersheetProps> = (props: MastersheetProps) => {
  const { userHasPermission: canCreate } = useHasPermissions({
    requiredPermissions: [`pages:create`],
  });

  return (
    <Page application="optimisation-manager" pageTitle="Mastersheet">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.optimisation_manager}>Dashboard</Link>
              <h1>Mastersheet</h1>
              {canCreate && (
                <Button href={ROUTES.pages_import} variant="hollow">
                  Import Page Revisions
                </Button>
              )}
              {canCreate && <Button href={ROUTES.page_create}>Create a Page</Button>}
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <OMMastersheetTable
                id="mastersheet"
                whereProtectedType="[domain][eq]="
                initialWhere={initialWhere}
                initialWith={initialWith}
                remember={true}
                queryFunction={callGetManyPageRevisions}
                queryName="callGetManyPageRevisions"
                groupBy="page"
                columns={columns}
                generateRows={generateRows}
              />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

export default Wrapper;

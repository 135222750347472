import { css } from 'styled-components';

import ThemeDefault from '../../themeDefault';

const colours = [
  { primary: ThemeDefault.primary },
  { secondary: ThemeDefault.secondary },
  { tertiary: ThemeDefault.tertiary },
  { quaternary: ThemeDefault.quaternary },
  { quinary: ThemeDefault.quinary },
  { senary: ThemeDefault.senary },
  { white: ThemeDefault.white },
  { grey: ThemeDefault.grey },
  { grey100: ThemeDefault.grey100 },
  { grey200: ThemeDefault.grey200 },
  { grey300: ThemeDefault.grey300 },
  { black: ThemeDefault.black },
  { blue: ThemeDefault.blue },
  { blue000: ThemeDefault.blue000 },
  { blue100: ThemeDefault.blue100 },
  { teal: ThemeDefault.teal },
  { green: ThemeDefault.green },
  { green000: ThemeDefault.green000 },
  { green100: ThemeDefault.green100 },
  { purple: ThemeDefault.purple },
  { red: ThemeDefault.red },
  { red000: ThemeDefault.red000 },
  { red100: ThemeDefault.red100 },
  { orange: ThemeDefault.orange },
];

const colourTrumps = () => {
  let trumpString = ``;
  colours.map(colour => {
    for (const [key, value] of Object.entries(colour)) {
      trumpString += `.background--${key} { background-color: ${value} !important; } `;
    }
    return null;
  });
  return trumpString;
};

const background = css`
  ${colourTrumps()};
`;

export default background;

import { fetchAPI } from 'particles';

type GetManyKeywordsParameters = {
  keywords?: string;
};

declare type ResponseItem = { keyword: Keyword; pageRevisions: PageRevision[] };
declare type Response = ResponseItem[];

export const callGetManyKeywordsUses = async (queryKey, parameters: GetManyKeywordsParameters): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `keywordUses` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetManyKeywordsUses;

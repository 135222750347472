import * as React from 'react';

import { Link } from 'atoms';
import { Badge } from 'atoms';

import { Clear } from 'icons';

import StyledChip from './chip.styles';
import { StyledChipButton, StyledChipLinkButton, StyledChipLink } from './chip.styles';

import type ChipProps from './chip.types';

const Chip: React.FC<ChipProps> = (props: ChipProps) => {
  const { buttonText, className, confirm, hasRemove = false, responseCode } = props;
  const { children, href, onClick, onChipClick, ...data } = props;

  let classList = `chip`;
  if (className) classList += ` ${className}`;

  let chip = (
    <StyledChip className={classList} onClick={onClick} {...data}>
      {children}
    </StyledChip>
  );

  if (onChipClick) {
    chip = (
      <StyledChipButton {...data} className={classList} onClick={onChipClick}>
        {children}
        {onClick && (
          <button onClick={e => onClick(e)}>
            {buttonText && <span className="hide">{buttonText}</span>}
            <Clear />
          </button>
        )}
      </StyledChipButton>
    );
  } else if (href && onClick) {
    chip = (
      <StyledChipLinkButton {...data} className={classList}>
        <Link confirm={confirm} href={href}>
          {children}
          <button onClick={e => onClick(e)}>
            {buttonText && <span className="hide">{buttonText}</span>}
            <Clear />
          </button>
        </Link>
      </StyledChipLinkButton>
    );
  } else if (onClick && hasRemove) {
    chip = (
      <StyledChipLinkButton {...data} className={classList}>
        <span className="chip__wrapper">
          {children}
          <button onClick={e => onClick(e)}>
            {buttonText && <span className="hide">{buttonText}</span>}
            <Clear />
          </button>
        </span>
      </StyledChipLinkButton>
    );
  } else if (onClick) {
    chip = (
      <StyledChipButton {...data} className={classList} onClick={onClick}>
        <span className="chip__wrapper">
          {children}
        </span>
      </StyledChipButton>
    );
  } else if (href) {
    chip = (
      <StyledChipLink {...data} className={classList} onClick={onClick}>
        <Link confirm={confirm} href={href}>
          {children}
        </Link>
      </StyledChipLink>
    );
  }

  if (responseCode) {
    let responseVariant: ColourVariant = `green`;
    if (responseCode >= 300) responseVariant = `orange`;
    if (responseCode >= 400) responseVariant = `red`;

    return (
      <Badge content={responseCode} variant={responseVariant} size="small" align={{x: `right`, y: `bottom`}}>
        {chip}
      </Badge>
    );
  }

  return chip;
};

export default Chip;

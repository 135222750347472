import * as React from 'react';
import { useReducer } from 'react';
import { useLocation } from '@reach/router';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import OMContext from './omContext';
import omReducer from './omReducer';
import defaultState from './defaultState';
import { callUpdatePageRevision } from 'corigan';

declare type OMStateProps = {
  children: React.ReactNode;
};

// Create global state provider to handle state and reducer dispatch events
export const OMState = ({ children }: OMStateProps) => {
  const [state, dispatch] = useReducer(omReducer, defaultState);
  const location = useLocation();

  // define lock / unlock mutation
  const [mutate] = useMutation(callUpdatePageRevision, {
    onMutate: () => { },
    onError: (error, variables, onMutateValue) => {
      console.error(error);
      toast.error(error, {})
    },
    onSettled: (data, error) => {
      toast.error(error, {})
    },
    onSuccess: (data: APIResponse, variables) => { },
  });


  // On change of browser path...
  React.useEffect(() => {
    // If the path is a single OM page then exit
    const { pathname } = location;
    const isSingleOMPage = pathname.includes(`/optimisation-manager/page`);
    if (isSingleOMPage) return;

    // Otherwise...
    // Set started edit to false
    if (dispatch) dispatch({ type: `pageReset` });

    // patch editlock
    const editedRevision = state.editedRevision;
    if (editedRevision) {
      mutate({
        id: editedRevision?.id,
        locked: false
      });
    }
  }, [location, mutate, state.editedRevision]);

  return (
    <OMContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </OMContext.Provider>
  );
};

export default OMState;

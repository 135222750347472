module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-518H234CH4","head":false,"anonymize":true,"respectDNT":true,"defer":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Corigan","short_name":"Corigan","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/storybook/storybook-assets/android-chrome-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"000f2ebd8b9d735bb40a2afb3033f629"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

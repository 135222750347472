import React from 'react';
import parser from 'html-react-parser';

import { Link } from 'corigan';
import { isString } from 'corigan';

const config = {
  replace: data => {
    const { attribs, children } = data;
    const fallbackChild = children?.length > 0 && children[0]?.data;
    if (attribs?.href) {
      const { children, href } = attribs;
      if (!href || (!children && !fallbackChild)) return;
      const inner = children ? children : fallbackChild;
      return <Link href={href}>{inner}</Link>;
    }
  },
};
export const parseHTML = (html: string) => {
  if (!isString(html)) return html;
  const clean = parser(html, config);
  return clean;
};

export default parseHTML;

const columns = [
  {
    align: `left`,
    dbKey: `changeSignificance`,
    filter: true,
    sort: true,
    hide: false,
    label: `Significance`,
    numeric: false,
    value: `changeSignificance`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `statusCode`,
    filter: true,
    sort: true,
    hide: false,
    label: `Status Code`,
    numeric: true,
    value: `statusCode`,
    wrap: false,
  },
  {
    align: `left`,
    chip: false,
    dbKey: `protocol`,
    filter: true,
    sort: true,
    hide: false,
    label: `Protocol`,
    numeric: false,
    value: `protocol`,
  },
  {
    align: `left`,
    chip: false,
    dbKey: `domain`,
    filter: true,
    sort: true,
    hide: false,
    label: `Site`,
    numeric: false,
    value: `domain`,
  },
  {
    align: `left`,
    dbKey: `previousUrl`,
    filter: true,
    sort: true,
    hide: false,
    disabled: false,
    label: `Previous URL`,
    numeric: false,
    value: `previousUrl`,
    wrap: true,
  },
  {
    align: `left`,
    dbKey: `newUrl`,
    filter: true,
    sort: true,
    hide: false,
    label: `URL`,
    numeric: false,
    value: `newUrl`,
    wrap: true,
  },
  {
    align: `left`,
    dbKey: `canonical`,
    filter: true,
    sort: true,
    hide: true,
    label: `Canonical URL`,
    numeric: true,
    value: `canonical`,
    wrap: true,
  },
];

export { columns };
export default columns;

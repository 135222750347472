import 'react-dates/initialize';
import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';

import { DateRangePicker } from 'react-dates';
import moment from 'moment';

// Particles
import { KRContext } from 'corigan';

declare type DateRangeProps = {
  dateEarliest: string;
  dateLatest: string;
};

const endDateId = `keyword-research-dashboard-end`;
const startDateId = `keyword-research-dashboard-start`;

const DateRange: React.FC<DateRangeProps> = (props: DateRangeProps) => {
  const [focus, setFocus] = useState(null);
  const { dateEarliest, dateLatest } = props;

  const context = useContext(KRContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const { chartDateEnd, chartDateStart } = state;

  const onChange = ({ startDate, endDate }) => {
    if (startDate) dispatch({ key: `chartDateStart`, value: startDate?.clone() });
    if (endDate) dispatch({ key: `chartDateEnd`, value: endDate?.clone() });
  };

  const isOutsideRange = date => {
    const tooNew = date.isAfter(moment(dateLatest).startOf(`week`));
    const tooOld = date.isSameOrBefore(moment(dateEarliest).startOf(`week`));

    const justRight = !tooNew && !tooOld;

    return !justRight;
  };

  return (
    <nav className="keyword-research__dates">
      <DateRangePicker
        displayFormat="DD-MM-YYYY"
        endDate={chartDateEnd} // momentPropTypes.momentObj or null,
        endDateId={endDateId} // PropTypes.string.isRequired,
        firstDayOfWeek={1}
        focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        isOutsideRange={isOutsideRange}
        minimumNights={28}
        onDatesChange={dates => onChange(dates)} // PropTypes.func.isRequired,
        onFocusChange={focusedInput => setFocus(focusedInput)} // PropTypes.func.isRequired,
        startDate={chartDateStart} // momentPropTypes.momentObj or null,
        startDateId={startDateId} // PropTypes.string.isRequired,
      />
    </nav>
  );
};

export default DateRange;

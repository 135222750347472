import { generateChip } from 'corigan';

const getStatusChip = (statusCode: number) => {
  if (!statusCode) return `-`;

  // Get the code type 1xx, 2xx, 3xx, 4xx, 5xx
  const codeType = statusCode.toString()[0];
  let variant = `plain`;

  switch (codeType) {
    case `1`:
      variant = `green`;
      break;
    case `2`:
      variant = `green`;
      break;
    case `3`:
      variant = `orange`;
      break;
    case `4`:
      variant = `red`;
      break;
    case `5`:
      variant = `red`;
      break;
    default:
      variant = `plain`;
      break;
  }

  const chip: ChipProperties = generateChip({
    condition: `eq`,
    value: String(statusCode),
    variant,
    where: `statusCode`,
  });

  return [chip];
};

const getSignificanceChip = (significance: string) => {
  const significanceLowercase = significance?.toLowerCase();
  const significanceCapitalized = significance.charAt(0).toUpperCase() + significance.slice(1);

  let variant = `plain`;

  switch (significanceLowercase) {
    case `info`:
      variant = `plain`;
      break;
    case `removed`:
      variant = `red`;
      break;
    case `new`:
      variant = `green`;
      break;
    case `low`:
      variant = `yellow`;
      break;
    case `medium`:
      variant = `orange`;
      break;
    case `high`:
      variant = `red`;
      break;
  }

  const significanceChip: ChipProperties = generateChip({
    bold: true,
    condition: `eq`,
    value: significanceCapitalized,
    variant,
    where: `changeSignificance`,
  });

  return significanceChip;
};

export { getStatusChip, getSignificanceChip };

declare type GenerateSeriesRankArgs = {
  pages: any[];
};

declare type RankData = { average_position: number; date: string };

const cleanArray = (array: any[]): any[] => {
  const hasArray: boolean = array?.length > 0;
  if (!hasArray) return [];

  return array.filter(Boolean);
};

export const generateSeriesRank = (props: GenerateSeriesRankArgs) => {
  const { pages } = props;

  const hasPages: boolean = pages?.length > 0;
  if (!hasPages) return null;

  let dirtySeriesData = [];

  // Loop through each page
  dirtySeriesData = pages?.map((page: any) => {
    // Get the url value, to use in the axis series plotting
    const name = page?.url;
    // Get the rank weekly data from the page
    const rankWeekly: RankData[] = page?.rankWeekly;

    const dirtyData = rankWeekly?.map((rank: RankData) => {
      // Get the corresponding date
      const date: string = rank?.date;
      if (!date) return null;

      // Get the average position from the API response
      const averagePosition: number = rank?.average_position;
      if (!averagePosition) return null;

      // Average position is returned as a deicmal, so round it to the nearest whole value
      const roundedPosition: number = Math.round(averagePosition);

      const newItem: { x: string; y: number } = {
        x: date,
        y: roundedPosition,
      };

      return newItem;
    });
    const cleanData = cleanArray(dirtyData);

    return { name, data: cleanData };
  });

  const cleanSeriesData = cleanArray(dirtySeriesData);
  return cleanSeriesData;
};

export default generateSeriesRank;

import styled from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const StyledChange = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  svg {
    height: 12px;
    margin-left: 4px;

    color: var(--green);
    transform: rotate(45deg);
  }

  &.change--negative {
    svg {
      color: var(--red);
      transform: rotate(225deg);
    }
  }

  .change {
    margin-bottom: 4px;
    align-items: center;
    display: flex;
    line-height: 1;

    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};

    @media ${device?.xxl} {
      font-size: 1.5rem;
    }
  }
`;

export default StyledChange;

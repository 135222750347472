import styled from 'styled-components';
import { device } from 'particles';

const StyledRow = styled.div`
  --gap: 15px;

  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
  margin-top: var(--gap);

  &.eq-height {
    > .col {
      display: flex;

      > * {
        flex: 1;
        max-width: 100%;
      }
    }
  }

  &.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  &.no-gutters > .col,
  &.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }

  & + .row {
    @media ${device?.sm} {
      margin-top: 0;
    }
  }

  &.no-top-margin {
    margin-top: 0;
  }
`;

export default StyledRow;

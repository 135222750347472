import * as React from 'react';

// Components
import { Card } from 'corigan';
import { Tabs } from 'corigan';

// Localised partials
import PageCategories from '../categories';
import PageSummary from '../summary';
import PageTags from '../tags';

import Comment from '../comment';
import CurrentKeywords from '../keywords/current';
import SearchKeyword from '../keywords/search';
// import SuggestedKeywords from '../keywords/suggested';
import AssignedTo from '../assignedTo';

import { statuses } from 'particles';

type SidebarProps = {
  status: string;
  isLocked?: boolean;
};

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { isLocked, status } = props;

  const lowerStatus: string = status?.toLowerCase();
  const isLive: boolean = lowerStatus === statuses.live.toLowerCase();
  const isImplemented: boolean = lowerStatus === statuses.implemented.toLowerCase();

  const liveOrImplemented: boolean = isLive || isImplemented;

  return (
    <React.Fragment>
      <Card minHeight={false}>
        <AssignedTo />
      </Card>
      <Card minHeight={false}>
        <Comment isLocked={isLocked} />
      </Card>
      <Card minHeight={false}>
        {/* <Tabs id="om-sidebar-keywords" x={0}> */}
          <React.Fragment>
            <span>Current Keywords</span>
            {/* <span>Suggested</span> */}
          </React.Fragment>
          <React.Fragment>
            <section>
              <CurrentKeywords isLocked={isLocked} />
              { !isLocked && <SearchKeyword isLocked={isLocked} /> }
            </section>
            {/* <SuggestedKeywords isLocked={isLocked} isOptimisationManager={true} /> */}
          </React.Fragment>
        {/* </Tabs> */}
      </Card>
      <Card minHeight={false}>
        <Tabs id="om-sidebar-overview" x={0}>
          <React.Fragment>
            <span>Summary</span>
            <span>Categories</span>
            <span>Tags</span>
          </React.Fragment>
          <React.Fragment>
            <PageSummary />
            <PageCategories isLocked={isLocked} />
            <PageTags isLocked={!liveOrImplemented && isLocked} />
          </React.Fragment>
        </Tabs>
      </Card>
    </React.Fragment>
  );
};

export default Sidebar;

import { isURL } from 'corigan';
import { ROUTES } from 'corigan';

import { getStatusChip, getSignificanceChip } from './getStatusChip';

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const generateRows = ({ data }) => {
  if (!data) return [];

  const formattedData = data.map(change => {
    const parentID = change?.id;
    const id = { value: parentID };
    const crawlId = change?.crawl;

    const significance: string = change?.changeSignificance;
    const significanceChip = significance ? [getSignificanceChip(significance)] : `-`;

    const emptyObject = {
      domain: `-`,
      protocol: `-`,
      url: {
        levels: 2,
        shorten: false,
        value: `-`,
      },
    };

    const getURLParts = (args: { fallbackDomain?: string; fallbackProtocol?: string; url: string }) => {
      // Destructure the arguments
      const { fallbackDomain, fallbackProtocol, url } = args;
      const hasFallbackParts = Boolean(fallbackDomain) && Boolean(fallbackProtocol);

      // If no url value was defined, the return an empty object value for the table
      if (!url) return emptyObject;

      // Is the URL relative? We use this to build an absolute URL
      const isRelative: boolean = url.startsWith(`/`);
      const canBuild = isRelative && hasFallbackParts;

      const basicObject = {
        domain: ``,
        protocol: ``,
        url: {
          levels: 2,
          shorten: true,
          value: url,
        },
      };

      // If not a full URL or relative...
      if (!isURL(url) && !canBuild) return basicObject;

      // Concatenate URL parts to form an absolute URL from a relative URL
      const fullURL = canBuild ? `${fallbackProtocol}://${fallbackDomain}${url}` : url;

      // If this isn't a valid URL, exit with a basic object
      if (!isURL(fullURL)) return basicObject;

      const urlObject = new URL(fullURL);
      const { hostname, protocol } = urlObject;

      const urlContext: {
        levels: number;
        shorten: boolean;
        value: string;
        hostname: string;
      } = {
        levels: 2,
        shorten: true,
        value: fullURL,
        hostname,
      };

      return {
        domain: hostname,
        protocol: protocol?.replace(`:`, ``),
        url: urlContext,
      };
    };

    const { url: previousUrl, domain: previousDomain, protocol: previousProtocol } = getURLParts({
      url: change?.previousUrl,
    });

    const { url: newUrl, domain: newDomain, protocol: newProtocol } = getURLParts({
      fallbackDomain: previousDomain,
      fallbackProtocol: previousProtocol,
      url: change?.newUrl,
    });

    const buttons = [];
    const buttonURL = newUrl?.value ?? previousUrl?.value;
    const buttonHostname = newUrl?.hostname ?? previousUrl?.hostname;

    if (buttonURL && buttonHostname) {
      buttons.push({
        name: `History`,
        href: `${ROUTES.diff}?crawlId=${crawlId}&url=${buttonURL}`,
      });

      buttons.push({
        name: `Compare`,
        href: `${ROUTES.performance_comparison}?competitor=${buttonHostname}&search=${buttonURL}`,
      });
    }

    const item = {
      id,
      changeSignificance: significanceChip,
      statusCode: getStatusChip(change?.statusCode),
      protocol: newProtocol,
      domain: newDomain,
      previousUrl,
      newUrl,
      canonical: change?.canonical,
      buttons,
    };

    return item;
  });

  return formattedData;
};

import styled from 'styled-components';
import { shouldBeDark } from 'corigan';

const StyledToggle = styled.div`
  display: inline-flex;

  input[type='checkbox'] {
    & + label {
      cursor: pointer;
      position: relative;

      & + span {
        opacity: 1;
        transform: translateY(12px);
      }

      &::before {
        background-image: none;
        display: none;
      }
    }

    &:focus,
    &:focus-within {
      & + label {
        outline: 1px dotted var(--primary);
        outline-offset: 2px;
        outline-color: ${() => (shouldBeDark() ? `var(--primary)` : `var(--primary)`)};
      }
    }
  }

  input[type='checkbox']:checked {
    & + label {
      --offset: ${props => (props.small ? `14px` : `18px`)};
      --gradient: 1;
      --shadow: rgba(0, 6, 39, 0.2);
      --light-shadow: rgba(255, 255, 255, 0.8);
      --light-shadow-2: rgba(255, 255, 255, 0.2);

      &::before {
        background-image: none;
        display: none;
      }
    }
  }

  .toggle__dot {
    border-radius: 50%;
    height: ${props => (props.small ? `16.5px` : `24px`)};
    left: -1px;
    position: absolute;
    top: -1px;
    width: ${props => (props.small ? `16.5px` : `24px`)};

    background: var(--grey600);
    box-shadow: -4px -4px 8px var(--light-shadow-2, transparent), 4px 4px 8px var(--shadow, transparent);
    cursor: pointer;
    transform: translateX(var(--offset, 0));
    transition: transform 0.4s, box-shadow 0.4s;

    &:before {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      border-radius: inherit;
      background: linear-gradient(160deg, var(--white), var(--offWhite));
      opacity: var(--gradient, 0);
      transition: opacity 0.4s;
    }
  }

  .toggle__descriptor {
    margin-left: 12px;
    margin-top: 2px;
    display: flex;

    svg {
      height: 16.3px;
      margin-right: 5px;
      margin-top: 3px;
    }
  }

  &.toggle--small {
    .toggle__descriptor {
      font-size: 1.4rem;
    }
  }

  .toggle__label {
    min-width: 0px;
    padding: 0px;
    width: auto;
  }

  .toggle__switch {
    display: inline-block;
    height: ${props => (props.small ? `15px` : `22px`)};
    position: relative;
    width: ${props => (props.small ? `28px` : `40px`)};

    background: var(--grey200);
    border-radius: ${props => (props.small ? `8px` : `11px`)};
    cursor: pointer;
    pointer-events: none;

    &:before {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      background-color: var(--primary);
      border-radius: inherit;
      opacity: var(--gradient, 0);
      transition: opacity 0.4s;
    }
  }
`;

export default StyledToggle;

import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { capitalizeString } from 'corigan';

// Molecules
import { Error } from 'corigan';
import { Card } from 'corigan';

// Organisms
import { Col, Row } from 'corigan';

// Local Components
import CrawlStatistics from '../../../partials/crawl-statistics';

// API
import { callGetManyCrawls } from 'corigan';

declare type DomainHealthProps = {};

const DomainHealth = (props: DomainHealthProps) => {
  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const id = domainActive?.id;
  const hasHostname = Boolean(domainActive?.hostname);

  const where: ArgWhere = `[domain][eq]=${id}`;
  const orderBy: ArgOrderBy = `-crawlDate`;
  const page: ArgPage = undefined;
  const perPage: ArgPerPage = 1;
  const _with: ArgWith = [`domain`];

  const apiArgs = { where, orderBy, page, perPage, _with };

  const { data: res, error, isLoading: loading } = useQuery([`callGetManyCrawls`, { ...apiArgs }], callGetManyCrawls);
  const data = res?.data;
  const crawl = data?.[0];

  const statistics = crawl?.statistics;
  const hasStatistics: boolean = statistics?.length > 0;

  if (!hasStatistics && !loading) return null;

  const title = `Your Site Health`;
  const cleanHostname = domainActive?.hostname?.replace(`www.`, ``);

  return (
    <Row>
      <Col>
        <Card loading={loading} minHeight={false}>
          <h2 className="mb-3">
            {title}
            {hasHostname && <span className="text--initial"> - {capitalizeString(cleanHostname)}</span>}
          </h2>
          {error && <Error error={error} />}
          <CrawlStatistics crawl={crawl} loading={loading} />
        </Card>
      </Col>
    </Row>
  );
};

export default DomainHealth;

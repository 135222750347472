import * as React from 'react';
import { useContext } from 'react';

import { ApplicationContext } from 'corigan';
import { useHasPermissions } from 'corigan';

import { IsometricBuilder, IsometricChart, IsometricClipboard } from 'icons';
import { IsometricDiagram, IsometricSearch } from 'icons';

import { Link } from 'atoms';

import StyledApplicationItems from './application-items.styles';

const ApplicationsItemsWrapper = () => (
  <StyledApplicationItems className="applications">
    <ApplicationItems />
  </StyledApplicationItems>
);

const ApplicationItems: Function = (): JSX.Element[] => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const state = applicationContext?.state;
  const items = state?.applications?.items;

  const { userHasPermission: canAccessOM } = useHasPermissions({
    requiredPermissions: [`om:read`],
  });
  const { userHasPermission: canAccessKM } = useHasPermissions({
    requiredPermissions: [`km:read`],
  });
  const { userHasPermission: canAccessKR } = useHasPermissions({
    requiredPermissions: [`kr:read`],
  });
  const { userHasPermission: canAccessCR } = useHasPermissions({
    requiredPermissions: [`cr:read`],
  });
  const { userHasPermission: canAccessRB } = useHasPermissions({
    requiredPermissions: [`rb:read`],
  });

  const hasItems: boolean = items?.length > 0;
  if (!hasItems) return null;

  return items.map(item => {
    const { href, id, icon, title } = item;

    let hasAccess: boolean = false;

    switch (id) {
      case `om`:
        hasAccess = canAccessOM;
        break;
      case `km`:
        hasAccess = canAccessKM;
        break;
      case `kr`:
        hasAccess = canAccessKR;
        break;
      case `cr`:
        hasAccess = canAccessCR;
        break;
      case `rb`:
        hasAccess = canAccessRB;
        break;
      default:
        hasAccess = false;
    }

    let className: string = `application`;
    if (!hasAccess) className += ` application--disabled`;

    return (
      <Link className={className} href={href} key={`application-${title}`}>
        <div className="application__content">
          <span className="application__icon">
            <ApplicationIcon name={icon} />
          </span>
          <h3 className="application__title">{title}</h3>
        </div>
      </Link>
    );
  });
};

// Assign SVGs to object with named keys
const Icons = {
  builder: IsometricBuilder,
  chart: IsometricChart,
  clipboard: IsometricClipboard,
  diagram: IsometricDiagram,
  search: IsometricSearch,
};

type ApplicationIconProps = {
  name: string;
};

const ApplicationIcon: React.FC<ApplicationIconProps> = (props: ApplicationIconProps) => {
  const { name } = props;
  // If icon name value doesn't match Icons object keys then return null
  if (Icons[name] === undefined) return null;
  // If icon found, return the icon in a span element
  const Icon = Icons[name];
  return <Icon />;
};

// Default prop values
ApplicationIcon.defaultProps = {};

export default ApplicationsItemsWrapper;

import * as React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { addSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Field } from 'corigan';
import { Error, Info } from 'corigan';

// API
import { callUpdateTag } from 'corigan';

// Localised partials
import TagContent from './content';

type RequestCompleteProps = {
  data: any;
};

const RequestComplete = (props: RequestCompleteProps) => {
  const { data } = props;
  const { name } = data;

  return (
    <TagContent data={data} loading={false} title={name}>
      <TagOverview data={data} />
    </TagContent>
  );
};

const successMessage = name => {
  return `The tag "${name}" has been successfully updated in the system.`;
};

type FormErrors = {
  name?: string;
};

type FormValues = {
  name: string;
};

type TagOverviewProps = {
  data: any;
};

const validate = (values: FormValues) => {
  const errors: FormErrors = {};
  const { name } = values;
  if (!name) errors.name = `Please specify a tag name`;
  return errors;
};

const TagOverview = (props: TagOverviewProps) => {
  const [success, setSuccess] = useState(null);
  const { data } = props;
  const { name: initialName } = data;

  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const { userHasPermission: canUpdate } = useHasPermissions({ requiredPermissions: [`tags:update`] });

  const queryCache = useQueryCache();

  const [mutate, { error, isLoading: loading }] = useMutation(callUpdateTag, {
    // When mutate is called:
    onMutate: () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries([`callGetOneTag`, { id }]);
      queryCache.cancelQueries([`callGetManyTags`]);

      // Snapshot the previous value
      const previousTag: Tag = queryCache.getQueryData([`callGetOneTag`, { id }]);

      // Return the snapshotted value
      return queryCache.setQueryData([`callGetOneTag`, { id }], previousTag);
    },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyTags`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const tagData: Tag = data?.data;
      // If updateTag function was successful, get values from API response
      const latestName = tagData?.name;
      // Let the user know the tag has been updated and update UI
      setSuccess(successMessage(latestName));
      // Optimistically update to the new value
      queryCache.setQueryData([`callGetOneTag`, { id, whereProtected }], data);
      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`], (previousTags: APIResponse) =>
        addSingleCache(previousTags, tagData),
      );
      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { perPage: 10, whereProtected }], (previousTags: APIResponse) =>
        addSingleCache(previousTags, tagData),
      );
      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { perPage: 1000, whereProtected }], (previousTags: APIResponse) =>
        addSingleCache(previousTags, tagData),
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      name: initialName,
    },
    onSubmit: async values => {
      if (windowAvailable() && !canUpdate) window.alert(`You don't have permission to update tags`);
      if (canUpdate) return await mutate({ ...values, id });
    },
    validate,
  });

  const { handleChange, handleSubmit, isSubmitting, values } = formik;
  const { name } = values;

  const onClose = e => {
    e.preventDefault();
    setSuccess(null);
  };

  const busy: boolean = isSubmitting || loading;
  const disabled: boolean = !canUpdate || busy;

  let buttonText: string = `Update Tag`;
  if (busy) buttonText = `Updating Tag`;

  return (
    <section className="tag__overview">
      <h2>Update tag information</h2>
      <form onSubmit={handleSubmit} aria-label="Update a Tag">
        <fieldset className="mb-0" aria-busy={busy} disabled={disabled}>
          {success && (
            <Info onClose={onClose} y="lg">
              {success}
            </Info>
          )}
          {error && <Error error={error} />}
          <Field handleChange={handleChange} id="name" label="Name" required={true} value={name} />
          <button type="submit" disabled={disabled}>
            {buttonText}
          </button>
        </fieldset>
      </form>
    </section>
  );
};

export default RequestComplete;

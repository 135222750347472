import styled from 'styled-components';

const StyledResearch = styled.span`
  .kr__header--loaded.kr__header--loaded {
    display: flex;

    > * {
      display: flex;
      flex: unset;
    }

    .kr__search {
      display: block;
    }
  }

  .kr__search--again {
    .kr__search__form {
      padding-top: 24px;
      margin-top: 32px;

      border-top: 1px solid var(--grey300);
    }

    + div {
      display: block !important;
    }
  }

  .kr__search.kr__search--searching {
    max-width: 50%;
  }

  .kr__search.kr__search--searched {
    flex: initial;
    margin-right: 24px;
  }

  .kr__search__form {
    flex: 1;
    position: relative;

    form {
      width: 100%;
    }
  }

  .kr__searched {
    align-items: center;
    display: flex;

    button {
      svg {
        height: 28px;
        margin-right: 8px;
      }
    }
  }

  .kr__searched__keyword {
    align-items: center;
    display: inline-flex;
    margin-right: 24px;
    padding-right: 24px;
    position: relative;

    font-size: 20px;

    &::after {
      content: '';
      display: block;
      height: 100%;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 2px;

      background-color: var(--grey300);
    }
  }

  .kr__searched__phrase {
    display: inline-flex;
    margin-left: 8px;

    font-weight: 700;
  }

  .table__pagination {
    align-items: center;
    display: flex;
    justify-content: space-between;

    nav {
      margin-left: 24px;
    }
  }

  nav {
    button + button {
      margin-left: 4px;
    }
  }

  .search__results {
    position: absolute;
    z-index: 11;

    button + button {
      margin-left: 0;
    }
  }

  .search__pagination {
    display: flex;
    width: 100%;

    border-top: 1px solid var(--blue050);

    button {
      justify-content: center;
      width: 100%;

      background-color: var(--blue300);
      border-top: none;
      color: var(--white);
    }

    button[disabled] {
      background-color: var(--grey200);
      color: var(--grey900);
    }

    button + button {
      border-left: 1px solid var(--blue400);
    }

    button + button[disabled],
    button[disabled] + button {
      border-left: 1px solid var(--grey400);
    }
  }
`;

export default StyledResearch;

import { css } from 'styled-components';

const textarea = css`
  textarea {
    padding: var(--spacingDefault);
  }

  textarea {
    max-width: 100%;
    min-height: 200px;
    resize: vertical;
  }

  .field--textarea {
    min-height: 56px;
  }
`;

export default textarea;

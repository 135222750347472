import * as React from 'react';
import { useContext, useState } from 'react';
import { Formik } from 'formik';

import { ApplicationContext } from 'corigan';

import { Button, Link } from 'corigan';

import { Error, Info } from 'corigan';

import { Half } from 'corigan';

type FormErrors = {
  email?: string;
};

type FormValues = {
  email: string;
};

const initialValues: FormValues = { email: `` };

const successMessage = `We've sent you an email to reset your password.`;

const ResetPassword = () => {
  const context: ApplicationContextProps = useContext(ApplicationContext);
  const sendResetEmail = context?.firebase?.sendResetEmail;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleResetEmail = async values => {
    const { email } = values;
    await sendResetEmail(email);
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  return (
    <Half intro="Welcome back! Please login to your account." title="Corigan">
      {success && (
        <Info onClose={onClose} y="lg">
          {success}
        </Info>
      )}
      {error && <Error error={error}>{error}</Error>}
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          setSuccess(null);

          try {
            await handleResetEmail(values);
            setSuccess(successMessage);
          } catch (error) {
            setError(error);
          }
        }}
        validate={(values: FormValues) => {
          setError(null);
          const errors: FormErrors = {};
          const { email } = values;
          if (!email) errors.email = `An email is required`;
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email Address"
              id="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              aria-invalid={!!errors.email}
              aria-describedby="errorEmail"
            />
            {touched.email && errors.email && <Error id="errorEmail" error={errors.email} />}
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? `Sending New Password` : `Send New Password`}
            </Button>
            <footer className="form__footer">
              <Link to="/login">Remembered your password?</Link>
            </footer>
          </form>
        )}
      </Formik>
    </Half>
  );
};

export default ResetPassword;

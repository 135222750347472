import { escapeRegExp } from 'helpers';

export const getKeywordsDetails = ({ editorKeywords, entities }) => {
  const keyordsDetailsIntial = [];

  editorKeywords.forEach(keyword => {
    entities.forEach(entity => {
      if (!entity?.data) return;

      const { data } = entity;

      const entityKeyword: string = data?.keyword;
      const entityLinked: boolean = data?.linked;
      const url: string = data?.url;

      if (!entityKeyword) return;
      if (!entityLinked) return;

      const { phrase } = keyword;

      // Make sure there are no empty keywords,
      // otherwise it matches infinitely
      if (!phrase) return;

      // If we have no escaped phrase then exit
      const escapedPhrase: string = escapeRegExp(phrase);
      if (!escapedPhrase) return;

      const isMatch: boolean = entityKeyword === phrase;
      if (!isMatch) return;

      const matchesCount = 0;
      const warning = ``;

      const details = { matchesCount, phrase, warning, url };

      keyordsDetailsIntial.push(details);
    });
  });

  const keywordsWithMatches = keyordsDetailsIntial.map(keyword => {
    const { phrase } = keyword;
    const matches = keyordsDetailsIntial.filter(k => k.phrase === phrase);
    keyword.matchesCount = matches?.length;
    return keyword;
  });

  const keywordsDetailsWithWarnings = keywordsWithMatches.map(keyword => {
    const sharesTrackingURL = keyordsDetailsIntial.filter(k => {
      const matchesPhrase: boolean = k.phrase === keyword.phrase;
      if (matchesPhrase) return false;

      const matchesURL: boolean = k.url === keyword.url;
      return matchesURL;
    });

    const hasConflicts: boolean = sharesTrackingURL?.length > 0;
    if (!hasConflicts) return keyword;

    const conflinctingPhrases = sharesTrackingURL.map(k => k.phrase);
    const warnings = conflinctingPhrases.map(phrase => `Warning, conflicts with keyword ${phrase}.`);

    keyword.warning = warnings?.join(` `);

    return keyword;
  });

  const details = keywordsDetailsWithWarnings.filter(Boolean);
  return details;
};

export default getKeywordsDetails;

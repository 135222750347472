import * as React from 'react';

import Revisions from '../revisions';

declare type DraftsProps = { isLocked: boolean };

const Drafts: React.FC<DraftsProps> = (props: DraftsProps) => {
  const { isLocked } = props;
  return <Revisions drafts={true} isLocked={isLocked} />;
};

export default Drafts;

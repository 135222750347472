import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { default as loadable } from '@loadable/component';

// Particles
import { deepMerge } from 'corigan';

// Components
import { Skeleton } from 'corigan';

// Localised Particles
import baseOptions from './bar.json';

type BarProps = {
  className?: string;
  colour?: string;
  height?: string | number;
  full?: boolean;
  id: string;
  onLoad?: Function;
  options?: any;
  series: any[];
  width?: string | number;
};

export const Bar = (props: BarProps) => {
  const [loaded, setLoaded] = useState(false);
  const { className, colour, id, onLoad, options: overwrite, series, width } = props;
  const height: any = props?.height;

  const windowAvailable = typeof window !== `undefined`;
  // @ts-ignore
  const ApexCharts = window?.ApexCharts;

  let options: any = { ...baseOptions };

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `450px 0px 0px 0px`,
    triggerOnce: true,
  });

  // Will force the state to be loaded if no method call from ApexCharts
  useEffect(() => {
    const timeoutID =
      windowAvailable &&
      window.setTimeout(() => {
        setLoaded(true);
      }, 1000);

    return () => windowAvailable && window.clearTimeout(timeoutID);
  }, [inView, windowAvailable]);

  useEffect(() => {
    if (ApexCharts && overwrite?.xaxis?.categories) {
      try {
        ApexCharts.exec(id, `updateOptions`, {
          xaxis: {
            categories: overwrite?.xaxis?.categories,
            labels: {
              format: `MMM 'yy`
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [ApexCharts, id, overwrite.xaxis.categories]);

  if (overwrite) options = deepMerge(options, overwrite);
  if (id) options.chart.id = id;
  if (colour) options.colors = [colour];
  if (height) options.chart.height = height;

  const customYLabelFormat = overwrite?.yaxis?.labels?.formatter;
  if (!customYLabelFormat) options.yaxis.labels.formatter = val => val?.toFixed(0);

  // Run loaded function once
  useEffect(() => {
    if (onLoad) onLoad();
  }, [loaded, onLoad]);

  return (
    <div className={className} ref={ref}>
      {!loaded && <Skeleton height={height} />}
      <LoadableChart height={loaded ? height : 1} options={options} series={series} type="bar" width={width} />
    </div>
  );
};

Bar.defaultProps = {
  height: 350,
  full: false,
  width: `100%`,
};

export default Bar;

// import react-apexcharts here
const LoadableChart = loadable(() => import(`../../../../../../../node_modules/react-apexcharts/src/react-apexcharts`));

import { fetchAPI } from 'particles';

type UpdatePageRevisionParameters = {
  id: ArgID;
  assignedTo?: ArgID;
  previousAssignedTo?: ArgID;
  contentBlocks?: ArgContentBlocks;
  keywords?: ArgKeywords;
  name?: ArgName;
  pageType?: ArgPageType;
  preferredCategories?: ArgID[];
  status?: ArgStatus;
  title?: string;
  tags?: ArgTags;
  url?: ArgURL;
  locked?: ArgEditLockled;
  _with?: ArgWith;
};

export const callUpdatePageRevision = async (
  parameters: UpdatePageRevisionParameters,
): Promise<{ data: PageRevision }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `pagerevisions` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdatePageRevision;

import * as React from 'react';
import { useReducer } from 'react';
import moment from 'moment';

import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';

// Particles
import { deepMerge } from 'corigan';

// Local State Handlers
import KRContext from './krContext';
import krReducer from './krReducer';

import defaultState from './defaultState';

declare type KRStateProps = {
  children: React.ReactNode;
  initialState?: any;
};

export const KRState = (props: KRStateProps) => {
  const { releaseVersion } = getRelease();

  // Create global state provider to handle state and reducer dispatch events
  const { children, initialState } = props;
  let startState = defaultState;
  if (initialState) startState = deepMerge(startState, initialState);

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = (key: string): string => `version=${releaseVersion}&key=krConfig${key}`;

  const localDateStart = localStorageRead(buildKey(`ChartDateStart`));
  const localDateEnd = localStorageRead(buildKey(`ChartDateEnd`));

  if (localDateStart) startState.chartDateStart = moment(localDateStart);
  if (localDateEnd) startState.chartDateEnd = moment(localDateEnd);

  const [state, dispatch] = useReducer(krReducer, startState);
  const value = { state, dispatch };

  return <KRContext.Provider value={value}>{children}</KRContext.Provider>;
};

export default KRState;

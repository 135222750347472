import styled from 'styled-components';
import { hexToRGB } from 'helpers';
import { shouldBeDark } from 'particles';

const handleHex = (hex, opaque = true) => {
  const rgb = hexToRGB(hex);
  return `rgba(${rgb}, ${opaque ? `0.05` : `0.3`})`;
};

const chipBackground = (props, opaque = true) => {
  // Fallback value if we can't get access to props
  if (!props || !props.theme || !props.theme.primary) return `var(--white)`;

  // If no variant is specified, return the primary colour in our theme
  if (!props.variant) return handleHex(props.theme.primary, opaque);

  if (shouldBeDark()) return handleHex(props.theme.black, false);

  // Dynamically determine the background colour based on props
  switch (props.variant) {
    case `plain`:
      return handleHex(props.theme.grey800, opaque);
    case `primary`:
      return handleHex(props.theme.primary, opaque);
    case `secondary`:
      return handleHex(props.theme.secondary, opaque);
    case `tertiary`:
      return handleHex(props.theme.tertiary, opaque);
    case `quaternary`:
      return handleHex(props.theme.green800, opaque);
    case `quinary`:
      return handleHex(props.theme.quinary, opaque);
    case `senary`:
      return handleHex(props.theme.senary, opaque);
    case `green`:
      return handleHex(props.theme.green800, opaque);
    case `red`:
      return handleHex(props.theme.red, opaque);
    case `orange`:
      return handleHex(props.theme.orange, opaque);
    case `yellow`:
      return handleHex(props.theme.yellow, opaque);
    default:
      return handleHex(props.theme.primary, opaque);
  }
};

const chipColour = props => {
  // Fallback value if we can't get access to props
  if (!props || !props.theme || !props.theme.primary) return `blue`;

  // If no variant is specified, return the white colour
  if (!props.variant) return `var(--primary)`;

  if (shouldBeDark()) return `var(--white)`;

  // Dynamically determine the background colour based on props
  switch (props.variant) {
    case `plain`:
      return `var(--grey800)`;
    case `primary`:
      return `var(--primary)`;
    case `secondary`:
      return `var(--secondary)`;
    case `tertiary`:
      return `var(--tertiary)`;
    case `quaternary`:
      return `var(--green800)`;
    case `quinary`:
      return `var(--quinary)`;
    case `senary`:
      return `var(--senary)`;
    case `green`:
      return `var(--green800)`;
    case `red`:
      return `var(--red)`;
    case `orange`:
      return `var(--orange)`;
    case `yellow`:
      return `var(--yellow800)`;
    default:
      return `var(--primary)`;
  }
};

export const StyledChip = styled.span`
  align-items: center;
  border-radius: 500px;
  display: inline-flex;
  justify-content: center;
  padding: 4px 8px;

  background-color: ${props => chipBackground(props)};
  border: 1px solid;
  border-color: ${props => chipBackground(props, false)};
  color: ${props => chipColour(props)};
  font-size: 12px;
  font-weight: ${props => (props?.bold ? `bold` : `normal`)};
  white-space: nowrap;

  svg {
    height: 18px;

    fill: ${props => chipColour(props)};
  }
`;

export const StyledChipLink = styled(StyledChip).attrs({
  as: `span`,
})`
  padding: 0;

  background-color: none;

  a {
    border-radius: 500px;
    padding: 4px 8px;

    background-color: ${props => chipBackground(props)};
    text-decoration: none;
    transition: 0.2s background-color ease, 0.2s color ease, 0.2s background-color ease;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => chipBackground(props, false)};
      color: var(--white);
    }
  }
`;

export const StyledChipLinkButton = styled.span`
  font-weight: ${props => (props?.bold ? `bold` : `normal`)};

  a,
  .chip__wrapper {
    align-items: center;
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    padding: 4px 8px;

    background-color: ${props => chipBackground(props)};
    border: 1px solid;
    border-color: ${props => chipBackground(props)};
    color: ${props => chipColour(props)};
    font-size: 12px;
    text-decoration: none;
    transition: 0.2s background-color ease, 0.2s color ease;
    white-space: nowrap;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => chipBackground(props, false)};
      color: var(--white);

      button {
        background-color: var(--white);
        color: ${props => chipColour(props)};
      }
    }
  }

  .chip__wrapper {
    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => chipBackground(props)};
      color: ${props => chipColour(props)};
    }

    svg {
      margin-bottom: -7px;
    }

    button {
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: var(--white);
        color: ${props => chipColour(props)};
      }
    }
  }

  button {
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    border-radius: 500px;
    margin-left: 4px;

    background-color: ${props => chipBackground(props)};
    border: 1px solid;
    border-color: ${props => chipBackground(props)};

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => chipBackground(props, false)};
    }

    &::before {
      border-radius: 500px;
    }
  }
`;

export const StyledChipButton = styled(StyledChip).attrs({
  as: `button`,
})`
  font-weight: ${props => (props?.bold ? `bold` : `initial`)};
  text-decoration: none;
  text-transform: initial;
  margin: 0px;

  &::before {
    display: none;
  }

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    background-color: ${props => chipBackground(props, false)};
  }

  .chip__wrapper {
    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => chipBackground(props)};
      color: ${props => chipColour(props)};
    }

    svg {
      margin-bottom: -7px;
    }

    button {
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: var(--white);
        color: ${props => chipColour(props)};
      }
    }
  }

  button {
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    border-radius: 500px;
    margin-left: 4px;

    background-color: ${props => chipBackground(props)};
    border: 1px solid;
    border-color: ${props => chipBackground(props)};

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      background-color: ${props => chipBackground(props, false)};
    }

    &::before {
      border-radius: 500px;
    }
  }
`;

export default StyledChip;

import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import useFirebase from './firebase/useFirebase';

import { GlobalStyle } from 'particles';
import { ThemeDefault } from 'particles';

import { ApplicationState } from 'particles';
import { CRState } from 'particles';
import { KRState } from 'particles';
import { OMState } from 'particles';
import { RBState } from 'particles';

type ApplicationWrapperProps = {
  children: any;
};

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const isDevelopment = process.env.NODE_ENV === `development`;

const ApplicationWrapper = ({ children }: ApplicationWrapperProps) => {
  const firebase = useFirebase();

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ApplicationState firebase={firebase}>
        <OMState>
          <CRState>
            <KRState>
              <RBState>
                <ThemeProvider theme={ThemeDefault}>
                  <React.Fragment>
                    <GlobalStyle />
                    {children}
                    {isDevelopment && <ReactQueryDevtools initialIsOpen />}
                    <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={true}
                      newestOnTop={false}
                      limit={5}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </React.Fragment>
                </ThemeProvider>
              </RBState>
            </KRState>
          </CRState>
        </OMState>
      </ApplicationState>
    </ReactQueryCacheProvider>
  );
};

export default ApplicationWrapper;

import * as React from 'react';
import { dateShort } from 'corigan';

import StyledSEOPreview from './seo-preview.styles';

type SEOPreviewProps = {
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  description: string;
  title: string;
  /**
   * The HTML heading element rendered, default (h4)
   */
  titleLevel?: HeadingLevels;
  updatedAt?: string;
};

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const SEOPreview: React.FC<SEOPreviewProps> = (props: SEOPreviewProps) => {
  const { className, description, title, titleLevel, updatedAt } = props;

  const HeadingTag = `h${titleLevel}` as HeadingTag;

  let classList = `seo-preview`;
  if (className) classList += ` ${className}`;

  if (!title) return null;

  return (
    <StyledSEOPreview className={classList}>
      <HeadingTag>{title}</HeadingTag>
      {updatedAt && <time className="seo-preview__date">Updated at: {dateShort(updatedAt)}</time>}
      {description && <p>{description}</p>}
      {!description && <p>No meta description has been found for this page.</p>}
    </StyledSEOPreview>
  );
};

// Default prop values
SEOPreview.defaultProps = { titleLevel: 4 };

export default SEOPreview;

import * as React from 'react';

import StyledButton, { StyledButtonWrapper } from './button.styles';

import { Link } from 'atoms';

type ButtonProps = {
  /**
   * Accepts a Hexadecimal or RGBA string to override default background colours
   */
  background?: string;
  /**
   * The innercontent of the Button element
   */
  children?: any;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * Used to disable/enable user interaction when processing events
   */
  disabled?: boolean;
  download?: boolean;
  /**
   * Optional 'href' prop to link the button to a page or action.
   * Enabling this prop will swap out the base HTML element from 'button'
   * to 'a' but maintain consistent styles.
   */
  href?: string;
  small?: boolean;
  /**
   * When used in combination with 'href' prop, will allow the link associated
   * with the button to open in a new tab
   */
  target?: string;
  /**
   * Defines the type of button (e.g. 'button', 'reset', 'submit')
   */
  type?: string;
  /**
   * Predefined variations of the button component
   */
  variant?: 'hollow' | 'primary' | 'secondary' | 'green';
} & React.HTMLProps<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { background, children, className } = props;
  const { disabled, href, small, variant } = props;

  let classList = ``;
  if (variant) classList += `button--${variant}`;
  if (className) classList += ` ${className}`;

  // Determines SVG position
  let count = 0;
  let iconPosition = 0;
  React.Children.map(children, (child, index) => {
    const { _owner, type } = child;
    if (!type) return;
    if (!_owner) count++;

    const { displayName, name } = type;
    const label = displayName || name;

    const alreadyGotSVG = iconPosition !== 0;
    if (alreadyGotSVG) return;
    if (!label) return;

    const isSVG = label.toLowerCase().includes(`svg`);
    if (isSVG) iconPosition = index + 1;
  });

  const styleProps = {
    background,
    count,
    disabled,
    iconPosition,
    small,
    variant,
  };

  const data = { ...props };
  delete data.children;

  if (!href)
    return (
      <StyledButton {...styleProps} {...data} className={classList}>
        {children}
      </StyledButton>
    );

  return (
    <StyledButtonWrapper {...styleProps}>
      <Link {...data} className={classList}>
        {children}
      </Link>
    </StyledButtonWrapper>
  );
};

Button.defaultProps = {
  children: `Button text`,
  variant: `primary`,
};

export { default as ButtonReset } from './button.reset';
export default Button;

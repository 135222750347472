import styled from 'styled-components';

const StyledEditorBlocksEditor = styled.div`
  .editor-blocks__block__wrapper {
    padding: 20px;

    background-color: var(--white);
    border: 1px solid var(--black);
    border-radius: 2px;

    + div {
      margin-top: 8px;
    }
  }

  .editor-blocks__block__title {
    margin-bottom: 8px;

    font-weight: bold;
    text-transform: uppercase;
  }
`;

export default StyledEditorBlocksEditor;

import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Error } from 'corigan';
import { Page } from 'corigan';

// Local components
import RequestError from './partials/error';
import Missing from './partials/missing';
import Request from './partials/request';

// API
import { callGetDomains } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`domains:read`]}>
    <DomainContents />
  </ProtectedRoute>
);

type DomainProps = {};

const DomainContents: React.FC<DomainProps> = (props: DomainProps) => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  return (
    <Page application="portal" pageTitle="Site">
      {id && <DomainInfo id={id} />}
      {!id && <Missing />}
    </Page>
  );
};

type DomainInfoProps = {
  id: string;
};


const DomainInfo: React.FC<DomainInfoProps> = (props: DomainInfoProps) => {
  const { id } = props;
  const where: ArgWhere = `[_id][eq]=${id}`;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`domains:read`] });

  const { data: res, error, isLoading: loading } = useQuery([`callGetDomains`, { where }], callGetDomains, {
    enabled: canRead,
  });
  const data: Domain[] = res?.data;

  if (!canRead) return <Error error="You do not have permission to access Sites data" />;
  if (error) return <RequestError error={error} id={id} />;
  return <Request data={data} loading={loading} />;
};

export default Wrapper;

import moment from 'moment';

declare type getWeeksBetweenOutput = {
  dates: string[];
  numberWeeks: number;
};

export const getWeeksBetween = (
  startDate: moment.Moment | string,
  endDate: moment.Moment | string,
  offset?: boolean,
): getWeeksBetweenOutput => {
  const start: moment.Moment = moment(startDate);
  const end: moment.Moment = moment(endDate);

  const steps: number = Math.abs(start.diff(end, `days`));

  let numberWeeks: number = Math.ceil(steps / 7);
  if (offset) numberWeeks++;

  const datetimestamps: string[] = Array.from({ length: numberWeeks }, (_, i) => {
    const addTo: number = 7 * i;
    const weekDate: moment.Moment = moment(start).add(addTo, `days`);
    const dateString: string = weekDate.toISOString();
    return dateString;
  });

  const formattedDate = s => moment(s).format(`YYYY-MM-DD[T]HH:mm:ss`);
  const dates: string[] = datetimestamps.map(formattedDate);

  return { dates, numberWeeks };
};

export default getWeeksBetween;

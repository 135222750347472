import styled from 'styled-components';
import { shouldBeDark } from 'particles';

const StyledSkeleton = styled.tr`
  height: var(--height);
  width: 100%;

  background: ${() =>
    shouldBeDark()
      ? `linear-gradient(100deg, var(--grey700) 30%, var(--black) 50%, var(--grey700) 70%)`
      : `linear-gradient(100deg, var(--grey100) 30%, var(--offWhite) 50%, var(--grey100) 70%)`};

  background-size: 400%;
  color: transparent;

  animation: loading 1.5s ease-in-out infinite;

  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @keyframes loading-odd {
    0% {
      background-position: 90% 50%;
    }
    100% {
      background-position: -10% 50%;
    }
  }

  &.row-skeleton--even {
    animation: loading-odd 1.5s ease-in-out infinite;
  }
`;

export default StyledSkeleton;

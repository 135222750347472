import styled from 'styled-components';

const StyledBreadcrumbs = styled.header`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .breadcrumbs__back {
    margin-bottom: 16px;
    margin-right: auto;
  }

  .breadcrumbs__buttons {
    display: flex;
    margin-top: 16px;

    > * + * {
      margin-left: 8px;
    }
  }

  .breadcrumbs__contents {
    align-items: flex-start;
    display: flex;
    width: 100%;
  }

  .breadcrumbs__column + .breadcrumbs__column {
    flex-shrink: 0;
    margin-left: auto;
    padding-left: 48px;

    *:first-child {
      margin-top: 0;
    }
  }

  .breadcrumbs__links {
    margin-bottom: 8px;

    a {
      text-decoration: none;

      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    a + a {
      margin-left: 16px;
      position: relative;

      &::before {
        content: '/';
        left: -12px;
        position: absolute;
        top: -3px;

        pointer-events: none;
      }
    }
  }

  .breadcrumbs__paragraphs {
    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

export default StyledBreadcrumbs;

import * as React from 'react';
import { useContext } from 'react';

// Particles
import { KRContext } from 'corigan';

// Components
import { Card } from 'corigan';

// Local Particles
import StyledStats from './stats.styles';

declare type KeywordStatsProps = {};

const KeywordStats: React.FC<KeywordStatsProps> = (props: KeywordStatsProps) => {
  const context: any = useContext(KRContext);
  const state: any = context?.state;
  const volume: any[] = state?.volume;

  const keyword: Keyword = state?.keyword;

  const csp: number = keyword?.currentSerpPosition;
  const wasZero: boolean = csp === 0;
  const was9999: boolean = csp === 9999;

  let position: null | string = null;
  if (csp) position = String(csp);
  if (!csp || wasZero || was9999) position = `100+`;

  const hasVolumes: boolean = volume?.length > 0;
  let keywordVolume: string | null = null;

  // Get the last item in the array of values
  if (hasVolumes) {
    const lastIndex: number = volume?.length - 1;
    const lastItem: any = volume[lastIndex];
    keywordVolume = lastItem?.search_volume;
  }

  if (keywordVolume) keywordVolume = keywordVolume.toLocaleString();
  if (!keywordVolume) keywordVolume = `-`;

  // If no position set, skip keyword rank
  const content: string = position || keywordVolume;
  if (!content) return null;

  return (
    <Card animate={true} minHeight={false}>
      <StyledStats>
        {position && (
          <div className="keyword__stat">
            <h2>Current Serp Position:</h2>
            <h3>{position}</h3>
          </div>
        )}
        {keywordVolume && (
          <div className="keyword__stat">
            <h2>Search Volume:</h2>
            <h3>{keywordVolume}</h3>
          </div>
        )}
      </StyledStats>
    </Card>
  );
};

export default KeywordStats;

import * as React from 'react';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { default as loadable } from '@loadable/component';

import { deepMerge } from 'corigan';

import baseOptions from './options.json';

import StyledChartRadar from './radar.styles';

type ChartRadarProps = {
  height?: string | number;
  id: string;
  /**
   * Allows for base options JSON configuration to be overwritten with any
   * values defined in a JSON object passed to the 'options' prop.
   */
  options?: any;
  /**
   * Accepts an array of series values, used to plot data on an Apex Chart
   */
  series: Array<any>;
  width?: string | number;
};

type ChartOptions = {
  chart?: any;
  colors?: string[];
  fill?: any;
  legend?: any;
  markers?: any;
  stroke?: any;
  xaxis?: any;
  yaxis?: any;
};

export const ChartRadar = (props: ChartRadarProps) => {
  const { id, height, options: overwrite, series, width } = props;

  // @ts-ignore
  const ApexCharts = window?.ApexCharts;

  let options: ChartOptions = { ...baseOptions };

  if (overwrite) options = deepMerge(options, overwrite);
  if (id) options.chart.id = id;
  if (height) options.chart.height = height;
  if (width) options.chart.width = width;

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `450px 0px 0px 0px`,
    triggerOnce: true,
  });

  useEffect(() => {
    if (ApexCharts && overwrite?.xaxis?.categories) {
      try {
        ApexCharts.exec(id, `updateOptions`, {
          xaxis: {
            categories: overwrite?.xaxis?.categories,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [ApexCharts, id, overwrite.xaxis.categories]);

  const classList = `chart chart--radar`;

  return (
    <div ref={ref}>
      <StyledChartRadar className={classList} {...props}>
        {inView && <LoadableChart height={height} options={options} series={series} type="radar" width={width} />}
      </StyledChartRadar>
    </div>
  );
};

ChartRadar.defaultProps = {
  height: 350,
  width: `100%`,
};

export default ChartRadar;

// import react-apexcharts here
const LoadableChart = loadable(() => import(`../../../../../../../node_modules/react-apexcharts/src/react-apexcharts`));

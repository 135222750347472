export const searchWithFilters = state => {
  const { apiFunctions, filters } = state;
  const { setPage, setWhere } = apiFunctions;

  const hasFilters = filters?.length > 0;
  if (!hasFilters) setPage(undefined);
  if (!hasFilters) setWhere(undefined);
  if (!hasFilters) return;

  const filterStringArr = filters.map((filter, i) => {
    const isFirst: boolean = i === 0;

    const { condition, field, value, or } = filter;
    let conditionString: string = ``;

    // If not the first filter, then we need to append it
    // as an additional filter whereObject[field][condition] = value;
    if (!isFirst) conditionString += `&where`;

    const orString = or ? `[or]` : ``;
    conditionString += `${orString}[${field}][${condition}]=${value}`;
    return conditionString;
  });

  const filterString = filterStringArr.join(``);
  const whereValue = filterString !== `` ? filterString : undefined;

  setPage(undefined);
  setWhere(whereValue);
};

export default searchWithFilters;

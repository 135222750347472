import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { QueryFunction } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Table } from 'corigan';

type OMMastersheetTableProps = {
  id: string;
  whereProtectedType: string,
  initialWhere?: ArgWhere;
  initialWith?: ArgWith;
  initialOrderBy?: ArgOrderBy,
  remember: boolean,
  queryFunction: QueryFunction<any>,
  queryName: string,
  groupBy: ArgGroupBy,
  columns: { [key: string]: any }[],
  generateRows: Function,
};

export const OMMastersheetTable = (props: OMMastersheetTableProps) => {
  const { id, whereProtectedType, initialWhere, initialWith, initialOrderBy, remember, queryFunction, queryName, groupBy, columns, generateRows } = props;
  const [mounted, setMounted] = useState<boolean>(false);
  const queryParameters = useQueryParameters();

  const parsedWhere: string = React.useMemo(() => {
    const whereQueries: string[] = [];

    Object.keys(queryParameters).forEach((query: string) => {
      if (query.includes(`where`)) {
        const queryValues: string | string[] = queryParameters[query];
        const queryValuesArray: string[] = Array.isArray(queryValues) ? queryValues : [queryValues];

        queryValuesArray.forEach((queryValue: string) => {
          const whereQuery: string = `${query}=${queryValue}`;

          whereQueries.push(whereQuery);
        });
      }
    });

    const joinedWhere = whereQueries.join(`&`);
    const finalWhere = joinedWhere.replace(`where`, ``);

    return finalWhere;
  }, [queryParameters]);

  const startingWhere = React.useMemo(() => {
    if (initialWhere && !parsedWhere) return initialWhere;
    if (!initialWhere && parsedWhere) return parsedWhere;
    if (!initialWhere && !parsedWhere) return ``;

    return initialWhere + `&` + parsedWhere;
  }, [initialWhere, parsedWhere]);

  const { releaseVersion } = getRelease();
  const { userHasPermission: canReadPages } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=omPageRevisionsMastersheet-${id}-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, id, releaseVersion],
  );

  // Create initial API arguments
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;

  const getLocalOrderBy: boolean = remember && Boolean(localStorageRead(buildKey(`OrderBy`)));
  const getLocalPage: boolean = remember && Boolean(localStorageRead(buildKey(`Page`)));
  const getLocalPerPage: boolean = remember && Boolean(localStorageRead(buildKey(`PerPage`)));
  const getLocalWhere: boolean = remember && Boolean(localStorageRead(buildKey(`Where`)));

  // Read local storage values
  const localOrderBy: ArgOrderBy = getLocalOrderBy ? localStorageRead(buildKey(`OrderBy`)) : initialOrderBy;
  const localPage: ArgPage = getLocalPage ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = getLocalPerPage ? localStorageRead(buildKey(`PerPage`)) : initialPerPage;
  const localWhere: ArgWhere =
    getLocalWhere && !(startingWhere && startingWhere !== ``) ? localStorageRead(buildKey(`Where`)) : startingWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }

      return newValue;
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = (value: any) => setStateValue(`Page`, value);
  const setPerPage = (value: any) => setStateValue(`PerPage`, value);
  const setWhere = (value: any) => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const whereProtected: ArgWhere = `${whereProtectedType}${domainActive?.id}`;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const args = { groupBy, orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = canReadPages && hasDomain;

  const { data: res, error, isLoading: loading } = useQuery([queryName, args], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination = res?.pagination;

  const hasData: boolean = data?.length > 0;
  const items = hasData ? generateRows(data) : [];

  return (
    <React.Fragment>
      {mounted && (
        <Table
          apiArgs={args}
          apiDetails={apiDetails}
          apiFunctions={apiFunctions}
          allowFilters={true}
          collectionType="page"
          columns={columns}
          csvTitle="pages"
          error={error}
          filtersToHide={[`domain`]}
          id={`optimisation-manager-${id}`}
          items={items}
          loading={loading}
          pagination={pagination}
          selectable={true}
          exportArgs={args}
          exportRoute={`pagerevisions`}
        />
      )}
    </React.Fragment>
  );
};

export default OMMastersheetTable;

import * as React from 'react';

import { Head } from 'corigan';

import { Footer, Header, Sidebar, Wrapper } from 'corigan';
import { ModalWrapper } from 'corigan';

type PageProps = {
  application: ApplicationName;
  pageTitle?: string;
  children?: React.ReactNode;
  simple?: boolean;
};

const Page: React.FC<PageProps> = (props: PageProps) => {
  const { application, simple, pageTitle } = props;

  return (
    <Wrapper>
      <Head application={application} pageTitle={pageTitle} />
      {!simple && <Sidebar application={application} />}
      {!simple && (
        <div className="wrapper__contents">
          <PageContents {...props} />
        </div>
      )}
      {simple && <PageContents {...props} />}
    </Wrapper>
  );
};

type PageContentsProps = {
  application: ApplicationName;
  children?: React.ReactNode;
  simple?: boolean;
};

const PageContents: React.FC<PageContentsProps> = (props: PageContentsProps) => {
  const { application, children, simple } = props;

  return (
    <React.Fragment>
      {!simple && <Header application={application} />}
      <main id="content">{children}</main>
      {!simple && <Footer application={application} />}
      {!simple && <ModalWrapper />}
    </React.Fragment>
  );
};

Page.defaultProps = {
  application: `portal`,
  simple: false,
};

export default Page;

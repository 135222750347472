import * as React from 'react';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Mastersheet specific imports
import { OMMastersheetTable } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';

// Local Partials
import { columns } from '../data/pages/columns';
import { generateRows } from '../data/pages/generateRows';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`pages:read`]}>
    <OptimisationManagerCategoryIssues />
  </ProtectedRoute>
);

// const initialWhere: ArgWhere = `&where[page.crawlResponse.responseCode][gte]=300&where[status][eq]=Live`;
const initialWhere: ArgWhere = undefined;
const initialWith: ArgWith = [
  `assignedTo`,
  `currentRevision`,
  `categories`,
  `categories.category`,
  `preferredCategories`,
  `page`,
  `pageType`,
  `updatedBy`,
];

type OMCategoryIssuesProps = {};

const OptimisationManagerCategoryIssues = (props: OMCategoryIssuesProps) => {
  return (
    <Page application="optimisation-manager" pageTitle="Category Issues">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.optimisation_manager}>Dashboard</Link>
              <h1>Category Issues</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <OMMastersheetTable
                id="category-issues"
                whereProtectedType="[domain][eq]="
                initialWhere={initialWhere}
                initialWith={initialWith}
                remember={false}
                queryFunction={callGetManyPageRevisions}
                queryName="callGetManyPageRevisions"
                groupBy="page"
                columns={columns}
                generateRows={generateRows}
              />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

export default Wrapper;

import { fetchAPI } from 'particles';

declare type GetReportDomainSERPArgs = {
  domain: ArgID;
  position: number;
};

declare type DataObject = {
  date: string;
  total: number;
};

declare type Response = {
  data: DataObject[];
};

export const callGetReportDomainSERP = async (reqKey, parameters: GetReportDomainSERPArgs): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/domain/serp` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportDomainSERP;

import styled from 'styled-components';
import { shouldBeDark } from 'particles';

const iconHeight = props => {
  if (!props?.variant) return `16px`;
  const variant = props.variant;
  switch (variant) {
    case `tiny`:
      return `8px`;
    case `small`:
      return `12px`;
    case `default`:
      return `16px`;
    case `large`:
      return `20px`;
    case `largest`:
      return `24px`;
  }
};

export const StyledNewTab = styled.span`
  display: inline-block;

  a {
    align-items: center;
    display: flex;
    justify-content: center;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      svg {
        fill: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--blue200)`)};
      }
    }
  }

  svg {
    height: ${props => iconHeight(props)};

    fill: 1px solid ${() => (shouldBeDark() ? `var(--white)` : `var(--blue)`)};
    transition: 0.2s fill ease;
  }
`;

export default StyledNewTab;

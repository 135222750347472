export const addSingleCache = (previousCollection, newItem) => {
  // Are there keywords in the cache?
  const data = previousCollection?.data;
  if (!data) return previousCollection;

  // If there are no keywords in the cache, create a new array with the new keyword
  const hasExistingData = data?.length > 0;
  if (!hasExistingData) return { ...previousCollection, data: [newItem] };

  // Add the new collection item to the data array
  const newData = [...data, newItem];

  return {
    ...previousCollection,
    data: newData,
  };
};

export default addSingleCache;

import { css } from 'styled-components';

const code = css`
  pre {
    background: var(--black);
    color: var(--white);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    hyphens: none;
    line-height: 1.5;
    tab-size: 4;
    text-align: left;
    text-shadow: 0 -0.1em 0.2em var(--black);
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;

    padding: 1em;
    margin: 32px 0;
    overflow: auto;
    border: 0.3em solid var(--black);
    border-radius: 0.5em;
    box-shadow: 1px 1px 0.5em var(--black) inset;

    ::-webkit-scrollbar {
      background-color: var(--grey300);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--grey600);
      border: 2px solid var(--grey600);
    }
  }

  code {
    background: none;
    color: var(--white);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    hyphens: none;
    line-height: 1.5;
    tab-size: 4;
    text-align: left;
    text-shadow: 0 -0.1em 0.2em var(--black);
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;

    ::-webkit-scrollbar {
      background-color: var(--grey300);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--grey600);
      border: 2px solid var(--grey600);
    }
  }
`;

export default code;

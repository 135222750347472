import styled from 'styled-components';

const avatarSize = props => {
  if (!props.size) return `64px`;

  switch (props.size) {
    case `xs`:
      return `16px`;
    case `sm`:
      return `32px`;
    case `md`:
      return `64px`;
    case `lg`:
      return `96px`;
    case `xl`:
      return `128px`;
    case `xxl`:
      return `192px`;
    default:
      return `64px`;
  }
};

const avatarSpacing = props => {
  if (!props.y) return `16px 0`;

  switch (props.y) {
    case `xs`:
      return `4px 0`;
    case `sm`:
      return `8px 0`;
    case `md`:
      return `16px 0`;
    case `lg`:
      return `24px 0`;
    case `xl`:
      return `32px 0`;
    case `xxl`:
      return `48px 0`;
    default:
      return `16px 0`;
  }
};

const AvatarStyles = styled.div`
  height: ${props => avatarSize(props)};
  margin: ${props => avatarSpacing(props)};
  width: ${props => avatarSize(props)};

  img {
    border-radius: 50%;
    height: ${props => avatarSize(props)};
    width: ${props => avatarSize(props)};

    object-fit: cover;
    object-position: center;
  }
`;

export default AvatarStyles;

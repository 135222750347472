import { useEffect } from 'react';
import { windowAvailable } from 'corigan';

export const useWindowEvent = (event, callback, dependencies) => {
  useEffect(() => {
    if (!windowAvailable()) return;
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useWindowEvent;

import * as React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';

// Particles
import { ApplicationContext } from 'corigan';
import { addSingleCache } from 'corigan';
import { ProtectedRoute } from 'corigan';
import ROUTES from 'routes';
import { windowAvailable } from 'corigan';
import { useHasPermissions } from 'corigan';

// Atoms
import { Field } from 'corigan';
import { Link } from 'corigan';

// Molecules
import { Breadcrumbs } from 'corigan';
import { Card } from 'corigan';
import { Error } from 'corigan';
import { Info } from 'corigan';

// Organisms
import { Col } from 'corigan';
import { Grid } from 'corigan';
import { Row } from 'corigan';

// Templates
import { Page } from 'corigan';

// API
import { callCreateTag } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.tags} requiredPermissions={[`tags:create`]}>
    <TagsCreateContent />
  </ProtectedRoute>
);

type CreateTagPageProps = {};

const TagsCreateContent = (props: CreateTagPageProps) => {
  return (
    <Page application="portal" pageTitle="Create Tag">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <Link href={ROUTES.tags}>All Tags</Link>
              <h1>Create Tag</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card>
              <CreateTag />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

const successMessage = tag => {
  return `The tag "${tag}" has been successfully created in the system.`;
};

type FormErrors = {
  name?: string;
};

type FormValues = {
  name: string;
  domain: string;
};

const initialValues: FormValues = {
  name: ``,
  domain: ``,
};

const validate = (values: FormValues) => {
  const errors: FormErrors = {};
  const { name } = values;
  if (!name) errors.name = `Please specify a tag name`;
  return errors;
};

type CreateTagProps = {};

const CreateTag = (props: CreateTagProps) => {
  const [success, setSuccess] = useState(null);
  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = appContext?.state?.domainActive;

  const { userHasPermission: canCreate } = useHasPermissions({ requiredPermissions: [`tags:create`] });

  const [mutate, { error, isLoading: loading }] = useMutation(callCreateTag, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyTags`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const tagData: Tag = data?.data;
      // If createTag function was successful, get values from API response
      const name = tagData?.name;
      // Let the user know the tag has been created before redirect begins
      setSuccess(successMessage(name));
      navigate(ROUTES.tags);
      // Reset form values to show form has processed
      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`], (previousTags: APIResponse) =>
        addSingleCache(previousTags, tagData),
      );
      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { perPage: 1000, whereProtected }], (previousTags: APIResponse) =>
        addSingleCache(previousTags, tagData),
      );
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (windowAvailable() && !canCreate) window.alert(`You do not have permission to create a tag`);
      if (canCreate) await mutate({ ...values, ...{ domain: domainActive?.id } });
    },
    validate,
  });

  const { handleChange, handleSubmit, values } = formik;
  const { name } = values;

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  return (
    <form onSubmit={handleSubmit} aria-label="Create a Tag">
      <fieldset aria-busy={loading} disabled={loading}>
        {success && (
          <Info onClose={onClose} y="lg">
            {success}
          </Info>
        )}
        {error && <Error error={error}>{error}</Error>}
        <Field handleChange={handleChange} id="name" label="Name" required={true} value={name} />
        <button type="submit" disabled={loading}>
          Creat{loading ? `ing` : `e`} Tag
        </button>
      </fieldset>
    </form>
  );
};

export default Wrapper;

import * as React from 'react';
import moment from 'moment';
import StyledIssues, { StyledIssuesGroup } from './issues.styles';

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const chronologicalOrder = array => {
  let returnedArray = array.sort((a, b) => {
    const c = new Date(a.datetime);
    const d = new Date(b.datetime);
    return +c - +d;
  });
  returnedArray = returnedArray.reverse();
  return returnedArray;
};

type IssuesProps = {
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * JSON Array structure of issues events to cast to render function
   */
  items?: {
    current: 'ongoing' | 'resolved';
    datetime: string;
    info?: string;
    status: 'maintenance' | 'offline' | 'online';
  }[];
};

const Issues: React.FC<IssuesProps> = (props: IssuesProps) => {
  const { className, items } = props;

  let classList = `issues`;
  if (className) classList += ` ${className}`;

  const hasItems = items?.length > 0;
  if (!hasItems) return null;

  // Check that the array has valid dates
  const validItems = items.filter(item => item.datetime);
  const hasValid = validItems && validItems.length > 0;
  if (!hasValid) return null;

  // Group the items into nested arrays by date
  const chronological = chronologicalOrder(items);
  const datedItems = chronological.map(item => {
    const groupDate = moment(item.datetime).format(`DD-MM-YYYY`);
    return {
      ...item,
      groupDate,
    };
  });

  const groupedItems = groupBy(datedItems, `groupDate`);

  return (
    <StyledIssues className={classList}>
      {Object.keys(groupedItems).map((key, i) => {
        const group = groupedItems[key];
        const groupKey = key;
        const fragmentKey = `issues-group-${key}`;
        const current = group[0].current;
        const datetime = group[0].datetime;
        const status = group[0].status;
        const data = {
          current,
          datetime,
          status,
          group,
          groupKey,
          key: fragmentKey,
        };
        return <IssuesGroup key={data.key} {...data} />;
      })}
    </StyledIssues>
  );
};

type IssuesGroupProps = {
  current: 'ongoing' | 'resolved';
  datetime?: Date;
  group?: [];
  info?: string;
  status: 'maintenance' | 'offline' | 'online';
};

export const IssuesGroup: React.FC<IssuesGroupProps> = (props: IssuesGroupProps) => {
  const { current, datetime, status, group } = props;

  const startOfGroupDate = moment(datetime).startOf(`day`);
  const today = moment().startOf(`day`);
  const yesterday = moment().subtract(1, `days`).startOf(`day`);
  const isToday = startOfGroupDate.isSame(today, `day`);
  const isYesterday = startOfGroupDate.isSame(yesterday, `day`);
  const day = moment(datetime).format(`dddd`);
  const dayNum = moment(datetime).format(`Do`);
  const month = moment(datetime).format(`MMM`);

  let title = day;
  if (isToday) title = `Today`;
  if (isYesterday) title = `Yesterday`;

  return (
    <StyledIssuesGroup status={status} className="issues__group">
      <h3 className="issues__group__title">
        {title && <span className="issues__day">{title}</span>}
        {(month || dayNum) && (
          <div className="issues__group__meta">
            {current && <span className="issues__current">{current} - </span>}
            {dayNum && <span className="issues__dayNumber">{dayNum}</span>}
            {month && <span className="issues__month">{month}</span>}
          </div>
        )}
      </h3>
      <div className="issues__items">
        {group.map(item => {
          const { info, title } = item;
          return (
            <div className="issues__item" key={`${title}-${info}`}>
              {title && <h4 className="issues__item__title">{title}</h4>}
              {info && <p className="issues__item__info">{info}</p>}
            </div>
          );
        })}
      </div>
    </StyledIssuesGroup>
  );
};

// Default prop values
IssuesGroup.defaultProps = {
  current: `ongoing`,
  status: `maintenance`,
};

export default Issues;

import * as React from 'react';

function SvgListBulleted(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M2.2 10.1c-1.1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9 1.9-.8 1.9-1.9-.9-1.9-1.9-1.9zm0-7.6C1.1 2.5.3 3.3.3 4.4s.8 1.9 1.9 1.9 1.9-.8 1.9-1.9-.9-1.9-1.9-1.9zm0 15.2c-1.1 0-1.9.9-1.9 1.9 0 1 .9 1.9 1.9 1.9s1.9-.9 1.9-1.9c0-1-.9-1.9-1.9-1.9zM6 20.9h17.7v-2.5H6v2.5zm0-7.6h17.7v-2.5H6v2.5zM6 3.1v2.5h17.7V3.1H6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgListBulleted;

import React, { useContext } from 'react';

import Overlay from '../overlay';

import { ApplicationContext } from 'particles';

import { Check } from 'icons';

import { Link } from 'atoms';

type SupportProps = {
  showSupport: boolean;
  updateHeaderView?: Function;
};

const Support: React.FC<SupportProps> = (props: SupportProps) => {
  const { showSupport, updateHeaderView } = props;
  const context = useContext(ApplicationContext);

  const state = context?.state;
  const items = state?.support?.items;
  const more = state?.support?.more;
  const hasItems = items && items.length > 0;
  const hasMore = more && more.href && more.text;

  let className = `header__support`;
  if (showSupport) className += ` header__overlay--show`;

  return (
    <Overlay active={showSupport} className={className} updateHeaderView={updateHeaderView}>
      {hasItems &&
        items.map(item => (
          <Link className="support" href={item.href} key={`header-support-${item.href}`}>
            <Check />
            <span>{item.text}</span>
          </Link>
        ))}
      {!hasItems && (
        <div className="support">
          <Check />
          <span>No new support updates</span>
        </div>
      )}
      {hasMore && (
        <Link className="header__support__more" href={more.href}>
          {more.text}
        </Link>
      )}
    </Overlay>
  );
};

export default Support;


export const columns = [
    {
      align: `right`,
      dbKey: `keyword`,
      filter: true,
      sort: true,
      hide: false,
      label: `Keyword`,
      numeric: false,
      value: `keyword`,
      wrap: false,
      tooltip: `Compare Keyword against > Keyword Column > Keyword Research: A keyword chip in green links to keyword research and if it's red then it will take you to create keyword`,
    },
    {
      align: `left`,
      dbKey: `searchVolume`,
      filter: true,
      sort: true,
      hide: false,
      label: `Search Volume`,
      numeric: true,
      value: `searchVolume`,
      wrap: false,
      tooltip: `Average number of searches monthly for this keyword on Google from within the target location’`,
    },
    {
      align: `left`,
      dbKey: `compRank`,
      filter: true,
      sort: true,
      hide: false,
      label: `Their Rank`,
      numeric: true,
      value: `compRank`,
      wrap: false,
      tooltip: `The competitors ranking position on the SERP for this keyword. Click on the ranking position to view the ranking URL. If no position is displayed, this is because the competitor does not rank for that keyword.`,
    },
    {
      align: `left`,
      dbKey: `prefRank`,
      filter: true,
      sort: true,
      hide: false,
      label: `Your Pref URL Rank`,
      numeric: true,
      value: `prefRank`,
      wrap: false,
      tooltip: `The current ranking position on the SERP for your Preferred URL for this keyword. Click on the ranking position to view the Preferred URL. If the Preferred URL is not ranking for that keyword, it will display as ‘Not Ranking’`,
    },
    {
      align: `left`,
      dbKey: `topRank`,
      filter: true,
      sort: true,
      hide: false,
      label: `Your Top Rank`,
      numeric: true,
      value: `topRank`,
      wrap: false,
      tooltip: `Your top URL that is currently ranking in the highest position on the SERP. If the Top Ranking URL is the same as the Preferred URL, then the position will be the same as the Preferred URL. However, if Your Top Ranking URL is not the Preferred URL, then it will display the higher ranking URLs position. Click on the ranking position to view the Top Ranking URL`,
    },
    {
      align: `left`,
      dbKey: `comparePage`,
      href: true,
      shorten: true,
      filter: false,
      sort: false,
      hide: false,
      label: `Compare URL`,
      numeric: false,
      value: `comparePage`,
      wrap: false,
      tooltip: `Compare Your Ranking URLs against your competitor’s Ranking URLs. If your Top Ranking URL is different to Your Preferred URL, you will have the option to compare both URLs against the competitor`,
    },
    {
      align: `left`,
      dbKey: `briefPage`,
      href: true,
      shorten: true,
      filter: false,
      sort: false,
      hide: false,
      label: `Brief Page`,
      numeric: false,
      value: `briefPage`,
      wrap: false,
      tooltip: `View the highest pagerevision status for this page or create a page using this information`,
    },
  ];

import { css } from 'styled-components';

const searchCSS = css`
  .search__results {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      /* Button Reset CSS */
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: relative;
      width: auto;
      overflow: visible;

      background: transparent;
      border: none;
      box-shadow: none;
      color: inherit;
      font: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: normal;
      text-decoration: none;
      text-transform: inherit;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
    }

    a,
    button,
    p {
      border-radius: 0;
      display: flex;
      margin: 0;
      padding: 12px;
      width: 100%;

      background-color: var(--blue000);
      border: none;
      color: var(--primary);
      text-transform: initial;
    }

    a,
    button {
      transition: 0.2s background-color ease;
      text-decoration: none;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: var(--blue050);

        .result__image__wrapper {
          background-color: var(--blue200);
        }
      }
    }

    a + a,
    button + button {
      border-top: 1px solid var(--blue050);
    }

    .result__image__wrapper {
      border-radius: 50%;
      margin-right: 12px;
      max-width: 32px;
      width: 100%;

      background-color: var(--blue050);
      border-color: var(--blue300);
      transition: 0.2s background-color ease;
    }

    .result__initials {
      align-items: center;
      display: flex;
      height: 32px;
      justify-content: center;
      width: 32px;

      span {
        line-height: 1;
      }
    }

    .result__image {
      border-radius: 50%;
      display: block;
      height: 0;
      padding-top: 100%;
      position: relative;
      width: 100%;

      img,
      picture {
        border-radius: 50%;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        object-fit: cover;
      }
    }
  }
`;

export default searchCSS;

import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useFormik } from 'formik';
import { FormikProps } from 'formik';

// Atoms
import { Button } from 'corigan';

// Molecules
import { Error } from 'corigan';

// Particles
import { TableContext } from 'corigan';
import { isEqualObjects } from 'corigan';
import { capitalizeString } from 'corigan';
import { generateID } from 'corigan';

// Local Partials
import SelectedDelete from './../controls/selected/delete';
import SelectedTag from './../controls/selected/tag';
import SelectedUses from './../controls/selected/uses';
import TableControls from './../controls';
import FilterWrapper from './filters.styles';
import ManageFilter from './manage';
import { conditionOptions } from './data';

type FormErrors = {
  condition?: string;
  field?: string;
  value?: string;
};

type FilterValues = {
  id?: string;
  condition: APICondition;
  field: string;
  value: string;
  or: boolean;
};

export type FiltersProps = {
  apiArgs: {
    orderBy?: ArgOrderBy | undefined;
    page?: ArgPage | undefined;
    perPage?: ArgPerPage | undefined;
    where?: ArgWhere | undefined;
    _with?: ArgWith | undefined;
  };
}

const Filters: React.FC<FiltersProps> = (props: FiltersProps) => {
  const { apiArgs } = props;

  const context = useContext(TableContext);
  const dispatch = context?.dispatch;
  const state = context?.state;

  const { columns, filters: initialFilters, collectionType } = state;
  const filtersToHide: string[] = state?.filtersToHide ?? [];

  const isKeyword: boolean = collectionType === `keyword`;
  const isPage: boolean = collectionType === `page`;
  const isTag: boolean = collectionType === `tag`;
  const isDomain: boolean = collectionType === `domain`;
  const canDelete: boolean = isKeyword || isPage || isTag || isDomain;
  const canTag: boolean = isKeyword || isPage;
  const hasColumns: boolean = columns?.length > 0;
  const tableControls: boolean = hasColumns;
  const fieldOptions = hasColumns && columns.filter(({ filter }) => filter);

  // Array of condition options that support numeric values
  const numericConditions = React.useMemo(() => {
    if (!conditionOptions) return [];

    const filtered = conditionOptions.filter(f => f.numeric);
    return filtered;
  }, []);

  // Array of condition options that support string values
  const stringConditions = React.useMemo(() => {
    if (!conditionOptions) return [];

    const filtered = conditionOptions.filter(f => f.string);
    return filtered;
  }, []);

  const createBlankFilter = useCallback((id, or) => {
    const field = fieldOptions[0];
    const conditions = field?.numeric ? numericConditions : stringConditions;
    const condition = conditions[0];

    const blankFilter: FilterValues = {
      id,
      field: field?.dbKey,
      condition: condition?.value,
      value: ``,
      or,
    }

    return blankFilter;
  }, [fieldOptions, numericConditions, stringConditions])

  const initialFilterId = React.useMemo(() => generateID(), []);
  const initialBlankFilter: FilterValues = createBlankFilter(initialFilterId, false);
  const initialValues = React.useMemo(() => ({ [initialFilterId]: initialBlankFilter }), []);

  const [lastSearched, setLastSearched] = useState<any>(initialValues);

  const handleSearch = values => {
    const newFilters = [];

    const filterIds = Object.keys(values);
    if (filterIds.length !== 1 || values[filterIds[0]]?.value !== ``) {
      Object.keys(values).forEach(id => {
        newFilters.push(values[id]);
      });
    }

    setLastSearched(values);
    dispatch({ type: `filterAllUpdate`, value: newFilters });
  };

  const formik: FormikProps<any> = useFormik<any>({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSearch(values);
      setSubmitting(false);
    },
    validate: values => {
      const errors: FormErrors = {};

      // If only one filter remains and no value entered, allow submitting
      const filterIds = Object.keys(values);
      if (filterIds.length === 1 && values[filterIds[0]]?.value === ``) {
        return errors;
      }

      Object.keys(values).forEach((filterId, index) => {
        const { condition, field, value } = values[filterId];
        const filterNumber = index + 1;
        const fieldType = ` - ${capitalizeString(field)}` ?? ``;
        if (!condition) Object.assign(errors, { [filterId]: { condition: `Filter ${filterNumber}${fieldType}: A condition is required.` }});
        if (!field) Object.assign(errors, { [filterId]: { field: `Filter ${filterNumber}${fieldType}: A field is required.` }});
        if (!value) Object.assign(errors, { [filterId]: { value: `Filter ${filterNumber}${fieldType}: A value is required.` }});
      })
      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
  });
  const { values, errors, setFieldValue, handleSubmit, isSubmitting, handleReset } = formik;
  const hasErrors = Object.keys(errors).some(errorId => errors[errorId]?.value);
  const hasFiltersChanged = isEqualObjects(lastSearched, values);

  useEffect(() => {
    let newFilters = false;
    const newValues = { ...values };

    initialFilters.forEach(filter => {
      const { id } = filter;
      if (!values[id])  {
        newFilters = true;
        newValues[id] = filter;
        setFieldValue(id, filter);
      }
    })

    if (newFilters) {
      if (values[initialFilterId]?.value === ``) {
        delete newValues[initialFilterId];
        setFieldValue(initialFilterId, undefined);
      }
      setLastSearched(newValues);
    }
  }, [initialFilters]);

  const orFilters = [];
  const andFilters = [];
  Object.keys(values).forEach(filterId => {
    const filter = values[filterId];

    if (!filtersToHide.includes(filter.field)) {
      const filterType = filter?.or ? orFilters : andFilters;
      filterType.push(filterId);
    }
  });
  const hasAndFilters = Boolean(andFilters.length);
  const hasOrFilters = Boolean(orFilters.length);
  const hasMultipleOrFilters = Boolean(orFilters.length > 1);
  if (!hasAndFilters && hasOrFilters && !hasMultipleOrFilters) {
    orFilters.forEach(filterId => {
      const fieldKey = `${filterId}.or`;
      setFieldValue(fieldKey, false);
    });
  }

  const handleAddFilter = (or: boolean): void => {
    // If + OR is clicked with only one filter, change the first filter to OR
    if (or && andFilters.length === 1) {
      const andFilter = values[andFilters[0]];
      setFieldValue(andFilter.id, { ...andFilter, or: true });
    }

    // If + OR is clicked and there is more than one AND filter, and no current
    // OR filters, add two OR filters
    if (or && andFilters.length > 1 && !orFilters.length) {
      const newFilterId = generateID();
      const blankFilter: FilterValues = createBlankFilter(newFilterId, or);
      setFieldValue(newFilterId, blankFilter);
    }

    const newFilterId = generateID();
    const blankFilter: FilterValues = createBlankFilter(newFilterId, or);
    setFieldValue(newFilterId, blankFilter);
  };

  const handleDeleteFilter = (filterId: string): void => {
     const newValues = {...values}

    let newFilterValue: FilterValues | undefined = undefined;
    if (Object.keys(values).length === 1) {
      newFilterValue = createBlankFilter(filterId, false);
      newValues[filterId] = newFilterValue;
    } else {
      newFilterValue = undefined;
      delete newValues[filterId];
    }
    setFieldValue(filterId, newFilterValue);

    handleSearch(newValues);
  };

  const handleClearAllFilters = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    handleReset();

    setLastSearched(initialValues);
    dispatch({ type: `filterAllUpdate`, value: [] });
  };

  return (
    <React.Fragment>
      <FilterWrapper>
        <form onSubmit={handleSubmit}>
          <div className="filters__wrapper">
            <div className="filter__forms">
              <div>
                {hasAndFilters &&
                  <React.Fragment>
                    {andFilters.map((filterId, index) => {
                      const showLabels = index === 0;
                      const hideDelete = !hasOrFilters && andFilters.length === 1;
                      const lastFilter = index === andFilters.length - 1;
                      return (
                        <div key={filterId} className="form--horizontal">
                          <ManageFilter
                            id={filterId}
                            formik={formik}
                            hideDelete={hideDelete}
                            showLabels={showLabels}
                            lastFilter={lastFilter}
                            addOrButton={!hasOrFilters}
                            addAndButton
                            handleAddFilter={handleAddFilter}
                            handleDeleteFilter={handleDeleteFilter}
                          />
                          {(!lastFilter || hasOrFilters) &&
                            <div className="form--connector">
                              {lastFilter && <hr />}
                                <span>and</span>
                              {lastFilter && <hr />}
                            </div>}
                        </div>
                      );
                    })}
                  </React.Fragment> }
                {hasOrFilters &&
                  <React.Fragment>
                    {orFilters.map((filterId, index) => {
                      const showLabels = index === 0 && !hasAndFilters;
                      const hideDelete = !hasAndFilters && orFilters.length === 1;
                      const lastFilter = index === orFilters.length - 1;
                      return (
                        <div key={filterId} className="form--horizontal">
                          <ManageFilter
                            id={filterId}
                            formik={formik}
                            hideDelete={hideDelete}
                            showLabels={showLabels}
                            lastFilter={lastFilter}
                            addOrButton
                            addAndButton={!hasAndFilters}
                            handleAddFilter={handleAddFilter}
                            handleDeleteFilter={handleDeleteFilter}
                          />
                          {!lastFilter &&
                            <div className="form--connector">
                              <span>or</span>
                            </div>}
                        </div>
                      );
                    })}
                  </React.Fragment> }
              </div>
              <div className="filter__controls">
                {isKeyword && <SelectedUses />}
                {canDelete && <SelectedDelete apiArgs={apiArgs} />}
                {canTag && <SelectedTag apiArgs={apiArgs} />}
                {tableControls && <TableControls />}
              </div>
            </div>
          </div>
          {hasErrors &&
            <Error className="filter__error">
              {Object.keys(errors).map(errorId => (
                <div key={errorId}>{errors[errorId]?.value}</div>
              ))}
            </Error> }
          <div className="filter__search">
            <Button disabled={isSubmitting} variant={hasFiltersChanged ? `primary` : `green`} type="submit">
              Search
            </Button>
            <Button disabled={isSubmitting} onClick={handleClearAllFilters}>
              Clear
            </Button>
          </div>
        </form>
      </FilterWrapper>
    </React.Fragment>
  );
};

export default Filters;

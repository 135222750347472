import { generateID } from 'corigan';
import { isEqualObjects } from 'corigan';
import { isUndefined } from 'corigan';
import { objectIsEmpty } from 'corigan';

import type { RawDraftContentState } from 'draft-js';

import { statuses } from 'particles';

import { defaultState } from './defaultState';

declare type ReducerAction = {
  key?: string;
  type?:
  | 'canonicalAdd'
  | 'canonicalRemove'
  | 'canonicalUpdate'
  | 'decision'
  | 'edit'
  | 'load'
  | 'pageInitial'
  | 'pageReset'
  | 'editLockState'
  | 'set';
  value?: any;
};

const generateNewCanonicalEntry = () => {
  const dateObject = new Date();

  // Contents of above date object is
  // converted into a string using toISOString() method.
  const createdAt = dateObject.toISOString();
  const updatedAt = createdAt;

  const newCanonical = {
    createdAt,
    updatedAt,
    url: ``,
  };

  return newCanonical;
};

// Create global page reducer to handle state changes
export const omReducer = (state: OMContextProps['state'], action: ReducerAction) => {
  const { key, type = `edit`, value } = action;

  // Create a copy of state to prevent any chance of mutating
  let newState = { ...state };
  if (isUndefined(value) && type !== 'pageReset') return newState;

  switch (type) {
    case `canonicalAdd`:
      const editedRevisionBefore = { ...state.editedRevision };
      const canonicals = editedRevisionBefore?.canonicals || [];
      const emptyCanonical = generateNewCanonicalEntry();
      const hasCanonicals: boolean = canonicals?.length > 0;

      const newCanonicals = hasCanonicals ? [...canonicals, emptyCanonical] : [emptyCanonical];

      const withNewCanonicals = { ...editedRevisionBefore, canonicals: newCanonicals };
      newState.editedRevision = withNewCanonicals;

      newState.startedEdit = true;
      return newState;

    case `canonicalRemove`:
      const editedRevisionBeforeRemoval = { ...state.editedRevision };
      const toLoopOver = editedRevisionBeforeRemoval?.canonicals || [];

      const canonicalToRemove: PageRevisionCanonical = value;
      const { createdAt, url } = canonicalToRemove;

      const removedFromArray: PageRevisionCanonical[] = toLoopOver.filter(canonical => {
        // As we don't have an ID to compare, we need some other properties to uniquely identify what to remove
        const isSameCreatedAt: boolean = canonical.createdAt === createdAt;
        const isSameURL: boolean = canonical.url === url;

        const shouldRemove: boolean = isSameCreatedAt && isSameURL;
        return !shouldRemove;
      });

      const withRemovedCanonicals = { ...editedRevisionBeforeRemoval, canonicals: removedFromArray };
      newState.editedRevision = withRemovedCanonicals;

      newState.startedEdit = true;
      return newState;

    case `canonicalUpdate`:
      const editedBeforeUpdate = { ...state.editedRevision };
      const availableCanonicals = editedBeforeUpdate?.canonicals || [];

      const canonicalArrayWithUpdate: PageRevisionCanonical[] = availableCanonicals.map(canonical => {
        // As we don't have an ID to compare, we need some other properties to uniquely identify what to remove
        const inputCreatedAt = value?.canonicalEntry?.createdAt;

        const isSameCreatedAt: boolean = canonical.createdAt === inputCreatedAt;
        const shouldUpdate: boolean = isSameCreatedAt;

        if (!shouldUpdate) return canonical;

        return {
          ...canonical,
          url: value.value,
        };
      });

      const editedWithUpdatedCanonicals = { ...editedBeforeUpdate, canonicals: canonicalArrayWithUpdate };

      newState.editedRevision = editedWithUpdatedCanonicals;
      newState.startedEdit = true;

      return newState;

    case `decision`:
      const decisionNewValue: PageRevision = value;
      const newStatus: ArgStatus = decisionNewValue?.status;
      const lowerNewStatus: string = newStatus?.toLowerCase();

      switch (lowerNewStatus) {
        case statuses.draft.toLowerCase():
          newState.loadedRevision = { ...decisionNewValue };
          break;
        case statuses.implemented.toLowerCase():
          newState.loadedRevision = { ...decisionNewValue };
          break;
        default:
          newState.loadedRevision.status = newStatus;
          break;
      }

      newState.editedRevision = { ...newState.loadedRevision };

      newState.startedEdit = false;

      return newState;

    case `edit`:
      let editNewValue: ContentBlock[] = value;
      let hasChanged: boolean = state.startedEdit;

      const editedCurrent = { ...state.editedRevision };
      const blockContentCurrent = editedCurrent.contentBlocks;

      if (key === `contentBlocks`) {
        const { content, encodedContent, id } = value;

        const revisionBlocks: ContentBlock[] = editedCurrent?.contentBlocks;
        const hasBlocks: boolean = revisionBlocks?.length > 0;
        if (!hasBlocks) return;

        const clonedBlocks: ContentBlock[] = [...revisionBlocks];

        const editedBlocks: ContentBlock[] = clonedBlocks.map((block: any) => {
          const { contentBlock } = block;
          const match = contentBlock.id === id;
          if (!match) return block;

          block.content = content;
          if (encodedContent) block.contentEncoded = encodedContent;
          return block;
        });

        editNewValue = editedBlocks;
        hasChanged = editNewValue !== blockContentCurrent;
      }

      const editedNew = { ...editedCurrent, [key]: editNewValue };

      const activeRevision = state.activeRevision;
      if (![`contentBlocks`, `assignedTo`].includes(key)) hasChanged = !isEqualObjects(activeRevision, editedNew);

      newState.editedRevision = editedNew;
      newState.startedEdit = hasChanged;

      return newState;

    case `load`:
      newState.startedEdit = false;
      newState.error = null;
      newState.locked = false;

      const toLoad: PageRevision = value;
      const hasBlocks: boolean = toLoad?.contentBlocks?.length > 0;

      if (hasBlocks) {
        const blocks: ContentBlock[] = [...toLoad?.contentBlocks];
        let newBlocks = [];

        blocks.forEach((block: ContentBlock) => {
          const { contentEncoded, ...blockData } = block;
          const hasEncodedField: boolean = !objectIsEmpty(contentEncoded);
          const shouldDecode: boolean = hasEncodedField && contentEncoded?.blocks?.length > 0;

          // Run if we are decoding a detailed object, parsing the draft-js encoding

          if (!shouldDecode) {
            newBlocks = [...newBlocks, block];
            return;
          }

          const entityMapAPI: ObjectLiteral = contentEncoded?.entityMap;

          // Decode the formatted (encoded data from the API)
          const hasEntityMapAPI: boolean = entityMapAPI?.length > 0;
          const entityMap = { ...entityMapAPI };

          if (hasEntityMapAPI) {
            for (let i = 0; i < entityMap.length; i++) {
              const value = entityMap[i];

              const position = value[`key`];
              if (position) delete value[`key`];

              entityMap[position] = value;
            }
          }

          const decoded: RawDraftContentState = { ...contentEncoded, entityMap };

          const blockWithDecoded: ContentBlock & { contentDecoded: RawDraftContentState } = {
            ...blockData,
            contentDecoded: decoded,
          };

          newBlocks = [...newBlocks, blockWithDecoded];
        });

        toLoad.contentBlocks = newBlocks;
      }

      const status: string = toLoad?.status;
      const lowerStatus: string = status?.toLowerCase();
      const isDraft: boolean = lowerStatus === statuses.draft.toLowerCase();
      const isAwaiting: boolean = lowerStatus === statuses.awaitingApproval.toLowerCase();
      const keepComment = isDraft || isAwaiting;

      const commentlessValue: PageRevision = { ...toLoad, comment: `` };

      const loadedValue = keepComment ? { ...toLoad } : { ...commentlessValue };

      newState.editedRevision = { ...loadedValue };
      newState.loadedRevision = { ...loadedValue };

      return newState;

    case `pageInitial`:
      const page: Page = value;
      newState = { ...state, ...page };

      newState.startedEdit = false;
      newState.editedRevision = null;
      newState.loadedRevision = null;
      newState.currentRevision = null;

      const initialActiveRevision: PageRevision = { ...newState.activeRevision };
      const initialCurrentRevision: PageRevision = { ...newState.currentRevision };
      const initialImplementedRevision: PageRevision = { ...newState.implementedRevision };

      const useActive: boolean = initialActiveRevision && !objectIsEmpty(initialActiveRevision);
      const useImplemented: boolean = initialImplementedRevision && !objectIsEmpty(initialImplementedRevision);
      const initialRevision: PageRevision = useActive
        ? initialActiveRevision
        : useImplemented
          ? initialImplementedRevision
          : initialCurrentRevision;

      newState.editedRevision = { ...initialRevision };
      newState.loadedRevision = { ...initialRevision };

      return newState;

    case `pageReset`:
      return defaultState;

    case `editLockState`:
      newState = { ...state };

      newState.editedRevision = {...newState.editedRevision, editLocked: value};

      return newState;

    case `set`: {
      if (key == null) {
        console.error(`No action key provided to 'set' action`);
        return;
      }

      newState[key] = value;
      return newState;
    }

    default: {
      return newState;
    }
  }
};

export default omReducer;

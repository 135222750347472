import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { Menu } from 'icons';
import StyledDraggable from './draggable.styles';

type DnDDraggableProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  draggableId: string;
  index: number;
  dragHandle?: boolean;
  disableInteractiveElementBlocking?: boolean;
};

const DnDDraggable: React.FC<DnDDraggableProps> = (props: DnDDraggableProps) => {
  const { children, className, draggableId, index, dragHandle, disableInteractiveElementBlocking } = props;

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      disableInteractiveElementBlocking={disableInteractiveElementBlocking}
    >
      {(provided: DraggableProvided) => (
        <div
          className={className}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...(!dragHandle ? { ...provided.dragHandleProps } : undefined)}
        >
          {dragHandle ? (
            <StyledDraggable>
              <div className="draggable__content">{children}</div>
              <div className="draggable__handle">
                <div className="draggable__handle__center" />
                <div className="draggable__handle__icon" {...provided.dragHandleProps}>
                  <Menu />
                  <span>Drag handle</span>
                </div>
              </div>
            </StyledDraggable>
          ) : (
            <>{children}</>
          )}
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

DnDDraggable.defaultProps = {
  dragHandle: false,
  disableInteractiveElementBlocking: false,
};

export default DnDDraggable;

import * as React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { generateID } from 'corigan';
import { useIsMount } from 'corigan';

// Local Partials
import StyledTabs from './tabs.styles';

type TabsProps = {
  /**
   * The 'children' of 'Tabs' expects two groups of children.
   * The first is a group of tab labels in spans
   * The second is a group of sections you want the labels to open on interaction.
   */
  children: React.ReactNode[];
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * Prop to switch the way the labels are styled
   */
  id: string;
  variation?: 'cards' | 'default';
  x?: number;
  y?: number;
};

const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const isMount = useIsMount();
  const { children, className, id, variation, x, y } = props;

  const context = useContext(ApplicationContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const tabs = state?.tabs;
  const hasTabs = tabs.length > 0;
  const tabInfo = !hasTabs ? undefined : tabs?.find(tab => tab.id === id);
  const focus = tabInfo?.focus ? tabInfo.focus : 0;

  let classList = `tabs`;
  if (variation) classList += ` tabs--${variation}`;
  if (className) classList += ` ${className}`;
  if (focus >= 0) classList += ` tabs--${focus}`;

  const titles = children?.[0];
  const contents = children?.[1];

  React.useEffect(() => {
    if (!isMount) return;
    if (!dispatch) return;

    const otherTabs = !hasTabs ? [] : tabs.filter(tab => tab.id !== id);
    const hasOtherTabs = otherTabs.length > 0;

    if (!hasOtherTabs) {
      dispatch({ type: `set`, key: `tabs`, value: [{ id, focus: 0 }] });
      return;
    }

    const newTabsState = [...otherTabs, { id, focus: 0 }];
    dispatch({ type: `set`, key: `tabs`, value: newTabsState });
  }, [dispatch, hasTabs, id, isMount, tabs]);

  return (
    <StyledTabs className={classList} x={x} y={y}>
      <TabsTitles id={id} titles={titles} />
      <TabsContents contents={contents} />
    </StyledTabs>
  );
};

// Default prop values
Tabs.defaultProps = {
  variation: `default`,
};

type TabChildrenProps = {
  id: string;
  titles: any;
};

const TabsTitles: React.FC<TabChildrenProps> = (props: TabChildrenProps) => {
  const { id, titles } = props;
  const children = titles?.props?.children;
  const visibleChildren = children.filter(title => title);

  return (
    <header className="tabs__header">
      <nav>
        {visibleChildren.map((title, i) => (
          <TabTitle i={i} id={id} key={`tab-title-${generateID(`t-t`)}`}>
            {title}
          </TabTitle>
        ))}
        <span className="tab__filler" />
      </nav>
    </header>
  );
};

type TabTitleProps = {
  children?: React.ReactElement | React.ReactElement[];
  i?: any;
  id: string;
};

const TabTitle: React.FC<TabTitleProps> = (props: TabTitleProps) => {
  const { children, i, id } = props;

  const context = useContext(ApplicationContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const tabs = state?.tabs;
  const hasTabs = tabs.length > 0;
  const tabInfo = !hasTabs ? undefined : tabs?.find(tab => tab.id === id);
  const focus = tabInfo?.focus;

  const isActive = focus === i;
  const hasWarningTab = Array.isArray(children)
    ? children.some(child => child?.props?.className?.includes(`tab__title--warning`))
    : children?.props?.className?.includes(`tab__title--warning`);

  let className = `tab__title`;
  if (isActive) className += ` tab__title--active`;
  if (hasWarningTab) className += ` tab__title--warning`;

  const handleClick = e => {
    if (e) e.preventDefault();

    if (!dispatch) return;

    const otherTabs = !hasTabs ? [] : tabs.filter(tab => tab.id !== id);
    const hasOtherTabs = otherTabs.length > 0;

    if (!hasOtherTabs) {
      dispatch({ type: `set`, key: `tabs`, value: [{ id, focus: i }] });
      return;
    }

    const newTabsState = [...otherTabs, { id, focus: i }];
    dispatch({ type: `set`, key: `tabs`, value: newTabsState });
  };

  return (
    <button className={className} onClick={handleClick}>
      {children}
    </button>
  );
};

// Default prop values
TabTitle.defaultProps = {};

declare type TabContentsProps = {
  contents: any;
};

const TabsContents: React.FC<TabContentsProps> = (props: TabContentsProps) => {
  const { contents } = props;
  const children = contents?.props?.children;

  const content = useMemo(() => {
    return (
      <section className="tabs__contents">
        {children.map((section, i) => {
          return <span key={`tab-section-${i}`}>{section}</span>;
        })}
      </section>
    );
  }, [children]);
  return content;
};

export default Tabs;

import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { toast } from 'react-toastify';

// Particles
import { isURL } from 'corigan';
import { OMContext } from 'corigan';
import { removeSingleCache } from 'corigan';
import { windowAvailable } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import PageRevisionModal from '../modal';

// Atoms
import { Button } from 'corigan';

// API
import { callUpdatePageRevision } from 'corigan';

// Localised partials
import { revisionWith } from '../../../_with';

import { getBlocks } from '../../helpers';
import { getIDs } from '../../helpers';

import { statuses } from 'particles';

const _with = revisionWith;

declare type PatchPageProps = {
  isLocked: boolean;
  permissions: PermissionLevelName[];
  hideNoPermissions: boolean;
  status: ArgStatus;
  text?: string;
  editedText?: string;
  loadingText?: string;
  toastMessage?: string;
  modal?: boolean;
  modalTitle?: string;
  modalText?: string;
  modalConfirm?: string;
  modalComment?: boolean;
};

const PatchPage: React.FC<PatchPageProps> = (props: PatchPageProps) => {
  const { permissions, hideNoPermissions } = props;

  const { userHasPermission } = useHasPermissions({ requiredPermissions: permissions });
  if (!userHasPermission && hideNoPermissions) return null;

  return <PatchButton userHasPermission={userHasPermission} {...props} />;
};

declare type PatchProps = {
  isLocked: boolean;
  userHasPermission: boolean;
  status: ArgStatus;
  text?: string;
  editedText?: string;
  loadingText?: string;
  toastMessage?: string;
  modal?: boolean;
  modalTitle?: string;
  modalText?: string;
  modalConfirm?: string;
  modalComment?: boolean;
};

const PatchButton: React.FC<PatchProps> = (props: PatchProps) => {
  const { isLocked, userHasPermission, status, text, editedText, loadingText, toastMessage } = props;
  const { modal, modalTitle, modalText, modalConfirm, modalComment } = props;
  const queryCache = useQueryCache();
  const [confirming, setConfirming] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const context: any = useContext(OMContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;
  const loadedRevision: PageRevision = state?.loadedRevision;
  const startedEdit: boolean = Boolean(state?.startedEdit);
  const isDraft = status === statuses.draft;
  const isLive = status === statuses.live;
  const isRejected = status === statuses.rejected;

  const editedRevision: PageRevision = state.editedRevision;
  const {
    canonicals,
    contentBlocks,
    keywords,
    linkedWords,
    name,
    pageId,
    pageType,
    preferredCategories,
    tags,
    title,
    url,
    metaDescription,
    comment,
    assignedTo,
    previousAssignedTo,
  } = editedRevision || {};
  const assignedToId: string = isDraft || isLive ? undefined
    : isRejected ? previousAssignedTo?.id || previousAssignedTo
    : assignedTo?.id || assignedTo;
  const previousAssignedToId: string = isDraft || isLive || isRejected ? assignedTo?.id || assignedTo
    : previousAssignedTo?.id || previousAssignedTo ;

  const [mutate, { error, isLoading: loading }] = useMutation(callUpdatePageRevision, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      dispatch({ type: `set`, key: `error`, value: err });
      queryCache.invalidateQueries([`callGetManyPageRevisions`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {
      toast.error(error, {})
     },
    onSuccess: (data: APIResponse, variables) => {
      // If callCreatePageRevision function was successful, get values from API response
      const pageRevisionData: PageRevision = data?.data;
      const otherPageRevisions = (pageRevisionData.revisions !== undefined) ? pageRevisionData.revisions : [];
      const id = pageRevisionData?.page;
      const status = pageRevisionData?.status;

      // These are the same parameters used in the revisions table query
      const perPage = 1000;
      const _with: ArgWith = [`createdBy`];

      if (status === statuses.readyToImplement) {
        dispatch({ key: `comment`, value: `` });
      }

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetOnePageRevisions`, { drafts: true, id, perPage, _with }],
        (previousRevisions: APIResponse) => removeSingleCache(previousRevisions, loadedRevision?.id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetOnePageRevisions`, { drafts: false, id, perPage, _with }],
        (previousRevisions: APIResponse) => {
          // Are there pageRevisions in the cache?
          const data = previousRevisions?.data;
          if (!data) return previousRevisions;

          // If there are no page revisions in the cache, create a new array with the new page revision
          const hasExistingData: boolean = data?.length > 0;
          if (!hasExistingData) return { ...previousRevisions, data: [pageRevisionData] };

          // Load the revision to new revisionData
          const updatedRevisions = data.filter(revision => {
              // Check if old revision status in cache is same as the revision in DB - if not change it
              const prevRevision = otherPageRevisions.find((o, i) => o.id === revision?.id);

              if (revision?.status !== prevRevision?.status) {
                revision.status = prevRevision.status;
              }

              // Don't return document just updated
              return revision?.id !== pageRevisionData?.id;
          });

          // If there are no keywords in the cache, create a new array with the new keyword
          const hasUpdatedRevisions: boolean = updatedRevisions?.length > 0;
          if (!hasUpdatedRevisions) return { ...previousRevisions, data: [pageRevisionData] };

          const newCache = {
            ...previousRevisions,
            data: [...updatedRevisions, pageRevisionData],
          };

          return newCache;
        },
      );

      if (toastMessage) {
        toast.info(toastMessage, {});
      }

      dispatch({ type: `set`, key: `currentStatus`, value: pageRevisionData.status });

      const url = `${window.location.pathname}?id=${id}&status=${pageRevisionData.status}`;
      window.history.replaceState(window.history.state, ``, url);

      // Load the revision to new revisionData
      dispatch({ type: `load`, value: pageRevisionData });

      queryCache.invalidateQueries([`callGetPage`]);

      dispatch({ type: `set`, key: `startedEdit`, value: false });

      setSuccess(true);
    },
  });

  const handleSubmit = async e => {
    try {
      if (e) e.preventDefault();

      // Gatsby build check for window
      if (!windowAvailable()) return;

      if (!userHasPermission) {
        window.alert(`You do not have permission to convert to a page revision with status '${status}'`);
        return;
      }

      const canonicalWithURLs = canonicals?.filter(canonical => Boolean(canonical?.url));
      const hasCanonicals: boolean = canonicals?.length > 0;

      // If we have canonicals to check then...
      if (hasCanonicals) {
        // Do each canonical entry have a valid URL value?
        const hasValidValues: boolean = canonicalWithURLs.every(canonical => {
          const { url } = canonical;
          return isURL(url);
        });

        // If not then notify the user and exit the mutation
        if (!hasValidValues) {
          toast.error(`The canonicals entered are not valid URLs`, {});
          return;
        }
      }

      const id = loadedRevision.id;
      const composedBlocks: ArgContentBlocks | [] = getBlocks(contentBlocks);
      const keywordIDs: ArgKeywords | [] = getIDs(keywords);
      const tagIDs: ArgTags | [] = getIDs(tags);
      const [pageTypeID] = getIDs([pageType]);
      const preferredCategoriesIds = getIDs(preferredCategories);
      const type: ArgPageType = pageTypeID;

      const args = {
        canonicals: canonicalWithURLs,
        contentBlocks: composedBlocks,
        keywords: keywordIDs,
        linkedWords,
        id,
        name,
        pageId,
        pageType: type,
        preferredCategories: preferredCategoriesIds,
        status,
        tags: tagIDs,
        title,
        url,
        metaDescription,
        comment: status === statuses.readyToImplement ? `` : comment,
        currentRejected: status === statuses.rejected ? true : false,
        assignedTo: assignedToId,
        previousAssignedTo: previousAssignedToId,
        _with,
      };

      await mutate(args);
    } catch (error) {
      toast.error(error, {});
    }
  };

  const openConfirm = e => {
    e.preventDefault();
    if (isLocked) return;
    setConfirming(true);
  };

  let buttonText: string = text ?? `Mark as ${status}`;
  if (startedEdit) buttonText = editedText ?? `Mark current as ${status}`;
  if (loading) buttonText = loadingText ?? `Marking as ${status}`;

  const disabled: boolean = loading || isLocked;

  const buttonFunction = modal ? openConfirm : handleSubmit;

  return (
    <>
      {modal && confirming && (
        <PageRevisionModal
          title={modalTitle}
          text={modalText}
          confirmText={modalConfirm}
          modalComment={modalComment}
          setConfirming={setConfirming}
          success={success}
          handleSubmit={handleSubmit}
          loading={loading}
          error={error}
        />
      )}
      <Button disabled={disabled} onClick={buttonFunction}>
        {buttonText}
      </Button>
    </>
  );
};

export default PatchPage;

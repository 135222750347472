import * as React from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

// Particles
import { ApplicationContext } from 'corigan';
import { brandColours } from 'corigan';
import { getRelease } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { objectIsEmpty } from 'corigan';
import { toTitleCase } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Skeleton } from 'corigan';
import { Error } from 'corigan';
import { Card } from 'corigan';
import { Toggle } from 'corigan';
import { ChartStackedArea } from 'corigan';
import { GapGroup } from 'corigan';

// API
import { callGetReportPageTopRanking } from 'corigan';

import DateRange from './date-range';

declare type TopRankingProps = {};

const TopRanking = (props: TopRankingProps) => {
  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;
  const hasDomainID: boolean = Boolean(domainId);

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetReportPageTopRanking`, { domainId }],
    callGetReportPageTopRanking,
    { enabled: hasDomainID },
  );

  return (
    <Card loading={loading} minHeight={false}>
      <RankingTable res={res} error={error} loading={loading} />
    </Card>
  );
};

declare type RankingTableProps = {
  res: GetReportPageTopRankingResponse;
  error: any;
  loading: boolean;
};

const RankingTable = (props: RankingTableProps) => {
  const { res, error, loading } = props;
  const data = res?.data;

  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const { releaseVersion } = getRelease();

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our initial parameters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const localStorageKey = React.useMemo((): string => {
    return `version=${releaseVersion}&key=competitor-research-top-ranking-chart-${domainId}`;
  }, [domainId, releaseVersion]);

  const hasData: boolean = !objectIsEmpty(data);
  const noDataNoError: boolean = !hasData && !error;

  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);

  const arrays = React.useMemo(() => {
    if (!hasData) return undefined;
    return Object.values(data);
  }, [data, hasData]);

  const allObjects = React.useMemo(() => {
    if (!hasData) return undefined;
    return arrays.flat();
  }, [arrays, hasData]);

  const allDates = React.useMemo(() => {
    if (!hasData) return undefined;
    return allObjects.map(value => value.date);
  }, [allObjects, hasData]);

  const hasDates: boolean = allDates?.length > 0;

  // Calculates the earliest date from the API response
  const dateEarliest = React.useMemo(() => {
    if (!hasDates) return null;

    return allDates.reduce((pre, cur) => {
      return Date.parse(pre) > Date.parse(cur) ? cur : pre;
    });
  }, [allDates, hasDates]);

  // Calculates the latest date from the API response
  const dateLatest = React.useMemo(() => {
    if (!hasDates) return null;

    return allDates.reduce((pre, cur) => {
      return Date.parse(pre) < Date.parse(cur) ? cur : pre;
    });
  }, [allDates, hasDates]);

  // On change of determined earliest and latest week, update the state values
  React.useEffect(() => {
    if (!dateEarliest || !dateLatest) return;

    setStart(moment(dateEarliest).startOf(`week`));
    setEnd(moment(dateLatest).startOf(`week`));
  }, [dateEarliest, dateLatest]);

  // Only recalculate on date changes in state store
  const between = React.useMemo(() => {
    if (!start || !end) return null;

    return getWeeksBetween(start, end);
  }, [start, end]);
  const dates = between?.dates;

  // 'series' will format the data from the API in a way that the chart component expects
  const series = React.useMemo(() => {
    // Is 'data' defined and an object with values?
    const hasData: boolean = !objectIsEmpty(data);
    if (!hasData) return null;

    // Converts object into an iterable array
    const items = Object.entries(data).map(([domain, values]) => {
      // If we have no values to iterate over then exit the map
      const hasValues = values?.length > 0;
      if (!hasValues) return null;

      const data = values.map(value => {
        // 'date' and 'total' are found in the response objects
        const { date, total } = value;

        // Return the date as a full ISO8601 string for 'x'
        // Return the total (integer) as the 'y' axis value
        const plot: { x: string; y: number } = {
          x: moment(date).toISOString(),
          y: total,
        };

        return plot;
      });

      // Sort the plot dates in chronological order
      const ordered = data.sort((a, b) => (a.x < b.x ? -1 : a.x > b.x ? 1 : 0));

      // Get the start & end dates from the date range picker
      const dateStart: Date = start?.toDate();
      const dateEnd: Date = end?.toDate();

      const filteredData = ordered.filter(plot => {
        // x is equal to the 'date' key in each domains history
        const { x } = plot;

        // Convert the date string to a date object for comparison
        const dateCompare: Date = new Date(x);

        // true if dateCompare is later than dateStart
        const isAfterStart: boolean = dateCompare > dateStart;

        // true if dateCompare is earlier than dateEnd
        const isBeforeEnd: boolean = dateCompare < dateEnd;

        // Do we want to display this series in the chart?
        const isNeeded: boolean = isAfterStart && isBeforeEnd;
        return isNeeded;
      });

      // Remove any null values
      const validData = filteredData?.filter(Boolean);

      return { name: domain, data: validData };
    });

    // Remove null values
    const clean = items?.filter(Boolean);
    return clean;
  }, [data, start, end]);

  // Determines if we have any series plots available to us from the data intercepted
  const hasSeries: boolean = series?.length > 0;

  // Create an initial state of every series domain name available
  const allSeriesPlotNames = React.useMemo(() => {
    // If there is no series (still loading) then return an empty array
    const hasSeries = series?.length > 0;
    if (!hasSeries) return [];

    // Otherwise return an array of domain names, in alphabetical order
    const allNames = series.map(({ name }) => name);
    const orderedNames = allNames.sort();
    return orderedNames;
  }, [series]);

  const [enabledPlots, setEnabledPlotsState] = React.useState<string[]>(allSeriesPlotNames);

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setEnabledPlots = React.useCallback(
    (newValue: any) => {
      setEnabledPlotsState(newValue);

      const hasWindow = windowAvailable();
      if (!hasWindow) return;
      localStorageSet(localStorageKey, newValue);
    },
    [localStorageKey],
  );

  // Do we have any plot names available to use in filtering?
  const hasPlotNames: boolean = allSeriesPlotNames?.length > 0;

  // If we have local storage values, set them
  React.useEffect(() => {
    const hasLocalEnabled = Boolean(localStorageRead(localStorageKey));
    if (!hasLocalEnabled) return;

    setEnabledPlots(localStorageRead(localStorageKey));
  }, [domainId, localStorageKey, setEnabledPlots]);

  // If we have no enabled series in localStorage, set them all as active
  React.useEffect(() => {
    const hasLocalEnabled = Boolean(localStorageRead(localStorageKey));

    if (!hasPlotNames) return;
    if (hasLocalEnabled) return;

    setEnabledPlots(allSeriesPlotNames);
  }, [allSeriesPlotNames, hasPlotNames, localStorageKey, setEnabledPlots]);

  // Helper function to use in filter function
  // is the series included in the useState array?
  const isPlotEnabled = React.useCallback(
    plot => {
      const isEnabled: boolean = enabledPlots.includes(plot.name);
      return isEnabled;
    },
    [enabledPlots],
  );

  // Create an empty series, and then update with filtered plots
  const enabledSeries: any[] = React.useMemo(() => {
    if (!hasSeries) return [];
    return series.filter(isPlotEnabled).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [series, hasSeries, isPlotEnabled]);

  // If the number of categories enabledParents is equal to the number of categories
  // available then we already have 'all' on.
  const allOn: boolean = enabledSeries?.length === series?.length;

  // On toggle change...
  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Get the ID of the toggle (domain name)
    const plotToChange: string = e.target.id;

    const isAll: boolean = plotToChange === `showAll`;
    const alreadyAll: boolean = allOn;

    // If there are categories missing, set them to enabled
    if (isAll && !alreadyAll) {
      setEnabledPlots(allSeriesPlotNames);
      return;
    }

    // If we aren't setting categories, disable event
    if (isAll) {
      setEnabledPlots([]);
      return;
    }

    // Create an empty array which will hold the new enabled series plots
    let newEnabled: any[] = [];

    // Determine if there are any enabled plots yet
    const currentEnabled: any[] = [...enabledPlots];
    const hasCurrent: boolean = currentEnabled?.length > 0;

    // If none are enabled, the new plot state should be the single new clicked toggle ID
    if (!hasCurrent) {
      newEnabled = [plotToChange];
      setEnabledPlots(newEnabled);
      return;
    }

    // Otherwise, determine if the clicked toggle is being added or not
    let shouldAdd: boolean = true;
    if (currentEnabled.includes(plotToChange)) shouldAdd = false;

    // If it is being added, spread the old array, and append the new value
    if (shouldAdd) {
      newEnabled = [...currentEnabled, plotToChange];
      setEnabledPlots(newEnabled);
      return;
    }

    // Prevent there being no series enabled
    const onlyOneEnabled: boolean = currentEnabled?.length === 1;
    if (onlyOneEnabled) return;

    // Otherwise, remove the clicked toggle ID from the current values
    newEnabled = currentEnabled.filter(plot => {
      const isMatch = plot === plotToChange;
      return !isMatch;
    });
    setEnabledPlots(newEnabled);
  };

  // Get an array of the brand colours, with their names
  const brandingColours = brandColours();

  // Create a cached array of colours
  const colors: string[] = React.useMemo(() => {
    const hasSeries = enabledSeries?.length > 0;
    if (!hasSeries) return [];

    // Loop over each enabled series
    const value = enabledSeries.map(group => {
      // Get the name from the plot (e.g. amazon.com)
      const { name } = group;

      // Look up the brand colours array, and see if there's a match
      const definedColour = brandingColours.find(brandObject => {
        const { brand } = brandObject;

        // Lowercase strings for comparison
        const lowerBrand: string = brand?.toLowerCase();
        const lowerName: string = name?.toLowerCase();

        // Was there a match? Searching enabled plot for substring of brand name
        // E.g. does www.amazon.co.uk include amazon?
        const found: boolean = lowerName.includes(lowerBrand);
        return found;
      });

      // If found, return the defined colour
      if (definedColour?.colour) return definedColour.colour;

      // Otherwise return a shade of grey
      return `#353535`;
    });

    return value;
  }, [brandingColours, enabledSeries]);

  const options: ObjectLiteral = {
    colors,
    legend: {
      show: false,
    },
    tooltip: {
      labels: {
        format: `dd MMM yyyy`,
      },
    },
    xaxis: {
      categories: dates,
      labels: {
        format: `MMM yyyy`,
      },
      type: `datetime`,
    },
    yaxis: {
      reversed: false,
    },
  };

  return (
    <React.Fragment>
      <div className="display--flex align-items--center justify-content--between">
        <h2>Top Ranking URLs</h2>
        <DateRange
          dateEarliest={dateEarliest}
          dateLatest={dateLatest}
          end={end}
          setEnd={setEnd}
          start={start}
          setStart={setStart}
        />
      </div>
      <p>Top 3 ranking URLS of each competitor compared to your business.</p>
      {loading && <Skeleton height={300} />}
      {noDataNoError && <p>No data could be retrieved.</p>}
      {error && <Error error={error} />}
      {hasData && (
        <React.Fragment>
          <GapGroup className="mt-2">
            <Toggle id="showAll" label="Show All" on={allOn} onChange={onToggleChange} small={true}>
              Show all on the chart
            </Toggle>
            {hasPlotNames &&
              allSeriesPlotNames.map(name => {
                const nonWWW = name.replace(`www.`, ``);
                const label = toTitleCase(nonWWW);
                const on = enabledPlots.includes(name);

                // Look up the brand colours array, and see if there's a match
                const definedColour = brandingColours.find(brandObject => {
                  const { brand } = brandObject;

                  // Lowercase strings for comparison
                  const lowerBrand: string = brand?.toLowerCase();
                  const lowerName: string = name?.toLowerCase();

                  // Was there a match? Searching enabled plot for substring of brand name
                  // E.g. does www.amazon.co.uk include amazon?
                  const found: boolean = lowerName.includes(lowerBrand);
                  return found;
                });

                // If found, return the defined colour
                const colour = definedColour?.colour;

                return (
                  <Toggle
                    id={name}
                    key={name}
                    colour={colour}
                    small={true}
                    label={label}
                    on={on}
                    onChange={onToggleChange}
                  >
                    {label}
                  </Toggle>
                );
              })}
          </GapGroup>
          <ChartStackedArea id="ranking-table-line" className="mt-2" options={options} series={enabledSeries} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default TopRanking;

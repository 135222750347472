import styled, { css } from 'styled-components';
import { shouldBeDark } from 'particles';

const largeStyles = css`
  label {
    color: ${() => (shouldBeDark() ? `var(--white)` : `#4d4f5c`)};
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }

  .dropzone__area.dropzone__area {
    flex-direction: column;
    justify-content: center;
    min-height: 320px;

    background-color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey100)`)};

    .dropzone__button {
      margin-top: 16px;
    }

    .dropzone__message {
      display: block;

      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--grey700)`)};
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
    }

    svg {
      display: block;
      height: 92px;
      margin-bottom: 32px;
      margin-right: 0;
      width: auto;

      fill: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey500)`)};
    }
  }
`;

const StyledUpload = styled.section`
  .dropzone__area.dropzone__area {
    align-items: center;
    display: flex;

    cursor: pointer;

    svg {
      margin-right: 16px;
      width: 20px;

      fill: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey600)`)};
    }
  }

  ${props => props.large && largeStyles}
`;

export default StyledUpload;

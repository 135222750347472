import * as React from 'react';

import StyledHR from './hr.styles';

type HRProps = {
  background?: string;
  /**
   * The text to be used in the vertical center of a HR element
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * A set of predefined variantions of the HR spacing rules.
   * Each variant has a focus on the y-axis margin spacing
   */
  variant?: 'x-large' | 'large' | 'default' | 'small';
  /**
   * A flexible integer or float value used to set the 'max-width' of the
   * 'hr' element.
   */
  width?: number;
};

const HR: React.FC<HRProps> = (props: HRProps) => {
  const { children, className } = props;

  let classList = `hr`;
  if (className) classList += ` ${className}`;
  if (children) classList += ` hr--text`;

  const data = {
    ...props,
  };
  delete data.children;
  delete data.className;

  return <StyledHR className={classList} data-content={children ? children : undefined} {...data} />;
};

// Default prop values
HR.defaultProps = {
  variant: `default`,
};

export default HR;

import { fetchAPI } from 'particles';

type DeleteManyPagesParameters = {
  ids: [ArgID];
};

export const callDeleteManyPages = async (parameters: DeleteManyPagesParameters) => {
  const response = await fetchAPI({ method: `DELETE`, parameters, route: `pages` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callDeleteManyPages;

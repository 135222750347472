import { useContext } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import type { QueryConfig } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { localStorageRead } from 'corigan';

// API
import { callMe } from 'corigan';

const isDevelopment = process.env.NODE_ENV !== `production`;

export const useMyPermissions = (queryConfig: QueryConfig<any, any> = {}): Permission[] => {
  const context: ApplicationContextProps = useContext(ApplicationContext);
  const state = context?.state;
  const domainActive = state?.domainActive;
  const domainClient = domainActive?.client;

  const localTokenResult: IdTokenResult = localStorageRead(`localTokenResult`);

  const hasLocalTokenResult: boolean = Boolean(localTokenResult);
  const enabled: boolean = hasLocalTokenResult;
  const config: QueryConfig<any, any> = { ...queryConfig, enabled };

  const { data: res } = useQuery([`callMe`], callMe, config);
  const user = res?.data;

  const permissions = useMemo(() => {
    // @ts-ignore
    const roles: Role[] = user?.roles;
    const hasRoles: boolean = roles?.length > 0;

    // If the user has no roles, then they have no permissions
    if (!hasRoles) {
      if (isDevelopment) console.error(`useMyPermissions, no roles`);
      return [];
    }

    // Decide itterable roles based on if active domain is available
    const hasActiveClient = roles.some(r => r.client === domainClient);
    const itterableRoles = hasActiveClient ? roles.filter(r => r.client === domainClient) : roles;

    const hasItterableRoles: boolean = itterableRoles?.length > 0;
    if (!hasItterableRoles) {
      if (isDevelopment) console.error(`useMyPermissions, no domain roles to itterate over`);
      return [];
    }

    // Otherwise, get the permission object from each role
    const userPermissions = itterableRoles?.map(r => {
      const hasPermissions: boolean = r?.permissions?.length > 0;
      if (!hasPermissions) return null;

      const rolePermissions = r.permissions;
      return rolePermissions;
    });

    // Filter out 'null' from the array
    const validPermissions = userPermissions.filter(Boolean);

    // Do we have any permissions form the roles we belong to?
    const hasPermissions: boolean = validPermissions?.length > 0;
    if (!hasPermissions) {
      if (isDevelopment) console.error(`useMyPermissions, no permissions`);
      return [];
    }

    // If so, flattend the array to objects of permissions
    const flatPermissions = Array.prototype.concat.apply([], validPermissions);
    const flatSuccess: boolean = flatPermissions?.length > 0;
    if (!flatSuccess) {
      if (isDevelopment) console.error(`useMyPermissions, no flat success permissions`);
      return [];
    }

    // Create a 'Set' class which will record any ids we see
    const seen = new Set();

    // Calculate permissions, removing any duplicate entities
    const permissions = flatPermissions.filter(permission => {
      const duplicate: boolean = seen.has(permission.id);
      seen.add(permission.id);
      return !duplicate;
    });

    // Sort the permissions alphabetically
    const permissionsOrderByName = permissions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    // Return the ordered array of permissions
    return permissionsOrderByName;
  }, [domainClient, user]);

  return permissions;
};

export default useMyPermissions;

import { localStorageRead } from 'corigan';

const idsToWhere = (ids: [ArgID]) => {
  let whereString = null;
  const validLength = ids?.length > 0;
  if (!validLength) return whereString;

  whereString = ids.map((id, i) => {
    const isFirst = i === 0;
    if (isFirst) return `?where[_id][any][]=${id}`;
    return `&where[_id][any][]=${id}`;
  });
  whereString = whereString.join(``);
  return whereString;
};

const handleDELETE = async ({ append, fetchOptions, parameters, url }: fetchHandlerArgs) => {
  const id = parameters?.id;
  if (id) delete parameters.id;
  if (id) url = url + `/` + id;

  let whereString = null;
  const multipleIDs = parameters?.ids && parameters.ids.length > 0;
  if (multipleIDs) whereString = idsToWhere(parameters.ids);
  if (multipleIDs) delete parameters.id;

  // Attach query IDs as 'where' string
  if (whereString) url = url + whereString;

  // Attached appended string if defined
  if (append) url = url + `/` + append;

  fetchOptions.body = JSON.stringify(parameters);

  // Call the fetch function and parse as JSON
  const request = await fetch(url, fetchOptions);
  const response = await request.json();
  return response;
};

const handleGET = async ({ append, fetchOptions, parameters, url }: fetchHandlerArgs) => {
  // An ID parameter currently only exists on getOne[collectionType] functions
  // E.g. getOneKeyword will pass an ID value which we can auto append before any query parameters
  const id = parameters?.id;
  if (id) {
    url = url + `/` + id;
    delete parameters.id;
  }

  // Check the number of parameter keys defined in parmeter object is greater than 0
  const hasParameters = Object.keys(parameters).length > 0;
  const stringParameters = new URLSearchParams(parameters).toString();
  let decodedString = decodeURIComponent(stringParameters);

  // Remove first `where=` as the equals comes after the object values
  const shouldClean = decodedString.includes(`where=`);
  if (shouldClean) decodedString = decodedString.replace(`where=`, `where`);
  decodedString = decodedString.replace(/withEq/g, `with[]=`);

  // Attached appended string if defined
  if (append) url = url + `/` + append;

  // Attach all parameters to queryable string;
  if (hasParameters) url += `?` + decodedString;

  // Call the fetch function and parse as JSON
  const request = await fetch(url, fetchOptions);
  const response = await request.json();
  return response;
};

const handlePATCH = async ({ append, fetchOptions, parameters, url }: fetchHandlerArgs) => {
  const id = parameters.id;
  delete parameters.id;

  let whereString = null;
  const multipleIDs = parameters?.ids && parameters.ids.length > 0;
  if (multipleIDs) whereString = idsToWhere(parameters.ids);
  if (multipleIDs) delete parameters.id;

  // Attach query IDs as 'where' string
  if (whereString) url = url + whereString;

  if (id) url = url + `/` + id;

  // Attached appended string if defined
  if (append) url = url + `/` + append;

  // Check the number of parameter keys defined in parmeter object is greater than 0
  let decodedWith;
  if (parameters.with) {
    const withString = parameters.with;
    delete parameters.with;
    decodedWith = withString.replace(/withEq/g, `with[]=`);
  }

  if (decodedWith) url += `?with[]=` + decodedWith;

  fetchOptions.body = JSON.stringify(parameters);

  // Call the fetch function and parse as JSON
  const request = await fetch(url, fetchOptions);
  const response = await request.json();
  return response;
};

const handlePOST = async ({ append, fetchOptions, parameters, url }: fetchHandlerArgs) => {
  // Attached appended string if defined
  if (append) url = url + `/` + append;

  // Check the number of parameter keys defined in parmeter object is greater than 0
  let decodedWith;
  if (parameters.with) {
    const withString = parameters.with;
    delete parameters.with;
    decodedWith = withString.replace(/withEq/g, `with[]=`);
  }

  // Attach all parameters to queryable string;
  if (decodedWith) url += `?with[]=` + decodedWith;

  fetchOptions.body = JSON.stringify(parameters);

  // Call the fetch function and parse as JSON
  const request = await fetch(url, fetchOptions);
  const response = await request.json();
  return response;
};

const handlePUT = async ({ append, fetchOptions, parameters, url }: fetchHandlerArgs) => {
  // Attached appended string if defined
  if (append) url = url + `/` + append;

  const value = parameters[Object.keys(parameters)[0]];
  const body = JSON.stringify(value);
  fetchOptions.body = body;

  // Call the fetch function and parse as JSON
  const request = await fetch(url, fetchOptions);
  const response = await request.json();
  return response;
};

const buildWith = (parameters: fetchParameters) => {
  if (!parameters._with) return parameters;

  const withArray = parameters._with.map((p, i) => {
    const isFirst = i === 0;

    let prepend = `&withEq`;
    if (isFirst) prepend = ``;
    return prepend + p;
  });
  const withString = withArray.join(``);

  delete parameters._with;
  parameters.with = withString;
  return parameters;
};

export const fetchAPI = async (args: fetchAPIArgs): Promise<any> => {
  const localTokenResult: IdTokenResult = localStorageRead(`localTokenResult`);
  const token = localTokenResult?.token;

  if (!token) console.warn(`No token defined in localStorage`);

  const append = args?.append;
  const method = args?.method;
  const route = args?.route;
  const initialParameters = args?.parameters;

  // If there's a protected where parameter, then merge it into
  // the existing where parameter
  const parametersWhere = initialParameters?.where;
  const parametersWhereProtected = initialParameters?.whereProtected;

  // This will only run once, as we are deleting 'whereProtected' after complete
  if (initialParameters && parametersWhereProtected) {
    // Create an empty 'where' string
    let where = ``;
    // If we have protected where parameters, then set them at the start of our 'where'
    if (parametersWhereProtected) where = parametersWhereProtected;

    // If we have other 'where' parameters, append them to our string
    if (parametersWhere) where += `&where${parametersWhere}`;

    // Assign the new where string to our API parameters, if we have any
    initialParameters.where = where ? where : undefined;

    // Delete the protected key so we don't run this twice
    if (parametersWhereProtected) delete initialParameters.whereProtected;
  }

  let parameters = { ...initialParameters };

  // Use the backend API URL base (e.g. https://localhost:9000) + the route
  const isStorybook: boolean = process.env.STORYBOOK_ENABLED === `true`;

  let fullAPIRoute = process.env.GATSBY_CORIGAN_API_BASE + `/` + route;
  if (isStorybook) fullAPIRoute = process.env.STORYBOOK_CORIGAN_API_BASE + `/` + route;

  const url = fullAPIRoute;

  // Construct fetch options using the getIdTokenResult Firebase value
  const fetchOptions = {
    body: null,
    headers: {
      authorization: null,
      'Content-Type': `application/json`,
    },
    method,
  };

  const bearer = token ? token : parameters.token;
  if (bearer) {
    fetchOptions.headers.authorization = `Bearer ${bearer}`;
    delete parameters.token;
  }

  parameters = buildWith(parameters);

  // Remove undefined parameters from parameter object
  Object.keys(parameters).forEach(key => parameters[key] === undefined && delete parameters[key]);

  let response;

  switch (method) {
    case `DELETE`:
      response = await handleDELETE({ append, fetchOptions, parameters, url });
      break;
    case `GET`:
      response = await handleGET({ append, fetchOptions, parameters, url });
      break;
    case `PATCH`:
      response = await handlePATCH({ append, fetchOptions, parameters, url });
      break;
    case `POST`:
      response = await handlePOST({ append, fetchOptions, parameters, url });
      break;
    case `PUT`:
      response = await handlePUT({ append, fetchOptions, parameters, url });
      break;
    default:
      response = await handleGET({ append, fetchOptions, parameters, url });
  }

  return response;
};

export default fetchAPI;

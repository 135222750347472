import styled from 'styled-components';
import { device } from 'particles';
import { hexToRGB } from 'corigan';

const handleHex = (hex, opaque = true) => {
  const rgb = hexToRGB(hex);
  const opacity = opaque ? `0.1` : `0.05`;

  return `rgba(${rgb}, ${opacity})`;
};

const StyledApplicationLabel = styled.section`
  * {
    border-radius: 0px;
  }

  a {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 18px 19px;
    position: relative;

    color: var(--colour);
    font-size: 0;
    font-weight: bold;
    line-height: 18px;
    transition: 0.2s border-left ease;

    @media ${device?.md} {
      padding: 18px 25px;
    }

    &::before {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      background-color: var(--colour);
      opacity: 1;
    }
  }

  button {
    /* Button Reset CSS */
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }

  span {
    flex-shrink: 0;
  }

  svg {
    display: block;
    max-height: 32px;
    max-width: 22px;

    color: var(--white);
    transition: 0.2s color ease;
  }

  .sidebar--open & {
    a {
      justify-content: flex-start;

      font-size: inherit;

      &::before {
        opacity: 0.1;
      }
    }

    > a {
      display: none;
    }

    svg {
      display: none;
    }

    .options {
      display: block;

      svg {
        display: block;
      }
    }
  }

  .options {
    display: none;
    position: relative;

    a {
      display: none;
      margin: 0;
      padding: 18px 20px;

      background-color: var(--white);
      border-bottom: 1px solid var(--grey100);
      border-left: 5px solid transparent;
      color: var(--colourLabel);
      font-weight: initial;
      transition: 0.2s background-color ease, 0.2s border-left ease;

      &:active,
      &:focus,
      &:hover {
        background-color: ${props => handleHex(props?.style?.[`--colour`], false)};
        border-left: 5px solid var(--colour);
        font-weight: bold;
      }
    }

    button {
      border-radius: 0;
      margin: 0;
      padding: 18px 20px;
      width: 100%;

      background-color: ${props => handleHex(props?.style?.[`--colour`])};
      border-left: 5px solid var(--colour);
      color: var(--colourLabel);
      font-weight: bold;
    }

    svg {
      margin-left: auto;
      height: 24px;
      max-height: 100%;
      max-width: 100%;

      color: inherit;
    }
  }

  .options__links {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2;

    background-color: var(--white);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .options--open {
    a {
      display: block;
    }
  }
`;

export default StyledApplicationLabel;

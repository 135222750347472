import * as React from 'react';

import StyledRowSkeleton from './skeleton.styles';

export interface CSSProperties extends React.CSSProperties {
  height?: string | number;
}

type RowSkeletonProps = {
  className?: string;
  colspan: number;
} & CSSProperties;

export const RowSkeleton = (props: RowSkeletonProps) => {
  const { className, colspan, height } = props;
  let classList = `row-skeleton`;
  if (className) classList += ` ${className}`;

  const style = { '--height': `${height}px` } as CSSProperties;

  return (
    <StyledRowSkeleton className={classList} style={style}>
      <td colSpan={colspan} />
    </StyledRowSkeleton>
  );
};

RowSkeleton.defaultProps = {
  colspan: 1,
  height: 100,
};

export default RowSkeleton;

import * as React from 'react';

function SvgFontIncrease(props) {
  return (
    <svg viewBox="0 0 26 24" {...props}>
      <path d="M6.188 4.094c-.4 0-.588.119-.688.218-.1.1-.213.3-.313.5l-5.093 16.5c-.1.3-.1.494 0 .594.1 0 .206.094.406.094h2.906c.2 0 .394-.087.594-.188.1-.1.313-.325.313-.625L5.218 18h5.343l.844 3.188c.1.3.181.524.281.625.1.1.325.187.626.187h3.28c.2 0 .313-.087.313-.188.1-.1.1-.2 0-.5L10.594 4.907c-.1-.3-.181-.393-.281-.593-.1-.1-.42-.22-.72-.22H6.189zm1.718 4.312H8L9.75 15H6.062l1.844-6.594zM21 9c-.6 0-1 .4-1 1v2h-2c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1h2v2c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-2h2c.6 0 1-.4 1-1v-1c0-.6-.4-1-1-1h-2v-2c0-.6-.4-1-1-1h-1z" />
    </svg>
  );
}

export default SvgFontIncrease;

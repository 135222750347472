import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { FormikProps } from 'formik';
import moment from 'moment';

// Particles
import { ApplicationContext } from 'corigan';
import { arrayChunk } from 'corigan';
import { CRContext } from 'corigan';
import { dateFormal } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { ROUTES } from 'corigan';

// Icons
import { ArrowDown } from 'icons';
import { ChevronLeft } from 'icons';
import { ChevronRight } from 'icons';

// Components
import { Button } from 'corigan';
import { Chip, Link, NewTab } from 'corigan';
import { Sparkline } from 'corigan';
import { SEOPreview } from 'corigan';

// Local Components
import StyledOverview from './overview.styles';

// Definitions
import { FormikValues } from '../../definitions';

declare type PageOverviewProps = {
  data: PerformanceComparisonPage;
  formik: FormikProps<FormikValues>;
  internal: boolean;
};

const PageOverview = (props: PageOverviewProps) => {
  const { data, formik, internal } = props;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainName = domainActive?.hostname;

  const context = useContext(CRContext);
  const state = context?.state;
  const competitor: string = state?.competitor?.hostname;

  const account: string = internal ? domainName : competitor;

  const metaDescription = data?.metaDescription;
  const metaTitle = data?.metaTitle;
  const h1 = data?.h1;
  const redirects = data?.redirects;
  const table = data?.table;
  const url = data?.url;

  const metaData = {
    description: metaDescription,
    title: metaTitle,
  };

  const categories = data?.categories;

  return (
    <StyledOverview>
      <PageHeader formik={formik} internal={internal} url={url} />
      <PageCategories account={account} categories={categories} />
      <PageMeta metaData={metaData} h1={h1} />
      <PageKeywords account={account} keywordChanges={table} />
      <PageStatusChanges account={account} redirects={redirects} url={url} />
      <PageContent />
    </StyledOverview>
  );
};

declare type PageHeaderProps = {
  formik: FormikProps<FormikValues>;
  internal: boolean;
  url: string;
};

const PageHeader = (props: PageHeaderProps) => {
  const { formik, internal, url } = props;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainName = domainActive?.hostname;

  const context = useContext(CRContext);
  const state = context?.state;
  const competitor: string = state?.competitor?.hostname;

  const page: PageRevision = formik?.values?.page;
  const name: string = page?.name;

  const account: string = internal ? domainName : competitor;

  let heading: string = account;
  if (name) heading += ` - ${name}`;

  const tabBoolean = name && url;
  const tabText: string = `Open ${name}`;

  return (
    <React.Fragment>
      <h2 className="overview__title text--initial">
        {heading}
        {tabBoolean && <NewTab href={url} text={tabText} />}
      </h2>
      {url && (
        <Link className="overview__link" href={url} target="_blank">
          {url}
        </Link>
      )}
    </React.Fragment>
  );
};

declare type PageCategoriesProps = {
  account: string;
  categories: any;
};

const PageCategories = (props: PageCategoriesProps) => {
  const { account, categories } = props;

  const hasCategories: boolean = categories?.length > 0;
  let formattedCategories: { title: string }[] = [];

  if (hasCategories) {
    formattedCategories = categories.map(category => {
      return { title: category };
    });
  }

  return (
    <React.Fragment>
      <h2 className="overview__categories__heading">Page Categories</h2>
      <section>
        {hasCategories &&
          formattedCategories.map(cat => (
            <Chip variant="plain" key={`${account}-${cat.title}`}>
              {cat.title}
            </Chip>
          ))}
        {!hasCategories && <p>No categories were found.</p>}
      </section>
    </React.Fragment>
  );
};

declare type PageMetaProps = {
  metaData?: {
    description: string;
    title: string;
  };
  h1?: [string];
};

const PageMeta = (props: PageMetaProps) => {
  const { metaData, h1 } = props;

  return (
    <React.Fragment>
      <h2 className="overview__meta__heading">Page Meta Data</h2>
      {metaData && <SEOPreview className="mt-1" {...metaData} />}
      {!metaData && <p>No SEO meta data was found.</p>}
      <h2 className="overview__meta__heading">Heading 1</h2>
      {h1 && (
        <React.Fragment>
          {h1.map(heading => (
            <p key={heading}>{heading}</p>
          ))}
        </React.Fragment>
      )}
      {!h1 && <p>No Heading 1 found.</p>}
    </React.Fragment>
  );
};

declare type KeywordSparklineProps = {
  id: string;
  positions?: any[];
};

const KeywordSparkline = (props: KeywordSparklineProps) => {
  const { id, positions } = props;
  const ordered: any[] = positions.sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)));

  const hasOrdered: boolean = ordered?.length > 0;
  if (!hasOrdered) return null;

  const startIndex: number = 0;
  const endIndex: number = positions.length - 1;

  const dateStart: string = positions[startIndex].date;
  const dateEnd: string = positions[endIndex].date;
  const seriesPositions: number[] = ordered.map(r => Math.round(Number(r.position)));

  // Get an array of the last 12 month dates formatted
  const momentStart: moment.Moment = moment(dateStart);
  const momentEnd: moment.Moment = moment(dateEnd);

  const { dates } = getWeeksBetween(momentStart, momentEnd, true);

  const options: any = {
    tooltip: {
      y: { title: { formatter: v => `` } },
    },
    xaxis: {
      type: `datetime`,
      categories: dates,
    },
    yaxis: {
      min: 1,
      reversed: true,
    },
  };

  return <Sparkline id={id} options={options} series={seriesPositions} height="40px" width="120px" />;
};

declare type PageKeywordsProps = {
  account: string;
  keywordChanges: any[];
};

declare type ValidOrderBy =
  | 'currentPosition+'
  | 'keyword+'
  | 'searchVolume+'
  | 'currentPosition-'
  | 'keyword-'
  | 'searchVolume-';

const PageKeywords = (props: PageKeywordsProps) => {
  const { account, keywordChanges } = props;
  const [chunkIndex, setChunkIndex] = useState(0);
  const [orderBy, setOrderBy] = useState<ValidOrderBy>(`keyword-`);

  const count: number = keywordChanges?.length;
  const hasChanges: boolean = count > 0;
  const chunkSize: number = 5;

  let keywords = keywordChanges;

  if (hasChanges && orderBy) {
    switch (orderBy) {
      case `currentPosition+`:
        keywords = keywords.sort((a, b) => Number(b.currentPosition) - Number(a.currentPosition));
        break;

      case `currentPosition-`:
        keywords = keywords.sort((a, b) => Number(b.currentPosition) - Number(a.currentPosition)).reverse();
        break;

      case `keyword+`:
        keywords = keywords.sort((a, b) => a?.keyword.localeCompare(b?.keyword)).reverse();
        break;

      case `keyword-`:
        keywords = keywords.sort((a, b) => a?.keyword.localeCompare(b?.keyword));
        break;

      case `searchVolume+`:
        keywords = keywords.sort((a, b) => Number(b.searchVolume) - Number(a.searchVolume));
        break;

      case `searchVolume-`:
        keywords = keywords.sort((a, b) => Number(b.searchVolume) - Number(a.searchVolume)).reverse();
        break;

      default:
        break;
    }
  }

  let chunkedChanges: any[] = [];
  if (hasChanges) chunkedChanges = arrayChunk(keywords, chunkSize);
  const hasChunkedChanges: boolean = chunkedChanges?.length > 0;

  const currentChunk = chunkedChanges[chunkIndex];

  const THButton = (props: { children: React.ReactNode; stateKey: 'currentPosition' | 'keyword' | 'searchVolume' }) => {
    const { children, stateKey } = props;
    const isActive = orderBy.includes(stateKey);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e) e.preventDefault();
      let newKey = stateKey;

      if (isActive) {
        const wasAscending = orderBy.includes(`+`);
        if (wasAscending) newKey = stateKey + `-`;
        if (!wasAscending) newKey = stateKey + `+`;
      } else {
        newKey = stateKey + `+`;
      }

      setOrderBy(newKey);
      setChunkIndex(0);
    };

    const direction = orderBy.includes(`+`) ? `ascending` : `descending`;

    let classList = `button--reset`;
    if (isActive) classList += ` enabled`;
    if (isActive) classList += ` button--${direction}`;

    return (
      <th>
        <button className={classList} onClick={handleClick}>
          {children}
          {isActive && <ArrowDown />}
        </button>
      </th>
    );
  };

  const currentPage: number = chunkIndex + 1;
  let to: number = currentPage * chunkSize;
  const total = count;
  const from: number = to - chunkSize + 1;
  const lastPage = Math.ceil(count / chunkSize);
  const prevPage = currentPage === from ? from : currentPage - 1;
  const nextPage = currentPage === to ? to : currentPage + 1;
  if (to > total) to = total;

  const setPage = (page: number) => {
    setChunkIndex(page - 1);
  };

  return (
    <React.Fragment>
      <h2 className="overview__keywords__heading">Ranking Keywords</h2>
      {!hasChunkedChanges && <p>This page doesn&rsquo;t rank for any keywords.</p>}
      {hasChunkedChanges && (
        <React.Fragment>
          <Pagination
            currentPage={currentPage}
            to={to}
            from={from}
            lastPage={lastPage}
            prevPage={prevPage}
            nextPage={nextPage}
            total={total}
            setPage={setPage}
          />
          <div className="table--orderby table--responsive">
            <table className="mt-0">
              <thead>
                <tr>
                  <THButton stateKey="keyword">Keyword</THButton>
                  <THButton stateKey="searchVolume">Search Volume</THButton>
                  <THButton stateKey="currentPosition">Current Rank</THButton>
                  <th>History</th>
                </tr>
              </thead>
              <tbody>
                {currentChunk.map(keyword => {
                  const { currentPosition, keyword: phrase, positions, searchVolume } = keyword;

                  const keywordId = keyword?.keywordId;
                  const title: string = keyword.keyword + ` ` + account;
                  let key: string = title;
                  if (keywordId) key += ` ${keywordId}`;

                  // Do we have keyword position data?
                  const hasPositions: boolean = positions?.length > 1;
                  const href = keywordId ? `${ROUTES.keyword}?id=${keywordId}` : undefined;

                  return (
                    <tr key={key}>
                      {!href && <td>{phrase}</td>}
                      {href && (
                        <td>
                          <Link href={href}>{phrase}</Link>
                        </td>
                      )}
                      <td>{searchVolume}</td>
                      <td>{currentPosition}</td>
                      <td>
                        {!hasPositions && <span>No data</span>}
                        {hasPositions && <KeywordSparkline id={title} positions={positions} />}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

declare type PaginationProps = Pagination & { setPage: Function };

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { currentPage, from, lastPage, nextPage, prevPage, to, total } = props;
  const { setPage } = props;

  const nextDisabled: boolean = currentPage === lastPage;
  const previousDisabled: boolean = currentPage <= 1;

  const handlePrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    setPage(prevPage);
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    setPage(nextPage);
  };

  return (
    <div className="mb-2 display--flex align-items--center justify-content--between">
      <p>
        Currently showing {from} - {to} of <span className="table__total__count">{total}</span>
      </p>
      <nav>
        <Button disabled={previousDisabled} onClick={handlePrevious}>
          <ChevronLeft />
          <span className="hidden">Previous</span>
        </Button>
        <Button className="ml-1" disabled={nextDisabled} onClick={handleNext}>
          <span className="hidden">Next</span>
          <ChevronRight />
        </Button>
      </nav>
    </div>
  );
};

declare type PageStatusChangesProps = {
  account: string;
  redirects?: any[];
  url: string;
};

const PageStatusChanges = (props: PageStatusChangesProps) => {
  const { redirects: redirectsAll } = props;
  const hasRedirectsAll: boolean = redirectsAll?.length > 0;

  const redirects = !hasRedirectsAll
    ? []
    : redirectsAll.filter(redirect => {
        const changeSignificance = redirect?.changeSignificance;
        const isSignificant = changeSignificance && changeSignificance !== `info`;
        return isSignificant;
      });

  const hasRedirects: boolean = redirects?.length > 0;

  return (
    <React.Fragment>
      <h2 className="overview__status__heading">Detected Structural Changes</h2>
      {!hasRedirects && <p>No structural changes including this url were found.</p>}
      {hasRedirects && (
        <div className="table--responsive">
          <table className="mt-1">
            <thead>
              <tr>
                <th>Date Detected</th>
                <th>Status Code</th>
                <th>Previous URL</th>
                <th>New URL</th>
              </tr>
            </thead>
            <tbody>
              {redirects.map(redirect => {
                return (
                  <tr key={redirect}>
                    <td className="text--nowrap">{dateFormal(new Date(redirect.createdAt))}</td>
                    <td>{redirect.statusCode > 0 ? redirect.statusCode : `-`}</td>
                    <td>{redirect.previousUrl ? redirect.previousUrl : `-`}</td>
                    <td>{redirect.newUrl ? redirect.newUrl : `-`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

declare type PageContentProps = {};

// TODO: Page Content
// TODO: Output of all tracked page content that comes from the site scraper in different blocks e.g Footer, paragraph.
const PageContent = (props: PageContentProps) => {
  return <React.Fragment></React.Fragment>;
};

export default PageOverview;

import * as React from 'react';

import { ApplicationState } from 'corigan';
import { useFirebase } from 'corigan';

type RootWrapperProps = {
  element: React.ReactNode;
};

export const wrapRootElement = ({ element }: RootWrapperProps) => <App element={element} />;

// We cannot assign wrapRootElement to a React function component directly
// https://github.com/gatsbyjs/gatsby/issues/22833
const App: React.FC<RootWrapperProps> = (props: RootWrapperProps) => {
  const { element } = props;
  const windowAvailable = typeof window !== `undefined`;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const firebase = windowAvailable && useFirebase();

  return <ApplicationState firebase={firebase}>{element}</ApplicationState>;
};

export default wrapRootElement;

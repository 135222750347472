import { css } from 'styled-components';

export const keywordDecoratorStyles = css`
  .editor__keyword {
    color: var(--red);
    font-weight: bold;
  }

  .editor__keyword--linked {
    color: var(--blue);
  }

  .editor__keyword--highlight {
    padding: 5px 4px;
  }

  .editor__keyword--warning {
    background-color: var(--orange);
    color: var(--white);
  }

  .editor__keyword--multiple {
    background-color: var(--red);
    color: var(--white);
  }
`;

export default keywordDecoratorStyles;

import { fetchAPI } from 'particles';

type CreateDomainParameters = {
  hostname: string;
  domainType: string;
  region: string;
  competesWith: ArgID;
};

export const callCreateDomain = async (parameters: CreateDomainParameters): Promise<{ data: Domain }> => {
  const response = await fetchAPI({ method: `POST`, parameters, route: `domains` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreateDomain;

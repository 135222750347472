import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { dateFormal } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { windowAvailable } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { HR, Link, Table } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetCrawl } from 'corigan';
import { callGetStructureChanges } from 'corigan';

// Local Partials
import { ChipKeys } from '../partials';
import { columns } from '../partials';
import { generateRows } from '../partials';

const csvColumns: {
  label: string;
  value: string;
}[] = [
  { label: `Significance`, value: `changeSignificance` },
  { label: `Status Code`, value: `statusCode` },
  { label: `Prev Protocol`, value: `previousProtocol` },
  { label: `Prev Site`, value: `previousDomain` },
  { label: `Prev URL`, value: `previousUrl` },
  { label: `New Protocol`, value: `newProtocol` },
  { label: `New Site`, value: `newDomain` },
  { label: `New URL`, value: `newUrl` },
];

declare type StructureChangesPageProps = {
  children?: React.ReactNode;
};

const StructureChanges: React.FC<StructureChangesPageProps> = (props: StructureChangesPageProps) => {
  return (
    <ProtectedRoute>
      <Page application="competitor-research" pageTitle="Crawl Structure Changes">
        <PageContents />
      </Page>
    </ProtectedRoute>
  );
};

const PageContents: React.FC = () => {
  const queryParameters = useQueryParameters();
  const { id, category } = queryParameters;

  useEffect(() => {
    document.title = `Corigan - Competitor Research: Crawl Structure Changes - ${category}`;
  }, [category]);

  const [mounted, setMounted] = useState<boolean>(false);
  const { releaseVersion } = getRelease();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=crStructureChanges-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Create initial API arguments
  const initialWhere: ArgWhere = undefined;
  const initialOrderBy: ArgOrderBy = undefined;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;
  const initialWith: ArgWith = undefined;

  // Read local storage values
  const localOrderBy: ArgOrderBy = localStorageRead(buildKey(`OrderBy`))
    ? localStorageRead(buildKey(`OrderBy`))
    : initialOrderBy;
  const localPage: ArgPage = localStorageRead(buildKey(`Page`)) ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = localStorageRead(buildKey(`PerPage`))
    ? localStorageRead(buildKey(`PerPage`))
    : initialPerPage;
  const localWhere: ArgWhere = localStorageRead(buildKey(`Where`)) ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }

      return newValue;
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = (value: any) => setStateValue(`Page`, value);
  const setPerPage = (value: any) => setStateValue(`PerPage`, value);
  const setWhere = (value: any) => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainActive?.id]);

  const whereProtected: ArgWhere = `[crawl][eq]=${id}&where[categoryNames][arrayContains]=${category}`;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [_with, setWith] = useState<ArgWith>(initialWith);

  const queryFunction = callGetStructureChanges;
  const queryName = `callGetStructureChanges`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected, _with };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage, setWith };

  const hasDomain: boolean = Boolean(domainActive?.id);
  const enabled: boolean = hasDomain;

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination: Pagination = res?.pagination;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data });
  }, [data]);

  // Query the parent crawl so we have a consistent date
  const parentCrawl = useQuery([`callGetCrawl`, { id, _with }], callGetCrawl);
  const parentCrawlData = parentCrawl?.data?.data;
  const hasParentDate = Boolean(parentCrawlData?.crawlDate);

  let csvTitle = `structure-changes-${category}`;
  if (hasParentDate) csvTitle += `-${dateFormal(parentCrawlData.crawlDate)}`;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={`${ROUTES.crawl}?id=${id}`}>Category List</Link>
            <h1>Category URLs: {category}</h1>
          </Breadcrumbs>
          {hasParentDate && <h2 className="text--lowercase">{dateFormal(parentCrawlData.crawlDate)}</h2>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {mounted && (
              <Table
                apiArgs={apiArgs}
                apiDetails={apiDetails}
                apiFunctions={apiFunctions}
                allowFilters={true}
                collectionType="crawl"
                columns={columns}
                csvColumns={csvColumns}
                csvTitle={csvTitle}
                error={error}
                filtersToHide={[`categoryNames`]}
                id="mastersheet-structure-changes"
                items={items}
                loading={loading}
                pagination={pagination}
                selectable={true}
                small={false}
                exportArgs={apiArgs}
                exportRoute={`crawlurls`}
              />
            )}
            <HR background="var(--grey200)" variant="large" />
            <ChipKeys />
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default StructureChanges;

import { fetchAPI } from 'particles';

type GetPageParameters = {
  id: ArgID;
  status: ArgStatus;
  _with?: ArgWith;
};

export const callGetPage = async (queryKey, parameters: GetPageParameters): Promise<{ data: Page }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `pages` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetPage;

import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { default as loadable } from '@loadable/component';

// Particles
import { deepMerge } from 'corigan';

// Components
import { Skeleton } from 'corigan';

// Localised Particles
import baseOptions from './pie.json';

type PieProps = {
  height?: number | string;
  id: string;
  onLoad?: Function;
  options?: any;
  series: any[];
  width?: number | string;
};

type ChartOptions = {
  chart?: any;
  colors?: string[];
  fill?: any;
  legend?: any;
  markers?: any;
  stroke?: any;
  xaxis?: any;
  yaxis?: any;
};

export const Pie = (props: PieProps) => {
  const [loaded, setLoaded] = useState(false);
  const { height, id, onLoad, options: overwrite, series, width } = props;

  const windowAvailable = typeof window !== `undefined`;

  let options: ChartOptions = { ...baseOptions };

  options = deepMerge(options, {
    chart: {
      events: {
        beforeMount: () => {
          setLoaded(true);
        },
      },
    },
  });

  useEffect(() => {
    if (loaded) return;
    if (onLoad) onLoad();
    setLoaded(true);
  }, [loaded, onLoad]);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `450px 0px 0px 0px`,
    triggerOnce: true,
  });

  // Will force the state to be loaded if no method call from ApexCharts
  useEffect(() => {
    const timeoutID =
      windowAvailable &&
      window.setTimeout(() => {
        setLoaded(true);
      }, 1000);

    return () => windowAvailable && window.clearTimeout(timeoutID);
  }, [inView, windowAvailable]);

  if (overwrite) options = deepMerge(options, overwrite);
  if (id) options.chart.id = id;

  return (
    <div ref={ref}>
      {!loaded && <Skeleton height={height} />}
      <LoadableChart height={loaded ? height : 1} options={options} series={series} type="donut" width={width} />
    </div>
  );
};

Pie.defaultProps = {
  width: `100%`,
};

export default Pie;

// import react-apexcharts here
const LoadableChart = loadable(() => import(`../../../../../../../node_modules/react-apexcharts/src/react-apexcharts`));

import * as React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import slugify from 'slugify';
import { ParsedQuery } from 'query-string';

// Particles
import { brandColours } from 'corigan';
import { exportTableToCSV } from 'corigan';
import { getRelease } from 'corigan';
import { objectIsEmpty } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { toTitleCase } from 'corigan';
import { windowAvailable } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Button } from 'corigan';
import { Toggle } from 'corigan';
import { Card, Breadcrumbs } from 'corigan';
import { Error, Info } from 'corigan';
import { GapGroup } from 'corigan';
import { ChartRadar } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Local Particles
import { ApplicationContext } from 'corigan';
import { Skeleton } from './partials/skeleton';
import { TableHandler } from './partials/table-handler';
import StyledCoverage from './coverage-map.styles';
import generateSeries from './functions/generateSeries';
import getCompetitors from './functions/getCompetitors';

// API
import { callGetReportKeywordCoverage } from 'corigan';

const KeywordCoverageMap = () => (
  <ProtectedRoute>
    <Page application="keyword-research" pageTitle="Coverage Map">
      <PageWrapper />
    </Page>
  </ProtectedRoute>
);

const PageWrapper = () => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const [mounted, setMounted] = useState<Boolean>(false);

  // On change of active domain...
  useEffect(() => {
    setMounted(false);

    const timerMounted = setTimeout(() => setMounted(true), 10);

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timerMounted);
    };
  }, [domainId]);

  if (!mounted) return null;
  return <PageContents />;
};

const topOptions = [
  { label: `Top 1`, value: `1` },
  { label: `Top 3`, value: `3` },
  { label: `Top 10`, value: `10` },
  { label: `Top 100`, value: `100` },
];

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

const cacheTime = 4 * HOUR;

const PageContents = () => {
  // Build API arguments / parameters
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const { releaseVersion } = getRelease();

  const queryParams: ParsedQuery<string> = useQueryParameters();

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=krCoverageMap-${key}&domainId=${domainId}`;
    },
    [domainId, releaseVersion],
  );

  // Read local storage values
  const initialEnabled: object = {};
  const initialTop: string = `100`;
  const initialLevels: object[] = [];

  const hasLocalEnabled = Boolean(localStorageRead(buildKey(`EnabledCategories`)));
  const hasLocalTop = Boolean(localStorageRead(buildKey(`Top`)));
  const hasLocalLevels = Boolean(localStorageRead(buildKey(`Levels`)));
  const localEnabled: object = hasLocalEnabled ? localStorageRead(buildKey(`EnabledCategories`)) : initialEnabled;
  const localTop: string = hasLocalTop ? localStorageRead(buildKey(`Top`)) : initialTop;
  const localLevels: string = hasLocalLevels ? localStorageRead(buildKey(`Levels`)) : initialLevels;

  // Create a function which sets the value of a new 'EnabledCategories' argument to localStorage
  const setEnabledCategories = useCallback(
    value => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(`EnabledCategories`), value);

      return stateSetEnabledCategories(value);
    },
    [buildKey],
  );

  const initialValues = {
    levels: [],
    top: localTop,
  };

  const formikConfig = {
    initialValues,
    onSubmit: values => console.info(values),
  };

  const formik = useFormik(formikConfig);

  const { values } = formik;
  const { levels, top } = values;

  // These will be handled by the data response
  const [categories, setCategories] = useState<string[]>([]);

  // This local state store should be informed by the active parent
  const [enabledCategories, stateSetEnabledCategories] = useState<object>(localEnabled);
  const [testNum, setTestNum] = useState<object>({test: 0});

  // When the top value is changed in Formik state store, update the localStorage value
  useEffect(() => {
    const hasWindow = windowAvailable();
    if (!hasWindow) return;

    localStorageSet(buildKey(`Top`), top);
  }, [buildKey, top]);

  // When the levels value is changed in Formik state store, update the localStorage value
  useEffect(() => {
    const hasWindow = windowAvailable();
    if (!hasWindow) return;

    localStorageSet(buildKey(`Levels`), levels);
  }, [buildKey, levels]);

  const encodedLevels = Buffer.from(JSON.stringify(levels)).toString(`base64`);
  const parameters = { levels: encodedLevels, top, domainId };

  const queryEnabled: boolean = Boolean(top);
  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetReportKeywordCoverage`, parameters],
    callGetReportKeywordCoverage,
    { cacheTime, enabled: queryEnabled },
  );
  const data = res?.data;
  const hasData: boolean = !objectIsEmpty(data);

  // Capture the categories on initialization
  useEffect(() => {
    if (!hasData) return;

    const titleCategories: string[] = Object.keys(data);
    setCategories(titleCategories);
    enabledCategories[levels.length] = titleCategories;
    stateSetEnabledCategories(enabledCategories);
  }, [data, hasData, stateSetEnabledCategories]);

  let title: string = `Number of Keywords in SERPS`;
  if (levels.length) title = `Number of Keywords in SERPS - ${levels.map(level => toTitleCase(level.name)).join(` > `)}`;

  let subtitle: string = `Top Level Categories Ranking amongst competitors`;
  if (levels.length) subtitle = `${levels.map(level => toTitleCase(level.name)).join(` > `)} - Subcategories Ranking amongst competitors`;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <h1>{title}</h1>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          {error && <Error error={error} />}
          {useMemo(() =>
            <CoverageMapContents
              buildKey={buildKey}
              categories={categories}
              data={data}
              formik={formik}
              loading={loading}
              subtitle={subtitle}
              enabledCategories={enabledCategories}
              setEnabledCategories={setEnabledCategories}
              query={queryParams}
            />, [
              buildKey,
              categories,
              data,
              formik,
              loading,
              subtitle,
              enabledCategories,
              setEnabledCategories,
            ]
          )}
        </Col>
      </Row>
    </Grid>
  );
};

declare type CoverageMapContentsProps = {
  buildKey: Function;
  categories: any;
  data: any;
  formik: any;
  loading: boolean;
  subtitle?: string;
  enabledCategories: object;
  setEnabledCategories: Function;
  query: any;
};

const CoverageMapContents = (props: CoverageMapContentsProps) => {
  const [renderingChart, setRenderingChart] = useState<boolean>(false);

  const { buildKey, categories, data, formik, enabledCategories, setEnabledCategories, query } = props;
  const { loading, subtitle } = props;

  const { handleBlur, handleChange, setFieldValue, values } = formik;
  const { levels, top } = values;
  const parent = levels.length ? levels[levels.length - 1] : null;

  const initialSeriesDisabled: string[] = [];
  const hasLocalSeriesDisabled = Boolean(localStorageRead(buildKey(`SeriesDisabled`)));
  const localSeriesDisabled: string[] = hasLocalSeriesDisabled
    ? localStorageRead(buildKey(`SeriesDisabled`))
    : initialSeriesDisabled;
  const hasSeriesDisabled: boolean = localSeriesDisabled?.length > 0;

  const hasData: boolean = !objectIsEmpty(data);
  const competitors: string[] = hasData && getCompetitors({ data });
  const hasCategories: boolean = categories?.length > 0;
  const hasCompetitors: boolean = competitors?.length > 0;
  const currentEnabledCategories: string[] = enabledCategories[levels.length] ?? []
  const hasEnabled: boolean = currentEnabledCategories?.length > 0;
  const enabledAlphabetical: string[] = hasEnabled && currentEnabledCategories.sort();

  // If the number of categories enabledParents is equal to the number of categories
  // available then we already have 'all' on.
  const allOn: boolean = currentEnabledCategories?.length === categories?.length;

  const chartID: string = `categories-comparison`;
  const isBusy: boolean = loading || renderingChart;

  const { dataTable, series } = generateSeries({ categories: currentEnabledCategories, competitors, data });

  // Get an array of the brand colours, with their names
  const brandingColours = brandColours();

  // Create a cached array of colours
  const colors: string[] = useMemo(() => {
    if (!hasCompetitors) return null;

    // Loop over each enabled series
    const value = competitors.map(comeptitor => {
      // Look up the brand colours array, and see if there's a match
      const definedColour = brandingColours.find(brandObject => {
        const { brand } = brandObject;

        // Lowercase strings for comparison
        const lowerBrand: string = brand?.toLowerCase();
        const lowerName: string = comeptitor?.toLowerCase();

        // Was there a match? Searching enabled plot for substring of brand name
        // E.g. does www.amazon.co.uk include amazon?
        const found: boolean = lowerName.includes(lowerBrand);
        return found;
      });

      // If found, return the defined colour
      if (definedColour?.colour) return definedColour.colour;

      // Otherwise return a shade of grey
      return `#353535`;
    });

    return value;
  }, [brandingColours, competitors, hasCompetitors]);

  const options: ChartOptions = useMemo(() => {
    const onLegendClick = (chartContext, seriesIndex, config) => {
        const hasWindow = windowAvailable();
        if (!hasWindow) {
        console.warn(`No window object available to set/read localStorage`);
        return;
        }

        const seriesName: string = series?.[seriesIndex]?.name;

        if (!seriesName) {
        console.warn(`Unable to detect series at index ${seriesIndex}`);
        return;
        }

        const currentDisabled = localStorageRead(buildKey(`SeriesDisabled`));
        const hasCurrentDisabled = currentDisabled?.length > 0;

        // If we have no legends disabled, create the array with the clicked legend name
        if (!hasCurrentDisabled) {
        localStorageSet(buildKey(`SeriesDisabled`), [seriesName]);
        return;
        }

        let newSeriesDisabled = [...currentDisabled];
        const exists: boolean = newSeriesDisabled.some(name => name === seriesName);

        // If the legends exists, remove it from the current array of legends
        if (exists) newSeriesDisabled = newSeriesDisabled.filter(name => name !== seriesName);

        // If the legends doesn't exist, append it to the array of legends
        if (!exists) newSeriesDisabled = [...newSeriesDisabled, seriesName];

        localStorageSet(buildKey(`SeriesDisabled`), newSeriesDisabled);
    };

    const categories = currentEnabledCategories?.map(k => toTitleCase(k));

    return {
      chart: {
        events: {
          beforeMount: (chartContext, config) => {
            setRenderingChart(true);
          },
          mounted: (chartContext, config) => {
            setRenderingChart(false);
          },
          legendClick: onLegendClick,
        },
      },
      colors,
      xaxis: {
        type: `category`,
        categories,
      },
      yaxis: {
        reversed: true,
      },
    };
  }, [buildKey, colors, currentEnabledCategories, series]);

  const handleToggleChange = useCallback(
    e => {
      const value: string = e?.target?.name;
      if (!value) return;

      const newEnabledCategories = JSON.parse(JSON.stringify(enabledCategories));

      const isAll: boolean = value === `showAll`;
      const alreadyAll: boolean = allOn;

      // If there are categories missing, set them to enabled
      if (isAll && !alreadyAll) {
        newEnabledCategories[levels.length] = categories;
        setEnabledCategories(newEnabledCategories);
        return;
      }

      // If we aren't setting categories, disable event
      if (isAll) {
        return;
      }

      let newEnabled: string[] = currentEnabledCategories;
      const alreadyOn: boolean = currentEnabledCategories?.some(c => c === value);

      const shouldAdd: boolean = !alreadyOn;
      const shouldRemove: boolean = alreadyOn;

      if (shouldAdd) newEnabled = [...newEnabled, value];
      if (shouldRemove) newEnabled = currentEnabledCategories?.filter(c => c !== value);

      newEnabledCategories[levels.length] = newEnabled;
      setEnabledCategories(newEnabledCategories);
    },
    [allOn, categories, currentEnabledCategories, setEnabledCategories],
  );

  const handleExportTable = async e => {
    if (e) e.preventDefault();

    const csvTitle = `Coverage Map`;

    const items = Object.entries(dataTable).map(([competitor, categories]) => {
      const item = { competitor };

      const c = categories as { category: string; value: string }[];
      const hasCategories = c?.length > 0;
      if (!hasCategories) return item;

      const sortedCategories = c.sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase()));
      sortedCategories.forEach(entry => {
        const { category, value } = entry;
        item[category] = value;
      });

      return item;
    });

    const columnsDefault = [{ value: `competitor`, label: `Competitor` }];
    const columnsEnabled = enabledAlphabetical.map(value => {
      const label = toTitleCase(value);
      return { label, value };
    });
    const columns = [...columnsDefault, ...columnsEnabled];

    await exportTableToCSV(csvTitle, columns, items);
  };

  const handleLevelChange = e => {
    if (e) e.preventDefault();
    const newLevel = {
      level: levels.length + 1,
      name: e.target.value,
    }
    const newLevels = [...levels, newLevel];
    setFieldValue(`levels`, newLevels);
  };

  const handleDownLevelChange = e => {
    if (e) e.preventDefault();

    const newLevels = [...levels];
    newLevels.pop();

    setFieldValue(`levels`, newLevels);
  };

  return (
    <Card loading={isBusy}>
      <StyledCoverage>
        <header>
          <h2>{subtitle}</h2>
          <select title="top 100" id="top" onBlur={handleBlur} onChange={handleChange} name="top" value={top}>
            {topOptions.map(({ label, value }) => (
              <option key={`${label}-${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
          {hasEnabled && hasCompetitors &&(
            <select title="explorer subcategories" id="parent" onBlur={handleBlur} onChange={handleLevelChange} name="parent" value={parent}>
              <option value="initial">Explore subcategories</option>
              {currentEnabledCategories.map(parentCategory => (
                <option key={`parent-${parentCategory}`} value={parentCategory}>
                  {toTitleCase(parentCategory)}
                </option>
              ))}
            </select>
          )}
          {Boolean(levels.length) && (
            <Button onClick={handleDownLevelChange} variant="hollow">
              Up One
            </Button>
          )}
        </header>
        <section className="coverage__contents">
          {hasCategories && (
            <GapGroup className="mt-3">
              <Toggle id="showAll" label="Show All" on={allOn} onChange={handleToggleChange} small={true}>
                Show all on the chart
              </Toggle>
              {categories.map(c => {
                const label = toTitleCase(c);
                const value = c;
                const on = currentEnabledCategories?.includes(value);

                return (
                  <Toggle id={value} key={value} label={label} on={on} onChange={handleToggleChange} small={true}>
                    Show {value} on the chart
                  </Toggle>
                );
              })}
            </GapGroup>
          )}
          {!hasCompetitors && !loading && !hasCategories && <Info>There is no data to display</Info>}
          {hasCompetitors && !loading && <ChartRadar height="auto" width="100%" id={chartID} options={options} series={series} />}
        </section>
        {hasCompetitors &&
          <TableHandler categories={levels} top={top} query={query}/>
        }
      </StyledCoverage>
    </Card>
  );
};

export default KeywordCoverageMap;

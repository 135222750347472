import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

// Particles
import { ApplicationContext } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Table, Error, Info, Loader } from 'corigan';
import { Card } from 'corigan';

// Local Paritals
import { generateRows } from './data/generateRows';
import RankingsTableFilter from './partials/filter';
import DailySERPRankingsExport from './partials/export';

// API
import { callGetReportDailySERPRankings } from 'corigan';

type DailySERPRankingsProps = {};

declare type WhereArray = {
  searchText: string;
  searchType: string;
  fromDate: string;
  toDate: string;
};

// define searchTypes for dropdown
const searchTypes = [
  { label: `Keyword`, value: `keyword` },
  { label: `Category`, value: `category` },
  { label: `Tag`, value: `tag` },
];

/**
 * deconstrust the where string to object for population of the form values
 * @param {string} where the built whre object from form values
 * @returns where string
 */
const whereDeconstrutor = (where) => {
  if(where !== undefined){
    const stringArray = where.split(`&`);
    if (stringArray.length > 0) {
      const mergeArray = stringArray.filter((item) => {
        if (item === ``) {
          return false;
        }
        return true;
      }).map((param) => {
        const items = param.split(`=`);
        if(items[0] === `searchType`) {
          for (const type of searchTypes) {
            if (items[1] === type.value) {
              items[1] = type.value;
            } else {
              continue;
            }
          }
        }
        return {[items[0]]: items[1]};
      });
      return mergeArray.reduce((result, current) => {
        return Object.assign(result, current);
      });
    }
  }
  return { searchText: ``, searchType: [], fromDate: ``, toDate: `` };
};

const DailySERPRankingsDetails: React.FC<DailySERPRankingsProps> = () => {
  const { releaseVersion } = getRelease();
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=rbDailyRankings${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Create initial API arguments
  const initialWhere: ArgWhere = `searchType=${searchTypes[0].value}`;
  const initialOrderBy: ArgOrderBy = `-keyword`;
  const initialPage: ArgPage = undefined;
  const initialPerPage: ArgPerPage = 10;

  const getLocalOrderBy: boolean = Boolean(localStorageRead(buildKey(`OrderBy`)));
  const getLocalPage: boolean = Boolean(localStorageRead(buildKey(`Page`)));
  const getLocalPerPage: boolean = Boolean(localStorageRead(buildKey(`PerPage`)));
  const getLocalWhere: boolean = Boolean(localStorageRead(buildKey(`Where`)));

  // Read local storage values
  const localOrderBy: ArgOrderBy = getLocalOrderBy ? localStorageRead(buildKey(`OrderBy`)) : initialOrderBy;
  const localPage: ArgPage = getLocalPage ? localStorageRead(buildKey(`Page`)) : initialPage;
  const localPerPage: ArgPerPage = getLocalPerPage ? localStorageRead(buildKey(`PerPage`)) : initialPerPage;
  const localWhere: ArgWhere = getLocalWhere ? localStorageRead(buildKey(`Where`)) : initialWhere;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setStateValue = useCallback(
    (stateKey: 'OrderBy' | 'Page' | 'PerPage' | 'Where', newValue) => {
      const hasWindow = windowAvailable();
      if (!hasWindow) return;

      localStorageSet(buildKey(stateKey), newValue, 12);

      switch (stateKey) {
        case `OrderBy`:
          stateSetOrderBy(newValue);
          break;
        case `Page`:
          stateSetPage(newValue);
          break;
        case `PerPage`:
          stateSetPerPage(newValue);
          break;
        case `Where`:
          stateSetWhere(newValue);
          break;
        default:
          break;
      }
    },
    [buildKey],
  );

  // Create API Functions for handling state
  const setOrderBy = (value: any) => setStateValue(`OrderBy`, value);
  const setPage = value => setStateValue(`Page`, value);
  const setPerPage = value => setStateValue(`PerPage`, value);
  const setWhere = value => setStateValue(`Where`, value);

  // On change of active domain...
  useEffect(() => {
    // Get the new state values from local storage
    const orderBy: ArgOrderBy = localOrderBy;
    const page: ArgPage = undefined;
    const perPage: ArgPerPage = localPerPage;
    const where: ArgWhere = localWhere;

    // decontruct where string into array for form population
    stateSetWhereArray(whereDeconstrutor(where));

    // Set the new values to state
    stateSetWhere(where);
    stateSetOrderBy(orderBy);
    stateSetPage(page);
    stateSetPerPage(perPage);
  }, [domainActive.id, localOrderBy, localPerPage, localWhere]);

  const whereProtected: ArgWhere = `&domainId=${domainActive?.id}`;
  const [where, stateSetWhere] = useState<ArgWhere>(localWhere);
  const [orderBy, stateSetOrderBy] = useState<ArgOrderBy>(localOrderBy);
  const [page, stateSetPage] = useState<ArgPage>(localPage);
  const [perPage, stateSetPerPage] = useState<ArgPerPage>(localPerPage);
  const [whereArray, stateSetWhereArray] = useState<WhereArray>({ searchText: ``, searchType: searchTypes[0].value, fromDate: ``, toDate: `` });

  const queryFunction = callGetReportDailySERPRankings;
  const queryName = `callGetReportDailySERPRankings`;

  const apiArgs = { orderBy, page, perPage, where, whereProtected };
  const apiDetails = { queryName, queryFunction };
  const apiFunctions = { setWhere, setOrderBy, setPage, setPerPage };

  const enabled: boolean = Boolean(domainActive?.id && whereArray.fromDate && whereArray.toDate);

  const { data: res, error, isLoading: loading } = useQuery([queryName, apiArgs], queryFunction, {
    enabled,
  });
  const data = res?.data;
  const pagination = res?.pagination;

  const hasData = data?.length > 0;
  const items = hasData ? generateRows(data) : [];

  // generate columns definition for table
  const columns = [{
      align: `left`,
      dbKey: `phrase`,
      filter: false,
      sort: true,
      hide: false,
      label: `Keyword`,
      numeric: false,
      value: `phrase`,
      wrap: false,
    },
    {
      align: `left`,
      dbKey: `volume`,
      filter: false,
      sort: true,
      hide: false,
      label: `Search volume`,
      numeric: true,
      value: `volume`,
      wrap: false,
    },
    ...hasData ? data[0].days.map((day) => ({
      align: `left`,
      dbKey: day.reportDate,
      filter: false,
      sort: false,
      hide: false,
      label: moment(day.reportDate).format(`DD-MM`),
      numeric: false,
      value: day.reportDate,
      wrap: false,
    })) : [],
    {
      align: `left`,
      dbKey: `total`,
      filter: false,
      sort: false,
      hide: false,
      label: `Total Movement`,
      numeric: false,
      value: `total`,
      wrap: false,
      tooltip: `Difference in position between the first and last date specified dates`
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <RankingsTableFilter
          loading={loading}
          setWhere={setWhere}
          where={whereArray}
          types={searchTypes}
          enabled={enabled}
        />
        {error && <Error error={error} />}
        {loading && <Loader className="table__loader" type="bar" />}
        {!hasData && !loading && enabled && <Info>There was no data found during the requested date range</Info>}
        {!enabled && !hasData && <Info>Please select the date range</Info>}
        {hasData && (
          <React.Fragment>
            <Table
              apiArgs={apiArgs}
              apiDetails={apiDetails}
              apiFunctions={apiFunctions}
              allowFilters={false}
              collectionType="report"
              columns={columns}
              error={error}
              id="rbDailyRankings"
              items={items}
              loading={loading}
              pagination={pagination}
              selectable={false}
              transfer={false}
              small={true}
            />
            <DailySERPRankingsExport
              loading={loading}
              where={where}
              domain={whereProtected}
            />
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
};

export default DailySERPRankingsDetails;

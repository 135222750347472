import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { toast } from 'react-toastify';

// Particles
import ROUTES from 'routes';
import { ApplicationContext } from 'corigan';
import { removeSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Atoms
import { Button, Link } from 'corigan';

// Molecules
import { Breadcrumbs, Card } from 'corigan';

// Organisms
import { Grid, Row, Col } from 'corigan';

// API
import { callDeleteTag } from 'corigan';

type TagContentProps = {
  children?: React.ReactNode;
  data?: any;
  loading: boolean;
  title: string;
};

const TagContent: React.FC<TagContentProps> = (props: TagContentProps) => {
  const { children, data, loading, title } = props;
  const id = data?.id;

  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;
  const domainActive: Domain = appContext?.state?.domainActive;

  const { userHasPermission: canDeleteTag } = useHasPermissions({
    requiredPermissions: [`keywords:delete`],
  });

  useEffect(() => {
    document.title = `Corigan - Portal: Tag - ${title}`;
  }, [title]);

  const [mutate, { isLoading }] = useMutation(callDeleteTag, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(err, {});

      queryCache.invalidateQueries([`callGetManyTags`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const id: ArgID = variables?.id;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`], (previousTags: APIResponse) => removeSingleCache(previousTags, id));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTags`, { perPage: 10, whereProtected }], (previousTags: APIResponse) =>
        removeSingleCache(previousTags, id),
      );

      // Redirect to directory of tags
      navigate(ROUTES.tags);
    },
  });

  if (!canDeleteTag) return null;

  const deleteTag = async () => {
    // If no ID is available, function can't run
    if (!id) return;
    if (!windowAvailable()) return;

    // Check if they have permission to delete the tag
    if (!canDeleteTag) {
      window.alert(`You do not have permission to delete tags`);
      return;
    }

    try {
      await mutate({ id });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => await deleteTag();
  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Deleting a tag is permanent, are you sure you want to delete the selected tag?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  };

  const isBusy = isLoading || loading;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.dashboard}>Dashboard</Link>
            <Link href={ROUTES.tags}>Tags</Link>
            <h1>{title}</h1>
            {canDeleteTag && (
              <Button className="breadcrumb__button" onClick={handleClick} variant="hollow">
                Delete Tag
              </Button>
            )}
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card loading={isBusy} minHeight={false}>
            {children}
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default TagContent;

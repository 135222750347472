import { fetchAPI } from 'particles';

type CallGetManyPageTypesParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
};

export const callGetManyPageTypes = async (
  queryKey,
  parameters: CallGetManyPageTypesParameters,
): Promise<{ data: PageType[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `pagetypes` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetManyPageTypes;

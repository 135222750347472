import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useFormik } from 'formik';

// Particles
import { ApplicationContext } from 'corigan';
import { arrayChunk } from 'corigan';
import { callSearch } from 'corigan';
import { isArray } from 'corigan';
import { isUndefined } from 'corigan';
import { ROUTES } from 'corigan';
import { useComponentVisible } from 'corigan';

// Icons
import { Search as SearchIcon } from 'icons';

// Components
import { Link } from 'corigan';

const initialValues = {
  headerSearch: ``,
};

declare type RefSearch = { current: HTMLDivElement | null };

export const HeaderSearch = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const formik = useFormik({
    initialValues,
    onSubmit: values => console.info(values),
  });

  const { handleChange, handleSubmit, values } = formik;

  const resetForm = () => {
    formik.resetForm({
      values: initialValues,
    });
  };

  const term = values?.headerSearch;
  const isLongEnough = term?.length >= 3;
  const showResults = isComponentVisible && isLongEnough;
  const shouldSearch = isUndefined(term) || isLongEnough;

  const searchTerm: string = shouldSearch ? term : ``;

  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const { data: res, isLoading: loading } = useQuery([`callSearch`, { whereProtected, term: searchTerm }], callSearch, {
    enabled: shouldSearch,
  });
  const data = res?.data;

  const onFocus = () => setIsComponentVisible(true);

  return (
    <div className="header__search" ref={ref}>
      <form autoComplete="off" className="header__search__form" onSubmit={handleSubmit}>
        <fieldset className="mb-0">
          <label htmlFor="headerSearch">
            <SearchIcon />
            <span className="hidden">Search</span>
          </label>
          <div className="header__search__wrapper">
            <input
              id="headerSearch"
              name="headerSearch"
              onChange={handleChange}
              onFocus={onFocus}
              placeholder="Search keywords, URLs and more"
              type="search"
              value={term}
            />
            {showResults && !loading && <RenderResults resetForm={resetForm} results={data} />}
          </div>
        </fieldset>
      </form>
    </div>
  );
};

declare type ResultsRes = {
  keywords: any;
  pageRevisions: any[];
  tags: any[];
};

declare type RenderResultsProps = {
  resetForm: any;
  results?: any | ResultsRes;
};

const chunkSize: number = 10;

const addToArray = (initial: any[], add: any[]): any[] => {
  // Check the values passed are arrays
  const initialIsArray: boolean = isArray(initial);
  const addIsArray: boolean = isArray(add);
  const neitherAreArray: boolean = !initialIsArray && !addIsArray;

  if (neitherAreArray) return [];
  if (!addIsArray) return initial;
  if (!initialIsArray) return add;

  const hasInitial: boolean = initial?.length > 0;
  const hasToAdd: boolean = add?.length > 0;

  // If we have no items to add, then return the initial array;
  if (!hasToAdd) return initial;

  // If we have initial items, then spread them and append the 'add' value
  if (hasInitial) return [...initial, ...add];

  // Otherwise return the add value as an array
  if (!hasInitial) return add;
};

const RenderResults = (props: RenderResultsProps) => {
  const { resetForm, results } = props;
  const [chunkIndex, setChunkIndex] = React.useState<number>(0);

  // Reset the chunk index when the results array is modified
  React.useEffect(() => {
    setChunkIndex(0);
  }, [results]);

  const showResultsPage = false;
  // const location = useLocation();
  // const showResultsPage: boolean = location?.pathname !== `/search`;

  const allResults = React.useMemo(() => {
    const keywords = results?.keywords;
    const pageRevisions = results?.pageRevisions;
    const tags = results?.tags;

    const hasKeywords: boolean = keywords?.length > 0;
    const hasPages: boolean = pageRevisions?.length > 0;
    const hasTags: boolean = tags?.length > 0;
    const hasResults: boolean = hasKeywords || hasPages || hasTags;
    if (!hasResults) return [];

    let resultsArray: any[] = [];
    resultsArray = addToArray(resultsArray, keywords);
    resultsArray = addToArray(resultsArray, pageRevisions);
    resultsArray = addToArray(resultsArray, tags);

    return resultsArray;
  }, [results]);

  const chunkedResults = React.useMemo(() => {
    const hasResults = allResults?.length > 0;
    if (!hasResults) return [];

    const chunked = arrayChunk(allResults, chunkSize);
    return chunked;
  }, [allResults]);

  const currentChunk = chunkedResults?.[chunkIndex];
  const finalChunkIndex = chunkedResults?.length - 1;
  const hasCurrentChunk: boolean = Boolean(currentChunk);

  const lastPage: number = finalChunkIndex;
  const nextPage: number = chunkIndex + 1;
  const prevPage: number = chunkIndex - 1;
  const hasNextPage: boolean = Boolean(nextPage <= lastPage);
  const hasPrevPage: boolean = Boolean(prevPage >= 0);
  const hasPagination: boolean = hasNextPage || hasPrevPage;

  const handleNextPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    if (!hasNextPage) return;

    setChunkIndex(chunkIndex + 1);
  };

  const handlePrevPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();
    if (!hasPrevPage) return;

    setChunkIndex(chunkIndex - 1);
  };

  return (
    <nav className="header__search__results">
      {!hasCurrentChunk && <p>Sorry, we couldn&apos;t find anything with that search term.</p>}
      {hasCurrentChunk &&
        currentChunk.map(result => {
          const isKeyword: boolean = result.hasOwnProperty(`phrase`);
          const isPage: boolean = result.hasOwnProperty(`page`);
          const variant = isKeyword ? `keyword` : isPage ? `page` : `tag`;

          return <Result key={result.id} resetForm={resetForm} result={result} variant={variant} />;
        })}
      {hasPagination && (
        <div className="header__search__pagination">
          <button disabled={!hasPrevPage} onClick={handlePrevPage}>
            Previous Results
          </button>
          <button disabled={!hasNextPage} onClick={handleNextPage}>
            More Results
          </button>
        </div>
      )}
      {showResultsPage && <Link href={ROUTES.search}>Go to search page</Link>}
    </nav>
  );
};

declare type ResultProps = {
  resetForm: any;
  result: any;
  variant: 'keyword' | 'page' | 'tag';
};

const Result = ({ resetForm, result, variant }: ResultProps) => {
  const id = result?.id;
  const pageID = result?.page;
  const status = result?.status;

  let href = ``;
  let text = ``;
  let variantText = variant;

  switch (variant) {
    case `page`:
      href = ROUTES.page + `?id=${pageID}&status=${status}`;
      text = result?.name;
      variantText = `${status} | ${variant}`;
      break;
    case `keyword`:
      href = ROUTES.keyword + `?id=${id}`;
      text = result?.phrase;
      break;
    case `tag`:
      href = ROUTES.tag + `?id=${id}`;
      text = result?.name;
      break;
    default:
      href = ``;
      text = ``;
      break;
  }

  const noLink = href === ``;
  const noText = text === ``;
  const invalidResult = noLink || noText;

  if (invalidResult) return null;

  const key = `result-${id}`;
  const classList = `result__${variant}`;

  return (
    <Link className={classList} onClick={resetForm} key={key} href={href} title={variant}>
      <span>{text}</span>
      {variant && <span>{variantText}</span>}
    </Link>
  );
};

export default HeaderSearch;

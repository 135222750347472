import * as React from 'react';

function SvgHelp(props) {
  return (
    <svg viewBox="0 0 16 16" aria-hidden="true" {...props}>
      <path
        d="M8 0a8 8 0 108 8 8.024 8.024 0 00-8-8zm0 10a2 2 0 112-2 2.006 2.006 0 01-2 2zm0-8a5.834 5.834 0 012.6.6L9.044 4.156a3.591 3.591 0 00-2.089 0L5.4 2.6A5.834 5.834 0 018 2zM2 8a5.834 5.834 0 01.6-2.6l1.556 1.556a3.591 3.591 0 000 2.089L2.6 10.6A5.834 5.834 0 012 8zm6 6a5.834 5.834 0 01-2.6-.6l1.556-1.556a3.591 3.591 0 002.089 0L10.6 13.4A5.834 5.834 0 018 14zm5.4-3.4l-1.556-1.556a3.591 3.591 0 000-2.089L13.4 5.4a5.933 5.933 0 010 5.2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHelp;

import { fetchAPI } from 'particles';

type CallMeParameters = {};

export const callMe = async (queryKey, parameters: CallMeParameters): Promise<{ data: User }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `users/me` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callMe;

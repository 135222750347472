import * as React from 'react';

function SvgEmojiEvents(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M21.3 2.7h-2.7V0H5.3v2.7H2.7C1.2 2.7 0 3.9 0 5.3v1.3c0 3.4 2.6 6.2 5.9 6.6.8 2 2.6 3.5 4.8 3.9v4.1H5.3V24h13.3v-2.7h-5.3v-4.1c2.2-.4 4-1.9 4.8-3.9 3.3-.4 5.9-3.2 5.9-6.6V5.3c0-1.4-1.2-2.6-2.7-2.6zm-18.6 4V5.3h2.7v5.1c-1.6-.5-2.7-2-2.7-3.7zm18.6 0c0 1.7-1.1 3.2-2.7 3.8V5.3h2.7v1.4z"
      />
    </svg>
  );
}

export default SvgEmojiEvents;

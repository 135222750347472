import { fetchAPI } from 'particles';

declare type callGetReportRankingsMovementArgs = {
  domainId: ArgID;
  searchText: string;
  searchType: string;
  baseDate: string;
  compareDate: string;
  page: number;
  perPage: number;
};

declare type DaysObject = {
  reportDate: string;
  position: number;
  movement: number;
};

declare type DataObject = {
  phrase: string;
  volume: number;
  days: DaysObject[];
};

declare type Response = {
  data: DataObject[];
  pagination: Pagination;
};

export const callGetReportRankingsMovement = async (reqKey, parameters: callGetReportRankingsMovementArgs): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/serp/movement` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportRankingsMovement;

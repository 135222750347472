export const dateFormal = (dateString: string | Date) => {
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleString(`en-GB`, {
    year: `numeric`,
    month: `2-digit`,
    day: `2-digit`,
  });
  return formattedDate;
};

export default dateFormal;

import * as React from 'react';
import { useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import { Toggle } from 'corigan';

// Atoms
import { Button } from 'corigan';
import { Link } from 'corigan';

// Molecules
import { Error } from 'corigan';

// Particles
import { ProtectedRoute } from 'corigan';

// Templates
import { Half } from 'corigan';

// API
import { callMe, callPatchVerifyMe } from 'corigan';


const Wrapper = () => (
  <ProtectedRoute>
    <EULA />
  </ProtectedRoute>
);

type FormValues = {
  verified: boolean;
};

const initialValues: FormValues = { verified: false };

const EULA = () => {
  // Get the user information
  const { data: res } = useQuery([`callMe`], callMe);
  const user = res?.data;

  // check if verified
  if (user?.verified) navigate(`/`);

  const [error, setError] = useState(null);

  const [mutate, { error: mutateError }] = useMutation(callPatchVerifyMe, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      setError(err);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: { message: string }, variables) => {
      navigate(`/`);
    },
  });

  // setup formik
  const formik = useFormik({
    initialValues,
    onSubmit: async values => await mutate({ ...values }),
  });

  const { touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;
  const formikHelpers = {
    touched,
    values,
    handleChange,
    handleBlur,
  };
  const { verified } = values;

  const handleToggleChange = () => {
    setFieldValue(`verified`, !verified);
  };

  return (
    <div>
      {!user?.verified && (
        <Half intro="Welcome to Corigan. As this is your first time logging in you will need to agree with our EULA." title="Corigan">
          {error && <Error error={error}>{error}</Error>}
          {mutateError && <Error error={mutateError}>{mutateError}</Error>}
          <Link href="https://corigan.io/eula/" target="_blank" >
            You can view the Corigan EULA by clicking here.
          </Link>
          <form onSubmit={handleSubmit}>
            <Toggle {...formikHelpers} id="verified" label="Agree to EULA" on={verified} onChange={handleToggleChange} small={false}>
              Agree to EULA
            </Toggle>
            <Button type="submit" disabled={isSubmitting || !verified}>
              {isSubmitting ? `Agreeing to EULA` : `Agree to EULA`}
            </Button>
          </form>
        </Half>
      )}
    </div>
  );
};

export default Wrapper;

import * as React from 'react';

function SvgAssignment(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M20.4 2.4h-5C14.9 1 13.6 0 12 0S9.1 1 8.6 2.4h-5c-1.3 0-2.4 1.1-2.4 2.4v16.7c0 1.3 1.1 2.4 2.4 2.4h16.7c1.3 0 2.4-1.1 2.4-2.4V4.8c0-1.3-1-2.4-2.3-2.4zm-8.4 0c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2.5-1.2 1.2-1.2zm2.4 16.8H6v-2.4h8.4v2.4zm3.6-4.8H6V12h12v2.4zm0-4.8H6V7.2h12v2.4z"
      />
    </svg>
  );
}

export default SvgAssignment;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { replaceAll } from 'helpers';
import { dateShort } from 'helpers';
import { toTitleCase } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { generateSeries } from '../generateSeries';

// API
import { callGetSistrixMarket } from 'corigan';
import { callGetSistrixBrowseWeeklyComparison } from 'corigan';

// Components
import { Skeleton } from 'corigan';
import { Toggle } from 'corigan';
import { Statistic } from 'corigan';
import { Error } from 'corigan';
import { ChartStackedArea } from 'corigan';
import { GapGroup } from 'corigan';

// Local Particles
import { DateRange } from '../../partials';
import { RBContext } from 'corigan';
import { generateStats } from '../generateStats';

// Local Components
import ViewTemplate from '../view-template';

declare type ReportClientDetailProps = {};

const ReportClientDetail = (props: ReportClientDetailProps) => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const context = useContext(RBContext);
  const state = context?.state;
  const { apiDate, monthOnMonth } = state;

  const date = apiDate?.clone()?.startOf(`isoWeek`)?.format(`YYYY-MM-DD`);
  const prettyDate = dateShort(apiDate?.toISOString());

  const marketParams = {
    domainId,
    date,
    monthOnMonth,
  };

  const weeklyParams = {
    domainId,
    limit: 156,
  };

  const market = useQuery([`callGetSistrixMarket`, marketParams], callGetSistrixMarket);
  const weekly = useQuery([`callGetSistrixBrowseWeeklyComparison`, weeklyParams], callGetSistrixBrowseWeeklyComparison);

  const error = weekly?.error || market?.error;
  const loading = weekly?.isLoading || market?.isLoading;

  return (
    <ViewTemplate controlDevices={false} controlMOM={true} loading={loading}>
      {error && <Error error={error} />}
      <ReportContentWrapper
        market={market?.data?.data}
        loading={loading}
        prettyDate={prettyDate}
        weekly={weekly?.data?.data}
      />
    </ViewTemplate>
  );
};

declare type ReportContentWrapperProps = {
  loading: boolean;
  market: any;
  prettyDate: string;
  weekly: any;
};

const ReportContentWrapper = (props: ReportContentWrapperProps) => {
  const { loading } = props;
  const context = useContext(RBContext);
  const state = context?.state;
  const { chartDateEnd, chartDateStart, monthOnMonth } = state;

  // Only recalculate on date changes in state store
  const { dates } = useMemo(() => {
    return getWeeksBetween(chartDateStart, chartDateEnd);
  }, [chartDateEnd, chartDateStart]);

  // Only recalculate on date changes in state store
  const content = useMemo(() => {
    return <ReportContent {...props} dates={dates} loading={loading} monthOnMonth={monthOnMonth} />;
  }, [dates, loading, monthOnMonth, props]);

  return <React.Fragment>{content}</React.Fragment>;
};

declare type ReportContentProps = {
  dates: any[];
  monthOnMonth: boolean;
} & ReportContentWrapperProps;

const ReportContent = (props: ReportContentProps) => {
  const { dates, loading, market, prettyDate, weekly } = props;
  const browse: any = market?.L1Categories;
  const subfolders: any = market?.subfolders;
  const context: any = useContext(RBContext);
  const state: any = context?.state;
  const { chartDateEnd, chartDateStart, monthOnMonth } = state;

  const browseWeekly: any[] = weekly?.browse;
  const subfoldersWeekly: any[] = weekly?.subfolders;

  let changeLabel: string = ``;
  if (!monthOnMonth) changeLabel = `than prev week`;
  if (monthOnMonth) changeLabel = `than prev month`;

  const browseStats: any = generateStats({ changeLabel, data: browse });
  const subfoldersStats: any = generateStats({ changeLabel, data: subfolders });

  const options: ObjectLiteral = {
    legend: {
      show: false,
    },
    tooltip: {
      labels: {
        format: `dd MMM yyyy`,
      },
    },
    xaxis: {
      categories: dates,
      labels: {
        format: `MMM yyyy`,
      },
      type: `datetime`,
    },
    yaxis: {
      reversed: false,
      seriesName: `Visibility`,
      title: {
        text: `Visibility`,
      },
    },
  };

  const browseSeries = generateSeries({ data: browseWeekly, chartDateEnd, chartDateStart });
  const subfoldersSeries = generateSeries({ data: subfoldersWeekly, chartDateEnd, chartDateStart });

  return (
    <React.Fragment>
      <h2>Subfolders - {prettyDate}</h2>
      {loading && <Skeleton height={150} />}
      {loading && <Skeleton height={150} />}
      <div className="report__statistics">
        {subfoldersStats.map(s => (
          <Statistic {...s} key={`${s?.label}-${s?.figure}`} />
        ))}
      </div>
      <h2>Browse - {prettyDate}</h2>
      {loading && <Skeleton height={150} />}
      {loading && <Skeleton height={150} />}
      <div className="report__statistics">
        {browseStats.map(s => (
          <Statistic {...s} key={`${s?.label}-${s?.figure}`} />
        ))}
      </div>
      <ToggledChart dates={true} id="browse-data" options={options} series={browseSeries} title="Browse" />
      <ToggledChart dates={false} id="subfolders-data" options={options} series={subfoldersSeries} title="Subfolders" />
    </React.Fragment>
  );
};

declare type ToggleChartProps = {
  dates: boolean;
  id: string;
  initial?: string[];
  options: any;
  series: any;
  title: string;
};

const ToggledChart: React.FC<ToggleChartProps> = (props: ToggleChartProps) => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainName = domainActive?.hostname;

  const { dates, id, initial = [], options, title } = props;
  let { series } = props;
  const [enabledPlots, setEnabledPlots] = useState<string[]>(initial);
  const hasEnabled: boolean = enabledPlots?.length > 0;
  const hasSeries: boolean = series?.length > 0;

  const cleanName = name => {
    // Lowercase the string for the replace comparison
    const lowerName = name?.toLowerCase();

    // Remove prefix www. from string if exists
    const hostNoWWW = domainName?.startsWith(`www.`) ? domainName?.replace(`www.`, ``) : domainName;

    // Remove where client prefixes
    const newName = replaceAll(lowerName, `${hostNoWWW}/`, ``);

    // Capitalize each word
    const titleName = toTitleCase(newName);

    // One exception is where the word needs to be uppercase (SD)
    if (titleName === `Sd`) return `SD`;
    return titleName;
  };

  // Clean up those dirty names (client.co.uk/technology => Technology)
  if (hasSeries) {
    series = series.map(s => {
      return { ...s, name: cleanName(s.name) };
    });
  }

  // On initialization, set the first series plot as enabled
  useEffect(() => {
    if (!hasSeries) return;
    if (hasEnabled) return;

    // Get an array of plot names, in alphabetical order
    const allNames = series.map(({ name }) => name);
    const orderedNames = allNames.sort();
    setEnabledPlots([orderedNames[0]]);
  }, [hasEnabled, hasSeries, series]);

  // Create an initial state of every series plot name available
  const allSeriesPlotNames = useMemo(() => {
    // If there is no series (still loading) then return an empty array
    const hasSeries = series?.length > 0;
    if (!hasSeries) return [];

    // Otherwise return an array of plot names, in alphabetical order
    const allNames = series.map(({ name }) => name);
    const orderedNames = allNames.sort();
    return orderedNames;
  }, [series]);

  // Do we have any plot names available to use in filtering?
  const hasPlotNames = allSeriesPlotNames?.length > 0;

  // Helper function to use in filter function
  // is the series included in the useState array?
  const isPlotEnabled = (plot: any) => {
    const isEnabled: boolean = enabledPlots.includes(plot.name);
    return isEnabled;
  };

  // Create an empty series, and then update with filtered plots
  let enabledSeries: any[] = [];
  if (hasSeries) enabledSeries = series.filter(isPlotEnabled);

  // On toggle change...
  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Get the ID of the toggle (domain name)
    const plotToChange: string = e.target.id;

    // Create an empty array which will hold the new enabled series plots
    let newEnabled: any[] = [];

    // Determine if there are any enabled plots yet
    const currentEnabled: any[] = [...enabledPlots];
    const hasCurrent: boolean = currentEnabled?.length > 0;

    // If none are enabled, the new plot state should be the single new clicked toggle ID
    if (!hasCurrent) {
      newEnabled = [plotToChange];
      setEnabledPlots(newEnabled);
      return;
    }

    // Otherwise, determine if the clicked toggle is being added or not
    let shouldAdd: boolean = true;
    if (currentEnabled.includes(plotToChange)) shouldAdd = false;

    // If it is being added, spread the old array, and append the new value
    if (shouldAdd) {
      newEnabled = [...currentEnabled, plotToChange];
      setEnabledPlots(newEnabled);
      return;
    }

    // Prevent there being no series enabled
    const onlyOneEnabled: boolean = currentEnabled?.length === 1;
    if (onlyOneEnabled) return;

    // Otherwise, remove the clicked toggle ID from the current values
    newEnabled = currentEnabled.filter(plot => {
      const isMatch = plot === plotToChange;
      return !isMatch;
    });
    setEnabledPlots(newEnabled);
  };

  return (
    <React.Fragment>
      {!dates && <h2>{title}</h2>}
      {dates && <DateRange title={title} />}
      <GapGroup className="mb-2">
        {hasPlotNames &&
          allSeriesPlotNames.map((name: string) => (
            <Toggle
              id={name}
              key={name}
              small={true}
              label={name}
              on={enabledPlots.includes(name)}
              onChange={onToggleChange}
            >
              Show {name} on the chart
            </Toggle>
          ))}
      </GapGroup>
      <ChartStackedArea id={id} options={options} series={enabledSeries} />
    </React.Fragment>
  );
};

export default ReportClientDetail;

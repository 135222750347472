export const removeCollectionCache = (previousCollection, ids: [ArgID], previousCollectionProperty?: string) => {
  // Are there keywords in the cache?
  const data = previousCollection?.data;
  if (!data) return previousCollection;

  // If there are no keywords in the cache, create a new array with the new keyword
  const hasExistingData = data?.length > 0;
  if (!hasExistingData) return { ...previousCollection, data: [] };

  // Remove the new old collection items to the data array
  const newData = data.filter(collectionItem => {
    const collectionItemId = previousCollectionProperty ? collectionItem[previousCollectionProperty]?.id : collectionItem.id;
    const toRemove = ids.includes(collectionItemId);
    const isValid = toRemove === false;
    return isValid;
  });

  return {
    ...previousCollection,
    data: newData,
  };
};

export default removeCollectionCache;

import { css } from 'styled-components';

/* Advanced vertical rhythym based off of https://medium.com/@sebastian.eberlein/advanced-vertical-margins-4ac69f032f79 */
const article = css`
  article {
    margin-bottom: 32px;

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    > * {
      margin-right: 0;
      margin-left: 0;
      max-width: 800px;
    }

    > * + * {
      margin-top: 16px;
    }

    > h2 + * {
      margin-top: 24px;
    }

    > img + *,
    > figure + * {
      margin-top: 32px;
    }

    > * + h2 {
      margin-top: 48px;
    }

    > * + h3 {
      margin-top: 32px;
    }

    > * + img {
      margin-top: 32px;
    }

    > * + h4 {
      margin-top: 24px;
    }

    > img + img,
    > figure + figure {
      margin-top: 32px;
    }

    > h2 + h3 {
      margin-top: 32px;
    }

    > h3 + h4 {
      margin-top: 32px;
    }
  }
`;

export default article;

import * as React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Button } from 'corigan';
import { Toggle } from 'corigan';

import { callTableArgumentsToCSV } from 'corigan';

import { getRelease } from 'corigan';
import { useLocalStorage } from 'corigan';


type TableTransferProps = {
  apiArgs: APIArgs;
  route: string;
};

const TableTransfer: React.FC<TableTransferProps> = (props: TableTransferProps) => {
  const { apiArgs, route } = props;
  let displayToogle:boolean = false;
  const includeToggle = [`pagerevisions`];
  let routeURL:string;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our initial parameters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const { releaseVersion } = getRelease();
  const localKey = React.useMemo(() => {
    return `version=${releaseVersion}&key=om-id-export-expand`;
  }, [releaseVersion]);

  const [idExportExpand, setIDExportExpand] = useLocalStorage(localKey, false);

  // define expanded export toggle handler
  const handleToggleChange = () => {
    setIDExportExpand(!idExportExpand);
  };

  // define expanded export label
  const ExportLabel = (
    <React.Fragment>
      Export id for importing updates
    </React.Fragment>
  );

  // check if expanded export if ids is available for route and set variables accordingly
  if (includeToggle.includes(route) === true) {
    displayToogle = true;
    routeURL = `export/${route}/${idExportExpand}`
  } else {
    routeURL = `export/${route}`
  }

  const [tableToCSV, { isLoading: loading }] = useMutation(callTableArgumentsToCSV, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      toast.error(`Unable to create export file`, {});
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {},
  });

  const handleExport = async e => {
    e.preventDefault();

    const res = await tableToCSV({ apiArgs, route: routeURL });

    const link = document.createElement(`a`);
  
    link.setAttribute(`href`, res.data);
    link.setAttribute(`download`, res.data);
  
    // Append the Anchor tag in the actual web page or application
    document.body.appendChild(link);
  
    // Trigger the click event of the Anchor link
    link.click();
  
    // Remove the Anchor link form the web page or application
    document.body.removeChild(link);

  };

  return (
    <nav className="table__transfer">
      <Button disabled={loading} onClick={handleExport}>Export Results</Button>
      {displayToogle && (
        <Toggle className="ml-2" id="IDExport" label={ExportLabel} onChange={handleToggleChange} on={idExportExpand} />
      )}
    </nav>
  );
};

export default TableTransfer;

import { fetchAPI } from 'particles';

type GetKeywordChangesParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
  _with?: ArgWith;
};

export const callGetManyKeywordChanges = async (queryKey, parameters: GetKeywordChangesParameters) => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `keywordChanges` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetManyKeywordChanges;

import * as React from 'react';

function SvgKeywordAdd(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M 15 13 L 16 13 L 16 15 L 18 15 L 18 16 L 16 16 L 16 18 L 15 18 L 15 16 L 13 16 L 13 15 L 15 15 L 15 13 ZM 11 15.5 C 11 17.9853 13.0147 20 15.5 20 C 17.9853 20 20 17.9853 20 15.5 C 20 13.0147 17.9853 11 15.5 11 C 13.0147 11 11 13.0147 11 15.5 ZM 4 4 L 4 20 L 11 20 L 11 18 L 6 18 L 6 6 L 13 6 L 13 10 L 17 10 L 17 11 L 19 11 L 19 8 L 15 4 L 4 4 Z" />
    </svg>
  );
}

export default SvgKeywordAdd;

import * as React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';

// Particles
import { buildSelectObjects } from 'corigan';
import { differencesBetweenObjects } from 'corigan';
import { updateSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Field, Select } from 'corigan';
import { Error, Info } from 'corigan';

// API
import { callGetRoles } from 'corigan';
import { callGetManyTeams } from 'corigan';
import { callMe } from 'corigan';
import { callUpdateUser } from 'corigan';

// Localised partials
import UserContent from './content';

type RequestCompleteProps = {
  data: any;
  loading: boolean;
};

const Request = (props: RequestCompleteProps) => {
  const { data, loading } = props;
  const name: string = data?.[0]?.name;
  const hasData: boolean = data?.length > 0;

  return (
    <UserContent data={data} loading={loading} title={name}>
      {!loading && hasData && <UserOverview data={data[0]} />}
    </UserContent>
  );
};

const successMessage = (name: string): string => {
  return `The user "${name}" has been successfully updated in the system.`;
};

type FormErrors = {
  email?: string;
  name?: string;
  roles?: string;
  teams?: string;
};

type FormValues = {
  email: string;
  name: string;
  roles: Role[] | string[];
  teams: Team[] | string[];
};

type UserOverviewProps = {
  data: User;
};

const singleWith: ArgWith = [`roles`, `teams`];

declare type SelectArgs = {
  event: any;
  field: string;
  setFieldValue: any;
};

const selectChange = (args: SelectArgs): void => {
  const { event, field, setFieldValue } = args;
  const noValueProvided = !event || !field;
  if (noValueProvided) setFieldValue([]);
  setFieldValue(field, event);
};

const UserOverview: React.FC<UserOverviewProps> = (props: UserOverviewProps) => {
  const { data } = props;
  const { id, email, name, roles: currentRoles, teams: currentTeams } = data;
  const queryCache = useQueryCache();

  const { userHasPermission: canReadTeams } = useHasPermissions({ requiredPermissions: [`teams:read`] });
  const { userHasPermission: canUpdateUser } = useHasPermissions({ requiredPermissions: [`users:update`] });

  const roles = buildSelectObjects(currentRoles);
  const teams = buildSelectObjects(currentTeams);

  const [success, setSuccess] = useState<string>(null);

  const initialValues = {
    email,
    name,
    roles,
    teams,
  };

  const rolesQuery = useQuery([`callGetRoles`], callGetRoles);
  const rolesData = rolesQuery?.data?.data;

  const teamsQuery = useQuery([`callGetManyTeams`], callGetManyTeams, { enabled: canReadTeams });
  const teamsData: Team[] = teamsQuery?.data?.data;

  const me = useQuery([`callMe`], callMe);
  const currentUser: User = me?.data?.data;
  const viewingMyself: boolean = currentUser?.id === id;

  // Fetch roles available to the system
  const roleOptions = useMemo(() => {
    const hasData: boolean = rolesData?.length > 0;
    if (!hasData) return [];

    const allRoles = buildSelectObjects(rolesData);
    const hasRoles: boolean = allRoles?.length > 0;
    if (!hasRoles) return [];

    return allRoles;
  }, [rolesData]);

  // Fetch teams available to the system
  const teamOptions = useMemo(() => {
    const hasData: boolean = teamsData?.length > 0;
    if (!hasData) return [];

    const allRoles = buildSelectObjects(teamsData);
    const hasRoles: boolean = allRoles?.length > 0;
    if (!hasRoles) return [];

    return allRoles;
  }, [teamsData]);

  const updateUser = async values => {
    const { email, name, roles: rolesArray, teams: teamsArray } = values;
    let teams = undefined;
    let roles = undefined;
    if (rolesArray) roles = rolesArray.map(r => r.value);
    if (teamsArray) teams = teamsArray.map(t => t.value);

    return await callUpdateUser({
      id,
      email,
      name,
      roles,
      teams,
      _with: singleWith,
    });
  };

  const [mutate, { error, isLoading: loading }] = useMutation(updateUser, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyUsers`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      // If updateUser function was successful, get values from API response
      const userData: User = data?.data;
      const id = userData?.id;
      const latestName = userData?.name;

      // Optimistically update to the new value
      if (viewingMyself) queryCache.setQueryData([`callMe`], { data: userData });

      // Parameters for single user query
      const singleWhere: ArgWhere = `[_id][eq]=${id}`;

      // Parameters for inital all users query
      const mastersheetPerPage = 10;
      const mastersheetWith: ArgWith = [`roles`, `teams`, `teams.domains`];

      // Optimistically update to the new value for mastersheet page
      queryCache.invalidateQueries([`callGetManyUsers`, { perPage: mastersheetPerPage, _with: mastersheetWith }]);

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyUsers`, { _with: singleWith, where: singleWhere }],
        (previousUsers: User[]) => updateSingleCache(previousUsers, userData),
      );

      // Let the user know the page has been updated and update UI
      setSuccess(successMessage(latestName));
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (windowAvailable() && !canUpdateUser) window.alert(`You do not have permission to update a user`);
      const changedValues = differencesBetweenObjects(initialValues, values);
      if (canUpdateUser) await mutate(changedValues);
    },
    validate: (values: FormValues) => {
      const { name, email, teams, roles } = values;
      const hasRoles = roles?.length > 0;
      const hasTeams = teams?.length > 0;

      const errors: FormErrors = {};
      if (!name) errors.name = `The user must have a name`;
      if (!email) errors.email = `The user must have an email address`;
      if (!hasTeams) errors.teams = `The user must have at least one team`;
      if (!hasRoles) errors.roles = `The user must have at least one role`;
      return errors;
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  const { errors, touched, values, handleChange, handleBlur, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const rolesOptionsChange = event => {
    selectChange({
      event,
      field: `roles`,
      setFieldValue,
    });
  };

  const teamsOptionsChange = event => {
    selectChange({
      event,
      field: `teams`,
      setFieldValue,
    });
  };

  const isBusy: boolean = isSubmitting || loading;
  const isLocked: boolean = !canUpdateUser || isBusy;

  return (
    <section className="user__update">
      <h2>Update user information</h2>
      <form onSubmit={handleSubmit} aria-label="Update a User">
        <fieldset className="mb-0" aria-busy={isBusy} disabled={isLocked}>
          {success && (
            <Info onClose={onClose} y="lg">
              {success}
            </Info>
          )}
          {error && <Error error={error}>{error}</Error>}
          <Field {...formikHelpers} id="name" label="Name" required={true} />
          <Field {...formikHelpers} id="email" label="Email Address" type="email" required={true} />
          <label htmlFor="teams">Teams</label>
          {teamsQuery.error && <Error id="teamsQueryError" error={teamsQuery.error} />}
          <Select disabled={isLocked} onChange={teamsOptionsChange} options={teamOptions} value={values.teams} />
          {touched && touched.teams && errors.teams && <Error id="errorTeams" error={errors.teams} />}
          <label htmlFor="roles">Roles</label>
          {rolesQuery.error && <Error id="rolesQueryError" error={rolesQuery.error} />}
          <Select disabled={isLocked} onChange={rolesOptionsChange} options={roleOptions} value={values.roles} />
          {touched && touched.roles && errors.roles && <Error id="errorRoles" error={errors.roles} />}
          <button type="submit" disabled={isSubmitting}>
            Updat{isSubmitting ? `ing` : `e`} User
          </button>
        </fieldset>
      </form>
    </section>
  );
};

export default Request;

import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { CRContext } from 'corigan';
import { useLocalStorage } from 'corigan';

// Components
import { Error } from 'corigan';

// API
import { callGetDomains } from 'corigan';

declare type DomainSelectorWrapperProps = {
  allowAll?: boolean;
  competitorsOnly: boolean;
  id: string;
  horizontal?: boolean;
  initial?: ArgID; // Should be the ID of the domain you want initially selected
  margin?: boolean;
  label?: string;
  defaultLabel?: string;
};

// Used for targeting all available domains (Including competitors)
const DomainSelector = (props: DomainSelectorWrapperProps) => {
  const { allowAll = false, competitorsOnly, id, horizontal = false, initial = ``, label, defaultLabel = `All Sites`, margin = true } = props;
  const [localCompetitorID, setLocalCompetitorID] = useLocalStorage(`domainSelector-${id}`, initial);

  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const context = React.useContext(CRContext);
  const dispatch = context?.dispatch;
  const state = context?.state;
  const competitor = state?.competitor?.hostname;

  const showCompetitors = Boolean(domainId) && competitorsOnly;

  const where: ArgWhere = showCompetitors
    ? `[canCrawl][eq]=true&where[_id][ne]=${domainId}&where[competesWith][eq]=${domainId}`
    : `[canCrawl][eq]=true`;

  const { data: res, error, isLoading: loading } = useQuery([`callGetDomains`, { where }], callGetDomains);

  const domains: Domain[] = res?.data;
  const hasDomains: boolean = domains?.length > 0;

  React.useEffect(() => {
    if (!hasDomains) return;

    // Determines if we should lookup based on local domain ID or not
    const lookup = domains.find(domain => domain.id === initial);
    const hasLookup = Boolean(lookup);

    let value = hasLookup ? lookup : { hostname: `all` };

    if (!allowAll && hasDomains && !hasLookup) {
      value = domains[0];
    }

    if (dispatch) dispatch({ type: `set`, key: `competitor`, value });
  }, [dispatch, domains, hasDomains, initial, allowAll]);

  const competitorChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    if (!e) return;
    if (!hasDomains) return;
    if (!dispatch) return;

    const activeHostname: string = e?.target?.value;

    const isAll: boolean = activeHostname === `all`;

    if (isAll || !activeHostname) {
      if (!activeHostname) console.error(`No active domain hostname found in event`);

      setLocalCompetitorID(`all`);
      dispatch({ type: `set`, key: `competitor`, value: { hostname: `all` } });
      return;
    }

    const activeDomain: Domain = domains.find(domain => {
      const isActive: boolean = domain.hostname === activeHostname;
      return isActive;
    });

    if (!activeDomain) {
      console.error(`No active domain found for ${activeHostname}`);
      return;
    }

    setLocalCompetitorID(activeDomain?.id);
    dispatch({ type: `set`, key: `competitor`, value: activeDomain });
    dispatch({ type: `set`, key: `competitorURL`, value: null });
  };

  let classList = `domain-selector`;
  if (!margin) classList += ` m-0`;
  if (horizontal) classList += ` display--flex align-items--center`;

  let labelClasses = `m-0 mr-4 text--nowrap`;
  if (!horizontal) labelClasses = `mt-0 mb-2`;

  return (
    <React.Fragment>
      <fieldset className={classList} disabled={loading}>
        <label className={labelClasses} htmlFor="competitor">
          {label ? label : `Site`}
        </label>
        {error && <Error error={error} />}
        <select id={id} name="competitor" onChange={competitorChange} value={competitor} style={{ width: `auto` }}>
          {allowAll && <option value="all">{defaultLabel}</option>}
          {hasDomains && <DomainOptions domains={domains} />}
        </select>
      </fieldset>
    </React.Fragment>
  );
};

declare type DomainOptionsProps = {
  domains: Domain[];
};

const DomainOptions = (props: DomainOptionsProps) => {
  const { domains } = props;

  return (
    <React.Fragment>
      {domains.map(domain => {
        const { hostname, id } = domain;
        return (
          <option key={`${hostname}-${id}`} value={hostname}>
            {hostname}
          </option>
        );
      })}
    </React.Fragment>
  );
};

export default DomainSelector;

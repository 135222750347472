import { objectIsEmpty } from 'corigan';

declare type GetBlocksResponse = ContentBlock[];

export const getBlocks = (arrayVal: ContentBlock[]): GetBlocksResponse => {
  // If we have no content blocks array, return the input
  const hasValues = arrayVal?.length > 0;
  if (!hasValues) return arrayVal;

  const duplicateValue = [...arrayVal];

  // Check the array of blocks to make sure we have itterable values
  const itterable = duplicateValue.filter(val => !objectIsEmpty(val));
  const hasItterable = itterable?.length > 0;
  if (!hasItterable) return itterable;

  const finalVal = itterable.map(({ contentBlock, contentEncoded, ...blockInfo }) => {
    // Do we have an entity map to encode before sending to the API?
    const entityMapPreformatted = contentEncoded?.entityMap;
    const hasEntityMap: boolean = !objectIsEmpty(entityMapPreformatted);
    const hasEncodedBlocks: boolean = contentEncoded?.blocks?.length > 0;

    // If so, loop over each entity ("0": { [key: string]: any })
    // and convert the entity to a unnamed object, e.g.
    // { [key: string]: any }, there is no "0" name
    // Instead we put the position key inside the object as value 'key' = index
    if (hasEntityMap) {
      const entityMap = Object.entries(entityMapPreformatted).map(([index, object]: [string, ObjectLiteral]) => {
        const formatted = { key: index, ...object };
        return formatted;
      });

      // Spread the previous value of contentEncoded...
      // Replacing the previous value of 'entityMap' from an object with named values,
      // To an array of objects with keys as identifiers
      contentEncoded = { ...contentEncoded, entityMap: Object.values(entityMap) };
    }

    // If no entity map found, that's fine but we need the field to be an array
    // NOT an object as draft-js will give us
    const emptyEntityMap = entityMapPreformatted && !hasEntityMap;
    if (emptyEntityMap) contentEncoded = { ...contentEncoded, entityMap: [] };

    // Remove ID key on contentEncoded blocks array
    if (hasEncodedBlocks) {
      const cleanBlocks = contentEncoded.blocks.map(block => {
        const { _id, ...blockFields } = block;
        return blockFields;
      });

      contentEncoded = { ...contentEncoded, blocks: cleanBlocks };
    }

    // Return the formatted block, this may be the original values, or
    // We my have formaatted the 'contentEncoded' key based on the logic at the start of the function
    const formattedBlock = {
      ...blockInfo,
      contentBlock: contentBlock.id,
      contentEncoded,
    };

    // Remove key as we don't specify the ID in our POST
    if (formattedBlock._id) delete formattedBlock._id;
    // Remove key as we don't specify the contentDecoded in our POST
    if (formattedBlock.contentDecoded) delete formattedBlock.contentDecoded;

    // Return the formatted object to our array of blocks
    return formattedBlock;
  });

  // Return the formatted blocks for the API
  return finalVal;
};

export const getIDs = (arrayVal: { id: string }[]): string[] => {
  // Get only the value of each object's "id" key
  const arrayOfIDs = arrayVal.map(({ id }) => id);

  // Filter the array to make sure we have valid values only (no null or undefined)
  const validIDs = arrayOfIDs.filter(Boolean);

  // Output the array of ids as a response
  return validIDs;
};

import { generateChip } from 'corigan';
import { dateCreateUpdate } from 'corigan';
import { ROUTES } from 'corigan';
import { datePretty } from 'corigan';

const _ = undefined;

export const generateRows = data => {
  return data.map(page => {
    const parentPage: Page = page?.page;
    const assignee = page?.assignedTo?.name;
    const createdAt = page?.createdAt;
    const updatedAt = page?.updatedAt;
    const previousResponse = parentPage?.crawlResponse?.previousCode ? parentPage?.crawlResponse?.previousCode : 0;
    const response = parentPage?.crawlResponse?.responseCode;
    const parentID = parentPage?.id;
    const wordCount = page?.contentWordCount;
    const contentBlocks = page?.contentBlocks;
    let updatedBy;

    if(parentPage?.updatedBy?.[`name`] && parentPage?.updatedBy?.[`name`] !== `System`) {
      updatedBy = parentPage?.updatedBy[`name`];
    } else if(page?.updatedBy?.[`name`] && page?.updatedBy?.[`name`] !== `System`){
      updatedBy = page?.updatedBy[`name`];
    } else {
      updatedBy = `Not updated`;
    }

    const { url } = page;
    const { canonicals: revisionCanonicals } = page;
    const { categories: revisionCategories } = page;
    const { keywords: revisionKeywords } = page;
    const { pageId: pageIdString } = page;
    const { name: revisionName } = page;
    const { title: revisionTitle } = page;
    const { metaDescription: revisionMetaDescription } = page;
    const { tags: revisionTags } = page;
    const { status: revisionStatus } = page;
    const { pageType: revisionPageType } = page;

    let categories;
    let canonicals;
    let keywords;
    let tags;
    const keywordCount = page?.keywordCount ? page?.keywordCount : 0;
    const implementedAt = page?.implementedAt ? datePretty(page?.implementedAt) : ``;

    const hasCanonicals: boolean = revisionCanonicals?.length > 0;
    const hasCategories: boolean = revisionCategories?.length > 0;
    const hasKeywords: boolean = revisionKeywords?.length > 0;
    const hasTags: boolean = revisionTags?.length > 0;

    const name = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: revisionName };
    const title = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: revisionTitle };
    const metaDescription = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: revisionMetaDescription };
    const pageId = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: pageIdString };
    const id = { href: `${ROUTES.page}?id=${parentID}&status=${revisionStatus}`, value: parentID };

    const contentText = [];
    if (contentBlocks && contentBlocks.length) {
      contentBlocks.forEach((contentBlock, index) => {
        const contentObj = {
          component: `text`,
          value: contentBlock.content,
        };
        contentText.push(contentObj);
      })
    }

    if (hasCanonicals) {
      canonicals = revisionCanonicals
        .filter(c => c?.url)
        .map(c => ({
          component: `link`,
          href: c.url,
          target: `_blank`,
          value: c.url,
        }));
    }

    if (hasCategories) {
      categories = revisionCategories
        .filter(c => c?.category?.name)
        .map(c =>
          generateChip({
            condition: `contains`,
            href: _,
            value: c.category.name,
            where: `categories.category.name`,
          }),
        );
    }

    if (hasKeywords) {
      keywords = revisionKeywords.map(k =>
        generateChip({
          condition: `contains`,
          href: _,
          value: k.phrase,
          where: `keywords.phrase`,
        }),
      );
    }

    if (hasTags) {
      tags = page.tags.map(t =>
        generateChip({
          condition: `contains`,
          href: _,
          value: t.name,
          where: `tags.name`,
        }),
      );
    }

    const pageTypeName = revisionPageType?.name;
    const pageType = [
      generateChip({
        condition: `contains`,
        href: _,
        value: pageTypeName,
        where: `pageType.name`,
      }),
    ];

    let responseVariant = `green`;
    if (response && response >= 300) responseVariant = `orange`;
    if (response && response >= 400) responseVariant = `red`;
    let responseString;
    if(previousResponse > 0 && previousResponse !== response) {
      responseString = `${previousResponse} -> ${response}`;
    } else {
      responseString = `${response}`;
    }

    let status = null;
    if (response) {
      status = [
        generateChip({
          href: _,
          value: responseString,
          variant: responseVariant,
        }),
      ];
    }

    let assignedTo = null;
    if (assignee) {
      assignedTo = [
        generateChip({
          condition: `eq`,
          href: _,
          value: String(assignee),
          where: `assignedTo.name`,
        }),
      ];
    }

    const currentPageStatus = revisionStatus;
    const changedFormatted = dateCreateUpdate({ createdAt, updatedAt });

    const overview = {
      model: true,
      value: page
    };

    const item = {
      id,
      pageId,
      name,
      title,
      metaDescription,
      url,
      canonicals,
      assignedTo,
      pageType,
      categories,
      tags,
      keywords,
      keywordCount,
      status,
      wordCount,
      changedFormatted,
      updatedBy,
      currentPageStatus,
      contentText,
      implementedAt: implementedAt,
      overview,
      originalObject: page,
    };

    return item;
  });
};

export default generateRows;

import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import Content from './content';

const MissingID = () => {
  const message = `Unfortunately we could not find a keyword as no ID was provided`;

  return (
    <Content data={null} title={`Sorry, we couldn't find that keyword`}>
      <Error error={message} />
      <p>
        Please return to the <Link href={ROUTES.keyword_manager}>all keywords</Link> page.
      </p>
    </Content>
  );
};

export default MissingID;

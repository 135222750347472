import { hasPermission } from 'corigan';
import { useMyPermissions } from 'corigan';

declare type Args = {
  requiredPermissions?: PermissionLevelName[];
};

declare type Res = {
  missingPermissions: PermissionLevelName[];
  userHasPermission: boolean;
};

export const useHasPermissions = (args: Args): Res => {
  const { requiredPermissions } = args;
  const currentPermissions = useMyPermissions();
  return hasPermission({ currentPermissions, requiredPermissions });
};

export default useHasPermissions;

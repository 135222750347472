import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { ParsedQuery } from 'query-string';

// Particles
import { addSingleCache } from 'corigan';
import { ApplicationContext } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Field } from 'corigan';
import { Error, Info } from 'corigan';

// API
import { callCreatePageRevision } from 'corigan';

import { statuses } from 'particles';

const successMessage = name => {
  return `The page "${name}" has been successfully created in the system.`;
};

type FormErrors = {
  name?: string;
  pageId?: string;
  pageType?: string;
  url?: ArgURL;
};

type FormValues = {
  name: string;
  pageId: string;
  pageType: string;
  title?: string;
  url: ArgURL;
};

type CreatePageProps = {
  pageTypes: any;
};

const CreatePageForm = (props: CreatePageProps) => {
  const { pageTypes } = props;
  const hasTypes: boolean = pageTypes?.length > 0;

  const queryParams: ParsedQuery<string> = useQueryParameters();
  const passedURL: string | string[] = queryParams?.url;
  const decodedURL = passedURL ? atob(passedURL) : ``;

  const queryCache = useQueryCache();
  const [success, setSuccess] = useState(null);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const hostname = domainActive?.hostname;
  const prefix: string = `https://` + hostname;

  const domain = domainActive?.id;
  const hasDomainID: boolean = Boolean(domain);

  const { userHasPermission: canCreate } = useHasPermissions({ requiredPermissions: [`pages:create`] });

  const [mutate, { error, isLoading: loading }] = useMutation(callCreatePageRevision, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyPageRevisions`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => { },
    onSuccess: (data: APIResponse, variables) => {
      // If createPage function was successful, get values from API response
      const pageRevisionData: PageRevision = data?.data;
      const id = pageRevisionData?.page;
      const name = variables?.name;

      // Let the user know the page has been created before redirect begins
      setSuccess(successMessage(name));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`], (previousRevisions: APIResponse) =>
        addSingleCache(previousRevisions, pageRevisionData),
      );

      // Redirect to the newly created Page
      const newPageURL = `${ROUTES.page}?id=${id}&status=New Page`;
      navigate(newPageURL);
    },
  });

  const initialValues: FormValues = {
    name: ``,
    pageId: ``,
    pageType: hasTypes ? pageTypes[0].id : ``,
    title: ``,
    url: decodedURL || ``,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      const status: ArgStatus = statuses.newPage;
      return await mutate({ ...values, domain, status });
    },
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { pageType, name } = values;
      if (!name) errors.name = `Please specify a page name`;
      if (!pageType) errors.pageType = `Please specify a page type`;
      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const onURLChange = e => {
    const value: string = e.target.value;
    let newFieldValue = value;

    const valid = newFieldValue?.startsWith(prefix);
    if (!valid) newFieldValue = prefix;

    setFieldValue(`url`, newFieldValue);
  };

  const isBusy: boolean = isSubmitting || loading;
  const locked: boolean = !hasDomainID || !canCreate || isBusy;

  let buttonText: string = `Create Page`;
  if (isBusy) buttonText = `Creating Page`;

  return (
    <form onSubmit={handleSubmit} aria-label="Create a Page" className="form--trimmed">
      <fieldset aria-busy={isBusy} disabled={locked}>
        {success && (
          <Info onClose={onClose} y="lg">
            {success}
          </Info>
        )}
        {error && <Error error={error}>{error}</Error>}
        <Field {...formikHelpers} id="pageId" label="Page ID" required={false} />
        <Field {...formikHelpers} id="name" label="Page Name" required={true} />
        <Field {...formikHelpers} id="title" label="Page Title" required={false} />
        <Field {...formikHelpers} handleChange={onURLChange} id="url" label="URL" required={false} type="url" />
        <label htmlFor="pageType">Page Type</label>
        {hasTypes && (
          <select id="pageType" onBlur={handleBlur} onChange={handleChange} name="pageType" value={values.pageType}>
            {pageTypes.map(({ id, name }) => (
              <option key={`${name}-${id}`} value={id}>
                {name}
              </option>
            ))}
          </select>
        )}
        <button type="submit" disabled={locked}>
          {buttonText}
        </button>
      </fieldset>
    </form>
  );
};

export default CreatePageForm;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import moment from 'moment';

// Particles
import { ApplicationContext } from 'corigan';
import { brandColours } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { KRContext } from 'corigan';

// Components
import { ChartBar } from 'corigan';

// Local Particles
import generateSeriesVolume from './functions/generateSeriesVolume';

// Local Paritals
import { StyledCharts } from '../charts.styled';
import { StyledHistory } from '../charts.styled';

declare type KeywordHistoryProps = {
  volume: Boolean;
};

const momentToday = moment();
const momentYearAgo = moment().subtract(365, `days`);

const KeywordHistory = (props: KeywordHistoryProps) => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.hostname;
  const activeDomainBrand = domain?.replace(`www.`, ``)?.split(`.`)?.[0] as BrandName;

  // Get the colour of the active domain hostname if available
  const activeDomainColour = brandColours(activeDomainBrand);

  const context: any = useContext(KRContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;

  const keyword: Keyword = state?.keyword;
  const phrase: string = keyword?.phrase;
  const volume: number = state?.volume;

  const end: moment.Moment = momentToday;
  const start: moment.Moment = momentYearAgo;

  const canLoad: string[] = state?.canLoad;
  const showSuggestions: boolean = canLoad.includes(`suggestions`);

  const { dates } = getWeeksBetween(start, end);

  const options: ObjectLiteral = useMemo(() => {
    return {
      xaxis: {
        type: `datetime`,
        categories: dates,
      },
    };
  }, [dates]);

  const seriesName: string = `Search Volume`;

  const volumeSeries: any[] = useMemo(() => {
    return generateSeriesVolume({ keyword, volume });
  }, [keyword, volume]);

  // Fixes bug when switching between views by removing undefined values
  const series = volumeSeries?.filter(Boolean);
  const hasSeries: boolean = series?.length > 0;

  const title: string = `Search Volume`;
  const id: string = `keyword-history-volume-chart`;

  const onLoadBar = useCallback(() => {
    if (showSuggestions) return;
    if (dispatch) dispatch({ type: `enableLoading`, value: `suggestions` });
  }, [dispatch, showSuggestions]);

  const intLabels = (val: number): string => String(Math.round(val));

  const impressionsOptions: ChartOptions = useMemo(() => {
    const optionsObject: ChartOptions = {
      ...options,
      yaxis: {
        labels: {
          formatter: intLabels,
        },
        seriesName,
      },
      xaxis: {
        labels: {
          format: `MMM 'yy`
        }
      },
    };

    if (activeDomainColour) optionsObject.colors = [activeDomainColour];

    return optionsObject;
  }, [activeDomainColour, options, seriesName]);

  const volumeOptions: ChartOptions = useMemo(() => {
    const optionsObject: ChartOptions = {
      ...impressionsOptions,
    };

    if (activeDomainColour) optionsObject.colors = [activeDomainColour];

    return optionsObject;
  }, [activeDomainColour, impressionsOptions]);

  return useMemo(() => {
    return (
      <React.Fragment>
        <StyledHistory className="history__navigation">
          <div className="history__phrase">
            {!phrase && <h2>{title}</h2>}
            {phrase && (
              <h2>
                {title} - {phrase}
              </h2>
            )}
          </div>
        </StyledHistory>
        {!hasSeries && <p>No series data was found for {title}</p>}
        <StyledCharts>
          {hasSeries && <ChartBar id={id} height="300px" options={volumeOptions} onLoad={onLoadBar} series={series} />}
        </StyledCharts>
      </React.Fragment>
    );
  }, [phrase, title, id, hasSeries, onLoadBar, volumeOptions, series]);
};
export default KeywordHistory;

import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';

// Particles
import { arrayChunk } from 'corigan';
import { KRContext } from 'corigan';
import { objectIsEmpty } from 'corigan';

// Components
import { ChevronLeft, ChevronRight } from 'icons';
import { Button } from 'corigan';
import { TableSkeleton } from 'corigan';

// Local Partials
import DataRow from './partials/data-row';

declare type KeywordRanksProps = {
  title: string;
};

declare type KeywordUsesTableProps = KeywordRanksProps & {
  data: any;
  loading: boolean;
};

const KeywordUsesTable: React.FC<KeywordUsesTableProps> = (props: KeywordUsesTableProps) => {
  const [chunkPosition, setChunkPosition] = useState<number>(1);
  const data: any = props?.data;
  const loading: boolean = props?.loading;
  const title: string = props?.title;

  const context: any = useContext(KRContext);
  const state: any = context?.state;
  const keyword: Keyword = state?.keyword;
  const phrase: string = keyword?.phrase;

  const hasData: boolean = !objectIsEmpty(data);

  const currentDomains: any = data?.currentDomain;
  const competitorDomains: any = data?.competitorDomains;

  let tableData: any[] = competitorDomains;
  const canSpread: boolean = currentDomains?.length > 0;
  if (canSpread) tableData = [...currentDomains, ...tableData];
  const hasTableData: boolean = tableData?.length > 0;

  let orderedData: any[] = [];
  if (hasTableData) orderedData = tableData.sort((a, b) => a.position - b.position);

  // Set the number of items per chunk (e.g. [10items, 10items])
  const chunkSize: number = 10;
  let orderedChunk: any[] = [];
  if (hasTableData) orderedChunk = arrayChunk(orderedData, chunkSize);

  // Get the current page from the useState function
  const currentPage: number = chunkPosition;

  // Calculate from page -> to page
  const from: number = (currentPage - 1) * chunkSize + 1;
  let to: number = currentPage * chunkSize;
  const total: number = tableData?.length;

  const previousDisabled: boolean = currentPage === 1;
  const nextDisabled: boolean = to + chunkSize > total;
  if (nextDisabled) to = tableData?.length;

  const handlePrevious = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault();
    setChunkPosition(chunkPosition - 1);
  };

  const handleNext = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault();
    setChunkPosition(chunkPosition + 1);
  };

  const currentRecords: any[] = orderedChunk[chunkPosition - 1];
  const paginationHelpers = { loading, from, to, total, previousDisabled, nextDisabled, handlePrevious, handleNext };

  return (
    <React.Fragment>
      {!phrase && (
        <h2 className="mt-2">
          {title}
          {phrase && ` - ${phrase}`}
        </h2>
      )}
      <div className="table">
        <header className="table__header table__pagination">
          <Pagination {...paginationHelpers} />
        </header>
        <table
          style={{
            tableLayout: `auto`,
            width: `100%`,
          }}
        >
          <thead>
            <tr>
              <th>Position</th>
              <th>Title</th>
              <th>Page ID / URL</th>
              <th>History</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && <Skeletons />}
            {hasData && currentRecords.map(r => <DataRow key={`${r?.url}-${r?.position}`} {...r} />)}
          </tbody>
        </table>
        <footer className="table__footer table__pagination">
          <Pagination {...paginationHelpers} />
        </footer>
      </div>
    </React.Fragment>
  );
};

declare type PaginationProps = {
  loading: boolean;
  from: number;
  to: number;
  total: number;
  previousDisabled: boolean;
  nextDisabled: boolean;
  handlePrevious: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { loading } = props;
  const { from, to, total, previousDisabled, nextDisabled, handlePrevious, handleNext } = props;

  if (loading) return <p>Loading data...</p>;

  return (
    <React.Fragment>
      <p>
        Currently showing {from} - {to} of <span className="table__total__count">{total}</span>
      </p>
      <nav>
        <Button disabled={previousDisabled} onClick={handlePrevious}>
          <ChevronLeft />
          <span className="hidden">Previous</span>
        </Button>
        <Button disabled={nextDisabled} onClick={handleNext}>
          <span className="hidden">Next</span>
          <ChevronRight />
        </Button>
      </nav>
    </React.Fragment>
  );
};

const Skeletons: React.FC = () => {
  const content: any[] = Array(...Array(10)).map((v, i) => {
    const order: 'even' | 'odd' = i % 2 ? `even` : `odd`;
    const className: string = `row-skeleton--${order}`;
    const key: string = `tr-skel-${i}`;
    return <TableSkeleton key={key} className={className} colspan={7} height={100} />;
  });
  return <React.Fragment>{content}</React.Fragment>;
};

export default KeywordUsesTable;

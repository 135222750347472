import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import Content from './content';

type RequestErrorProps = {
  error: any;
  id: string;
};

const RequestError: React.FC<RequestErrorProps> = (props: RequestErrorProps) => {
  const { error, id } = props;
  console.error(error);

  const message = `Unfortunately we could not lookup the user with the ID: ${id}`;

  return (
    <Content data={null} title={`Sorry, we couldn't find that user`}>
      <Error error={message} />
      <p>
        Please return to the <Link href={ROUTES.users}>full list of users</Link>
      </p>
    </Content>
  );
};

export default RequestError;

import { css } from 'styled-components';
import { shouldBeDark } from 'particles';

const anchor = css`
  a {
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--blue)`)};
  }
`;

export default anchor;

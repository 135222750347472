// Create global application reducer to handle state changes
export const crReducer = (state, action) => {
  const { key, type, value } = action;

  // Create a copy of state to prevent any chance of mutating
  const stateCopy = { ...state };

  if (type == null) {
    console.error(`No action type provided to application reducer`);
    return;
  }

  switch (type) {
    case `set`: {
      if (key == null) {
        console.error(`No action key provided to 'set' action`);
        return;
      }

      const newState = { ...stateCopy };
      newState[key] = value;
      return newState;
    }

    default: {
      return stateCopy;
    }
  }
};

export default crReducer;

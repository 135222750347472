import * as React from 'react';
import Select from 'react-select';

import { ThemeDefault } from 'corigan';

import StyledSelect from './select.styles';

type CoriganSelectProps = {
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  id?: string;
  disabled?: boolean;
  name?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange?: Function;
  options: {
    label: string;
    value: string;
  }[];
  value: any[];
};

const customStyles = {
  control: base => ({
    ...base,
    borderColor: ThemeDefault.black,
    color: ThemeDefault.black,
  }),
};

const CoriganSelect: React.FC<CoriganSelectProps> = (props: CoriganSelectProps) => {
  const { className, disabled } = props;

  let classList = `select`;
  if (className) classList += ` ${className}`;

  const data = {
    ...props,
  };

  if (data.className) delete data.className;

  if (disabled) data.isDisabled = disabled;
  if (data.disabled) delete data.disabled;

  return (
    <StyledSelect className={classList}>
      <Select {...data} styles={customStyles} />
    </StyledSelect>
  );
};

// Default prop values
CoriganSelect.defaultProps = {
  isMulti: true,
  isSearchable: true,
  value: [],
};

export default CoriganSelect;

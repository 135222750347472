import styled from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const newNotifications = props => {
  if (props.toViewNotification) return `block`;
  return `none`;
};

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1920px;
  position: relative;
  width: 100%;

  border-top: 6px solid var(--border-colour);

  @media ${device?.xs} {
    flex-direction: row;
  }

  @media ${device?.md} {
    margin-bottom: 24px;
    padding: 0 30px;
    padding-top: 8px;
  }

  button {
    /* Button Reset CSS */
    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    background-color: ${() => (shouldBeDark() ? `transparent` : `transparent`)};
    border: none;

    svg {
      margin-left: 0;
    }
  }

  input[type='search'] {
    background-color: transparent;
    max-height: 48px;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      outline: none;
    }

    &:active,
    &:focus {
      box-shadow: 1px solid ${() => (shouldBeDark() ? `var(--grey200)` : `var(--purple)`)};

      &::placeholder {
        transform: translateX(4px) translateY(2px);
      }
    }

    &::placeholder {
      transform: translateX(0) translateY(2px);
      transition: 0.2s transform ease;
    }
  }

  .header__search {
    flex: 1;
    width: 100%;

    @media ${device?.xs} {
      width: auto;
    }

    fieldset {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    input,
    input:not([type='checkbox']):not([type='radio']) {
      height: 100%;
      margin: 0;
      width: 100%;

      border-radius: 0;
      border-width: 0px;

      border-bottom-width: 1px;
    }

    label {
      margin: 0;
      padding: 8px 11px;
      padding-left: 0px;

      svg {
        height: 25px;
      }
    }
  }

  .header__search__wrapper {
    flex: 1;
    position: relative;
  }

  input,
  input:not([type='checkbox']):not([type='radio']),
  > nav {
    padding: 8px;
  }

  input[type='search']:not([type='checkbox']):not([type='radio']) {
    padding-left: 0;
  }

  nav {
    align-items: center;
    display: flex;
    height: 100%;
    margin-top: 0;

    @media ${device?.sm} {
      position: relative;
    }
  }

  svg {
    display: block;
    height: 18px;

    transition: 0.2s fill ease;
  }

  .header__actions,
  .header__domains,
  .header__user {
    button {
      padding: 8px;

      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
      transition: 0.2s color ease;

      &:active,
      &:focus,
      &:focus-within,
      &:hover,
      &.active {
        color: ${() => (shouldBeDark() ? `var(--grey200)` : `var(--grey900)`)};
      }
    }
  }

  .header__overlay {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */

    border-radius: 3px;
    flex-direction: column;
    right: 0%;
    position: absolute;
    top: 100%;
    z-index: 101;

    background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--white)`)};
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--grey900)`)};
    opacity: 0;
    transition: 0.4s opacity cubic-bezier(0.25, 0.8, 0.25, 1), 0.3s box-shadow cubic-bezier(0.25, 0.8, 0.25, 1);

    @media ${device?.sm} {
      left: 50%;
      right: initial;

      transform: translateX(-50%);
    }

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    &::after {
      content: '';
      display: block;
      height: 16px;
      position: absolute;
      right: calc(50% - 12px);
      top: -2px;
      width: 16px;
      z-index: -1;

      background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--white)`)};
      pointer-events: none;
      transform: rotate(45deg) translateX(-50%);
      transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    & > * {
      position: static;
      z-index: 1;
    }

    header {
      padding: 24px 32px;
      width: 100%;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${() => (shouldBeDark() ? `var(--white)` : `#4d4f5c`)};

        font-size: 17px;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
      }
    }
  }

  .header__overlay--show {
    height: auto;
    overflow: unset;
    width: auto;

    clip: unset;
    opacity: 1;
    white-space: normal;
  }

  .header__actions {
    padding: 8px 16px 8px 0;
    width: 100%;

    background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--grey200)`)};

    button {
      padding: 8px 11px;
    }

    @media ${device?.xs} {
      width: auto;

      background-color: transparent;
    }
  }

  .header__actions,
  .header__domains {
    @media ${device?.sm} {
      position: relative;

      &::after {
        content: '';
        display: block;
        height: 24px;
        position: absolute;
        right: 0;
        top: calc(50% - 12px);
        width: 1px;

        background-color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey400)`)};
      }
    }
  }

  .header__action__applications {
    svg {
      height: 22px;
    }
  }

  .header__applications {
    &::after {
      right: calc(40% - 8px);
    }

    @media ${device?.sm} {
      left: 0%;

      transform: translateX(-55%);
    }

    header {
      h2 {
        text-align: center;
      }
    }
  }

  .header__avatar {
    display: none;

    @media ${device?.sm} {
      display: block;
      margin-left: 16px;
    }

    img {
      overflow: hidden;

      text-indent: 100%;
      white-space: nowrap;
    }

    img,
    svg {
      border-radius: 50%;
      display: block;
      height: 48px;
      min-width: 48px;
      width: 48px;

      object-fit: cover;
    }

    svg {
      fill: var(--primary);
    }
  }

  .header__avatar.header__avatar {
    svg {
      margin-left: 0;
    }
  }

  .header__action__notifications {
    button {
      position: relative;

      &::after {
        border-radius: 50%;
        content: '';
        display: ${props => newNotifications(props)};
        height: 12px;
        position: absolute;
        right: calc(50% - 12px);
        top: calc(50% - 16px);
        width: 12px;

        background-color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--primary)`)};
        border: 2px solid ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
      }
    }
  }

  .header__notifications {
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--purple)`)};

    span {
      display: block;
      width: calc(100% - 32px);
    }

    svg {
      margin-right: 8px;
      width: 24px;
    }

    .notifications {
      display: flex;
      flex-direction: column;
      width: 460px;
    }

    .notification {
      align-items: flex-start;
      display: flex;
      padding: 24px 32px;
      position: relative;
      width: 100%;

      color: inherit;
      text-decoration: none;
      transition: 0.2s background-color ease, 0.2s color ease;

      &::before {
        content: '';
        height: 1px;
        left: 0;
        left: calc(0% + 20px);
        position: absolute;
        top: 0;
        width: 100%;
        width: calc(100% - 40px);

        background-color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--purple)`)};
        opacity: ${() => (shouldBeDark() ? 1 : 0.1)};
      }

      footer {
        margin-top: 2px;

        color: ${() => (shouldBeDark() ? `var(--grey200)` : `var(--grey600)`)};
      }

      img {
        display: block;
        height: 48px;
        margin-right: 16px;
        width: 48px;

        background-color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey200)`)};
        border-radius: 50%;
        object-fit: cover;
      }

      p {
        margin: 0;
      }
    }

    a.notification {
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--grey100)`)};
        color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
      }
    }

    .notification__body {
      flex: 1;
      padding-right: 24px;
    }

    .notification__dismiss {
      padding: 0;

      svg {
        height: 24px;
        margin: 0;

        color: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey900)`)};
        fill: currentColor;
        transition: 0.2s color ease;
      }

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        svg {
          color: ${() => (shouldBeDark() ? `var(--grey300)` : `var(--grey500)`)};
        }
      }
    }

    .notification__username {
      display: inline-block;
      margin-right: 4px;
      width: auto;

      font-weight: 700;
    }
  }

  .header__support {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 300px;

    a,
    .support {
      align-items: center;
      display: flex;
      padding: 10px 15px;

      text-decoration: none;
      transition: 0.2s color ease;
      width: 100%;
    }

    span {
      display: block;
      width: calc(100% - 32px);
    }

    svg {
      margin-right: 8px;
      width: 24px;
    }

    .header__support__more {
      justify-content: center;
      margin-top: 15px;
      padding: 10px 0 15px;

      border-top: 1px solid ${() => (shouldBeDark() ? `var(--grey/00)` : `var(--grey400)`)};
      color: ${() => (shouldBeDark() ? `var(--grey300)` : `var(--grey700)`)};
      text-align: center;
      transition: 0.2s color ease;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
      }
    }
  }

  .header__search {
    margin-right: 12px;
    position: relative;
    z-index: 9;
  }

  .header__search__pagination {
    display: flex;
    width: 100%;

    border-top: 1px solid var(--blue050);

    button {
      border-radius: 0;
      margin-top: 0;
      justify-content: center;
      width: 100%;

      background-color: var(--blue300);
      border-top: none;
      color: var(--white);
    }

    button[disabled] {
      background-color: var(--grey200);
      color: var(--grey900);
    }

    button + button {
      border-left: 1px solid var(--blue400);
    }

    button + button[disabled],
    button[disabled] + button {
      border-left: 1px solid var(--grey400);
    }
  }

  .header__search__results {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: auto;
    position: absolute;
    top: 100%;
    width: 100%;

    a,
    p {
      display: flex;
      margin: 0;
      padding: 12px;
      width: 100%;

      background-color: ${() => (shouldBeDark() ? `var(--blue900)` : `var(--blue000)`)};
      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};
    }

    a {
      transition: 0.2s background-color ease;
      text-decoration: none;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: ${() => (shouldBeDark() ? `var(--blue800)` : `var(--blue050)`)};
      }
    }

    a + a {
      border-top: 1px solid ${() => (shouldBeDark() ? `var(--blue900)` : `var(--blue050)`)};
    }

    span + span {
      margin-left: auto;
      text-transform: capitalize;
    }
  }

  .header__domains,
  .header__user {
    button {
      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    }

    svg {
      margin-left: 4px;
    }
  }

  .header__user {
    display: none;
    padding-right: 0;

    @media ${device?.xs} {
      display: flex;
    }
  }

  .header__domain__wrap,
  .header__user__wrap {
    position: relative;
  }

  .header__domain__buttons,
  .header__user__links {
    padding: 4px 0;
    min-width: 200px;

    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--purple)`)};
    line-height: 24px;
    text-align: left;

    button {
      width: 100%;

      text-align: left;
      text-transform: capitalize;

      &::before {
        display: none;
      }
    }

    a,
    button,
    span {
      display: block;

      white-space: nowrap;
    }

    a,
    button {
      padding: 12px 16px;
      position: relative;

      color: inherit;
      text-decoration: none;
      transition: 0.2s background-color ease;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: ${() => (shouldBeDark() ? `var(--grey800)` : `var(--offWhite)`)};
      }

      @media ${device?.sm} {
        padding: 16px 24px;
      }
    }

    a + a,
    a + button,
    button + a,
    button + button {
      &::before {
        content: '';
        height: 1px;
        left: 0;
        left: calc(0% + 20px);
        position: absolute;
        top: 0;
        width: 100%;
        width: calc(100% - 40px);

        background-color: ${() => (shouldBeDark() ? `var(--white)` : `var(--purple)`)};
        opacity: 0.1;
      }
    }
  }

  .user__option--disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .header__domain__options,
  .header__user__options {
    width: 100%;
  }

  .header__domain__options {
    button {
      text-transform: initial;
    }
  }

  .header__domain__toggle,
  .header__user__toggle {
    padding: 8px;

    svg {
      transform: rotate(0);
      transition: 0.2s transform ease;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .header__domain,
  .header__username {
    display: block;

    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-transform: initial;
  }
`;

export default StyledHeader;

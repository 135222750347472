import * as React from 'react';

import Revisions from '../revisions';

declare type HIstoryProps = { isLocked: boolean };

const History: React.FC<HIstoryProps> = (props: HIstoryProps) => {
  const { isLocked } = props;

  return <Revisions drafts={false} isLocked={isLocked} />;
};

export default History;

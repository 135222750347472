import * as React from 'react';
import { useContext } from 'react';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { ProtectedRoute, ROUTES } from 'corigan';
import { differencesBetweenObjects } from 'corigan';
import { updateSingleCache } from 'corigan';

// Components
import { Avatar, Button, Link } from 'corigan';
import { Breadcrumbs, Card, Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callUpdateUser } from 'corigan';
import { callMe } from 'corigan';

const Wrapper: React.FC = () => (
  <ProtectedRoute>
    <ProfilePage />
  </ProtectedRoute>
);

type ProfilePageProps = {};

const ProfilePage: React.FC<ProfilePageProps> = (props: ProfilePageProps) => {
  // Fetch user info
  const { data: res, error, isLoading: loading } = useQuery([`callMe`], callMe);
  const user = res?.data;

  return (
    <Page application="portal" pageTitle="Profile">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <h1>Profile</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card loading={loading}>
              {error && <Error error={error} />}
              {user && <ProfileContent user={user} />}
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

type FormErrors = {
  name?: string;
  email?: string;
};

type FormValues = {
  name: string;
  email: string;
  roles?: any[];
};

declare type ProfileContentProps = {
  user: User;
};

const ProfileContent: React.FC<ProfileContentProps> = (props: ProfileContentProps) => {
  const { user } = props;
  const id = user?.id;

  const queryCache = useQueryCache();

  const context: ApplicationContextProps = useContext(ApplicationContext);
  const avatar = context?.state?.currentUser?.photoURL;

  // TODO: Allow self role management?
  // const { userHasPermission: canUpdateUser } = useHasPermissions({ requiredPermissions: [`users:update`] });
  // const canManageRoles: boolean = canUpdateUser;
  // let initialRoles = [];
  // if (user?.roles) initialRoles = user?.roles;

  const initialValues: FormValues = { name: user?.name, email: user?.email };

  const [mutate, { error, isLoading: loading, isSuccess }] = useMutation(callUpdateUser, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callMe`]);
      queryCache.invalidateQueries([`callGetOneUser`, { id }]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      // If callUpdateUser function was successful, get values from API response
      const userData: User = data?.data;
      const id = userData?.id;

      // Optimistically update to the new value
      queryCache.setQueryData([`callMe`], { data: userData });

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetOneUser`, { id }], userData);

      // Parameters for single user query
      const singleWith = [`roles`, `teams`];
      const singleWhere: ArgWhere = `[_id][eq]=${id}`;

      // Parameters for inital all users query
      const mastersheetPerPage = 10;
      const mastersheetWith: ArgWith = [`roles`, `teams`, `teams.domains`];

      // Optimistically update to the new value for mastersheet page
      queryCache.setQueryData(
        [`callGetManyUsers`, { perPage: mastersheetPerPage, _with: mastersheetWith }],
        (previousUsers: APIResponse) => updateSingleCache(previousUsers, userData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyUsers`, { _with: singleWith, where: singleWhere }],
        (previousUsers: APIResponse) => updateSingleCache(previousUsers, userData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyUsers`, { perPage: 1000 }], (previousUsers: APIResponse) =>
        updateSingleCache(previousUsers, userData),
      );
    },
  });

  const processSubmit = async values => {
    const changedValues = differencesBetweenObjects(initialValues, values);
    const noChanges = Object.entries(changedValues).length === 0;

    if (noChanges) return;

    const { email, name } = values;

    await mutate({ id, email, name });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: processSubmit,
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { name, email } = values;
      if (!name) errors.name = `A username is required`;
      if (!email) errors.email = `An email address is required`;
      return errors;
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = formik;
  const { email, name } = values;

  return (
    <React.Fragment>
      <Avatar alt={`Profile avatar for ${name}`} size="lg" src={avatar} />
      {error && <Error error={error} />}
      {isSuccess && <p>Updated successfully</p>}
      <form onSubmit={handleSubmit}>
        <fieldset aria-busy={loading} disabled={loading}>
          <label htmlFor="name">Username</label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={name}
            aria-invalid={touched.name && !!errors.name}
            aria-describedby="errorName"
          />
          {touched.name && errors.name && <Error id="errorName" error={errors.name} />}
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={email}
            aria-invalid={touched.email && !!errors.email}
            aria-describedby="errorEmail"
          />
          {touched.email && errors.email && <Error id="errorEmail" error={errors.email} />}
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? `Updating` : `Update`}
          </Button>
        </fieldset>
      </form>
    </React.Fragment>
  );
};

export default Wrapper;

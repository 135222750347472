import * as React from 'react';

function SvgArrowDown(props) {
  return (
    <svg viewBox="0 0 12.197 13" aria-hidden="true" {...props}>
      <path
        d="M4.574 5.2v6.5A1.346 1.346 0 006.097 13a1.346 1.346 0 001.526-1.3V5.2h4.574L6.097 0l-6.1 5.2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowDown;

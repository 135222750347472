import { css } from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const form = css`
  form {
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};

    > *:first-child {
      margin-top: 0;
    }

    button,
    input,
    select,
    textarea {
      margin-top: var(--spacingDefault);
    }

    > button:last-of-type {
      margin-top: 24px;
    }

    input[type='checkbox'] + label,
    input[type='radio'] + label {
      margin-top: 24px;

      & + input[type='checkbox'] + label,
      & + input[type='radio'] + label {
        margin-top: var(--spacingDefault);
      }
    }

    & + * {
      margin-top: 32px;
    }
  }

  .form--trimmed {
    fieldset {
      margin-bottom: 0;

      > *:first-child {
        margin-top: 0;
      }
    }
  }

  .form--horizontal {
    width: 100%;

    .form--fields {
      display: flex;
      flex-direction: column;

      @media ${device?.sm} {
        align-items: flex-end;
        flex-direction: row;
      }

      > * {
        margin: 0;
        width: auto;

        & + * {
          margin-top: 16px;

          @media ${device?.sm} {
            margin-left: 8px;
            margin-top: 0;
          }
        }
      }

      button {
        margin-top: 0;
      }

      label {
        margin-top: 0;
      }

      span {
        display: inline-block;

        white-space: nowrap;
      }
    }

    .form--connector {
      margin-top: 10px;
      margin-bottom: -8px;
      width: 450px;
      display: flex;

      hr {
        width: 40%;
        margin: auto;
      }

      span {
        margin: auto;
      }
    }
  }

  .form__footer {
    margin-top: 24px;

    color: ${() => (shouldBeDark() ? `var(--grey100)` : `var(--grey800)`)};
    font-size: 15px;
    line-height: 25px;
    text-align: center;
  }
`;

export default form;

declare type GenerateSeriesVolumeArgs = {
  keyword: Keyword;
  volume: any;
};

const cleanArray = (array: any[]): any[] => {
  const hasArray: boolean = array?.length > 0;
  if (!hasArray) return [];

  return array.filter(Boolean);
};

export const generateSeriesVolume = (props: GenerateSeriesVolumeArgs) => {
  const { keyword, volume } = props;

  const hasVolume: boolean = volume?.length > 0;
  if (!hasVolume) {
    console.warn(`No volume was provided to generateSeriesVolume for volume chart`);
    return null;
  }

  const name = `${keyword?.phrase} volume`;

  const dirtyData = volume.map(entry => {
    const { date, search_volume: searchVolume } = entry;

    return {
      x: date,
      y: searchVolume,
    };
  });
  const cleanData = cleanArray(dirtyData);

  return [{ name, data: cleanData }];
};

export default generateSeriesVolume;

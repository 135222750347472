import { fetchAPI } from 'particles';

type UpdateUserParameters = {
  id: ArgID;
  email?: ArgEmail;
  name?: ArgName;
  notifications?: CoriganNotification[];
  roles?: ArgRoles;
  teams?: ArgTeams;
  _with?: ArgWith;
};

export const callUpdateUser = async (parameters: UpdateUserParameters): Promise<{ data: User }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `users` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdateUser;

import { css } from 'styled-components';
import { shouldBeDark } from 'particles';

const getThemeValues = props => {
  let rootCustomProperties = ``;
  if (!props || !props.theme) return ``;

  Object.keys(props.theme).map(key => {
    const val = props.theme[key];
    rootCustomProperties += `--${key}: ${val};`;
    return null;
  });

  return rootCustomProperties;
};

const body = css`
  :root {
    ${props => getThemeValues(props)}
  }

  ::selection {
    background: var(--primary);
    color: var(--white);
  }

  ::-webkit-scrollbar {
    width: 15px;
    background-color: var(--grey100);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--grey400);
    border: 2px solid var(--grey400);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--grey100);
  }

  body {
    overflow-x: hidden;
    overflow-y: scroll;

    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--bodyBackground)`)};
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    font-family: var(--font);
    font-size: 1.6rem;
    line-height: 1.5;
    scroll-behavior: smooth;
  }
`;

export default body;

import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { ROUTES } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { callGetManyPageTypes } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Localized Partials
import CreatePageForm from './partials/form';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.mastersheet} requiredPermissions={[`pages:create`]}>
    <CreatePageParent />
  </ProtectedRoute>
);

type CreatePageParentProps = {};

const CreatePageParent = (props: CreatePageParentProps) => {
  const { data: res, error, isLoading: loading } = useQuery([`callGetManyPageTypes`], callGetManyPageTypes);
  const data: PageType[] = res?.data;

  return (
    <Page application="optimisation-manager" pageTitle="Create Page">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.optimisation_manager}>Dashboard</Link>
              <Link href={ROUTES.mastersheet}>Mastersheet</Link>
              <h1>Create Page</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card loading={loading}>
              {error && <Error error={error} />}
              {data && <CreatePageForm pageTypes={data} />}
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

export default Wrapper;

import * as React from 'react';

function SvgDashboard(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M0 13.3h10.7V0H0v13.3zM0 24h10.7v-8H0v8zm13.3 0H24V10.7H13.3V24zm0-24v8H24V0H13.3z"
      />
    </svg>
  );
}

export default SvgDashboard;

import { createGlobalStyle } from 'styled-components';

import normalize from 'styles/setup/normalize';
import boxSizing from 'styles/setup/box-sizing';
import fonts from 'styles/setup/fonts';
import id from 'styles/setup/id';
import storybook from 'styles/setup/storybook';

import anchor from 'styles/base/anchor';
import article from 'styles/base/article';
import body from 'styles/base/body';
import button from 'styles/base/button';
import code from 'styles/base/code';
import fieldset from 'styles/base/fieldset';
import form from 'styles/base/form';
import heading from 'styles/base/heading';
import hr from 'styles/base/hr';
import html from 'styles/base/html';
import iframe from 'styles/base/iframe';
import image from 'styles/base/image';
import input from 'styles/base/input';
import label from 'styles/base/label';
import main from 'styles/base/main';
import select from 'styles/base/select';
import table from 'styles/base/table';
import textarea from 'styles/base/textarea';
import video from 'styles/base/video';
import list from 'styles/base/list';

import apexCharts from 'styles/components/apex-charts';
import avatar from 'styles/components/avatar';
import chips from 'styles/components/chips';
import datePicker from 'styles/components/date-picker';
import searchCSS from 'styles/components/search';
import statisticsCSS from './styles/components/statistics';
import toastCSS from './styles/components/toast';

import background from 'styles/utilities/background';
import display from 'styles/utilities/display';
import flex from 'styles/utilities/flex';
import hidden from 'styles/utilities/hidden';
import margin from 'styles/utilities/margin';
import padding from 'styles/utilities/padding';
import position from './styles/utilities/position';
import text from 'styles/utilities/text';

const GlobalStyle = createGlobalStyle`
	/* Setup (Fonts, box-sizing, etc.) */
	${normalize}
	${boxSizing}
	${fonts}
	${id}
	${storybook}

	/* Base (Generic HTML5 standard elements) */
	${anchor}
	${article}
	${body}

	button,
  .button,
  input[type='reset'],
  input[type='submit'] {
		${button}
	}

  .button--link,
  .button--reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;
    box-shadow: none;
    color: inherit;
    font: inherit;

    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &::before {
      display: none;
    }
  }

	${code}
	${form}
	${heading}
	${hr}
	${html}
	${iframe}
	${image}
	${input}
	${fieldset}
	${label}
	${main}
	${select}
	${table}
	${textarea}
	${video}
	${list}

	/* Components */
	${apexCharts}
	${avatar}
	${chips}
	${datePicker}
	${searchCSS}
	${statisticsCSS}
	${toastCSS}

	/* Utilities */
	${background}
	${display}
	${flex}
	${hidden}
	${margin}
	${padding}
	${position}
	${text}
`;

export default GlobalStyle;

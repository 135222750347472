import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import Content from './content';

const MissingID = () => {
  const message = `Unfortunately we could not find a tag as no ID was provided`;

  return (
    <Content data={null} loading={false} title={`Sorry, we couldn't find that tag`}>
      <Error error={message} />
      <ReturnLink />
    </Content>
  );
};

const ReturnLink = () => (
  <p>
    Please return to the <Link href={ROUTES.tags}>full list of tags</Link>
  </p>
);

export default MissingID;

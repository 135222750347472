import { css } from 'styled-components';
import { shouldBeDark } from 'corigan';

const button = css`
  align-items: center;
  display: inline-flex;
  overflow: hidden;
  padding: ${props => (props?.small ? `8px` : `16px`)};
  position: relative;
  width: auto;

  background-color: ${() => (shouldBeDark() ? `transparent` : `var(--black)`)};
  border-radius: 3px;
  border: 1px solid ${() => (shouldBeDark() ? `var(--black)` : `var(--black)`)};
  color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s background-color ease;

  &:disabled {
    background-color: ${() => (shouldBeDark() ? `var(--grey800)` : `var(--grey200)`)};
    border-color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey400)`)};
    color: ${() => (shouldBeDark() ? `var(--grey700)` : `var(--grey400)`)};
    cursor: not-allowed;
    pointer-events: none;
  }

  &::before {
    border-radius: 3px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    background-color: ${() => (shouldBeDark() ? `var(--black)` : `var(--white)`)};
    opacity: 0;
    transition: 0.2s opacity ease;
  }

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    text-decoration: none;

    &::before {
      opacity: 0.2;
    }
  }

  svg {
    height: 16.3px;

    fill: currentColor;
  }

  &.button--hollow {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17px;
    text-transform: initial;

    &::before {
      background-color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    }

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      &::before {
        opacity: 0.05;
      }
    }
  }
`;

export default button;

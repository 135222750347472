import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useFormik, FormikProps } from 'formik';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { CRContext } from 'corigan';
import { isString } from 'corigan';
import { ROUTES } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { useQueryParameters } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { DomainSelector } from 'corigan';
import { Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callGetReportPageInternal } from 'corigan';
import { callGetReportPageCompetitor } from 'corigan';

// Local Components
import PageSearch from './partials/search';
import PageOverview from './partials/overview';

// Definitions
import { FormikValues } from './definitions';

const PerformanceComparison = () => (
  <ProtectedRoute>
    <Page application="competitor-research" pageTitle="Performance Comparison">
      <PageContents />
    </Page>
  </ProtectedRoute>
);

const onSubmit = async values => {
  const domainToDownload = values?.domain;
  return domainToDownload;
};

const PageContents = () => {
  const queryParameters = useQueryParameters();
  const { search: searchQuery, competitor: competitorQuery, compurl: compURLQuery, prefurl: prefURLQuery } = queryParameters;

  const prefURL = isString(compURLQuery) ? String(prefURLQuery) : undefined;
  // const compURL = isString(prefURLQuery) ? String(compURLQuery) : false;
  // const competID = isString(competitorQuery) ? String(competitorQuery) : false;

  const context = useContext(CRContext);
  const state = context?.state;
  const dispatch = context?.dispatch;
  const competitor = state?.competitor?.id || state?.competitor;
  const competitorURL = state?.competitorURL;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainId = domainActive?.id;

  const initialValues: FormikValues = {
    page: null,
    search: isString(searchQuery) ? String(searchQuery) : ``,
  };

  const formik: FormikProps<FormikValues> = useFormik<FormikValues>({
    initialValues,
    onSubmit,
  });
  const { handleSubmit, isSubmitting } = formik;

  // Get the page value if a page has been set in the application
  const pageRevision: PageRevision | null = formik?.values?.page;

  // Should we be running the 'callGetReportPageInternal' query?
  const page = pageRevision?.page?.id;
  const runInternalQuery: boolean = !!page || !!prefURL;
  const { data: iRes, error: iError, isLoading: iLoading, isSuccess: iSuccess } = useQuery(
    [`callGetReportPageInternal`, { page, domainId, url: prefURL }],
    callGetReportPageInternal,
    { enabled: runInternalQuery },
  );
  const iData = iRes?.data;

  // Should we be running the 'callGetReportPageCompetitor' query?
  const url = competitorURL || iData?.url;
  const runCompetitorQuery = runInternalQuery && competitor && url;
  const { data: cRes, error: cError, isLoading: cLoading, isSuccess: cSuccess } = useQuery(
    [`callGetReportPageCompetitor`, { url, internal: !competitorURL, competitor, domainId }],
    callGetReportPageCompetitor,
    { enabled: runCompetitorQuery },
  );

  const cData = cRes?.data;
  const error = iError || cError;
  const isSuccess = iSuccess || cSuccess;

  useEffect(() => {
    if (dispatch && competitorQuery) {
      const competitorQueryStr = String(competitorQuery);

      dispatch({ type: `set`, key: `competitor`, value: competitorQueryStr });
    }
    if (dispatch && compURLQuery) {
      const compURLQueryStr = String(compURLQuery);

      dispatch({ type: `set`, key: `competitorURL`, value: compURLQueryStr });
    }
  }, [dispatch, competitorQuery, compURLQuery]);

  useEffect(() => {
    return function cleanup() {
      if (dispatch) {
        dispatch({ type: `set`, key: `competitor`, value: `` });
        dispatch({ type: `set`, key: `competitorURL`, value: `` });
      }
    };
  }, [dispatch]);

  useEffect(() => {
    document.title = `Corigan - Competitor Research: Performance Comparison - ${page}`;
  }, [page]);

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.competitor_research}>Competitor Research</Link>
            <h1>Performance Comparison</h1>
          </Breadcrumbs>
          {error && <Error error={error} />}
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card loading={iLoading} minHeight={false}>
            <PageSearch formik={formik} />
          </Card>
        </Col>
        <Col xl={6}>
          {pageRevision && (
            <Card animate={true} minHeight={false}>
              <form onSubmit={handleSubmit}>
                <fieldset className="mb-0" aria-busy={isSubmitting} disabled={isSubmitting}>
                  <DomainSelector id="domain-selector" competitorsOnly={true} margin={false} />
                </fieldset>
              </form>
            </Card>
          )}
        </Col>
      </Row>
      <Row equalHeight={true}>
        <Col xl={6}>
          {isSuccess && (
            <Card animate={true} loading={iLoading}>
              <PageOverview data={iData} formik={formik} internal={true} />
            </Card>
          )}
        </Col>
        <Col xl={6}>
          {isSuccess && (
            <Card animate={true} loading={cLoading}>
              <PageOverview data={cData} formik={formik} internal={false} />
            </Card>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default PerformanceComparison;

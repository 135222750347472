import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/app';

import firebaseMethods from '../methods';

let instance = null;

export default function getFirebase() {
  const windowAvailable: boolean = typeof window !== `undefined`;
  if (!windowAvailable) return null;

  const isStorybook: boolean = process.env.STORYBOOK_ENABLED === `true`;

  // Firebase web config
  let config = {
    apiKey: process.env.GATSBY_API_KEY,
    authDomain: process.env.GATSBY_AUTHDOMAIN,
    databaseURL: process.env.GATSBY_BASEURL,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGEBUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
    measurementId: process.env.GATSBY_MEASUREMENT_ID,
  };

  if (isStorybook)
    config = {
      apiKey: process.env.STORYBOOK_API_KEY,
      authDomain: process.env.STORYBOOK_AUTHDOMAIN,
      databaseURL: process.env.STORYBOOK_BASEURL,
      projectId: process.env.STORYBOOK_PROJECT_ID,
      storageBucket: process.env.STORYBOOK_STORAGEBUCKET,
      messagingSenderId: process.env.STORYBOOK_MESSAGING_SENDER_ID,
      appId: process.env.STORYBOOK_APP_ID,
      measurementId: process.env.STORYBOOK_MEASUREMENT_ID,
    };

  if (instance) return instance;

  // Iniialise Firebase
  instance = firebase.initializeApp(config);

  // Pass methods from Firebase to application
  const allMethods = firebaseMethods(instance, windowAvailable);
  const fullInstance = { ...instance, ...allMethods };
  return fullInstance;
}

import * as React from 'react';

function SvgRankings(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M 2 20.5851 L 3.6327 22 L 9.551 16.1383 L 13.4286 19.9787 L 22 10.4787 L 20.5714 8.8617 L 13.4286 16.7447 L 9.551 12.9043 L 2 20.5851 ZM 2 14.7234 L 3.6327 16.1383 L 9.551 10.2766 L 13.4286 14.117 L 22 4.617 L 20.5714 3 L 13.4286 10.883 L 9.551 7.0426 L 2 14.7234 Z"
      />
    </svg>
  );
}

export default SvgRankings;

import { css } from 'styled-components';

const storybook = css`
  /* Storybook specific */
  .sbdocs-content {
    > div[id]:first-of-type {
      .sbdocs-preview {
        > div[class] {
          > div[class]:first-of-type {
            min-height: 400px;
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
  }

  .sbdocs-preview {
    background-color: var(--bodyBackground);

    > div[class]:first-of-type {
      > div[class]:first-of-type {
        min-height: 400px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
`;

export default storybook;

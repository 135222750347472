import 'react-dates/lib/css/_datepicker.css'; // Airbnb Date Picker
import { css } from 'styled-components';

/* NOTE: the order of these styles DO matter */
const datePicker = css`
  .CalendarDay__default {
    position: relative;
    z-index: 1;

    background: transparent;
    border-color: transparent;
    color: var(--black);

    &::after {
      border-radius: 50%;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      background-color: var(--primary);
      border: 1px solid transparent;
      opacity: 0;
      transition: 0.05s opacity ease;
    }

    &:active,
    &:hover {
      background: transparent;
      border-color: transparent;
      color: var(--primary);
      font-weight: 700;

      &::after {
        opacity: 0.2;
      }
    }
  }

  .DayPicker_weekHeader_li {
    color: #a3a6b4;
    font-size: 15px;
    font-weight: 700;

    small {
      font-size: 100%;
    }
  }

  .CalendarMonth_caption {
    padding-bottom: 48px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span,
  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover {
    background: transparent;
    border-color: transparent;
    color: var(--primary);
    font-weight: 700;

    &::after {
      opacity: 0.2;
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover {
    &::after {
      border-radius: 0%;
    }
  }

  .SingleDatePicker,
  .DateRangePicker {
    td[aria-disabled='true']:not(.CalendarDay__selected) {
      opacity: 0.2;
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected_start:active,
    .CalendarDay__selected_start:hover {
      &::after {
        border-radius: 50% 0 0 50%;
      }
    }

    .CalendarDay__selected_end,
    .CalendarDay__selected_end:active,
    .CalendarDay__selected_end:hover {
      &::after {
        border-radius: 0 50% 50% 0;
      }
    }

    .CalendarDay__selected_start.CalendarDay__selected_end {
      &::after {
        border-radius: 50%;
      }
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: var(--primary);
  }

  .DateInput {
    width: 150px;

    text-align: center;
  }

  .DateInput_input {
    border: none !important;
  }

  .DayPickerNavigation_button__default {
    border: none !important;
  }

  .CalendarMonth_caption {
    font-size: 15px;
    font-weight: 700;
  }
`;

export default datePicker;

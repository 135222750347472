import { getRelease } from 'corigan';
import { isUndefined } from 'corigan';
import { localStorageSet } from 'corigan';
import { windowAvailable } from 'corigan';

declare type ReducerAction = {
  key?: string;
  type?: string;
  value?: any;
};

const minPages = 1;
const maxPages = 6;

// Create global page reducer to handle state changes
export const krReducer = (state: KRContextProps['state'], action: ReducerAction) => {
  const { releaseVersion } = getRelease();

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = (key: string): string => `version=${releaseVersion}&key=krConfig${key}`;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setLocalValue = (stateKey: 'ChartDateEnd' | 'ChartDateStart', newValue: any, hoursToExpireFrom?: number) => {
    const hasWindow = windowAvailable();
    if (!hasWindow) return;

    localStorageSet(buildKey(stateKey), newValue, hoursToExpireFrom);
  };

  const { key, type = `set`, value } = action;

  // Create a copy of state to prevent any chance of mutating
  const newState = { ...state };

  if (isUndefined(value)) return newState;

  switch (type) {
    case `enableLoading`:
      // Get the current state of enabled loading UI elements
      const currentLoaded = [...newState.canLoad];

      // If the enabked array already has the string then break
      const alreadEnabled = currentLoaded.includes(value);
      if (alreadEnabled) break;

      // Otherwise concatenate it to the array
      newState.canLoad = [...currentLoaded, value];
      break;

    case `resetPages`:
      newState.pages = [];
      newState.canLoad = [`stats`];
      break;

    case `set`:
      newState[key] = value;
      if (key === `chartDateStart`) setLocalValue(`ChartDateStart`, value, 12);
      if (key === `chartDateEnd`) setLocalValue(`ChartDateEnd`, value, 12);
      break;

    case `togglePage`:
      let hitMax: boolean = false;
      let hitMin: boolean = false;
      let newPages = [...newState.pages];

      const exists: boolean = newPages.some(page => page.url === value.url);

      // We need at least one page for the chart
      if (newPages?.length === minPages && exists) hitMin = true;
      if (hitMin) break;

      // If the page exists, remove it from the current array of pages
      if (exists) newPages = newPages.filter(page => page.url !== value.url);

      // If the page doesn't exist, append it to the array of pages
      if (!exists) newPages = [...newPages, value];

      // Remove first item from array if we are at 6 or above
      if (newPages?.length >= maxPages) hitMax = true;
      if (hitMax) newPages.shift();

      newState.pages = newPages;

      // If we haven't loaded the keyword search volume, load it to state
      const hasVolume: boolean = value?.svMonthly?.length > 0;
      const hasSetVolume: boolean = newState?.volume?.length > 0;

      if (!hasSetVolume && hasVolume) newState.volume = value.svMonthly;

      // If we haven't started loading other parts of UI, enable loading
      const loadedHistory = newState.canLoad.includes(`history`);
      if (!loadedHistory) newState.canLoad = [...newState.canLoad, `history`];

      break;
  }

  return newState;
};

export default krReducer;

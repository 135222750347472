import * as React from 'react';

// Fragments
import { Modal } from 'corigan';
import { Chip } from 'corigan';
import { Link } from 'corigan';
import PageEditor from './editor';

// helpers
import { generateID } from 'corigan';
import { generateChip } from 'corigan';
import { datePretty } from 'corigan';

// props
import type { ChipProps } from 'corigan';

// core checks
const _ = undefined;
const isArray = (argValue: any): boolean => argValue && argValue.constructor === Array && argValue !== null;
const isObject = (argValue: any): boolean => argValue && typeof argValue === `object` && argValue !== null;

// handle chip / link generation

declare type ArrayItem = {
  bold?: boolean;
  component?: 'chip' | 'link';
  condition?: APICondition;
  href?: string;
  target?: string;
  value?: any;
  variant?: ChipProps['variant'];
  where?: string;
};

const renderArray = (argValue: any | any[]) => {
  const wasArray: boolean = isArray(argValue);
  if (!wasArray) return argValue;

  return argValue.map((itemValue: ArrayItem) => {
    if (!itemValue) return null;

    const { bold, component = `chip`, condition, href, target, value, variant } = itemValue;

    switch (component) {
      case `chip`: {
        const isButton: boolean = Boolean(condition);
        const isLink: boolean = Boolean(href);

        const hasVariant: boolean = Boolean(variant);
        const fallbackVariant: ChipProps['variant'] | undefined = isLink ? `primary` : undefined;
        const appliedVariant: ChipProps['variant'] = hasVariant ? variant : fallbackVariant;

        const handleClick: Function = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
          if (e) e.preventDefault();
        };

        let onClick: undefined | Function = undefined;
        if (isButton) onClick = handleClick;

        const wasObject: boolean = isObject(value);
        let val: any = value;
        if (wasObject) val = `object`;

        const key: string = generateID();

        return (
          <Chip bold={bold} href={href} key={key} onClick={onClick} variant={appliedVariant}>
            {val}
          </Chip>
        );
      }
      case `link`: {
        return (
          <p>
            <Link href={href} target={target}>
              {value}
            </Link>
          </p>
        );
      }
    }
  });
};

declare type OverviewModelProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  page: any;
};

const OverviewModel: React.FC<OverviewModelProps> = (props: OverviewModelProps) => {
  const { isOpen, setOpen, page } = props;
  const PageRevision = page.page;
  const ParentPage = PageRevision.page;
  const pageTitle= PageRevision?.name + ` overview`;
  const assignee = PageRevision?.assignedTo?.name;
  const updatedAt = PageRevision?.updatedAt;
  const previousResponse = ParentPage?.crawlResponse?.previousCode ? ParentPage?.crawlResponse?.previousCode : 0;
  const response = ParentPage?.crawlResponse?.responseCode;
  const wordCount = PageRevision?.contentWordCount;
  let updatedBy;
  const isLocked = true;

  if (ParentPage?.updatedBy?.[`name`] && ParentPage?.updatedBy?.[`name`] !== `System`) {
    updatedBy = ParentPage?.updatedBy[`name`];
  } else if (PageRevision?.updatedBy?.[`name`] && PageRevision?.updatedBy?.[`name`] !== `System`) {
    updatedBy = PageRevision?.updatedBy[`name`];
  } else {
    updatedBy = `Not updated`;
  }

  const { url } = PageRevision;
  const { canonicals: revisionCanonicals } = PageRevision;
  const { categories: revisionCategories } = PageRevision;
  const { keywords: revisionKeywords } = PageRevision;
  const { pageId: pageIdString } = PageRevision;
  const { name: revisionName } = PageRevision;
  const { tags: revisionTags } = PageRevision;
  const { status: revisionStatus } = PageRevision;
  const { pageType: revisionPageType } = PageRevision;

  let categories;
  let canonicals;
  let keywords;
  let tags;

  const hasCanonicals: boolean = revisionCanonicals?.length > 0;
  const hasCategories: boolean = revisionCategories?.length > 0;
  const hasKeywords: boolean = revisionKeywords?.length > 0;
  const hasTags: boolean = revisionTags?.length > 0;

  const hasImplementedAt: boolean = Boolean(PageRevision?.implementedAt);
  const implementedAt = PageRevision?.implementedAt ? datePretty(PageRevision?.implementedAt) : ``;
  
  if (hasCanonicals) {
    canonicals = revisionCanonicals
      .filter(c => c?.url)
      .map(c => ({
        component: `link`,
        href: c.url,
        target: `_blank`,
        value: c.url,
      }));
  }

  if (hasCategories) {
    categories = revisionCategories
      .filter(c => c?.category?.name)
      .map(c =>
        generateChip({
          condition: `contains`,
          href: _,
          value: c.category.name,
          where: `categories.category.name`,
        }),
      );
  }

  if (hasKeywords) {
    keywords = revisionKeywords.map(k =>
      generateChip({
        condition: `contains`,
        href: _,
        value: k.phrase,
        where: `keywords.phrase`,
      }),
    );
  }

  if (hasTags) {
    tags = PageRevision.tags.map(t =>
      generateChip({
        condition: `contains`,
        href: _,
        value: t.name,
        where: `tags.name`,
      }),
    );
  }

  let responseVariant = `green`;
  if (response && response >= 300) responseVariant = `orange`;
  if (response && response >= 400) responseVariant = `red`;
  let responseString;
  if (previousResponse > 0 && previousResponse !== response) {
    responseString = `${previousResponse} -> ${response}`;
  } else {
    responseString = `${response}`;
  }

  let assignedTo = null;
  if (assignee) {
    assignedTo = [
      generateChip({
        condition: `eq`,
        href: _,
        value: String(assignee),
        where: `assignedTo.name`,
      }),
    ];
  }

  // model open close functions
  const handleConfirm = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // style classes
  const divStyle = {
    marginBottom: "2em",
  };

  const titleStyle = {
    fontSize: 18,
    fontWeight: 700,
    width: "100%",
    marginBottom: "5px",
  };

  const headerStyle = {
    fontSize: 16,
    fontWeight: 600,
    width: "100%",
    marginBottom: "5px",
  };

  const subHeaderStyle = {
    fontSize: 14,
    fontWeight: 400,
    width: "100%",
    marginBottom: "5px",
    marginTop: "5px",
  };

  const subBoldStyle = {
    fontWeight: 500,
  };

  const statusStyle = {
    fontWeight: 500,
    color: responseVariant
  };

  return (
    <Modal handleConfirm={handleConfirm} handleClose={handleClose} isOpen={isOpen} title={pageTitle} enableConfirm={false}>
      <div style={divStyle}>
        <div style={titleStyle}>{revisionName} - {revisionStatus}</div>
        <a href={url} target="_blank">{url}</a>
        <div style={subHeaderStyle}>Status: <span style={statusStyle}>{responseString}</span></div>
        <div style={subHeaderStyle}>ID: <span style={subBoldStyle}>{pageIdString}</span></div>
        <div style={subHeaderStyle}>Type: <span style={subBoldStyle}>{revisionPageType?.name}</span></div>
        <div style={subHeaderStyle}>Word Count: <span style={subBoldStyle}>{wordCount}</span></div>
      </div>
      <div style={divStyle}>
        <div style={headerStyle}>Metadata</div>
        <div style={subHeaderStyle}>{ParentPage?.metaData?.title}</div>
        <p>{ParentPage?.metaData?.description}</p>
      </div>
      <div style={divStyle}>
        <div style={headerStyle}>Content</div>
        <PageEditor isLocked={isLocked} page={PageRevision} />
      </div>
      {hasKeywords && (<div style={divStyle}>
        <div style={headerStyle}>Keywords</div>
        {renderArray(keywords)}
      </div>)}
      {hasCategories && (<div style={divStyle}>
        <div style={headerStyle}>Categories</div>
        {renderArray(categories)}
      </div>)}
      {hasTags && (<div style={divStyle}>
        <div style={headerStyle}>Tags</div>
        {renderArray(tags)}
      </div>)}
      {hasCanonicals && (<div style={divStyle}>
        <div style={headerStyle}>Canonicals</div>
        {renderArray(canonicals)}
      </div>)}
      {hasImplementedAt && (<div style={divStyle}>
        <p>Implemented at {implementedAt} at {implementedAt}</p>
      </div>)}
      <div style={divStyle}>
        <div style={headerStyle}>Users</div>
        {!assignee && (
          <p>Not Assigned to user</p>
        )}
        {assignee && (
          <p>Assigned to {renderArray(assignedTo)}</p>
        )}
        <p>Updated by {updatedBy} at {updatedAt}</p>
      </div>
    </Modal>
    );
};

export default OverviewModel;

import { fetchAPI } from 'particles';

type UpdateKeywordParameters = {
  id: ArgID;
  excludedFromCategories?: ArgCategory[];
  highPriority?: ArgHighPriority;
  keywordId?: ArgKeywordId;
  phrase?: ArgPhrase;
  priorityCategory?: ArgCategory;
  tags?: ArgTags;
  uplift?: ArgUplift;
  url?: ArgURL;
  _with?: ArgWith;
};

export const callUpdateKeyword = async (parameters: UpdateKeywordParameters): Promise<{ data: Keyword }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `keywords` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdateKeyword;

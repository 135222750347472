import { fetchAPI } from 'particles';

type GetManyKeywordsParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
  _with?: ArgWith;
};

export const callGetManyKeywords = async (
  queryKey,
  parameters: GetManyKeywordsParameters,
): Promise<{ data: Keyword[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `keywords` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetManyKeywords;

import { capitalizeString } from 'corigan';

const cleanName = strVal => {
  if (!strVal) return strVal;
  strVal = strVal.toLowerCase().replace(`https://`, ``).replace(`http://`, ``).replace(`www.`, ``);
  strVal = strVal.endsWith(`/`) ? strVal.slice(0, -1) : strVal;
  return capitalizeString(strVal);
};

function groupBy(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export const generateSeries = ({ categories, competitors, data }) => {
  const dataPreformat = [];
  const series = [];

  for (let i = 0; i < competitors.length; i++) {
    const competitor = competitors[i];
    const scores = [];

    for (let i = 0; i < categories.length; i++) {
      let score = 0;
      const category = categories[i];
      const categoryData = data[category];

      const competitorData = categoryData?.find(entry => {
        const [entryKey] = Object.keys(entry);
        return entryKey === competitor;
      });

      const competitorScore: any = competitorData && Object.values(competitorData)[0];

      if (competitorData) score = competitorScore;
      scores.push(score);

      dataPreformat.push({
        category,
        competitor: cleanName(competitor),
        value: score,
      });
    }

    series.push({
      name: cleanName(competitor),
      data: scores,
    });
  }

  const dataTable = groupBy(dataPreformat, `competitor`);

  return { dataTable, series };
};

export default generateSeries;

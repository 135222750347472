// Escapes all special characters when using regular expressions.
// Taken from:
// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
// And:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping

export const escapeRegExp = (string: string): string => {
  if (!string) return string;
  return string.replace(/[.*+?^${}()|[\]\\]/g, `\\$&`);
};

export default escapeRegExp;

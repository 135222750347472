import moment from 'moment';
import { ROUTES } from 'corigan';

export const generateRows = (data: SERPMovementReports[]) => {
  return data.map(keyword => {
    // define data variables
    const phrase = [{
      href: `${ROUTES.research}?id=${keyword?.id}`,
      value: keyword?.phrase,
      variant: `green`,
      tooltip: `Research - ${keyword?.phrase}`,
      tooltipAlign: `center`,
      tooltipSide: `right`,
    }];

    const createdAt = keyword?.createdAt;
    const volume = keyword?.volume;
    const days = keyword?.days;

    // define core item
    const item = {
      phrase,
      volume,
    };

    let firstPosition;

    // add date and positions to row item and calc averages
    days.forEach((day) => {
      const beforeCreation = moment(day.reportDate).isBefore(createdAt);
      const positionNull = day.position === null;
      const movementNull = day.movement === null;
      const nullBeforeCreation = positionNull && beforeCreation;
      const positionValue = positionNull ? beforeCreation ? `-` : `100+` : day.position
      const movementValue = movementNull ? 0 : day.movement;
      item[day.reportDate] = {
        movement: true,
        value: positionValue,
        movementValue: movementValue.toString(),
        average: false,
      };

      if (!firstPosition  && !nullBeforeCreation) {
        firstPosition = day.position;
      }
    });

    const movementValue = days.length ? firstPosition - days[days.length - 1].position : 0;

    // add averages to item
    item[`total`] = {
      movement: true,
      value: null,
      movementValue,
      average: true,
    };

    return item;
  });
};

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { toast } from 'react-toastify';

// Particles
import { OMContext } from 'corigan';
import { isString } from 'corigan';
import { isUndefined } from 'corigan';

// Molecules
import { Error } from 'corigan';

// API
import { callGetManyUsers } from 'corigan';
import { callUpdatePageRevision } from 'corigan';
import { updateSingleCache } from 'corigan';

// Definitions
import type { FormikProps } from 'formik';
import { FormikValues } from './definitions';

import { statuses } from 'particles';

declare type AssignedToProps = {
  isLocked?: boolean;
};

const AssignedTo: React.FC<AssignedToProps> = (props: AssignedToProps) => {
  const queryCache = useQueryCache();
  const [searchEnabled, setSearchEnabled] = useState(false);

  const context = useContext(OMContext);
  const state = context?.state;
  const dispatch = context?.dispatch;

  const editedRevision: PageRevision = state.editedRevision;
  const revisionID: string = editedRevision?.id;
  const assignedToUser: User | string = editedRevision?.assignedTo;
  const previousAssignedToUser: User | string = editedRevision?.previousAssignedTo;
  const isDraft: boolean = editedRevision?.status === statuses.draft;

  const hasUser = Boolean(assignedToUser);
  const assignee: string = hasUser && isString(assignedToUser?.name) ? `${assignedToUser?.name}` : ``;

  const initialValues: FormikValues = {
    user: null,
    search: assignee,
  };

  const formik: FormikProps<FormikValues> = useFormik<FormikValues>({
    initialValues,
    onSubmit: undefined,
  });
  const { handleChange: handleFormikChange, values, setFieldValue } = formik;
  const { search } = values;

  const isLongEnough: boolean = search?.length >= 2;
  const shouldSearch: boolean = !isUndefined(search) && isLongEnough && searchEnabled;

  const where: ArgWhere = `[name][contains]=${search}`;
  const { data: res, error, isLoading: loading } = useQuery([`callGetManyUsers`, { where }], callGetManyUsers, {
    enabled: shouldSearch,
  });
  const results: User[] = res?.data;
  const hasResults: boolean = results?.length >= 1;

  const [mutate, mutateProgress] = useMutation(callUpdatePageRevision, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to assign user to page`, {});
    },
    // Always refetch after error:
    onSettled: (data, error) => { },
    onSuccess: (data: APIResponse, variables) => {
      // If callUpdatePageRevision function was successful, get values from API response
      const pageRevisionData: PageRevision = data?.data;
      const id = pageRevisionData?.page;

      // These are the same parameters used in the revisions table query
      const perPage = 1000;
      const _with = [`createdBy`];

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetOnePageRevisions`, { drafts: false, id, perPage, _with }],
        (previousRevisions: APIResponse) => {
          // Update the approved revision to pending
          return updateSingleCache(previousRevisions, pageRevisionData);
        },
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetOnePageRevisions`, { drafts: true, id, perPage, _with }],
        (previousRevisions: APIResponse) => {
          // Update the approved revision to pending
          return updateSingleCache(previousRevisions, pageRevisionData);
        },
      );
    },
  });

  useEffect(() => {
    setFieldValue(`user`, assignedToUser);
    setFieldValue(`search`, assignee);
  }, [assignedToUser, assignee, mutate, setFieldValue]);

  const handleChange = async (e) => {
    setSearchEnabled(true);
    handleFormikChange(e);

    if (e.target.value === ``) {
      try {
        await mutate({
          id: revisionID,
          assignedTo: null,
          previousAssignedTo: assignedToUser?.id,
          _with: [`assignedTo`]
        });
      } catch (error) {
        console.error(error);
      } finally {
        setFieldValue(`user`, null);
        dispatch({ key: `assignedTo`, value: null });
      }
    }
  };

  const isBusy: boolean = mutateProgress?.isLoading;

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: User) => {
    const assignedToId = user?.id;
    const originalAssignedToId = assignedToUser?.id;
    const previousAssignedToId = previousAssignedToUser?.id;
    const name = user?.name;

    if (e) e.preventDefault();

    try {
      // Update the currently edited revision
      await mutate({
        id: revisionID,
        assignedTo: assignedToId,
        previousAssignedTo: assignedToId !== originalAssignedToId ? originalAssignedToId : previousAssignedToId,
        _with: [`assignedTo`]
      });
    } catch (error) {
      console.error(error);
    } finally {
      setFieldValue(`user`, user);
      setFieldValue(`search`, name);
      dispatch({ key: `assignedTo`, value: user });
      setSearchEnabled(false);
    }
  };

  return (
    <React.Fragment>
      <form>
        <fieldset aria-busy={isBusy} disabled={isBusy || isDraft} className="m-0">
          <label className="mt-0" htmlFor="search">
            Assignee
          </label>
          <input
            autoComplete="off"
            className="mt-2"
            id="search"
            name="search"
            onChange={handleChange}
            required={true}
            placeholder="Unassigned"
            value={search}
            type="search"
          />
        </fieldset>
        {error && <Error error={error} />}
        {isLongEnough && searchEnabled && (
          <nav className="search__results mt-1">
            {!hasResults && !loading && <p>No results were found with that search</p>}
            {loading && <p>Loading results...</p>}
            {hasResults && results.map(result => <Result key={result.id} handleClick={handleClick} user={result} />)}
          </nav>
        )}
      </form>
    </React.Fragment>
  );
};

declare type ResultProps = {
  handleClick: any;
  user: User;
};

const Result = (props: ResultProps) => {
  const { handleClick, user } = props;
  const image = user?.photoURL;
  const name = user?.name;

  const initials = name?.charAt(0)?.toUpperCase();
  const hasImage: boolean = Boolean(image);

  return (
    <button onClick={e => handleClick(e, user)}>
      <div className="result__image__wrapper">
        {!hasImage && (
          <div className="result__initials">
            <span>{initials}</span>
          </div>
        )}
        {hasImage && (
          <div className="result__image">
            <img src="https://dummyimage.com/64x64.png" alt="" />
          </div>
        )}
      </div>
      {name}
    </button>
  );
};

export default AssignedTo;

import moment from 'moment';
import { ROUTES } from 'corigan';

export const generateRows = (data: SERPMovementReports[]) => {
  return data.map(keyword => {
    // define data variables
    const phrase = [{
      href: `${ROUTES.research}?id=${keyword?.id}`,
      value: keyword?.phrase,
      variant: `green`,
      tooltip: `Research - ${keyword?.phrase}`,
      tooltipAlign: `center`,
      tooltipSide: `right`,
    }];

    const createdAt = keyword?.createdAt;
    const volume = keyword?.volume;
    const days = keyword?.days;

    // define core item
    const item = {
      phrase,
      volume,
    };

    if (!days.length) {
      item[`baseDate`] = `-`;
      item[`compareDate`] = `-`;
      item[`average`] = {
        movement: true,
        value: null,
        movementValue: 0,
        average: true,
      };
    }

    // add date and poitions to row item and calc averages
    const date1BeforeCreation = moment(days[0].reportDate).isBefore(createdAt);
    const date1PositionNull = days[0].position === null;
    const date1NullBeforeCreation = date1PositionNull && date1BeforeCreation;
    const date1Position = date1PositionNull ? date1BeforeCreation ? `-` : `100+` : days[0].position
    item[`baseDate`] = date1Position.toString();

    const date2BeforeCreation = moment(days[1].reportDate).isBefore(createdAt);
    const date2PositionNull = days[1].position === null;
    const date2NullBeforeCreation = date2PositionNull && date2BeforeCreation;
    const date2Position = date2PositionNull ? date2BeforeCreation ? `-` : `100+` : days[1].position
    const date2Movement = date2PositionNull ? 0 : days[1].movement
    item[`compareDate`] = date2Position.toString();

    const total = (date1NullBeforeCreation || date2NullBeforeCreation || (date1PositionNull && date2PositionNull)) ? 0
    : (date1PositionNull && !date2PositionNull) ? 100 - days[1].position
    : (date2PositionNull && !date1PositionNull) ? days[0].position - 100
    : date2Movement

    // add averages to item
    item[`average`] = {
      movement: true,
      value: null,
      movementValue: total.toString(),
      average: true,
    };

    return item;
  });
};

import * as React from 'react';
import { useReducer } from 'react';

import CRContext from './crContext';
import crReducer from './crReducer';
import defaultState from './defaultState';

declare type CRStateProps = {
  children: React.ReactNode;
};

// Create global state provider to handle state and reducer dispatch events
export const CRState = ({ children }: CRStateProps) => {
  const [state, dispatch] = useReducer(crReducer, defaultState);

  return (
    <CRContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CRContext.Provider>
  );
};

export default CRState;

import * as React from 'react';

import { Button } from 'corigan';

import StyledBreadcrumbs from './breadcrumbs.styles';

type BreadrcrumbProps = {
  back?: boolean;
  /**
   * The primary content of the Card component
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
};

const Breadcrumbs: React.FC<BreadrcrumbProps> = (props: BreadrcrumbProps) => {
  const { back, children, className } = props;

  let classList = `breadcrumbs`;
  if (className) classList += ` ${className}`;

  const data = {
    ...props,
  };
  delete data.children;

  const onClickBack = e => {
    if (e) e.preventDefault();
    const hasHistoryAPI: boolean = typeof window?.history !== `undefined`;
    if (hasHistoryAPI) window.history.go(-1);
  };

  return (
    <StyledBreadcrumbs {...data} className={classList}>
      {back && (
        <Button className="breadcrumbs__back" onClick={onClickBack} small={true}>
          Back
        </Button>
      )}
      <div className="breadcrumbs__contents">
        <ChildrenContent>{children}</ChildrenContent>
      </div>
    </StyledBreadcrumbs>
  );
};

const isButton = child => {
  const className = child?.props?.className;
  const displayName = child?.type?.displayName;
  const href = child?.props?.href;
  const variant = child?.props?.variant;
  const hfefWithVariant = href && variant;
  const isHTMLButton = displayName === `button`;
  const isReactButton = displayName === `Button`;
  const isWrapButton = className === `breadcrumb__button`;
  const isAButton = hfefWithVariant || isHTMLButton || isReactButton || isWrapButton;

  return isAButton;
};

const isHeading = child => {
  const type = child?.type;
  const className = child?.props?.className;
  const isH1 = type === `h1`;
  const isWrapHeading = className === `breadcrumb__heading`;
  return isH1 || isWrapHeading;
};

const isLink = child => {
  const href = child?.props?.href;
  const variant = child?.props?.variant;
  const hfefNoVariant = href && !variant;
  const type = child?.type;
  const displayName = child?.type?.displayName;
  const isHTMLLink = type === `a`;
  const isReactLink = displayName === `AnchorLink`;
  const isALink = hfefNoVariant || isHTMLLink || isReactLink;
  return isALink;
};

const isParagraph = child => {
  const isHTMLParagraph = child?.type === `p`;
  return isHTMLParagraph;
};

const filterChildren = (children, method) => {
  const childrenArray = [];

  children.forEach(child => {
    if (child?.type === React.Fragment) {
      child.props.children.forEach(cChild => {
        if (method(cChild)) childrenArray.push(cChild);
      })
    }
    if (Array.isArray(child)) {
      child.forEach(cChild => {
        if (method(cChild)) childrenArray.push(cChild);
      })
    }
    if (method(child)) childrenArray.push(child);
  });

  return childrenArray;
}

declare type ChildrenContentProps = {
  children: any[];
};

const ChildrenContent = ({ children }: ChildrenContentProps) => {
  const isSeveral = children?.length;
  const buttons = isSeveral && filterChildren(children, isButton);
  const headings = isSeveral && filterChildren(children, isHeading);
  const links = isSeveral && filterChildren(children, isLink);
  const paragraphs = isSeveral && filterChildren(children, isParagraph);

  const hasButtons = buttons?.length > 0;
  const hasHeadings = headings?.length > 0;
  const hasLinks = links?.length > 0;
  const hasParagraphs = paragraphs?.length > 0;

  return (
    <React.Fragment>
      {!isSeveral && <div className="breadcrumbs__column">{children}</div>}
      {(hasLinks || hasHeadings) && (
        <div className="breadcrumbs__column">
          {hasLinks && <nav className="breadcrumbs__links">{links}</nav>}
          {hasHeadings && <div className="breadcrumbs__headings">{headings}</div>}
          {hasParagraphs && <div className="breadcrumbs__paragraphs">{paragraphs}</div>}
        </div>
      )}
      {hasButtons && (
        <div className="breadcrumbs__column">
          <div className="breadcrumbs__buttons">{buttons}</div>
        </div>
      )}
    </React.Fragment>
  );
};

Breadcrumbs.defaultProps = {
  back: true,
};

export default Breadcrumbs;

import styled from 'styled-components';
import { device } from 'particles';

const StyledCoverage = styled.section`
  header {
    display: flex;
    flex-direction: column;

    @media ${device?.lg} {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  nav {
    display: flex;

    > * + * {
      margin-left: 24px;
    }

    > select + button,
    > select + select {
      margin-left: 12px;
    }
  }

  select {
    min-width: 200px;
    width: auto;

    border-color: #dbdbdb;
  }

  .apexcharts-legend {
    padding-left: 0;
  }

  .coverage__skeleton {
    display: flex;

    svg {
      display: block;
      flex-shrink: 0;
      margin: 0 auto;
      margin-top: 48px;
      width: 100%;
      max-width: 40%;
    }
  }
`;

export default StyledCoverage;

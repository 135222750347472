import styled from 'styled-components';
import { device } from 'particles';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  animation-name: fadeIn;
  animation-duration: 0.3s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media ${device?.md} {
    flex-direction: row;
  }

  .wrapper__contents {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;

    @media ${device?.md} {
      width: calc(100% - 80px);
    }

    main {
      padding: 30px 0;

      @media ${device?.md} {
        padding: 15px 15px 45px;
      }
    }
  }

  .sidebar--open + .wrapper__contents {
    @media ${device?.md} {
      width: calc(100% - 240px);
    }
  }
`;

export default StyledWrapper;

export const getCompetitors = ({ data }): string[] => {
  const allCompetitors = [];
  const values = Object.values(data);

  for (const value of values) {
    const arrayVal: any = value;
    allCompetitors.push(arrayVal.flatMap(x => Object.keys(x)));
  }

  const hasCompetitors = allCompetitors?.length > 0;
  if (!hasCompetitors) return null;

  const competitors = [...new Set(allCompetitors.flat(1))];
  return competitors.sort();
};

export default getCompetitors;

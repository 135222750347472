import { fetchAPI } from 'particles';

declare type callGetReportDailyMovementSERPArgs = {
  domainId: ArgID;
  searchText: string;
  searchType: string;
  fromDate: string;
  toDate: string;
  page: number;
  perPage: number;
};

declare type DaysObject = {
  reportDate: string;
  position: number;
  movement: number;
};

declare type DataObject = {
  phrase: string;
  volume: number;
  days: DaysObject[];
};

declare type Response = {
  data: DataObject[];
  pagination: Pagination;
};

export const callGetReportDailySERPRankings = async (reqKey, parameters: callGetReportDailyMovementSERPArgs): Promise<Response> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/daily/serp` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportDailySERPRankings;

import styled from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const figureColour = props => {
  if (!props.statistic || !props.statistic.positive) return `var(--orange)`;
  return `var(--green)`;
};

const arrowDirection = props => {
  const isPositive = props?.statistic?.positive === true;
  if (!isPositive) return `rotate(180deg)`;
  return `rotate(0deg)`;
};

const statusColour = props => {
  if (!props.status) return `#78E7B0`;
  const lower = props.status.toLowerCase();
  switch (lower) {
    case `online`:
      return `#78E7B0`;
    case `offline`:
      return `#F02D70`;
    case `maintenance`:
      return `var(--teal)`;
    default:
      return `var(--black)`;
  }
};

const StyledCard = styled.div`
  min-height: ${props => props.minHeight && `400px`};
  padding: ${props => props.padding && `16px`};
  position: relative;

  background-color: ${() => (shouldBeDark() ? `var(--grey800)` : `var(--white)`)};
  border: ${props => props.padding && `1px solid rgba(0, 0, 0, 0.05)`};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  @media ${device?.xs} {
    padding: ${props => props.padding && `24px`};
  }

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 8px;

    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--black)`)};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    & + p,
    & + p:first-of-type {
      margin-top: 16px;
    }
  }

  &.card--focus {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card__header {
      margin-bottom: 0;

      *:only-child {
        margin: 0 auto;
      }
    }

    .card__title {
      color: var(--grey700);
    }

    .card__header,
    .card__statistic__figure {
      justify-content: center;
    }

    .card__statistic__overview {
      margin: 0 auto;
      width: 100%;
      padding-right: 0;
    }

    .card__statistic__title {
      margin: 12px auto;
      font-size: 28px;
      line-height: 34px;
      color: var(--purple);

      text-align: center;
    }
  }

  &.card--status {
    @media ${device?.xs} {
      padding: 20px 20px 26px;
    }

    .card__header {
      margin-bottom: 0;
    }

    color: var(--grey700);

    h2,
    h3 {
      margin-bottom: 0;
      margin-top: 0;

      text-transform: uppercase;
    }

    h2 {
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
    }

    h3 {
      margin-top: 4px;

      color: ${props => statusColour(props)};
      font-size: 25px;
      font-weight: 700;
      line-height: 36px;
      text-shadow: rgba(20, 18, 19, 0.1) 0px 4px 3px, rgba(20, 18, 19, 0.02) 0px 8px 13px,
        rgba(20, 18, 19, 0.02) 0px 18px 23px;
    }

    .card__status__content {
      margin-left: auto;

      span {
        display: block;

        color: var(--black);
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        text-align: center;
      }
    }

    .card__status__footnote {
      display: block;
      margin-top: 4px;

      color: var(--grey500);
      font-size: 11px;
      font-weight: 700;
      line-height: 14px;
    }

    .card__status--default {
      display: flex;
    }
  }

  .card__tooltip {
    display: block;

    &.card__tooltip {
      margin-right: 8px;
    }

    svg {
      height: 16px;

      color: var(--grey800);
    }
  }

  .card__header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    * {
      margin: 0;

      white-space: nowrap;
    }

    h2 {
      margin-right: auto;
    }

    a {
      font-size: 14px;
      text-decoration: none;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .card__loader {
    height: 2px;
    padding: 0;

    > div {
      max-width: 100%;
      width: 100%;
    }
  }

  .card__statistic {
    display: flex;
  }

  .card__statistic--focus {
  }

  .card__statistic__content,
  .card__statistic__overview {
    width: 50%;
  }

  .card__statistic__content {
    margin-left: auto;
    max-width: 200px;
  }

  .card__statistic__overview {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: 16px;
  }

  .card__statistic__figure,
  .card__statistic__title {
    margin: 0;

    font-weight: 700;
  }

  .card__statistic__figure {
    align-items: center;
    display: flex;

    color: ${props => figureColour(props)};
    font-size: 14px;
    opacity: 1;

    @media ${device?.lg} {
      font-size: 16px;
      line-height: 18px;
    }

    svg {
      display: block;
      height: 13px;
      margin-right: 5px;

      transform: ${props => arrowDirection(props)};
    }
  }

  .card__statistic__title {
    margin-bottom: 8px;

    font-size: 25px;

    @media ${device?.lg} {
      margin-bottom: 4px;

      font-size: 28px;
      line-height: 34px;
    }
  }

  & + .card {
    margin-top: 16px;

    @media ${device?.xs} {
      margin-top: 12px;
    }
  }
`;

export default StyledCard;

import { fetchAPI } from 'particles';

export const callGetReportPageTopRanking = async (
  reqKey,
  parameters: GetReportPageTopRankingParameters,
): Promise<GetReportPageTopRankingResponse> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/pages/top3Urls` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportPageTopRanking;

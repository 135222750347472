import styled from 'styled-components';

export const StyledSelect = styled.div`
  input {
    margin-top: 0;
    min-height: 42px;
  }
`;

export default StyledSelect;

/**
 * 'dbKey' property allows a column value to be passed to CrudTable.
 * The component will favour this property if it is present.
 */
export const columns = [
  {
    align: `left`,
    dbKey: `id`,
    filter: false,
    sort: true,
    hide: true,
    label: `ID`,
    numeric: false,
    value: `id`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `keywordId`,
    filter: true,
    sort: true,
    hide: true,
    label: `Keyword ID`,
    numeric: false,
    value: `keywordId`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `phrase`,
    filter: true,
    sort: true,
    hide: false,
    label: `Phrase`,
    numeric: false,
    value: `phrase`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `url`,
    filter: true,
    sort: true,
    hide: true,
    label: `URL`,
    numeric: false,
    value: `url`,
    wrap: false,
  },
  {
    align: `left`,
    chip: true,
    dbKey: `tags.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Tags`,
    numeric: false,
    value: `tags`,
  },
  {
    align: `left`,
    dbKey: `status`,
    filter: true,
    sort: true,
    hide: false,
    label: `Status`,
    numeric: false,
    value: `status`,
    wrap: false,
  },
  {
    align: `center`,
    dbKey: `crawlResponse.responseCode`,
    filter: true,
    sort: false,
    hide: false,
    label: `HTTP Status`,
    numeric: true,
    value: `httpStatus`,
  },
  {
    align: `left`,
    dbKey: `currentSerpPosition`,
    filter: true,
    sort: true,
    hide: false,
    label: `SERP Position`,
    numeric: true,
    value: `currentSerpPosition`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `searchVolume.volume`,
    filter: true,
    sort: true,
    hide: false,
    label: `Search Volume`,
    numeric: true,
    value: `searchVolume`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `highPriority`,
    filter: true,
    sort: true,
    hide: false,
    label: `Promoted`,
    numeric: false,
    value: `highPriority`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `keywordBriefedUseCount`,
    filter: true,
    sort: true,
    hide: false,
    label: `Briefed Use Count`,
    numeric: true,
    value: `keywordBriefedUseCount`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `keywordLiveUseCount`,
    filter: true,
    sort: true,
    hide: false,
    label: `Live Use Count`,
    numeric: true,
    value: `keywordLiveUseCount`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `highestRankingUrl`,
    filter: true,
    sort: true,
    hide: true,
    label: `Highest Rank URL`,
    numeric: false,
    value: `highestRanking`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `highestRankingMatchStatus`,
    filter: true,
    sort: true,
    hide: false,
    label: `Highest Rank Match`,
    tooltip: `Does the url for this keyword match the highest ranked url in the crawl data`,
    numeric: false,
    value: `mismatchedItem`,
    wrap: false,
  },
  {
    align: `right`,
    dbKey: `updatedAt`,
    filter: true,
    sort: true,
    hide: false,
    label: `Updated`,
    numeric: true,
    date: true,
    value: `dateFormatted`,
    wrap: false,
  },
  {
    align: `left`,
    dbKey: `updatedBy.name`,
    filter: true,
    sort: false,
    hide: false,
    label: `Updated By`,
    numeric: false,
    value: `updatedBy`,
    wrap: false,
  },
];

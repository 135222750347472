import * as React from 'react';
import { useContext } from 'react';
import Link from 'gatsby-link';
import { navigate } from 'gatsby-link';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';
import { isInternal } from 'corigan';
import { Shorten } from 'corigan';

type AnchorLinkProps = {
  children: React.ReactNode;
  className?: string;
  confirm?: boolean;
  confirmText?: string;
  disabled?: boolean;
  href?: string;
  onClick?: Function;
  rel?: string;
  target?: string;
  title?: string;
  to?: string;
  download?: boolean;
  shorten?: boolean;
  levels?: number;
  style?: ObjectLiteral;
};

const AnchorLink: React.FC<AnchorLinkProps> = (props: AnchorLinkProps) => {
  const { children, className, confirm, confirmText } = props;
  const { href, target, title, to, download, shorten, levels, style } = props;
  let { onClick } = props;

  let linkTo = href ? href : to ? to : `/`;

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const omContext = useContext(OMContext);
  const startedEdit: boolean = Boolean(omContext?.state?.startedEdit);

  // Determines if we should confirm the user wants to leave the page with unsaved changes
  const shouldConfirm: boolean = startedEdit;
  let linkContent = children;

  if (shorten) {
    linkContent = <Shorten levels={levels}>{children}</Shorten>;
  }

  // If the URL isn't internal, return an anchor tag
  if (!isInternal(linkTo)) {
    return (
      <a
        className={className}
        href={linkTo}
        rel={target === `_blank` ? `noopener norefferer` : null}
        target={target}
        title={title}
        download={download}
        style={style}
      >
        {linkContent}
      </a>
    );
  }

  linkTo = linkTo.startsWith(`/`) ? linkTo : `/` + linkTo;

  // Otherwise, use the React Router Link component

  // Create onClick event if requires confirmation
  const handleConfirm = async () => navigate(linkTo);
  if ((confirm || shouldConfirm) && confirmText) {
    onClick = e => {
      if (!e) return;

      if (e) e.preventDefault();

      // This is a specific fix for the application draw as it technically take up no space
      // The boxes are created with a padding hack, which doesn't count as the currentTarget in the event
      const isApplicationIcon = e?.target?.closest(`.application`);

      const isLinkClicked: boolean = e.target === e.currentTarget;

      const shouldContinue = isApplicationIcon || isLinkClicked;
      if (!shouldContinue) return;

      const value = { handleConfirm, isOpen: true, message: confirmText };
      dispatch({ key: `modal`, type: `set`, value });
    };
  }

  const handleClick = e => {
    if (onClick) onClick(e);
  };

  return (
    <React.Fragment>
      <Link className={className} onClick={e => handleClick(e)} style={style} title={title} to={linkTo}>
        {linkContent}
      </Link>
    </React.Fragment>
  );
};

AnchorLink.defaultProps = {
  confirmText: `Are you sure you want to leave this page? Any unsaved changes will be lost.`,
  disabled: false,
};

export default AnchorLink;

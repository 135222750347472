import { fetchAPI } from 'particles';

type GetSistrixMarketChangesArguments = {
  date: string;
};

export const callGetSistrixMarketChanges = async (reqKey, parameters: GetSistrixMarketChangesArguments) => {
  const response = await fetchAPI({
    method: `GET`,
    parameters,
    route: `sistrix/market/marketChanges`,
  });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetSistrixMarketChanges;

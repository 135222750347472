import * as React from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { toast } from 'react-toastify';
import moment from 'moment';

// Particles
import { parseHTML } from 'corigan';

// Components
import { Clear } from 'icons';

// API
import { callUpdateUser } from 'corigan';

declare type SingleNotificationProps = {
  user: User;
} & CoriganNotification;

const SingleNotification = (props: SingleNotificationProps) => {
  const { _id, content, createdAt, status, user } = props;

  const queryCache = useQueryCache();

  const [mutate, { isLoading: loading }] = useMutation(callUpdateUser, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to dismiss notification`, {});

      queryCache.invalidateQueries([`notificationsCallMe`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const notifications: CoriganNotification[] = variables?.notifications;

      // Optimistically update to the new value
      queryCache.setQueryData([`notificationsCallMe`], (currentUser: any) => {
        const userObject: User = currentUser?.data;
        const newUserObject = { data: { ...userObject, notifications } };
        return newUserObject;
      });
    },
  });

  const handleClick = async e => {
    e.preventDefault();

    const hasNotifications = user?.notifications?.length > 0;
    if (!hasNotifications) return;
    const current = [...user.notifications];

    const newNotifications = current.filter(n => n._id !== _id);

    const notifications: any[] = newNotifications.map(n => {
      const wholeNotification = { ...n, notification: n._id, status: `Read` };
      delete wholeNotification._id;
      return wholeNotification;
    });

    await mutate({ id: user.id, notifications });
  };

  if (!content) return null;

  return (
    <div className="notification" data-viewed={status}>
      <div className="notification__body">
        <p className="notification__text">{parseHTML(content)}</p>
        {createdAt && <footer className="notification__footer">{moment(createdAt).fromNow()}</footer>}
      </div>
      <button className="notification__dismiss" disabled={loading} onClick={handleClick}>
        <span className="hidden">Dismiss Notification</span>
        <Clear />
      </button>
    </div>
  );
};

export default SingleNotification;

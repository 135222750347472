import { fetchAPI } from 'particles';

type PageRevisionModel = {
  importExpanded: boolean,
  domain: string,
  pageRevisions: {
    currentUrl: ArgURL;
    newUrl: ArgURL;
    name: ArgName;
    tags?: ArgTags;
    }[];
};

export const callCreateManyPageRevisions = async (
  args: PageRevisionModel
): Promise<{ data: PageRevision[] }> => {
  const { domain,importExpanded, ...parameters } = args;
  const response = await fetchAPI({ method: `PUT`, parameters, route: `pagerevisions?domain=${domain}&importExpanded=${importExpanded}` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreateManyPageRevisions;

import rgbaToHex from './rgbaToHex';

export const isLight = colour => {
  if (!colour) return null;

  if (colour.startsWith(`rgba`)) {
    colour = rgbaToHex(colour);
  }

  if (!colour.startsWith(`#`)) return null;

  if (colour.length < 6) {
    const smallHex = colour.split(`#`)[1];
    const hexValues = Array.from(smallHex);
    const newHexValues = hexValues.map(v => `${v}${v}`);
    const largeHex = newHexValues.join(``);
    colour = `#${largeHex}`;
  }

  const c = colour.charAt(0) === `#` ? colour.substring(1, 7) : colour;
  const r = parseInt(c.substring(0, 2), 16); // hexToR
  const g = parseInt(c.substring(2, 4), 16); // hexToG
  const b = parseInt(c.substring(4, 6), 16); // hexToB
  const lightOrNot = r * 0.299 + g * 0.587 + b * 0.114 > 186;
  return lightOrNot;
};

export default isLight;

import * as React from 'react';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Mastersheet specific imports
import { OMMastersheetTable } from 'corigan';

// API
import { callGetManyKeywordChanges } from 'corigan';

// Local Partials
import { columns } from '../data/keywordChanges/columns';
import { generateRows } from '../data/keywordChanges/generateRows';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`pages:read`]}>
    <OptimisationManagerStatusIssues />
  </ProtectedRoute>
);

const initialWhere: ArgWhere = `[positionChange][lt]=0`;
const initialWith: ArgWith = [
  `keyword`,
  `pagerevision`,
  `pagerevision.assignedTo`,
  `pagerevision.categories`,
  `pagerevision.categories.category`,
  `pagerevision.keywords`,
  `pagerevision.page`,
  `pagerevision.pageType`,
  `pagerevision.tags`,
  `pagerevision.updatedBy`,
];

type OMStatusIssuesProps = {};

const OptimisationManagerStatusIssues = (props: OMStatusIssuesProps) => {
  return (
    <Page application="optimisation-manager" pageTitle="Worst Ranking">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.optimisation_manager}>Dashboard</Link>
              <h1>Worst Ranking</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <OMMastersheetTable
                id="rank-worst"
                whereProtectedType="[pagerevision.domain][eq]="
                initialWhere={initialWhere}
                initialWith={initialWith}
                initialOrderBy="positionChange"
                remember={false}
                queryFunction={callGetManyKeywordChanges}
                queryName="keywordChanges"
                columns={columns}
                generateRows={generateRows}
              />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

export default Wrapper;

import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { toast } from 'react-toastify';

// Particles
import { ApplicationContext } from 'particles';
import { dateCreateUpdate } from 'corigan';
import { ROUTES } from 'corigan';
import { updateSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Button } from 'corigan';
import { Link } from 'corigan';
import { Card } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';
import { callUpdatePageRevision } from 'corigan';

// Local partials
import TableError from '../error';

import { statuses } from 'particles';

type TableRequestProps = {};

const _with: ArgWith = [`createdBy`, `currentRevision`, `page`, `updatedBy`];
const perPage: ArgPerPage = 5;

const Revisions = (props: TableRequestProps) => {
  const linkRevisions = { href: ROUTES.revisions, title: `View all` };
  let content;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const where: ArgWhere = `[domain][eq]=${domainActive?.id}&where[status][any]=${statuses.awaitingApproval}`;

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyPageRevisions`, { perPage, where, _with }],
    callGetManyPageRevisions,
  );
  const data = res?.data;

  if (!canRead) return <TableError error="You do not have permission to view page revisions" />;
  if (error) content = <TableError error={error} />;

  const hasData = data?.length > 0;
  if (hasData) content = data.map((pageRevision: PageRevision) => <Revision key={pageRevision.id} {...pageRevision} />);

  return (
    <Card link={linkRevisions} loading={loading} title="Revisions">
      <table className="table table--small m-0" id="table-revisions" style={{tableLayout: `fixed`}}>
        <thead>
          <tr>
            <th>Page ID</th>
            <th>Page Name</th>
            <th>Updated On</th>
            <th>User</th>
            <th />
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
    </Card>
  );
};

declare type RevisionProps = PageRevision;

const Revision: React.FC<RevisionProps> = (props: RevisionProps) => {
  const { updatedBy, createdAt, updatedAt, createdBy } = props;
  const { id, name, page, pageId, assignedTo, previousAssignedTo } = props;
  const assignedToId: string = assignedTo?.id || assignedTo;
  const previousAssignedToId: string = previousAssignedTo?.id || previousAssignedTo;
  const queryCache = useQueryCache();

  const { userHasPermission: canApprove } = useHasPermissions({ requiredPermissions: [`pages:approve`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const where: ArgWhere = `[domain][eq]=${domainActive?.id}&where[status][any]=${statuses.awaitingApproval}`;

  const [mutate, { isLoading: loading }] = useMutation(callUpdatePageRevision, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to update page revision status`, {});

      queryCache.invalidateQueries([`callGetManyPageRevisions`, { perPage, where, _with }]);
    },
    onSettled: (data, error) => {
      setApproving(false);
      setRejecting(false);
    },
    onSuccess: (data: APIResponse, variables) => {
      queryCache.invalidateQueries([`callGetManyPageRevisions`, { perPage, where, _with }]);
    },
  });

  const key: string = `revision-${page?.id}`;
  const href: string = `${ROUTES.page}?id=${page?.id}&status=${statuses.awaitingApproval}`;

  const dateFormatted = dateCreateUpdate({ createdAt, updatedAt });

  const user: any = updatedBy ? updatedBy : createdBy;
  const username: string = (user?.name !== `System`) ? user?.name : `Not updated`;

  const approveRevision = async e => {
    if (e) e.preventDefault();

    if (windowAvailable() && !canApprove) window.alert(`You do not have permission to approve a page revision`);
    if (!canApprove) return;

    const status: ArgStatus = statuses.readyToImplement;
    await mutate({ id, status, _with });
    setApproving(true);
  };

  const rejectRevision = async e => {
    if (e) e.preventDefault();

    if (windowAvailable() && !canApprove) window.alert(`You do not have permission to reject a page revision`);
    if (!canApprove) return;

    const status: ArgStatus = statuses.rejected;
    await mutate({ id, status, assignedTo: previousAssignedToId, previousAssignedTo: assignedToId, _with });
    setRejecting(true);
  };

  const [approving, setApproving] = React.useState(false);
  const [rejecting, setRejecting] = React.useState(false);
  const approveText: 'Approve' | 'Approving' = approving ? `Approving` : `Approve`;
  const rejectText: 'Reject' | 'Rejecting' = rejecting ? `Rejecting` : `Reject`;

  return (
    <tr key={key} className="table__row table--revisions__row">
      <td className="text--nowrap">
        <Link href={href}>{pageId}</Link>
      </td>
      <td className="text--nowrap">
        <Link href={href}>{name}</Link>
      </td>
      <td>{dateFormatted}</td>
      <td>{username}</td>
      {canApprove && (
        <React.Fragment>
          <td>
            <Button disabled={loading} onClick={approveRevision} variant="hollow" small className="mr-1">
              {approveText}
            </Button>
            <Button disabled={loading} onClick={rejectRevision} variant="hollow" small>
              {rejectText}
            </Button>
          </td>
        </React.Fragment>
      )}
    </tr>
  );
};

export default Revisions;

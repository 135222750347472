import * as React from 'react';
import { default as loadable } from '@loadable/component';
import { deepMerge } from 'corigan';

import baseOptions from './options.json';

import StyledSparkline from './sparkline.styles';

type SparklineProps = {
  children?: React.ReactNode;
  height?: string | number;
  id: string;
  /**
   * Allows for base options JSON configuration to be overwritten with any
   * values defined in a JSON object passed to the 'options' prop.
   */
  options?: any;
  /**
   * Accepts an array of series values, used to plot data on an Apex Chart
   */
  series: Array<any>;
  variant?: 'line' | 'bar';
  width?: string | number;
};

export const Sparkline = (props: SparklineProps) => {
  const { id, height, options: overwrite, series: seriesData, variant, width } = props;

  const data = { ...props };
  if (data.children) delete data.children;

  let options = { ...baseOptions };
  if (overwrite) options = deepMerge(options, overwrite);
  if (id) options.chart.id = id;

  const series = [{ data: seriesData }];

  const classList = `sparkline`;

  return (
    <StyledSparkline className={classList} {...data}>
      <LoadableChart height={height} options={options} series={series} type={variant} width={width} />
    </StyledSparkline>
  );
};

Sparkline.defaultProps = {
  height: 100,
  full: false,
  variant: `line`,
  width: 200,
};

export default Sparkline;

// import react-apexcharts here
const LoadableChart = loadable(() => import(`../../../../../../node_modules/react-apexcharts/src/react-apexcharts`));

import styled from 'styled-components';

const StyledDrafts = styled.div`
  position: relative;

  .draggable__content {
    display: inline-block;
    width: 100%;
    padding-right: 58px;
  }

  .draggable__handle {
    float: right;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .draggable__handle__center {
    float: right;
    height: 50%;
    width: 100%;
    margin-bottom: -27px;
  }

  .draggable__handle__icon {
    padding: 16px;
    display: inline-block;
    font: 400 13.3333px Arial;
    color: var(--black);

    span {
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      position: absolute;
      width: 1px;

      border: 0;
    }

    svg {
      margin: 0;
      height: 20px;
      fill: currentColor;
    }
  }
`;

export default StyledDrafts;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { brandColours } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { RBContext } from 'corigan';
import { toTitleCase } from 'corigan';

// Components
import { HR } from 'corigan';
import { Toggle } from 'corigan';
import { ChartStackedArea } from 'corigan';
import { GapGroup } from 'corigan';

// Local Particles
import { DateRange } from '../../../../partials';
import { generateSeries } from '../../../generateSeries';

declare type ReportChartProps = {
  data: any;
  device: 'desktop' | 'mobile';
  initial?: string[];
  title: string;
};

const ReportChart = (props: ReportChartProps) => {
  const { data, device = `desktop`, title } = props;
  const hasData: boolean = data?.length > 0;

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domainName = domainActive?.hostname;

  const { initial = [`${domainName} ${toTitleCase(device)}`] } = props;
  const [enabledPlots, setEnabledPlots] = useState(initial);

  const context = useContext(RBContext);
  const state = context?.state;
  const { chartDateEnd, chartDateStart, devices } = state;

  // Only recalculate on date changes in state store
  const { dates } = useMemo(() => {
    return getWeeksBetween(chartDateStart, chartDateEnd);
  }, [chartDateStart, chartDateEnd]);

  // Helpers function to determine if the item includes desktop/mobile
  const hasCurrentDevice = collectionItem => {
    const name: string = collectionItem?.name;
    const match = name.includes(device);
    return match;
  };

  // Filter the data returned by device set in global state
  const byDevice = hasData && data?.filter(hasCurrentDevice);
  const hasFiltered = byDevice?.length > 0;

  const removeDeviceName = name => {
    let updatedName = name;

    // For each enabled device, remove the name
    devices.map(device => {
      const titleDevice = toTitleCase(device);
      updatedName = updatedName?.replace(` ${titleDevice}`, ``);
      return device;
    });

    return updatedName;
  };

  // Generate the series plots
  const allSeries = hasFiltered && generateSeries({ data: byDevice, chartDateEnd, chartDateStart });
  const hasSeries = allSeries?.length > 0;

  // Create an initial state of every series domain name available
  const allSeriesPlotNames = useMemo(() => {
    // If there is no series (still loading) then return an empty array
    const hasSeries = allSeries?.length > 0;
    if (!hasSeries) return [];

    // Otherwise return an array of domain names, in alphabetical order
    const allNames = allSeries.map(({ name }) => name);
    const orderedNames = allNames.sort();
    return orderedNames;
  }, [allSeries]);

  // Do we have any plot names available to use in filtering?
  const hasPlotNames: boolean = allSeriesPlotNames?.length > 0;

  // Helper function to use in filter function
  // is the series included in the useState array?
  const isPlotEnabled = useCallback(
    plot => {
      const isEnabled: boolean = enabledPlots.includes(plot.name);
      return isEnabled;
    },
    [enabledPlots],
  );

  // Create an empty series, and then update with filtered plots
  const enabledSeries: any[] = useMemo(() => {
    if (!hasSeries) return [];
    return allSeries.filter(isPlotEnabled);
  }, [allSeries, hasSeries, isPlotEnabled]);

  // On toggle change...
  const onToggleChange = e => {
    // Get the ID of the toggle (domain name)
    const plotToChange = e.target.id;

    // Create an empty array which will hold the new enabled series plots
    let newEnabled = [];

    // Determine if there are any enabled plots yet
    const currentEnabled = [...enabledPlots];
    const hasCurrent = currentEnabled?.length > 0;

    // If none are enabled, the new plot state should be the single new clicked toggle ID
    if (!hasCurrent) {
      newEnabled = [plotToChange];
      setEnabledPlots(newEnabled);
      return;
    }

    // Otherwise, determine if the clicked toggle is being added or not
    let shouldAdd = true;
    if (currentEnabled.includes(plotToChange)) shouldAdd = false;

    // If it is being added, spread the old array, and append the new value
    if (shouldAdd) {
      newEnabled = [...currentEnabled, plotToChange];
      setEnabledPlots(newEnabled);
      return;
    }

    // Prevent there being no series enabled
    const onlyOneEnabled = currentEnabled?.length === 1;
    if (onlyOneEnabled) return;

    // Otherwise, remove the clicked toggle ID from the current values
    newEnabled = currentEnabled.filter(plot => {
      const isMatch = plot === plotToChange;
      return !isMatch;
    });
    setEnabledPlots(newEnabled);
  };

  // Get an array of the brand colours, with their names
  const brandingColours = brandColours();

  // Create a cached array of colours
  const colors: string[] = useMemo(() => {
    // Loop over each enabled series
    const value = enabledSeries.map(plot => {
      // Get the name from the plot (e.g. amazon.com)
      const { name } = plot;

      // Look up the brand colours array, and see if there's a match
      const definedColour = brandingColours.find(brandObject => {
        const { brand } = brandObject;

        // Lowercase strings for comparison
        const lowerBrand: string = brand?.toLowerCase();
        const lowerName: string = name?.toLowerCase();

        // Was there a match? Searching enabled plot for substring of brand name
        // E.g. does www.amazon.co.uk include amazon?
        const found: boolean = lowerName.includes(lowerBrand);
        return found;
      });

      // If found, return the defined colour
      if (definedColour?.colour) return definedColour.colour;

      // Otherwise return a shade of grey
      return `#353535`;
    });

    return value;
  }, [brandingColours, enabledSeries]);

  const options: ChartOptions = {
    colors,
    legend: {
      show: false,
    },
    tooltip: {
      labels: {
        format: `dd MMM yyyy`,
      },
    },
    xaxis: {
      categories: dates,
      labels: {
        format: `MMM yyyy`,
      },
      type: `datetime`,
    },
    yaxis: {
      reversed: false,
    },
  };

  return (
    <React.Fragment>
      <HR />
      {hasSeries && (
        <React.Fragment>
          <DateRange title={title} />
          <GapGroup className="mb-2">
            {hasPlotNames &&
              allSeriesPlotNames.map(name => {
                const titleName = toTitleCase(name);
                const on = enabledPlots.includes(titleName);
                const label = removeDeviceName(titleName);

                // Look up the brand colours array, and see if there's a match
                const definedColour = brandingColours.find(brandObject => {
                  const { brand } = brandObject;

                  // Lowercase strings for comparison
                  const lowerBrand: string = brand?.toLowerCase();
                  const lowerName: string = name?.toLowerCase();

                  // Was there a match? Searching enabled plot for substring of brand name
                  // E.g. does www.amazon.co.uk include amazon?
                  const found: boolean = lowerName.includes(lowerBrand);
                  return found;
                });

                // If found, return the defined colour
                const colour = definedColour?.colour;

                return (
                  <Toggle
                    id={titleName}
                    key={titleName}
                    colour={colour}
                    small={true}
                    label={label}
                    on={on}
                    onChange={onToggleChange}
                  >
                    Show {device} {name} on the chart
                  </Toggle>
                );
              })}
          </GapGroup>
          <ChartStackedArea id="visibility-scores" options={options} series={enabledSeries} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { ReportChart };
export default ReportChart;

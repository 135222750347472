import * as React from 'react';

function SvgMail(props) {
  return (
    <svg viewBox="0 0 16 14" aria-hidden="true" {...props}>
      <path
        d="M15 0H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V1a1 1 0 00-1-1zm-1 12H2V5.723l5.5 3.145a1 1 0 00.992 0L14 5.723zm0-8.58L8 6.849 2 3.42V2h12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMail;

import * as React from 'react';
import { useContext } from 'react';

import { ChevronLeft } from 'icons';
import { ChevronRight } from 'icons';

import { Button } from 'corigan';
import { TableContext } from 'corigan';

import { perPageOptions } from './perPageOptions';

declare type TablePaginationProps = {
  apiArgs: APIArgs;
  apiFunctions: APIFunctions;
  pagination: Pagination;
};

const TablePagination = (props: TablePaginationProps) => {
  const { apiArgs, apiFunctions } = props;
  const { pagination } = props;

  const { perPage } = apiArgs;
  const { setPage, setPerPage } = apiFunctions;
  const { currentPage, from, lastPage, nextPage, prevPage, to, total } = pagination;

  const context = useContext(TableContext);
  const dispatch = context?.dispatch;

  const nextDisabled: boolean = currentPage === lastPage;
  const previousDisabled: boolean = currentPage <= 1;

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value: string = e.target.value;
    if (!value) return;

    setPerPage(Number(value));
    setPage(1);
  };

  const handlePrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    if (dispatch) dispatch({ type: `itemsAllSelectClear`, value: true });
    setPage(prevPage);
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();
    if (dispatch) dispatch({ type: `itemsAllSelectClear`, value: true });
    setPage(nextPage);
  };

  return (
    <nav className="table__pagination">
      <label htmlFor="chunksSize">Rows per page</label>
      <select onChange={handlePerPageChange} id="perPage" name="perPage" value={perPage}>
        {perPageOptions.map(({ label, value }) => (
          <option key={`chunk-size-${value}`} value={value}>
            {label}
          </option>
        ))}
      </select>
      <p>
        Currently showing {String(from)} - {String(to)} of <span className="table__total__count">{String(total)}</span>
      </p>
      <Button disabled={previousDisabled} onClick={handlePrevious}>
        <ChevronLeft />
        <span className="hidden">Previous</span>
      </Button>
      <Button disabled={nextDisabled} onClick={handleNext}>
        <span className="hidden">Next</span>
        <ChevronRight />
      </Button>
    </nav>
  );
};

export default TablePagination;

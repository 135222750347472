/**
 * Uppercases first character when typeof string
 * @param {string} text
 */
export const capitalizeString = text => {
  if (!text) return ``;
  const isString = typeof text === `string`;
  if (!isString) return ``;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export default capitalizeString;

import { fetchAPI } from 'particles';

type GetReportKeywordShareArgs = {
  parent?: ArgName;
  top: number;
  domainId: string;
};

declare type NamedValue = {
  [key: string]: number;
};

declare type Data = {
  [key: string]: NamedValue[];
};

declare type Response = {
  data: Data;
};

export const callGetReportKeywordCoverage = async (
  reqKey,
  parameters: GetReportKeywordShareArgs,
): Promise<Response> => {
  const response = await fetchAPI({
    method: `GET`,
    parameters,
    route: `reports/keyword/coverage`,
  });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportKeywordCoverage;

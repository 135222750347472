import * as React from 'react';
import { useContext } from 'react';

// Particles
import { OMContext } from 'corigan';
import { datePretty } from 'corigan';
import { isString } from 'corigan';

function countWords(str: string): number {
  if (!str) return 0;
  if (!isString(str)) return 0;

  str = str.replace(/(^\s*)|(\s*$)/gi, ``);
  str = str.replace(/[ ]{2,}/gi, ` `);
  str = str.replace(/\n /, `\n`);
  return str.split(` `).length;
}

const PageSummary = () => {
  const context = useContext(OMContext);
  const state = context?.state;

  const editedRevision: PageRevision = state.editedRevision;
  if (!editedRevision) return null;

  const { createdBy } = state;
  const user: User = createdBy;
  const username = user?.name;

  const { keywordCount } = editedRevision;
  const hasKeywords: boolean = keywordCount > 0;

  const crawlResponse: Page['crawlResponse'] = state?.crawlResponse;
  const hasResponseCode: boolean = Boolean(crawlResponse?.responseCode);
  const hasCrawled: boolean = Boolean(crawlResponse?.lastCrawled);
  const hasImplementedAt: boolean = Boolean(editedRevision?.implementedAt);

  const blocks = editedRevision?.contentBlocks;
  const hasBlocks: boolean = blocks?.length > 0;

  let wordCount: number = 0;
  if (hasBlocks) wordCount = blocks.reduce((a, b) => a + ((b[`content`] && countWords(b[`content`])) || 0), 0);
  const hasWordCount: boolean = wordCount > 0;

  return (
    <section className="page__summary">
      <ul className="list--unstyled">
        {hasWordCount && <li>Word Count: {wordCount}</li>}
        {hasKeywords && <li>Keyword Count: {keywordCount}</li>}
        {username && <li>Created By: {username}</li>}
        {hasResponseCode && <li>HTTP Status: {crawlResponse.responseCode}</li>}
        {hasCrawled && <li>Last Crawled: {datePretty(crawlResponse.lastCrawled)}</li>}
        {hasImplementedAt && <li>Implemented At: {datePretty(editedRevision?.implementedAt)}</li>}
      </ul>
    </section>
  );
};

export default PageSummary;

import * as React from 'react';

import StyledStats from './stats.styles';

declare type KeywordStatsProps = {
  keyword: Keyword;
};

const KeywordStats = ({ keyword }: KeywordStatsProps) => {
  const csp = keyword?.currentSerpPosition;
  const wasZero = csp === 0;
  const was9999 = csp === 9999;

  let position = null;
  if (csp) position = String(csp);
  if (!csp || wasZero || was9999) position = `100+`;

  let keywordVolume: any = keyword?.searchVolume?.volume;

  if (keywordVolume) keywordVolume = keywordVolume.toLocaleString();
  if (!keywordVolume) keywordVolume = `-`;

  // If no position set, skip keyword rank
  const content = position || keywordVolume;
  if (!content) return null;

  const status = keyword?.status;

  return (
    <StyledStats>
      {position && (
        <div className="keyword__stat">
          <h2>Current Serp Position</h2>
          <h3>{position}</h3>
        </div>
      )}
      {keywordVolume && (
        <div className="keyword__stat">
          <h2>Search Volume</h2>
          <h3>{keywordVolume}</h3>
        </div>
      )}
      <div className="keyword__stat">
        <h2>Status</h2>
        <h3>{status}</h3>
      </div>
    </StyledStats>
  );
};

export default KeywordStats;

import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { toast } from 'react-toastify';

// Particles
import ROUTES from 'routes';
import { ApplicationContext } from 'corigan';
import { removeSingleCache } from 'corigan';
import { windowAvailable } from 'corigan';
import { useHasPermissions } from 'corigan';

// Atoms
import { Button, Link } from 'corigan';

// Molecules
import { Breadcrumbs, Card } from 'corigan';
import { Error } from 'corigan';

// Organisms
import { Grid, Row, Col } from 'corigan';

// API
import { callDeleteTeam } from 'corigan';

// Local Components
import TeamMembers from './members';

type TeamContentProps = {
  children?: React.ReactNode;
  data?: any;
  title: string;
};

const TeamContent: React.FC<TeamContentProps> = (props: TeamContentProps) => {
  const { children, data, title } = props;
  const id = data?.id;
  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;

  const { userHasPermission: canDelete } = useHasPermissions({ requiredPermissions: [`teams:delete`] });

  useEffect(() => {
    document.title = `Corigan - Portal: Team - ${title}`;
  }, [title]);

  const [mutate, { error, isLoading: loading }] = useMutation(callDeleteTeam, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(err, {});

      queryCache.invalidateQueries([`callGetManyTeams`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const id: ArgID = variables?.id;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTeams`], (previousTeams: APIResponse) =>
        removeSingleCache(previousTeams, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyTeams`, { perPage: 10, _with: [`domains`] }], (previousTeams: APIResponse) =>
        removeSingleCache(previousTeams, id),
      );

      // Redirect to directory of teams
      navigate(ROUTES.teams);
    },
  });

  const deleteTeam = async () => {
    // If no ID is available, function can't run
    if (!id) return;
    if (!windowAvailable()) return;

    // If the user doesn't have permission to delete a team, then pevent
    if (!canDelete) {
      window.alert(`You don't have permission to delete teams`);
      return;
    }

    try {
      // Use delete team hook
      await mutate({ id });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => await deleteTeam();
  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Deleting a page is permanent, are you sure you want to delete the selected page?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  };

  let buttonText = `Delete Team`;
  if (loading) buttonText = `Deleting Team`;

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.dashboard}>Dashboard</Link>
            <Link href={ROUTES.teams}>Teams</Link>
            <h1>{title}</h1>
            {canDelete && (
              <Button className="breadcrumb__button" disabled={loading} onClick={handleClick} variant="hollow">
                {buttonText}
              </Button>
            )}
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card loading={loading}>
            {error && <Error error={error} />}
            {children}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          {data && (
            <Card loading={loading}>
              <h2>{title} Team Members</h2>
              <TeamMembers data={data} />
            </Card>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default TeamContent;

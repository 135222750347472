import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';
import { Col, Row } from 'corigan';

// Localised partials
import Content from './content';

type RequestErrorProps = {
  error: any;
  id: string;
};

const RequestError = (props: RequestErrorProps) => {
  const { error, id } = props;
  console.error(error);

  const message = `Unfortunately we could not lookup the crawl with the ID: ${id}`;

  return (
    <Content data={null} title={`Sorry, we couldn't find that crawl`}>
      <Row>
        <Col>
          <Error error={message} />
          <p>
            Please return to the <Link href={ROUTES.crawls}>all crawls</Link> page.
          </p>
        </Col>
      </Row>
    </Content>
  );
};

export default RequestError;

import { fetchAPI } from 'particles';

type ManyUsersParameters = {
  orderBy?: ArgOrderBy;
  page?: ArgPage;
  perPage?: ArgPerPage;
  where?: ArgWhere;
  whereProtected?: ArgWhere;
  _with?: ArgWith;
};

export const callGetManyUsers = async (
  queryKey,
  parameters: ManyUsersParameters,
): Promise<{ data: User[]; pagination: Pagination }> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `users` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetManyUsers;

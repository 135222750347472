type createCombinedWordListArgs = {
  keywords: Keyword[];
  linkedWords: LinkedWord[];
};

export const createCombinedWordList = ({keywords, linkedWords}: createCombinedWordListArgs): Array<Keyword | LinkedWord> => {
  const combinedKeywordsLinkedWords = [];

  keywords.forEach(keyword => {
    const typedKeyword = {
      ...keyword,
      keywordType: `keyword`,
    }

    combinedKeywordsLinkedWords.push(typedKeyword);
  })

  linkedWords.forEach(linkedWord => {
    const typedLinkedWord = {
      ...linkedWord,
      keywordType: `linkedWord`,
    }

    combinedKeywordsLinkedWords.push(typedLinkedWord);
  })

  return combinedKeywordsLinkedWords;
}

export default createCombinedWordList;

import * as React from 'react';
import { useContext } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// API
import { callGetPageRevision } from 'corigan';

// Localised partials
import { revisionWith } from '../../_with';

import { statuses } from 'particles';

const _with: ArgWith = revisionWith;

type LoadRevisionProps = {
  id: string;
  status: ArgStatus;
};

const LoadRevision = (props: LoadRevisionProps) => {
  const { id, status } = props;

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const appDispatch: any = appContext?.dispatch;

  const omContext: OMContextProps = useContext(OMContext);
  const omDispatch: any = omContext?.dispatch;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const handleRevisionSwitch = async () => {
    // If no ID is available, function can't run
    if (!id) {
      console.error(`No ID provided to handleRevisionSwitch function`);
      return;
    }

    // Confirm the user wants to override the current state of the page
    if (!windowAvailable()) {
      console.error(`No windowAvailable to handleRevisionSwitch function`);
      return;
    }

    if (!canRead) {
      window.alert(`You do not have permission to read page revisions`);
      return;
    }

    try {
      const response = await callGetPageRevision(undefined, { id, _with });
      const value = response?.data;
      const pageId = typeof value?.page === `string` ? value?.page : value?.page?.id;

      omDispatch({ type: `set`, key: `currentStatus`, value: value.status });

      const url = `${window.location.pathname}?id=${pageId}&status=${value.status}`;
      window.history.replaceState(window.history.state, ``, url);

      omDispatch({ type: `load`, value });

      //  Switch the tab focus
      const tabID = `om-page-content`;
      const state = appContext?.state;
      const tabs = state?.tabs;
      const hasTabs = tabs.length > 0;
      const otherTabs = !hasTabs ? [] : tabs.filter(tab => tab.id !== tabID);
      const hasOtherTabs = otherTabs.length > 0;

      if (!hasOtherTabs) {
        appDispatch({ type: `set`, key: `tabs`, value: [{ id: tabID, focus: 1 }] });
        return;
      }

      const newTabsState = [...otherTabs, { id: tabID, focus: 1 }];
      appDispatch({ type: `set`, key: `tabs`, value: newTabsState });
      omDispatch({ type: `set`, key: `error`, value: null });
    } catch (error) {
      console.error(error);
      omDispatch({ type: `set`, key: `error`, value: error });
    }
  };

  const handleConfirm = async () => await handleRevisionSwitch();

  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Loading a revision will remove the current state of the page. Are you sure you want to remove the edited
          content, and load a revision?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    appDispatch({ key: `modal`, type: `set`, value });
  };

  let buttonText = `Load`;

  switch (status?.toLowerCase()) {
    case statuses.awaitingApproval.toLowerCase():
      buttonText = `Review`;
      break;
    case statuses.implemented.toLowerCase():
      buttonText = `Import`;
      break;
    default:
      buttonText = `Load`;
      break;
  }

  return <button onClick={handleClick}>{buttonText}</button>;
};

export default LoadRevision;

import * as React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { CRContext } from 'corigan';
import { dateFormal } from 'corigan';
import { ROUTES } from 'corigan';

// Components
import { Error } from 'corigan';
import { Card } from 'corigan';
import { DomainSelector } from 'corigan';

// Atoms
import { Link } from 'atoms';

// API
import { callGetManyCrawls } from 'corigan';

declare type GenerateRowsArgs = {
  data: Crawl[];
};

declare type GenerateRowsOutput = {
  id: string;
  crawlDate: string;
  hostname: string;
  status: string;
  totalUrls: string;
  urlsChanged: string;
}[];

const generateRows = (args: GenerateRowsArgs): GenerateRowsOutput => {
  const { data } = args;
  if (!data) return [];

  const formattedData = data.map(crawl => {
    const id = crawl.id;
    const crawlDate = dateFormal(crawl.crawlDate);
    const hostname = String(crawl.domain.hostname);
    const totalUrls = crawl?.totalUrls?.toLocaleString();
    const status = crawl.status;

    let urlsChanged: string = `N/A`;
    if (Boolean(crawl?.urlsChanged)) urlsChanged = crawl.urlsChanged.toLocaleString();

    const item = {
      id,
      crawlDate,
      hostname,
      status,
      totalUrls,
      urlsChanged,
    };

    return item;
  });

  return formattedData;
};

declare type CompetitorStatusesProps = {};

const LatestCrawls = (props: CompetitorStatusesProps) => {
  const applicationContext: ApplicationContextProps = React.useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const competitorContext = React.useContext(CRContext);
  const state = competitorContext?.state;
  const competitor = state?.competitor?.id;
  const hasCompetitor = Boolean(competitor);

  const where: ArgWhere = hasCompetitor ? `[domain][eq]=${competitor}` : undefined;
  const orderBy: ArgOrderBy = `-crawlDate`;
  const page: ArgPage = undefined;
  const perPage: ArgPerPage = 5;
  const _with: ArgWith = [`domain`];

  const queryFunction = callGetManyCrawls;
  const queryName = `callGetManyCrawls`;

  const apiArgs = { where, orderBy, page, perPage, _with };

  const { data: res, error, isLoading: loading } = useQuery([queryName, { ...apiArgs }], queryFunction);
  const data = res?.data;

  // Very expensive function, should be saved for when the API return new information
  const items = useMemo(() => {
    return generateRows({ data });
  }, [data]);

  const hasData: boolean = items?.length > 0;

  return (
    <Card loading={loading}>
      <div className="display--flex align-items--center justify-content--between mb-2">
        <h2 className="mb-0">Latest Crawls</h2>
        <DomainSelector
          allowAll={true}
          competitorsOnly={false}
          id="domain-selector"
          initial={domainActive?.id}
          horizontal={true}
          label="Filter by Site"
          margin={false}
        />
      </div>
      {error && <Error error={error} />}
      <div className="table--responsive">
        <table className="mt-1">
          <thead>
            <tr>
              <th>Crawl Date</th>
              <th>Site</th>
              <th>Status</th>
              <th>Total URLs</th>
              <th>URLs Changed</th>
            </tr>
          </thead>
          <tbody>
            {hasData &&
              items.map(crawl => {
                const { id, crawlDate, hostname, status, totalUrls, urlsChanged } = crawl;
                const key = crawlDate + `-` + hostname + `-` + urlsChanged;

                return (
                  <tr key={key}>
                    <td className="text--nowrap">
                      {status === `complete` && <Link href={`${ROUTES.crawl}?id=${id}`}>{crawlDate}</Link>}
                      {status !== `complete` && crawlDate}
                    </td>
                    <td>{hostname}</td>
                    <td>{status}</td>
                    <td>{totalUrls}</td>
                    <td>{urlsChanged}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default LatestCrawls;

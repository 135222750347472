import { fetchAPI } from 'particles';

declare type GetCategoryCountsParameters = {
  domain?: string;
};

declare type APIResponse = {
  data: CategoryCount[];
  pagination: Pagination;
};

export const callGetCategoryCounts = async (
  queryKey,
  parameters: GetCategoryCountsParameters,
): Promise<APIResponse> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `research/category-counts` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetCategoryCounts;

import styled from 'styled-components';

const DiffWrapper = styled.section`
  hr {
    margin: 10px 0px;
  }
  .diff--container {
    & + .diff--container {
      margin-top: 40px;
    }

    .diff-expected {
      background-color: #ffd1d0;
      white-space:pre-wrap;
    }

    .diff-detected {
      background-color: #dbffe1;
      white-space:pre-wrap;
    }
  }
`;

export default DiffWrapper;

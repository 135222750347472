import * as React from 'react';

type VideoProps = {
  /**
   * True/False value to auto-play the video on loading of resource and
   * initialisation of the 'Video' component
   */
  autoPlay?: boolean;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * True/False value to display browser default video controls
   */
  controls?: boolean;
  /**
   * True/False value to force the 'Video' component to become an
   * absolute position element, covering the nearest relative parent element.
   */
  fill?: boolean;
  /**
   * True/False value to automatically replay the video on final frame.
   */
  loop?: boolean;
  /**
   * True/False value to enable/disable audio,
   * needs to be true when 'autoPlay' is true.
   */
  muted?: boolean;
  /**
   * The HTML string for setting the source of the MP4 file
   * used as a resource in the 'video' element.
   */
  src: string;
  /**
   * Address of the track (.vtt file). Must be a valid URL.
   */
  track?: string;
};

const Video: React.FC<VideoProps> = (props: VideoProps) => {
  const { autoPlay, className, controls, fill } = props;
  const { loop, muted, src, track } = props;
  let classList = `video`;
  if (className) classList += ` ${className}`;
  if (fill) classList += ` video--fill`;

  return (
    <video className={classList} autoPlay={autoPlay} controls={controls} loop={loop} muted={muted}>
      <source src={src} type="video/mp4" />
      {track && <track default kind="captions" srcLang="en" src={track} />}
      Your browser does not support the video tag.
    </video>
  );
};

// Default prop values
Video.defaultProps = {
  autoPlay: true,
  controls: false,
  fill: false,
  loop: true,
  muted: true,
  track: null,
};

export default Video;

import ROUTES from 'routes';

import type { NavigationItem } from '../navigation.types';

export const sidebarItems: NavigationItem[] = [
  {
    href: ROUTES.optimisation_manager,
    icon: `dashboard`,
    title: `Dashboard`,
  },
  {
    href: ROUTES.mastersheet,
    icon: `pageview`,
    title: `Mastersheet`,
  },
  {
    href: ROUTES.tasks,
    icon: `task`,
    title: `Tasks`,
  },
  {
    disabled: true,
    href: ROUTES.keyword_changes,
    icon: `listBulleted`,
    title: `Keyword Changes`,
  },
];

export default sidebarItems;

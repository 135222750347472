import styled from 'styled-components';
import { isLight } from 'helpers';
import { shouldBeDark } from 'particles';

import button from 'styles/base/button';

const buttonBackground = props => {
  // If a background value is specified, use that instead of theme
  if (props.background) return props.background;

  // If no variant is specified, return the black colour in our theme
  if (!props.variant) return `var(--black)`;

  // Dynamically determine the background colour based on props
  let colour;
  switch (props.variant) {
    case `primary`:
      colour = shouldBeDark() ? `var(--grey900)` : `var(--black)`;
      break;
    case `secondary`:
      colour = shouldBeDark() ? `var(--grey600)` : `var(--secondary)`;
      break;
    case `green`:
      colour = shouldBeDark() ? `var(--grey600)` : `var(--green)`;
      break;
    case `hollow`:
      colour = shouldBeDark() ? `transparent` : `transparent`;
      break;
    default:
      colour = shouldBeDark() ? `var(--grey600)` : `var(--black)`;
      break;
  }

  return colour;
};

const buttonBorder = props => {
  // If a background value is specified, use that instead of theme
  if (props.background) return props.background;

  // If no variant is specified, return the primary colour in our theme
  if (!props.variant) return `var(--primary)`;

  // Dynamically determine the background colour based on props
  let colour;
  switch (props.variant) {
    case `primary`:
      colour = shouldBeDark() ? `transparent` : `transparent`;
      break;
    case `secondary`:
      colour = shouldBeDark() ? `transparent` : `transparent`;
      break;
    case `green`:
      colour = shouldBeDark() ? `transparent` : `transparent`;
      break;
    case `hollow`:
      colour = shouldBeDark() ? `var(--white)` : `var(--grey800)`;
      break;
    default:
      colour = shouldBeDark() ? `transparent` : `transparent`;
      break;
  }

  return colour;
};

const buttonColour = props => {
  // Fallback value if we can't get access to props
  if (!props || !props.theme || !props.theme.primary) return `var(--white)`;

  if (props.background) {
    const { background } = props;
    const textColour = `var(--black)`;

    if (!background) return textColour;
    const wasLight = isLight(background);

    if (!wasLight) return `var(--white)`;
    if (wasLight) return `var(--black)`;
  }

  // If no variant is specified, return the white colour
  if (!props.variant) return `white`;

  // Dynamically determine the background colour based on props
  let colour;
  switch (props.variant) {
    case `primary`:
      colour = `var(--white)`;
      break;
    case `secondary`:
      colour = `var(--white)`;
      break;
    case `green`:
      colour = `var(--white)`;
      break;
    case `hollow`:
      colour = shouldBeDark() ? `var(--white)` : `var(--black)`;
      break;
    default:
      colour = `var(--white)`;
      break;
  }

  return colour;
};

const spaceIcon = props => {
  // Fallback value if we can't get access to props
  if (!props || !props.count || !props.iconPosition) return `0`;
  if (props.count === 1) return `0`;
  if (props.count === props.iconPosition) return `0 0 0 8px`;
  return `0 8px 0 0`;
};

const buttonTransform = props => {
  // Fallback value if we can't get access to props
  if (!props) return null;
  const { count, iconPosition } = props;
  if (!count || !iconPosition) return null;
  const isSingle = count === 1;
  const isSingleIcon = isSingle && count === iconPosition;
  if (!isSingleIcon) return null;
  return `4px`;
};

export const StyledButtonWrapper = styled.span`
  display: inline-block;

  a {
    ${button};
    display: block;
    padding-left: ${props => buttonTransform(props)};
    padding-right: ${props => buttonTransform(props)};

    background-color: ${props => buttonBackground(props)};
    border: 1px solid ${props => buttonBorder(props)};
    color: ${props => buttonColour(props)};
    pointer-events: ${props => props.disabled && `none`};
    opacity: ${props => props.disabled && 0.4};

    svg {
      margin: ${props => spaceIcon(props)};
    }
  }
`;

const StyledButton = styled(StyledButtonWrapper).attrs({
  as: `button`,
})`
  ${button};
  display: inline-flex;
  padding-left: ${props => buttonTransform(props)};
  padding-right: ${props => buttonTransform(props)};

  background-color: ${props => buttonBackground(props)};
  border: 1px solid ${props => buttonBorder(props)};
  color: ${props => buttonColour(props)};

  svg {
    margin: ${props => spaceIcon(props)};
  }
`;

export default StyledButton;

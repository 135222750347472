/**
 * Copies raw text to clipboard
 * Creates a textarea, adds raw text into it, copies and removes texarea
 * @param {string} text
 */
export const copyRawTextToClipboard = text => {
  const dummy = document.createElement(`textarea`);
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand(`copy`);
  document.body.removeChild(dummy);
};

export default copyRawTextToClipboard;

import styled from 'styled-components';
import { device } from 'particles';

const StyledViewTemplate = styled.section`
  .report-builder__dates > h2,
  .report-builder__dates > h3,
  .report-builder__header ~ h2,
  .report-builder__header ~ h3 {
    margin-bottom: 24px;
    margin-top: 0;
    padding-bottom: 24px;

    border-bottom: 1px solid var(--grey200);
    font-size: 2rem;
  }

  .report-builder__header ~ h3 {
    font-size: 1.8rem;

    border-top: 1px solid var(--grey200);
    padding-top: 24px;
  }

  .report-builder__header ~ h2 + h3 {
    padding-top: 0;

    border-top: none;
  }

  .report-builder__header {
    display: flex;
    margin-bottom: 24px;
    margin-top: 0;

    h2 {
      flex: 1;
      margin-bottom: 0;
      margin-right: 24px;

      @media ${device?.xl} {
        margin-right: 48px;
      }
    }
  }

  /* Targets preceeding H2s after first H2 */
  .report-builder__header ~ h2 {
    margin-top: 64px;
  }

  /* Targets first H2 after header */
  .report-builder__header + h2 {
    margin-top: 24px;
  }

  .report-builder__controls {
    display: flex;
    margin-bottom: 24px;
    padding-bottom: 16px;
    width: 100%;

    border-bottom: 1px solid var(--grey200);

    > * {
      align-items: center;
      display: flex;
    }

    > * + * {
      margin-left: auto;
    }
  }

  .SingleDatePicker {
    margin-left: 16px;
  }

  .report-builder__dates {
    display: flex;
    margin-top: 64px;

    h2 {
      flex-grow: 1;
      margin-right: 32px;
    }
  }
`;

export default StyledViewTemplate;

import * as React from 'react';

// Localised partials
import CurrentCategories from './current';
import DetectedCategories from './detected';
import SearchCategories from './search';

type Props = {
  isLocked?: boolean;
};

const PageCategories: React.FC<Props> = (props: Props) => {
  const { isLocked } = props;

  return (
    <section className="page__categories">
      <DetectedCategories />
      <CurrentCategories isLocked={isLocked} />
      { !isLocked && <SearchCategories isLocked={isLocked} /> }
    </section>
  );
};

export default PageCategories;

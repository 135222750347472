import * as React from 'react';
import ReactMarkdown from 'react-markdown';

const findPart = (text, find = `Title`, whitespace = false) => {
  const regex = new RegExp(String.raw`(?<=<!-- ${find} -->\s*).*?(?=\s*<!-- ${find} -->)`);
  let textVal = text.match(regex);
  const hasVal = textVal?.length > 0;
  if (hasVal && !whitespace) textVal = textVal[0].replace(/(\r\n|\n|\r)/gm, ``);
  return textVal;
};

const processSingle = page => {
  if (!page) return undefined;
  const { id, relativePath } = page;
  const internalContent = page?.internal?.content;
  if (!internalContent) return undefined;

  let singleContent = undefined;
  const splitContentArr = internalContent.split(`<!-- Content -->`);
  const hasContent = splitContentArr?.length === 3;
  if (hasContent) singleContent = splitContentArr[1];

  let excerptContent = undefined;
  const splitExcerptArr = internalContent.split(`<!-- Excerpt -->`);
  const hasExcerpt = splitExcerptArr?.length === 3;
  if (hasExcerpt) excerptContent = splitExcerptArr[1];

  const dateContent = findPart(internalContent, `Date`);
  const title = findPart(internalContent, `Title`);

  const content = (
    <article className="m-0" key={id}>
      <ReactMarkdown source={singleContent} escapeHtml={false} />
    </article>
  );

  const date = dateContent;
  const excerpt = <ReactMarkdown source={excerptContent} escapeHtml={false} />;

  const pagePath = relativePath.replace(`.md`, ``);
  const href = pagePath;

  return {
    content,
    date,
    excerpt,
    href,
    id,
    title,
  };
};

export const parseMarkdown = data => {
  if (!data) return null;

  const hasArray = data?.length > 0;
  if (!hasArray) return processSingle(data);

  const contents = data.map(processSingle);
  return contents.filter(Boolean);
};

export default parseMarkdown;

import { css } from 'styled-components';

const video = css`
  video {
    max-width: 100%;
  }

  .video--fill {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    object-fit: cover;
  }
`;

export default video;

import * as React from 'react';
import { useContext } from 'react';
import { OMModelview } from 'corigan';

// Particles
import { parseHTML } from 'corigan';

// Helpers
import { generateID } from 'corigan';
import { isAnObject } from 'corigan';
import { isHTML } from 'corigan';

// Atoms
import { Chip } from 'corigan';
import { Change } from 'corigan';
import { Link } from 'corigan';
import { Shorten } from 'corigan';
import type { ChipProps } from 'corigan';
import { CompareArrows } from 'icons';
import { Tooltip } from 'corigan';
import { Button } from 'corigan';

import { TableMovementCell } from '../../../../pages/report-builder/components/partials';

// Local partials
import TableContext from '../../context/tableContext';

import type { TableContextProps } from '../../table.types';

declare type TableBodyProps = {
  columns: any[];
  items: any[];
  itemsGrouped: any[];
  selectable: boolean;
};

const isArray = (argValue: any): boolean => argValue && argValue.constructor === Array && argValue !== null;
const isObject = (argValue: any): boolean => argValue && typeof argValue === `object` && argValue !== null;
const isValidObject = (argValue: any): boolean => isObject(argValue) && argValue.value !== undefined;

declare type ArrayItem = {
  bold?: boolean;
  component?: 'chip' | 'link' | 'text';
  condition?: APICondition;
  href?: string;
  target?: string;
  value?: any;
  variant?: ChipProps['variant'];
  where?: string;
  tooltip?: string;
  tooltipAlign?: "top" | "bottom" | "center"
  tooltipSide?: "right" | "left";
};

const renderArray = (argValue: any | any[], columnKey) => {
  const wasArray: boolean = isArray(argValue);
  if (!wasArray) return argValue;

  return argValue.map((itemValue: ArrayItem) => {
    const context: TableContextProps = useContext(TableContext);
    const dispatch = context?.dispatch;
    const key: string = generateID();

    if (!itemValue) return null;

    const { bold, component = `chip`, condition, href, target, value, variant, where, tooltip, tooltipAlign, tooltipSide } = itemValue;

    switch (component) {
      case `chip`: {
        const isButton: boolean = Boolean(condition);
        const isLink: boolean = Boolean(href);

        const hasVariant: boolean = Boolean(variant);
        const fallbackVariant: ChipProps['variant'] | undefined = isLink ? `primary` : undefined;
        const appliedVariant: ChipProps['variant'] = hasVariant ? variant : fallbackVariant;
        const field: string = where ? where : columnKey;

        const values: ObjectLiteral & { condition: APICondition } = {
          condition,
          field,
          value,
          or: false,
        };

        const handleClick: Function = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
          if (e) e.preventDefault();
          dispatch({ type: `filterAdd`, value: values });
        };

        let onClick: undefined | Function = undefined;
        if (isButton) onClick = handleClick;

        const wasObject: boolean = isObject(value);
        let val: any = value;
        if (wasObject) val = `object`;

        const hasTooltip: Boolean = Boolean(tooltip)

        return (
          <div>
            {!hasTooltip &&
              <Chip bold={bold} href={href} key={key} onClick={onClick} variant={appliedVariant}>
                {val}
              </Chip>
            }
            {hasTooltip &&
              <Tooltip content={tooltip} key={key} side={tooltipSide} align={tooltipAlign}>
                <Chip bold={bold} href={href} key={key} onClick={onClick} variant={appliedVariant}>
                        {val}
                </Chip>
              </Tooltip>
            }
          </div>
        );
      }
      case `link`: {
        return (
          <p>
            <Link href={href} target={target} key={key} >
              {value}
            </Link>
          </p>
        );
      }
      case `text`: {
        return (
          <p key={key}>
            {value}
          </p>
        );
      }
    }
  });
};

declare type RenderObjectArg = {
  href?: string;
  levels?: number;
  shorten?: boolean;
  value: any;
  type?: string;
  model?: boolean;
  movement?: boolean;
  movementValue?: number;
  average?: boolean;
};

const renderObject = (argValue: RenderObjectArg) => {
  const { href, levels, shorten, value, type, model, movement, movementValue, average } = argValue;
  const hasHREF: boolean = Boolean(href);
  const shouldShorten: boolean = Boolean(shorten);

  if(model){
    return <OMModelview page={value} />
  }

  if(movement){
    return <TableMovementCell positionValue={value} movementValue={movementValue} average={average} />
  }

  if (hasHREF)
    return (
        <Link href={href} shorten={shouldShorten} levels={levels}>
          {value}
        </Link>
    );

  if (shouldShorten) return <Shorten levels={levels}>{value}</Shorten>;

  if (type) {
    if (["best", "worst"].includes(type.toLowerCase())) return <Change value={value} />;
  }

  return value;
};

const TableBody = (props: TableBodyProps) => {
  const { items: basicItems, itemsGrouped } = props;
  const hasGrouped: boolean = itemsGrouped?.length > 0;
  const items = hasGrouped ? itemsGrouped : basicItems;

  const hasItems: boolean = items?.length > 0;
  if (!hasItems) return null;

  const validItems: any[] = hasGrouped
    ? items.filter(group => group.every(i => isAnObject(i)))
    : items.filter(i => isAnObject(i));

  const hasValid: boolean = validItems?.length > 0;
  if (!hasValid) return null;

  return (
    <tbody>
      {hasGrouped &&
        validItems.map((group, groupIndex) => {
          return group.map((item, i: number) => {
            const lastGroup = groupIndex + 1 === validItems.length;

            const isFirst: boolean = i === 0;
            const isSibling: boolean = !isFirst;
            const isLast: boolean = i + 1 === group.length;
            const className = isFirst ? `tr--grouped--first` : isLast && !lastGroup ? `tr--grouped--last` : ``;

            const id = item?.page?.id || item?.id || item;
            const idValue = isAnObject(id) ? id?.value : id;
            const key = `${idValue}-${i}`;

            return <TableRow {...props} className={className} item={item} isSibling={isSibling} key={key} />;
          });
        })}
      {!hasGrouped &&
        validItems.map(item => {
          const id = item?.page?.id || item?.id || item;
          const idValue = isAnObject(id) ? id?.value : id;
          const key = idValue;

          return <TableRow {...props} item={item} key={key} />;
        })}
    </tbody>
  );
};

declare type TableRowProps = {
  className?: string;
  columns: any[];
  item: any;
  isSibling?: boolean;
  selectable: boolean;
};

const TableRow: React.FC<TableRowProps> = (props: TableRowProps) => {
  const { className, columns, isSibling, selectable } = props;
  const { item } = props;

  const context: TableContextProps = useContext(TableContext);
  const dispatch: any = context?.dispatch;
  const state: any = context?.state;

  const selected: any[] = state?.selected;
  const selectedIDs: string[] = selected?.map(entity => entity?.page?.id || entity?.id || entity).filter(Boolean);
  const hasSelect: boolean = selectable === true;
  const hasButtons = Boolean(item?.buttons?.length);

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>, value) => {
    if (e) e.preventDefault();
    if (!value) return;
    dispatch({ type: `itemSelect`, value });
  };

  const selectID: string = item?.id?.value;

  let selectValue: string = selectID;
  const hasCollectionObject: boolean = item?.originalObject;
  if (hasCollectionObject) selectValue = item.originalObject;

  const renderSelect: boolean = hasSelect && Boolean(selectValue);

  const isSelected: boolean = selectedIDs.some(id => {
    const match: boolean = id === selectID;
    return match;
  });

  return (
    <tr className={className} key={generateID()}>
      {renderSelect && (
        <td>
          {!isSibling && (
            <>
              <input
                id={selectID}
                name={selectID}
                checked={isSelected}
                onChange={e => handleSelect(e, selectValue)}
                type="checkbox"
              />
              <label htmlFor={selectID} />
            </>
          )}
        </td>
      )}
      {Object.keys(item).map((key: string) => {
        const column = columns.find(column => column.value === key);
        const isVisible: boolean = Boolean(column);
        if (!isVisible) return null;

        const id: string = generateID();
        const isSmall: boolean = column.small || false;
        const isWrapped: boolean = column.wrap || false;

        const val: any = item[key];

        const data: ObjectLiteral = {
          key: id,
          'data-col': key,
        };

        if (!val && val !== 0) return <td {...data} key={id} />;

        const wasArray: boolean = isArray(val);
        const wasObject: boolean = !wasArray && isObject(val);
        const wasBrokenObject: boolean = wasObject && !isValidObject(val);

        const handleObject: boolean = wasObject;
        const handleBrokenObject: boolean = wasBrokenObject;
        const handleArray: boolean = !wasObject && wasArray;
        const handleHTML: boolean = isHTML(val);

        if (handleBrokenObject) {
          return <td {...data} key={id} />;
        }

        if (handleObject) {
          let classList = `text--nowrap`;
          if (column?.wrap) classList = ``;

          return (
            <td className={classList} {...data} key={id}>
              {renderObject(val)}
            </td>
          );
        }

        if (handleArray) {
          return (
            <td {...data} key={id}>
              {renderArray(val, key)}
            </td>
          );
        }

        let classList = `text--nowrap`;
        if (isWrapped) classList = ``;
        if (isSmall) classList += ` text--small`;

        if (handleHTML) {
          return (
            <td className={classList} {...data} key={id}>
              {parseHTML(val)}
            </td>
          );
        }

        return (
          <td className={classList} {...data} key={id}>
            {val}
          </td>
        );
      })}

      {hasButtons && (
        <td>
          {item.buttons.map(button => {

            const { name, href } = button;
            const id: string = generateID();
            const data: ObjectLiteral = {
              key: id,
              'data-col': 'buttons',
            };

            return (
              <Button {...data} key={id} href={href} small={true} className="ml-1">
                {name}
              </Button>
            )
          })}
        </td>
      )}
    </tr>
  );
};

export default TableBody;

import * as React from 'react';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { toast } from 'react-toastify';

// Particles
import { ApplicationContext } from 'corigan';
import { windowAvailable } from 'corigan';
import { updateSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Promoted } from 'icons';
import { Button } from 'corigan';

// API
import { callUpdateKeyword } from 'corigan';

declare type KeywordPromoteProps = {
  keyword: Keyword;
};

const KeywordPromote: React.FC<KeywordPromoteProps> = (props: KeywordPromoteProps) => {
  const { keyword } = props;
  const { id, highPriority: initialPromoted, phrase } = keyword;
  const [promoted, setPromoted] = useState(initialPromoted);

  const queryCache = useQueryCache();

  const { userHasPermission: canUpdateKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:update`],
  });

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = appContext?.state?.domainActive;

  const [mutate, { isLoading: loading }] = useMutation(callUpdateKeyword, {
    // When mutate is called:
    onMutate: () => { },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Sorry the keyword ${phrase} could not be updated.`, {});

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => { },
    onSuccess: (data: APIResponse, variables) => {
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const _with: ArgWith = [`tags`, `updatedBy`];

      setPromoted(!promoted);
      // If callUpdateKeyword function was successful, get values from API response
      const keywordData: Keyword = data?.data;
      const phrase = keywordData?.phrase;

      const messageStart = `The keyword <span class="text--bold">${phrase}</span> has been marked as a`;
      let message = `${messageStart} high priority keyword and will be checked daily for changes.`;
      if (promoted) message = `${messageStart} lower priority keyword.`;

      toast.info(message, {});

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetKeyword`, { id }], data);

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`], (previousKeywords: APIResponse) =>
        updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 10, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 25, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 50, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, perPage: 100, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { perPage: 1000, whereProtected }],
        (previousKeywords: APIResponse) => updateSingleCache(previousKeywords, keywordData),
      );
    },
  });

  const handleClick = async e => {
    if (e) e.preventDefault();

    if (windowAvailable() && !canUpdateKeyword) window.alert(`You do not have permission to update keywords`);
    if (!canUpdateKeyword) return;

    await mutate({ id, highPriority: !promoted });
  };

  let promoteClass: string = `mt-1 keyword__promote`;
  if (promoted) promoteClass += ` keyword__promote--active`;

  let promoteText: string = `Promote Keyword`;
  if (promoted) promoteText = `Promoted`;

  let titleText: string = ``;
  if (promoted) titleText = `Demote`;

  const variant: 'green' | 'hollow' = promoted ? `green` : `hollow`;

  const disabled = loading || !canUpdateKeyword;

  return (
    <Button className={promoteClass} disabled={disabled} onClick={handleClick} title={titleText} variant={variant}>
      <Promoted />
      <span className="ml-1">{promoteText}</span>
    </Button>
  );
};

export default KeywordPromote;

import ROUTES from 'routes';

import type { NavigationItem } from '../navigation.types';

export const sidebarItems: NavigationItem[] = [
  {
    href: ROUTES.market,
    icon: `store`,
    title: `Market`,
  },
  // {
  //   href: ROUTES.market_changes,
  //   icon: `chart`,
  //   title: `Market Changes`,
  // },
  // {
  //   href: ROUTES.detailed,
  //   icon: `find`,
  //   title: `Detailed`,
  // },
  {
    href: ROUTES.daily_rankings,
    icon: `rankings`,
    title: `Daily SERP Rankings`,
  },
  {
    href: ROUTES.movement_rankings,
    icon: `compareArrows`,
    title: `Rankings Movement`,
  },
];

export default sidebarItems;

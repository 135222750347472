import styled from 'styled-components';
import { device } from 'particles';

const StyledCrawlStatistics = styled.section`
  display: flex;
  flex-direction: column;

  @media ${device?.lg} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(var(--rows, 5), 1fr);
  }
`;

export default StyledCrawlStatistics;

import * as React from 'react';
import { useReducer } from 'react';
import moment from 'moment';

// Particles
import { deepMerge } from 'corigan';
import { getRelease } from 'corigan';
import { localStorageRead } from 'corigan';
import { useQueryParameters } from 'corigan';

// Local State Handlers
import RBContext from './rbContext';
import rbReducer from './rbReducer';

import defaultState from './defaultState';

declare type RBStateProps = {
  children: React.ReactNode;
  initialState?: any;
};

export const RBState = (props: RBStateProps) => {
  const { releaseVersion } = getRelease();
  const queryParameters = useQueryParameters();
  const apiDate: string = queryParameters?.apiDate;
  const chartDateEnd: string = queryParameters?.chartDateEnd;
  const chartDateStart: string = queryParameters?.chartDateStart;
  const mom: 'true' | 'false' | string = queryParameters?.mom;
  const momSet = mom === `true` || mom === `false`;

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = (key: 'Devices' | 'MonthOnMonth' | 'ChartDateEnd' | 'ChartDateStart' | 'APIDate'): string =>
    `version=${releaseVersion}&key=rbConfig${key}`;

  // Device detection, a little more complicated as string to array conversion
  const devices: string = queryParameters?.devices;
  let devicesArray: string[] = [];

  if (devices) devicesArray = devices.split(`,`);
  const hasDevices: boolean = devicesArray?.length > 0;

  const isValid = (device: string): boolean => device === `mobile` || device === `desktop`;
  let validDevices: string[] = [];
  if (hasDevices) validDevices = devicesArray.filter(isValid);
  const hasValidDevices = validDevices?.length > 0;

  // Create global state provider to handle state and reducer dispatch events
  const { children, initialState } = props;

  // Create a duplicated start state variable
  let startState = { ...defaultState };

  // If the user has values in local storage, using them in the start state
  const localDevices = localStorageRead(buildKey(`Devices`));
  const localMOM = localStorageRead(buildKey(`MonthOnMonth`));
  const localChartDateEnd = localStorageRead(buildKey(`ChartDateEnd`));
  const localChartDateStart = localStorageRead(buildKey(`ChartDateStart`));
  const localAPIDate = localStorageRead(buildKey(`APIDate`));
  const hasLocalDevices = localDevices?.filter(isValid)?.length > 0;

  if (hasLocalDevices) startState.devices = localDevices.filter(isValid);
  if (localChartDateEnd) startState.chartDateEnd = moment(localChartDateEnd);
  if (localChartDateStart) startState.chartDateStart = moment(localChartDateStart);
  if (localAPIDate) startState.apiDate = moment(localAPIDate);
  if (localMOM) startState.monthOnMonth = Boolean(localMOM);

  // If query parameters are passed, then set them on the start state
  if (chartDateEnd) startState.chartDateEnd = moment(chartDateEnd);
  if (chartDateStart) startState.chartDateStart = moment(chartDateStart);
  if (apiDate) startState.apiDate = moment(apiDate);
  if (momSet) startState.monthOnMonth = Boolean(mom);

  // @ts-ignore
  if (hasValidDevices) startState.devices = validDevices;
  if (initialState) startState = deepMerge(startState, initialState);

  const [state, dispatch] = useReducer(rbReducer, startState);
  const value = { state, dispatch };

  return <RBContext.Provider value={value}>{children}</RBContext.Provider>;
};

export default RBState;

import * as React from 'react';

// Particles
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// Local Partials
import ReportDetailed from '../components/views/detailed';

const ReportBuilderMarket = () => (
  // <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`rb:read`]}>
  <ProtectedRoute>
    <Page application="report-builder" pageTitle="Detailed">
      <PageContent />
    </Page>
  </ProtectedRoute>
);

const PageContent = () => {
  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.report_builder}>Report Builder</Link>
            <h1>Detailed</h1>
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportDetailed />
        </Col>
      </Row>
    </Grid>
  );
};

export default ReportBuilderMarket;

import * as React from 'react';
import { useRef, useState } from 'react';

import { windowAvailable } from 'corigan';

import { ChevronDown } from 'icons';

import StyledAccordion from './accordion.styles';

type AccordionProps = {
  /**
   * The 'children' of 'Accordion' expects two groups of children.
   * The first is a group of accordion laccordionels in spans
   * The second is a group of sections you want the laccordionels to open on interaction.
   */
  contents: {
    content: any;
    title: string;
  }[];
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * Prop to switch the way the laccordionels are styled
   */
  variation?: 'default';
};

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const { contents, className } = props;
  const [focus, setFocus] = useState(null); // Uses focus index

  const hasContents = contents && contents.length > 0;
  if (!hasContents) return null;

  let classList = `accordion`;
  if (className) classList += ` ${className}`;

  return (
    <StyledAccordion className={classList}>
      {contents.map((item, i) => (
        <AccordionItem
          content={item.content}
          focus={focus}
          i={i}
          key={`accordion-item-${item.title}`}
          setFocus={setFocus}
          title={item.title}
        />
      ))}
    </StyledAccordion>
  );
};

// Default prop values
Accordion.defaultProps = {
  variation: `default`,
};

type AccordionItemProps = {
  content: any;
  focus: number;
  i: number;
  title: string;
  setFocus: Function;
};

const scrollToRef = ref => {
  if (!windowAvailable()) return;
  if (!ref?.current?.offsetTop) return;
  window.scrollTo(0, ref.current.offsetTop);
};

const AccordionItem: React.FC<AccordionItemProps> = (props: AccordionItemProps) => {
  const { content, focus, i, setFocus, title } = props;
  const isActive = focus === i;

  const ref = useRef(null);

  const handleClick = e => {
    e.preventDefault();
    let updatedVal = i;
    if (isActive) updatedVal = null;
    if (updatedVal) scrollToRef(ref);
    setFocus(updatedVal);
  };

  let classList = `accordion__item`;
  if (isActive) classList += ` accordion__item--active`;

  return (
    <div className={classList} key={`accordion-item-${title}`} ref={ref}>
      <h2 className="accordion__item__heading">
        <button className="accordion__item__toggle" onClick={handleClick}>
          <span>{title}</span>
          <ChevronDown />
        </button>
      </h2>
      <div className="accordion__item__content">{content}</div>
    </div>
  );
};

export default Accordion;

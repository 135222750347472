import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { callMe } from 'corigan';

// Components
import { Error } from 'corigan';

// Local Partials
import SingleNotification from './notification';
import Overlay from '../overlay';

type NotificationsProps = {
  showNotifications: boolean;
  updateHeaderView?: Function;
};

const Notifications: React.FC<NotificationsProps> = (props: NotificationsProps) => {
  const { showNotifications, updateHeaderView } = props;

  let className = `header__notifications`;
  if (showNotifications) className += ` header__overlay--show`;

  return (
    <Overlay active={showNotifications} className={className} updateHeaderView={updateHeaderView}>
      <header>
        <h2>Notifications</h2>
      </header>
      <div className="notifications">
        <NotificationsCollection />
      </div>
    </Overlay>
  );
};

const NotificationsCollection = () => {
  const { data: res, error, isLoading: loading } = useQuery([`notificationsCallMe`], callMe);
  const user: User = res?.data;

  const items = user?.notifications;
  const hasItems = items?.length > 0;

  const message = loading || error || !hasItems;
  if (message) {
    return (
      <div className="notification">
        {loading && <p>Loading notifications...</p>}
        {error && <Error error={error} />}
        {!hasItems && <p>No new notifications</p>}
      </div>
    );
  }

  return (
    <React.Fragment>
      {items.map((n: CoriganNotification, i: number) => {
        const key = `${n?._id ? n._id : n.createdAt}-${i}`;
        return <SingleNotification {...n} key={key} user={user} />;
      })}
    </React.Fragment>
  );
};

export default Notifications;

import { css } from 'styled-components';
import { device } from 'particles';

const statisticsCSS = css`
  .report__statistics {
    display: grid;
    grid-gap: 32px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(1, 1fr);
    margin: 24px 0;

    @media ${device?.md} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${device?.lg} {
      grid-template-columns: repeat(6, 1fr);
    }

    & + * {
      margin-top: 48px;
    }
  }

  .report__statistics--landscape {
    @media ${device?.md} {
      grid-template-columns: repeat(6, 1fr);
    }

    @media ${device?.xxl} {
      grid-template-columns: repeat(12, 1fr);
    }
  }
`;

export default statisticsCSS;

import * as React from 'react';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { toast } from 'react-toastify';

// Particles
import ROUTES from 'routes';
import { ApplicationContext } from 'corigan';
import { removeSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Button, Link } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Grid, Row, Col } from 'corigan';

// API
import { callDeleteKeyword } from 'corigan';

type KeywordContentProps = {
  children: React.ReactNode;
  data: Keyword;
  title: string;
};

// https://gist.github.com/SonyaMoisset/aa79f51d78b39639430661c03d9b1058#file-title-case-a-sentence-for-loop-wc-js
const kebabeToTitle = stringValue => {
  stringValue = stringValue.replace(`-`, ` `);
  stringValue = stringValue.toLowerCase().split(` `);
  for (let i = 0; i < stringValue.length; i++) {
    stringValue[i] = stringValue[i].charAt(0).toUpperCase() + stringValue[i].slice(1);
  }
  return stringValue.join(` `);
};

const KeywordContent: React.FC<KeywordContentProps> = (props: KeywordContentProps) => {
  const { children, title } = props;
  const keyword = props?.data;
  const id = keyword?.id;
  const keywordResearchHREF = ROUTES.research + `?id=` + id;
  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = appContext?.dispatch;
  const domainActive: Domain = appContext?.state?.domainActive;

  const { userHasPermission: canDeleteKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:delete`],
  });

  const browserPageTitle = kebabeToTitle(title);

  React.useEffect(() => {
    document.title = `Corigan - Keyword Manager - ${browserPageTitle}`
  }, [browserPageTitle]);

  const [mutate, { isLoading: loading }] = useMutation(callDeleteKeyword, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      toast.error(`Unable to delete keyword`, {});
    },
    // Always refetch after error:
    onSettled: (data, error) => {
      if (error) queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    onSuccess: (data: APIResponse, variables) => {
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const _with: ArgWith = [`tags`, `updatedBy`];
      const id: ArgID = variables?.id;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`], (previousKeywords: APIResponse) =>
        removeSingleCache(previousKeywords, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 10 }],
        (previousKeywords: APIResponse) => removeSingleCache(previousKeywords, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 25 }],
        (previousKeywords: APIResponse) => removeSingleCache(previousKeywords, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 50 }],
        (previousKeywords: APIResponse) => removeSingleCache(previousKeywords, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 100 }],
        (previousKeywords: APIResponse) => removeSingleCache(previousKeywords, id),
      );

      // Redirect to keywords directory
      navigate(ROUTES.keyword_manager);
    },
  });

  const deleteKeyword = async () => {
    // If no ID is available, function can't run
    if (!id) return;
    if (!windowAvailable()) return;

    // Check their permission level
    if (!canDeleteKeyword) {
      window.alert(`You do not have permission to delete keywords`);
      return;
    }

    try {
      // Process delete mutaiton
      await mutate({ id });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => await deleteKeyword();

  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Deleting a keyword is permanent, are you sure you want to delete the selected keyword?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  };

  return (
    <Grid>
      <Row>
        <Col>
          <Breadcrumbs>
            <Link href={ROUTES.keyword_manager}>All Keywords</Link>
            <h1>{title}</h1>
            <Button className="breadcrumb__button" href={keywordResearchHREF}>
              View in Keyword Research
            </Button>
            {canDeleteKeyword && (
              <Button className="breadcrumb__button" disabled={loading} onClick={handleClick} variant="hollow">
                Delet{loading ? `ing` : `e`} Keyword
              </Button>
            )}
          </Breadcrumbs>
        </Col>
      </Row>
      <Row>{children}</Row>
    </Grid>
  );
};

export default KeywordContent;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';
import { fetchAPI } from 'corigan';
import { isUndefined } from 'corigan';
import { ROUTES } from 'corigan';

// Components
import { Chip, Field } from 'corigan';

const searchTagText = async ({ name, domainActive }) => {
  return await fetchAPI({
    parameters: {
      whereProtected: `[domain][eq]=${domainActive?.id}`,
      where: `[or][textSearch][textSearch]=${name}&where[or][name][contains]=${name}`,
    },
    route: `tags`,
  });
};

type PageTagsProps = {
  isLocked?: boolean;
};

const PageTags: React.FC<PageTagsProps> = (props: PageTagsProps) => {
  const { isLocked } = props;
  return (
    <section className="page__tags">
      <CurrentTags isLocked={isLocked} />
      { !isLocked && <SearchTag isLocked={isLocked} /> }
    </section>
  );
};

const CurrentTags = ({ isLocked }) => {
  const context = useContext(OMContext);
  const state = context?.state;
  const dispatch = context?.dispatch;

  const editedRevision: PageRevision = state.editedRevision;
  const tags = editedRevision?.tags;

  const startedEdit = state?.startedEdit;
  const shouldConfirm = startedEdit;

  const handleClick = useCallback(
    (e, id) => {
      e.preventDefault();
      const current = editedRevision?.tags;

      // If already exists in state, ignore the function
      const exists = current.some(tag => tag.id === id);
      if (!exists) return;

      // Create new array with removed keyword
      const newTags = current.filter(tag => tag.id !== id);
      dispatch({ key: `tags`, value: newTags });
    },
    [dispatch, editedRevision],
  );

  const tagRoute = ROUTES.tag;

  return useMemo(() => {
    const variant = !isLocked ? null : `primary`;
    const hasTags = tags && tags.length > 0;
    if (!hasTags) return null;

    const currentTags = tags.map(({ id, name }) => {
      const key = `tag-current-${id}`;
      let onClick = null;
      if (!isLocked) onClick = e => handleClick(e, id);

      const href = tagRoute + `?id=${id}`;

      return (
        <Chip confirm={shouldConfirm} key={key} href={href} onClick={onClick} variant={variant}>
          {name}
        </Chip>
      );
    });
    return (
      <React.Fragment>
        <h3 className="mt-1 mb-2">Current Tags</h3>
        {currentTags}
      </React.Fragment>
    );
  }, [handleClick, isLocked, shouldConfirm, tags, tagRoute]);
};

declare type SearchTagProps = {
  isLocked: boolean;
};

const SearchTag: React.FC<SearchTagProps> = (props: SearchTagProps) => {
  const { isLocked } = props;
  const [results, setResults] = useState<any[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchTerm, setSearchValue] = useState<string>(``);
  const [searchArgument, setSearchArgument] = useState<string>(``);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  useEffect(() => {
    const callAsyncSearch = async () => {
      setSearching(true);
      try {
        const res = await searchTagText({ name: searchArgument, domainActive });
        setResults(res.data);
      } catch (error) {
        console.error(error);
      }
      setSearching(false);
    };
    callAsyncSearch();
  }, [searchArgument, domainActive]);

  const handleChange = e => {
    if (searching) return;

    const searchTerm = e?.target?.value;
    if (isUndefined(searchTerm)) return;

    setSearchValue(searchTerm);

    const length = searchTerm.length;
    const isLongEnough = length >= 3;
    if (!isLongEnough) return;

    setSearchArgument(searchTerm);
  };

  const hasResults = results?.length > 0;
  const showResults = searchTerm?.length >= 3;
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault();

  return (
    <React.Fragment>
      <form autoComplete="off" className="mt-4" onSubmit={onSubmit}>
        <Field
          id="tag-search"
          disabled={isLocked}
          label="Search Tags"
          handleChange={handleChange}
          type="search"
          value={searchTerm}
        />
      </form>
      {showResults && (
        <div className="mt-2">
          {!hasResults && <span>No results found</span>}
          {hasResults && <RenderTags isLocked={isLocked} linked={false} tags={results} />}
        </div>
      )}
    </React.Fragment>
  );
};

const RenderTags = ({ isLocked, linked = false, tags }) => {
  const omContext = useContext(OMContext);
  const dispatch = omContext?.dispatch;
  const state = omContext?.state;
  const editedRevision: PageRevision = state.editedRevision;

  const startedEdit = state?.startedEdit;
  const shouldConfirm = startedEdit;

  const handleClick = useCallback(
    (e, tag) => {
      e.preventDefault();
      const current = editedRevision?.tags;

      // If already exists in state, ignore the function
      const exists = current.some(t => t.id === tag.id);
      if (exists) return;

      // Create new array with updated tag
      const newTags = [...current, tag];
      dispatch({ key: `tags`, value: newTags });
    },
    [dispatch, editedRevision],
  );

  const tagRoute = ROUTES.tag;

  return tags.map(tag => {
    const { id, name } = tag;
    const disabled = isLocked;
    const key = `tag-${id}`;

    const href = !linked ? null : tagRoute + `?id=${id}`;

    return (
      <Chip confirm={shouldConfirm} disabled={disabled} href={href} key={key} onClick={e => handleClick(e, tag)}>
        {name}
      </Chip>
    );
  });
};

export default PageTags;

import { fetchAPI } from 'particles';

type GetReportKeywordSERPArgs = {
  domain: ArgID;
  keyword: ArgPhrase;
};

export const callGetReportKeywordSERP = async (reqKey, parameters: GetReportKeywordSERPArgs) => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `reports/keyword/serp` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetReportKeywordSERP;

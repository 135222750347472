import styled from 'styled-components';
import { device } from 'particles';
import { shouldBeDark } from 'particles';

const StyledSidebar = styled.aside`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;

  background-color: ${() => (shouldBeDark() ? `var(--grey800)` : `var(--purple)`)};
  color: var(--grey200);

  @media ${device?.md} {
    height: 100%;
    min-height: 100vh;
    position: sticky;
    top: 0;
    width: 80px;
  }

  a {
    align-items: center;
    display: flex;
    justify-content: center;

    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    font-size: 20px;
    letter-spacing: 4px;
    line-height: 21px;
    text-transform: uppercase;
  }

  header {
    display: flex;

    background-color: ${() => (shouldBeDark() ? `var(--grey800)` : `var(--purple)`)};
    box-shadow: 0px 2px 3px #0000000d;

    a {
      padding: 16px;

      color: var(--white);

      @media ${device?.md} {
        display: none;
        padding: 24.5px 26px;
        padding-right: 24px;
      }
    }

    button {
      align-items: center;
      display: flex;
      margin-left: auto;
      padding: 16px 24px;

      background: transparent;
      border: transparent;

      @media ${device?.md} {
        width: 100%;
      }

      svg {
        margin: 0;

        color: var(--orange400);
        fill: var(--orange400);

        @media (min-width: 340px) {
          margin-right: 8px;
        }

        @media ${device?.md} {
          margin: 0 auto;
        }
      }

      span {
        display: none;

        @media (min-width: 340px) {
          display: inline-block;
        }

        @media ${device?.md} {
          display: none;
        }
      }

      span span {
        display: none;

        @media (min-width: 440px) {
          display: inline-block;
        }
      }
    }
  }

  &.sidebar--open {
    height: 100vh;

    @media ${device?.md} {
      height: 100%;
      width: 260px;

      background-color: ${() => (shouldBeDark() ? `var(--grey900)` : `var(--white)`)};
    }

    a {
      justify-content: unset;
    }

    header a {
      @media ${device?.md} {
        display: block;
      }
    }

    header button {
      width: initial;

      svg {
        color: var(--orange400);
      }
    }

    li {
      a {
        color: ${() => (shouldBeDark() ? `var(--grey200)` : `var(--black)`)};
        font-size: inherit;

        &[aria-current='page'],
        &:active,
        &:focus,
        &:focus-within,
        &:hover {
          border-left: 5px solid ${() => (shouldBeDark() ? `var(--teal200)` : `var(--teal)`)};
          color: ${() => (shouldBeDark() ? `var(--teal200)` : `var(--teal)`)};
        }
      }

      svg {
        margin-right: 12px;
        width: 16px;

        color: currentColor;
      }
    }
  }

  li {
    position: relative;

    a {
      padding: 18px 14px;

      @media ${device?.md} {
        padding: 18px 20px;
      }

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      color: var(--grey100);
      font-size: 0;
      line-height: 18px;
      transition: 0.2s border-left ease;

      &[aria-current='page'],
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        border-left: 5px solid var(--white);
        color: var(--white);

        svg {
          color: currentColor;
        }
      }
    }

    svg {
      display: block;
      width: 20px;

      color: var(--grey100);
      transition: 0.2s color ease;
    }
  }

  li + li {
    &::before {
      content: '';
      height: 1px;
      left: 0;
      left: calc(0px + 20px);
      position: absolute;
      top: 0;
      width: 100%;
      width: calc(100% - 40px);

      background-color: var(--black);
      opacity: 0.08;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  .disabled {
    opacity: 0.4;

    pointer-events: none;
  }
`;

export default StyledSidebar;

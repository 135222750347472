import * as React from 'react';

import './movement.css';

declare type TableMovementProps = {
  positionValue?: string;
  movementValue?: string;
  average: boolean;
};

export const TableMovementCell = (props: TableMovementProps) => {
  const { positionValue, movementValue, average } = props;
  const movementValueInt = parseInt(movementValue);
  let cell;
  let averageClass;
  const averageOpenBracket = average ? `` : `[`;
  const averageCloseBracket = average ? `` : `]`;

  if (movementValueInt === 0) {
    averageClass = average ? `tablecell__back__orange` : ``;
    cell = (
      <span className='tablecell__span'>
        <span className='tablecell__brackets'>
          {averageOpenBracket}
        </span>
        <span className='tablecell__orange'>&#61;</span>
        <span className='tablecell__brackets'>
          {averageCloseBracket}
        </span>
      </span>
    )
  } else if (Math.sign(movementValueInt) === -1) {
    averageClass = average ? `tablecell__back__red` : ``;
    cell = (
      <span className='tablecell__span'>
        <span className='tablecell__brackets'>
          {averageOpenBracket}
        </span>
        {movementValue}
        <span className='tablecell__red'>&darr;</span>
        <span className='tablecell__brackets'>
          {averageCloseBracket}
        </span>
      </span>
    )
  } else {
    averageClass = average ? `tablecell__back__green` : ``;
    cell = (
      <span className='tablecell__span'>
        <span className='tablecell__brackets'>
          {averageOpenBracket}
        </span>
        +{movementValue}
        <span className='tablecell__green'>&uarr;</span>
        <span className='tablecell__brackets'>
          {averageCloseBracket}
        </span>
      </span>
    )
  }

  return (
    <span className={`tablecell_movement ${averageClass}`}>
      {positionValue && <span className='tablecell__position'>{positionValue}</span>}
      {cell}
    </span>
  );
};

export default TableMovementCell;

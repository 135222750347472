import * as React from 'react';
import { useQuery } from 'react-query';

// Particles
import { useQueryParameters } from 'corigan';
import { ProtectedRoute } from 'corigan';

// Components
import { Page } from 'corigan';

// Localised partials
import Complete from './partials/complete';
import Error from './partials/error';
import Loading from './partials/loading';
import Missing from './partials/missing';

// API
import { callGetCrawl } from 'corigan';

const CrawlWrapper = () => (
  <ProtectedRoute>
    <CrawlContents />
  </ProtectedRoute>
);

type CrawlCProps = {};

const CrawlContents: React.FC<CrawlCProps> = (props: CrawlCProps) => {
  const queryParameters = useQueryParameters();
  const { id } = queryParameters;

  return (
    <Page application="competitor-research" pageTitle="Crawl Categories">
      {id && <Crawl id={id} />}
      {!id && <Missing />}
    </Page>
  );
};

type CrawlProps = {
  id: ArgID;
};

const _with: ArgWith = [`domain`];

const Crawl: React.FC<CrawlProps> = (props: CrawlProps) => {
  const { id } = props;

  const { data: res, error, isLoading: loading } = useQuery([`callGetCrawl`, { id, _with }], callGetCrawl);
  const data = res?.data;

  if (loading) return <Loading />;
  if (data) return <Complete data={data} id={id} />;
  if (error) return <Error error={error} id={id} />;
};

export default CrawlWrapper;

import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { exportTableToCSV } from 'corigan';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Components
import { Button } from 'corigan';
import { Link } from 'corigan';
import { NewTab } from 'corigan';
import { Error } from 'corigan';

// API
import { callGetManyPageRevisions } from 'corigan';

import { statuses } from 'particles';

declare type Props = {
  keyword: Keyword;
};

const BriefedKeywordUses: React.FC<Props> = (props: Props) => {
  const { keyword } = props;
  const { phrase, briefedPageUses } = keyword;

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = appContext?.state?.domainActive;

  const { userHasPermission: canReadPages } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const briefedStatuses = [statuses.briefed, statuses.awaitingApproval];

  const pageWhere = briefedPageUses.map(page => `&where[page][any]=${page}`)
  const statusWhere = briefedStatuses.map(status => `&where[status][any]=${status}`)
  const where: ArgWhere = `[domain][eq]=${domainActive?.id}${pageWhere.join(``)}${statusWhere.join(``)}`;
  const perPage: ArgPerPage = 50;

  const { data: res, error, isLoading: loading } = useQuery(
    [`callGetManyPageRevisions`, { perPage, where }],
    callGetManyPageRevisions,
    { enabled: canReadPages && briefedPageUses.length },
  );
  const data = res?.data;

  if (loading) return null;
  if (error) return <Error error={error} />;

  const hasData: boolean = data?.length > 0;
  const revisions = data;

  const csvTitle: string = `briefed-keyword-uses-${phrase}`;

  const columns: {
    label: string;
    value: string;
  }[] = [
      { value: `keyword`, label: `Keyword` },
      { value: `title`, label: `Page Title` },
      { value: `id`, label: `Page ID` },
      { value: `url`, label: `Page URL` },
    ];

  const items = hasData && revisions.map((revision: PageRevision) => {
    const { pageId, name, url } = revision;
    const keyword = phrase;

    return { keyword, title: name, id: pageId, url };
  });

  const handleExport = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) e.preventDefault();

    try {
      await exportTableToCSV(csvTitle, columns, items);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <div className="display--flex align-items--center justify-content--between">
        <h2>Briefed Keyword Uses</h2>
        {hasData && <Button onClick={handleExport}>Export CSV</Button>}
      </div>
      {!hasData && <NoData />}
      {hasData && (
        <div className="table--responsive">
          <table>
            <thead>
              <tr>
                <th>Page Title</th>
                <th>Page ID</th>
                <th>URL Path</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {revisions.map((revision: PageRevision) => {
                const { id, page, pageId, name, url, status } = revision;
                const urlObject = new URL(url);
                const slug: string = urlObject?.pathname;
                const link: string = slug ? slug : url;

                const pageRoute: string = `${ROUTES.page}?id=${page}&status=${status}`;

                return (
                  <tr key={`keyword-use-${id}`}>
                    <td className="text--wrap-all">
                      <Link href={pageRoute}>{name}</Link>
                    </td>
                    <td className="text--wrap-all">{pageId}</td>
                    <td>{link}</td>
                    <td>
                      <NewTab href={url} text={`Navigate to ${url}`} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

const NoData = () => {
  return (
    <React.Fragment>
      <p>No keyword uses were found in any briefed pages.</p>
      <p>
        To add the keyword to a page, visit <Link href={ROUTES.optimisation_manager}>Optimisation Manager</Link>.
      </p>
    </React.Fragment>
  );
};

export default BriefedKeywordUses;

import * as React from 'react';

function SvgMap(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M23.3 0h-.2L16 2.8 8 0 .5 2.5c-.3.1-.5.4-.5.7v20.1c0 .4.3.7.7.7h.2L8 21.2l8 2.8 7.5-2.5c.3-.1.5-.3.5-.6V.7c0-.4-.3-.7-.7-.7zM16 21.3l-8-2.8V2.7l8 2.8v15.8z"
        fill="currentColor"
      />
      <path d="M0 0h24v24H0V0z" fill="none" />
    </svg>
  );
}

export default SvgMap;

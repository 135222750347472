import * as React from 'react';

// Components
import { Row, Col } from 'corigan';

// Molecules
import { Error } from 'corigan';

import DiffWrapper from './diff';

type DiffProps = {
  previousColumn?: string;
  currentColumn?: string;
  loading?: boolean;
  diff: CrawlUrlDiff;
};

const Diff: React.FC<DiffProps> = (props: DiffProps) => {
  const { loading, diff } = props;
  const previousColumn = props.previousColumn ?? `Previous`;
  const currentColumn = props.currentColumn ?? `Current`;

  const { title, metaDescription, contentBlocks, h1, h2, h3, url } = diff || {};
  const hasTitleDiff: boolean = Boolean(title?.length);
  const hasMetaDescriptionDiff: boolean = Boolean(metaDescription?.length);
  const hasContentBlocksDiff: boolean = Boolean(contentBlocks?.length);
  const hasH1Diff: boolean = Boolean(h1?.length);
  const hasH2Diff: boolean = Boolean(h2?.length);
  const hasH3Diff: boolean = Boolean(h3?.length);
  const hasUrlDiff: boolean = Boolean(url?.length);
  const hasDiffs: boolean =
    hasTitleDiff || hasMetaDescriptionDiff || hasContentBlocksDiff || hasH1Diff || hasH2Diff || hasH3Diff || hasUrlDiff;

  return (
    <DiffWrapper>
      <Row equalHeight={true}>
        <Col xl={6}>
          <div>
            <h1>{previousColumn}</h1>
            <hr />
          </div>
        </Col>
        <Col xl={6}>
          <div>
            <h1>{currentColumn}</h1>
            <hr />
          </div>
        </Col>
      </Row>
      {!hasDiffs && !loading && <Error>There are no differences to display</Error>}
      {hasTitleDiff && <RenderDiff name={`Title`} diff={title} />}
      {hasMetaDescriptionDiff && <RenderDiff name={`Meta Description`} diff={metaDescription} />}
      {hasUrlDiff && <RenderDiff name={`URL`} diff={url} />}
      {hasContentBlocksDiff &&
        contentBlocks.map((contentBlockDiff, index) => {
          const diff = contentBlockDiff?.diff;
          const name = contentBlockDiff?.contentBlock?.name ?? `Unknown`;

          return <RenderDiff key={`contentBlockDiff-${index}`} name={name} diff={diff} />;
        })}
      {hasH1Diff && <RenderArrayDiff name={`Heading 1`} diff={h1} />}
      {hasH2Diff && <RenderArrayDiff name={`Heading 2`} diff={h2} />}
      {hasH3Diff && <RenderArrayDiff name={`Heading 3`} diff={h3} />}
    </DiffWrapper>
  );
};

type RenderDiffProps = {
  name: string;
  diff: Diff[];
};

const RenderDiff: React.FC<RenderDiffProps> = (props: RenderDiffProps) => {
  const { name, diff } = props;

  const renderDiff = (diff: Diff[], live: Boolean) => {
    const diffContent = diff.filter(part => {
      const { added, removed } = part;

      return live ? !added : !removed;
    });

    return diffContent.flatMap((part, index) => {
      const { value, added, removed } = part;
      const diffClass = added ? `diff-expected` : removed ? `diff-detected` : undefined;

      return (
        <span className={diffClass} key={index}>
          {value}
        </span>
      );
    });
  };

  return (
    <div className="diff--container">
      <Row>
        <Col>
          <h2>{name}</h2>
        </Col>
      </Row>
      <Row equalHeight={true}>
        <Col xl={6}>
          <div>{renderDiff(diff, false)}</div>
        </Col>
        <Col xl={6}>
          <div>{renderDiff(diff, true)}</div>
        </Col>
      </Row>
    </div>
  );
};

const RenderArrayDiff: React.FC<RenderDiffProps> = (props: RenderDiffProps) => {
  const { name, diff } = props;

  const renderDiff = (diff: Diff[], live: Boolean) => {
    const diffContent = diff.filter(part => {
      const { added, removed } = part;

      return live ? !added : !removed;
    });

    return diffContent.map((part, index1) => {
      const { value, added, removed } = part;
      const diffClass = added ? `diff-expected` : removed ? `diff-detected` : undefined;

      return (
        <>
          {value.map((val, index2) => (
            <li className={diffClass} key={`${index1}-${index2}`}>
              {val}
            </li>
          ))}
        </>
      );
    });
  };

  return (
    <div className="diff--container">
      <Row>
        <Col>
          <h2>{name}</h2>
        </Col>
      </Row>
      <Row equalHeight={true}>
        <Col xl={6}>
          <ul>{renderDiff(diff, false)}</ul>
        </Col>
        <Col xl={6}>
          <ul>{renderDiff(diff, true)}</ul>
        </Col>
      </Row>
    </div>
  );
};

export default Diff;

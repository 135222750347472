import * as React from 'react';

// Particles
import { ProtectedRoute, ROUTES } from 'corigan';
import { parseMarkdown } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Accordion } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

declare type FAQProps = {
  data: any[];
};

const FAQ: React.FC<FAQProps> = (props: FAQProps) => {
  const { data } = props;
  const hasContent = data?.length > 0;

  return (
    <ProtectedRoute>
      <Page application="portal" pageTitle="FAQs">
        <Grid>
          <Row>
            <Col>
              <Breadcrumbs>
                <Link href={ROUTES.dashboard}>Dashboard</Link>
                <h1>FAQs</h1>
              </Breadcrumbs>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card minHeight={false}>{hasContent && <Articles data={data} />}</Card>
            </Col>
          </Row>
        </Grid>
      </Page>
    </ProtectedRoute>
  );
};

declare type ArticlesProps = {
  data: any;
};

const Articles: React.FC<ArticlesProps> = (props: ArticlesProps) => {
  const { data } = props;
  const contents = parseMarkdown(data);
  return <Accordion contents={contents} />;
};

export default FAQ;

import { fetchAPI } from 'particles';
import { statuses } from 'particles';

type GetOnePageRevisionsParameters = {
  id: ArgID;
  drafts?: boolean;
  orderBy?: ArgOrderBy;
  perPage?: ArgPerPage;
  _with?: ArgWith;
};

const isBool = checkedVal => typeof checkedVal === `boolean`;

export const callGetOnePageRevisions = async (queryKey, parameters: GetOnePageRevisionsParameters) => {
  const { drafts, id } = parameters;
  const whereID = `[page][eq]=${id}`;

  let whereStatus = null;
  if (isBool(drafts)) {
    const operatorWhere = drafts ? `eq` : `ne`;
    whereStatus = `&where[status][${operatorWhere}]=${statuses.draft}`;
  }

  let where = whereID;
  if (whereStatus) where += whereStatus;

  const converted = { ...parameters, where };
  delete converted.id;

  const response = await fetchAPI({ method: `GET`, parameters: converted, route: `pagerevisions` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetOnePageRevisions;

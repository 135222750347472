import * as React from 'react';

function SvgIsometricChart(props) {
  return (
    <svg viewBox="0 0 32.5 44.5" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        d="M32 2.884c-.3-.1-3.4-1.6-3.6-1.7-.4-.1-.8-.1-1.1.2l-5 3.4v-2.3c0-.4-.2-.7-.5-.9-.2-.1-3.4-1.6-3.6-1.7-.4-.1-.8 0-1.1.2l-6.1 4.4c-.5.3-.8.9-.8 1.5v17.1c-1-.4-2.1-1-2.2-1-.4-.3-.8-.2-1.1.1l-6.1 4.2c-.4.3-.7.8-.8 1.4v14.1c0 .3.2.7.5.9.3.1 3.4 1.5 3.6 1.7.4.1.8.1 1.1-.2l6.1-4.2c.5-.3.8-.9.8-1.5v-2.4c1 .4 2.1 1 2.2 1 .4.1.8.1 1.1-.2l6.1-4.2c.5-.3.8-.9.8-1.4v-2.4c.9.4 2.1 1 2.2 1 .4.1.8.1 1.1-.2l6.1-4.2c.5-.3.8-.9.8-1.5v-20.4c0-.3-.2-.6-.5-.8zm-20.5 33.1v2.7c0 .3-.1.5-.4.7l-5.9 4.1h-.1v-13.2c0-.3.1-.5.4-.7l4.7-3.3 1.2-.8h.1v10.5zm10.2-4.6c0 .3-.1.6-.4.7l-5.9 4.1h-.1V8.284c0-.3.1-.5.4-.7l5.9-4.1h.1v27.9zm10.2-7.2c0 .3-.1.5-.4.7l-5.9 4.1h-.1v-19.5c0-.3.1-.5.4-.7l5.9-4.1h.1v19.5z"
      />
    </svg>
  );
}

export default SvgIsometricChart;

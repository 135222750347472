// Replaces HTML spaces &nbsp; from text.
// Taken from:
// https://stackoverflow.com/questions/1495822/replacing-nbsp-from-javascript-dom-text-node

const cleanTextMap = [
  // Space
  {
    to: String.fromCharCode(32),
    from: [
      String.fromCharCode(160), // No-break space (NBSP)
      String.fromCharCode(8232), // Line separator
      String.fromCharCode(8233), // Paragraph separator
      String.fromCharCode(8192), // En quad
      String.fromCharCode(8193), // Em quad
      String.fromCharCode(8194), // En space
      String.fromCharCode(8195), // Em space
      String.fromCharCode(8196), // Three-per-em space
      String.fromCharCode(8197), // Four-per-em space
      String.fromCharCode(8198), // Six-per-em space
      String.fromCharCode(8199), // Figure space
      String.fromCharCode(8200), // Punctuation space
      String.fromCharCode(8201), // Thin space
      String.fromCharCode(8202), // Hair space
      String.fromCharCode(8239), // Narrow no-break space (NNBSP)
      String.fromCharCode(8287), // Medium mathematical space (MMSP)
      String.fromCharCode(12288), // Ideographic space
    ],
  },
  // Apostrophe
  {
    to: String.fromCharCode(39),
    from: [
      String.fromCharCode(8216), // Left single quotation mark
      String.fromCharCode(8217), // Right single quotation mark
      String.fromCharCode(8219), // Single high-reversed-9 quotation mark
      String.fromCharCode(8242), // Prime
      String.fromCharCode(8245), // Reversed prime
    ],
  },
  // Quotation mark
  {
    to: String.fromCharCode(34),
    from: [
      String.fromCharCode(8220), // Left double quotation mark
      String.fromCharCode(8221), // Right double quotation mark
      String.fromCharCode(8223), // Double high-reversed-9 quotation mark
      String.fromCharCode(8243), // Double prime
      String.fromCharCode(8246), // Reversed double prime
    ],
  },
  // Comma
  {
    to: String.fromCharCode(44),
    from: [
      String.fromCharCode(8218), // Single low-9 quotation mark
    ],
  },
  // Full stop
  {
    to: String.fromCharCode(44),
    from: [
      String.fromCharCode(8228), // One dot leader
      String.fromCharCode(8229), // Two dot leader
      String.fromCharCode(8230), // Horizontal ellipsis
    ],
  },
  // Hyphen-minus
  {
    to: String.fromCharCode(45),
    from: [
      String.fromCharCode(8208), // Hyphen
      String.fromCharCode(8209), // Non-breaking hyphen
      String.fromCharCode(8210), // Figure dash
      String.fromCharCode(8211), // EN dash
      String.fromCharCode(8212), // EM dash
      String.fromCharCode(8213), // Horizontal bar
      String.fromCharCode(8259), // Hyphen bullet
    ],
  },
  // Semicolon
  {
    to: String.fromCharCode(59),
    from: [
      String.fromCharCode(8271), // Reversed semicolon
    ],
  },
  // Solidus (Forward slash)
  {
    to: String.fromCharCode(47),
    from: [
      String.fromCharCode(8260), // Fraction slash
    ],
  },
]

export const cleanTextRegExp = (string: string): string => {
  if (!string) return string;

  cleanTextMap.forEach(cleanChar => {
    const { to, from } = cleanChar;
    const regExChars = `[${from.join(``)}]`;
    const regEx = new RegExp(regExChars, `g`);

    string = string.replace(regEx, to);
  })

  return string;
};

export default cleanTextRegExp;


import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledMDN = styled.p`
  font-family: var(--font);
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  margin: 16px 0;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;

const base = `https://developer.mozilla.org/en-US/docs/Web/HTML/Element/`;
const DAYS_IN_SECS = 86400000;

type MDNProps = {
  element: string;
};

type MDNSummaryProps = {
  innerText?: string;
};

type MDNWikiProps = {
  innerText?: string;
};

const MDN: React.FC<MDNProps> = (props: MDNProps) => {
  const { element = `hr` } = props;
  const [description, setDescription] = useState(null);

  const requestData = useCallback(() => {
    fetch(base + element)
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser();
        const doc: Document = parser.parseFromString(html, `text/html`);
        const desc: Element & MDNSummaryProps = doc.querySelector(`.seoSummary`);
        const wikiArticle: Element & MDNWikiProps = doc.querySelector(`#wikiArticle p`);
        if (!desc && !wikiArticle) {
          throw Error(`No SEO description found, could be referencing an incorrect URL or unsupported HTML element`);
        }
        const value = desc ? desc.innerText : wikiArticle.innerText;
        const object = {
          timestamp: new Date().getTime(),
          value,
        };
        localStorage.setItem(element, JSON.stringify(object));
        setDescription(value);
      })
      .catch(err => {
        console.warn(`Something went wrong.`, err);
        setDescription(``);
      });
  }, [element]);

  useEffect(() => {
    const localValue = localStorage.getItem(element);

    if (localValue) {
      const object = JSON.parse(localValue);
      const { value } = object;

      const today = new Date(1685817000386);
      const storageDate = new Date(object.timestamp);
      const diff = Math.floor(+today - +storageDate / DAYS_IN_SECS);
      const isOld = diff > 180;

      if (!isOld) setDescription(value);
      if (isOld) {
        localStorage.removeItem(element);
        requestData();
      }
    }

    if (!localValue) requestData();
  }, [element, requestData]);

  if (!description) return null;
  if (description === ``) return null;
  return (
    <StyledMDN>
      {description} -{` `}
      <a href={base + element} rel="noreferrer noopener" target="_blank">
        MDN
      </a>
    </StyledMDN>
  );
};

export default MDN;

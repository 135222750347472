import styled from 'styled-components';
import { device } from 'particles';

const StyledApplicationItems = styled.section`
  &.applications {
    align-items: initial;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .header__action__applications & {
      width: 320px;

      @media ${device?.xs} {
        width: 550px;
      }
    }
  }

  .application {
    display: block;
    height: 0;
    padding-top: 33.3333%;
    position: relative;
    width: 33.3333%;

    background-color: var(--blue);
    color: var(--white);
    font-size: 1.4rem;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    transition: 0.2s background-color ease, 0.2s color ease;

    @media ${device?.xs} {
      padding-top: 20%;
      width: 20%;
    }

    &::before {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      background-color: var(--white);
      opacity: 0;
      pointer-events: none;
      transition: 0.2s opacity ease;
    }

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      &::before {
        opacity: 0.3;
      }
    }

    &.application {
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        &::before {
          opacity: 0.3;
        }
      }
    }
  }

  .application:nth-child(1) {
    background-color: var(--orange);
  }

  .application:nth-child(2) {
    background-color: #e9a500;
  }

  .application:nth-child(3) {
    background-color: var(--green);
  }

  .application:nth-child(4) {
    background-color: var(--teal);
  }

  .application:nth-child(5) {
    background-color: var(--blue);
  }

  .application__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 24px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .application--disabled.application--disabled {
    background-color: var(--grey100);
    pointer-events: none;
  }

  .application__icon,
  svg {
    display: block;
    margin: 0 auto;
    max-height: 64px;
    width: 100%;
  }

  .header__action__applications.header__action__applications & svg {
    display: block;
    height: 42px;
    margin: 0 auto;
  }

  .application__title {
    display: none;
    margin-top: 10px;

    color: var(--white);
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(10px);

    @media ${device?.xl} {
      display: block;
    }
  }

  .header__action__applications.header__action__applications & .application__title {
    display: block;

    transform: translateY(0);
  }
`;

export default StyledApplicationItems;

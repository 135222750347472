import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import moment from 'moment';

// Particles
import { OMContext } from 'corigan';

// Components
import { Loader } from 'corigan';
import { Tabs } from 'corigan';
import { Info } from 'corigan';

// Localised partials
import PageContent from './content';
import PageDrafts from './drafts';
import PageEditor from './editor';
import PageHistory from './history';
import PageOverview from './overview';
import PageDiff from './diff';
import PageInfo from './info';

import { statuses } from 'particles';

// API
import { callUpdatePageRevision, callMe } from 'corigan';

const interval = 3;
const SECOND = 1000;
const refreshLength = interval * SECOND;

type ParentWrapperProps = {
  data: Page;
};

const ParentWrapper = (props: ParentWrapperProps) => {
  const data = props?.data;
  const queryCache = useQueryCache();
  const context: any = useContext(OMContext);
  const dispatch: any = context?.dispatch;
  const state = context?.state;
  const editedRevision: PageRevision | null = state?.editedRevision;
  const status = editedRevision?.status;

  // fetch user
  const me = useQuery([`callMe`], callMe);
  const currentUser = me?.data?.data;

  // If any edits to the page revision have been made yet
  const startedEdit: boolean = Boolean(state?.startedEdit);
  const locked: boolean = Boolean(state?.locked);

  const [isLocked, setLocked] = useState(false);
  const [isPolling, setPolling] = useState(true);

  let hasLocked = editedRevision?.editLocked;
  const lockedUser = editedRevision?.editLocked?.editLockedBy?.id;
  const lockedDate = editedRevision?.editLocked?.date;

  // define lock / unlock mutation
  const [mutate, { error, isLoading: loading }] = useMutation(callUpdatePageRevision, {
    onMutate: () => { },
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      dispatch({ type: `set`, key: `error`, value: err });
    },
    onSettled: (data, error) => {
      toast.error(error, {})
    },
    onSuccess: (data: APIResponse, variables) => { },
  });

  // handle unlocked polling effect
  useEffect(() => {
    // define lock unlock write handler function
    const lockWriteHandler = async (lockedState: boolean) => {
      // define args for editlock mutate
      const args = {
        id: editedRevision.id,
        locked: lockedState
      };
      // patch editlock
      await mutate(args);
    }

    // out of date checker function
    const outOfDateLockCheck = () => {
      // check for lock time
      if (lockedDate) {
        // check if lock by another user
        if (currentUser?.id !== hasLocked?.editLockedBy?.id) {
          // do calc on lock date
          const lockDate = moment(new Date(lockedDate));
          const currentDate = moment(new Date());
          // lock time out of date checker
          const checkLockDate = (lock, now) => lock.isBefore(now.subtract(2, `hours`));
          // check if out of date
          if (checkLockDate(lockDate, currentDate) === true) {
            // remove lock
            lockWriteHandler(false);
            // clear old haslocked
            hasLocked = null;
          }
        }
      }
    };

    // handle lock date check and reset if out of date
    outOfDateLockCheck();

    // do state checks and update editlocked object accordingly
    if (hasLocked) {
      // check if current user has locked this page
      if (lockedUser !== currentUser.id) {
        // is locked by another user
        setLocked(true);
      } else {
        // locked by current user
        setLocked(false);

        // check if editing
        if (startedEdit && !locked) {
          dispatch({ type: `set`, key: `locked`, value: true });
          // add edit lock object
          lockWriteHandler(true);
        }
      }
    } else {
      // set editing to unlocked
      setLocked(false);
      // check if editing
      if (startedEdit) {
        // add edit lock object
        lockWriteHandler(true);
      }
    }

    // define poll Update EditLocked handler function
    const pollingLockUnlockHandler = async () => {
      // invalidate data to check for lock/unlock and edited data
      queryCache.invalidateQueries([`callGetPage`]);
      // check if not locked and no editing
      if (isLocked) {
        // trigger out of date check for each poll
        outOfDateLockCheck();
      } else {
        // TODO: Improve this so it only checks when new edits are made
        // // check if editing
        // if (!isLocked && startedEdit) {
        //   console.log(`isPolling false, lockWriteHandler true`);
        //   // do date update for editlock object
        //   lockWriteHandler(true);
        // }
      }
    }

    // start main lock / unlock polling
    const pollingInterval = setInterval(() => {
      // Limits edit checking to only new page, briefed, live and drafts
      if ([statuses.newPage, statuses.briefed, statuses.live, statuses.draft].includes(status)) {
        pollingLockUnlockHandler();
      }
    }, refreshLength);

    // define cleanup event handler function
    const cleanupEventHandler = async () => {
      lockWriteHandler(false);
    }

    // add unload event listeners
    window.addEventListener(`beforeunload`, cleanupEventHandler);

    return () => {
      // remove polling
      clearInterval(pollingInterval);
      // remove unload event listener
      window.removeEventListener(`beforeunload`, cleanupEventHandler);
    }
  }, [currentUser, lockedUser, isLocked, isPolling, lockedDate, setPolling, setLocked, editedRevision, queryCache, hasLocked, mutate, startedEdit]);

  // if no data return loader
  if (!data) return <Loader />;

  // return request completed
  return <RequestComplete isLocked={isLocked} initialData={data} userId={currentUser?.id} />;
};

type RequestCompleteProps = {
  isLocked: boolean;
  initialData?: Page;
  userId: string;
};

const RequestComplete = (props: RequestCompleteProps) => {
  const { isLocked, initialData, userId } = props;

  const omContext = useContext(OMContext);
  const dispatch = omContext?.dispatch;
  const state = omContext?.state;

  const editedRevision: PageRevision | null = state?.editedRevision;
  const startedEdit: boolean = state?.startedEdit;
  const locked: boolean = state?.locked;
  const status = editedRevision?.status;
  const lowerStatus: string = status?.toLowerCase();
  const isMismatched: boolean = lowerStatus === statuses.mismatched.toLowerCase();

  // What date was the page locked, if we have an ISO8601 date value available
  const lockedDate: string = editedRevision?.editLocked?.date;

  // Set the unlock date to null
  let unlockStamp = null;

  // If we have a locked date, then figure out the time from now until unlocked
  if (lockedDate) unlockStamp = moment(lockedDate).fromNow();

  // current edit lock user
  const editLocked: EditLocked = editedRevision?.editLocked;
  const currentLockedUserId: string = editLocked?.editLockedBy?.id;
  const currentLockedUsername: string = editLocked?.editLockedBy?.name;

  // new edit lock user
  const activeRevision = initialData?.activeRevision;
  const newEditLocked: EditLocked = activeRevision?.editLocked;
  const newLockedUserId: string = newEditLocked?.editLockedBy?.id;

  // Resets the page when going from one page to another (ID_ONE -> ID_TWO)
  useEffect(() => {
    if (!dispatch) return;

    if (!startedEdit) {
      dispatch({ type: `pageInitial`, value: initialData });
    } else {
      dispatch({ type: `editLockState`, value: initialData?.activeRevision?.editLocked });
    }
  }, [dispatch, editLocked, initialData, startedEdit]);

  return (
    <PageContent isLocked={isLocked} loading={false}>
      <PageInfo status={status} newLockedUserId={newLockedUserId} currentLockedUserId={currentLockedUserId} userId={userId} locked={locked} />
      {isLocked && unlockStamp && (
        <Info>The page was locked by {currentLockedUsername} and was locked {unlockStamp}</Info>
      )}
      <Tabs id="om-page-content" x={0}>
        <React.Fragment>
          <span>Page Details</span>
          <span>Editor</span>
          <span>Drafts</span>
          <span>History</span>
          {isMismatched && <span className="tab__title--warning">Difference</span>}
        </React.Fragment>
        <React.Fragment>
          <PageOverview isLocked={isLocked} />
          <PageEditor isLocked={isLocked} />
          <PageDrafts isLocked={isLocked} />
          <PageHistory isLocked={isLocked} />
          {isMismatched && <PageDiff />}
        </React.Fragment>
      </Tabs>
    </PageContent>
  );
};

export default ParentWrapper;

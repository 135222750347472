import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';

// Particles
import { ApplicationContext } from 'particles';
import { ROUTES } from 'corigan';
import { useHasPermissions } from 'corigan';

// Atoms
import { Link } from 'corigan';
import { Card } from 'corigan';
import { Change } from 'corigan';

// API
import { callGetManyKeywordChanges } from 'corigan';

// Local partials
import TableError from '../error';

// Local functions
import { getChip } from './functions';

type TableRequestProps = {};

const _with = [`pagerevision`, `keyword`];

const RankingsWorst = (props: TableRequestProps) => {
  const linkRankingsWorst = { href: ROUTES.rankings_worst, title: `View all` };
  let content;

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`pages:read`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const where: ArgWhere = `[pagerevision.domain][eq]=${domainActive?.id}&where[positionChange][lt]=0`;

  const { data: res, error, isLoading: loading } = useQuery(
    [`keywordChanges`, { perPage: 5, where, _with, orderBy: `positionChange` }],
    callGetManyKeywordChanges,
    { enabled: canRead },
  );
  const data = res?.data;

  if (!canRead) return <TableError error="You do not have permission to view page revisions" />;
  if (error) content = <TableError error={error} />;

  const hasData: boolean = data?.length > 0;

  if (hasData) {
    content = data.map((keywordChange: any) => {
      const { pagerevision, keyword, positionChange, endPosition } = keywordChange;
      const { name, pageId, page, status } = pagerevision;
      const key = `ranking-best-${page}`;
      const href = `${ROUTES.page}?id=${page}&status=${status}`;
      const hasKeywords = keyword && Object.keys(keyword).length;

      return (
        <tr key={key} className="table__row table--worst__row">
          <td className="text--nowrap">
            <Link href={href}>
              {pageId}
            </Link>
          </td>
          <td className="text--nowrap">
            <Link href={href}>
              {name}
            </Link>
          </td>
          <td>{hasKeywords && getChip(key, keyword)}</td>
          <td>{endPosition}</td>
          <td>
            <Change value={positionChange} />
          </td>
        </tr>
      );
    });
  }

  return (
    <Card link={linkRankingsWorst} loading={loading} title="Rankings (Worst)">
      <table className="table table--small m-0" id="table-worst">
        <thead>
          <tr>
            <th>Page ID</th>
            <th>Page</th>
            <th>Keyword</th>
            <th>Position</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
    </Card>
  );
};

export default RankingsWorst;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { saveAs } from 'file-saver';

// Particles
import { ApplicationContext, callGetReportKeywordSERPExport } from 'corigan';
import { brandColours } from 'corigan';
import { dateShort } from 'corigan';
import { getRelease } from 'corigan';
import { getWeeksBetween } from 'corigan';
import { KRContext } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Link , Button} from 'corigan';
import { ChartStackedArea } from 'corigan';
import { Skeleton } from 'corigan';
import { TableSkeleton } from 'corigan';
import { Breadcrumbs } from 'corigan';
import { Card } from 'corigan';
import { Error } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page as PageTemplate } from 'corigan';

// API
import { callGetReportDomainGSC } from 'corigan';
import { callGetReportDomainSERP } from 'corigan';

// Local Components
import StyledDashboard from './dashboard.styles';
import DateRange from './partials/date-range';
import {downloadFileFromUrl} from 'helpers';

const Wrapper = () => {
  return (
    <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`keywords:read`]}>
      <KeywordResearch />
    </ProtectedRoute>
  );
};

type KeywordResearchProps = {};



const KeywordResearch = (props: KeywordResearchProps) => {

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const dispatch = applicationContext?.dispatch;
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.id;
  const hasDomainID: boolean = Boolean(domain);

  const [mutate, { isLoading: mutateLoading }] = useMutation(callGetReportKeywordSERPExport, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

     // queryCache.invalidateQueries([`callGetDomains`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
       const csv: string = data?.data;

       saveAs(csv, "download.csv");

       // downloadFileFromUrl('download.csv', csv);
      // const id = userData?.id;

      // Parameters for single user query
      // const singleWhere: ArgWhere = `[_id][eq]=${id}`;

      // Parameters for inital all users query
      // const mastersheetPerPage = 10;

      // Optimistically update to the new value for mastersheet page
      // queryCache.setQueryData(
      //   [`callGetDomains`],
      //   (previousUsers: APIResponse) => removeSingleCache(previousUsers, id),
      // );

      // // Optimistically update to the new value
      // queryCache.setQueryData(
      //   [`callGetDomains`, { where: singleWhere }],
      //   (previousUsers: APIResponse) => removeSingleCache(previousUsers, id),
      // );

      // // Redirect to directory of users
      // navigate(ROUTES.domains);
    },
  });

  const exportSERP = async () => {
    // If no ID is available, function can't run
    if (!hasDomainID) return;
    if (!windowAvailable()) return;

    try {
      // Use delete Domain hook
      await mutate({ domain });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => await exportSERP();

  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Are you sure you want to export the Report?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    dispatch({ key: `modal`, type: `set`, value });
  };

  return (
    <PageTemplate application="keyword-research">
      <StyledDashboard>
        <Grid>
          <Row>
            <Col>
              <Breadcrumbs className="">
                <Link href={ROUTES.dashboard}>Portal Dashboard</Link>
                <h1>Keyword Research</h1>
                <Button className="breadcrumb__button" onClick={handleClick} disabled={false} variant="hollow">
                  Export
                </Button>
              </Breadcrumbs>
            </Col>
          </Row>
          <SERPReports />
          <GSCReports />
        </Grid>
      </StyledDashboard>
    </PageTemplate>
  );
};

const onSubmit = values => console.info(values);

const positionOptions = [
  { label: `1`, value: 1 },
  { label: `3`, value: 3 },
  { label: `10`, value: 10 },
];

const SERPReports = () => {
  const { releaseVersion } = getRelease();
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.hostname;
  const domainId = domainActive?.id;
  const enabled: boolean = Boolean(domainId);
  const brand = domain?.replace(`www.`, ``)?.split(`.`)?.[0] as BrandName;

  // Context focused on Keyword Research
  const keywordResearchContext = useContext(KRContext);
  const state = keywordResearchContext?.state;
  const chartDateEnd = state?.chartDateEnd?.startOf(`week`);
  const chartDateStart = state?.chartDateStart?.startOf(`week`);
  const hasDates: boolean = Boolean(chartDateEnd && chartDateStart);

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      return `version=${releaseVersion}&key=krDashboard-${key}&domainActive=${domainActive?.id}`;
    },
    [domainActive?.id, releaseVersion],
  );

  // Read local storage values
  const initialPosition: number = 3;
  const localPosition: number = localStorageRead(buildKey(`Position`))
    ? localStorageRead(buildKey(`Position`))
    : initialPosition;

  const initialValues = { position: localPosition };
  // Get the colour of the active domain hostname if available
  const brandingColour = brandColours(brand);

  const formik = useFormik({
    initialValues,
    onSubmit,
  });
  const { handleBlur, handleChange, values } = formik;
  const position = values?.position;

  useEffect(() => {
    const hasWindow = windowAvailable();
    if (!hasWindow) return;

    localStorageSet(buildKey(`Position`), position);
  }, [buildKey, position]);

  const { data: res, isLoading: loading, error } = useQuery(
    [`callGetReportDomainSERP`, { domainId, position }],
    callGetReportDomainSERP,
    { enabled },
  );

  const data = res?.data;
  const hasData: boolean = data?.length > 0;
  const lastIndex: number = data?.length ? data.length - 1 : 0;

  const dateEarliest: string = data?.[0]?.date;
  const dateLatest: string = data?.[lastIndex]?.date;

  const dates = useMemo(() => {
    if (!hasDates) return null;

    const calculated = getWeeksBetween(chartDateStart, chartDateEnd);
    return calculated?.dates;
  }, [hasDates]);

  const series = useMemo(() => {
    if (!hasData) return [];

    let rankingData = data.map(({ date: x, total: y }) => ({ x, y }));

    if (hasDates) {
      rankingData = rankingData.filter(plot => {
        const { x } = plot;
        const dateCompare: Date = new Date(x);
        const dateStart: Date = chartDateStart?.toDate();
        const dateEnd: Date = chartDateEnd?.toDate();

        // true if dateCompare is later than dateStart
        const isAfterStart: boolean = dateCompare > dateStart;

        // true if dateCompare is earlier than dateEnd
        const isBeforeEnd: boolean = dateCompare < dateEnd;

        const isNeeded: boolean = isAfterStart && isBeforeEnd;
        return isNeeded;
      });
    }

    const ranking = [{ name: `Top ${position} Ranking`, data: rankingData }];
    return ranking;
  }, [data, chartDateStart, chartDateEnd, hasData, position]);

  const options: ChartOptions = {
    legend: {
      show: false,
    },
    xaxis: {
      type: `datetime`,
      categories: dates,
    },
    yaxis: {
      reversed: false,
    },
  };

  if (brandingColour) options.colors = [brandingColour];
  const hasRanking: boolean = dates && series?.length > 0;

  return (
    <Row>
      <Col>
        <Card loading={loading} minHeight={false}>
          <header>
            <h2>Number of top {position} positions (SERP)</h2>
            <nav>
              <div className="display--flex align-items--center mr-2">
                <label htmlFor="position">Position</label>
                <select id="position" onBlur={handleBlur} onChange={handleChange} name="position" value={position}>
                  {positionOptions.map(({ label, value }) => (
                    <option key={`${label}-${value}`} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <DateRange dateEarliest={dateEarliest} dateLatest={dateLatest} />
            </nav>
          </header>
          {error && <Error error={error} />}
          {loading && <Skeleton height={300} />}
          {hasRanking && <ChartStackedArea id="top-ranking-urls" height="300px" options={options} series={series} />}
        </Card>
      </Col>
    </Row>
  );
};

declare type SkeletonsProps = {
  id: string;
  count?: number;
};

const Skeletons = ({ id, count = 10 }: SkeletonsProps) => {
  const content = Array(...Array(count)).map((v, i) => {
    const order = i % 2 ? `even` : `odd`;
    const className = `row-skeleton--${order}`;
    return <TableSkeleton key={`tr-skel-${id}-${i}`} className={className} colspan={4} height={50} />;
  });
  return <React.Fragment>{content}</React.Fragment>;
};

declare type KeywordQuery = {
  query: string;
  previousWeek: number;
  currentWeek: number;
  change: number;
  changePercentage: number;
};

declare type Plot = {
  x: string;
  y: number;
};

const GSCReports = () => {
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.hostname;
  const domainId = domainActive?.id;
  const enabled: boolean = Boolean(domainId);
  const brand = domain?.replace(`www.`, ``)?.split(`.`)?.[0] as BrandName;

  // Context focused on Keyword Research
  const keywordResearchContext = useContext(KRContext);
  const state = keywordResearchContext?.state;
  const chartDateEnd = state?.chartDateEnd?.startOf(`week`);
  const chartDateStart = state?.chartDateStart?.startOf(`week`);
  const hasDates: boolean = Boolean(chartDateEnd && chartDateStart);

  // Get the colour of the active domain hostname if available
  const brandingColour = brandColours(brand) as string;

  const { data: res, isLoading: loading, error } = useQuery(
    [`callGetReportDomainGSC`, { domainId }],
    callGetReportDomainGSC,
    { enabled },
  );

  const data = res?.data;
  const totals = data?.totals;
  const hasData: boolean = totals?.length > 0;

  const dates = useMemo(() => {
    if (!hasDates) return null;

    const calculated = getWeeksBetween(chartDateStart, chartDateEnd);
    return calculated?.dates;
  }, [hasDates]);

  const filterByDates = (initialPlots: Plot[]): Plot[] => {
    if (!hasDates) return initialPlots;

    const filteredPlots = initialPlots.filter(plot => {
      const { x } = plot;
      const dateCompare: Date = new Date(x);
      const dateStart: Date = chartDateStart?.toDate();
      const dateEnd: Date = chartDateEnd?.toDate();

      // true if dateCompare is later than dateStart
      const isAfterStart: boolean = dateCompare > dateStart;

      // true if dateCompare is earlier than dateEnd
      const isBeforeEnd: boolean = dateCompare < dateEnd;

      const isNeeded: boolean = isAfterStart && isBeforeEnd;
      return isNeeded;
    });

    const uniqueDatePlots: Plot[] = filteredPlots.reduce((unique, plot) => {
      const { x, y } = plot;

      // Does the plot already exist (duplicate date?)
      const foundDuplicate: Plot = unique.find(uniquePlot => uniquePlot.x === x);

      // If not, then push the plot to the reducer array;
      if (!foundDuplicate) {
        unique.push(plot);
        return unique;
      }

      // Otherwise, is the new plot greater in size than the found duplicate
      const latestIsGreater: boolean = y > foundDuplicate.y;

      // If not, then push the plot to the reducer array;
      if (!latestIsGreater) {
        return unique;
      }

      // Remove the previous item
      unique = unique.filter(p => p.x !== x);

      // Add the larger plot
      unique.push(plot);
      return unique;
    }, []);

    return uniqueDatePlots;
  };

  const clicks: { name: string; data: Plot[] }[] = useMemo(() => {
    if (!hasData) return [];

    const data: Plot[] = totals.map(({ date: x, total_clicks: y }) => ({ x, y }));
    const filteredData = filterByDates(data);
    return [{ name: `Total Clicks`, data: filteredData }];
  }, [chartDateStart, chartDateEnd, hasData, totals]);

  const ctr: { name: string; data: Plot[] }[] = useMemo(() => {
    if (!hasData) return [];

    const data: Plot[] = totals.map(({ date: x, average_ctr: y }) => ({ x, y }));
    const filteredData = filterByDates(data);
    return [{ name: `Average CTR`, data: filteredData }];
  }, [chartDateStart, chartDateEnd, hasData, totals]);

  const impressions: { name: string; data: Plot[] }[] = useMemo(() => {
    if (!hasData) return [];

    const data: Plot[] = totals.map(({ date: x, total_impressions: y }) => ({ x, y }));
    const filteredData = filterByDates(data);
    return [{ name: `Total Impressions`, data: filteredData }];
  }, [chartDateStart, chartDateEnd, hasData, totals]);

  const options: any = {
    legend: {
      show: false,
    },
    xaxis: {
      type: `datetime`,
      categories: dates,
    },
  };

  if (brandingColour) options.colors = [brandingColour];

  const hasClicks: boolean = dates && clicks?.length > 0;
  const hasCTR: boolean = dates && ctr?.length > 0;
  const hasImpressions: boolean = dates && impressions?.length > 0;

  // Table data parsing
  const topQueries = data?.topQueries?.current;
  const hasTopQueries: boolean = topQueries?.length > 0;

  const topMoversUp: KeywordQuery[] = data?.topMovers?.up;
  const topMoversDown: KeywordQuery[] = data?.topMovers?.down;

  return (
    <React.Fragment>
      {error && (
        <Row>
          <Col>
            <Error error={error} />
          </Col>
        </Row>
      )}
      {!error && (
        <React.Fragment>
          <Row>
            <Col xl={4}>
              <Card loading={loading} minHeight={false}>
                <h2>Total Impressions</h2>
                {loading && <Skeleton height={300} />}
                {hasImpressions && (
                  <ChartStackedArea id="total-impressions" height="300px" options={options} series={impressions} />
                )}
                {!loading && !hasImpressions && <p>No impressions data found.</p>}
              </Card>
            </Col>
            <Col xl={4}>
              <Card loading={loading} minHeight={false}>
                <h2>Total Clicks</h2>
                {loading && <Skeleton height={300} />}
                {hasClicks && <ChartStackedArea id="total-clicks" height="300px" options={options} series={clicks} />}
                {!loading && !hasClicks && <p>No clicks data found.</p>}
              </Card>
            </Col>
            <Col xl={4}>
              <CTR brandingColour={brandingColour} dates={dates} ctr={ctr} hasCTR={hasCTR} loading={loading} />
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card loading={loading}>
                <h2>Top Clicked Queries Current Week</h2>
                <div className="table--responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Query</th>
                        <th>Total Clicks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && <Skeletons count={5} id="top-clicks" />}
                      {hasTopQueries &&
                        topQueries.map(q => {
                          const { date, query, total_clicks: tc } = q;

                          return (
                            <tr key={`keyword-move-up-${query}`}>
                              <td>{dateShort(date)}</td>
                              <td className="text--nowrap">{query}</td>
                              <td>{tc}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card loading={loading}>
                <h2>Largest Growing Queries</h2>
                <MoveTable data={topMoversUp} id="top-movers-up" loading={loading} />
              </Card>
            </Col>
            <Col xl={6}>
              <Card loading={loading}>
                <h2>Largest Declining Queries</h2>
                <MoveTable data={topMoversDown} id="top-movers-down" loading={loading} />
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

declare type CTRProps = {
  brandingColour?: string;
  ctr: any;
  dates: string[];
  hasCTR: boolean;
  loading: boolean;
};

const CTR: React.FC<CTRProps> = (props: CTRProps) => {
  const { brandingColour, ctr, dates, hasCTR, loading } = props;

  const percentageLabels = val => val?.toLocaleString(`en`, { style: `percent`, minimumFractionDigits: 2 });

  const optionsCTR: any = {
    legend: {
      show: false,
    },
    xaxis: {
      type: `datetime`,
      categories: dates,
    },
    yaxis: {
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: percentageLabels,
      },
    },
  };

  if (brandingColour) optionsCTR.colors = [brandingColour];

  return (
    <Card loading={loading} minHeight={false}>
      <h2>Average CTR</h2>
      {loading && <Skeleton height={300} />}
      {hasCTR && <ChartStackedArea id="average-ctr" height="300px" options={optionsCTR} series={ctr} />}
      {!loading && !hasCTR && <p>No CTR data found.</p>}
    </Card>
  );
};

declare type MoveTableProps = {
  data: KeywordQuery[];
  id: string;
  loading: boolean;
};

const MoveTable = (props: MoveTableProps) => {
  const { data, id, loading } = props;
  const hasData: boolean = data?.length > 0;

  return (
    <div className="table--responsive">
      <table>
        <thead>
          <tr>
            <th>Query</th>
            <th>Previous Week</th>
            <th>Current Week</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {loading && <Skeletons count={5} id={id} />}
          {hasData &&
            data.map((q: KeywordQuery) => {
              const { query, previousWeek, currentWeek, change, changePercentage } = q;

              let percentage = null;
              if (changePercentage) {
                percentage = changePercentage.toLocaleString(`en`, {
                  style: `percent`,
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                });
              }

              return (
                <tr key={`${id}-${query}`}>
                  <td className="text--nowrap">{query}</td>
                  <td>{previousWeek}</td>
                  <td>{currentWeek}</td>
                  <td>
                    {change} {percentage && `(${percentage})`}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Wrapper;

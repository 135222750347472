import * as React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';

// Particles
import { ApplicationContext } from 'corigan';
import { differencesBetweenObjects } from 'corigan';
import { updateSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Field, Select } from 'corigan';
import { Error, Info } from 'corigan';

// API
import { callUpdateDomain } from 'corigan';

// Localised partials
import DomainContent from './content';

type RequestCompleteProps = {
  data: any;
  loading: boolean;
};

const Request = (props: RequestCompleteProps) => {
  const { data, loading } = props;
  const name: string = data?.[0]?.name;
  const hasData: boolean = data?.length > 0;

  return (
    <DomainContent data={data} loading={loading} title={name}>
      {!loading && hasData && <DomainOverview data={data[0]} />}
    </DomainContent>
  );
};

const successMessage = (name: string): string => {
  return `The site "${name}" has been successfully updated in the system.`;
};

type FormErrors = {
  hostname?: string;
};

type FormValues = {
  id: ArgID;
  hostname: string;
  domainType: string | any[];
  region: string;
  competesWith?: ArgID;
};

type DomainOverviewProps = {
  data: Domain;
};


declare type SelectArgs = {
  event: any;
  field: string;
  setFieldValue: any;
};

const selectChange = (args: SelectArgs): void => {
  const { event, field, setFieldValue } = args;
  const noValueProvided = !event || !field;
  if (noValueProvided) setFieldValue([]);
  setFieldValue(field, event);
};

const DomainOverview: React.FC<DomainOverviewProps> = (props: DomainOverviewProps) => {
  const { data } = props;
  const { id, hostname, competesWith} = data;
  const queryCache = useQueryCache();

  const region = data?.region || ``;
  const domainType = data?.domainType || `primary`;

  const { userHasPermission: canUpdateUser } = useHasPermissions({ requiredPermissions: [`domains:update`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  const [success, setSuccess] = useState<string>(null);

  const initialValues = {
    id,
    hostname,
    region,
    domainType: [{label: domainType, value: domainType}],
    competesWith,
  };

  const [mutate, { error, isLoading: loading }] = useMutation(callUpdateDomain, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetDomains`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      // If updateUser function was successful, get values from API response
      const domainData: Domain = data?.data;
      const id = domainData?.id;
      const latestName = domainData?.hostname;

      // Parameters for single domain query
      const singleWhere: ArgWhere = `[_id][eq]=${id}`;
      const whereProtected: ArgWhere = domainActive ? `[competesWith][eq]=${domainActive?.id}` : ``;

      queryCache.invalidateQueries([`callGetDomains`]);

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetDomains`, { where: singleWhere, whereProtected }],
        (previousDomains: Domain[]) => updateSingleCache(previousDomains, domainData),
      );

      // Let the domain know the page has been updated and update UI
      setSuccess(successMessage(latestName));
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (windowAvailable() && !canUpdateUser) window.alert(`You do not have permission to update a site`);
      values.domainType = values.domainType[0].value;
      if (canUpdateUser) await mutate(values);
    },
    validate: (values: FormValues) => {
      const { hostname } = values;

      const errors: FormErrors = {};
      if (!hostname) errors.hostname = `The domain must have a name`;
      return errors;
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  const { errors, touched, values, handleChange, handleBlur, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const typeOptionsChange = event => {
    selectChange({
      event,
      field: `domainType`,
      setFieldValue,
    });
  };

  const typeOptions = [
    {
      label: `Primary`,
      value: `primary`,
    }, {
      label: `Secondary`,
      value: `secondary`,
    }
  ];

  const isBusy: boolean = isSubmitting || loading;
  const isLocked: boolean = !canUpdateUser || isBusy;

  return (
    <section className="domain__update">
      <h2>Update site information</h2>
      <form onSubmit={handleSubmit} aria-label="Update a User">
        <fieldset className="mb-0" aria-busy={isBusy} disabled={isLocked}>
          {success && (
            <Info onClose={onClose} y="lg">
              {success}
            </Info>
          )}
          {error && <Error error={error}>{error}</Error>}
          <Field {...formikHelpers} id="hostname" label="Hostname" required={true} />

          <label htmlFor="domainType">Site Type</label>
          <Select
            id="domainType"
            name="domainType"
            onChange={typeOptionsChange}
            options={typeOptions}
            value={values.domainType}
            isMulti={false}
            disabled={isLocked}
          />

          <Field
            id="region"
            label="Region code if required"
            handleChange={handleChange}
            type="text"
            value={values.region}
            required={false}
            disabled={isLocked}
          />

          <button type="submit" disabled={isSubmitting}>
            Updat{isSubmitting ? `ing` : `e`} Site
          </button>
        </fieldset>
      </form>
    </section>
  );
};

export default Request;

// Create global application reducer to handle state changes
export const applicationReducer = (state, action) => {
  const { key, type, value } = action;

  // Create a copy of state to prevent any chance of mutating
  const stateCopy = { ...state };

  if (type == null) {
    console.error(`No action type provided to application reducer`);
    return;
  }

  switch (type) {
    case `set`: {
      if (key == null) {
        console.error(`No action key provided to 'set' action`);
        return;
      }

      const newState = { ...stateCopy };
      newState[key] = value;

      // If the value being set is the active domain, set it to localStorage too
      if (key === `domainActive` && value) localStorage.setItem(`domainActive`, JSON.stringify(value));

      return newState;
    }

    case `refresh`: {
      const newState = { ...stateCopy };
      newState.refresh = newState.refresh + 1;
      return newState;
    }

    default: {
      return stateCopy;
    }
  }
};

export default applicationReducer;

import { fetchAPI } from 'particles';

type CreateTeamParameters = {
  domains: Domain[];
  name: ArgName;
};

export const callCreateTeam = async (parameters: CreateTeamParameters): Promise<{ data: Team }> => {
  const response = await fetchAPI({ method: `POST`, parameters, route: `teams` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreateTeam;

import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';
import { Col, Row } from 'corigan';

// Localised partials
import Content from './content';

const MissingID = () => {
  const message = `Unfortunately we could not find a crawl as no ID was provided`;

  return (
    <Content data={null} title={`Sorry, we couldn't find that crawl`}>
      <Row>
        <Col>
          <Error error={message} />
          <p>
            Please return to the <Link href={ROUTES.crawls}>all crawls</Link> page.
          </p>
        </Col>
      </Row>
    </Content>
  );
};

export default MissingID;

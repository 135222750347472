import * as React from 'react';

import StyledWrapper from './wrapper.styles';

type WrapperProps = {
  /**
   * The primary content of the Wrapper component
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: any;
};

const Wrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
  const { children, className } = props;
  let classList = `wrapper`;
  if (className) classList += ` ${className}`;

  return <StyledWrapper className={classList}>{children}</StyledWrapper>;
};

export default Wrapper;

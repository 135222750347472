import * as React from 'react';

function SvgTrash(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M11-.031c-.836 0-1.656.164-2.25.75-.594.586-.781 1.418-.781 2.281H4c-.55 0-1 .45-1 1H2v2h22V4h-1c0-.55-.45-1-1-1h-3.969c0-.863-.187-1.695-.781-2.281-.594-.586-1.414-.75-2.25-.75zm0 2.062h4c.547 0 .719.13.781.188.063.058.188.222.188.781H10.03c0-.559.125-.723.188-.781.062-.059.234-.188.781-.188zM4 7v16c0 1.652 1.348 3 3 3h12c1.652 0 3-1.348 3-3V7zm4 3h2v12H8zm4 0h2v12h-2zm4 0h2v12h-2z" />
    </svg>
  );
}

export default SvgTrash;

import { fetchAPI } from 'particles';

type CreateKeywordParameters = {
  keywordId: ArgKeywordId;
  phrase: ArgPhrase;
  tags?: ArgTags;
  url: ArgURL;
  highPriority?: boolean;
  domain: string;
};

export const callCreateKeyword = async (parameters: CreateKeywordParameters): Promise<{ data: Keyword }> => {
  const response = await fetchAPI({ method: `POST`, parameters, route: `keywords` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callCreateKeyword;

import * as React from 'react';

function SvgWeekend(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M21.8 9.8c-1.2 0-2.2 1-2.2 2.2v3.3H4.4V12c0-1.2-1-2.2-2.2-2.2S0 10.8 0 12v5.5c0 1.2 1 2.2 2.2 2.2h19.6c1.2 0 2.2-1 2.2-2.2V12c0-1.2-1-2.2-2.2-2.2zm-3.3-5.4h-13c-1.2 0-2.2 1-2.2 2.2v2.3c1.3.4 2.2 1.7 2.2 3.1v2.2h13.1V12c0-1.4.9-2.6 2.2-3.1V6.5c-.1-1.2-1.1-2.1-2.3-2.1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgWeekend;

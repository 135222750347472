import * as React from 'react';

// Particles
import { ROUTES } from 'corigan';
import { slugify } from 'corigan';

// Components
import { Skeleton } from 'corigan';
import { Statistic } from 'corigan';

// Localised partials
import StyledCrawlStatistics from './crawl-statistics.styles';

declare type CrawlStatisticsProps = {
  crawl: Crawl;
  loading?: boolean;
};

export interface CSSProperties extends React.CSSProperties {
  '--rows'?: number;
}

const statisticsWhere = {
  'total-changes': `&where[changeSignificance][ne]=info`,
  'total-urls': ``,
  'http-200': `&where[statusCode][gte]=200&where[statusCode][lt]=300`,
  'http-300': `&where[statusCode][gte]=300&where[statusCode][lt]=400`,
  'http-400': `&where[statusCode][gte]=400&where[statusCode][lt]=500`,
  'http-500': `&where[statusCode][gte]=500&where[statusCode][lt]=600`,
  'new-urls': `&where[changeSignificance][eq]=new`,
  'removed-urls': `&where[changeSignificance][eq]=removed`,
  'high-significance': `&where[changeSignificance][eq]=high`,
  'medium-significance': `&where[changeSignificance][eq]=medium`,
};

const statisticsTooltips = {
  'high-significance': `A redirect or change that may yield a high performance impact`,
  'medium-significance': `A redirect or change that may yield a medium performance impact`,
};

const CrawlStatistics = (props: CrawlStatisticsProps) => {
  const { crawl, loading = false } = props;
  const id = crawl?.id;
  const isFirstCrawl = crawl?.isFirstCrawl;
  const statistics = crawl?.statistics;

  const formatted = React.useMemo(() => {
    const hasData: boolean = statistics?.length > 0;
    if (!hasData) return [];

    const namedStatistics = statistics.filter(val => {
      const hasName = Boolean(val?.name);
      return hasName;
    });
    const hasNamedStatistics: boolean = namedStatistics.length > 0;
    if (!hasNamedStatistics) return [];

    const changeLabel: string = isFirstCrawl ? `First crawl, no comparison data available` : `than prev crawl`;

    const formatted = namedStatistics.map(val => {
      let tooltip = null;
      let href: string = `${ROUTES.crawl_details}?id=${id}`;
      const figure: number = Math.ceil(Number(val?.value)) || 0;
      const hasPercentage: boolean = Boolean(val?.changePercentage);
      const showChange: boolean = !isFirstCrawl && hasPercentage;
      const change = showChange ? val.changePercentage * 100 : null;

      const lowerName = val?.name?.toLowerCase();
      const slugName = slugify(lowerName);

      const hasWhereToAdd: boolean = statisticsWhere.hasOwnProperty(slugName);
      if (hasWhereToAdd) href += statisticsWhere[slugName];

      const hasTooltipToAdd: boolean = statisticsTooltips.hasOwnProperty(slugName);
      if (hasTooltipToAdd) tooltip = statisticsTooltips[slugName];

      const result = {
        change,
        changeLabel,
        figure,
        href,
        label: val?.name,
        ...(tooltip && { tooltip }),
      };
      return result;
    });
    return formatted;
  }, [statistics]);

  const hasFormatted: boolean = formatted?.length > 0;
  if (!hasFormatted && loading) return <Skeletons />;
  if (!hasFormatted) return null;

  const style = { '--rows': 5 } as CSSProperties;

  return (
    <StyledCrawlStatistics style={style}>
      {hasFormatted && formatted.map(stat => <Statistic {...stat} key={`${stat?.label}-${stat?.figure}`} />)}
    </StyledCrawlStatistics>
  );
};

const Skeletons = () => (
  <>
    <Skeleton height={150} />
    <Skeleton className="mt-2" height={150} />
  </>
);

export { CrawlStatistics };
export default CrawlStatistics;

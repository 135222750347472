import { fetchAPI } from 'particles';

type GetSistrixMarketArguments = {
  date: string;
  dateCompare?: string;
  domainId: ArgID;
  monthOnMonth?: boolean;
};

declare type SistrixValue = {
  date: string;
  path: string;
  value: number;
  __v: number;
  name: string;
  sortOrder?: number;
  changePercentage: number;
  previous: {
    date: string;
    value: number;
    __v: number;
  };
};

declare type SistrixValueCompetitor = SistrixValue & {
  domainType?: 'primary';
};

declare type GetSistrixMarketResponse = {
  data: {
    primary: SistrixValue[];
    competitors: SistrixValueCompetitor[];
    subfolders: SistrixValue[];
    browse: SistrixValue[];
  };
};

export const callGetSistrixMarket = async (
  reqKey,
  parameters: GetSistrixMarketArguments,
): Promise<GetSistrixMarketResponse> => {
  const response = await fetchAPI({ method: `GET`, parameters, route: `sistrix/market` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetSistrixMarket;

import * as React from 'react';

function SvgPageview(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M11.4 8.4c-1.6 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3zm10.1-5.9h-19C1.2 2.5.1 3.6.1 4.9v14.3c0 1.3 1.1 2.4 2.4 2.4h19.1c1.3 0 2.4-1.1 2.4-2.4V4.8c-.1-1.3-1.1-2.3-2.5-2.3zm-3.8 16.9l-3.5-3.5c-.8.5-1.8.8-2.9.8-3 0-5.4-2.4-5.4-5.4S8.4 6 11.4 6s5.4 2.4 5.4 5.4c0 1-.3 2-.8 2.9l3.5 3.5c-.1-.1-1.8 1.6-1.8 1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPageview;

import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { navigate } from 'gatsby-link';

// Icons
import { Promoted } from 'icons';

// Particles
import { ApplicationContext } from 'corigan';
import ROUTES from 'routes';
import { addSingleCache } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';
import { useQueryParameters } from 'corigan';
import { isString } from 'corigan';

// Atoms
import { Field } from 'corigan';
import { Link } from 'corigan';
import { Select } from 'corigan';
import { Toggle } from 'corigan';

// Molecules
import { Breadcrumbs } from 'corigan';
import { Card } from 'corigan';
import { Error } from 'corigan';
import { Info } from 'corigan';

// Organisms
import { Col } from 'corigan';
import { Grid } from 'corigan';
import { Row } from 'corigan';

// Templates
import { Page } from 'corigan';

// API
import { callCreateKeyword } from 'corigan';
import { callGetManyTags } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.keyword_manager} requiredPermissions={[`keywords:create`]}>
    <KeywordManagerCreate />
  </ProtectedRoute>
);

type KeywordProps = {};

const KeywordManagerCreate = (props: KeywordProps) => {
  const queryParameters = useQueryParameters();
  const { phrase: phraseQuery, url: urlQuery } = queryParameters;

  const { userHasPermission: canReadTags } = useHasPermissions({ requiredPermissions: [`tags:read`] });
  const { userHasPermission: canCreateKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:create`],
  });
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // Fetch tags info
  const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
  const { data: res, error, isLoading: loading } = useQuery([`callGetManyTags`, { perPage: 1000, whereProtected }], callGetManyTags, {
    enabled: canReadTags,
  });
  const tags = res?.data || [];

  return (
    <Page application="keyword-manager" pageTitle="Create Keyword">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.keyword_manager}>All Keywords</Link>
              <h1>Create Keyword</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card loading={loading}>
              {!canCreateKeyword && (
                <React.Fragment>
                  <Error error="You do not have permission to create a keyword" />
                  <Link href={ROUTES.keyword_manager}>Return to all keywords</Link>
                </React.Fragment>
              )}
              {error && <Error error={error} />}
              {canCreateKeyword && <CreateKeyword tagsArray={tags} phraseParam={phraseQuery} urlParam={urlQuery} />}
            </Card>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

const selectChange = ({ event, field, setFieldValue }) => {
  const noValueProvided = !event || !field;
  if (noValueProvided) setFieldValue([]);
  setFieldValue(field, event);
};

const successMessage = keyword => {
  return `The keyword "${keyword}" has been successfully created in the system.`;
};

type FormErrors = {
  phrase?: ArgPhrase;
  url?: ArgURL;
};

type FormValues = {
  keywordId: ArgKeywordId;
  phrase: ArgPhrase;
  url: ArgURL;
  tags?: [];
  highPriority: boolean;
};

const buildTagObjects = (tagsArray: Tag[]) => {
  return tagsArray.map(({ id, name }) => ({ label: name, value: id }));
};

type CreateKeywordProps = {
  tagsArray: Tag[];
  phraseParam?: any;
  urlParam?: any;
};

const CreateKeyword = (props: CreateKeywordProps) => {
  const { tagsArray, phraseParam, urlParam } = props;
  const queryCache = useQueryCache();

  const { userHasPermission: canCreateKeyword } = useHasPermissions({
    requiredPermissions: [`keywords:create`],
  });

  const [success, setSuccess] = useState(null);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const hostname = domainActive?.hostname;
  const prefix: string = `https://` + hostname + `/`;

  const tagOptions = useMemo(() => {
    if (!tagsArray) return [];
    const formattedTags = buildTagObjects(tagsArray);
    return formattedTags;
  }, [tagsArray]);

  const createKeyword = async values => {
    // Check their permission level
    if (!windowAvailable()) return;
    if (!canCreateKeyword) {
      window.alert(`You do not have permission to create a keyword`);
      return;
    }

    const { keywordId, phrase, url, tags: tagsArray, highPriority } = values;
    let tags = undefined;

    // If no keywordId, phrase or URL is available, function can't run
    if (!keywordId) {
      console.error(`No keyword ID provided`);
      return;
    }

    if (!phrase) {
      console.error(`No phrase provided`);
      return;
    }

    if (!url) {
      console.error(`No phrase provided`);
      return;
    }

    if (tagsArray) tags = tagsArray.map(t => t.value);
    return await callCreateKeyword({ keywordId, phrase, url, tags, highPriority, domain: domainActive.id });
  };

  const [mutate, { error, isLoading: loading }] = useMutation(createKeyword, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyKeywords`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const _with: ArgWith = [`tags`];
      const whereProtected: ArgWhere = `[domain][eq]=${domainActive?.id}`;
      const keywordData: Keyword = data?.data;
      const id = keywordData?.id;
      const phrase = keywordData?.phrase;

      // Let the user know the keyword has been created before redirect begins
      setSuccess(successMessage(phrase));

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`], (previousKeywords: APIResponse) =>
        addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 10 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 25 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 50 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetManyKeywords`, { _with, whereProtected, perPage: 100 }],
        (previousKeywords: APIResponse) => addSingleCache(previousKeywords, keywordData),
      );

      // Redirect to the newly created Keyword
      const newKeywordURL = `${ROUTES.keyword}?id=${id}`;
      navigate(newKeywordURL);
    },
  });

  const initialValues: FormValues = {
    keywordId: isString(phraseParam) ? String(phraseParam) : ``,
    phrase: isString(phraseParam) ? String(phraseParam) : ``,
    url: isString(urlParam) ? String(urlParam) : ``,
    tags: [],
    highPriority: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      // If Keyword ID is null, set it as the phrase
      values.keywordId = values.keywordId || values.phrase;

      await mutate({ ...values })
    },
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { phrase, url } = values;
      if (!phrase) errors.phrase = `Please specify a keyword`;
      if (!url) errors.url = `Please specify a URL`;
      return errors;
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };
  const { keywordId, phrase, tags, highPriority } = values;

  const handleToggleChange = () => {
    setFieldValue(`highPriority`, !highPriority);
  };

  const onSelectChange = event => {
    selectChange({
      event,
      field: `tags`,
      setFieldValue,
    });
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const onURLChange = e => {
    const value: string = e.target.value;
    let newFieldValue = value;

    const valid = newFieldValue?.startsWith(prefix);
    if (!valid) newFieldValue = prefix;

    setFieldValue(`url`, newFieldValue);
  };

  useEffect(() => {
    if (!urlParam) {
      setFieldValue(`url`, prefix);
    }
  }, [prefix, urlParam, setFieldValue]);

  const isBusy: boolean = isSubmitting || loading;
  const locked: boolean = !canCreateKeyword || isBusy;

  let buttonText: string = `Create Keyword`;
  if (isBusy) buttonText = `Creating Keyword`;

  const PromotedLabel = (
    <React.Fragment>
      <Promoted />
      {highPriority ? `Promoted` : `Promote`}
    </React.Fragment>
  );

  return (
    <form onSubmit={handleSubmit} aria-label="Create a Keyword" className="form--trimmed">
      <fieldset aria-busy={locked} disabled={locked}>
        {success && (
          <Info onClose={onClose} y="lg">
            {success}
          </Info>
        )}
        {error && <Error error={error}>{error}</Error>}
        <Field handleChange={handleChange} id="keywordId" label="Keyword ID" required={false} value={keywordId} />
        <Field handleChange={handleChange} id="phrase" label="Keyword" required={true} value={phrase} />
        <Field {...formikHelpers} handleChange={onURLChange} id="url" label="URL" required={true} type="url" />
        <label htmlFor="tags">Tags</label>
        <Select disabled={locked} onChange={onSelectChange} options={tagOptions} value={tags} />
        <div className="display--block my-1">
          <Toggle id="promoted" label={PromotedLabel} onChange={handleToggleChange} on={highPriority} />
        </div>
        <button type="submit">{buttonText}</button>
      </fieldset>
    </form>
  );
};

export default Wrapper;

import * as React from 'react';

function SvgChevronDown(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
      <path d="M0 0h24v24H0V0z" fill="none" />
    </svg>
  );
}

export default SvgChevronDown;

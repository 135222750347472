import styled from 'styled-components';
import { device } from 'particles';

const StyledHistory = styled.nav`
  display: flex;
  flex-direction: column;

  @media ${device?.xl} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  input {
    font-size: 16px;
  }

  nav {
    align-items: flex-start;
    display: flex;
  }

  select {
    flex-grow: 0;
    width: auto;
  }

  .tooltip-root {
    margin-left: 4px;
  }

  .history__controls {
    align-items: center;
    display: flex;
    padding: 8px 16px;

    background-color: var(--grey100);

    > * + * {
      margin-left: 24px;
    }
  }

  .history__phrase {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;

    a {
      margin-left: 8px;
    }

    h2 {
      margin-bottom: 0;
    }
  }
`;

const StyledCharts = styled.section`
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: visible;
  padding-bottom: 16px;

  foreignObject {
    overflow: visible;
  }

  svg {
    overflow: visible;
  }

  .apexcharts-legend {
    transform: translateY(2px);
  }
`;

export { StyledCharts };
export { StyledHistory };

export default StyledHistory;

import * as React from 'react';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';

// Particles
import { ApplicationContext } from 'corigan';
import { OMContext } from 'corigan';
import { removeSingleCache } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// API
import { callDeletePageRevision } from 'corigan';

type DeleteRevisionProps = {
  id: string;
  page: string;
};

const DeleteRevision = (props: DeleteRevisionProps) => {
  const { id, page } = props;
  const queryCache = useQueryCache();

  const appContext: ApplicationContextProps = useContext(ApplicationContext);
  const appDispatch: any = appContext?.dispatch;

  const omContext: OMContextProps = useContext(OMContext);
  const omDispatch: any = omContext?.dispatch;

  const { userHasPermission: canDelete } = useHasPermissions({ requiredPermissions: [`pages:delete`] });

  const [mutate, { isLoading: loading }] = useMutation(callDeletePageRevision, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);
      omDispatch({ type: `set`, key: `error`, value: err });
      queryCache.invalidateQueries([`callGetManyPageRevisions`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      // If callDeletePageRevision function was successful, get values from API response
      const { id } = variables;

      // These are the same parameters used in the revisions table query
      const perPage = 1000;
      const _with = [`createdBy`];

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetOnePageRevisions`, { drafts: true, id: page, perPage, _with }],
        (previousRevisions: APIResponse) => removeSingleCache(previousRevisions, id),
      );

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`], (previousRevisions: APIResponse) =>
        removeSingleCache(previousRevisions, id),
      );

      omDispatch({ type: `set`, key: `error`, value: null });
    },
  });

  if (!canDelete) return null;

  const deletePage = async () => {
    // If no ID is available, function can't run
    if (!id) {
      console.error(`No ID provided to deletePage function`);
      return;
    }

    // Confirm the user has permission to delete
    if (!windowAvailable()) return;
    if (!canDelete) {
      window.alert(`You do not have permission to pages.`);
      return;
    }

    try {
      // Process delete mutaiton
      await mutate({ id });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => await deletePage();

  const handleClick = async e => {
    if (e) e.preventDefault();
    const confirmText: string = `Deleting a page revision is permanent, are you sure you want to delete this page revision?`;
    const value = { handleConfirm, isOpen: true, message: confirmText };
    appDispatch({ key: `modal`, type: `set`, value });
  };

  let buttonText: string = `Delete`;
  if (loading) buttonText = `Deleting`;

  const locked: boolean = !canDelete || loading;
  if (!canDelete) return null;

  return (
    <button className="button--delete" disabled={locked} onClick={handleClick}>
      {buttonText}
    </button>
  );
};

export default DeleteRevision;

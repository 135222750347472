import * as React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { useContext } from 'react';

// Particles
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';
import { parseCSV } from 'corigan';
import { addCollectionCache } from 'corigan';
import { ApplicationContext } from 'corigan';

// Components
import { Link, Upload } from 'corigan';
import { Breadcrumbs, Card, Error, Info } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callCreateManyKeywords } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.keyword_manager} requiredPermissions={[`keywords:create`, `pages:approve`]}>
    <KeywordManagerImport />
  </ProtectedRoute>
);

type KeywordProps = {};

const KeywordManagerImport = (props: KeywordProps) => {
  return (
    <Page application="keyword-manager" pageTitle="Import Keywords">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.keyword_manager}>All Keywords</Link>
              <h1>Import Keywords</h1>
              <p>Import file must be a CSV (with headers) contain at least 2 columns (phrase, url).</p>
              <p>If you wish to promote a keyword please add a column called highPriority and use true or false to set promotion status, see example for details. <a href={`/csv/keyword-import-example.csv`} download={`/csv/keyword-import-example.csv`}>Download example CSV</a></p>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <ImportKeywords />
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

type ImportKeywordsProps = {};

type KeywordFormat = {
  excludedFromCategories?: Category[];
  highPriority?: ArgHighPriority;
  phrase: ArgPhrase;
  priorityCategory?: Category;
  uplift?: ArgUplift;
  tags?: ArgTags;
  url: ArgURL;
};

const SECOND = 1000;

const perPage: ArgPerPage = 10;
const _with: ArgWith = [`tags`];

const ImportKeywords = (props: ImportKeywordsProps) => {
  const [success, setSuccess] = useState(false);
  const queryCache = useQueryCache();
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.id;

  const [mutate, { error, isLoading: loading }] = useMutation(callCreateManyKeywords, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyKeywords`, { perPage, _with }]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      // If callCreateManyKeywords function was successful, get values from API response
      const keywords: Keyword[] = data?.data;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyKeywords`, { perPage, _with }], (previousKeywords: APIResponse) =>
        addCollectionCache(previousKeywords, keywords),
      );

      // Redirect to keywords directory after 3 seconds
      setSuccess(true);
      setTimeout(() => {
        navigate(ROUTES.keyword_manager);
      }, 3 * SECOND);
    },
  });

  const onMediaSet = async files => {
    const hasFile = files?.length > 0;
    if (!hasFile) return;

    // Destructure first file from array of files (Our CSV)
    const [file] = files;

    // Parse the CSV to an array
    const keywords: any | KeywordFormat[] = await parseCSV(file);
    const hasKeywords = keywords?.length > 0;
    if (!hasKeywords) return;

    const safeKeywords = keywords.map(
      ({ excludedFromCategories, highPriority, phrase, priorityCategory, uplift, tags, url }) => ({
        ...(excludedFromCategories && { excludedFromCategories }),
        ...(highPriority && { highPriority }),
        ...(phrase && { phrase }),
        ...(priorityCategory && { priorityCategory }),
        ...(uplift && { uplift }),
        ...(tags && { tags }),
        ...(url && { url })
      }),
    );

    // Send the parsed content to the create many keywords route
    await mutate({  keywords: safeKeywords, domain });
  };

  const onClose = e => {
    e.preventDefault();
    setSuccess(null);
  };

  return (
    <Card loading={loading}>
      <section>
        {error && <Error error={error} />}
        {success && (
          <Info onClose={onClose}>Keywords have successfully been imported, redirecting to mastersheet view</Info>
        )}
        <Upload disabled={loading} label={false} large={true} name="keywords" onMediaSet={onMediaSet} buttonLabel={`Choose file`} buttonLabelMultiple={`Choose file`} confirmText={`You are about to import multiple keywords. Please check the data in your import file`}>
          Drag and drop a CSV file here, or click to use a file picker
        </Upload>
      </section>
    </Card>
  );
};

export default Wrapper;

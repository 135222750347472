import { css } from 'styled-components';

const chips = css`
  .chip-spacer {
    > * {
      display: inline-block;

      margin-bottom: 6px !important;
      margin-right: 4px !important;
      margin-top: initial;
    }
  }

  .chip-spacer__response-code {
    > * {
      display: inline-block;

      margin-bottom: 10px !important;
      margin-right: 12px !important;
      margin-top: initial;
    }
  }
`;

export default chips;

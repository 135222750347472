import * as React from 'react';

import StyledGapGroup from './gap-group.styles';

export interface CSSProperties extends React.CSSProperties {
  gap?: number;
}

type GapGroupProps = {
  /**
   * The element in which to position the Toggle relative to
   */
  children: any;
  className?: string;
} & CSSProperties;

const GapGroup = (props: GapGroupProps) => {
  const { children, className, gap } = props;
  let classList = `gap-group`;
  if (className) classList += ` ${className}`;

  const style = { '--gap': `${gap}px` } as CSSProperties;

  return (
    <StyledGapGroup className={classList} style={style}>
      {children}
    </StyledGapGroup>
  );
};

GapGroup.defaultProps = {
  gap: 12,
};

export default GapGroup;

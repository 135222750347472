import { css } from 'styled-components';
import { hexToRGB } from 'helpers';

const handleHex = (hex, opaque = true) => {
  const rgb = hexToRGB(hex);
  return `rgba(${rgb}, ${opaque ? `0.2` : `0.7`})`;
};

export const actions = css`
  .editor__toolbar--static {
    display: flex;
    margin-bottom: 8px;
    width: calc(100% + 24px);

    transform: translateX(-12px) translateY(-8px);

    button,
    input {
      align-items: center;
      display: inline-flex;
      flex-grow: 0;
      justify-content: center;
      min-height: 0px;
      padding: 12px;
      width: auto;

      background-color: transparent;
      text-align: center;

      span {
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        position: absolute;
        width: 1px;

        border: 0;
      }
    }

    button {
      &:hover {
        background-color: ${props => handleHex(props.theme.blue100, true)};

        svg {
          fill: var(--blue);
        }
      }
    }

    input {
      max-width: 40px;
      padding: 0px;
      cursor: default;
      font-size: 20px;
      font-weight: 700;

      &:hover {
        background-color: transparent;
      }
    }

    nav {
      align-items: center;
      display: inline-flex;
      justify-content: flex-start;
    }

    nav + nav {
      margin-left: auto;
    }
  }

  .editor__font-size--locked {
    color: var(--grey400);
  }

  .editor__link-button--active,
  .editor__save-button--active {
    path {
      fill: var(--blue);
    }
  }

  .editor__link-all-button {
    g {
      path {
        fill: #bfbfbf;
      }
    }
  }
`;

export default actions;

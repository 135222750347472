import * as React from 'react';

// Particles
import ROUTES from 'routes';

// Components
import { Link } from 'corigan';
import { Error } from 'corigan';

// Localised partials
import Content from './content';

const MissingID = () => {
  const message = `Unfortunately we could not find a domain as no ID was provided`;

  return (
    <Content data={null} title={`Sorry, we couldn't find that domain`}>
      <Error error={message} />
      <ReturnLink />
    </Content>
  );
};

const ReturnLink = () => (
  <p>
    Please return to the <Link href={ROUTES.domains}>full list of domains</Link>
  </p>
);

export default MissingID;

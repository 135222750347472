import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useQueryCache } from 'react-query';

// Particles
import { updateSingleCache } from 'corigan';
import { ROUTES } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { callGetManyPageTypes } from 'corigan';
import { callUpdatePageType } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Link, Field } from 'corigan';
import { Breadcrumbs, Error, Info } from 'corigan';
import { Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

const PageTypes = () => (
  <ProtectedRoute redirect={ROUTES.dashboard} requiredPermissions={[`pagetypes:read`]}>
    <Page application="portal" pageTitle="Page Types">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.dashboard}>Dashboard</Link>
              <h1>Page Types</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            <ManageTypes />
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

type ManageTypesFormValues = {
  pageType: any[];
};

const initialValues: ManageTypesFormValues = {
  pageType: undefined,
};

const onSubmit = values => console.info(values);

declare type ManageTypesProps = {};

const ManageTypes: React.FC<ManageTypesProps> = (props: ManageTypesProps) => {
  const [success, setSuccess] = useState<string>(null);

  const { userHasPermission: canRead } = useHasPermissions({ requiredPermissions: [`pagetypes:read`] });

  const { data: res, error, isLoading: loading } = useQuery([`callGetManyPageTypes`], callGetManyPageTypes, {
    enabled: canRead,
  });
  const pageTypes = res?.data;
  const hasTypes: boolean = pageTypes?.length > 0;

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const { handleChange, handleBlur, setFieldValue, values } = formik;
  const { pageType } = values;

  useEffect(() => {
    if (!hasTypes) return;
    if (setFieldValue) setFieldValue(`pageType`, pageTypes[0]?.id);
  }, [hasTypes, pageTypes, setFieldValue]);

  const queryCache = useQueryCache();

  const [mutate, { error: mutateError, isLoading: mutateLoading }] = useMutation(callUpdatePageType, {
    // When mutate is called:
    onMutate: () => {
      setSuccess(null);
    },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyPageTypes`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const pageTypeData: PageType = data?.data;
      // If updateTag function was successful, get values from API response
      const latestName = pageTypeData?.name;
      // Let the user know the tag has been updated and update UI
      setSuccess(`The page type "${latestName}" has been updated in the system`);

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageTypes`], (previousPageTypes: APIResponse) => {
        return updateSingleCache(previousPageTypes, pageTypeData);
      });
    },
  });

  const activeType: PageType = pageTypes?.find(p => p.id === pageType);
  const activeTypeName: string = activeType?.name;
  const isBusy: boolean = loading || mutateLoading;

  const onClose = (e): void => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  useEffect(() => {
    document.title = `Corigan - Portal: Page Types - ${activeTypeName}`;
  }, [activeTypeName]);

  return (
    <Card loading={isBusy} minHeight={false}>
      {error && <Error error={error} />}
      {mutateError && <Error error={mutateError} />}
      {success && (
        <Info onClose={onClose} y="lg">
          {success}
        </Info>
      )}
      <label htmlFor="pageType" className="mt-0">
        Page Type
      </label>
      {hasTypes && (
        <select id="pageType" onBlur={handleBlur} onChange={handleChange} name="pageType" value={values?.pageType}>
          {pageTypes.map(({ id, name }) => (
            <option key={`${name}-${id}`} value={id}>
              {name}
            </option>
          ))}
        </select>
      )}
      {activeType && <UpdatePageType {...activeType} mutate={mutate} />}
    </Card>
  );
};

type FormErrors = {
  name?: string;
};

type FormValues = {
  name: string;
};

declare type UpdatePageTypeProps = {
  id: string;
  mutate: any;
  name: string;
};

const UpdatePageType: React.FC<UpdatePageTypeProps> = (props: UpdatePageTypeProps) => {
  const { id, mutate, name } = props;
  const initialValues: FormValues = { name };

  const { userHasPermission: canUpdate } = useHasPermissions({ requiredPermissions: [`pagetypes:update`] });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async values => {
      // If no ID is available, function can't run
      if (!id) return;

      // Check they have permission to update the page type
      if (windowAvailable() && !canUpdate) window.alert(`You do not have permission to update page types`);
      if (!canUpdate) return;

      // Process delete mutaiton
      await mutate({ ...values, id });
    },
    validate: values => {
      const errors: FormErrors = {};
      const { name } = values;
      if (!name) errors.name = `A page type is required`;
      return errors;
    },
  });

  const { errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  let buttonText: string = `Update`;
  if (isSubmitting) buttonText = `Updating`;

  const disabled: boolean = !canUpdate || isSubmitting;

  return (
    <form onSubmit={handleSubmit} aria-label="Update a Page Type">
      <fieldset aria-busy={isSubmitting} disabled={disabled}>
        <Field {...formikHelpers} id="name" label="Name" required={true} />
        <button type="submit">{buttonText}</button>
      </fieldset>
    </form>
  );
};

export default PageTypes;

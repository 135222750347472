import { fetchAPI } from 'particles';

type GetKeywordSuggestionsParameters = {
  id: ArgID;
  pageNumber?: number;
  perPage?: ArgPerPage;
  _with?: ArgWith;
};

export const callGetKeywordSuggestions = async (queryKey, parameters: GetKeywordSuggestionsParameters) => {
  let append;
  if (parameters?.id) append = `keywordSuggestions`;

  const response = await fetchAPI({ append, method: `GET`, parameters, route: `pages` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callGetKeywordSuggestions;

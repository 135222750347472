import { fetchAPI } from 'particles';

type DeleteManyDomainsParameters = {
  ids: [ArgID];
};

export const callDeleteManyDomains = async (parameters: DeleteManyDomainsParameters) => {
  const response = await fetchAPI({ method: `DELETE`, parameters, route: `domains` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callDeleteManyDomains;

import ROUTES from 'routes';

import type { NavigationItem } from '../navigation.types';

export const sidebarItems: NavigationItem[] = [
  {
    href: ROUTES.keyword_research,
    icon: `dashboard`,
    title: `Dashboard`,
  },
  {
    href: ROUTES.research,
    icon: `chart`,
    title: `Research`,
  },
  {
    href: ROUTES.coverage_map,
    icon: `map`,
    title: `Coverage Map`,
  },
];

export default sidebarItems;

import * as React from 'react';

// Components
import { Card } from 'corigan';

// Local Particles
import StyledViewTemplate from './view-template.styles';

// Local Components
import ReportControls from '../../components/partials/controls';

declare type ViewTemplateProps = {
  children: React.ReactNode;
  controlDevices: boolean;
  controlMOM: boolean;
  loading: boolean;
};

const ViewTemplate = (props: ViewTemplateProps) => {
  const { children, controlDevices, controlMOM, loading } = props;

  return (
    <Card loading={loading}>
      <StyledViewTemplate>
        <nav className="report-builder__header">
          <ReportControls controlDevices={controlDevices} controlMOM={controlMOM} />
        </nav>
        {children}
      </StyledViewTemplate>
    </Card>
  );
};

export default ViewTemplate;

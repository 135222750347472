export const _with: ArgWith = [
  `createdBy`,
  `activeRevision.categories.category`,
  `activeRevision.contentBlocks.contentBlock`,
  `activeRevision.keywords`,
  `activeRevision.pageType`,
  `activeRevision.preferredCategories`,
  `activeRevision.tags`,
  `activeRevision.assignedTo`,
  `activeRevision.diff.contentBlocks.contentBlock`,
  `activeRevision.editLocked.editLockedBy`,
  `currentRevision.categories.category`,
  `currentRevision.contentBlocks.contentBlock`,
  `currentRevision.keywords`,
  `currentRevision.pageType`,
  `currentRevision.preferredCategories`,
  `currentRevision.tags`,
  `currentRevision.assignedTo`,
  `currentRevision.diff.contentBlocks.contentBlock`,
  `currentRevision.editLocked.editLockedBy`,
  `implementedRevision.categories.category`,
  `implementedRevision.contentBlocks.contentBlock`,
  `implementedRevision.keywords`,
  `implementedRevision.pageType`,
  `implementedRevision.preferredCategories`,
  `implementedRevision.tags`,
  `implementedRevision.assignedTo`,
  `implementedRevision.diff.contentBlocks.contentBlock`,
  `implementedRevision.editLocked.editLockedBy`,
];

export const revisionWith: ArgWith = [
  `assignedTo`,
  `createdBy`,
  `categories.category`,
  `contentBlocks.contentBlock`,
  `keywords`,
  `pageType`,
  `preferredCategories`,
  `tags`,
  `assignedTo`,
  `diff.contentBlocks.contentBlock`,
  `updatedBy`,
  `editLocked.editLockedBy`,
];

export default _with;

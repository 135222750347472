export const dateShort = (dateString: string) => {
  const dateObject = new Date(dateString);
  const formattedDate: string = dateObject.toLocaleString(`en-GB`, {
    year: `numeric`,
    month: `short`,
    day: `numeric`,
  });
  return formattedDate;
};

export default dateShort;

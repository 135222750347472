import * as React from 'react';

import StyledFooter from './footer.styles';

import { Link } from 'atoms';

const isStorybook: boolean = process.env.STORYBOOK_ENABLED === `true`;

let releaseVersion = process.env.GATSBY_APP_VERSION;
if (isStorybook) releaseVersion = process.env.STORYBOOK_APP_VERSION;

type FooterProps = {
  application: ApplicationName;
  /**
   * The primary content of the Footer component, defaults to nothing
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: any;
};

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { children, className } = props;
  let classList = `footer`;
  if (className) classList += ` ${className}`;

  return (
    <StyledFooter className={classList}>
      <section className="footer__contents">
        {children}
        <nav>
          {releaseVersion && <span>{`Version - ${releaseVersion}`}</span>}
          <Link href="https://theorganicagency.com/" target="_blank">
            Handcrafted by Organic
          </Link>
        </nav>
      </section>
    </StyledFooter>
  );
};

// Default prop values
Footer.defaultProps = {};

export default Footer;

import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import { GlobalStyle } from 'corigan';
import { ThemeDefault } from 'corigan';

import { CRState } from 'particles';
import { KRState } from 'particles';
import { OMState } from 'particles';
import { RBState } from 'particles';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const isDevelopment = process.env.NODE_ENV === `development`;

export const wrapPageElement = ({ element, props }) => <ProcessedProps {...props}>{element}</ProcessedProps>;

declare type ProcessedPropsProps = {
  children: any;
};

const ProcessedProps: React.FC<ProcessedPropsProps> = (props: ProcessedPropsProps) => {
  const { children } = props;

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <OMState>
        <CRState>
          <KRState>
            <RBState>
              <ThemeProvider theme={ThemeDefault}>
                <React.Fragment>
                  <GlobalStyle />
                  {React.cloneElement(children, props)}
                  {isDevelopment && <ReactQueryDevtools initialIsOpen />}
                  <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    limit={5}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </React.Fragment>
              </ThemeProvider>
            </RBState>
          </KRState>
        </CRState>
      </OMState>
    </ReactQueryCacheProvider>
  );
};

export default wrapPageElement;

export const wordCounter = (text: string): number => {
  text = text.replace(/\n/gi, ` `); // Replaces new lines with spaces
  text = text.replace(/(^\s*)|(\s*$)/gi, ``); // Excludes leading and trailing white-space
  text = text.replace(/[ ]{2,}/gi, ` `); // Changes 2 or more spaces to 1 space
  const words = text.split(` `).filter(word => word); // Filters out empty spaces

  return words.length;
};

export default wordCounter;

import { getRelease } from 'corigan';
import { isUndefined } from 'corigan';
import { localStorageSet } from 'corigan';
import { windowAvailable } from 'corigan';

declare type ReducerAction = {
  key?: string;
  type?: string;
  value: any;
};

// Create global page reducer to handle state changes
export const rbReducer = (state, action: ReducerAction) => {
  const { releaseVersion } = getRelease();

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our inital paramaters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = (key: string): string => `version=${releaseVersion}&key=rbConfig${key}`;

  // Create a function which sets the value of a new 'where' argument to localStorage
  const setLocalValue = (
    stateKey: 'Devices' | 'MonthOnMonth' | 'ChartDateEnd' | 'ChartDateStart' | 'APIDate',
    newValue: any,
    hoursToExpireFrom?: number,
  ) => {
    const hasWindow = windowAvailable();
    if (!hasWindow) return;

    localStorageSet(buildKey(stateKey), newValue, hoursToExpireFrom);
  };

  const { key, type = `set`, value } = action;

  // Create a copy of state to prevent any chance of mutating
  const newState = { ...state };

  if (isUndefined(value)) return newState;

  switch (type) {
    case `toggleDesktop`: {
      // Create a new values to stop mutation in current state
      let newDevices = [...newState.devices];
      // Does desktop exist in the devices state array?
      const hasDesktop = newDevices.some(d => d === `desktop`);
      // If desktop exists, remove it
      if (hasDesktop) newDevices = newDevices.filter(d => d !== `desktop`);
      // Otherwise, add it
      if (!hasDesktop) newDevices = [...newDevices, `desktop`];
      // Make sure the devices array has a single value
      if (newDevices.length === 0) newDevices = [`mobile`];
      // Set new devices value
      newState.devices = newDevices;
      setLocalValue(`Devices`, newDevices, 12);
      break;
    }

    case `toggleMobile`: {
      // Create a new values to stop mutation in current state
      let newDevices = [...newState.devices];
      // Does mobile exist in the devices state array?
      const hasMobile = newDevices.some(d => d === `mobile`);
      // If mobile exists, remove it
      if (hasMobile) newDevices = newDevices.filter(d => d !== `mobile`);
      // Otherwise, add it
      if (!hasMobile) newDevices = [...newDevices, `mobile`];
      // Make sure the devices array has a single value
      if (newDevices.length === 0) newDevices = [`desktop`];
      // Set new devices value
      newState.devices = newDevices;
      setLocalValue(`Devices`, newDevices, 12);
      break;
    }

    case `setMOM`: {
      newState.monthOnMonth = value;
      setLocalValue(`MonthOnMonth`, value, 12);
      break;
    }

    case `set`:
      newState[key] = value;
      if (key === `apiDate`) setLocalValue(`APIDate`, value, 12);
      if (key === `chartDateStart`) setLocalValue(`ChartDateStart`, value, 12);
      if (key === `chartDateEnd`) setLocalValue(`ChartDateEnd`, value, 12);
      if (key === `devices`) setLocalValue(`Devices`, value, 12);
      break;
  }

  return newState;
};

export default rbReducer;

import { fetchAPI } from 'particles';

type UpdatePageTypeParameters = {
  id: ArgID;
  name?: ArgName;
};

export const callUpdatePageType = async (parameters: UpdatePageTypeParameters): Promise<{ data: PageType }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `pagetypes` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdatePageType;

import * as React from 'react';
import { CompositeDecorator } from 'draft-js';

import type { ContentState } from 'draft-js';

/**
 * DraftDecoratorComponentProps are the core set of props that will be
 * passed to all DraftDecoratorComponents if a Custom Block Component is not used.
 * Note that a component may also accept additional props outside of this list.
 */
export type DraftDecoratorComponentProps = {
  blockKey: string;
  children?: any;
  contentState: ContentState;
  decoratedText: string;
  end: number;
  // Many folks mistakenly assume that there will always be an 'entityKey'
  // passed to a DecoratorComponent.
  // To find the `entityKey`, Draft calls
  // `contentBlock.getEntityKeyAt(leafNode)` and in many cases the leafNode does
  // not have an entityKey. In those cases the entityKey will be null or
  // undefined. That's why `getEntityKeyAt()` is typed to return `?string`.
  // See https://github.com/facebook/draft-js/blob/2da3dcb1c4c106d1b2a0f07b3d0275b8d724e777/src/model/immutable/BlockNode.js#L51
  entityKey?: string;
  offsetKey: string;
  start: number;
};

const entityFinder = (contentBlock, callback, contentState, type) => {
  contentBlock.findEntityRanges(character => {
    const entityKey: string = character.getEntity();
    const entityType: string = entityKey !== null && contentState.getEntity(entityKey).getType();
    const match: boolean = type.includes(entityType);
    return match;
  }, callback);
};

const wordFoundStrategy = (contentBlock, callback, contentState) => {
  entityFinder(contentBlock, callback, contentState, [`UNLINKED`]);
};

const wordLinkedStrategy = (contentBlock, callback, contentState) => {
  entityFinder(contentBlock, callback, contentState, [`LINK`]);
};

const KeywordFoundSpan: React.FC<DraftDecoratorComponentProps> = (props: DraftDecoratorComponentProps) => {
  const { offsetKey, children } = props;

  return (
    <span className="editor__keyword" data-offset-key={offsetKey}>
      {children}
    </span>
  );
};

const KeywordLinkedAnchor: React.FC<DraftDecoratorComponentProps> = (props: DraftDecoratorComponentProps) => {
  const { contentState, entityKey, children } = props;

  const entity = contentState.getEntity(entityKey);
  const data = entity.getData();

  const { matchesCount, warning, url } = data;
  const usedMultipleInText: boolean = matchesCount >= 2;

  let className: string = `editor__keyword--linked`;
  if (usedMultipleInText || warning) className += ` editor__keyword--highlight`;
  if (warning) className += ` editor__keyword--warning`;
  if (usedMultipleInText) className += ` editor__keyword--multiple`;

  return (
    <a className={className} data-warning={warning} href={url}>
      {children}
    </a>
  );
};

const keywordDecorator = new CompositeDecorator([
  {
    component: KeywordFoundSpan,
    strategy: wordFoundStrategy,
  },
  {
    component: KeywordLinkedAnchor,
    strategy: wordLinkedStrategy,
  },
]);

export default keywordDecorator;

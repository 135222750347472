import * as React from 'react';

import { isURL } from 'corigan';

import StyledTooltip, { StyledTooltipRoot } from './tooltip.styles';

type TooltipProps = {
  align?: 'top' | 'bottom' | 'center';
  /**
   * The element in which to position the tooltip relative to
   */
  children: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  /**
   * The innercontent of the Tooltip element
   */
  content: string;
  side: 'right' | 'left';
};

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const { children, content, ...data } = props;
  const { align, className, side } = props;

  const contentIsURL = isURL(content);
  const isLarge = content?.length > 20 && !contentIsURL;
  const isLongURL = content?.length > 100 && contentIsURL;

  let classList = `tooltip-root`;
  if (isLarge || isLongURL) classList += ` tooltip--large`;
  if (contentIsURL) classList += ` tooltip--url`;
  if (className) classList += ` ${className}`;
  if (align) classList += ` tooltip-root--${align}`;
  if (side) classList += ` tooltip-root--${side}`;

  return (
    <StyledTooltipRoot className={classList}>
      <StyledTooltip {...data} className="tooltip">
        {content}
      </StyledTooltip>
      {children}
    </StyledTooltipRoot>
  );
};

Tooltip.defaultProps = {
  side: `right`,
};

export default Tooltip;

import moment from 'moment';

const momentCurrentWeek = moment().startOf(`isoWeek`);
const momentYearAgo = moment().subtract(1, `years`).startOf(`isoWeek`);

declare type device = 'mobile' | 'desktop';
declare type defaultStateProperties = {
  apiDate: moment.Moment;
  chartDateEnd: moment.Moment;
  chartDateStart: moment.Moment;
  devices: [device];
  monthOnMonth: boolean;
  view: string;
};

export const defaultState: defaultStateProperties = {
  apiDate: momentCurrentWeek,
  chartDateEnd: momentCurrentWeek,
  chartDateStart: momentYearAgo,
  devices: [`mobile`],
  monthOnMonth: false,
  view: `client-detail`,
};

export default defaultState;

import styled from 'styled-components';
import { isUndefined } from 'helpers';
import { shouldBeDark } from 'particles';

const borderWidth = `1px`;
const initial = `16px`;

const tabPaddingButton = props => {
  const { x } = props;

  const noProps = !props;
  const hasX = !isUndefined(x);

  if (noProps || !hasX) return null;

  if (hasX) return `${x}px`;
};

const tabPaddingContent = props => {
  const { x, y } = props;

  const noProps = !props;
  const hasX = !isUndefined(x);
  const hasY = !isUndefined(y);

  if (noProps || (!hasX && !hasY)) return initial;

  let padding = initial;
  if (hasX && !hasY) padding = `${initial} ${x}px`;
  if (!hasX && hasY) padding = `${y}px ${initial}`;
  if (hasX && hasY) padding = `${y}px ${x}px`;

  return padding;
};

const StyledTabs = styled.section`
  header {
    button {
      /* Button Reset CSS */
      border-radius: 0;
      margin: 0;
      padding: 16px;
      position: relative;
      width: auto;
      overflow: visible;

      background: transparent;
      border: none;
      box-shadow: none;
      color: inherit;
      font: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: normal;
      text-decoration: none;
      text-transform: inherit;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      border-radius: 0px;

      font-weight: 400;
      transition: 0.2s border-color ease, 0.2s color ease;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        border-bottom: ${borderWidth} solid ${() => (shouldBeDark() ? `var(--grey200)` : `var(--primary)`)};
        color: ${() => (shouldBeDark() ? `var(--grey200)` : `var(--primary)`)};

        outline: none;
      }
    }

    button:first-of-type {
      padding-left: ${props => tabPaddingButton(props)};
    }

    button,
    .tab__filler {
      border-bottom: ${borderWidth} solid ${() => (shouldBeDark() ? `var(--grey400)` : `var(--grey400)`)};
    }
  }

  nav {
    display: flex;
  }

  .tabs__contents {
    padding: ${props => tabPaddingContent(props)};
  }

  .tab__filler {
    display: block;
    flex: 1;
  }

  .tab__title {
    margin: 0;

    border-left: ${() => (shouldBeDark() ? `none` : null)};
    border-top: ${() => (shouldBeDark() ? `none` : null)};
    border-right: ${() => (shouldBeDark() ? `none` : null)};
    color: ${() => (shouldBeDark() ? `var(--grey400)` : null)};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    text-transform: capitalize;

    > * {
      align-items: center;
      display: flex;
    }

    svg {
      margin-right: 4px;

      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--grey400)`)};
    }
  }

  .tab__filler,
  .tab__title:active,
  .tab__title:focus,
  .tab__title:focus-within,
  .tab__title:hover,
  .tab__title--active {
    border-bottom: ${borderWidth} solid ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};
    color: ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};
    transition: 0.2s color ease, 0.2s border-bottom ease;

    svg {
      color: ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};

      transition: 0.2s color ease;
    }
  }

  .tab__title--warning {
    padding: 0;

    span {
      padding: 16px;
      background-color: #f9d4cf;
    }
  }

  &.tabs--cards {
    .tab__title--active {
      border: ${borderWidth} solid ${() => (shouldBeDark() ? `var(--white)` : `var(--primary)`)};
      border-bottom: 0;

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        border-bottom: 0;
      }
    }
  }

  &.tabs--0 {
    .tabs__contents {
      > span {
        display: none;
      }

      > span:first-of-type {
        display: block;
      }
    }
  }

  &.tabs--1 {
    .tabs__contents {
      > span {
        display: none;
      }

      > span:nth-of-type(2) {
        display: block;
      }
    }
  }

  &.tabs--2 {
    .tabs__contents {
      > span {
        display: none;
      }

      > span:nth-of-type(3) {
        display: block;
      }
    }
  }

  &.tabs--3 {
    .tabs__contents {
      > span {
        display: none;
      }

      > span:nth-of-type(4) {
        display: block;
      }
    }
  }

  &.tabs--4 {
    .tabs__contents {
      > span {
        display: none;
      }

      > span:nth-of-type(5) {
        display: block;
      }
    }
  }

  &.tabs--5 {
    .tabs__contents {
      > span {
        display: none;
      }

      > span:nth-of-type(6) {
        display: block;
      }
    }
  }
`;
export default StyledTabs;

import * as React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { navigate } from 'gatsby-link';
import { useContext } from 'react';

// Particles
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';
import { parseCSV } from 'corigan';
import { addCollectionCache } from 'corigan';
import { ApplicationContext } from 'corigan';

// Components
import { Link, Upload } from 'corigan';
import { Breadcrumbs, Card, Error, Info } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

import { Toggle } from 'corigan';

// API
import { callCreateManyPageRevisions } from 'corigan';

import { getRelease } from 'corigan';
import { useLocalStorage } from 'corigan';

const Wrapper = () => (
  <ProtectedRoute redirect={ROUTES.optimisation_manager} requiredPermissions={[`pages:create`, `pages:approve`]}>
    <PageRevisionManagerImport />
  </ProtectedRoute>
);

type PageProps = {};

let idImportExpanded:boolean;

const PageRevisionManagerImport = (props: PageProps) => {
  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our initial parameters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const { releaseVersion } = getRelease();
  const localKey = React.useMemo(() => {
    return `version=${releaseVersion}&key=om-id-import-expand`;
  }, [releaseVersion]);

  const [idImport, setIDImportExpand] = useLocalStorage(localKey, false);
  idImportExpanded = idImport;

  // define expanded export toggle handler
  const handleToggleChange = () => {
    setIDImportExpand(!idImport);
    idImportExpanded = idImport;
  };

  const ImportLabel = (
    <React.Fragment>
      Import updated export file
    </React.Fragment>
  );

  return (
    <Page application="optimisation-manager" pageTitle="Import Pages">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.optimisation_manager}>All Pages</Link>
              <h1>Import Page Revisions</h1>
              <p>Warning this will archive all working page revisions matching currentName or IDs except for Live and Implemented versions, please use with caution and check your import data first.</p>
              <p>There are two ways to import data</p>
              <p>First is to create an CSV import file (with headers) that contain currentName, newName, url, tags columns. <a href={`/csv/pagerevisions-import-example.csv`} download={`/csv/pagerevisions-import-example.csv`}>Download example CSV</a>.</p>
              <p>Second is to export a dataset from the Mastersheet table with "Import Page Revisions" enabled, modify what you want and then import with "Import updated export file" enabled.</p>
            </Breadcrumbs>
          </Col>
        </Row>
        <Toggle className="ml-2" id="IDImport" label={ImportLabel} onChange={handleToggleChange} on={idImport} />
        <Row>
          <Col>
            <ImportPageRevisons />
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

type ImportPageRevisionsProps = {};

type ExportedPageRevisionsFormat = {
  page: ArgID;
  pageId?: string;
  name?: ArgName;
  url?: ArgURL;
  title?: string;
  metaDescription?: string;
  assignedTo?: ArgTags;
  pagetype?: ArgPageType;
  categories?: string;
  tags?: ArgTags;
  keywords?: string;
  status?: ArgStatus;
};

type PageRevisionsFormat = {
  currentName: ArgName;
  newName: ArgName;
  url: ArgURL;
  tags?: ArgTags;
  title?: string;
  metaDescription?: string;
};

const SECOND = 1000;

const perPage: ArgPerPage = 10;
const _with: ArgWith = [`tags`];

const ImportPageRevisons = (props: ImportPageRevisionsProps) => {
  const [success, setSuccess] = useState(false);
  const [fails, setFails] = useState<string[]>([]);
  const queryCache = useQueryCache();
  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;
  const domain = domainActive?.id;
  const hasFails = Boolean(fails.length);

  const [mutate, { error, isLoading: loading }] = useMutation(callCreateManyPageRevisions, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err);

      queryCache.invalidateQueries([`callGetManyPageRevisions`, { perPage, _with }]);
    },
    // Always re-fetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {

      // If callGetManyPageRevisions function was successful, get values from API response
      const pages: PageRevision[] = data?.data;

      // Optimistically update to the new value
      queryCache.setQueryData([`callGetManyPageRevisions`, { perPage, _with }], (previousPages: APIResponse) =>
        addCollectionCache(previousPages, pages),
      );

      // set fails to const
      const failsData = data?.fails;

      // check for fails
      if(failsData.length > 0){
        // print to log
        console.error(`failsData`,failsData);

        // loop through array
        const failedItems: string[] = [];

        for(const item of failsData) {
          // generate string for fail state display
          failedItems.push(`Line ${item.line} (${item.name}): ${item.error}`);
        }

        // set failed state
        setFails(failedItems);
      } else {
        // Redirect to pagerevision directory after 2 seconds if no errors
        setSuccess(true);
        setTimeout(() => {
          navigate(ROUTES.mastersheet);
        }, 2 * SECOND);
      }
    },
  });

  const onMediaSet = async files => {
    const hasFile = files?.length > 0;
    if (!hasFile) return;

    // Deconstruct first file from array of files (Our CSV)
    const [file] = files;

    // check if importing with ids
    if (idImportExpanded) {
      // Parse the CSV to an array
      const pageRevisions: any | ExportedPageRevisionsFormat[] = await parseCSV(file);
      const hasPageRevisions = pageRevisions?.length > 0;
      if (!hasPageRevisions) return;

      const safePageRevisions = pageRevisions.map(
        ({ page, pageid, name, title, metaDescription, url, assignedto, pagetype, categories, tags, keywords, status }) => ({
          ...(page && { page: page.toString() }),
          ...(pageid && { pageid: pageid.toString() }),
          ...(name && { name: name.toString() }),
          ...(title && { title: title.toString() }),
          ...(metaDescription && { metaDescription: metaDescription.toString() }),
          ...(url && { url: url.toString() }),
          ...(assignedto && { assignedto: assignedto.toString() }),
          ...(pagetype && { pagetype: pagetype.toString() }),
          ...(categories && { categories }),
          ...(tags && { tags: tags }),
          ...(keywords && { keywords }),
          ...(status && { status: status.toString() }),
        }),
      );

      // Send the parsed content to the create many pages route
      await mutate({  pageRevisions: safePageRevisions, domain, importExpanded: idImportExpanded });
    } else {
      // Parse the CSV to an array
      const pageRevisions: any | PageRevisionsFormat[] = await parseCSV(file);
      const hasPageRevisions = pageRevisions?.length > 0;
      if (!hasPageRevisions) return;

      const safePageRevisions = pageRevisions.map(
        ({ currentname, newname, url, tags, title, metaDescription }) => ({
          ...(currentname && { currentname: currentname.toString() }),
          ...(newname && { newname: newname.toString() }),
          ...(url && { url }),
          ...(tags && { tags }),
          ...(title && { title: title.toString() }),
          ...(metaDescription && { metaDescription: metaDescription.toString() }),
        }),
      );

      // Send the parsed content to the create many pages route
      await mutate({  pageRevisions: safePageRevisions, domain, importExpanded: idImportExpanded });
    }
  };

  const onClose = e => {
    e.preventDefault();
    setSuccess(null);
  };

  return (
    <Card loading={loading}>
      <section>
        {error && <Error error={error} />}
        {hasFails && (<Info onClose={onClose} y="lg">{fails.map((fail, index) => <p key={index}>{fail}</p>)}</Info>)}
        {success && (
          <Info onClose={onClose}>Page revisions have successfully been imported, redirecting to mastersheet view</Info>
        )}
        <Upload disabled={loading} multiple={false} label={false} large={true} name="keywords" onMediaSet={onMediaSet} buttonLabel={`Choose file`} buttonLabelMultiple={`Choose file`} confirmText={`You are about to import multiple page revisions. Please check the data in your import file`}>
          Drag and drop a CSV file here, or click to use a file picker.
        </Upload>
      </section>
    </Card>
  );
};

export default Wrapper;

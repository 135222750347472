import * as React from 'react';

import StyledCol from './col.styles';

/* TypeScript is yet to support ranges... */
type gridrange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ColProps = {
  /**
   * The primary content of the Col component
   */
  children?: React.ReactNode;
  /**
   * Additional classNames to apply utility and helper classes
   */
  className?: string;
  full?: boolean;
  /**
   * The number of columns to spread across on a 12 (0 - 12) column grid at 576px
   */
  xs?: gridrange;
  /**
   * The number of columns to spread across on a 12 (0 - 12) column grid at 768px
   */
  sm?: gridrange;
  /**
   * The number of columns to spread across on a 12 (0 - 12) column grid at 992px
   */
  md?: gridrange;
  /**
   * The number of columns to spread across on a 12 (0 - 12) column grid at 1200px
   */
  lg?: gridrange;
  /**
   * The number of columns to spread across on a 12 (0 - 12) column grid at 1440px
   */
  xl?: gridrange;
  /**
   * The number of columns to spread across on a 12 (0 - 12) column grid at 1800px
   */
  xxl?: gridrange;
};

export const Col: React.FC<ColProps> = (props: ColProps) => {
  const { children, className, full } = props;
  const { xs, sm, md, lg, xl, xxl } = props;

  let classList = `col`;
  if (xs) classList += ` col-xs`;
  if (sm) classList += ` col-sm`;
  if (md) classList += ` col-md`;
  if (lg) classList += ` col-lg`;
  if (xl) classList += ` col-xl`;
  if (xxl) classList += ` col-xxl`;
  if (full) classList += ` col--full`;
  if (className) classList += ` ${className}`;

  return (
    <StyledCol {...props} className={classList}>
      {children}
    </StyledCol>
  );
};

// Default prop values
Col.defaultProps = {};

export default Col;

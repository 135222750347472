import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { navigate } from 'gatsby';

// Particles
import { ApplicationContext } from 'corigan';
import { ProtectedRoute } from 'corigan';
import { ROUTES } from 'corigan';
import { useIsMount } from 'corigan';
import { useQueryParameters } from 'corigan';
import { OMContext } from 'corigan';

// Components
import { Error } from 'corigan';
import { Page } from 'corigan';

// Localised partials
import Complete from './partials/complete';
import Loading from './partials/loading';
import RequestError from './partials/error';
import MissingID from './partials/missing';

import _with from './_with';

// API
import { callGetPage } from 'corigan';

const Wrapper = () => {
  const isMount = useIsMount();

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  // When the user changes domain, redirect to the mastersheet as they need to select a new page
  useEffect(() => {
    if (isMount) return;
    navigate(ROUTES.mastersheet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainActive]);

  return (
    <ProtectedRoute redirect={ROUTES.mastersheet} requiredPermissions={[`pages:read`]}>
      <PageDetails />
    </ProtectedRoute>
  );
};

type PageProps = {};

const PageDetails: React.FC<PageProps> = () => {
  const queryParameters = useQueryParameters();
  const { id, status } = queryParameters;
  const stringID: ArgID = String(id);
  const stringStatus: ArgID = String(status);

  return (
    <Page application="optimisation-manager" pageTitle="Page">
      {id && <PageInfo id={stringID} status={stringStatus} />}
      {!id && <MissingID />}
    </Page>
  );
};

type PageInfoProps = {
  id: ArgID;
  status: ArgStatus;
};

const PageInfo: React.FC<PageInfoProps> = (props: PageInfoProps) => {
  const { id, status } = props;

  const context: any = useContext(OMContext);
  const state = context?.state;
  const { currentStatus } = state;

  const queryKey = [`callGetPage`, { id, status:currentStatus ?? status, _with }];
  const queryFn = callGetPage;
  const queryOptions = { cacheTime: 0 };

  const { data: res, error, isLoading: loading } = useQuery(queryKey, queryFn, queryOptions);
  const data = res?.data;

  const hasActiveRevision: boolean = Boolean(data?.activeRevision);
  const hasAnyLoadableRevision: boolean = hasActiveRevision;
  const canLoad: boolean = hasAnyLoadableRevision;

  if (loading) return <Loading />;
  if (error) return <RequestError error={error} id={id} />;
  if (!canLoad) return <Error error="No revision found for this page" id={id} />;
  if (canLoad) return <Complete data={data} />;
  return null;
};

export { SuggestedKeywords } from './partials';
export default Wrapper;

import * as React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useReducer } from 'react';

import { ApplicationContext } from 'corigan';

import { arrayMerge } from 'corigan';
import { deepMerge } from 'corigan';
import { generateID } from 'corigan';
import { getRelease } from 'corigan';
import { isAnObject } from 'corigan';
import { localStorageRead } from 'corigan';
import { localStorageSet } from 'corigan';
import { windowAvailable } from 'corigan';

import defaultState from './defaultState';
import TableContext from './tableContext';
import { searchWithFilters } from '../parts/filters/functions';

import type { TableProps } from '../table.types';

type TableStateProps = {
  className?: string;
  children?: React.ReactNode;
  dependencies?: any;
  initialState?: TableProps & {
    searched: boolean;
  };
};

declare type ReducerAction = {
  key?: string;
  value: any;
  type:
    | 'set'
    | 'columnToggle'
    | 'itemSelect'
    | 'itemsSelectAllAdd'
    | 'itemsSelectAllClear'
    | 'itemsSelectEverythingAdd'
    | 'filterAdd'
    | 'filterRemove'
    | 'filterUpdate'
    | 'filterAllUpdate'
    | 'filtersAllRemove'
    | 'resetState'
    | 'updatingEnd'
    | 'updatingStart';
};

declare type APIValidFilter = {
  condition: APICondition;
  field: string;
  id: string;
  or: boolean;
  value: any;
};

const TableState: React.FC<TableStateProps> = (props: TableStateProps) => {
  // Create global state provider to handle state and reducer dispatch events
  const { children, initialState } = props;
  const { id } = initialState;

  let startState = defaultState;

  const { releaseVersion } = getRelease();
  const hasWindow = windowAvailable();

  // Creates a unique build key which handles users preferences for the table
  // IMPORTANT: The users local storage key will take preference over our initial parameters,
  // this may cause errors if we change the schema or how we query so will need to change the key on major updates
  const buildKey = useCallback(
    (key: string): string => {
      const keyID = hasWindow ? window?.location?.pathname?.replace(`/`, `-`) : id;
      return `version=${releaseVersion}&key=component-table-${keyID}-${key}`;
    },
    [hasWindow, id, releaseVersion],
  );

  // Read local storage values
  const getLocalColumns: boolean = Boolean(hasWindow && localStorageRead(buildKey(`Columns`)));
  const localColumns = getLocalColumns ? localStorageRead(buildKey(`Columns`)) : undefined;

  startState = useMemo(() => {
    const newState = { ...initialState };

    const columns = initialState.columns;

    // Read a basic localStorage array to get the values of 'hide' on columns
    const toggledColumns = columns.map(col => {
      // Get two possible identifiers
      const { dbKey, value } = col;

      // Find the column in our Table component form localStorage
      const foundInLocalStorage = localColumns?.find(localCol => {
        const matchedDBKey: boolean = localCol?.dbKey === dbKey;
        const matchedValue: boolean = localCol?.value === value;
        const isMatched: boolean = matchedDBKey || matchedValue;
        return isMatched;
      });

      // If no found, return the column passed in as a prop with no changes
      if (!foundInLocalStorage) return col;

      // Otherwise, return the column defined, with the hide property based
      // on the localStorage column value
      return { ...col, hide: foundInLocalStorage?.hide };
    });

    if (columns) newState.columns = toggledColumns;

    let filters = [];
    const where: ArgWhere = newState?.apiArgs?.where;

    const apiFilters: any[] = where?.split(`&where`);
    const hasFilters: boolean = apiFilters?.length > 0;

    if (!hasFilters) newState.filters = filters;

    if (hasFilters) {
      filters = apiFilters.map(apiFilter => {
        // Pattern matches all substrings in []
        const regexPattern = /\[(.*?)\]/g;
        // Get a RegexExpression object that matches any []
        const parts = apiFilter.matchAll(regexPattern);

        // Spread the expression into an iterable array (should have two values)
        const apiArguments = [...parts];

        const hasArguments: boolean = apiArguments.length >= 2;
        if (!hasArguments) return null;

        // Get the values of the condition and field, and filter out empty []
        const argValues: string[] = apiArguments.map((apiArg: any) => apiArg.pop());
        const filteredArgValues: string[] | APICondition[] = argValues.filter((argValue: string | APICondition) => argValue);

        const condition: APICondition = filteredArgValues.pop() as APICondition;
        const field: string = filteredArgValues.pop();
        const id = generateID();
        const or = filteredArgValues.pop() === `or`;
        const value = apiFilter.split(`=`)?.pop();

        const filter: APIValidFilter = {
          condition,
          field,
          id,
          or,
          value,
        };

        return filter;
      });

      const validFilters: APIValidFilter[] = filters?.filter(Boolean);
      const hasValidFilters: boolean = validFilters?.length > 0;

      if (hasValidFilters) {
        // Creates an empty array dataset
        const seen = new Set();

        // Loop over each filter available in the table
        const newFilters = validFilters.filter(filter => {
          // Create a new object by shallow duplicating the filter object
          const params = { ...filter };

          // If the 'params' object has the key 'id' then delete it
          const hasID: boolean = params.hasOwnProperty(`id`);
          if (hasID) delete params.id;

          // Determines if we have already got a duplicate filter in our dataset
          const duplicate: boolean = seen.has(params);

          // Add the 'params' value to our dataset
          seen.add(params);

          // Return whether or not this is a duplicate as boolean
          // If true, it won't be kept more than once
          return !duplicate;
        });

        newState.filters = newFilters;
      }
    }

    if (!newState) return startState;
    const finalState = deepMerge(startState, newState);

    return finalState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create global table reducer to handle state changes
  const tableReducer = (state: TableProps, action: ReducerAction) => {
    const { key, type, value } = action;

    // Create a copy of state to prevent any chance of mutating
    const newState = { ...state };
    newState.showSelectEverything = false;
    newState.updating = false;

    const invalidType = type === null;
    const invalidValue = value === null;

    if (invalidType) console.error(`No action type provided to table reducer`);
    if (invalidType) return newState;

    if (invalidValue) console.error(`No value provided to table reducer`);
    if (invalidValue) return newState;

    switch (type) {
      case `resetState`: {
        return startState;
      }

      case `updatingStart`: {
        newState.updating = true;
        return newState;
      }

      case `updatingEnd`: {
        newState.updating = false;
        return newState;
      }

      case `set`: {
        if (key == null) {
          console.error(`No action key provided to 'set' action`);
          return;
        }

        newState[key] = value;
        return newState;
      }

      case `columnToggle`: {
        // If no value was provided, then exit the function and return the current state store
        if (!value) return newState;

        // Get an array of the columns in our state store
        const currentColumns = [...newState.columns];

        // Do we have a column in our table that matches the column we want to update?
        const hasColumn: boolean = currentColumns.some(col => col.value === value);

        if (!hasColumn) return newState;

        // Go through each column in our table
        const newColumns = currentColumns.map(col => {
          // Is the current itterated column the one we want to update?
          const hasColumnToUpdate: boolean = col.value === value;

          // If not, return the column with no changes
          if (!hasColumnToUpdate) return col;

          // Get the current value of the column to update
          const current: boolean = col.hide;

          // Set thew new value of 'hide' to be the opposite of what it currently is
          const hide: boolean = !current;

          // Spread the existing column info (value, id, etc.)
          // and append the new hide value to replace the existing value
          return { ...col, hide };
        });

        // Set the new state store value of columns to our newly toggled state
        newState.columns = [...newColumns];

        // Create a basic localStorage array to store the values of 'hide' on columns
        // We don't want all the details, just an identifier and whether to show the column
        const localStorageColumns = newColumns.map(col => {
          const { dbKey, hide, value } = col;
          return { dbKey, hide, value };
        });

        // If we have the window available to us, save the basic columns data in localStorage
        if (hasWindow) localStorageSet(buildKey(`Columns`), [...localStorageColumns]);

        return newState;
      }

      case `itemSelect`: {
        if (!value) return newState;
        let newSelected = [...newState.selected];

        const alreadySelected: boolean = newSelected.some(arrVal => arrVal.id === value.id);

        if (alreadySelected) newSelected = newSelected.filter(arrVal => arrVal.id !== value.id); // Remove
        if (!alreadySelected) newSelected = [...newSelected, value]; // Append

        newState.selected = newSelected;
        return newState;
      }

      case `itemsSelectAllAdd`: {
        if (!value) return newState;
        let newSelected = [...newState.selected];

        newSelected = arrayMerge(newSelected, value);

        newState.selected = newSelected;
        newState.showSelectEverything = true;
        return newState;
      }

      case `itemsSelectAllClear`: {
        // Set the selection array to be an empty array
        const newSelected = [];
        newState.selected = newSelected;

        return newState;
      }

      case `itemsSelectEverythingAdd`: {
        // Replace the selection array with value
        const newSelected = value;
        newState.selected = newSelected;

        return newState;
      }

      case `filterAdd`: {
        // Do we have a value to check?
        if (!value) {
          console.error(`No value provided to 'filterAdd'`);
          return newState;
        }

        // Is the new value an object?
        if (!isAnObject(value)) {
          console.error(`Value provided to 'filterAdd' is not an object`);
          return newState;
        }

        // Check we have valid properties on filter being added to array of filters
        const hasCondition: boolean = value.hasOwnProperty(`condition`);
        const hasField: boolean = value.hasOwnProperty(`field`);
        const hasValue: boolean = value.hasOwnProperty(`value`);
        const hasValidProperties: boolean = hasCondition && hasField && hasValue;

        if (!hasValidProperties) {
          if (!hasCondition) console.error(`Value provided to 'filterAdd' is an object missing key 'condition'`);
          if (!hasField) console.error(`Value provided to 'filterAdd' is an object missing key 'field'`);
          if (!hasValue) console.error(`Value provided to 'filterAdd' is an object missing key 'value'`);
          return newState;
        }

        // Spread the existing filters as a non-direct mutable array
        const currentFilters = [...newState.filters];

        // Get the value being added into the array of filters
        const compare: { condition: APICondition; field: string; value: string } = { ...value };

        // If the new filter matches 'condition', 'field', 'or' and 'value' then it already exists
        const filterExists: boolean = currentFilters?.some(f => {
          const matchedCondition: boolean = f.condition === compare.condition;
          const matchedField: boolean = f.field === compare.field;
          const matchedValue: boolean = f.value === compare.value;
          const isExactMatch: boolean = matchedCondition && matchedField && matchedValue;
          return isExactMatch;
        });

        // If the filter already exists in our table, then skip duplication
        if (filterExists) return newState;

        const latestFilters = [...currentFilters, { id: generateID(), ...value }];
        newState.filters = latestFilters;

        searchWithFilters(newState);

        return newState;
      }

      case `filterRemove`: {
        newState.filters = newState.filters.filter(f => f.id !== value);
        searchWithFilters(newState);
        return newState;
      }

      case `filterUpdate`: {
        if (!value?.id) return newState;

        const filterExists = newState.filters.some(f => f.id === value.id);
        if (!filterExists) return newState;

        const newFilters = newState.filters.map(f => {
          if (f.id !== value.id) return f;
          return { ...value };
        });

        newState.filters = newFilters;
        searchWithFilters(newState);
        return newState;
      }

      case `filterAllUpdate`: {
        newState.filters = value;
        searchWithFilters(newState);
        return newState;
      }

      case `filtersAllRemove`: {
        const hasFilters: boolean = newState.filters?.length > 0;
        const filters = !hasFilters ? [] : [...newState.filters];
        const hasFiltersToManage: boolean = filters?.length > 0;

        const filtersToHide: string[] = state?.filtersToHide;
        const hasFiltersToHide: boolean = filtersToHide?.length > 0;

        newState.filters = [];

        if (hasFiltersToManage && hasFiltersToHide) {
          const filtersToKeep = filters.filter(filter => {
            const shouldKeep = filtersToHide.includes(filter?.field);
            return shouldKeep;
          });

          newState.filters = filtersToKeep;
        }

        newState.searched = false;

        searchWithFilters(newState);
        return newState;
      }

      default: {
        return newState;
      }
    }
  };

  const [state, dispatch] = useReducer(tableReducer, startState);

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;

  useEffect(() => {
    dispatch({ type: `resetState`, value: true });
  }, [domainActive]);

  return (
    <TableContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

// Default prop values
TableState.defaultProps = {};

export default TableState;

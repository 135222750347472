import styled from 'styled-components';
import { device } from 'particles';

const StyledSuggested = styled.table`
  &.suggested {
    margin: 0;
  }

  .sidebar__pagination {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media ${device?.xl} {
      align-items: flex-start;
      flex-direction: column;
    }

    @media ${device?.xxl} {
      align-items: center;
      flex-direction: row;
    }

    button + button {
      margin-left: 4px;
    }

    p {
      margin-bottom: 0;
      margin-top: 0;

      @media ${device?.xl} {
        margin-top: 12px;
      }

      @media ${device?.xxl} {
        margin-top: 0;
      }
    }
  }

  .keyword__actions {
    display: none;
    margin-left: auto;
    padding: 12px 16px;

    button {
      /* Button Reset CSS */
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: relative;
      width: auto;
      overflow: visible;

      background: transparent;
      border: none;
      box-shadow: none;
      color: inherit;
      font: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: normal;
      text-decoration: none;
      text-transform: inherit;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      -webkit-appearance: none;

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
      border-radius: 50%;
      padding: 4px;

      border: 1px solid var(--grey100);

      svg {
        height: 20px;
      }

      &::before {
        border-radius: 50%;
      }

      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: var(--grey100);
      }
    }

    button + button {
      margin-left: 8px;
    }

    button:last-of-type {
      padding: 6px;

      svg {
        height: 16px;
      }
    }

    nav {
      justify-content: flex-start;

      .tooltip-root + .tooltip-root {
        margin-left: 4px;
      }
    }

    .keyword__include--active {
      background-color: var(--green100);
      color: var(--green900);
    }

    .keyword__promote--active {
      background-color: var(--green100);
      color: var(--green900);
    }

    .keyword__include--active,
    .keyword__promote--active {
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        background-color: var(--green200);
      }
    }
  }

  .keyword__details {
    animation-duration: 0.2s;
    animation-name: fadeIn;
  }

  .keyword__phrase {
    padding-right: 12px;
  }

  &.suggested--om {
    .sidebar__keyword {
      &:active,
      &:focus,
      &:focus-within,
      &:hover {
        .keyword__details {
          display: none;
        }

        .keyword__actions {
          display: table-cell;

          animation-duration: 0.2s;
          animation-name: fadeIn;
        }
      }
    }
  }

  .sidebar__keyword + .sidebar__keyword {
    margin-top: 16px;
  }

  .sidebar__modal {
    padding: 0;

    border-top: none;

    > td {
      padding: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default StyledSuggested;

import styled from 'styled-components';
import { hexToRGB } from 'helpers';

const width = `2px`;

const loaderPosition = props => {
  if (!props || props.fixed === true) return `fixed`;
  return `absolute`;
};

export const StyledLoader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 32px;
  position: ${props => loaderPosition(props)};
  top: 0;
  width: 100%;
  z-index: 100;

  pointer-events: none;
`;

export const StyledDefault = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .loader__vector {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    transform-origin: center center;

    animation: rotate 2s linear infinite;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: ${width};

    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: #5fe3a1;
    }
    40% {
      stroke: #56d9fe;
    }
    66% {
      stroke: #a3a1fb;
    }
    80%,
    90% {
      stroke: #fba3a1;
    }
  }
`;

const circumfrance = 125.663706;

const calculateCircleProgress = props => {
  if (!props.percentage) return circumfrance;

  const total = circumfrance * (props.percentage / 100);
  return circumfrance - total;
};

export const StyledProgress = styled(StyledDefault)`
  .loader__vector,
  .path {
    animation: none;
  }

  svg {
    transform: rotate(-80deg);
  }

  .path {
    stroke: var(--primary);
    stroke-dasharray: ${circumfrance} ${circumfrance};
    stroke-dashoffset: ${props => calculateCircleProgress(props)};

    transition: 0.4s stroke-dashoffset ease;
  }
`;

const opaqueBar = hex => {
  const rgb = hexToRGB(hex);
  return `rgba(${rgb}, 0.2)`;
};

export const StyledBar = styled.div`
  min-width: 200px;
  max-width: 500px;
  overflow: hidden;
  width: 80%;

  .loader__amount {
    height: 2px;
    position: relative;
    width: 100%;

    background-color: ${props => opaqueBar(props.theme.primary)};
    transform-origin: left center;
    transition: 0.2s transform ease;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      background-color: var(--primary);
      transform-origin: left center;
    }

    &::before {
      animation: increase 2.5s ease infinite;
    }

    &::after {
      animation: decrease 2.5s ease infinite;
      animation-delay: 0.5s;
    }
  }

  @keyframes increase {
    from {
      transform: translateX(-5%) scaleX(0.05);
    }
    to {
      transform: translateX(130%) scaleX(1);
    }
  }
  @keyframes decrease {
    from {
      transform: translateX(-80%) scaleX(0.8);
    }
    to {
      transform: translateX(110%) scaleX(0.1);
    }
  }
`;

export const StyledBarProgress = styled(StyledBar)`
  .loader__amount {
    animation: none;

    &::before,
    &::after {
      animation: none;
    }

    &::after {
      display: none;
    }

    &::before {
      transform: ${props => `scaleX(${props.percentage / 100})`};
      transition: 0.4s transform ease;
    }
  }
`;

export default StyledLoader;

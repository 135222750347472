import styled from 'styled-components';

const StyledPagination = styled.nav`
  align-items: center;
  display: flex;
  padding: 8px 0;

  button,
  .pagination__elipsis {
    /* Button Reset CSS */
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    align-items: center;
    display: inline-flex;
    justify-content: center;
    min-width: 42px;
    padding: 8px 12px;

    background-color: var(--white);
    border: 1px solid #e8e9ec;
    color: var(--black);
    text-align: center;

    &[disabled] {
      background-color: transparent;
      color: var(--grey400);
    }
  }

  select {
    margin-left: 20px;
    min-height: 38px;
    width: auto;

    border: 1px solid #e8e9ec;
  }

  button + button,
  button + .pagination__elipsis,
  .pagination__elipsis + button {
    margin-left: 4px;
  }

  .pagination__button--active {
    background-color: var(--primary);
    color: var(--white);
  }

  .pagination__elipsis {
    user-select: none;
  }
`;
export default StyledPagination;

import { ROUTES } from 'corigan';

/**
 * adds st, nd, rd, th to number for ranks
 * @param {number} i
 * @return {string} rank number
 */
const dateSuffix = (i) => {
  const j = i % 10;
      const k = i % 100;
  if (j === 1 && k !== 11) {
      return i + `st`;
  }
  if (j === 2 && k !== 12) {
      return i + `nd`;
  }
  if (j === 3 && k !== 13) {
      return i + `rd`;
  }
  return i + `th`;
}

export const generateRows = ({ data }) => {
    if (!data) return [];

    const formattedData = data.map((row, index) => {
      // define competitor variable
      const competitor = row.competitor;

      // define rankings chip for competitors or display active domain rank
      const rankings = index ? [{
        href: `${ROUTES.coverage_map}?competitor=${row.competitor}&filter=false`,
        value: row.rankings,
        tooltip: `Compare keywords against ${row.competitor}`,
        tooltipAlign: `center`,
        tooltipSide: `right`,
      }] : row.rankings;

      // define gap chip for competitors or display active domain gap
      const gapVariant = row.gap > 0 ? `red`: `green`;
      const gap = index ? [{
        href: `${ROUTES.coverage_map}?competitor=${row.competitor}&filter=true`,
        value: row.gap,
        variant: gapVariant,
        tooltip: `Compare ${row.competitor} higher ranking keywords`,
        tooltipAlign: `center`,
        tooltipSide: `right`,
      }] : null;

      // define competitor chips
      let opportunities = index ? row.opportunities : null;
      if (opportunities !== null) {
        const oppVariant = row.opportunities > 0 ? `var(--red)`: `var(--green)`;
        opportunities = index ? `<span style="color:${oppVariant}">${row.opportunities}</span>` : null;
      }

      // ratings variable
      const rating = dateSuffix(row.rating);

      // define coverage chip for competitors or display active domain coverage
      const coverage = index ? [{
        href: `${ROUTES.coverage_map}?competitor=${row.competitor}&filter=false`,
        value: row.coverage + `%`,
        tooltip: `Compare keywords against ${row.competitor}`,
        tooltipAlign: `center`,
        tooltipSide: `right`,
      }] : row.coverage + `%`;

      // define opportunityGap
      const oppGapVariant = row.opportunityGap > 0 ? `var(--red)`: `var(--green)`;
      const opportunityGap = `<span style="color:${oppGapVariant}">${row.opportunityGap}</span>`;

      // define row
      const item = {
        competitor,
        rankings,
        gap,
        opportunities,
        rating,
        coverage,
        opportunityGap,
      };

      return item;
    });

    return formattedData;
  };

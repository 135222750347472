import * as React from 'react';

// Organisms
import { Col, Row } from 'corigan';

// Local Components
import PieCategories from './partials/pie-categories';
import PieStatus from './partials/pie-status';

declare type CrawlChartsProps = {
  crawl: Crawl;
  error?: any;
  loading?: boolean;
};

const CrawlCharts = (props: CrawlChartsProps) => {
  return (
    <Row equalHeight={true}>
      <Col md={12} lg={6}>
        <PieStatus {...props} />
      </Col>
      <Col md={12} lg={6}>
        <PieCategories {...props} />
      </Col>
    </Row>
  );
};

export default CrawlCharts;

import * as React from 'react';
import { useContext } from 'react';
import { navigate } from 'gatsby-link';

import { ROUTES } from 'corigan';
import { ApplicationContext } from 'corigan';
import { windowAvailable } from 'corigan';

import { localStorageRemove } from 'corigan';

import { Link } from 'atoms';

import Overlay from '../overlay';

type UserProps = {
  showUserNavigation: boolean;
  updateHeaderView?: Function;
};

const User: React.FC<UserProps> = (props: UserProps) => {
  const { showUserNavigation, updateHeaderView } = props;
  const context: ApplicationContextProps = useContext(ApplicationContext);
  const hasWindow = windowAvailable();

  const logoutUser = context?.firebase?.logoutUser;

  const navigation = {
    items: [
      { href: ROUTES?.faq, title: `FAQs` },
      {
        disabled: true,
        href: ROUTES?.profile,
        title: `Account Setting`,
      },
      {
        disabled: true,
        href: ROUTES?.issues,
        title: `Report Issues`,
      },
    ],
  };

  const items = navigation?.items;
  const hasItems = items && items.length > 0;

  const handleLogout = async e => {
    if (e) e.preventDefault();
    if (!hasWindow) return;

    try {
      // Log the user out of the application
      await logoutUser();

      // Delete the local token result from Firebase (auth token)
      await localStorageRemove(`localTokenResult`);

      navigate(`/login`);
    } catch (error) {
      console.error(error);
    }
  };

  let className = `header__user__links`;
  if (showUserNavigation) className += ` header__overlay--show`;

  return (
    <Overlay active={showUserNavigation} className={className} updateHeaderView={updateHeaderView}>
      <div className="header__user__options">
        {hasItems && <UserOptions items={items} />}
        <button id="logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </Overlay>
  );
};

type UserOptionsProps = {
  items: {
    disabled?: boolean;
    href: string;
    title: string;
  }[];
};

const UserOptions: Function = (props: UserOptionsProps): JSX.Element[] => {
  const { items } = props;

  return items.map(({ disabled, href, title }) => {
    let className = `user__option`;
    if (disabled) className += ` user__option--disabled`;

    return (
      <Link className={className} href={href} key={`option-${title}`}>
        <span className="user__option__title">{title}</span>
      </Link>
    );
  });
};

export default User;

import slugify from 'slugify';

/**
 * @param {{name, label}[]} columns Which columns to export.
 *  The name properties should correspond to the property names contained in the rows param.
 * @param {{}[]} data An array of objects containing the data to be exported
 */

// As per RFC 4180, double quotes within a string should be doubled
const escape = text => text.replace(/"/g, `""`);
const objectValueKey = objectVal => objectVal[`value`];

type ValueType = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function' | 'array';

// Double quote all strings
const quote = value => {
  let valueType: ValueType = typeof value;
  if (Array.isArray(value)) valueType = `array`;

  switch (valueType) {
    case `object`:
      value = objectValueKey(value);
      break;
    case `array`:
      value = String(value.filter(v => v[`value`]).map(v => objectValueKey(v)));
      break;
    default:
      value = String(value);
      break;
  }

  if (!value) return ``;

  value = `"${escape(value)}"`;
  return value;
};

declare type Columns = { label: string; value: string }[];
declare type Data = ObjectLiteral[];

export const createCSVContent = (columns: Columns, data: Data) => {
  const headerRow = columns.map(({ label }) => quote(label)).join(`,`);
  const dataRows = data.map(row => columns.map(({ value }) => quote(row[value] || ``)).join(`,`)).join(`\n`);
  return headerRow + `\n` + dataRows;
};

/**
 * @param {string} title The filename of the export (will have ".csv" appended).
 * @param {{name, label}[]} columns Which columns to export.
 *  The name properties should correspond to the property names contained in the rows param.
 * @param {{}[]} data An array of objects containing the data to be exported
 */

export const exportTableToCSV = async (
  title: string,
  columns: Columns,
  data: Data,
  mime = `application/octet-stream`,
) => {
  const content = createCSVContent(columns, data);

  const encodedURL = URL.createObjectURL(
    new Blob([content], {
      type: mime,
    }),
  );

  const link = document.createElement(`a`);
  const sluggedTitle: string = slugify(title);

  link.setAttribute(`href`, encodedURL);
  link.setAttribute(`download`, `${sluggedTitle}.csv`);

  // Append the Anchor tag in the actual web page or application
  document.body.appendChild(link);

  // Trigger the click event of the Anchor link
  link.click();

  // Remove the Anchor link form the web page or application
  document.body.removeChild(link);

  // Delete the file from the bucket as it's no longer needed.
  // Must be pushed to the event queue otherwise the file will be deleted before the browser can download it.
  // setTimeout(() => dbHelper.deleteFile(filePath), 1);
};

export default exportTableToCSV;

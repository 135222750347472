import * as React from 'react';

function SvgBarChart(props) {
  return (
    <svg viewBox="0 0 16 16" aria-hidden="true" {...props}>
      <path
        d="M13 16a.945.945 0 01-1-1V6a.945.945 0 011-1h2a.945.945 0 011 1v9a.945.945 0 01-1 1zm-6 0a.944.944 0 01-1-1V1a.945.945 0 011-1h2a.945.945 0 011 1v14a.944.944 0 01-1 1zm-6 0a.945.945 0 01-1-1v-4a.945.945 0 011-1h2a.946.946 0 011 1v4a.945.945 0 01-1 1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBarChart;

import styled from 'styled-components';

export const StyledShorten = styled.div`
  align-items: center;
  display: inline-flex;

  button {
    border-radius: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    overflow: visible;

    background: transparent;
    border: none;
    box-shadow: none;
    color: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: normal;
    text-decoration: none;
    text-transform: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 8px;

    color: var(--blue);

    svg {
      flex-shrink: 0;
      height: 20px;

      fill: currentColor;
    }
  }
`;

export default StyledShorten;

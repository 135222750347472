import { fetchAPI } from 'particles';

type UpdateDomainParameters = {
  id: ArgID;
  hostname: string;
  domainType: string;
  region: string;
  competesWith: ArgID;
};

export const callUpdateDomain = async (parameters: UpdateDomainParameters): Promise<{ data: Domain }> => {
  const response = await fetchAPI({ method: `PATCH`, parameters, route: `domains` });
  const error = response?.error?.message;
  if (error) throw error;
  return response;
};

export default callUpdateDomain;

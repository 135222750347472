import { useLocation } from '@reach/router';
import queryString from 'query-string';

const isStorybook: boolean = process.env.STORYBOOK_ENV === `true`;

export const useQueryParameters = () => {
  if (isStorybook) return {};
  // eslint-disable-next-line
  const location = useLocation();
  const queryObject = queryString.parse(location.search);

  return queryObject;
};

export default useQueryParameters;

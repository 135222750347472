declare type ConditionOption = {
  label: string;
  numeric: boolean;
  string: boolean;
  value: APICondition;
};

export const conditionOptions: ConditionOption[] = [
  { label: `Contains`, numeric: false, string: true, value: `contains` },
  { label: `Does not contain`, numeric: false, string: true, value: `noContains` },
  { label: `Starts with`, numeric: false, string: true, value: `startsWith` },
  { label: `Ends with`, numeric: false, string: true, value: `endsWith` },
  { label: `Equals`, numeric: true, string: true, value: `eq` },
  { label: `Doesn't Equal`, numeric: true, string: true, value: `ne` },
  { label: `Greater Than`, numeric: true, string: false, value: `gt` },
  { label: `Greater Than or Equal To`, numeric: true, string: false, value: `gte` },
  { label: `Less Than`, numeric: true, string: false, value: `lt` },
  { label: `Less Than or Equal To`, numeric: true, string: false, value: `lte` },
];

import 'react-dates/initialize';
import * as React from 'react';
import { useState, useMemo } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useFormik } from 'formik';

// Atoms
import { Error } from 'corigan';

import './form.css';

declare type selectType = {
  label: string;
  value: string;
}

declare type RankingsTableFilterProps = {
  loading: boolean;
  setWhere: Function;
  where: {
    searchText: string;
    searchType: string;
    baseDate: string;
    compareDate: string;
  };
  types: selectType[];
  enabled: boolean;
};

type FormErrors = {
  searchText?: string;
  searchType?: string;
  baseDate?: string;
  compareDate?: string;
};

type FormValues = {
  searchText: string;
  searchType: string;
  baseDate: string;
  compareDate: string;
};

const momentToday = moment();
const momentThreeYearsAgo = moment().subtract(3, `years`);

const baseDateId = `baseDateID`;
const compareDateId = `compareDateID`;

/**
 * build and set where string from form
 * @param {object} newWhere the built whre object from form values
 * @returns where object
 */
const whereBuilder = (newWhere) => {
  let tempWhere = ``;
  // eslint-disable-next-line guard-for-in
  for (const key in newWhere) {
    if (newWhere[key]) {
      const value = (typeof newWhere[key] === `string`) ? newWhere[key] : newWhere[key].value;
      tempWhere = `${tempWhere}&${key}=${value}`
    }
  }
  return tempWhere;
}

// define PICKABLE date range
const isOutsideRange = date => {
  const tooNew = date.isAfter(momentToday);
  const tooOld = date.isBefore(momentThreeYearsAgo);
  const justRight = !tooNew && !tooOld;
  return !justRight;
};

const RankingsTableFilter = (props: RankingsTableFilterProps) => {
  const { setWhere, types, loading, where, enabled } = props;
  const [focusedBase, setFocusedBase] = useState<boolean>(false);
  const [focusedCompare, setFocusedCompare] = useState<boolean>(false);
  const [tableBaseDate, setTableBaseDate] = useState(null);
  const [tableCompareDate, setTableCompareDate] = useState(null);

  const initialValues: FormValues = {
    searchText: where.searchText,
    searchType: where.searchType,
    baseDate: where.baseDate,
    compareDate: where.compareDate,
  };

  // define formik form functions
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const whereString = whereBuilder(values);
      setWhere(whereString);
    },
    validate: (values: FormValues) => {
      const errors: FormErrors = {};
      const { searchType, baseDate, compareDate } = values;
      if (!searchType) errors.searchType = `Please select a type`;
      if (!baseDate) errors.baseDate = `Please select a start date`;
      if (!compareDate) errors.compareDate = `Please select a end date`;
      const startDate = moment(baseDate);
      const endDate = moment(compareDate);
      if (startDate.isSameOrAfter(endDate)) errors.baseDate = `Base date must be before compare date`;
      return errors;
    },
  });

  // define formik variable and functions
  const { errors, values, handleSubmit, handleChange, handleBlur, isSubmitting, setFieldValue } = formik;
  const { searchText, searchType, baseDate, compareDate } = values;

  // Set previous filters for date picker
  useMemo(() => {
    if(baseDate){
      setTableBaseDate(moment(baseDate));
    }
    if(compareDate){
      setTableCompareDate(moment(compareDate));
    }
  }, [baseDate, compareDate]);

  // handle date picker change
  const onChangeBaseDate = (baseDateChange) => {
    if (baseDateChange) {
      setFieldValue(`baseDate`, moment(baseDateChange?.clone()).format(`YYYY-MM-DD`));
      setTableBaseDate(baseDateChange?.clone());
    };
  };

  // handle date picker change
  const onChangeCompareDate = (compareDate) => {
    if (compareDate) {
      setFieldValue(`compareDate`, moment(compareDate?.clone()).format(`YYYY-MM-DD`));
      setTableCompareDate(compareDate?.clone());
    };
  };

  // define and check if page is busy for disabling
  const isBusy: boolean = isSubmitting || loading;

  return (
    <div>
      <form onSubmit={handleSubmit} aria-label="table filter" className="form--trimmed">
        <div className="form--horizontal">
          <div className="form--fields">
            <div>
              <label htmlFor="searchText">Value</label>
              <input
                className="form__margin"
                type="text"
                id="searchText"
                name="searchText"
                value={searchText}
                onBlur={handleBlur}
                onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="searchType">Type</label>
              <select
                className="form__margin"
                id="searchType"
                name="searchType"
                value={searchType}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {types.map(({ label, value }) => (
                  <option key={`${label}-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="baseDate">Base date</label>
              <div className="form__margin">
                <SingleDatePicker
                  id={baseDateId}
                  date={tableBaseDate}
                  onDateChange={onChangeBaseDate}
                  focused={focusedBase}
                  onFocusChange={({ focused }) => setFocusedBase(focused)}
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  isOutsideRange={isOutsideRange}
                  displayFormat="DD-MM-YYYY"
                />
              </div>
            </div>
            <div>
              <label htmlFor="baseDate">Compare date</label>
              <div className="form__margin">
                <SingleDatePicker
                  id={compareDateId}
                  date={tableCompareDate}
                  onDateChange={onChangeCompareDate}
                  focused={focusedCompare}
                  onFocusChange={({ focused }) => setFocusedCompare(focused)}
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  isOutsideRange={isOutsideRange}
                  displayFormat="DD-MM-YYYY"
                />
              </div>
            </div>
            <div>
              <button
                className='form__margin'
                disabled={isBusy}
                type="submit"
              >Search</button>
            </div>
          </div>
        </div>
      </form>
      {enabled && Boolean(Object.keys(errors).length) && <Error className='mb-0' error={Object.values(errors)[0]} />}
    </div>
  );
};

export default RankingsTableFilter;

import * as React from 'react';

function SvgRecentActors(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M21.7 4.5v15.1h2.2V4.5h-2.2zm-4.3 15h2.2v-15h-2.2v15zm-3.2-15h-13c-.6 0-1 .5-1 1v12.9c0 .6.5 1.1 1.1 1.1h12.9c.6 0 1.1-.5 1.1-1.1V5.5c-.1-.5-.6-1-1.1-1zM7.7 7.4c1.3 0 2.4 1.1 2.4 2.4S9 12.3 7.7 12.3s-2.4-1.1-2.4-2.4 1.1-2.5 2.4-2.5zm4.8 10H2.9v-.8c0-1.6 3.2-2.4 4.8-2.4s4.8.8 4.8 2.4v.8z"
      />
    </svg>
  );
}

export default SvgRecentActors;

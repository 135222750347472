import moment from 'moment';

const momentCurrentWeek = moment().startOf(`isoWeek`);
const momentThreeMonthsAgo = moment().subtract(3, `months`).startOf(`isoWeek`);

const viewOptions: { label: string; value: 'ctr' | 'impressions' }[] = [
  { label: `CTR`, value: `ctr` },
  { label: `Impressions`, value: `impressions` },
];

export const defaultState: KRContextProps['state'] = {
  canLoad: [`stats`],
  chartDateEnd: momentCurrentWeek,
  chartDateStart: momentThreeMonthsAgo,
  historyView: viewOptions[0].value,
  keyword: null,
  pages: [],
  volume: [],
};

export default defaultState;

import * as React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useQueryCache } from 'react-query';
import { useFormik } from 'formik';
import { navigate } from 'gatsby-link';

// Particles
import { ApplicationContext } from 'corigan';
import { addSingleCache } from 'corigan';
import ROUTES from 'routes';
import { ProtectedRoute } from 'corigan';
import { useHasPermissions } from 'corigan';
import { windowAvailable } from 'corigan';

// Components
import { Field, Link, Select } from 'corigan';
import { Breadcrumbs, Card, Error, Info } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'corigan';

// API
import { callCreateDomain } from 'corigan';

const DomainCreate = () => (
  <ProtectedRoute redirect={ROUTES.domains} requiredPermissions={[`domains:create`]}>
    <Page application="portal" pageTitle="Create Site">
      <Grid>
        <Row>
          <Col>
            <Breadcrumbs>
              <Link href={ROUTES.domains}>Sites</Link>
              <h1>Create Site</h1>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <CreateDomainForm />
          </Col>
        </Row>
      </Grid>
    </Page>
  </ProtectedRoute>
);

const successMessage = (hostname: string): string => {
  return `The domain "${hostname}" has been successfully created in the system.`;
};

type FormErrors = {
  hostname: string;
};

type FormValues = {
  hostname: ArgURL;
  domainType: any[];
  region: string;
  competesWith?: ArgID;
};

type CreateDomainProps = {};

const initialValues = {
  hostname: ``,
  domainType: [],
  region: ``,
};

declare type SelectArgs = {
  event: any;
  field: string;
  setFieldValue: any;
};

const selectChange = (args: SelectArgs): void => {
  const { event, field, setFieldValue } = args;
  const noValueProvided = !event || !field;
  if (noValueProvided) setFieldValue([]);
  setFieldValue(field, event);
};

const CreateDomainForm = (props: CreateDomainProps) => {
  const [success, setSuccess] = useState<string>(null);
  const queryCache = useQueryCache();

  const { userHasPermission: canCreateDomain } = useHasPermissions({ requiredPermissions: [`domains:create`] });

  const applicationContext: ApplicationContextProps = useContext(ApplicationContext);
  const domainActive: Domain = applicationContext?.state?.domainActive;


  const createDomain = async values => {
    const { hostname, region, domainType} = values;

    return await callCreateDomain({
      hostname,
      region,
      domainType,
      competesWith: domainActive.id
    });
  };

  const [mutate, { error, isLoading: loading }] = useMutation(createDomain, {
    // When mutate is called:
    onMutate: () => {},
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (err, variables, onMutateValue) => {
      console.error(err, variables);

      queryCache.invalidateQueries([`callGetDomains`]);
    },
    // Always refetch after error:
    onSettled: (data, error) => {},
    onSuccess: (data: APIResponse, variables) => {
      const domainData: Domain = data?.data;
      const id = domainData?.id;

      // Parameters for single domain query
      const singleWhere: ArgWhere = `[_id][eq]=${id}`;
      const whereProtected: ArgWhere = domainActive ? `[competesWith][eq]=${domainActive?.id}` : ``;

      queryCache.invalidateQueries([`callGetDomains`]);

      // Optimistically update to the new value
      queryCache.setQueryData(
        [`callGetDomains`, { where: singleWhere, whereProtected }],
        (previousUsers: APIResponse) => addSingleCache(previousUsers, domainData),
      );

      // If updateUser function was successful, get values from API response
      const latestName = domainData?.hostname;

      // Let the domain know the page has been updated and update UI
      setSuccess(successMessage(latestName));

      // Redirect to the domains table
      navigate(ROUTES.domains);
    },
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (windowAvailable() && !canCreateDomain) window.alert(`You do not have permission to create a new domain`);
      if (canCreateDomain) return await mutate({ ...values});
      // competesWith: domainActive.id 
    },
    validate: (values: FormValues) => {
      const { hostname } = values;

      const errors: FormErrors = {};
      if (!hostname) errors.hostname = `The hostname field is required`;
      if (!validURL(hostname)) errors.hostname = `Invalid hostname`;

      return errors;
    },
  });

  function validURL(str) {
    const pattern = new RegExp(`^(https?:\\/\\/)?`+ // protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|`+ // domain name
      `((\\d{1,3}\\.){3}\\d{1,3}))`+ // OR ip (v4) address
      `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*`+ // port and path
      `(\\?[;&a-z\\d%_.~+=-]*)?`+ // query string
      `(\\#[-a-z\\d_]*)?$`,`i`); // fragment locator
    return !!pattern.test(str);
  }


  const { handleSubmit, isSubmitting } = formik;

  const { errors, touched, values, handleChange, handleBlur, setFieldValue } = formik;
  const formikHelpers = {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
  };

  const onClose = e => {
    if (e) e.preventDefault();
    setSuccess(null);
  };

  const typeOptionsChange = event => {
    selectChange({
      event,
      field: `domainType`,
      setFieldValue,
    });
  };

  const typeOptions = [
    {
      label: `Primary`,
      value: `primary`,
    }, {
      label: `Secondary`,
      value: `secondary`,
    }
  ];

  const isBusy: boolean = isSubmitting || loading;
  const isLoading: boolean =  isBusy;
  const isLocked: boolean = !canCreateDomain || isBusy;

  let buttonText: string = `Create Site`;
  if (isBusy) buttonText = `Creating Site`;

  return (
    <Card loading={isLoading}>
      <section className="domain__create">
        <form onSubmit={handleSubmit} aria-label="Create a Site">
          <fieldset className="mb-0" aria-busy={isBusy} disabled={isLocked}>
            {success && (
              <Info onClose={onClose} y="lg">
                {success}
              </Info>
            )}
            {error && <Error error={error}>{error}</Error>}
            <Field {...formikHelpers} id="hostname" label="Hostname" required={true} />

            <label htmlFor="domainType">Site Type</label>
            <Select
              id="domainType"
              name="domainType"
              onChange={typeOptionsChange}
              options={typeOptions}
              value={values.domainType}
              isMulti={false}
              disabled={isLocked}
            />

            <Field
              id="region"
              label="Region code if required"
              handleChange={handleChange}
              type="text"
              value={values.region}
              required={false}
              disabled={isLocked}
            />

            <button type="submit" disabled={isLocked}>
              {buttonText}
            </button>
          </fieldset>
        </form>
      </section>
    </Card>
  );
};

export default DomainCreate;
